import React from "react";
import PropTypes from "prop-types";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

type NotificationContextType = {
    sendNotification : (message: string) => void
};

const NotificationContext = React.createContext<NotificationContextType>({
    sendNotification: (message: string) => {},
});

const Notifications = ({ open, handleClose, message = "" }) => {
    return (
        <Snackbar
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            message={message}
            action={
                <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={handleClose}
                >
                    <CloseIcon fontSize="small" />
                </IconButton>
            }
        />
    );
};

Notifications.defaultProps = {
    message: "",
};

Notifications.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    message: PropTypes.string,
};

const NotificationsProvider = ({ children }) => {
    const [state, setState] = React.useState({
        open: false,
        message: "",
    });
    const { open, message } = state;

    const handleClose = () => {
        setState({
            open: false,
            message: "",
        });
    };

    const handleOpen = (messageContent) => {
        setState({
            open: true,
            message: messageContent,
        });
    };

    return (
        <NotificationContext.Provider value={{ sendNotification: handleOpen }}>
            {children}
            <Notifications
                open={open}
                message={message}
                handleClose={handleClose}
            />
        </NotificationContext.Provider>
    );
};

NotificationsProvider.propTypes = {
    children: PropTypes.element.isRequired,
};

const useNotifications = () => {
    return React.useContext(NotificationContext);
};

export { NotificationsProvider as default, useNotifications };
