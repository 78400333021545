import React, { Component } from "react";
import { Row, Col } from "react-flexbox-grid";
import Paper from "material-ui/Paper";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { blue700 } from "material-ui/styles/colors";
import _ from "lodash";
import { LBLPROFILE } from "../../constants/labels";
import FloatingActionButtonTooltip from "../fields/FloatingActionButtonTooltip/index.tsx";
import AuthComponent from "../AuthComponent";
import TablePagination from "../TablePagination/tablePagination";

const stylePaper = {
  marginTop: 20,
  marginBottom: 20,
};

const ProfileGrid = () => {
  const body = [
    {
      field: "name",
      title: "Nombre",
      colspan: 2,
      filter: true,
    },
    {
      field: "description",
      title: "Descripción",
      colspan: 3,
    },
    {
      actions: true,
      title: "Operaciones",
      colspan: 1,
    },
  ];
  return (
    <Row>
      <AuthComponent
        component={
          <Col mdOffset={2} md={8} xs={12}>
            <Paper style={stylePaper}>
              <Row>
                <Col xs>
                  <div
                    style={{
                      width: "100%",
                      background: blue700,
                      paddingTop: 5,
                      paddingBottom: 5,
                      color: "#FFF",
                    }}
                  >
                    <h1 style={{ textAlign: "center", fontWeight: 400 }}>
                     {`${LBLPROFILE.titleGrid} `}
                    </h1>
                    <AuthComponent
                      component={
                        <FloatingActionButtonTooltip
                          textTooltip={LBLPROFILE.tooltips.btnCreate}
                          component={Link}
                          to="/admin/profiles/create"
                        />
                      }
                      permission="backend/profiles:write"
                      type="component"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs>
                  <TablePagination
                    name="profiles"
                    URL="/profiles"
                    version={1}
                    body={body}
                  />
                </Col>
              </Row>
            </Paper>
          </Col>
        }
        permission="backend/profiles:read"
        type="url"
      />
    </Row>
  );
};

function mapStateToProps({ profileForm }) {
  return { profile: profileForm.get("all").toArray() };
}
export default connect(mapStateToProps)(ProfileGrid);
