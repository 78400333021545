import React, { Component } from "react";
import { Row, Col } from "react-flexbox-grid";
import Divider from "material-ui/Divider";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import { blue700 } from "material-ui/styles/colors";
import { connect } from "react-redux";
import _ from "lodash";
import {
    showAboutAppMessage,
    hideAboutAppMessage,
} from "../../../actions/about";

class About extends Component {
    constructor(props) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose() {
        const { hideAboutAppMessage } = this.props;
        hideAboutAppMessage();
    }

    render() {
        const { show } = this.props;
        return (
            <Dialog
                title="Acerca de"
                actions={[
                    <FlatButton
                        label="Cerrar"
                        primary
                        onClick={this.handleClose}
                    />,
                ]}
                titleStyle={{ textAlign: "center", color: blue700 }}
                contentStyle={{ textAlign: "justify", minWidth: 300 }}
                modal={false}
                autoScrollBodyContent
                open={show}
                onRequestClose={this.handleClose}
            >
                SURA encuentas...
            </Dialog>
        );
    }
}

function mapStateToProps({ about }) {
    return {
        show: about.get("show"),
    };
}

export default connect(mapStateToProps, { hideAboutAppMessage })(About);
