import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";

type Props = {
  tooltip: string;
  onClick?: () => void;
  disabled?: boolean;
  icon: React.ReactNode;
  to: string;
};
const LinkIconButton = (props: Props) => {
  const { tooltip, onClick, disabled, icon, to } = props;
  return (
    <Tooltip title={tooltip}>
      <span>
        <IconButton
          component={Link}
          to={to}
          onClick={onClick}
          disabled={disabled}
        >
          {icon}
        </IconButton>
      </span>
    </Tooltip>
  );
};

export { LinkIconButton };
