import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import { Col, Row } from "react-flexbox-grid";
import {removeAliasesEmail} from "Utils";
import ButtonBack from "../../fields/buttonBack/buttonBack";
import UserActions from "./userActions";
import { LOCALE_PROFILE, LOCALE_USER_TYPE } from "../localTranslation";

function UserDetail({data}) {
  const {person, user} = data;
  const dateCreate = moment(user.createdAt).format("LL");
  const dateUpdate = moment(user.updatedAt).format("LL");

  return (
    <div
      style={{
        height: "91%",
        margin: "1%",
        borderLeft: "1px solid #D0D0D0",
      }}
    >
      <Row>
        <Col xsOffset={1} xs={10}>
          <ButtonBack url="/admin/users/grid" />
        </Col>
      </Row>
      <Row>
        <Col xs>
          
          <Row end="xs"
          style={{
            borderTop: "1px solid #E0E0E0",
            borderBottom: "1px solid #E0E0E0",
            borderRight: "1px solid #E0E0E0",


            marginLeft: "0.1%",
            width:"100%", 
          }}>
        <Col xs >
          <h2
            style={{
              fontWeight: 10,
              fontSize: 25,
              textAlign: "left",
            }}
          >
            Información del usuario
          </h2>
        </Col>
            <Col xs={6}>
              <UserActions data={data} />
            </Col>
          </Row>
        </Col>
      </Row>
  
      <Grid
        container
        style={{
          backgroundColor: "#FFF",
        }}
      >
        <Grid item xs={4} md={4}>
          <div>
            <List>
              <ListItem>
                <ListItemText secondary="Nombres: " />
                <ListItemText primary={person.information.firstName} />
              </ListItem>
              <ListItem>
                <ListItemText secondary="Apellidos: " />
                <ListItemText primary={person.information.lastName} />
              </ListItem>
              <ListItem>
                <ListItemText secondary="Identificación: " />
                <ListItemText
                  primary={person.information.identification.number}
                />
              </ListItem>
            </List>
          </div>
        </Grid>
        <Grid item xs={4} md={4}>
          <div
            style={{
              borderLeft: "1px solid #E0E0E0",
              borderRight: "1px solid #E0E0E0",
              height: "80%",
              marginTop: "5%",
            }}
          >
            <List>
              <ListItem>
                <ListItemText secondary="Email: " />
                <ListItemText primary={removeAliasesEmail(person.information.email)} />
              </ListItem>
              <ListItem>
                <ListItemText secondary="Perfil: " />
                <ListItemText>
                  {LOCALE_PROFILE[user.profiles[0]]
                    ?
                      LOCALE_PROFILE[user.profiles[0]]
                    : user.profiles[0]}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText secondary="Tipo de usuario: " />
                <ListItemText>
                  {LOCALE_USER_TYPE[user.type.kind]
                    ? LOCALE_USER_TYPE[user.type.kind]
                    : user.type.kind}
                </ListItemText>
              </ListItem>
            </List>
          </div>
        </Grid>
        <Grid item xs={4} md={4}>
          <div>
            <List>
              <ListItem>
                <ListItemText secondary="Fecha de creación: " />
                <ListItemText primary={dateCreate} />
              </ListItem>
              <ListItem>
                <ListItemText secondary="Ultima actualización: " />
                <ListItemText primary={dateUpdate} />
              </ListItem>
            </List>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default UserDetail;
