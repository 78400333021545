import Immutable from 'immutable';
import _ from 'lodash';
import { GET_DATA_PROJECT_ARRAY_ADVANCE } from './constants';

const initialState = Immutable.Map({
    advance: null,
    details: [],
    surveysProjects: []
});

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_DATA_PROJECT_ARRAY_ADVANCE:
            return state.withMutations(function(map) {
                        map.set("advance", _.get(action, 'payload.data.graphic', null));
                        map.set("details", _.get(action, 'payload.data.details', []));
                        map.set("surveysProjects", _.get(action, 'payload.data.surveys', []));
                    });
        default:
            return state;
    }
}
