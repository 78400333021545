import {ApolloClient, InMemoryCache, createHttpLink} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";
import {relayStylePagination} from "@apollo/client/utilities";
import Auth from "@aws-amplify/auth";

const uri = `${process.env.CONFIG_GRAPHQL}`;

const httpLink = createHttpLink({
    uri,
});

const authLink = setContext(async (_, {headers}) => {
    const session = await Auth.currentSession();
    const id = session.getIdToken();
    const token = session.getAccessToken();
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: `Bearer ${token.getJwtToken()}`,
            "x-id-token": id.getJwtToken(),
        },
    };
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    batchJobs: relayStylePagination(),
                },
            },
        },
    }),
});


export {client as default, client as mainClient};
