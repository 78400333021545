/**
 * Regular expressions for emails
 */
export const EXPRESSION_EMAIL = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/; // /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i

/**
 * Regular expressions for only numbers
 */
export const EXPRESSION_NUMBERS = /^([0-9])*$/;

/**
 * Regular expressions for NIT
 */
export const EXPRESSION_NIT = /^[0-9]{9,10}$/;

/**
 * Regular expressions for telephone
 */
export const EXPRESSION_TELEPHONE = /^[0-9]{7,11}$/;

/**
 * Regular expressions for Identification
 */
export const EXPRESSION_IDENTIFICACION = /^[a-zA-Z0-9]{5,13}$/g;

/**
 * Regular expressions for only string
 */
export const EXPRESSION_STRING = /^[a-zA-Z]/;

/**
 * Regular expressions for only string
 */
export const EXPRESSION_ESPECIALS = /[\^$#.*+?=!:|\\/()\[\]{}]/;

/**
 * Regular expressions for question open detail with option "other which"
 */
export const EXPRESSION_OTHER_WHICH = /^(otherWhich##)/g;
