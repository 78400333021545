import { axiosApiV2 } from "../../middleware/api";
import { GET_RESOURCES, GET_PROFILES } from "../../constants/index";

export function FetchResource() {
  const request = axiosApiV2.get(`/profiles/resources`);

  return {
    type: GET_RESOURCES,
    payload: request,
  };
}

export function FetchProfiles() {
  const request = axiosApiV2.get(`/profiles`);

  return {
    type: GET_PROFILES,
    payload: request,
  };
}

export function FetchProfilesNames() {
  const request = axiosApiV2.get(`/profiles/names`);

  return {
    type: GET_PROFILES,
    payload: request,
  };
}
