import React from 'react';
import FlatButton from 'material-ui/FlatButton';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useNewComplementValue } from '../data';

/**
 * Botón para agregar un valor
 * @author Jhoan Lopez <jhoanlt19@gmail.com>
 * @param {*} param0
 */
const ButtonSaveValue = ({ values, onCompleted }) => {
  const [mutate, { loading, error }] = useNewComplementValue(
    { ...values },
    onCompleted
  );

  return (
    <FlatButton
      data-testid="button-save-value"
      label="Guardar"
      primary
      keyboardFocused
      onClick={mutate}
      disabled={values.name === '' || loading || error}
    />
  );
};

ButtonSaveValue.defaultProps = {
  value: '',
  onCompleted: _.noop,
};

ButtonSaveValue.propTypes = {
  value: PropTypes.string,
  onCompleted: PropTypes.func,
};

export default ButtonSaveValue;
