const LOCALE_PROFILE = {
    'OPERATOR' : 'OPERADOR',
    'EVALUATOR': 'EVALUADOR',
    'COMPANY': 'EMPRESA',
    'EMPLOYEE': 'EMPLEADO',
    'ADMIN': 'ADMINISTRADOR',
    'ARL' : 'ARL',
}

const LOCALE_USER_TYPE = {
    'EXTERNAL' : 'CGR',
    'INTERNAL': 'AZURE',
}

export {
    LOCALE_PROFILE,
    LOCALE_USER_TYPE
}