import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { embedDashboard } from "amazon-quicksight-embedding-sdk";

import { dashboardSignedUrlQuery } from "./data";
import Loader from "../../commons/apollo-loaders";

const DashboardView = () => {
  const history = useHistory();
  const {
    state: { dashboardName },
  } = useLocation<{ dashboardName: string }>();
  const { dashboardId } = useParams<{ dashboardId: string }>();
  const ref = React.useRef();
  const { loading, data, error } = useQuery(dashboardSignedUrlQuery, {
    variables: {
      dashboardId,
    },
  });
  const isReady = !loading && !error;
  React.useEffect(() => {
    if (isReady) {
      embedDashboard({
        url: data.getDashboardLink,
        container: ref.current,
        locale: "es-ES",
      });
    }
  }, [data, isReady]);

  return (
    <Paper className="mx-4">
      <div className="bg-indigo-200 pb-3">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => history.goBack()}
          >
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" color="inherit">
            {dashboardName}
          </Typography>
        </Toolbar>
        <Loader isLoading={loading} error={error}>
          <div className="mx-3" style={{ height: "78vh" }} ref={ref} />
        </Loader>
      </div>
    </Paper>
  );
};

export default DashboardView;
