import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn} from 'material-ui/Table';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { getValuesList, cleanStateTreeMap } from './actions';
import { LBLQUESTIONS } from '../../../constants/labels';

let sectionId = "";

class TableListValuesSection extends Component {
    constructor(props) {
        super(props);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { idSection } = nextProps;
        const {
            getValuesList
            } = this.props;
        if (sectionId !== idSection && idSection != '') {
            sectionId = idSection;
            getValuesList(idSection);
        }
    }

    UNSAFE_componentWillMount() {
        this.props.cleanStateTreeMap('values');
        sectionId = "";
    }

    renderQuestions(questions) {
        return questions.map(function (value, index) {
            let typeQuestion = "";
            let sizeListQuestion = "";
            switch (_.get(value, '_id.type')) {
                case "AnswerOpen":
                    typeQuestion = LBLQUESTIONS.arrTypesQuestions[0];
                    break;
                case "AnswerClose":
                    typeQuestion = LBLQUESTIONS.arrTypesQuestions[1];
                    break;
                case "ListOpen":
                    typeQuestion = LBLQUESTIONS.arrTypesQuestions[2];
                    break;
                case "ListClose":
                    typeQuestion = LBLQUESTIONS.arrTypesQuestions[3];
                    break;
                case "Date":
                    typeQuestion = LBLQUESTIONS.arrTypesQuestions[4];
                    break;
                case "Number":
                    typeQuestion = LBLQUESTIONS.arrTypesQuestions[5];
                    break;
                case "AnswerOpenShort":
                    typeQuestion = LBLQUESTIONS.arrTypesQuestions[6];
                    break;
                case "GroupClose":
                    typeQuestion = LBLQUESTIONS.arrTypesQuestions[7];
                    sizeListQuestion = _.get(value, '_id.sizeList') > 1
                        ? `(${  _.get(value, '_id.sizeList')  } preguntas agrupadas)`
                        : `(${  _.get(value, '_id.sizeList')  } pregunta agrupada)`;
                    break;
                case "GroupList":
                    typeQuestion = LBLQUESTIONS.arrTypesQuestions[8];
                    sizeListQuestion = _.get(value, '_id.sizeList') > 1
                        ? `(${  _.get(value, '_id.sizeList')  } preguntas agrupadas)`
                        : `(${  _.get(value, '_id.sizeList')  } pregunta agrupada)`;
                    break;
                case "Group":
                    typeQuestion = LBLQUESTIONS.arrTypesQuestions[9];
                    sizeListQuestion = _.get(value, '_id.sizeList') > 1
                        ? `(${  _.get(value, '_id.sizeList')  } preguntas agrupadas)`
                        : `(${  _.get(value, '_id.sizeList')  } pregunta agrupada)`;
                    break;
                case "ListSelected":
                    typeQuestion = LBLQUESTIONS.arrTypesQuestions[10];
                    break;
                case "LocationCountry":
                    typeQuestion = LBLQUESTIONS.arrTypesQuestions[11];
                    break;
                case "LocationDepartment":
                    typeQuestion = LBLQUESTIONS.arrTypesQuestions[12];
                    break;
                case "LocationCity":
                    typeQuestion = LBLQUESTIONS.arrTypesQuestions[13];
                    break;
                default:
                    typeQuestion = "Vacío";
                    break;
            }
            return (
                <TableRow key={index}>
                    <TableRowColumn colSpan={2}
                                    style={{whiteSpace: 'normal'}}>{_.get(value, '_id.title')} {sizeListQuestion}</TableRowColumn>
                    <TableRowColumn style={{whiteSpace: 'normal'}}>{typeQuestion}</TableRowColumn>
                </TableRow>
            );
        })
    }

    render() {
        const {
            listData,
            visible
            } = this.props;
        const visib = visible ? _.size(listData) !== 0 : visible;
        return (
            <div style={{display: visib ? 'block' : 'none'}}>
                <Table selectable={false} style={{marginBottom: 20}}>
                    <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
                        <TableRow>
                            <TableHeaderColumn colSpan={2}>Pregunta ({_.size(listData)})</TableHeaderColumn>
                            <TableHeaderColumn>Tipo</TableHeaderColumn>
                        </TableRow>
                    </TableHeader>
                    <TableBody displayRowCheckbox={false}>
                        {this.renderQuestions(listData)}
                    </TableBody>
                </Table>
            </div>
        );
    }
}

TableListValuesSection.propTypes = {
    idSection: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getValuesList,
        cleanStateTreeMap
    }, dispatch);
}

function mapStateToProps({listValuesSection}) {
    if (_.get(listValuesSection.get('values'), 'size') !== 0) {
        if (_.size(_.get(listValuesSection.get('values'), 'data'))) {
            return {
                listData: _.has(listValuesSection.get('values'), 'data.questions')
                    ? _.get(listValuesSection.get('values'), 'data.questions')
                    : []
            };
        } 
            return {listData: []};
        
    }
    return {listData: []};
}

export default connect(mapStateToProps, mapDispatchToProps)(TableListValuesSection);
