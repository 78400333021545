import React from "react";
import PropTypes from "prop-types";
import ComplementValueMain from "./complementValueMain";
import ContentMain from "../contentMain";

/**
 * @author Jhoan Lopez <jhoanlt19@gmail.com>
 * @param {*} param0
 */
const ComplementValue = ({ params }) => {
    return (
        <ContentMain
            title="Valor del complemento"
            flex={false}
            buttomBack
            urlBack="/admin/complements/main"
            columns={10}
        >
            <ComplementValueMain value={params.id} />
        </ContentMain>
    );
};

ComplementValue.defaultProps = {
    params: {},
};

ComplementValue.propTypes = {
    params: PropTypes.object,
};

export default ComplementValue;
