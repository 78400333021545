import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Row, Col } from "react-flexbox-grid";
import Paper from "material-ui/Paper";
import { red700, blue700, orange700 } from "material-ui/styles/colors";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import RaisedButton from "material-ui/RaisedButton";
import Divider from "material-ui/Divider";
import _ from "lodash";
import { toggleSnackbar } from "../actions/commons";
import { LBLSURVEYS } from "../constants/labels";
import { getQuestionSelectorsSection } from "../actions/info/sections_info_form";
import { saveMetricsSectionSurvey } from "../actions/surveys_form";
import QuestionMetricalItemsNews from "./sections/form_question_metrical";
import QuestionMetricalItemsGroupNews from "./sections/form_question_metrical_group";
import ButtonBack from "../components/fields/buttonBack/buttonBack";
import AuthComponent from "../components/AuthComponent";

const stylePaper = {
  marginTop: 0,
  marginBottom: 50,
  paddingTop: 0,
  paddingBottom: 40,
};
const styleStatusErr = {
  margin: " 0 0 0 3px",
  background: red700,
  padding: "3px 5px",
  fontSize: 11,
  borderRadius: 5,
  color: "#FFF",
  opacity: 0.5,
};
const styleStatus = {
  margin: " 0 0 0 3px",
  background: blue700,
  padding: "3px 5px",
  fontSize: 11,
  borderRadius: 5,
  color: "#FFF",
  opacity: 0.5,
};
const styleStatusWar = {
  margin: " 0 0 0 3px",
  background: orange700,
  padding: "3px 5px",
  fontSize: 11,
  borderRadius: 5,
  color: "#FFF",
  opacity: 0.5,
};

class MetricalItemsNews extends Component {
  constructor(props) {
    super(props);
    this._validateItemMetricalQuestion =
      this._validateItemMetricalQuestion.bind(this);
    this._saveMetricals = this._saveMetricals.bind(this);
    this.renderDialogButtomConfirm = this.renderDialogButtomConfirm.bind(this);
    this.renderDialogConfirm = this.renderDialogConfirm.bind(this);
    this._handleClose = this._handleClose.bind(this);
    this.renderContentDialogConfirm =
      this.renderContentDialogConfirm.bind(this);
    this.state = {
      validate: false,
      open: false,
      summary: [],
      questions: [],
    };
  }

  UNSAFE_componentWillMount() {
    const { getQuestionSelectorsSection } = this.props;
    getQuestionSelectorsSection(this.props.params.section);
  }

  _saveMetricals() {
    const { saveMetricsSectionSurvey, toggleSnackbar, params } = this.props;
    const idSurvey = _.get(params, "survey");
    saveMetricsSectionSurvey(
      _.get(params, "survey"),
      _.get(params, "section"),
      this.state.questions
    )
      .then(
        data => {
          if (_.has(data, "error") || _.get(data, "payload.status") !== 200) {
            toggleSnackbar(true, LBLSURVEYS.msg.errorMetricsSave);
          } else {
            toggleSnackbar(true, LBLSURVEYS.msg.successMetricsSave);
            window.location.replace(`/admin/surveys/metrical/${idSurvey}`);
          }
        },
        reason => {
          toggleSnackbar(true, LBLSURVEYS.msg.errorMetricsSave);
        }
      )
      .catch(data => {
        toggleSnackbar(true, LBLSURVEYS.msg.errorMetricsSave);
      });
  }

  _validateItemMetricalQuestion() {
    const { forms, formsGroup } = this.props;
    let infinite = true;
    let i = 0;
    const questions = [];
    const summary = [];
    while (infinite) {
      if (_.isUndefined(_.get(forms, `formMetricalQuestion${i}`))) {
        if (_.isUndefined(_.get(formsGroup, `formMetricalQuestion${i}`))) {
          infinite = false;
        } else {
          const formMetricalQuestion = _.get(
            formsGroup,
            `formMetricalQuestion${i}`
          );
          const questionsGroup = [];
          let numQuestions = 0;
          let numQuestionsIncomplete = 0;
          const questionsGroupAdd = [];
          formMetricalQuestion.questions.map((question, index) => {
            let sizeLists = _.size(_.get(question, "lists", []));
            const valuesList = question.lists.map(list => {
              let metrical = _.get(list, "metrical.value");
              if (
                _.isUndefined(metrical) ||
                _.isNull(metrical) ||
                _.isEqual(metrical, "") ||
                _.isNaN(metrical)
              ) {
                sizeLists--;
                metrical = "";
              }
              return {
                idListItem: _.get(list, "_id.value"),
                value: metrical,
              };
            });
            if (_.isEqual(sizeLists, _.size(_.get(question, "lists", [])))) {
              questionsGroup.push({
                id: index + 1,
                title: _.get(question, "title.value"),
                status: 0,
                description: LBLSURVEYS.metrical.resume.complete,
              });
              questionsGroupAdd.push({
                idQuestion: _.get(question, "_id.value"),
                type: _.isUndefined(
                  _.get(formMetricalQuestion, "typeCalc.value")
                )
                  ? "summatory"
                  : _.get(formMetricalQuestion, "typeCalc.value"),
                items: valuesList,
              });
              numQuestions++;
            } else if (_.isEqual(sizeLists, 0)) {
              questionsGroup.push({
                id: index + 1,
                title: _.get(question, "title.value"),
                status: 2,
                description: LBLSURVEYS.metrical.resume.isNull,
              });
            } else {
              questionsGroup.push({
                id: index + 1,
                title: _.get(question, "title.value"),
                status: 1,
                description: LBLSURVEYS.metrical.resume.incomplete,
              });
              numQuestionsIncomplete++;
            }
          });
          if (numQuestions === 0 && numQuestionsIncomplete === 0) {
          } else if (
            _.isEqual(
              numQuestions,
              _.size(_.get(formMetricalQuestion, "questions", []))
            )
          ) {
            summary.push({
              id: i + 1,
              title: _.get(formMetricalQuestion, "title.value"),
              status: true,
              description: LBLSURVEYS.metrical.resume.complete,
              questions: questionsGroup,
            });
            questionsGroupAdd.map((v, idx) => {
              questions.push(v);
            });
          } else {
            summary.push({
              id: i + 1,
              title: _.get(formMetricalQuestion, "title.value"),
              status: false,
              description: LBLSURVEYS.metrical.resume.incomplete,
              questions: questionsGroup,
            });
          }
        }
      } else {
        const formMetricalQuestion = _.get(forms, `formMetricalQuestion${i}`);
        let sizeLists = _.size(_.get(formMetricalQuestion, "lists"));
        const valuesList = _.map(_.get(formMetricalQuestion, "lists"), list => {
          let metrical = _.get(list, "metrical.value");
          if (
            _.isUndefined(metrical) ||
            _.isNull(metrical) ||
            _.isEqual(metrical, "") ||
            _.isNaN(metrical)
          ) {
            sizeLists--;
            metrical = "";
          }
          return {
            idListItem: _.get(list, "_id.value"),
            value: metrical,
          };
        });
        if (
          _.isEqual(sizeLists, _.size(_.get(formMetricalQuestion, "lists", [])))
        ) {
          questions.push({
            idQuestion: _.get(formMetricalQuestion, "_id.value"),
            items: valuesList,
          });
          summary.push({
            id: i + 1,
            title: _.get(formMetricalQuestion, "title.value"),
            status: true,
            description: LBLSURVEYS.metrical.resume.complete,
          });
        } else if (_.isEqual(sizeLists, 0)) {
        } else {
          summary.push({
            id: i + 1,
            title: _.get(formMetricalQuestion, "title.value"),
            status: false,
            description: LBLSURVEYS.metrical.resume.incomplete,
          });
        }
      }
      i++;
    }
    this.setState({
      questions,
      summary,
      open: true,
    });
  }

  renderDescription(descrip) {
    let description = "";
    if (!_.isUndefined(descrip) && !_.isEqual(descrip, "")) {
      description = _.split(descrip, /\r\n|\r|\n/g);
    }
    if (description instanceof Array) {
      return (
        <h3 style={{ textAlign: "justify", fontWeight: 200 }}>
          {description.map((value, index) => {
            if (!_.isEqual(value, "")) {
              return (
                <div key={`des${index}`}>
                  {value}
                  <br />
                  <br />
                </div>
              );
            }
          })}
        </h3>
      );
    }
    return (
      <h3 style={{ textAlign: "justify", fontWeight: 200 }}>{description}</h3>
    );
  }

  _handleClose() {
    this.setState({ open: false });
  }

  renderDialogButtomConfirm() {
    return [
      <FlatButton label="Cancelar" secondary onClick={this._handleClose} />,
      <FlatButton label="Guardar" primary onClick={this._saveMetricals} />,
    ];
  }

  renderContentDialogConfirm() {
    return (
      <div>
        <h3 style={{ textAlign: "left", fontWeight: 200 }}>Resumen</h3>
        <ul style={{ listStyleType: "decimal" }}>
          {this.state.summary.map((resume, index) => (
            <li key={`resumeLI${index}`}>
              {!_.has(resume, "questions") ? (
                <div key={`resume${index}`}>
                  {resume.title}
                  <span style={resume.status ? styleStatus : styleStatusErr}>
                    {resume.description}
                  </span>
                </div>
              ) : (
                <div key={`resume${index}`}>
                  <div>
                    {resume.title}
                    <span style={resume.status ? styleStatus : styleStatusErr}>
                      {resume.description}
                    </span>
                  </div>
                  <ul style={{ listStyleType: "lower-latin" }}>
                    {resume.questions.map((subresume, idx) => (
                      <li key={`${idx}resume`}>
                        {subresume.title}
                        <span
                          style={
                            subresume.status === 0
                              ? styleStatus
                              : subresume.status === 1
                              ? styleStatusWar
                              : styleStatusErr
                          }
                        >
                          {subresume.description}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    );
  }

  renderDialogConfirm() {
    return (
      <Dialog
        title="Guardar métricas"
        titleStyle={{ textAlign: "center", color: blue700 }}
        actions={this.renderDialogButtomConfirm()}
        modal={false}
        onRequestClose={this._handleClose}
        autoScrollBodyContent
        open={this.state.open}
        contentStyle={{
          /* width: '100%', maxWidth: 'none' */ maxWidth: 800,
          minWidth: 320,
          textAlign: "justify",
        }}
      >
        {this.renderContentDialogConfirm()}
      </Dialog>
    );
  }

  render() {
    const { section, questions, loading, locked, params } = this.props;
    if (!_.isNull(section)) {
      return (
        <Row>
          <AuthComponent permission="backend/surveys:write" type="url" />
          <Col xs={12} mdOffset={2} md={8}>
            <Paper style={stylePaper}>
              <ButtonBack
                url={`/admin/surveys/metrical/${_.get(params, "survey")}`}
              />
              <Row>
                <Col xs={12}>
                  <div
                    style={{
                      width: "100%",
                      background: blue700,
                      paddingTop: 5,
                      paddingBottom: 5,
                      color: "#FFF",
                    }}
                  >
                    <h1
                      style={{
                        textAlign: "center",
                        fontWeight: 400,
                      }}
                    >
                      {LBLSURVEYS.metrical.titleConfigMetricals}
                    </h1>
                    <h2
                      style={{
                        textAlign: "center",
                        fontWeight: 400,
                      }}
                    >{`${_.get(section, "survey.name")} - ${_.get(
                      section,
                      "title"
                    )}`}</h2>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xsOffset={1} xs={10}>
                  {this.renderDescription(_.get(section, "description"))}
                </Col>
              </Row>
              <Row>
                <Col xsOffset={1} xs={10}>
                  {this.renderDialogConfirm()}
                </Col>
              </Row>
              <Row>
                <Col xsOffset={1} xs={10}>
                  <h3
                    style={{
                      textAlign: "justify",
                      fontWeight: 400,
                      color: red700,
                      marginBottom: 40,
                      display: locked ? "none" : "block",
                    }}
                  >
                    {LBLSURVEYS.metrical.titleRequiredConfigMetricals}
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  {questions.map((value, index) => (
                    <div key={index}>
                      {_.indexOf(
                        ["GroupList", "GroupClose", "Group"],
                        _.get(value, "type")
                      ) !== -1 ? (
                        <QuestionMetricalItemsGroupNews
                          formKey={`formMetricalQuestion${index}`}
                          initialValues={value}
                        />
                      ) : (
                        <QuestionMetricalItemsNews
                          formKey={`formMetricalQuestion${index}`}
                          initialValues={value}
                        />
                      )}
                      <Row style={{ paddingBottom: 20 }}>
                        <Col xs>
                          <Divider />
                        </Col>
                      </Row>
                    </div>
                  ))}
                  <Row>
                    <Col xsOffset={1} xs={10}>
                      <AuthComponent
                        component={
                          <RaisedButton
                            type="submit"
                            label={LBLSURVEYS.buttons.saveMetrical}
                            secondary
                            disabled={locked}
                            style={{
                              margin: 20,
                              float: "right",
                            }}
                            onClick={this._validateItemMetricalQuestion}
                          />
                        }
                        permission="backend/surveys:write"
                        type="component"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Paper>
          </Col>
        </Row>
      );
    }
    return (
      <div>
        <ButtonBack
          url={`/admin/surveys/metrical/${this.props.params.survey}`}
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getQuestionSelectorsSection,
      saveMetricsSectionSurvey,
      toggleSnackbar,
    },
    dispatch
  );
}

function mapStateToProps({ sections, form }) {
  const questionMetricals = _.isNull(sections.section)
    ? []
    : sections.section.data.questions;
  return {
    locked: _.isUndefined(_.get(sections, "section.data.survey.locked"))
      ? false
      : _.get(sections, "section.data.survey.locked"),
    section: _.isNull(_.get(sections, "section", null))
      ? null
      : _.get(sections, "section.data"),
    questions: questionMetricals,
    forms: _.get(form, "QuestionMetricalItemsNewsForm"),
    formsGroup: _.get(form, "QuestionMetricalItemsGroupNewsForm"),
    loading: !!_.isUndefined(_.get(sections.section, "data")),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MetricalItemsNews);
