import _ from "lodash";
import * as actions from "./constants";
import { axiosApiV2 } from "../../middleware/api";

export function loadResults(projectId, surveyId, personId) {
  const request = axiosApiV2.get(
    `/results/${projectId}/surveys/${surveyId}/persons/${personId}`
  );
  return {
    type: actions.LOAD_RESULTS,
    payload: request,
  };
}

export function saveAudit(audit) {
  return {
    type: actions.SAVE_AUDIT,
    payload: axiosApiV2.post(`/audit`, { audit }),
  };
}

export function cleanResults() {
  return {
    type: actions.CLEAN_RESULTS_SURVEY,
  };
}

export function saveComment(solutionId, comment) {
  const result = {
    result: {
      solutionId,
      comment,
    },
  };
  const request = axiosApiV2.put(`/results`, result);
  return {
    type: actions.SAVE_COMMENT_RESULT,
    payload: request,
  };
}
