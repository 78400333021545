import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Progress from "@material-ui/core/CircularProgress";
import { useQuickSightLicenseStatus } from "./data";
import { useApolloClient } from "@apollo/client";

type Props = {
  username: string;
  email: string;
};
const QuickSightButton: React.FC<Props> = ({ email, username }) => {
  const { isActive, isReady, toggleLicense } = useQuickSightLicenseStatus({
    email,
    username,
  });
  return (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={isActive}
            disabled={!isReady}
            onChange={(_e, checked) => toggleLicense(checked)}
            name="quickSightLicense"
            color="primary"
          />
        }
        label="QuickSight"
        disabled={!isReady}
      />
      {!isReady ? <Progress size={20} /> : null}
    </>
  );
};

export default QuickSightButton;
