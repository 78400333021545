import React, { Component } from "react";
import { Row, Col } from "react-flexbox-grid";
import Paper from "material-ui/Paper";
import RaisedButton from "material-ui/RaisedButton";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import _ from "lodash";
import { blue600 } from "material-ui/styles/colors";
import { LBLCOMPANIES } from "Labels";
import {
  FetchCompanyParam,
  cleanProject,
} from "../../actions/info/companies_info_form";
import { deleteProject } from "../../actions/projects_form";
import ButtonBack from "../fields/buttonBack/buttonBack";
import AuthComponent from "../AuthComponent";

const stylePaper = {
  marginTop: 20,
  marginBottom: 20,
};
const styleTable = {
  margin: "auto",
  width: "90%",
};
const styleButton = {
  margin: 15,
  float: "right",
};

class CompaniesInfo extends Component {
  constructor(props) {
    super(props);
  }

  componentWillUnmount() {
    this.props.cleanProject();
  }

  UNSAFE_componentWillMount() {
    const { FetchCompanyParam, params } = this.props;
    FetchCompanyParam(params._id);
  }

  renderCompany() {
    const company = _.get(this, "props.companies.data");
    return (
      <div>
        <div
          style={{
            width: "100%",
            background: blue600,
            paddingTop: 5,
            paddingBottom: 5,
            marginBottom: 25,
            color: "#FFF",
          }}
        >
          <h1 style={{ textAlign: "center", fontWeight: 400 }}>
            {company.businessName}
          </h1>
        </div>
        <ButtonBack url="/admin/me/assignprojects" />
        <table style={styleTable}>
          <tbody>
            <tr>
              <td>
                <table style={{ margin: "auto", width: "90%" }}>
                  <tbody>
                    <tr>
                      <td style={{ minWidth: 100, maxWidth: 200, width: 200 }}>
                        <strong>{LBLCOMPANIES.nit}</strong>
                      </td>
                      <td>{company.nit}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>{LBLCOMPANIES.abbreviation}</strong>
                      </td>
                      <td>{company.acronym}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>{LBLCOMPANIES.phone}</strong>
                      </td>
                      <td>{company.phone}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>{LBLCOMPANIES.email}</strong>
                      </td>
                      <td>{company.email}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>{LBLCOMPANIES.address}</strong>
                      </td>
                      <td>{company.address}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>{LBLCOMPANIES.ActivityEconomic}</strong>
                      </td>
                      <td>
                        {_.get(
                          company,
                          "activityEconomic",
                          company.economicActivity
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>{LBLCOMPANIES.numberEmployees}</strong>
                      </td>
                      <td>
                        {_.get(company, "employeesNum", company.numEmployees)}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>{LBLCOMPANIES.nameContact}</strong>
                      </td>
                      <td>{company.nameContact}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>{LBLCOMPANIES.phoneContact}</strong>
                      </td>
                      <td>{company.phoneContact}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>{LBLCOMPANIES.emailContact}</strong>
                      </td>
                      <td>{company.emailContact}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <AuthComponent
                  component={
                    <Link
                      to={`/admin/employees/grid/ref/${this.props.params._id}`}
                    >
                      <RaisedButton
                        label={LBLCOMPANIES.buttons.listEmployess}
                        secondary
                        style={styleButton}
                      />
                    </Link>
                  }
                  permission="backend/employees:read"
                  type="component"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    if (_.get(this, "props.companies.data")) {
      return (
        <Row>
          <AuthComponent
            component={
              <Col xs={12} mdOffset={2} md={8}>
                <Paper style={stylePaper}>
                  <Row>
                    <Col xs>{this.renderCompany()}</Col>
                  </Row>
                </Paper>
              </Col>
            }
            permission="backend/companies:write"
            type="url"
          />
        </Row>
      );
    }
    return (
      <div>
        <ButtonBack url="/admin/me/assignprojects" />
      </div>
    );
  }
}

function handleWindowResize() {
  triggerEvent(this._googleMapComponent, "resize");
}

function mapStateToProps({ companies }) {
  return { companies: companies.company };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      FetchCompanyParam,
      deleteProject,
      cleanProject,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesInfo);
