import PropTypes from "prop-types";
import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Row, Col } from "react-flexbox-grid";
import Paper from "material-ui/Paper";
import RaisedButton from "material-ui/RaisedButton";
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from "material-ui/Table";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { createListReferees } from "../actions/projects_form";
import AutoComplete from "../components/functional/AutoComplete";
import {
  addListValuesStore,
  removeListValuesStore,
  removeValueStore,
  changePositionValueStore,
} from "../components/functional/actions";

import { toggleSnackbar } from "../actions/commons";
import { LBLPROJECTS } from "../constants/labels";
import DeleteEntityModal from "../components/deleteEntityModal/component";
import ButtonBack from "../components/fields/buttonBack/buttonBack";
import AuthComponent from "../components/AuthComponent";

const style = {
  margin: 20,
  float: "right",
};
const stylePaper = {
  marginTop: 20,
  marginBottom: 20,
};

class ListRefereesProjectsNews extends Component {
  constructor(props) {
    super(props);
    this.removeReferees = this.removeReferees.bind(this);
    this.upReferees = this.upReferees.bind(this);
    this.downReferees = this.downReferees.bind(this);
    this._saveListRefereesProjects = this._saveListRefereesProjects.bind(this);
    this.addReferees = this.addReferees.bind(this);
    this.removeAllReferees = this.removeAllReferees.bind(this);
  }

  _saveListRefereesProjects(formData) {
    const { createListReferees, toggleSnackbar, resetForm, params } =
      this.props;
    createListReferees(formData, _.get(formData, "_id"))
      .then(data => {
        if (_.get(data, "error") || _.get(data, "payload.status") !== 200) {
          toggleSnackbar(
            true,
            "Ocurrió un error inesperado, intentalo de nuevo más tarde."
          );
        } else {
          resetForm();
          toggleSnackbar(true, "Evaluadores actualizados con éxito");
          this.props.history.push(
            `/admin/projects/info/${_.get(params, "profile")}/${params._id}/${
              params.company
            }`
          );
        }
      })
      .catch(data => {
        toggleSnackbar(
          true,
          "Ocurrió un error inesperado, intentalo de nuevo más tarde."
        );
      });
  }

  UNSAFE_componentWillMount() {
    if (_.has(this, "props.params._id")) {
      this.addReferees(this.props.params._id);
    } else {
      this.removeAllReferees("referees");
    }
  }

  addReferees(id) {
    this.props.addListValuesStore(id, "referees");
  }

  removeAllReferees() {
    this.props.removeListValuesStore("referees");
  }

  removeReferees(index) {
    this.props.removeValueStore(index, "referees");
  }

  upReferees(index) {
    this.props.changePositionValueStore(index, "referees", "up");
  }

  downReferees(index) {
    this.props.changePositionValueStore(index, "referees", "down");
  }

  render() {
    const {
      fields: { _id, collections },
      handleSubmit,
      referees,
      resetForm,
      params,
    } = this.props;
    const configDeleteModal = {
      display: "table-cell",
    };
    return (
      <Row>
        <AuthComponent
          component={
            <Col xs={12} mdOffset={2} md={8}>
              <Paper style={stylePaper}>
                <ButtonBack
                  url={`/admin/projects/info/${_.get(params, "profile")}/${
                    params._id
                  }/${params.company}`}
                />
                <Row>
                  <Col xsOffset={1} xs={10}>
                    <h1 style={{ textAlign: "center", fontWeight: 400 }}>
                      {LBLPROJECTS.associateEvaluator.title}
                    </h1>
                    <form
                      onSubmit={handleSubmit(this._saveListRefereesProjects)}
                    >
                      <Row>
                        <Col
                          xs={12}
                          mdOffset={2}
                          md={8}
                          style={{ paddingTop: 60 }}
                        >
                          <AutoComplete
                            placeholder={
                              LBLPROJECTS.associateEvaluator.helpSearch
                            }
                            subHeader={
                              LBLPROJECTS.associateEvaluator.resultSearch
                            }
                            coreName="referees"
                            storeName="referees"
                            fullWidth
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xsOffset={2} xs={8} />
                      </Row>
                      <Row>
                        <Col xsOffset={2} xs={8}>
                          <h2
                            style={{
                              textAlign: "center",
                              fontWeight: 400,
                              marginTop: 40,
                            }}
                          >
                            {LBLPROJECTS.associateEvaluator.listEvaluators}(
                            {_.size(referees)})
                          </h2>
                        </Col>
                        <Col xsOffset={2} xs={8}>
                          <div>
                            {!_.size(referees) && (
                              <h3
                                style={{
                                  marginTop: 30,
                                  textAlign: "center",
                                }}
                              >
                                {LBLPROJECTS.associateEvaluator.noEvaluators}
                              </h3>
                            )}
                            <Table selectable={false}>
                              <TableHeader displaySelectAll={false}>
                                <TableRow>
                                  <TableHeaderColumn />
                                </TableRow>
                              </TableHeader>
                              <TableBody displayRowCheckbox={false}>
                                {referees.map((referee, index) => {
                                  if (!_.isNull(referee)) {
                                    return (
                                      <TableRow key={referee._id}>
                                        <TableRowColumn colSpan={4}>
                                          <h3 style={{ fontWeight: 200 }}>{`${
                                            index + 1
                                          }. ${referee.name} ${
                                            referee.lastName
                                          } - ${referee.id}`}</h3>
                                        </TableRowColumn>
                                        <TableRowColumn>
                                          <div
                                            style={{
                                              position: "absolute",
                                              marginTop: -24,
                                              zIndex: 99,
                                            }}
                                          >
                                            <AuthComponent
                                              component={
                                                <DeleteEntityModal
                                                  tooltip={
                                                    LBLPROJECTS.tooltips
                                                      .btnDelete
                                                  }
                                                  title={
                                                    LBLPROJECTS.titleDeleteReferee
                                                  }
                                                  message={LBLPROJECTS.msg.deleteModalReferee(
                                                    `${referee.name} ${referee.lastName} (identificación: ${referee.id})`
                                                  )}
                                                  fn={() => {
                                                    this.removeReferees(index);
                                                  }}
                                                  args={[referee]}
                                                  style={configDeleteModal}
                                                />
                                              }
                                              permission="backend/projects:write"
                                              type="component"
                                            />
                                          </div>
                                        </TableRowColumn>
                                      </TableRow>
                                    );
                                  }
                                })}
                              </TableBody>
                            </Table>
                          </div>
                        </Col>
                      </Row>
                      <AuthComponent
                        component={
                          <RaisedButton
                            type="submit"
                            label={LBLPROJECTS.buttons.save}
                            secondary
                            style={style}
                          />
                        }
                        permission="backend/referees:write"
                        type="component"
                      />
                    </form>
                  </Col>
                </Row>
              </Paper>
            </Col>
          }
          permission="backend/referees:write"
          type="url"
        />
      </Row>
    );
  }
}

const validate = values => {
  const errors = {};
  return errors;
};

function mapStateToProps({ autoComplete }, ownProps) {
  return {
    referees: autoComplete.get("referees").toArray(),
    initialValues: {
      _id: ownProps.params._id,
      collections: autoComplete.get("referees").toArray(),
    },
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createListReferees,
      removeValueStore,
      changePositionValueStore,
      addListValuesStore,
      removeListValuesStore,
      toggleSnackbar,
    },
    dispatch
  );
}

ListRefereesProjectsNews.propTypes = {
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm(
  {
    form: "SectionsNewForm",
    fields: [
      "_id",
      "collections[].id",
      "collections[].name",
      "collections[].lastName",
      "collections[]._id",
    ],
    validate,
  },
  mapStateToProps,
  mapDispatchToProps
)(ListRefereesProjectsNews);
