import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import RaisedButton from "material-ui/RaisedButton";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import { blue700 } from "material-ui/styles/colors";
import AuthComponent from "../../AuthComponent";
import {
  toggleProject,
  initialProjects,
  fetchSolutionProcessed,
} from "./actions";
import * as ps from "../../../lib/projectScheduler";

const styleButton = {
  marginBottom: 10,
  marginRight: 10,
  marginTop: 0,
  float: "right",
};

class ProjectCardActions extends Component {
  constructor(props) {
    super(props);
    this.handleButton = this.handleButton.bind(this);
    this._handleCloseAction = this._handleCloseAction.bind(this);
    this._handleOpenAction = this._handleOpenAction.bind(this);
    this.state = {
      open: false,
      buttonNameValid: "",
      buttonsDisabledValid: [
        "Finalizado",
        "No iniciado",
        "No terminado",
        "Rechazado",
        "Ver resultados"
      ],
    };
  }

  _handleCloseAction() {
    this.setState({ open: false });
  }

  _handleOpenAction() {
    this.setState({ open: true });
  }

  handleButton(URL) {
    const { buttonName, projectId, personId, fetchSolutionProcessed, history } =
      this.props;
    if (_.isEqual(this.state.buttonNameValid, buttonName)) {
      fetchSolutionProcessed(projectId, personId)
        .then(
          data => {
            if (
              _.isNull(_.get(data, "error", null)) &&
              _.isEqual(_.get(data, "payload.status"), 200)
            ) {
              if (_.get(data, "payload.data.isProcessed", false)) {
                history.push(URL);
              } else {
                this._handleOpenAction();
              }
            }
          },
          reason => {
            this._handleOpenAction();
          }
        )
        .catch(data => {
          this._handleOpenAction();
        });
    } else {
      history.push(URL);
    }
  }

  componentDidMount() {
    const { time, toggleProject, projectId, buttonDisabled, projectFinalized } =
      this.props;
    const validPT = ps.validateProjectToday(time);
    if (
      _.isEqual(buttonDisabled, false) &&
      validPT &&
      _.isEqual(projectFinalized, false)
    ) {
      toggleProject(projectId, !validPT);
    } else if (!_.isEqual(projectFinalized, true)) {
      if (_.isEqual(buttonDisabled, true)) {
        toggleProject(projectId, false);
      } else {
        toggleProject(projectId, !validPT);
      }
    } else {
      toggleProject(projectId, false);
    }
  }

  UNSAFE_componentWillMount() {
    const { initialProjects } = this.props;
    initialProjects();
  }

  render() {
    const { buttonName, URLAction, buttonStatus, projectId, history } =
      this.props;
    let buttonDisabled = false;
    if (_.indexOf(this.state.buttonsDisabledValid, buttonName) >= 0) {
      buttonDisabled = true;
    } else if (_.isEqual(this.state.buttonNameValid, buttonName)) {
      buttonDisabled = false;
    } else {
      buttonDisabled = buttonStatus;
    }
    if (_.isEqual(this.state.buttonNameValid, buttonName)) {
      return (
        <div>
          <Dialog
            title="Aviso"
            actions={[
              <FlatButton
                label="Aceptar"
                secondary
                onClick={this._handleCloseAction}
              />,
            ]}
            titleStyle={{ textAlign: "center", color: blue700 }}
            contentStyle={{
              maxWidth: 600,
              minWidth: 300,
              textAlign: "justify",
            }}
            modal={false}
            open={this.state.open}
            onRequestClose={this._handleCloseAction}
          >
            <h3 style={{ fontWeight: 200, textAlign: "justify" }}>
              Los resultados se están procesando. Inténtelo más tarde.
            </h3>
          </Dialog>
          <AuthComponent
            component={
              <RaisedButton
                label={buttonName}
                secondary
                style={styleButton}
                onClick={this.handleButton.bind(this, URLAction)}
                disabled={buttonDisabled}
              />
            }
            componentDefault={
              <RaisedButton
                label="Finalizado"
                secondary
                style={styleButton}
                disabled
              />
            }
            type="component"
            permission="backend/surveyResults:read"
          />
        </div>
      );
    }
    return (
      <RaisedButton
        label={buttonName}
        secondary
        style={styleButton}
        onClick={this.handleButton.bind(this, URLAction)}
        disabled={buttonDisabled}
      />
    );
  }
}

function mapStateToProps({ dashboard, login }, ownProps) {
  const dashboardState = dashboard.get("dashboard");
  const profile = login.get("profile");
  if (_.isEqual(ownProps.buttonName, "Rechazado")) {
    return {
      buttonStatus: true,
      personId: _.get(profile, "person._id"),
    };
  }
  return {
    buttonStatus: dashboardState.get(ownProps.projectId),
    personId: _.get(profile, "person._id"),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      toggleProject,
      initialProjects,
      fetchSolutionProcessed,
    },
    dispatch
  );
}

ProjectCardActions.propTypes = {
  buttonName: PropTypes.string,
  URLAction: PropTypes.string,
  buttonDisabled: PropTypes.bool,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProjectCardActions)
);
