import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from "material-ui/Table";
import { connect } from "react-redux";
import _ from "lodash";
import MenuItem from "material-ui/MenuItem";
import {
  getRecords,
  resetOrderRecord,
  setInSearchRecords,
  changeLimitRecords,
} from "./actions";
import TableHeadColumn from "./tableHeadColumn";
import TableHeadColumnFilter from "./tableHeadColumnFilter";
import SelectFieldFormat from "../fields/SelectFieldFormat/SelectFieldFormat";

class TableHeadPagination extends Component {
  constructor(props) {
    super(props);
    this._renderTotalHead = this._renderTotalHead.bind(this);
    this._renderHead = this._renderHead.bind(this);
    this._renderRowHead = this._renderRowHead.bind(this);
    this._renderHeadFilter = this._renderHeadFilter.bind(this);
    this._onChangeLimit = this._onChangeLimit.bind(this);
  }

  _onChangeLimit(e, item, value) {
    const {
      URL,
      version,
      limit,
      skip,
      getRecords,
      setInSearchRecords,
      coreName,
      resetOrderRecord,
      changeLimitRecords,
      extra,
      body,
    } = this.props;
    const arrayFields = [];
    _.map(body, (field) => {
      if (!_.has(field, "actions") && _.has(field, "filter")) {
        if (_.get(field, "filter")) {
          arrayFields.push(_.get(field, "field"));
        }
      }
    });
    this.setState({ text: "" });
    changeLimitRecords(value, coreName);
    resetOrderRecord(coreName);
    setInSearchRecords(coreName);
    if (!_.isUndefined(extra) && !_.isNull(extra)) {
      getRecords(
        URL,
        version,
        0,
        value,
        "null",
        0,
        arrayFields,
        "",
        coreName,
        extra
      );
    } else {
      getRecords(URL, version, 0, value, "null", 0, arrayFields, "", coreName);
    }
  }

  _renderTotalHead() {
    const { total, body, limit } = this.props;
    let colSpanHead = 0;
    _.map(body, (field) => {
      colSpanHead += _.get(field, "colspan", 1);
    });
    return (
      <TableRow>
        <TableHeaderColumn
          colSpan={colSpanHead}
          style={{ textAlign: "center" }}
        >
          <h4 style={{ display: "inline-block", fontWeight: 400 }}>
            {`Número de registros en total: ${total}`}
          </h4>
          <br />
          <table style={{ width: 240, margin: "0 auto" }}>
            <tbody>
              <tr>
                <td>
                  <h4
                    style={{
                      marginTop: 0,
                      marginBottom: -12,
                      display: "inline-block",
                      fontWeight: 400,
                    }}
                  >
                    Número de registros por página:
                  </h4>
                </td>
                <td>
                  <SelectFieldFormat
                    config={{
                      floatingLabelText: "",
                      value: limit,
                      style: {
                        marginLeft: 8,
                        width: 55,
                        display: "inline-block",
                        marginBottom: -20,
                        marginTop: 9,
                      },
                      onChange: this._onChangeLimit,
                    }}
                  >
                    <MenuItem value={25} primaryText={25} />
                    <MenuItem value={50} primaryText={50} />
                    <MenuItem value={100} primaryText={100} />
                  </SelectFieldFormat>
                </td>
              </tr>
            </tbody>
          </table>
        </TableHeaderColumn>
      </TableRow>
    );
  }

  _renderHeadFilter() {
    const {
      URL,
      version,
      body,
      extra,
      selection,
      coreName,
      refreshName,
      hasFilter,
    } = this.props;

    let colSpanHead = 0;
    _.forEach(body, (field) => {
      colSpanHead += _.get(field, "colspan", 1);
    });

    if (hasFilter) {
      return (
        <TableHeadColumnFilter
          refreshName={refreshName}
          URL={URL}
          version={version}
          body={body}
          extra={extra}
          selection={selection}
          coreName={coreName}
        />
      );
    }
    return (
      <TableRow>
        <TableHeaderColumn colSpan={colSpanHead} />
      </TableRow>
    );
  }

  _renderRowHead() {
    const { body, orderName, orderType, version, URL, coreName, extra } =
      this.props;
    return _.map(body, (field, index) => (
      <TableHeadColumn
        URL={URL}
        version={version}
        field={field}
        key={`subHead${index}`}
        extra={extra}
        coreName={coreName}
      />
    ));
  }

  _renderHead() {
    return <TableRow>{this._renderRowHead()}</TableRow>;
  }

  render() {
    const { hasFilter } = this.props;
    return (
      <Table selectable={false}>
        <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
          {this._renderTotalHead()}
          {this._renderHeadFilter()}
          {this._renderHead()}
        </TableHeader>
      </Table>
    );
  }
}

function mapStateToProps({ genericTablePagination }, ownProps) {
  const store = genericTablePagination.get(
    _.get(ownProps, "coreName", "default")
  );
  return {
    limit: store.get("limit"),
    skip: store.get("skip"),
    total: store.get("total"),
    orderName: store.get("orderName"),
    orderType: store.get("orderType"),
    fieldSelection: store.get("fieldSelection"),
    arrayFields: store.get("arrayFields"),
    inSearch: store.get("inSearch"),
  };
}

TableHeadPagination.propTypes = {
  body: PropTypes.array.isRequired,
  URL: PropTypes.string.isRequired,
  version: PropTypes.number.isRequired,
  extra: PropTypes.object,
  selection: PropTypes.bool,
  coreName: PropTypes.string.isRequired,
  refreshName: PropTypes.string.isRequired,
  hasFilter: PropTypes.bool,
};

export default connect(mapStateToProps, {
  getRecords,
  resetOrderRecord,
  setInSearchRecords,
  changeLimitRecords,
})(TableHeadPagination);
