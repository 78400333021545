import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {reduxForm} from 'redux-form';
import {Row, Col} from 'react-flexbox-grid';
import Paper from 'material-ui/Paper';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import _ from 'lodash';
import {LBLPROJECTS} from 'Labels';
import DatePickerFormat from '../components/fields/DatePickerFormat/DatePickerFormat';
import { FetchProjectParam } from '../actions/info/projects_info_form';
import { updateProjects } from '../actions/projects_form';
import { toggleSnackbar } from '../actions/commons';
import ButtonBack from '../components/fields/buttonBack/buttonBack';
import { EXPRESSION_NUMBERS } from '../constants/regularExpressions';
import AuthComponent from '../components/AuthComponent';

function errorFieldValidation(field){
    return field.touched && field.error ? field.error : '';
}

let urlBack = "";

class ProjectsNews extends Component {

    constructor(props) {
        super(props);
        this._updateProject = this._updateProject.bind(this);
        this.state = {
            isReferee: false
        };
    }

    _updateProject(formData) {
        const {
            updateProjects,
            toggleSnackbar,
            resetForm,
            params,
            history
        } = this.props;
        updateProjects(_.omit(formData, 'project'), _.get(formData, 'project'))
            .then((data) => {
                if(_.get(data, 'error') || (_.get(data, 'payload.status') !== 200)){
                    toggleSnackbar(true, LBLPROJECTS.msg.errorSave);
                } else {
                    resetForm();
                    toggleSnackbar(true, LBLPROJECTS.msg.successSave);
                    history.push(`/admin/projects/info/${_.get(params, 'profile')}/${params._id}/${params.company}`);
                }
            }, (reason) => {
                toggleSnackbar(true, LBLPROJECTS.msg.errorSave);
            })
            .catch((data) => {
                toggleSnackbar(true, LBLPROJECTS.msg.errorSave);
            });
    }

    UNSAFE_componentWillMount(){
        const {
            FetchProjectParam,
            profile,
            params
        } = this.props;
        if (!_.isEmpty(profile)) {
            this.state = {
                isReferee: _.isEqual(_.get(profile, 'role'), 'referee')
            }
        }
        urlBack = `/admin/projects/info/${_.get(params, 'profile')}/${params._id}/${params.company}`;
        FetchProjectParam(params._id);
    }

    render() {
        const {
            fields: {
                name,
                description,
                project,
                openDate,
                closeDate,
                inactive
            },
            handleSubmit,
            autocomplete,
            resetForm
        } = this.props;
        return (
            <Row>
                <AuthComponent
                    component={
                        <Col xsOffset={2} xs={8}>
                            <Paper style={{ marginTop: 20, marginBottom: 20 }}>
                                <ButtonBack url={`${urlBack}`}/>
                                <Row>
                                    <Col xsOffset={2} xs={8}>
                                        <h1 style={{textAlign: 'center', fontWeight: 400}}>{LBLPROJECTS.titleEdit}</h1>
                                        <form onSubmit={handleSubmit(this._updateProject)}>
                                            <Row>
                                                <Col xs>
                                                    <TextField
                                                        hintText=""
                                                        floatingLabelText={LBLPROJECTS.name}
                                                        type="text"
                                                        errorText={name.touched && name.error ? name.error : '' }
                                                        {...name}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs>
                                                    <TextField
                                                        hintText=""
                                                        floatingLabelText={LBLPROJECTS.description}
                                                        multiLine
                                                        rows={3}
                                                        fullWidth
                                                        errorText={description.touched && description.error ? description.error : '' }
                                                        {...description}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs>
                                                    <DatePickerFormat
                                                        datePickerConfig={{
                                                            floatingLabelText:LBLPROJECTS.initialDate,
                                                            hintText:LBLPROJECTS.initialDateHint,
                                                            mode:"landscape",
                                                            errorText: errorFieldValidation(openDate),
                                                            value: openDate.value,
                                                            onChange:(e, value) => openDate.onChange(value),
                                                            style: {marginTop: 24, marginBottom: 23}
                                                        }}
                                                        stylefloatingLabelText={{marginTop: 33,marginBottom: -33}}
                                                    />
                                                </Col>
                                                <Col xs>
                                                    <DatePickerFormat
                                                        datePickerConfig={{
                                                            floatingLabelText:LBLPROJECTS.finalDate,
                                                            hintText:LBLPROJECTS.finalDateHint,
                                                            mode:"landscape",
                                                            errorText: errorFieldValidation(closeDate),
                                                            value: closeDate.value,
                                                            onChange:(e, value) => closeDate.onChange(value),
                                                            style: {marginTop: 24, marginBottom: 23}
                                                        }}
                                                        stylefloatingLabelText={{marginTop: 33,marginBottom: -33}}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs>
                                                    <TextField
                                                        floatingLabelText={LBLPROJECTS.inactiveTime}
                                                        errorText={inactive.touched && inactive.error ? inactive.error : '' }
                                                        {...inactive}
                                                        type="number"
                                                    />
                                                </Col>
                                            </Row>
                                            <AuthComponent
                                                component={<RaisedButton
                                                                type="submit"
                                                                label={LBLPROJECTS.buttons.save}
                                                                secondary
                                                                style={{ margin: 20, float: 'right' }}
                                                            />}
                                                permission="backend/projects:write"
                                                type="component"
                                            />
                                        </form>
                                    </Col>
                                </Row>
                            </Paper>
                        </Col>
                    }
                    permission="backend/projects:write"
                    type="url"
                />
            </Row>
        );
    }
}

const validate = (values) => {
    const errors = {};
    if (!values.closeDate) {
        errors.closeDate = LBLPROJECTS.validations.finalDate;
    }
    if (!values.openDate) {
        errors.openDate = LBLPROJECTS.validations.initialDate;
    }
    if(values.closeDate && values.openDate){
        const fechaInicial = moment(values.openDate).format("YYYY-MM-DD");
        const fechaFinal = moment(values.closeDate).format("YYYY-MM-DD");
        if(!(moment(fechaInicial).isBefore(fechaFinal))){
            if(!_.isEqual(`${fechaInicial}`, `${fechaFinal}`)){
                errors.openDate = LBLPROJECTS.validations.validateDate;
            }
        }
    }
    if (!values.name) {
        errors.name = LBLPROJECTS.validations.name;
    }
    if (!values.description) {
        errors.description = LBLPROJECTS.validations.description;
    }
    if (!values.inactive) {
        errors.inactive = LBLPROJECTS.validations.validateInactiveTime;
    } else if(!EXPRESSION_NUMBERS.test(values.inactive)){
            errors.inactive = LBLPROJECTS.validations.validateInactiveTimeOnlyNumber;
        }
    return errors;
};

function mapStateToProps({projects, login}, ownProps){
    return  {
        profile: login.get('profile'),
        initialValues: {
            project: ownProps.params._id,
            name: _.has(projects, 'all.data.name') ? _.get(projects, 'all.data.name') : "",
            description: _.has(projects, 'all.data.description') ? _.get(projects, 'all.data.description') : "",
            inactive: _.has(projects, 'all.data.inactive') ? _.get(projects, 'all.data.inactive') : "",
            openDate: _.has(projects, 'all.data.time.openDate') ? new Date(_.get(projects, 'all.data.time.openDate')) : "",
            closeDate: _.has(projects, 'all.data.time.closeDate') ? new Date(_.get(projects, 'all.data.time.closeDate')) : ""
        }
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateProjects,
        FetchProjectParam,
        toggleSnackbar
    }, dispatch);
}

ProjectsNews.propTypes = {
    fields: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired
};

export default reduxForm({
    form: 'ProjectsNewForm',
    fields: [
        'name',
        'description',
        'project',
        'openDate',
        'closeDate',
        'inactive'
    ],
    validate
}, mapStateToProps, mapDispatchToProps)(ProjectsNews);
