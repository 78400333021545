import React from "react";
import AppBar from "material-ui/AppBar";
import IconButton from "material-ui/IconButton";
import NavigationMenu from "material-ui/svg-icons/navigation/menu";
import IconMenu from "material-ui/IconMenu";
import MoreVertIcon from "material-ui/svg-icons/navigation/more-vert";
import AccountCircleIcon from "material-ui/svg-icons/action/account-circle";
import HelpIcon from "material-ui/svg-icons/action/help";
import MenuItem from "material-ui/MenuItem";
import _ from "lodash";
import { blue600 } from "material-ui/styles/colors";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { LBLAPP, NameEmployeeTest } from "Labels";
import { toggleNav } from "../../../actions/left_navbar";
import { showFaq, setInSearch } from "../../../actions/faq_form";
import { logOut } from "./actions";
import { showModalChangePassword } from "../../ModalChangePassword/actions";
import useChangeUserPassword from "../../users/password/index";
import { useAuth } from "../../../auth/context";

function MenuBar(props) {
  const { profile, loggedIn } = props;
  const { signOut, user } = useAuth();
  const { MenuItem: ChangePasswordItem, Modal: ChangePasswordModal } =
    useChangeUserPassword();
  const personId = _.get(profile, "person._id");
  let userType = null

  if(user && user.challengeParam){
    userType = user.challengeParam.userAttributes["custom:user_type"] || "";
  } else {
    userType = user.attributes["custom:user_type"] || "";
  }

  React.useEffect(() => {
    if (!personId) {
      this.props.history.replace(`/login`);
    }
  }, [personId]);

  const onTapLeft = () => {
    const { toggleNav, menuState } = props;
    const newState = !menuState;
    toggleNav(newState);
  };

  const onTapFaq = () => {
    const { showFaq, setInSearch } = props;
    setInSearch();
    showFaq(true);
  };

  const handleLogOut = () => {
    signOut();
  };

  const userName = loggedIn
    ? `${_.get(profile, "person.name")} ${_.get(profile, "person.lastName")}`
    : NameEmployeeTest;

  return (
    <AppBar
      title={LBLAPP.title}
      iconElementLeft={
        <IconButton onClick={onTapLeft}>
          <NavigationMenu />
        </IconButton>
      }
      iconElementRight={
        <div>
          <IconButton
            touch
            tooltip={LBLAPP.menu.frecuenceQuestions}
            onClick={onTapFaq}
          >
            <HelpIcon color="#FFF" />
          </IconButton>
          <IconMenu
            iconButtonElement={
              <IconButton iconStyle={{ fill: "#FFF" }}>
                <MoreVertIcon />
              </IconButton>
            }
            targetOrigin={{
              horizontal: "right",
              vertical: "top",
            }}
            anchorOrigin={{
              horizontal: "right",
              vertical: "top",
            }}
          >
            <MenuItem
              primaryText={userName}
              rightIcon={<AccountCircleIcon color={blue600} />}
              disabled
              innerDivStyle={{
                paddingBottom: 5,
                borderBottom: "1px solid #CCC",
              }}
              style={{ color: "#2E2E2E" }}
            />
            {userType === "INTERNAL" ? null : <ChangePasswordItem />}
            <MenuItem onClick={handleLogOut} primaryText="Cerrar sesión" />
            <MenuItem
              primaryText="v2.1.4.3"
              disabled
              innerDivStyle={{ textAlign: "right" }}
            />
          </IconMenu>
          <ChangePasswordModal />
        </div>
      }
      style={{ zIndex: 1400, position: "fixed" }}
    />
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      toggleNav,
      showFaq,
      setInSearch,
      logOut,
      showModalChangePassword,
    },
    dispatch
  );
}

function mapStateToProps({ menu, login }) {
  return {
    menuState: menu.get("menuState"),
    profile: login.get("profile"),
    loggedIn: login.get("loggedIn"),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuBar);
