import * as React from 'react';
import {
    Button,
    TextField,
    Dialog,
    DialogContent,
    DialogTitle,
    InputLabel,
    Select,
    MenuItem,
    FormControl, FormHelperText
} from '@material-ui/core';
import {
    Formik,
    Form,
} from 'formik';
import {Col, Row} from "react-flexbox-grid";
import {makeStyles} from "@material-ui/core/styles";
import {useSaveDataset, useChangeDetails} from "../../data";
import * as Yup from "yup";
import {useNotifications} from "Notifications";
import {useHistory} from "react-router-dom"

const useStyles = makeStyles(theme => ({
    buttonContent: {
        marginTop: 36,
        marginBottom: 20,
    },
    formControl: {
        marginTop: theme.spacing(2),
        width: "100%",
        minWidth: 200
    },
    helperTextColor: {
        color: "red"
    }
}))

const schema = Yup.object().shape({
    name: Yup.string()
        .required("Nombre requerido"),
    description: Yup.string()
        .required("Descripción requerida"),
    output: Yup.string()
        .required("Tipo de salida requerida")
});

type CreateFormDialogProps = {
    open: boolean
    handleClose: () => void,
    item: any
}

interface FormValues {
    name: string
    description: string
    output: string
}

const initialValues: FormValues = {
    name: '',
    description: '',
    output: ''
}

/**
 * Create or Edit Dataset Component
 * @author Jhoan Lopez <jhoan.lopez@ias.com.co>
 * @param open
 * @param handleClose
 * @param item
 * @constructor
 */
const CreateFormDialog: React.FC<CreateFormDialogProps> = ({open, handleClose, item}) => {
    const classes = useStyles();
    const {mutate, loading: loadingSaveDataset} = useSaveDataset();
    const {mutate: update, loading: loadingUpdate} = useChangeDetails();
    const {sendNotification} = useNotifications();
    const history = useHistory();
    const title = item ? 'Editar información' : 'Crear dataset'
    const initValuesToEdit = {
        name: item?.details.name,
        description: item?.details.description,
        output: 'ColumnsOnly',
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="create-form-dialog">
                <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={item ? initValuesToEdit : initialValues}
                        validationSchema={schema}
                        onSubmit={(values) => {
                            if (Boolean(item)) {
                                update({
                                    variables: {
                                        request: {
                                            datasetId: item.datasetId,
                                            name: values.name,
                                            description: values.description
                                        }
                                    },
                                })
                                    .then(() => {
                                        handleClose();
                                        sendNotification('Modificación exitosa!');
                                    })
                                    .catch(() => {
                                        sendNotification('Ocurrió un error al realizar la modificación');
                                    })
                            } else {
                                mutate({variables: {request: {...values}}})
                                    .then((res) => {
                                        history.push({
                                            pathname: `/admin/analytics/dataset/detail/${res.data.createDataset.item.datasetId}`
                                        });
                                    })
                                    .catch(() => {
                                        sendNotification('Ocurrió un error al crear el dataset');
                                    })
                            }
                        }}>
                        {({values, handleChange, touched, errors}) => {
                            return (
                                <Form>
                                    <TextField
                                        name="name"
                                        margin="dense"
                                        label="Nombre"
                                        value={values.name}
                                        onChange={handleChange}
                                        disabled={Boolean(item)}
                                        error={
                                            touched.name &&
                                            Boolean(errors.name)
                                        }
                                        helperText={
                                            touched.description && errors.description
                                        }
                                        fullWidth
                                    />
                                    <TextField
                                        name="description"
                                        margin="dense"
                                        label="Descripción"
                                        value={values.description}
                                        onChange={handleChange}
                                        error={
                                            touched.description &&
                                            Boolean(errors.description)
                                        }
                                        helperText={
                                            touched.description && errors.description
                                        }
                                        fullWidth
                                    />
                                    <FormControl className={classes.formControl}>
                                        <InputLabel>Tipo de salida</InputLabel>
                                        <Select
                                            name="output"
                                            value={values.output}
                                            onChange={handleChange}
                                            disabled={Boolean(item)}
                                            error={
                                                touched.output &&
                                                Boolean(errors.output)
                                            }
                                        >
                                            <MenuItem value="ColumnsOnly">Solo columnas</MenuItem>
                                            <MenuItem value="GroupersPivot">Pivot de agrupadores</MenuItem>
                                        </Select>
                                        {Boolean(errors.output) &&
                                            <FormHelperText className={classes.helperTextColor}>
                                                Tipo de salida requerido
                                            </FormHelperText>
                                        }
                                    </FormControl>
                                    <Row center="xs">
                                        <Col xs>
                                            <Button onClick={handleClose} className={classes.buttonContent}>
                                                Cancelar
                                            </Button>
                                        </Col>
                                        <Col xs>
                                            <Button
                                                type="submit"
                                                color="primary"
                                                className={classes.buttonContent}
                                                disabled={loadingSaveDataset || loadingUpdate}
                                            >
                                                Guardar
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            )
                        }}
                    </Formik>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default CreateFormDialog
