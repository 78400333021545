export const GENERIC_GET_RECORDS = 'GENERIC_GET_RECORDS';
export const GENERIC_CLEAN_TABLE = 'GENERIC_CLEAN_TABLE';
export const GENERIC_REMOVE_RECORD = 'GENERIC_REMOVE_RECORD';
export const GENERIC_REMOVE_MASSIVE_RECORD = 'GENERIC_REMOVE_MASSIVE_RECORD';
export const GENERIC_CHANGE_FIELD_RECORD = 'GENERIC_CHANGE_FIELD_RECORD';
export const GENERIC_LOCKED_RECORD = 'GENERIC_LOCKED_RECORD';
export const GENERIC_CHANGE_STATUS_RECORD = 'GENERIC_CHANGE_STATUS_RECORD';
export const GENERIC_RESET_ORDER_RECORDS = 'GENERIC_RESET_ORDER_RECORDS';
export const GENERIC_SET_ARRAY_FIELDS_FILTER = 'GENERIC_SET_ARRAY_FIELDS_FILTER';
export const GENERIC_SET_FIELD_SELECTION = 'GENERIC_SET_FIELD_SELECTION';
export const GENERIC_CLEAN_SELECTED = 'GENERIC_CLEAN_SELECTED';
export const GENERIC_ADD_SELECTED = 'GENERIC_ADD_SELECTED';
export const GENERIC_REMOVE_SELECTED = 'GENERIC_REMOVE_SELECTED';
export const GENERIC_ADD_MASSIVE_SELECTED = 'GENERIC_ADD_MASSIVE_SELECTED';
export const GENERIC_REMOVE_MASSIVE_SELECTED = 'GENERIC_REMOVE_MASSIVE_SELECTED';
export const GENERIC_SET_SELECTED = 'GENERIC_SET_SELECTED';
export const GENERIC_SET_STATUS_FILTER = 'GENERIC_SET_STATUS_FILTER';
export const GENERIC_SET_IN_SEARCH_RECORDS = 'GENERIC_SET_IN_SEARCH_RECORDS';
export const GENERIC_CHANGE_LIMIT_RECORDS = 'GENERIC_CHANGE_LIMIT_RECORDS';
export const GENERIC_ADD_RECORD = 'GENERIC_ADD_RECORD';
