import { axiosAPI } from '../../middleware/api';

export const GET_QUESTION_INFO = "GET_QUESTION_INFO";

export function FetchQuestionParam(id) {

    const request = axiosAPI.get(`/questions/${id}`);

    return {
        type: GET_QUESTION_INFO,
        payload: request
    }
}
