import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableHeaderColumn,
  TableHeader,
  TableRowColumn,
} from "material-ui/Table";
import moment from "moment";
import _ from "lodash";
import { spanishLocale } from "../../constants/index";

class TabNoEvaluatedQuestions extends Component {
  constructor(props) {
    super(props);
  }

  renderQuestion(question, idx) {
    const questionName = _.get(question, "question.title", "");
    const answerValue = _.get(question, "normalizedAnswer", "");
    return (
      <TableRow key={idx}>
        <TableRowColumn style={{ whiteSpace: "normal" }}>
          {questionName}
        </TableRowColumn>
        <TableRowColumn style={{ whiteSpace: "normal" }}>
          {_.isEqual(_.get(question, "question.type", ""), "Date")
            ? moment(answerValue).utc().startOf("days").format("LL")
            : answerValue}
        </TableRowColumn>
      </TableRow>
    );
  }

  render() {
    const { questions } = this.props;
    moment.locale(spanishLocale);
    return (
      <Table selectable={false}>
        <TableHeader
          adjustForCheckbox={false}
          displaySelectAll={false}
          enableSelectAll={false}
        >
          <TableRow>
            <TableHeaderColumn>Pregunta</TableHeaderColumn>
            <TableHeaderColumn>Respuesta</TableHeaderColumn>
          </TableRow>
        </TableHeader>
        <TableBody displayRowCheckbox={false}>
          {_.map(questions, this.renderQuestion)}
        </TableBody>
      </Table>
    );
  }
}

TabNoEvaluatedQuestions.PropTypes = {
  questions: PropTypes.array.isRequired,
};

export default TabNoEvaluatedQuestions;
