import React, { useEffect } from "react";
import { Col } from "react-flexbox-grid";
import _ from "lodash";
import ComplementMenu from "./complementMenu";
import {
  useGetComplements,
  useDeleteComplement,
  useEditComplement,
} from "./data";
import { useComplementContext } from "./context/complementProvider";
import ComplementForm from "./complementForm";
import ListComplementValue from "./listComplementValue";
import { LBL_COMPLEMENTS } from "../../constants/labels";

const styles = {
  colMenu: {
    borderRight: "solid",
    borderRightColor: "#eeeeee",
    borderRightWidth: 2,
  },
  contentTitle: {
    padding: 6,
    backgroundColor: "#E3F2FD",
    margin: 12,
  },
  contentList: {
    padding: 24,
    paddingTop: 0,
  },
  contentTextFieldValues: {
    textAlign: "center",
  },
};

/**
 * Contenedor del menu, formulario y lista
 * @author Jhoan Lopez <jhoanlt19@gmail.com>
 */
const ComplementMain = () => {
  const {
    complements,
    selectedComplement,
    setComplements,
    setSelectedComplement,
  } = useComplementContext();

  const { loading: loadingComplements, error, data } = useGetComplements();
  const [deleteComplement, {}] = useDeleteComplement({
    _id: selectedComplement._id,
  });
  const [editComplement, {}] = useEditComplement({});

  useEffect(() => {
    if (!loadingComplements && !error) {
      setComplements(data);
    }
  }, [loadingComplements, data, error]);

  const handleSaveEditComplement = (name) => {
    editComplement({
      variables: { input: { _id: selectedComplement._id, name } },
    })
      .then(({ data }) => {
        if (data.editComplement) {
          const newData = _.map(complements, (c) => {
            if (c._id === selectedComplement._id) {
              c.name = name;
            }
            return c;
          });
          setComplements(newData);
        }
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  };

  const handleDeleteComplement = (_id) => {
    deleteComplement()
      .then(({ data }) => {
        if (data.deleteComplement) {
          const newData = _.filter(complements, (c) => c._id !== _id);
          setComplements(newData);
          setSelectedComplement({});
        }
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  };

  return (
    <>
      <Col xs={12} md={2} style={styles.colMenu}>
        <ComplementMenu
          data-testid="render-menu"
          data={complements}
          loading={loadingComplements}
        />
      </Col>
      <Col xs={12} md={10}>
        <ComplementForm
          selected={selectedComplement}
          handleSave={handleSaveEditComplement}
          handleDelete={handleDeleteComplement}
          titleDelete={LBL_COMPLEMENTS.titleDelete}
        />
        <div style={styles.contentTitle}>
          <span>{LBL_COMPLEMENTS.titleListComplement}</span>
        </div>
        <div style={styles.contentList}>
          <ListComplementValue selectedComplement={selectedComplement} />
        </div>
      </Col>
    </>
  );
};

export default ComplementMain;
