import { axiosAPI } from '../../middleware/api';

export const GET_COMPANIES_GRID = "GET_COMPANIES_GRID";
export const GET_COMPANIES_NAMES = "GET_COMPANIES_NAMES";

export function FetchCompany() {
    const request = axiosAPI.get(`/companies`);
    return {
        type: GET_COMPANIES_GRID,
        payload: request
    }
}

export function getCompaniesName() {
    const request = axiosAPI.get(`/companies/names`);
    return {
        type: GET_COMPANIES_NAMES,
        payload: request
    }
}
