import "regenerator-runtime";
import "core-js";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ApolloProvider } from "@apollo/client";
import { QueryCache, ReactQueryCacheProvider } from "react-query";
import Amplify, { Logger } from "@aws-amplify/core";
import AmplifyAuth from "@aws-amplify/auth";

import NotificationsProvider from "Notifications";
import configureStore from "./store/configureStore";
import AuthRouter from "./router";
import MuiThemeProvider from "./components/theme-provider";

import apolloClient from "./middleware/graphql";

import "./css/style.css";
import awsConfig from "./aws-exports";
import { parse } from "url";

function configureAuth() {
  const logger = new Logger("configureAuth");
  const currentUrl = window.location.href;

  const hasCode = !!(parse(currentUrl).query || "")
    .split("&")
    .map(entry => entry.split("="))
    .find(([k]) => k === "code");

  const hasStateInUrl = !!(parse(currentUrl).query || "")
    .split("&")
    .map(entry => entry.split("="))
    .find(([k]) => k === "state");

  const hasStateInStorage = !!sessionStorage.getItem("oauth_state");

  logger.info("currentUrl", {
    currentUrl,
    hasCode,
    hasStateInUrl,
    hasStateInStorage,
  });

  if (hasCode && !hasStateInUrl && hasStateInStorage) {
    logger.info(
      "oauth: code in url, but no state in url, but state in storage, so we add it to url"
    );
    // set state in window.location
    const state = sessionStorage.getItem("oauth_state");
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("state", state);

    history.replaceState(null, null, "?" + queryParams.toString());
  }

  Amplify.configure(awsConfig);
  AmplifyAuth.configure(awsConfig);
}

configureAuth();

const store = configureStore();

const queryCache = new QueryCache();

function App() {
  return (
    <Provider store={store}>
      <NotificationsProvider>
        <ApolloProvider client={apolloClient}>
          <ReactQueryCacheProvider queryCache={queryCache}>
            <MuiThemeProvider>
              <AuthRouter />
            </MuiThemeProvider>
          </ReactQueryCacheProvider>
        </ApolloProvider>
      </NotificationsProvider>
    </Provider>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
