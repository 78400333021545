import _ from "lodash";
import {
  POST_EMPLOYEE,
  DELETE_EMPLOYEE,
  GET_DOCUMENTS_TYPES,
} from "../constants/index";
import { axiosAPI, axiosApiV2 } from "../middleware/api";

export const GET_EMPLOYEE = "GET_EMPLOYEE";

export function createEmployee(props, editMode) {
  const person = _.omit(props, ["company", "_id", "tags"]);
  let employee = _.pick(props, ["company", "_id", "tags"]);
  employee = _.set(
    employee,
    "tags",
    _.map(_.get(employee, "tags"), function (tag) {
      return _.omit(tag, ["dataSource"]);
    })
  );

  let request = null;
  if (editMode) {
    request = axiosAPI.put(`/employees`, {
      person,
      employee,
    });
  } else {
    request = axiosAPI.post(`/employees`, {
      person,
      employee,
    });
  }

  return {
    type: POST_EMPLOYEE,
    payload: request,
  };
}

export function FetchEmployeeParam(id) {
  const request = axiosAPI.get(`/employees/one/${id}`);

  return {
    type: GET_EMPLOYEE,
    payload: request,
  };
}

export function deleteEmploye(employeId) {
  const request = axiosAPI.delete(`/employees/${employeId}`, {
    data: { type: "logic" },
  });

  return {
    type: DELETE_EMPLOYEE,
    payload: request,
    meta: {
      employeId,
    },
  };
}

export function getDocumentsTypes() {
  const request = axiosApiV2.get(`/master/documentsTypes`);

  return {
    type: GET_DOCUMENTS_TYPES,
    payload: request,
  };
}
