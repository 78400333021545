import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {Row, Col} from 'react-flexbox-grid';
import TablePagination from '../../TablePagination/tablePagination';

class EmployeesProject extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { projectId } = this.props;
        const body = [
            {
                field: "id",
                title: "Identificación",
                filter: true,
            },
            {
                field: "name",
                title: "Nombre",
                filter: true,
            },
            {
                field: "lastName",
                title: "Apellido(s)",
                filter: true,
            },
            {
                field: "email",
                title: "Correo electrónico"
            },
            {
                actions: true,
                title: "Operaciones"
            }
        ];
        return (
            <Row>
                <Col xs>
                    <TablePagination
                        name="configemployees"
                        URL={`/projects/config/employees/${projectId}`}
                        version={1}
                        body={body}
                        extra={{project: projectId}}
                    />
                </Col>
            </Row>
        );
    }
}

export default EmployeesProject;
