import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-flexbox-grid';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { cleanFilterCompanies } from './actions';
import FilterEconomicActivity from './filters/filterEconomicActivity';
import FilterEmployeesNumber from './filters/filterEmployeesNumber';
import FilterCountries from './filters/filterCountry';
import FilterDepartments from './filters/filterDepartment';
import FilterCities from './filters/filterCity';
import FilterEconomicActivityGeneric from './filtersGeneric/filterEconomicActivity';
import FilterEmployeesNumberGeneric from './filtersGeneric/filterEmployeesNumber';
import FilterCountriesGeneric from './filtersGeneric/filterCountry';
import FilterDepartmentsGeneric from './filtersGeneric/filterDepartment';
import FilterCitiesGeneric from './filtersGeneric/filterCity';

class FilterCompanies extends Component {

    constructor(props){
        super(props);
        this.renderFilter = this.renderFilter.bind(this);
    }

    UNSAFE_componentWillMount() {
        const { cleanFilterCompanies } = this.props;
        cleanFilterCompanies();
    }

    renderFilter(){
        const { URL, version, extra, body } = this.props;
        return (
            <Col xs={12} mdOffset={1} md={10}>
                <Row>
                    <Col xs>
                        <FilterEconomicActivity
                            URL={URL}
                            version={version}
                            extra={extra}
                            body={body}
                        />
                    </Col>
                    <Col xs>
                        <FilterEmployeesNumber
                            URL={URL}
                            version={version}
                            extra={extra}
                            body={body}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs>
                        <FilterCountries
                            URL={URL}
                            version={version}
                            extra={extra}
                            body={body}
                        />
                    </Col>
                    <Col xs>
                        <FilterDepartments
                            URL={URL}
                            version={version}
                            extra={extra}
                            body={body}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs>
                        <FilterCities
                            URL={URL}
                            version={version}
                            extra={extra}
                            body={body}
                        />
                    </Col>
                </Row>
            </Col>
        );
    }

    renderFilterGeneric(){
        const { URL, version, extra, body, coreName } = this.props;
        return (
            <Col xs={12} mdOffset={1} md={10}>
                <Row>
                    <Col xs>
                        <FilterEconomicActivityGeneric
                            coreName={coreName}
                            URL={URL}
                            version={version}
                            extra={extra}
                            body={body}
                        />
                    </Col>
                    <Col xs>
                        <FilterEmployeesNumberGeneric
                            coreName={coreName}
                            URL={URL}
                            version={version}
                            extra={extra}
                            body={body}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs>
                        <FilterCountriesGeneric
                            coreName={coreName}
                            URL={URL}
                            version={version}
                            extra={extra}
                            body={body}
                        />
                    </Col>
                    <Col xs>
                        <FilterDepartmentsGeneric
                            coreName={coreName}
                            URL={URL}
                            version={version}
                            extra={extra}
                            body={body}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs>
                        <FilterCitiesGeneric
                            coreName={coreName}
                            URL={URL}
                            version={version}
                            extra={extra}
                            body={body}
                        />
                    </Col>
                </Row>
            </Col>
        );
    }

    render() {
        const { isGeneric } = this.props;
        const generic = _.isNull(isGeneric) || _.isUndefined(isGeneric) ? false : isGeneric;
        if(generic){
            return ( <div>{this.renderFilterGeneric()}</div> );
        } 
            return ( <div>{this.renderFilter()}</div> );
        
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        cleanFilterCompanies
    }, dispatch);
}

FilterCompanies.propTypes = {
    URL: PropTypes.string.isRequired,
    version: PropTypes.number.isRequired,
    extra: PropTypes.object.isRequired,
    body: PropTypes.array.isRequired,
    isGeneric: PropTypes.bool,
    coreName: PropTypes.string,
};

export default connect(null, mapDispatchToProps)(FilterCompanies);
