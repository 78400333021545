import _ from "lodash";
import {
  GET_VALUES_ACTIVITY_ECONOMIC_FILTER_COMPANY,
  CHANGE_VALUE_ACTIVITY_ECONOMIC_FILTER_COMPANY,
  GET_VALUES_EMPLOYEES_NUMBER_FILTER_COMPANY,
  CHANGE_VALUE_EMPLOYEES_NUMBER_FILTER_COMPANY,
  GET_VALUES_COUNTRIES_FILTER_COMPANY,
  CHANGE_VALUE_COUNTRIES_FILTER_COMPANY,
  GET_VALUES_DEPARTMENTS_FILTER_COMPANY,
  CHANGE_VALUE_DEPARTMENTS_FILTER_COMPANY,
  GET_VALUES_CITIES_FILTER_COMPANY,
  CHANGE_VALUE_CITIES_FILTER_COMPANY,
  CLEAN_VALUES_FILTER_COMPANY,
} from "./constants";
import { axiosApiV2 } from "../../middleware/api";

export function getValuesActivityEconomic() {
  const request = axiosApiV2.get(`/master/economicActivities`);

  return {
    type: GET_VALUES_ACTIVITY_ECONOMIC_FILTER_COMPANY,
    payload: request,
  };
}

export function changeValueActivityEconomic(economicActivity) {
  return {
    type: CHANGE_VALUE_ACTIVITY_ECONOMIC_FILTER_COMPANY,
    meta: {
      economicActivity,
    },
  };
}

export function getValuesEmployeesNumber() {
  const request = axiosApiV2.get(`/master/employeesNumber`);

  return {
    type: GET_VALUES_EMPLOYEES_NUMBER_FILTER_COMPANY,
    payload: request,
  };
}

export function changeValueEmployeesNumber(employeesNum) {
  return {
    type: CHANGE_VALUE_EMPLOYEES_NUMBER_FILTER_COMPANY,
    meta: {
      employeesNum,
    },
  };
}

export function getValuesCountries() {
  const request = axiosApiV2.get(`/location/countries`);

  return {
    type: GET_VALUES_COUNTRIES_FILTER_COMPANY,
    payload: request,
  };
}

export function changeValueCountries(country) {
  return {
    type: CHANGE_VALUE_COUNTRIES_FILTER_COMPANY,
    meta: {
      country,
    },
  };
}

export function getValuesDepartments(idCountry) {
  const request = axiosApiV2.get(`/location/departments/${idCountry}`);

  return {
    type: GET_VALUES_DEPARTMENTS_FILTER_COMPANY,
    payload: request,
  };
}

export function changeValueDepartments(department) {
  return {
    type: CHANGE_VALUE_DEPARTMENTS_FILTER_COMPANY,
    meta: {
      department,
    },
  };
}

export function getValuesCities(idDepartment) {
  const request = axiosApiV2.get(`/location/cities/${idDepartment}`);

  return {
    type: GET_VALUES_CITIES_FILTER_COMPANY,
    payload: request,
  };
}

export function changeValueCities(city) {
  return {
    type: CHANGE_VALUE_CITIES_FILTER_COMPANY,
    meta: {
      city,
    },
  };
}

export function cleanFilterCompanies() {
  return {
    type: CLEAN_VALUES_FILTER_COMPANY,
  };
}
