import React, { Component } from "react";
import { Row, Col } from "react-flexbox-grid";
import { List, ListItem } from "material-ui/List";
import _ from "lodash";
import { blue700 } from "material-ui/styles/colors";
import LabelIcon from "material-ui/svg-icons/action/label";
import { getRamdonString } from "../../lib/util";

const styles = {
    innerDivStyle: {
        paddingRight: 35,
    },
    innerDivStyle2: {
        paddingRight: 35,
        paddingTop: 0,
    },
    nestedListStyle: {
        paddingLeft: 10,
    },
};

class TableSumQuestion extends Component {
    renderListItem(children) {
        return (
            <ListItem
                key={getRamdonString()}
                autoGenerateNestedIndicator
                leftIcon={<LabelIcon color={_.get(children, "conditions.0.color", "#F0F0F0")} />}
                secondaryText={_.get(children, "conditions.0.descriptionUser")}
                primaryText={_.get(children, "name", "")}
                initiallyOpen={false}
                innerDivStyle={{ ...styles.innerDivStyle }}
                nestedListStyle={{ ...styles.nestedListStyle }}
                primaryTogglesNestedList
                nestedItems={this.renderChildren(_.get(children, "children", []))}
            />
        );
    }

    renderListItemLevel1(children) {
        return (
            <ListItem
                key={getRamdonString()}
                autoGenerateNestedIndicator
                leftIcon={<LabelIcon color={_.get(children, "conditions.0.color", "#F0F0F0")} />}
                secondaryText={_.get(children, "conditions.0.descriptionUser")}
                primaryText={_.get(children, "name", "")}
                innerDivStyle={{ ...styles.innerDivStyle }}
                nestedListStyle={{ ...styles.nestedListStyle }}
                primaryTogglesNestedList
            />
        );
    }

    renderChildren(childrens, showAll) {
        return _.map(childrens, children => {
            if (children.level !== 1) {
                if (_.get(children, "visible") || showAll) {
                    return this.renderListItem(children);
                }
            } else if (_.get(children, "visible") || showAll) {
                return this.renderListItemLevel1(children);
            }
        });
    }

    renderChildrens(childrens, showAll) {
        return _.map(childrens, (children, idx) => {
            if (_.get(children, "visible") || showAll) {
                return (
                    <ListItem
                        key={getRamdonString()}
                        primaryText={_.get(children, "name", "")}
                        autoGenerateNestedIndicator
                        leftIcon={<LabelIcon color={_.get(children, "conditions.0.color", "#F0F0F0")} />}
                        secondaryText={_.get(children, "conditions.0.descriptionUser")}
                        initiallyOpen={false}
                        innerDivStyle={{ ...styles.innerDivStyle }}
                        primaryTogglesNestedList
                        nestedListStyle={{ ...styles.nestedListStyle }}
                        secondaryTextLines={2}
                        nestedItems={this.renderChildren(_.get(children, "children", []), showAll)}
                    />
                );
            }
        });
    }

    render() {
        const { dataConditionals, showAll } = this.props;
        return (
            <Row>
                <Col xs={12}>
                    <div style={{ width: "100%", background: blue700, paddingTop: 5, paddingBottom: 5, color: "#FFF" }}>
                        <h1 style={{ textAlign: "center", fontWeight: 400 }}>{_.get(dataConditionals, "name")}</h1>
                    </div>
                    <p style={{ textAlign: "justify", color: "#828282", padding: "6px 15px" }}>
                        Estos son los resultados generales de la prueba; de clic en cualquiera de las condiciones para
                        visualizar los resultados que lo componen.
                    </p>
                    <List>{this.renderChildrens(_.get(dataConditionals, "children", []), showAll)}</List>
                </Col>
            </Row>
        );
    }
}

export default TableSumQuestion;
