import React, { useState } from "react";
import { List, ListItem } from "material-ui/List";
import Subheader from "material-ui/Subheader";
import CircularProgress from "material-ui/CircularProgress";
import { FloatingActionButton } from "material-ui";
import ContentAdd from "material-ui/svg-icons/content/add";
import { 
  
 } from "react-router-dom";
import PropTypes from "prop-types";
import DialogAddComplement from "./dialogAddComplement";
import { useComplementContext } from "./context/complementProvider";

const styles = {
  lisItem: {
    textAlign: "initial",
  },
  selected: {
    textAlign: "initial",
    backgroundColor: "#e3f2fd",
  },
  text: {
    color: "grey",
  },
  contentAddCom: {
    width: "100%",
    textAlign: "end",
    paddingRight: 8,
    marginBottom: 8,
  },
};

/**
 * Menu de complementos
 * @author Jhoan Lopez <jhoanlt19@gmail.com>
 * @param {*} param0
 */
const ComplementMenu = ({ data, loading }) => {
  const [stateModal, setStateModal] = useState(false);
  const { selectedComplement, setSelectedComplement } = useComplementContext();

  const changeModalState = () => {
    setStateModal((currentState) => !currentState);
  };

  const handleSelect = (item) => {
    setSelectedComplement(item);
  };

  return (
    <div>
      <List>
        <Subheader>Complementos</Subheader>
        <div style={styles.contentAddCom}>
          <FloatingActionButton
            onClick={changeModalState}
            mini
            data-testid="add-button"
          >
            <ContentAdd />
          </FloatingActionButton>
        </div>
        {!loading && data.length > 0 ? (
          data.map((complement) => (
            <ListItem
              data-testid="complement-info"
              data-id={complement._id}
              style={
                selectedComplement._id === complement._id
                  ? styles.selected
                  : styles.lisItem
              }
              key={complement._id}
              primaryText={complement.name}
              onClick={() => handleSelect(complement)}
              nestedItems={complement.values.map((value) => (
                <ListItem
                  data-testid="value-info"
                  style={styles.lisItem}
                  key={value._id}
                  primaryText={value.name}
                  containerElement={
                    <Link to={`/admin/complements/value/${value._id}`} />
                  }
                />
              ))}
            />
          ))
        ) : loading ? (
          <CircularProgress />
        ) : (
          <p style={styles.text}>No se han agregado complementos!</p>
        )}
      </List>
      <DialogAddComplement
        title="Agregar complemento"
        label="Nombre"
        open={stateModal}
        handleClose={changeModalState}
      />
    </div>
  );
};

ComplementMenu.defaultProps = {
  data: [],
  loading: false,
};

ComplementMenu.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
};

export default ComplementMenu;
