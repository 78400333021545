import Immutable from 'immutable';
import _ from 'lodash';
import * as actions from './constants';

const initialState = Immutable.Map(
    {
        id: null,
        code: "",
        name: "",
        title: "",
        openModal: false,
        location: "",
        idParent: "",
        editMode: false
    }
);

export default function (state = initialState, action) {
    switch (action.type) {
        case actions.OPEN_MODAL_FORM:
            var newState = Immutable.Map(
                {
                    openModal: true,
                    code: action.code,
                    name: action.name,
                    id: action.id,
                    title: action.title,
                    location: action.location,
                    idParent: action.idParent,
                    editMode: action.editMode
                }
            );
            return newState;
        case actions.CLOSE_MODAL_FORM:
            return state.set("openModal", false);
        case actions.SAVE_LOCATIONS:
            return state;
        case actions.DELETE_LOCATION:
            return state;
        default:
            return state;
    }
}
