import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Row, Col } from "react-flexbox-grid";
import Paper from "material-ui/Paper";
import { blue700 } from "material-ui/styles/colors";
import _ from "lodash";
import { toggleSnackbar } from "../actions/commons";
import { LBLSURVEYS } from "../constants/labels";
import { getQuestionSelectorsSection } from "../actions/info/sections_info_form";
import { saveMetricsSectionSurvey } from "../actions/surveys_form";
import { FetchSurveyParam } from "../actions/info/surveys_info_form";
import TreeQuestionsItems from "./surveys/treeQuestionItems";
import ButtonBack from "../components/fields/buttonBack/buttonBack";
import AuthComponent from "../components/AuthComponent";

const stylePaper = {
  marginTop: 20,
  marginBottom: 50,
  paddingTop: 0,
  paddingBottom: 40,
};

class GrouperQuestionNews extends Component {
  UNSAFE_componentWillMount() {
    const { FetchSurveyParam, params } = this.props;
    FetchSurveyParam(params.survey);
  }

  renderDescription(descrip) {
    let description = "";
    if (!_.isUndefined(descrip) && !_.isEqual(descrip, "")) {
      description = _.split(descrip, /\r\n|\r|\n/g);
    }
    if (description instanceof Array) {
      return (
        <h3 style={{ textAlign: "justify", fontWeight: 200 }}>
          {description.map((value, index) => {
            if (!_.isEqual(value, "")) {
              return (
                <div key={`des${index}`}>
                  {value}
                  <br />
                  <br />
                </div>
              );
            }
          })}
        </h3>
      );
    }
    return (
      <h3 style={{ textAlign: "justify", fontWeight: 200 }}>{description}</h3>
    );
  }

  render() {
    const { surveyName, locked, params } = this.props;
    return (
      <Row>
        <AuthComponent
          component={
            <Col xs={12} mdOffset={2} md={8}>
              <Paper style={stylePaper}>
                <Row>
                  <Col xs={12}>
                    <div
                      style={{
                        width: "100%",
                        background: blue700,
                        paddingTop: 5,
                        paddingBottom: 5,
                        color: "#FFF",
                      }}
                    >
                      <h1 style={{ textAlign: "center", fontWeight: 400 }}>
                        {LBLSURVEYS.metrical.titleConfigGrouperQuestion}
                      </h1>
                      <h2 style={{ textAlign: "center", fontWeight: 400 }}>
                        {surveyName}
                      </h2>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} mdOffset={1} md={10}>
                    <TreeQuestionsItems
                      surveyId={_.get(params, "survey")}
                      locked={locked}
                      URLBack={`/admin/surveys/groupers/${_.get(
                        params,
                        "survey"
                      )}`}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs>
                    <ButtonBack
                      url={`/admin/surveys/groupers/${_.get(params, "survey")}`}
                    />
                  </Col>
                </Row>
              </Paper>
            </Col>
          }
          permission="backend/surveyGroupers:write"
          type="url"
        />
      </Row>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getQuestionSelectorsSection,
      saveMetricsSectionSurvey,
      FetchSurveyParam,
      toggleSnackbar,
    },
    dispatch
  );
}

function mapStateToProps({ surveys }, ownProps) {
  return {
    locked: _.get(surveys, "all.data.locked", true),
    surveyName: _.get(surveys, "all.data.name"),
    survey: ownProps.params.survey,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GrouperQuestionNews);
