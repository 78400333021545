import Immutable from 'immutable';
import _ from 'lodash';
import {
    GET_PROFILE,
    GET_PROFILES,
    GET_RESOURCES,
    ADD_PERMISSION,
    CLEAR_PROFILE
} from '../constants/index';

const INITIAL_STATE = Immutable.Map({
    all: Immutable.List(),
    profile: null,
    resources: null,
    permissions: Immutable.List()
});

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_PROFILE:
            return state.withMutations(state => {
                state.set('profile', _.get(action, 'payload.data', null));
                state.set('permissions', Immutable.List(_.get(action, 'payload.data.permissions', [])));
            });
        case GET_RESOURCES:
            return state.set('resources', _.get(action, 'payload.data', null));
        case GET_PROFILES:
            return state.set('all', Immutable.List(_.get(action, 'payload.data', [])));
        case ADD_PERMISSION:
            var permissions = state.get('permissions').toArray();
            if(_.get(action, 'meta.value', false)){
                permissions.push(_.get(action, 'meta.idPermission'));
                permissions = _.uniq(permissions);
            } else {
                permissions = _.filter(permissions, permission => !_.isEqual(permission, _.get(action, 'meta.idPermission')));
            }
            return state.set('permissions', Immutable.List(permissions));
        case CLEAR_PROFILE:
            return state.withMutations(state => {
                state.set('all', Immutable.List([]));
                state.set('profile', null);
                state.set('resources', null);
                state.set('permissions', Immutable.List([]));
            });
        default:
            return state;
    }
}
