import React from "react";
import {TableRow, TableCell, Link} from "@material-ui/core"
import {Link as RouterLink, LinkProps} from "react-router-dom";
import {parseSimpleDate} from "Utils";

const LinkBehavior = React.forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => (
    <RouterLink ref={ref} {...props} />
));

type DatasetRowProps = {
    item: any;
};

/**
 * Component that represents a row in the list of datasets
 * @author Jhoan Lopez <jhoan.lopez@ias.com.co>
 * @param item
 * @constructor
 */
const DatasetRow: React.FC<DatasetRowProps> = ({item}) => {
    return (
        <>
            <TableRow>
                <TableCell align="center">
                    <Link
                        component={LinkBehavior}
                        to={{
                            pathname: `/admin/analytics/dataset/detail/${item.datasetId}`,
                            state: item.datasetId
                        }}
                    >
                        {item.details.name}
                    </Link>
                </TableCell>
                <TableCell align="center">{item.details.description}</TableCell>
                <TableCell align="center">{parseSimpleDate(item.createdAt)}</TableCell>
                <TableCell align="center">{parseSimpleDate(item.updatedAt)}</TableCell>
            </TableRow>
        </>
    );
};

export default DatasetRow;
