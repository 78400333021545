import { axiosAPI } from '../../../middleware/api';

export const ADVANCE_USER = "ADVANCE_USER";
export const ADVANCE_USER_CLEAN = "ADVANCE_USER_CLEAN";
export const ADVANCE_USER_DIALOG = "ADVANCE_USER_DIALOG";

export function advanceUserProject(userId, projectId, person) {
    const request = axiosAPI.get(`/users/advance/${userId}/${projectId}`);

    return {
        type: ADVANCE_USER,
        payload: request,
        meta: {
            person
        }
    }
}

export function advanceUserClean() {
    return {
        type: ADVANCE_USER_CLEAN
    }
}

export function openDialogUserAdvance() {
    return {
        type: ADVANCE_USER_DIALOG,
        meta: {
            openDialog: true
        }
    }
}

export function closeDialogUserAdvance() {
    return {
        type: ADVANCE_USER_DIALOG,
        meta: {
            openDialog: false
        }
    }
}
