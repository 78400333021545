import React from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-flexbox-grid";
import Paper from "material-ui/Paper";
import { blue600 } from "material-ui/styles/colors";
import { makeStyles } from "@material-ui/core/styles";
import { LBLUSERS } from "Labels";
import AuthComponent from "../../components/AuthComponent";
import SpinnerLoading from "../../components/SpinnerLoading/componentSpinner";
import UserDetail from "../../components/users/detail/userDetail";
import TableAttributesUser from "./TableAtributes";
import UserLicenses from "../../components/users/licenses/index";

const UserGridDetail = ({location}) => {
  const data = location.state;
  const useToolbarStyles = makeStyles((theme) => ({
    root: {
      margin: 0,
      backgroundColor: "#f2f2f2",
    },
    table: {
      marginTop: 20,
      marginBottom: 20,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    rootPassword: {
      margin: "2%",
    },
    ChangePassword: {
      margin: "2%",
      padding: 10,
      border: "1px solid #D0D0D0",
      backgroundColor: "#f2f2f2",
    },
    UserLicenses: {
      padding: 0,
      margin: "2%",
      border: "1px solid #D0D0D0",
      backgroundColor: "#FFF",
    },
  }));
  const classes = useToolbarStyles();

  return (
    <Row>
      <AuthComponent
        component={
          <Col xsOffset={1} xs={10}>
            <Paper className={classes.table}>
              <Row>
                <Col xs>
                  <div
                    style={{
                      width: "100%",
                      background: blue600,
                      paddingTop: 5,
                      paddingBottom: 5,
                      color: "#FFF",
                    }}
                  >
                    <h1
                      style={{
                        textAlign: "center",
                        fontWeight: 400,
                      }}
                    >
                      {`${LBLUSERS.titleGridUser} ${data.person.information.firstName} ${data.person.information.lastName} `}
                    </h1>
                  </div>
                </Col>
              </Row>
              <Row className={classes.root}>
                <Col xs>
                  <UserDetail data={data} />
                </Col>
              </Row>
              <Row className={classes.root}>
                <Col xs>
                  <TableAttributesUser data={data} />
                </Col>
              </Row>
              <Row className={classes.root}>
                <Col className={classes.UserLicenses} xs>
                  <UserLicenses username={data.user.type.username} email={data.person.information.email} />
                </Col>
              </Row>
            </Paper>
          </Col>
        }
        permission="backend/users:read"
        type="url"
      />
      <SpinnerLoading />
    </Row>
  );
};

export default connect(null)(UserGridDetail);
