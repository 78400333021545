import PropTypes from "prop-types";
import React, { Component } from "react";
import TableCell from "@material-ui/core/TableCell";
import _ from "lodash";
import { Link } from "react-router-dom";
import IconButton from "material-ui/IconButton";
import { connect } from "react-redux";
import EditIcon from "material-ui/svg-icons/editor/mode-edit";
import AuthComponent from "../../AuthComponent";
import { LBLFAQS } from "../../../constants/labels";
import DeleteEntityModal from "../../deleteEntityModal/component";
import { deleteFaq } from "../../../actions/faq_form";
import { toggleSnackbar } from "../../../actions/commons";
import { removeRecord } from "../actions";

class FaqActionColumn extends Component {
  constructor(props) {
    super(props);
    this._handleDeleteFaq = this._handleDeleteFaq.bind(this);
  }

  _handleDeleteFaq(data) {
    const { deleteFaq, toggleSnackbar, removeRecord } = this.props;
    const idFaq = _.get(data, "_id");
    deleteFaq(idFaq)
      .then(
        (data) => {
          if (_.get(data, "error") || _.get(data, "payload.status") !== 200) {
            toggleSnackbar(true, LBLFAQS.msg.errorDelete);
          } else {
            removeRecord(idFaq, "_id");
            toggleSnackbar(true, LBLFAQS.msg.successDelete);
          }
        },
        (reason) => {
          toggleSnackbar(true, LBLFAQS.msg.errorDelete);
        }
      )
      .catch((data) => {
        toggleSnackbar(true, LBLFAQS.msg.errorDelete);
      });
  }

  render() {
    const { data, colSpan } = this.props;
    const cs = _.isUndefined(colSpan) ? 1 : colSpan;
    return (
      <TableCell colSpan={cs}>
        <div style={{ position: "absolute", marginTop: -24, zIndex: 99 }}>
          <AuthComponent
            component={
              <Link to={`/admin/faq/edit/${_.get(data, "_id")}`}>
                <IconButton
                  tooltip={LBLFAQS.tooltips.btnEdit}
                  tooltipPosition="top-center"
                  children={<EditIcon />}
                />
              </Link>
            }
            permission="backend/faqs:write"
            type="component"
          />
          <AuthComponent
            component={
              <DeleteEntityModal
                tooltip={LBLFAQS.tooltips.btnDelete}
                title={LBLFAQS.titleDelete}
                message={LBLFAQS.msg.deleteModal(_.get(data, "name"))}
                fn={this._handleDeleteFaq}
                args={[data]}
                style={{ display: "table-cell" }}
              />
            }
            permission="backend/faqs:delete"
            type="component"
          />
        </div>
      </TableCell>
    );
  }
}

FaqActionColumn.propTypes = {
  data: PropTypes.object.isRequired,
  colSpan: PropTypes.number,
};

export default connect(null, { deleteFaq, toggleSnackbar, removeRecord })(
  FaqActionColumn
);
