import React, { Component } from "react";
import { Row, Col } from "react-flexbox-grid";
import Paper from "material-ui/Paper";
import { blue700 } from "material-ui/styles/colors";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import BusinessInformation from "./businessInformation";
import {
  cleanBusinessInfo,
  getArlInfoFromPersonId,
  getInfoCompaniesArl,
} from "./actions";
import AuthComponent from "../../AuthComponent";

import LoadingPage from "../../commons/loadingPage";

class ArlCompanies extends Component {
  constructor(props) {
    super(props);
    this.renderInformation = this.renderInformation.bind(this);
  }

  UNSAFE_componentWillMount() {
    const {
      profile,
      getArlInfoFromPersonId,
      getInfoCompaniesArl,
      cleanBusinessInfo,
    } = this.props;
    const personId = _.get(profile, "person._id");
    cleanBusinessInfo();
    if (personId) {
      getArlInfoFromPersonId(personId).then((response) => {
        const arlId = _.get(response, "payload.data.arl");
        getInfoCompaniesArl(arlId);
      });
    }
  }

  renderInformation() {
    const { infoCompanies, arl } = this.props;
    if (_.isNull(infoCompanies)) {
      return <LoadingPage visible />;
    }
    if (_.isUndefined(_.get(infoCompanies, "countArlCompanies", undefined))) {
      return (
        <div style={{ paddingTop: 40, paddingBottom: 40 }}>
          <h2 style={{ textAlign: "center", fontWeight: 200 }}>
            No se pudo cargar la información
          </h2>
        </div>
      );
    }
    return (
      <AuthComponent
        component={
          <div style={{ paddingTop: 40, paddingBottom: 40 }}>
            <BusinessInformation
              info={infoCompanies}
              arlId={_.get(arl, "arl", null)}
            />
          </div>
        }
        permission="backend/arl:write"
        type="component"
      />
    );
  }

  render() {
    const { profile } = this.props;
    const personName = `${_.get(profile, "person.name", "")} ${_.get(
      profile,
      "person.lastName",
      ""
    )}`;
    return (
      <Row style={{ paddingTop: 30 }}>
        <Col xs={12} mdOffset={1} md={10}>
          <Paper style={{ marginTop: 20, marginBottom: 20 }}>
            <Row>
              <Col xs>
                <div
                  style={{
                    width: "100%",
                    background: blue700,
                    paddingTop: 5,
                    paddingBottom: 5,
                    color: "#FFF",
                  }}
                >
                  <h1 style={{ textAlign: "center", fontWeight: 400 }}>
                    {personName}
                  </h1>
                </div>
              </Col>
            </Row>
            {this.renderInformation()}
          </Paper>
        </Col>
      </Row>
    );
  }
}

function mapStateToProps({ arlDashboard, login }) {
  return {
    arl: arlDashboard.get("arl"),
    profile: login.get("profile"),
    infoCompanies: arlDashboard.get("companies"),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getArlInfoFromPersonId,
      getInfoCompaniesArl,
      cleanBusinessInfo,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ArlCompanies);
