import React from 'react'
import {Col, Row } from 'react-flexbox-grid';
import {makeStyles, Fab, Tooltip} from "@material-ui/core";
import AddFilter from "./addFilter";
import AddIcon from "@material-ui/icons/Add";
import FiltersList from "./filtersList";

const useStyles = makeStyles(theme => ({
    root: {
        padding: 20,
        minHeight: 294
    },
    addFilterButton: {
        marginRight: theme.spacing(2),
    },
}))

type TabFilterProps = {
    item: any
}

/**
 * Component to add filters to dataset
 * @author Jhoan Lopez <jhoan.lopez@ias.com.co>
 * @param item
 * @constructor
 */
const TabFilter:React.FC<TabFilterProps> = ({item}) => {
    const classes = useStyles()
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return(
        <Row className={classes.root}>
            <Col xs={12}>
                <Row end="xs">
                    <Tooltip title="Agregar filtro">
                        <Fab
                            size="small"
                            color="secondary"
                            onClick={handleClickOpen}
                            className={classes.addFilterButton}
                        >
                            <AddIcon />
                        </Fab>
                    </Tooltip>
                </Row>
            </Col>
            <Col xs>
                <Row center="xs">
                    <FiltersList dataset={item} />
                </Row>
            </Col>
            <AddFilter open={open} handleClose={handleClose} dataset={item}/>
        </Row>
    )
}

export default TabFilter;