import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import {TransitionProps} from '@material-ui/core/transitions';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

type AlertDialogSlideProps = {
    title: string
    description: string
    open: boolean
    handleClose: () => void
    handleAccept: () => void
    disabled: boolean
}

/**
 * Alert Dialog
 * Generic component for notification alerts in the app
 * @author Jhoan Lopez <jhoan.lopez@ias.com.co>
 * @param open
 * @param handleClose
 * @param handleAccept
 * @param title
 * @param description
 * @param disabled
 * @constructor
 */
const AlertDialog: React.FC<AlertDialogSlideProps> = ({
                                                               open,
                                                               handleClose,
                                                               handleAccept,
                                                               title,
                                                               description,
                                                               disabled
                                                           }) => {
    return (
        <div>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {description}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="default">
                        Cancelar
                    </Button>
                    <Button onClick={handleAccept} color="primary" disabled={disabled}>
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default AlertDialog
