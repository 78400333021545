import React, { useState } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-flexbox-grid";
import { TextField, RaisedButton } from "material-ui";
import Container from "./container";
import { passwordRegex, passwordInvalidMessage } from "../commons";
import {Typography} from "@material-ui/core";

const styles = {
  loginBox: {
    marginTop: 80,
  },
  paper: {
    paddingBottom: 25,
    paddingLeft: 0,
  },
  codeRow: {
    marginTop: 40,
  },
  field: {
    margin: "0 auto",
    textAlign: "left",
    minWidth: "50%",
  },
  sendChangePassword: {
    textAlign: "center",
    marginTop: 36,
    marginBottom: 20,
    minWidth: "50%",
  },
  link: {
    color: "blue",
  },
  fieldContent: {
    textAlign: "center",
    width: "100%",
  },
  textCode: {
    fontSize: 14,
    color: "#9e9e9e",
  },
  passwordRow: {},
};

const initialStateForm = {
  code: "",
  newPassword: "",
  confirmPassword: "",
  errors: {},
};

const CodeVerificationForm = ({ onSubmit, isLoading }) => {
  const [formState, setFormState] = useState(initialStateForm);

  const validate = () => {
    const { code, newPassword, confirmPassword } = formState;
    let isValid = true;
    const errors = {};

    if (!code) {
      isValid = false;
      errors.code = "Ingresa el código de verificación";
    }

    if (!newPassword) {
      isValid = false;
      errors.newPassword = "Ingresa la nueva contraseña";
    }

    if (!passwordRegex.test(newPassword)) {
      isValid = false;
      errors.newPassword = passwordInvalidMessage;
    }

    if (!confirmPassword) {
      isValid = false;
      errors.confirmPassword = "Confirma la nueva contraseña";
    }

    if (newPassword && confirmPassword) {
      if (newPassword !== confirmPassword) {
        isValid = false;
        errors.confirmPassword = "Las contraseñas no coinciden";
      }
    }
    setFormState(() => ({ ...formState, errors }));

    return isValid;
  };

  const forgotPassword = async (e) => {
    e.preventDefault();
    const { code, newPassword } = formState;

    if (!validate()) {
      return;
    }
    onSubmit({ code, newPassword });
  };

  const onChange = (e) => {
    e.persist();
    setFormState(() => ({ ...formState, [e.target.name]: e.target.value }));
  };

  return (
    <Container>
      <form onSubmit={forgotPassword}>
        <Row style={styles.codeRow}>
          <Col xs style={styles.fieldContent}>
            <Typography >
              Te enviamos un código de verificación a tú correo electrónico
            </Typography>
          </Col>
        </Row>
        <Row style={styles.codeRow}>
          <Col xs style={styles.fieldContent}>
            <TextField
              maxLength="6"
              floatingLabelText="Código de verificación"
              style={styles.field}
              name="code"
              onChange={onChange}
              errorText={formState.errors.code}
              value={formState.code}
            />
          </Col>
        </Row>
        <Row>
          <Col xs style={styles.fieldContent}>
            <TextField
              minLength="8"
              floatingLabelText="Nueva contraseña"
              style={styles.field}
              name="newPassword"
              onChange={onChange}
              type="password"
              errorText={formState.errors.newPassword}
              value={formState.newPassword}
            />
          </Col>
        </Row>
        <Row>
          <Col xs style={styles.fieldContent}>
            <TextField
              floatingLabelText="Confirmar contraseña"
              style={styles.field}
              name="confirmPassword"
              onChange={onChange}
              type="password"
              errorText={formState.errors.confirmPassword}
              value={formState.password}
            />
          </Col>
        </Row>
        <Row>
          <Col xs style={styles.fieldContent}>
            <RaisedButton
              label="Cambiar contraseña"
              secondary
              disabled={isLoading}
              type="submit"
              style={styles.sendChangePassword}
            />
          </Col>
        </Row>
      </form>
    </Container>
  );
};

CodeVerificationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default CodeVerificationForm;
