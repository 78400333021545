import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { reduxForm } from "redux-form";
import _ from "lodash";
import Paper from "material-ui/Paper";
import { Row, Col } from "react-flexbox-grid";
import { blue700 } from "material-ui/styles/colors";
import PersonPin from "material-ui/svg-icons/maps/person-pin";
import InsertChart from "material-ui/svg-icons/editor/insert-chart";
import { Tabs, Tab } from "material-ui/Tabs";
import TextField from "material-ui/TextField";
import RaisedButton from "material-ui/RaisedButton";
import Divider from "material-ui/Divider";
import DocumentTitle from "react-document-title";
import NotEvalQuestions from "./tabNoEvalQuestions";
import TabSumQuestion from "./tabSumQuestions";
import TableFreqResult from "./tableFreqResult";
import TableSumQuestion from "./tableSumQuestion";
import SnackbarMessage from "../commons/snackBarMessage";
import { toggleSnackbar } from "../../actions/commons";
import FaqMessage from "../fields/FaqMessage/FaqMessage";
import About from "../fields/about/About";
import ButtonBack from "../fields/buttonBack/buttonBack";
import AuthComponent from "../AuthComponent";
import { loadResults, cleanResults, saveComment, saveAudit } from "./actions";

const style = {
  margin: 20,
  float: "right",
};

class ResultsSurvey extends Component {
  constructor(props) {
    super(props);
    this.showComment = this.showComment.bind(this);
    this._saveComment = this._saveComment.bind(this);
  }
  UNSAFE_componentWillMount() {
    const {
      params: { project, survey, employee },
      loadResults,
      cleanResults,
      profile,
      saveAudit,
    } = this.props;
    cleanResults();
    loadResults(project, survey, employee);
    const audit = {
      who: _.get(profile, "person._id"),
      event: "view_results",
      whom: employee,
      project,
    };
    saveAudit(audit);
  }

  componentWillUnmount() {
    const { cleanResults } = this.props;
    cleanResults();
  }

  _saveComment(formData) {
    const self = this;
    const { solutionId, saveComment, toggleSnackbar } = self.props;
    saveComment(solutionId, formData.comment)
      .then(
        (data) => {
          if (_.get(data, "error") || _.get(data, "payload.status") !== 200) {
            toggleSnackbar(true, "Ocurrió un error al guardar el comentario");
          } else {
            toggleSnackbar(true, "Comentario guardado con éxito");
          }
        },
        (reason) => {
          toggleSnackbar(true, "Ocurrió un error al guardar el comentario");
        }
      )
      .catch((data) => {
        toggleSnackbar(true, "Ocurrió un error al guardar el comentario");
      });
  }

  showComment() {
    const {
      role,
      handleSubmit,
      fields: { comment },
      params,
    } = this.props;
    let isReferee = false;
    if (_.isEqual(role, "referee") || _.isEqual(role, "admin")) {
      if (!_.isEqual(_.get(params, "profile"), "empl")) {
        isReferee = true;
      }
    }
    const showUser = !_.isEmpty(comment.value) || !_.isEqual(comment.value, "");

    if (isReferee) {
      return (
        <AuthComponent
          component={
            <Paper style={{ marginTop: 20, marginBottom: 20 }}>
              <div
                style={{
                  width: "100%",
                  background: blue700,
                  paddingTop: 5,
                  paddingBottom: 5,
                  color: "#FFF",
                }}
              >
                <h1 style={{ textAlign: "center", fontWeight: 400 }}>
                  Comentario
                </h1>
              </div>
              <Row>
                <Col xs={8} xsOffset={2}>
                  <form onSubmit={handleSubmit(this._saveComment)}>
                    <div style={{ width: "100%" }}>
                      <TextField
                        hintText="Escriba su comentario..."
                        floatingLabelText="Comentario"
                        multiLine
                        fullWidth
                        readOnly={false}
                        rowsMax={5}
                        errorText={
                          comment.touched && comment.error ? comment.error : ""
                        }
                        {...comment}
                      />
                    </div>
                    <RaisedButton
                      type="submit"
                      label="Guardar"
                      secondary
                      style={style}
                    />
                  </form>
                </Col>
              </Row>
            </Paper>
          }
          permission="backend/surveyResults:write"
          type="component"
        />
      );
    }
    if (showUser) {
      return (
        <Paper style={{ marginTop: 20, marginBottom: 20 }}>
          <div
            style={{
              width: "100%",
              background: blue700,
              paddingTop: 5,
              paddingBottom: 5,
              color: "#FFF",
            }}
          >
            <h1 style={{ textAlign: "center", fontWeight: 400 }}>Comentario</h1>
          </div>
          <Row>
            <Col xs={8} xsOffset={2}>
              <p style={{ fontSize: "15px", textAlign: "justify" }}>
                {_.get(comment, "value")}
              </p>
            </Col>
          </Row>
        </Paper>
      );
    }
    return <div />;
  }

  render() {
    const { freq, sum, notEval, params, role } = this.props;
    let urlBack = "";
    if (_.isEqual(_.get(params, "profile"), "empl")) {
      if (!_.isEqual(role, "employee")) {
        urlBack = `/admin/me/projects`;
      } else {
        urlBack = `/dashboard/employee/projects`;
      }
    } else {
      urlBack = `/admin/projects/advance/${_.get(params, "profile")}/${
        params.company
      }/${params.project}`;
    }

    const dataConditionals = _.set(sum, "name", "Condiciones");
    let showAll = false;
    if (_.isEqual(role, "referee") || _.isEqual(role, "admin")) {
      if (!_.isEqual(_.get(params, "profile"), "empl")) {
        showAll = true;
      }
    }

    return (
      <DocumentTitle title="Resultados">
        <Row style={{ marginTop: 80, marginBottom: 40 }}>
          <AuthComponent
            component={
              <Col xs={12} mdOffset={2} md={8}>
                <ButtonBack url={urlBack} />
                <div
                  style={{
                    width: "100%",
                    background: blue700,
                    paddingTop: 5,
                    paddingBottom: 5,
                    color: "#FFF",
                  }}
                >
                  <h1 style={{ textAlign: "center", fontWeight: 400 }}>
                    Resultados de la prueba
                  </h1>
                </div>
                <Paper>
                  <Tabs>
                    <Tab
                      icon={<PersonPin />}
                      label="Información general"
                      value={0}
                    >
                      <NotEvalQuestions questions={notEval} />
                    </Tab>
                    <Tab icon={<InsertChart />} label="Resultados" value={1}>
                      {_.size(sum) > 0 ? (
                        <TableSumQuestion
                          dataConditionals={dataConditionals}
                          showAll={showAll}
                        />
                      ) : (
                        <div />
                      )}
                      <br />
                      <Divider />
                      {_.size(sum) > 0 ? (
                        <TabSumQuestion
                          data={dataConditionals}
                          showAll={showAll}
                        />
                      ) : (
                        <div />
                      )}
                      <br />
                      <br />
                      <Divider />
                      <TableFreqResult data={freq} showAll={showAll} />
                    </Tab>
                  </Tabs>
                </Paper>
                {this.showComment()}
              </Col>
            }
            permission="backend/surveyResults:read"
            type="url"
          />
          <SnackbarMessage />
          <FaqMessage />
          <About />
        </Row>
      </DocumentTitle>
    );
  }
}

const validate = (values) => {
  const errors = {};
  /* if (!values.comment) {
        errors.comment = "Ingrese un comentario"
    } */
  return errors;
};

function mapStateToProps({ login, resultsSurvey }) {
  const profile = login.get("profile");

  return {
    initialValues: {
      comment: resultsSurvey.get("comment"),
    },
    profile,
    role: _.get(profile, "role"),
    solutionId: resultsSurvey.get("solutionId"),
    freq: resultsSurvey.get("freq"),
    sum: resultsSurvey.get("sum"),
    notEval: resultsSurvey.get("notEval"),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadResults,
      cleanResults,
      saveComment,
      toggleSnackbar,
      saveAudit,
    },
    dispatch
  );
}

export default reduxForm(
  {
    form: "ResultsForm",
    fields: ["comment"],
    validate,
  },
  mapStateToProps,
  mapDispatchToProps
)(ResultsSurvey);
