import React from "react";
import { Grid } from "react-flexbox-grid";
import AppMenu from "./menu_bar";
import LeftMenu from "./left_navbar";

function AdminDashboard(props) {
  return (
    <>
      <AppMenu />
      <LeftMenu />
      <Grid fluid>{props.children}</Grid>
    </>
  );
}

export default AdminDashboard;
