import PropTypes from "prop-types";
import React, { Component } from "react";
import { Row, Col } from "react-flexbox-grid";
import _ from "lodash";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import {
    Table,
    TableBody,
    TableHeader,
    TableHeaderColumn,
    TableRow,
    TableRowColumn,
} from "material-ui/Table";
import IconButton from "material-ui/IconButton";
import RefreshIcon from "material-ui/svg-icons/navigation/refresh";
import InfoIcon from "material-ui/svg-icons/action/info";
import CircularProgress from "material-ui/CircularProgress";
import RaisedButton from "material-ui/RaisedButton";
import { connect } from "react-redux";
import { blue700 } from "material-ui/styles/colors";
import { bindActionCreators } from "redux";
import { toggleSnackbar } from "../../actions/commons";
import AuthComponent from "../AuthComponent";
import {
    getEmployeeProcessed,
    getEmployeeAndProcessed,
    sendEmployeeProcessed,
    inSearchEmployeeProcessed,
} from "./actions";

function getColorBorder(countSolution, countProcessed) {
    if (countSolution === countProcessed) {
        return "#58C257";
    } if (countProcessed === 0) {
        return "#EDE969";
    } if (countSolution > countProcessed) {
        return "#EB9850";
    }
    return "#FFF";
}

class EmployeesProcessed extends Component {
    static propTypes = {
        idProjects: PropTypes.arrayOf(String).isRequired,
    };

    state = {
        open: false,
    };

    UNSAFE_componentWillMount() {
        this._toUpdateProcessed();
    }

    _toUpdateProcessed = () => {
        const {
            idProjects,
            getEmployeeProcessed,
            inSearchEmployeeProcessed,
        } = this.props;
        if (!_.isNull(idProjects) && !_.isUndefined(idProjects)) {
            if (_.size(idProjects) > 0) {
                inSearchEmployeeProcessed();
                getEmployeeProcessed(idProjects);
            }
        }
    };

    _toUpdateAndProcessed = () => {
        const {
            idProjects,
            getEmployeeAndProcessed,
            inSearchEmployeeProcessed,
        } = this.props;
        if (!_.isNull(idProjects) && !_.isUndefined(idProjects)) {
            if (_.size(idProjects) > 0) {
                inSearchEmployeeProcessed();
                getEmployeeAndProcessed(idProjects);
            }
        }
    };

    _handleDetail = () => {
        this.setState({ open: true });
    };

    _handlerCloseDialog = () => {
        this.setState({ open: false });
    };

    _handleSendProcessed = () => {
        const {
            idProjects,
            sendEmployeeProcessed,
            inSearchEmployeeProcessed,
            toggleSnackbar,
        } = this.props;
        if (!_.isNull(idProjects) && !_.isUndefined(idProjects)) {
            if (_.size(idProjects) > 0) {
                inSearchEmployeeProcessed();
                sendEmployeeProcessed(idProjects)
                    .then(
                        (data) => {
                            if (
                                _.get(data, "error") ||
                                _.get(data, "payload.status") !== 200
                            ) {
                                toggleSnackbar(
                                    true,
                                    "Ha ocurrido un error al procesar las soluciones."
                                );
                            } else {
                                toggleSnackbar(
                                    true,
                                    "Las soluciones se enviaron correctamente para ser procesadas."
                                );
                            }
                        },
                        (reason) => {
                            toggleSnackbar(
                                true,
                                "Ha ocurrido un error al procesar las soluciones."
                            );
                        }
                    )
                    .catch((data) => {
                        toggleSnackbar(
                            true,
                            "Ha ocurrido un error al procesar las soluciones."
                        );
                    });
            }
        }
    };

    render() {
        const { processed, idProjects, inSearchProcessed } = this.props;
        const disableDetail =
            _.size(idProjects) === 1 ||
            _.size(_.get(processed, "details", [])) === 0;
        if (
            !_.isNull(processed) &&
            !_.isNull(idProjects) &&
            !_.isUndefined(idProjects)
        ) {
            if (_.size(idProjects) > 0) {
                const validProcessed = _.isEqual(
                    _.get(processed, "countSolution", 0),
                    _.get(processed, "countProcessed", 0)
                );
                const styleColumns = {
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                    textAlign: "justify",
                };
                return (
                    <Row style={{ margin: 5 }}>
                        <Col xs>
                            <div
                                style={{
                                    width: 250,
                                    minHeight: 60,
                                    padding: 10,
                                    background: "#F8F8FC",
                                    borderRadius: 13,
                                    margin: "0 auto",
                                }}
                            >
                                {inSearchProcessed ? (
                                    <CircularProgress
                                        size={45}
                                        thickness={3}
                                        style={{ margin: "8px 0px 0px 104px" }}
                                    />
                                ) : (
                                    <table
                                        style={{
                                            borderCollapse: "collapse",
                                            width: "100%",
                                            height: "100%",
                                        }}
                                    >
                                        <tbody>
                                            <tr>
                                                <td
                                                    rowSpan={2}
                                                    style={{ width: 52 }}
                                                >
                                                    <IconButton
                                                        tooltip="Actualizar"
                                                        tooltipPosition="top-center"
                                                        onClick={
                                                            this
                                                                ._toUpdateProcessed
                                                        }
                                                    >
                                                        <RefreshIcon />
                                                    </IconButton>
                                                </td>
                                                <td
                                                    rowSpan={2}
                                                    style={{ width: 52 }}
                                                >
                                                    {disableDetail ? (
                                                        <span />
                                                    ) : (
                                                        <IconButton
                                                            tooltip="Detalles"
                                                            tooltipPosition="top-center"
                                                            disabled={
                                                                disableDetail
                                                            }
                                                            onClick={
                                                                this
                                                                    ._handleDetail
                                                            }
                                                        >
                                                            <InfoIcon />
                                                        </IconButton>
                                                    )}
                                                </td>
                                                <td>
                                                    <h4
                                                        style={{
                                                            fontWeight: 400,
                                                            margin: 3,
                                                        }}
                                                    >
                                                        Soluciones:{" "}
                                                        <span
                                                            style={{
                                                                marginLeft: 6,
                                                            }}
                                                        >
                                                            {_.get(
                                                                processed,
                                                                "countSolution",
                                                                0
                                                            )}
                                                        </span>
                                                    </h4>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <h4
                                                        style={{
                                                            fontWeight: 400,
                                                            margin: 3,
                                                        }}
                                                    >
                                                        Procesadas:{" "}
                                                        <span
                                                            style={{
                                                                marginLeft: 1,
                                                            }}
                                                        >
                                                            {_.get(
                                                                processed,
                                                                "countProcessed",
                                                                0
                                                            )}
                                                        </span>
                                                    </h4>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    colSpan={3}
                                                    style={{
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    {_.isEqual(
                                                        _.get(
                                                            processed,
                                                            "countSolution",
                                                            0
                                                        ),
                                                        _.get(
                                                            processed,
                                                            "countProcessed",
                                                            0
                                                        )
                                                    ) ? (
                                                        <span />
                                                    ) : (
                                                        <AuthComponent
                                                            component={
                                                                <RaisedButton
                                                                    label="Procesar soluciones"
                                                                    secondary
                                                                    disabled={_.isEqual(
                                                                        _.get(
                                                                            processed,
                                                                            "countSolution",
                                                                            0
                                                                        ),
                                                                        _.get(
                                                                            processed,
                                                                            "countProcessed",
                                                                            0
                                                                        )
                                                                    )}
                                                                    style={{
                                                                        margin:
                                                                            "5px auto 10px auto",
                                                                    }}
                                                                    onClick={
                                                                        this
                                                                            ._toUpdateAndProcessed
                                                                    }
                                                                />
                                                            }
                                                            permission="backend/solutions:write_solutions_processed"
                                                            type="component"
                                                        />
                                                    )}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                            </div>
                            <Dialog
                                title="Detalle de soluciones procesadas"
                                titleStyle={{
                                    textAlign: "center",
                                    color: blue700,
                                }}
                                actions={[
                                    <FlatButton
                                        label="Aceptar"
                                        secondary
                                        keyboardFocused
                                        onClick={this._handlerCloseDialog}
                                    />,
                                ]}
                                modal={false}
                                contentStyle={{
                                    maxWidth: 600,
                                    minWidth: 300,
                                    textAlign: "justify",
                                }}
                                open={this.state.open}
                                onRequestClose={this._handlerCloseDialog}
                                autoScrollBodyContent
                            >
                                <Table
                                    selectable={false}
                                    headerStyle={{ marginTop: 3 }}
                                >
                                    <TableHeader
                                        displaySelectAll={false}
                                        adjustForCheckbox={false}
                                    >
                                        <TableRow>
                                            <TableHeaderColumn colSpan={3}>
                                                Proyecto
                                            </TableHeaderColumn>
                                            <TableHeaderColumn>
                                                Soluciones
                                            </TableHeaderColumn>
                                            <TableHeaderColumn>
                                                Procesadas
                                            </TableHeaderColumn>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody displayRowCheckbox={false}>
                                        {_.map(
                                            _.get(processed, "details", []),
                                            (detail, index) => (
                                                <TableRow
                                                    key={`detailDialogProcessed${index}`}
                                                    style={{
                                                        borderLeft: `5px solid ${getColorBorder(
                                                            _.get(
                                                                detail,
                                                                "countSolution",
                                                                0
                                                            ),
                                                            _.get(
                                                                detail,
                                                                "countProcessed",
                                                                0
                                                            )
                                                        )}`,
                                                    }}
                                                >
                                                    <TableRowColumn
                                                        colSpan={3}
                                                        style={styleColumns}
                                                    >
                                                        {_.get(
                                                            detail,
                                                            "name",
                                                            ""
                                                        )}
                                                    </TableRowColumn>
                                                    <TableRowColumn
                                                        style={styleColumns}
                                                    >
                                                        {_.get(
                                                            detail,
                                                            "countSolution",
                                                            0
                                                        )}
                                                    </TableRowColumn>
                                                    <TableRowColumn
                                                        style={styleColumns}
                                                    >
                                                        {_.get(
                                                            detail,
                                                            "countProcessed",
                                                            0
                                                        )}
                                                    </TableRowColumn>
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                            </Dialog>
                        </Col>
                    </Row>
                );
            }
            return <div />;
        }
        return <div />;
    }
}

function mapStateToProps({ employeesProcessed }) {
    return {
        processed: employeesProcessed.get("processed"),
        inSearchProcessed: employeesProcessed.get("inSearchProcessed"),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getEmployeeProcessed,
            getEmployeeAndProcessed,
            inSearchEmployeeProcessed,
            sendEmployeeProcessed,
            toggleSnackbar,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeesProcessed);
