import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {List, ListItem} from 'material-ui/List';
import Paper from 'material-ui/Paper';
import Divider from 'material-ui/Divider';
import {Col, Row} from 'react-flexbox-grid';
import map from 'lodash/map';
import last from 'lodash/last';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import AppBar from 'material-ui/AppBar';
import {Tabs, Tab} from 'material-ui/Tabs';
import SubArrowRight from 'material-ui/svg-icons/navigation/subdirectory-arrow-right';
import {loadSurveyMetadata, cleanState} from './editorDucks';
import EditorItemComponent from './editorItemComponent';
import {toggleSnackbar} from '../../actions/commons';
import {LBL_METADATA} from '../../constants/labels';
import ButtonBack from '../fields/buttonBack/buttonBack';

function mapInnerConditionElements(survey, grouper, item, index, list) {
    const conditionId = item._id;
    const SE_type = "SE";
    const EE_type = "EE";
    const ItemFormSE = EditorItemComponent(conditionId, item, SE_type, 100);
    const ItemFormEE = EditorItemComponent(conditionId, item, EE_type, 1);
    return (
        <div key={`indicator-metadata-sub-${index}`}>
            <ListItem
                primaryText={item.reportDescription}
                leftIcon={<SubArrowRight/>}
                primaryTogglesNestedList
                nestedItems={[
                    <Tabs>
                        <Tab label="Informe individual">
                            <ItemFormSE surveyId={survey} grouperId={grouper} conditionId={conditionId}/>
                        </Tab>
                        <Tab label="Informe empresarial">
                            <ItemFormEE surveyId={survey} grouperId={grouper} conditionId={conditionId}/>
                        </Tab>
                    </Tabs>
                ]}
            />
            {isEqual(last(list), item) ? <span/> : <Divider/>}
        </div>
    )
}

const mapInnerGrouperElements = surveyId => (item, index, list) => {
    return (
        <div key={`indicator-metadata-${index}`}>
            <ListItem
                primaryText={item.name}
                primaryTogglesNestedList
                nestedItems={map(item.conditions, mapInnerConditionElements.bind(undefined, surveyId, item._id))}
            />
            {isEqual(last(list), item) ? <span/> : <Divider/>}
        </div>
    )
};

class Editor extends Component {

    UNSAFE_componentWillMount() {
        this.props.cleanState();
    }

    componentWillUnmount() {
        this.props.cleanState();
    }

    componentDidMount() {
        const {loadSurveyMetadata, params: {survey}} = this.props;
        loadSurveyMetadata(survey);
    }

    UNSAFE_componentWillReceiveProps({status}) {
        const {toggleSnackbar} = this.props;
        if (status === "saving") {
            toggleSnackbar(true, LBL_METADATA.saving);
        } else if (status === "saved") {
            toggleSnackbar(true, LBL_METADATA.saved);
        } else if (status === "error") {
            toggleSnackbar(true, LBL_METADATA.error);
        }
    }

    render() {
        const {metadata, params: {survey}} = this.props;
        return (
            <Row>
                <Col md={8} mdOffset={2}>
                    <ButtonBack url="/admin/surveys/grid"/>
                    <Paper style={{marginTop: '30px'}}>
                        <AppBar showMenuIconButton={false} title="Configuración de condiciones de interpretación."/>
                        <List>
                            {
                                map(metadata, mapInnerGrouperElements(survey))

                            }
                            {
                                metadata.length === 0 ?
                                    <ListItem primaryText="No hay condiciones de interpretación definidas."/> :
                                    <span/>

                                    
                            }
                      
                        </List>
                    </Paper>
                </Col>
            </Row>
        );

    }
}

const mapStateToProps = ({metadataSurvey}) => {
    return {
        metadata: metadataSurvey.get("metadata"),
        status: metadataSurvey.get('status')
    }
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        loadSurveyMetadata,
        toggleSnackbar,
        cleanState
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Editor);
