import React, {Component} from 'react';
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn,TableFooter} from 'material-ui/Table';





import {Row, Col} from 'react-flexbox-grid';
import Paper from 'material-ui/Paper';

import TextField from 'material-ui/TextField';
import Toggle from 'material-ui/Toggle';
import RaisedButton from 'material-ui/RaisedButton';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FetchListParam } from '../../actions/info/list_info_form';

const style = {
        margin: 20,
        float: 'right'
    };
    const stylePaper = {
        marginTop: 20,
        marginBottom: 20
    };
    const styleTable = {
        margin: 'auto',
        width: '90%'
    };
    const styleButton = {
        margin: 12,
    };

const stylePopover = {
    padding: 20,
};

class ListInfo extends Component {
    UNSAFE_componentWillMount(){
        this.props.FetchListParam(this.props.params._id, this.props.params.id);
    }

    renderList(){
        if(this.props.list.data){
            return (
                <table style={styleTable}>
                    <tbody>
                        <tr>
                            <td colSpan='3'>
                                <h1 style={{textAlign: 'center', fontWeight: 400}}> {this.props.list.data.name}  </h1>
                            </td>
                        </tr>
                        <tr>
                            <td><strong>key</strong></td>
                            <td colSpan='2'>{this.props.employees.data.key}</td>
                        </tr>
                        <tr>
                            <td><strong>valor</strong></td>
                            <td colSpan='2'>{this.props.referees.data.value}</td>
                        </tr>
                        <tr>
                            <td>
                                <RaisedButton label="Actualizar" secondary style={styleButton} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            );
        }
    }

    render() {
        return (
          <Row>
              <Col xsOffset={2} xs={8}>
                  <Paper style={stylePaper}>
                      <Row>
                          <Col xs>
                              {this.renderList()}
                          </Col>
                      </Row>
                  </Paper>
              </Col>
          </Row>
        );
    }
}

function mapStateToProps(state){
    return  { list: state.list.all };
}

export default connect (mapStateToProps, { FetchListParam })(ListInfo);
