import React from "react";
import {connect} from "react-redux";
import {Row, Col} from "react-flexbox-grid";
import Paper from "material-ui/Paper";
import {Link} from "react-router-dom";
import {blue600} from "material-ui/styles/colors";
import {LBLUSERS} from "Labels";
import FloatingActionButtonTooltip from "../../components/fields/FloatingActionButtonTooltip/index.tsx";
import AuthComponent from "../../components/AuthComponent";
import SpinnerLoading from "../../components/SpinnerLoading/componentSpinner";
import UserList from "../../components/users/list/UserList";


const Users = () => {
    return (
        <Row>
            <AuthComponent
                component={
                    <Col xsOffset={1} xs={10}>
                        <Paper style={{marginTop: 20, marginBottom: 20}}>
                            <Row>
                                <Col xs>
                                    <div
                                        style={{
                                            width: "100%",
                                            background: blue600,
                                            paddingTop: 5,
                                            paddingBottom: 5,
                                            color: "#FFF",
                                        }}
                                    >
                                        <h1
                                            style={{
                                                textAlign: "center",
                                                fontWeight: 400,
                                            }}
                                        >
                                            {LBLUSERS.titleGrid}
                                        </h1>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs>
                                    <AuthComponent
                                        component={
                                            <FloatingActionButtonTooltip
                                                textTooltip={LBLUSERS.tooltips.btnCreate}
                                                component={Link}
                                                to="/admin/users/create"
                                            />
                                        }
                                        permission="backend/users:write"
                                        type="component"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs>
                                    <AuthComponent
                                        component={
                                            <UserList/>
                                        }
                                        permission="backend/users:write"
                                        type="component"
                                    />
                                </Col>
                            </Row>
                        </Paper>
                    </Col>
                }
                permission="backend/users:read"
                type="url"
            />
            <SpinnerLoading/>
        </Row>
    );
};

export default connect(null)(Users);
