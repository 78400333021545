import _ from 'lodash';
import { axiosAPI } from '../../middleware/api';

export const GET_EMPLOYEES_GRID = "GET_EMPLOYEE_GRID";
export const GET_ANSWER_EMPLOYEES_GRID = "GET_ANSWER_EMPLOYEES_GRID";
export const FILTER_EMPLOYEES_COMPANY = "FILTER_EMPLOYEES_COMPANY";
export const SELECT_ALL_EMPLOYEES = "SELECT_ALL_EMPLOYEES";
export const UNSELECT_ALL_EMPLOYEES = "UNSELECT_ALL_EMPLOYEES";
export const TOGGLE_ROW_EMPLOYEE_TABLE = "TOGGLE_ROW_EMPLOYEE_TABLE";
export const DELETE_EMPLOYEES = "DELETE_EMPLOYEES";
export const OPEN_MODAL_DELETE_MASIVE = "OPEN_MODAL_DELETE_MASIVE";
export const UNMOUNT_TABLE_EMPLOYEES = "UNMOUNT_TABLE_EMPLOYEES";

export function FetchEmployeesCompany(company) {

    const request = axiosAPI.get(`/employees/${company}`);

    return {
        type: GET_EMPLOYEES_GRID,
        payload: request
    }
}

export function filterEmployees(filteredEmployees){

    return {
        type: FILTER_EMPLOYEES_COMPANY,
        employees: filteredEmployees
    }
}

export function FetchAnswerEmployeesProjects(user, project, section) {

    const request = axiosAPI.get(`/answers/user/${user}/${project}/${section}`);

    return {
        type: GET_ANSWER_EMPLOYEES_GRID,
        payload: request
    }
}


export function toggleRowEmployeeTable(idEmployee) {
    return {
        type: TOGGLE_ROW_EMPLOYEE_TABLE,
        employee: idEmployee
    }
}

export function selectAll() {
    return {
        type: SELECT_ALL_EMPLOYEES
    }
}

export function unselectAll() {
    return {
        type: UNSELECT_ALL_EMPLOYEES
    }
}

export function unMountTableEmployees() {
  return {
      type: UNMOUNT_TABLE_EMPLOYEES
  }
}

export function deleteEmployeeMasive(employees) {

  const employeesToDelete = _.chain(employees)
                              .filter(employee=>{
                                  return _.get(employee, "selected");
                              })
                              .map(employee=>{
                                return _.get(employee, "_id");
                              })
                              .value();
    const request = axiosAPI.delete(`/employees`, {
                                                  data: {
                                                          "employees": employeesToDelete,
                                                           "type": 'logic'
                                                         }
                                                   });

    return {
        type: DELETE_EMPLOYEES,
        payload: request
    }
}

export function deleteEmployeeMasiveId(employees) {

    const request = axiosAPI.delete(`/employees`, {
                                                  data: {
                                                            "employees": employees,
                                                             "type": 'logic'
                                                         }
                                                   });

    return {
        type: DELETE_EMPLOYEES,
        payload: request
    }
}
