import React, {Component} from 'react';
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn,TableFooter} from 'material-ui/Table';





import {Row, Col} from 'react-flexbox-grid';
import Paper from 'material-ui/Paper';

import TextField from 'material-ui/TextField';
import Toggle from 'material-ui/Toggle';
import RaisedButton from 'material-ui/RaisedButton';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FetchSectionParam } from '../../actions/info/sections_info_form';

const style = {
        margin: 20,
        float: 'right'
    };
    const stylePaper = {
        marginTop: 20,
        marginBottom: 20
    };
    const styleTable = {
        margin: 'auto',
        width: '90%'
    };
    const styleButton = {
        margin: 12,
    };

const stylePopover = {
    padding: 20,
};

class SectionsInfo extends Component {
    UNSAFE_componentWillMount(){
        this.props.FetchSectionParam(this.props.params._id);
    }

    renderSection(){
        if(this.props.sections.data){
            return (
                <table style={styleTable}>
                    <tbody>
                        <tr>
                            <td colSpan='3'>
                                <h1 style={{textAlign: 'center', fontWeight: 400}}> {this.props.sections.data.title} </h1>
                            </td>
                        </tr>
                        <tr>
                            <td><strong>Orden</strong></td>
                            <td colSpan='2'>{this.props.consents.data.order}</td>
                        </tr>
                        <tr>
                            <td>
                                <RaisedButton label="Actualizar" secondary style={styleButton} />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='3'>
                                <Table onRowSelection={this._onRowSelection}>
                                    <TableHeader>
                                        <TableRow>
                                            <TableHeaderColumn>Título</TableHeaderColumn>
                                            <TableHeaderColumn>Nombre del Campo</TableHeaderColumn>
                                            <TableHeaderColumn>Tipo</TableHeaderColumn>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>
                                        {this.renderQuestions(this.props.questions.data.questions)}
                                    </TableBody>
                                </Table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            );
        }
    }

    renderQuestions(questions){
        return questions.data.map((question) => {
            return (
                <TableRow key={question._id}>
                    <TableRowColumn>{question.title}</TableRowColumn>
                    <TableRowColumn>{question.fieldName}</TableRowColumn>
                    <TableRowColumn>{question.type}</TableRowColumn>
                </TableRow>
            );
        });
    }

    render() {
        return (
          <Row>
              <Col xsOffset={2} xs={8}>
                  <Paper style={stylePaper}>
                      <Row>
                          <Col xs>
                              {this.renderSection()}
                          </Col>
                      </Row>
                  </Paper>
              </Col>
          </Row>
        );
    }
}

function mapStateToProps(state){
    return  { sections: state.sections.all };
}

export default connect (mapStateToProps, { FetchSectionParam })(SectionsInfo);
