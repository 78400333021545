import React from 'react'
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    TextField
} from "@material-ui/core";
import {Form, Formik} from "formik";
import {Col, Row} from "react-flexbox-grid";
import {makeStyles} from "@material-ui/core/styles";
import {useAddDatasetColumn} from "../../../data"
import {useNotifications} from "Notifications";
import * as Yup from "yup";
import OptionsSelector from "../../../../commons/select";
import definedSources, {columnOptionsType, metadata} from "./options";

const useStyles = makeStyles(theme => ({
    buttonContent: {
        marginTop: 36,
        marginBottom: 20,
    },
    formControl: {
        marginBottom: theme.spacing(2),
        minWidth: 200
    },
    helperTextColor: {
        color: "red"
    }
}))

type AddColumnProps = {
    open: boolean
    handleClose: () => void
    datasetId: string
}

interface FormValues {
    columnName: string
    columnType: string
    sources: any
}

const initialValues: FormValues = {
    columnName: '',
    columnType: '',
    sources: ''
}

const schema = Yup.object().shape({
    columnType: Yup.string()
        .required("Tipo es requerido"),
    columnName: Yup.string().when("columnType", {
        is: (val) => definedSources[val] === undefined,
        then: Yup.string().required("Nombre requerido")
    }),
    sources: Yup.string().when("columnType", {
        is: (val) => definedSources[val] !== undefined,
        then: Yup.string().required("Fuente de información requerida")
    })
});

/**
 * Component to add columns to dataset
 * @author Jhoan Lopez <jhoan.lopez@ias.com.co>
 * @param open
 * @param handleClose
 * @param datasetId
 * @constructor
 */
const AddColumn: React.FC<AddColumnProps> = ({open, handleClose, datasetId}) => {
    const classes = useStyles()
    const {sendNotification} = useNotifications();
    const {mutate, loading} = useAddDatasetColumn();

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="add-column-dialog" fullWidth maxWidth="sm">
                <DialogTitle id="form-dialog-title">Agregar columna</DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={schema}
                        onSubmit={(values) => {
                            let formValues = {}
                            if (definedSources[values.columnType] !== undefined) {
                                formValues = {
                                    ...values,
                                    columnName: `${metadata[values.columnType]} - ${metadata[values.sources]}`
                                }
                            } else {
                                formValues = {...values, sources: []}
                            }
                            mutate({variables: {request: {...formValues, datasetId}}})
                                .then(() => {
                                    handleClose();
                                    sendNotification('Se agrego la columna correctamente');
                                })
                                .catch(() => {
                                    sendNotification('Ocurrió un error al agregar la columna');
                                })
                        }}>
                        {({values, handleChange, touched, errors}) => {
                            return (
                                <Form>
                                    <Row>
                                        <OptionsSelector options={columnOptionsType} name="columnType" value={values.columnType}
                                                         label="Tipo" handleChange={handleChange} errors={errors}
                                                         touched={touched} errorText="Tipo requerido"/>
                                        {definedSources[values.columnType] !== undefined ?
                                            <OptionsSelector options={definedSources[values.columnType]}
                                                             name="sources" value={values.sources}
                                                             label="Fuente de información"
                                                             handleChange={handleChange} errors={errors}
                                                             touched={touched}
                                                             errorText="Fuente de información requerida"/> :
                                            <Col xs>
                                                <TextField
                                                    name="columnName"
                                                    label="Nombre"
                                                    value={values.columnName}
                                                    onChange={handleChange}
                                                    fullWidth
                                                    error={
                                                        touched.columnName && definedSources[values.columnType] === undefined &&
                                                        Boolean(errors.columnName)
                                                    }
                                                    helperText={
                                                        touched.columnName && errors.columnName && definedSources[values.columnType] === undefined
                                                    }
                                                />
                                            </Col>
                                        }
                                    </Row>
                                    <Row center="xs">
                                        <Col xs>
                                            <Button onClick={handleClose} className={classes.buttonContent}>
                                                Cancelar
                                            </Button>
                                        </Col>
                                        <Col xs>
                                            <Button
                                                type="submit"
                                                color="primary"
                                                className={classes.buttonContent}
                                                disabled={loading}
                                            >
                                                Guardar
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            )
                        }}
                    </Formik>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default AddColumn;