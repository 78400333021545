import PropTypes from "prop-types";
import React from "react";
import TableCell from "@material-ui/core/TableCell";
import _ from "lodash";
import IconButton from "@material-ui/core/IconButton";
import { connect } from "react-redux";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {
  toggleDialog as tg,
  selectUser as su,
} from "../../../actions/users/userDialogActions";

const UserActionColumn = (props) => {
  const { data, colSpan, toggleDialog, selectUser } = props;
  const cs = _.isUndefined(colSpan) ? 1 : colSpan;
  const handleViewDetailUser = () => {
    selectUser(data);
    toggleDialog(true);
  };
  return (
    <TableCell colSpan={cs}>
      <div style={{ position: "absolute", marginTop: -24, zIndex: 99 }}>
        <IconButton
          tooltip="Detalle"
          tooltipPosition="top-center"
          onClick={handleViewDetailUser}
        >
          <VisibilityIcon />
        </IconButton>
      </div>
    </TableCell>
  );
};

UserActionColumn.defaultProps = {
  colSpan: 0,
  extra: {},
};

UserActionColumn.propTypes = {
  data: PropTypes.shape({}).isRequired,
  colSpan: PropTypes.number,
  extra: PropTypes.shape({}),
  toggleDialog: PropTypes.func.isRequired,
  selectUser: PropTypes.func.isRequired,
};

export default connect(null, { toggleDialog: tg, selectUser: su })(
  UserActionColumn
);
