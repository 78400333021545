import React from "react";
import Container from "./components/container";
import ListDatasets from "./components/list";

const DataSet = () => {
  return (
    <Container title="Dataset" addButton>
      <ListDatasets />
    </Container>
  );
};

export default DataSet;
