import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../auth/context";

function PrivateRoute({ children, path }) {
  const auth = useAuth();
    return (
    <Route
      path={path}
        // @ts-ignore
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <Redirect
              // @ts-ignore
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

PrivateRoute.propTypes = {
  children: PropTypes.element.isRequired,
  path: PropTypes.string.isRequired,
};

export default PrivateRoute;
