import * as React from 'react';
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    selectFormControl: {
        margin: theme.spacing(1),
        minWidth: "50%",
    }
}))

type DocumentType = {
    value: string
    label: string
}

type AdaptiveSelectorProps = {
    label: string,
    value: string,
    handleChange: {
        (e: React.ChangeEvent<any>): void,
        <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any> ? void : ((e: (string | React.ChangeEvent<any>)) => void)
    }
    data: DocumentType[]
    name: string
    disabled?: boolean
    error?: boolean
}

/**
 * Select generic
 * @author Jhoan Lopez <jhoan.lopez@ias.com.co>
 * @param label
 * @param value
 * @param handleChange
 * @param data [{value: '', label: ''}]
 * @param name
 * @param disabled
 * @param error
 * @constructor
 */
const AdaptiveSelector: React.FC<AdaptiveSelectorProps> = ({
                                                               label,
                                                               value,
                                                               handleChange,
                                                               data,
                                                               name,
                                                               disabled,
                                                               error
                                                           }) => {
    const classes = useStyles();
    return (
        <FormControl className={classes.selectFormControl}>
            <InputLabel id="user-type-select-label">{label}</InputLabel>
            <Select
                labelId="select-user-type"
                id="user-type-select"
                name={name}
                value={value}
                onChange={handleChange}
                disabled={disabled}
                error={error}
            >
                {data.map((i) => (
                    <MenuItem value={i.value} key={`${i.value}-${i.label}`}>{i.label}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default AdaptiveSelector;