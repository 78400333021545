import PropTypes from "prop-types";
import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Row, Col } from "react-flexbox-grid";
import Paper from "material-ui/Paper";
import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { createSection } from "../actions/sections_form";
import {
  FetchSectionParam,
  CleanSectionParam,
  getAllQuestionsSurvey,
} from "../actions/info/sections_info_form";
import AutoComplete from "../components/functional/AutoComplete";
import {
  removeValueStore,
  addListValuesStore,
  removeListValuesStore,
  changePositionValueStore,
} from "../components/functional/actions";

import { toggleSnackbar } from "../actions/commons";
import SectionQuestionsList from "./sections/sectionQuestionsList";
import { LBLSURVEYS } from "../constants/labels";
import ButtonBack from "../components/fields/buttonBack/buttonBack";
import AuthComponent from "../components/AuthComponent";

const style = {
  margin: 20,
  float: "right",
};
const stylePaper = {
  marginTop: 20,
  marginBottom: 20,
};

class SectionsNews extends Component {
  constructor(props) {
    super(props);
    this.removeQuestions = this.removeQuestions.bind(this);
    this.addQuestions = this.addQuestions.bind(this);
    this.removeAllQuestions = this.removeAllQuestions.bind(this);
    this.upQuestions = this.upQuestions.bind(this);
    this.downQuestions = this.downQuestions.bind(this);
    this._saveSections = this._saveSections.bind(this);
  }

  UNSAFE_componentWillMount() {
    const {
      getAllQuestionsSurvey,
      FetchSectionParam,
      CleanSectionParam,
      resetForm,
      params,
    } = this.props;
    CleanSectionParam();
    getAllQuestionsSurvey(params._id);
    if (_.has(this, "props.params.section")) {
      resetForm();
      FetchSectionParam(params.section);
      this.addQuestions(params.section);
    } else {
      this.removeAllQuestions();
    }
  }

  _saveSections(formData) {
    const { createSection, toggleSnackbar, resetForm, params } = this.props;
    const modeEdit = _.has(params, "section");
    createSection(formData, modeEdit)
      .then(
        data => {
          if (_.get(data, "error") || _.get(data, "payload.status") !== 200) {
            toggleSnackbar(true, LBLSURVEYS.msg.errorSave);
          } else {
            resetForm();
            toggleSnackbar(true, LBLSURVEYS.msg.successSaveSection);
            this.props.history.push(
              `/admin/surveys/info/${this.props.params._id}`
            );
          }
        },
        reason => {
          toggleSnackbar(true, LBLSURVEYS.msg.errorSave);
        }
      )
      .catch(data => {
        toggleSnackbar(true, LBLSURVEYS.msg.errorSave);
      });
  }

  removeQuestions(index) {
    this.props.removeValueStore(index, "questions");
  }

  addQuestions(id) {
    const { addListValuesStore } = this.props;
    addListValuesStore(id, "questions");
  }

  removeAllQuestions() {
    this.props.removeListValuesStore("questions");
  }

  upQuestions(index) {
    this.props.changePositionValueStore(index, "questions", "up");
  }

  downQuestions(index) {
    this.props.changePositionValueStore(index, "questions", "down");
  }

  render() {
    const {
      fields: { _id, order, title, description, surveyId, collections },
      handleSubmit,
      questions,
      questionsId,
      params,
    } = this.props;
    const modeEdit = _.has(params, "section");
    return (
      <Row>
        <Col xs={12} mdOffset={2} md={8}>
          <Paper style={stylePaper}>
            <Row>
              <Col xsOffset={1} xs={10}>
                <ButtonBack
                  url={`/admin/surveys/info/${this.props.params._id}`}
                />
                <h1
                  style={{
                    textAlign: "center",
                    fontWeight: 400,
                  }}
                >
                  {modeEdit
                    ? LBLSURVEYS.sections.titleEdit
                    : LBLSURVEYS.sections.titleCreate}
                </h1>
                <form onSubmit={handleSubmit(this._saveSections)}>
                  <Row>
                    <Col xs>
                      <TextField
                        hintText=""
                        floatingLabelText={LBLSURVEYS.sections.title}
                        type="text"
                        fullWidth
                        errorText={
                          title.touched && title.error ? title.error : ""
                        }
                        {...title}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs>
                      <TextField
                        hintText=""
                        floatingLabelText={LBLSURVEYS.description}
                        multiLine
                        rows={3}
                        fullWidth
                        errorText={
                          description.touched && description.error
                            ? description.error
                            : ""
                        }
                        {...description}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} mdOffset={2} md={8} style={{ paddingTop: 60 }}>
                      <AutoComplete
                        placeholder={LBLSURVEYS.sections.searchQuestion}
                        subHeader={LBLSURVEYS.sections.resultFind}
                        coreName="questions"
                        storeName="questions"
                        fullWidth
                        arrayValidate={questionsId}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xsOffset={2} xs={8} />
                  </Row>
                  <Row>
                    <Col xsOffset={1} xs={10}>
                      <h2
                        style={{
                          textAlign: "center",
                          fontWeight: 400,
                          marginTop: 40,
                        }}
                      >
                        {LBLSURVEYS.sections.listsQuestions}
                      </h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <div>
                        {!questions.size && (
                          <h3 style={{ marginTop: 30, textAlign: "center" }}>
                            {LBLSURVEYS.sections.noValues}
                          </h3>
                        )}
                        <SectionQuestionsList
                          questions={questions}
                          upQuestions={this.upQuestions}
                          downQuestions={this.downQuestions}
                          removeQuestions={this.removeQuestions}
                        />
                      </div>
                    </Col>
                  </Row>
                  <AuthComponent
                    component={
                      <RaisedButton
                        type="submit"
                        label={
                          modeEdit
                            ? LBLSURVEYS.buttons.editSections
                            : LBLSURVEYS.buttons.saveSections
                        }
                        secondary
                        disabled={!questions.size}
                        style={style}
                      />
                    }
                    permission="backend/sections:write"
                    type="component"
                  />
                </form>
              </Col>
            </Row>
          </Paper>
        </Col>
      </Row>
    );
  }
}

const validate = values => {
  const errors = {};
  if (!values.title) {
    errors.title = LBLSURVEYS.validations.title;
  }
  if (!values.description) {
    errors.description = LBLSURVEYS.validations.description;
  }
  return errors;
};

function mapStateToProps({ autoComplete, sections, form }, ownProps) {
  if (_.has(ownProps, "params.section")) {
    const collections = autoComplete
      .get("questions")
      .toArray()
      .map(function (value) {
        const id = _.has(value, "_id._id")
          ? _.get(value, "_id._id")
          : _.get(value, "_id");
        const obj = _.omit(value, "_id");
        return _.set(obj, "_id", id);
      });
    // let title = _.isUndefined(_.get(sections, 'all.data.title')) ? "" : _.get(sections, 'all.data.title');
    // if(_.isEqual(_.get(form, 'SectionsNewForm._id.value')+"", ownProps.params.section+"")){
    //     if(!_.isUndefined(_.get(form, 'SectionsNewForm.title.value'))){
    //         if(!_.isEqual(_.get(form, 'SectionsNewForm.title.value'), "") && !_.isEqual(_.get(form, 'SectionsNewForm.title.value'), _.get(sections, 'all.data.title'))){
    //             title = _.get(form, 'SectionsNewForm.title.value');
    //         }
    //     }
    // }
    // let description = _.isUndefined(_.get(sections, 'all.data.description')) ? "" : _.get(sections, 'all.data.description');
    // if(_.isEqual(_.get(form, 'SectionsNewForm._id.value'), ownProps.params.section)){
    //     if(!_.isUndefined(_.get(form, 'SectionsNewForm.description.value'))){
    //         if(!_.isEqual(_.get(form, 'SectionsNewForm.description.value'), "")){
    //             if(!_.isEqual(_.get(form, 'SectionsNewForm.description.value'), _.get(sections, 'all.data.description'))){
    //                 description = _.get(form, 'SectionsNewForm.description.value');
    //             }
    //         }
    //     }
    // }
    return {
      questions: autoComplete.get("questions"),
      questionsId: sections.questionsId,
      initialValues: {
        surveyId: ownProps.params._id,
        _id: ownProps.params.section,
        order: _.get(sections, "all.data.order"),
        title: _.isUndefined(_.get(sections, "all.data.title"))
          ? ""
          : _.get(sections, "all.data.title"),
        description: _.isUndefined(_.get(sections, "all.data.description"))
          ? ""
          : _.get(sections, "all.data.description"),
        collections,
      },
    };
  }
  return {
    questions: autoComplete.get("questions"),
    questionsId: sections.questionsId,
    initialValues: {
      surveyId: ownProps.params._id,
      _id: null,
      title: !_.isUndefined(
        _.get(form, "SectionsNewForm.title.value") ||
          !_.isEqual(`${_.get(form, "SectionsNewForm.title.value")}`, "")
      )
        ? _.get(form, "SectionsNewForm.title.value")
        : "",
      description: !_.isUndefined(
        _.get(form, "SectionsNewForm.description.value") ||
          !_.isEqual(`${_.get(form, "SectionsNewForm.description.value")}`, "")
      )
        ? _.get(form, "SectionsNewForm.description.value")
        : "",
      order: 0,
      collections: autoComplete.get("questions").toArray(),
    },
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createSection,
      removeValueStore,
      addListValuesStore,
      removeListValuesStore,
      changePositionValueStore,
      toggleSnackbar,
      FetchSectionParam,
      CleanSectionParam,
      getAllQuestionsSurvey,
    },
    dispatch
  );
}

SectionsNews.propTypes = {
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm(
  {
    form: "SectionsNewForm",
    fields: [
      "order",
      "_id",
      "title",
      "description",
      "surveyId",
      "collections[].title",
      "collections[].type",
      "collections[]._id",
      "collections[].sizeList",
      "collections[].srtSubQuestions",
    ],
    validate,
  },
  mapStateToProps,
  mapDispatchToProps
)(SectionsNews);
