import _ from 'lodash';
import { GET_GROUPERS_SURVEY,DELETE_GROUPER_SURVEY,GET_TREE_GROUPERS_SURVEY } from './constants';
import { SAVE_NAME_GROUPER_GROUPER } from "../ModalFormGroupers/constants";

const INITIAL_STATE = { groupers: [], treeGroupers:[] };

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_GROUPERS_SURVEY:
            return _.assign({}, state, {
                groupers: action.payload.data
            });
            break;
        case GET_TREE_GROUPERS_SURVEY:
            return _.assign({}, state, {
                treeGroupers: action.payload.data
            });
            break;
        case DELETE_GROUPER_SURVEY:
            const arrayGroupers = _.get(state, "groupers");
            if (_.get(action, "payload.data.deleted")) {
                const newsGroupers = _.filter(arrayGroupers, (grouper) => !_.isEqual(_.get(grouper, '_id'), _.get(action, 'meta.idGrouper')));
                return _.assign({}, state, {
                    groupers: newsGroupers
                });
            } 
                return state;
            
            break;
        case SAVE_NAME_GROUPER_GROUPER:
            if (_.get(action, 'payload.data.error') || (_.get(action, 'payload.status') !== 200)) {
                return state;
            } 
                const newGrouperList = _.map(state.groupers, item => {
                    if(_.isEqual(item._id, action.meta.id)){
                        return _.chain(item)
                                .set('name', action.meta.name)
                                .set('visible', !!_.isEqual(action.meta.visible, "true"))
                                .value();
                    } 
                        return item;
                    
                });
                return _.assign({}, state, {
                    groupers: newGrouperList
                });
            
            break;
        default:
            return state;
    }
}
