import React from "react";
import PropTypes from "prop-types";
import { CircularProgress } from "@material-ui/core";
import Container from "./container";
import Form from "./form";
import { useQuestion } from "./api-operations";

const EditForm = ({ id }) => {
    const { isLoading, data } = useQuestion(id);
    return (
        <Container editMode>
            {isLoading ? (
                <div>
                    <CircularProgress />
                    <span>Cargando pregunta ...</span>
                </div>
            ) : (
                <Form initialValues={data} editMode />
            )}
        </Container>
    );
};

const QuestionForm = (props) => {
    const {
        params: { _id: id },
    } = props;
    if (id) {
        return <EditForm id={id} />;
    }
    return (
        <Container editMode={false}>
            <Form />
        </Container>
    );
};

QuestionForm.propTypes = {
    params: PropTypes.shape({}).isRequired,
};

export default QuestionForm;
