import { axiosAPI } from '../../middleware/api';

export const GET_CONSENTS_GRID = "GET_CONSENTS_GRID";

export function FetchConsent() {

    const request = axiosAPI.get(`/consents`);

    return {
        type: GET_CONSENTS_GRID,
        payload: request
    }
}
