import { axiosAPI } from '../../../middleware/api';

export const GET_LIST_SECTIONS = "GET_LIST_SECTIONS";
export const CLEAN_LIST_SECTIONS = "CLEAN_LIST_SECTIONS";

/**
 *
 * @param idSurveys
 */
export function getListSections(idSurveys) {
    const request = axiosAPI.get(`/surveys/sections/${idSurveys}`);

    return {
        type: GET_LIST_SECTIONS,
        payload: request
    };
}

export function cleanListSections() {
    return {
        type: CLEAN_LIST_SECTIONS
    };
}
