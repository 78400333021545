import React from "react";

import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

type Props = {
    open: boolean;
    onClose: () => void;
    children: JSX.Element;
};
function ChangePasswordModal({ open, onClose, children }: Props) {
    return (
        <Dialog
            onClose={onClose}
            aria-labelledby="change-password-title"
            open={open}
            maxWidth="sm"
            fullWidth={true}

        >
            <DialogTitle id="change-password-title"
                         style={{
                             marginBottom:-30,
                         }}>Cambiar contraseña</DialogTitle>
            <DialogContent>{children}</DialogContent>
        </Dialog>
    );
}

export default ChangePasswordModal;
