import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { Row, Col, Grid } from "react-flexbox-grid";
import {
  getCompanyInfoFromPersonId,
  loadCompanyProjects,
  cleanCompanyProjects,
  setCompanyIdUser,
} from "./actions";
import AppBarCompany from "./appBarComponent";

const style = {
  paddingTop: 50,
};

class dashboardCompany extends Component {
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillMount() {
    const {
      profile,
      getCompanyInfoFromPersonId,
      loadCompanyProjects,
      cleanCompanyProjects,
      setCompanyIdUser,
    } = this.props;
    const personId = _.get(profile, "person._id");
    cleanCompanyProjects();
    if (personId) {
      getCompanyInfoFromPersonId(personId).then((response) => {
        const companyId = _.get(response, "payload.data._id");
        setCompanyIdUser(companyId);
        loadCompanyProjects(companyId);
      });
    }
  }

  render() {
    return (
      <div>
        <Grid fluid>
          <Row>
            <AppBarCompany />
          </Row>
          <Row style={style}>
            <Col xs>{this.props.children}</Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCompanyInfoFromPersonId,
      loadCompanyProjects,
      cleanCompanyProjects,
      setCompanyIdUser,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(dashboardCompany);
