import PropTypes from "prop-types";
import React, { Component } from "react";
import TableCell from "@material-ui/core/TableCell";
import _ from "lodash";
import { Link } from "react-router-dom";
import IconButton from "material-ui/IconButton";
import { connect } from "react-redux";
import PollIcon from "material-ui/svg-icons/social/poll";
import SettingsIcon from "material-ui/svg-icons/action/settings";
import AuthComponent from "../../AuthComponent";
import { LBLPROJECTS } from "../../../constants/labels";
import DeleteEntityModal from "../../deleteEntityModal/component";
import { deleteProject } from "../../../actions/projects_form";
import { toggleSnackbar } from "../../../actions/commons";
import { removeRecord } from "../actions";

class ProjectActionColumn extends Component {
  constructor(props) {
    super(props);
    this.handleTouchDelete = this.handleTouchDelete.bind(this);
  }

  handleTouchDelete(data) {
    const { toggleSnackbar, deleteProject, removeRecord } = this.props;
    const idProject = _.get(data, "_id");
    deleteProject(idProject)
      .then(
        (data) => {
          if (_.get(data, "error") || _.get(data, "payload.status") !== 200) {
            toggleSnackbar(true, LBLPROJECTS.msg.errorProjectDelete);
          } else {
            removeRecord(idProject, "_id");
            toggleSnackbar(true, LBLPROJECTS.msg.successProjectDelete);
          }
        },
        (reason) => {
          toggleSnackbar(true, LBLPROJECTS.msg.errorProjectDelete);
        }
      )
      .catch((data) => {
        toggleSnackbar(true, LBLPROJECTS.msg.errorProjectDelete);
      });
  }

  render() {
    const { data, colSpan, extra } = this.props;
    const cs = _.isUndefined(colSpan) ? 1 : colSpan;
    return (
      <TableCell colSpan={cs}>
        <div style={{ position: "absolute", marginTop: -24, zIndex: 99 }}>
          <AuthComponent
            component={
              <Link
                to={`/admin/projects/advance/adm/${_.get(
                  extra,
                  "company"
                )}/${_.get(data, "_id")}`}
              >
                <IconButton
                  tooltip="Estado actual"
                  tooltipPosition="top-center"
                >
                  <PollIcon />
                </IconButton>
              </Link>
            }
            permission="backend/projects:read"
            type="component"
          />
          <AuthComponent
            component={
              <Link
                to={`/admin/projects/info/adm/${_.get(data, "_id")}/${_.get(
                  extra,
                  "company"
                )}`}
              >
                <IconButton
                  tooltip={LBLPROJECTS.tooltips.btnView}
                  tooltipPosition="top-center"
                >
                  <SettingsIcon />
                </IconButton>
              </Link>
            }
            permission="backend/projects:write"
            type="component"
          />
          <AuthComponent
            component={
              <DeleteEntityModal
                tooltip={LBLPROJECTS.tooltips.btnDelete}
                title={LBLPROJECTS.titleDelete}
                message={LBLPROJECTS.msg.deleteModal(_.get(data, "name"))}
                fn={this.handleTouchDelete}
                args={[data]}
              />
            }
            permission="backend/projects:write"
            type="component"
          />
        </div>
      </TableCell>
    );
  }
}

ProjectActionColumn.propTypes = {
  data: PropTypes.object.isRequired,
  colSpan: PropTypes.number,
  extra: PropTypes.object,
};

export default connect(null, { deleteProject, toggleSnackbar, removeRecord })(
  ProjectActionColumn
);
