import Immutable from "immutable";
import _ from "lodash";
import * as actions from "./constants";

const initialState = Immutable.Map({
    persons: [],
    openModal: false,
    type: null,
    companyId: null,
});

export default function(state = initialState, action) {
    switch (action.type) {
        case actions.OPEN_MODAL_SEND_EMAIL:
            return Immutable.Map({
                openModal: true,
                persons: _.get(action, "people", []),
                type: _.get(action, "meta.type", null),
                companyId: null,
            });
        case actions.OPEN_MODAL_SEND_EMAIL_ID:
            return Immutable.Map({
                openModal: true,
                persons: _.get(action, "payload.data", []),
                type: _.get(action, "meta.type", null),
                companyId: null,
            });
        case actions.OPEN_MODAL_SEND_EMAIL_USER:
            return Immutable.Map({
                openModal: true,
                persons: _.get(action, "people", []),
                type: _.get(action, "meta.type", null),
                companyId: _.get(action, "meta.companyId", null),
            });
        case actions.CLOSE_MODAL_SEND_EMAIL:
            return state.withMutations(map => {
                map.set("openModal", false);
                map.set("companyId", null);
            });
        default:
            return state;
    }
}
