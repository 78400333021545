import { axiosAPI } from '../../middleware/api';

export const GET_LISTMASTER_GRID = "GET_LISTMASTER_GRID";
export const GET_LISTMASTER_AND_LIST_VALUES = "GET_LISTMASTER_AND_LIST_VALUES";
export const GET_LIST_VALUES_LISTMASTER = "GET_LIST_VALUES_LISTMASTER";

export function FetchListMaster() {
    const request = axiosAPI.get(`/listmaster`);

    return {
        type: GET_LISTMASTER_GRID,
        payload: request
    }
}

export function FetchListMasterAndListValuesParam(id) {
    const request = axiosAPI.get(`/listmaster/one/${id}`);

    return {
        type: GET_LISTMASTER_AND_LIST_VALUES,
        payload: request
    }
}

export function loadListValuesMaster(id) {
    const request = axiosAPI.get(`/list/${id}`);

    return {
        type: GET_LIST_VALUES_LISTMASTER,
        payload: request
    }
}
