import axios from "axios";
import _ from "lodash";
import Auth from "@aws-amplify/auth";

import { API_URL, API_URL_V2, API_CGR_AUTH } from "../constants/index";

const { localStorage } = window;

const config = {
  baseURL: API_URL,
  timeout: 50500,
  insecureHTTPParser: true
};

const configV2 = {
  baseURL: API_URL_V2,
  timeout: 50500,
  insecureHTTPParser: true
};

const configAuth = {
  baseURL: API_URL,
  insecureHTTPParser: true
};

const configCgrAuth = {
  baseURL: API_CGR_AUTH,
  insecureHTTPParser: true
};

function equalTokens(newOne) {
  return _.isEqual(localStorage.getItem("authToken"), newOne);
}

function isFromAuthentication(path) {
  return _.endsWith(path, "users/authentication");
}

function onRejected(err) {
  const errorObject = { ...err };
  const requestToken = _.get(errorObject, "config.headers.authorization");
  const errorStatus = _.get(errorObject, "response.status");
  const url = _.get(errorObject, "config.url");
  const sameToken = !equalTokens(requestToken);
  const fromAuthentication = isFromAuthentication(url);
  if (errorStatus === 401) {
    if (fromAuthentication) {
      return Promise.reject(err);
    }
    if (sameToken) {
      return axios.request(errorObject.config);
    }
    /* store.dispatch(
      toggleSnackbar(
        true,
        "Parece que tu sesión ha caducado. Ingresa de nuevo para continuar."
      )
    ); */
    return Promise.reject(err);
  }
  return Promise.reject(err);
}

async function amplifyAdapter(requestConfig) {
  const session = await Auth.currentSession();
  const id = session.getIdToken();
  const token = session.getAccessToken();
  return {
    ...requestConfig,
    headers: {
      ...requestConfig.headers,
      Authorization: `Bearer ${token.getJwtToken()}`,
      "x-id-token": id.getJwtToken(),
    },
  };
}

export const axiosAnon = axios.create(configV2);
export const axiosAPI = axios.create(config);
export const axiosAuth = axios.create(configAuth);
export const axiosApiV2 = axios.create(configV2);
export const authClient = axios.create(configCgrAuth);

axiosAPI.interceptors.request.use(amplifyAdapter, onRejected);
axiosApiV2.interceptors.request.use(amplifyAdapter, onRejected);
axiosAuth.interceptors.request.use(amplifyAdapter, onRejected);
authClient.interceptors.request.use(amplifyAdapter, onRejected);
axiosAnon.interceptors.request.use(amplifyAdapter, onRejected);
