import React from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

import {
    passwordInvalidMessage,
    passwordRegex,
} from "../../../../auth/commons";

const useToolbarStyles = makeStyles((theme) => ({
    items: {
        margin: 5,
    },
    botton: {
        marginTop: 25,
    }


}));

const validationSchema = Yup.object().shape({
    currentPassword: Yup.string()
        .matches(passwordRegex, passwordInvalidMessage)
        .required("Contraseña actual requerida"),
    password: Yup.string()
        .matches(passwordRegex, passwordInvalidMessage)
        .required("Contraseña requerida"),
    confirmPassword: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Las contraseñas deben coincidir"
    ),
});

export type PasswordFormValues = {
    currentPassword: string;
    password: string;
    confirmPassword: string;
};

const initialValues: PasswordFormValues = {
    currentPassword: "",
    password: "",
    confirmPassword: "",
};

type ChangePasswordProps = {
    onSubmit: (e: PasswordFormValues) => Promise<any>;
};

const ChangePasswordForm = ({ onSubmit }: ChangePasswordProps) => {
    const { values, handleChange, handleBlur, handleSubmit, errors, touched } =
        useFormik<PasswordFormValues>({
            initialValues,
            validationSchema,
            onSubmit: (formValues, helpers) => {
                return onSubmit(formValues).then(() => {
                    helpers.resetForm({
                        values: initialValues,
                    });
                });
            },
        });
    const styles = useToolbarStyles();

    return (
        <form onSubmit={handleSubmit}>
            <div className="flex flex-col p-5">
                <TextField
                    className={styles.items}
                    placeholder=""
                    label="Contraseña actual"
                    type="password"
                    name="currentPassword"
                    error={
                        touched.currentPassword && errors.currentPassword !== undefined
                    }
                    helperText={
                        touched.currentPassword && errors.currentPassword
                            ? errors.currentPassword
                            : ""
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.currentPassword}
                />
                <TextField
                    className={styles.items}
                    placeholder=""
                    label="Nueva contraseña"
                    type="password"
                    name="password"
                    error={touched.password && errors.password !== undefined}
                    helperText={
                        touched.password && errors.password ? errors.password : ""
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                />
                <TextField
                    className={styles.items}
                    label="Confirmar contraseña"
                    type="password"
                    name="confirmPassword"
                    error={
                        touched.confirmPassword && errors.confirmPassword !== undefined
                    }
                    helperText={
                        touched.confirmPassword && errors.confirmPassword
                            ? errors.confirmPassword
                            : ""
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirmPassword}
                />
                <Button
                    className={styles.botton}
                    variant="contained"
                    color="primary"
                    type="submit"
                >
                    Cambiar contraseña
                </Button>
            </div>
        </form>
    );
};

export default ChangePasswordForm;
