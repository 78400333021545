import React from "react";
import {
    Table,
    TableCell,
    TableRow,
    TableFooter,
    TableHead,
    TableBody,
    TableContainer,
    Button, createStyles, withStyles, Theme
} from '@material-ui/core'
import {grey} from '@material-ui/core/colors';
import {useListDatasets} from "../../data";
import DatasetRow from "./datasetRow";

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: grey[100],
        },
        body: {
            fontSize: 14,
        },
    }),
)(TableCell);

/**
 * Component for the list of datasets
 * @author Jhoan Lopez <jhoan.lopez@ias.com.co>
 * @constructor
 */
const ListDatasets = () => {
    const {data, loadMore} = useListDatasets();

    return (
        <TableContainer>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell align="center">Nombre</StyledTableCell>
                        <StyledTableCell align="center">Descripción</StyledTableCell>
                        <StyledTableCell align="center">Fecha de creación</StyledTableCell>
                        <StyledTableCell align="center">Fecha de actualización</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.listDatasets.items.map((item) => (
                        <DatasetRow item={item} key={item.datasetId}/>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell align="center" className="m-2" colSpan={6}>
                            <Button
                                color="primary"
                                variant="outlined"
                                onClick={() => {
                                    loadMore();
                                }}
                            >
                                Cargar más
                            </Button>
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>

    );
};

export default ListDatasets;
