import { axiosAPI } from '../../middleware/api';

export const POST_EMPLOYEE_INFO = "POST_EMPLOYEE_INFO";

export function FetchEmployeeParam(_id, id) {

    const request = axiosAPI.get(`/employees/${_id}/${id}`);

    return {
        type: POST_EMPLOYEE_INFO,
        payload: request
    }
}
