import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {RadioButton, RadioButtonGroup} from 'material-ui/RadioButton';
import {connect} from 'react-redux';
import _ from 'lodash';
import { LBLSURVEYS } from '../../../../constants/labels';

class QuestionListGroup extends Component {

    constructor(props) {
        super(props);
        this._onChange = this._onChange.bind(this);
    }

    _onChange(e, value) {
        this.props._handlerUpdateValue(e, value);
    }

    renderOptions(options, itemList) {
        const widthAnswers = `${100 / _.size(options)  }%`;
        let array;
        if(_.isEqual(itemList, 'lowerCase')){
            array = LBLSURVEYS.arrays.lowercase;
        } else if(_.isEqual(itemList, 'upperCase')){
            array = LBLSURVEYS.arrays.uppercase;
        } else {
            array = [];
        }
        if(options instanceof Array){
            const OptionList = _.map(options, function (op){
                return _.set(op, 'key', parseInt(_.get(op, 'key')));
            });
            return _.map(_.orderBy(OptionList, 'key', 'asc'), (option, position) => {
                const enumeration = _.size(array) !== 0
                                        ? _.size(array) <= position ? "" : `${array[position]  }.`
                                        : _.isEqual(itemList, 'numeric')
                                            ? `${position + 1  }.`
                                            : "";
                return (
                    <RadioButton
                        value={option._id}
                        label={`${enumeration} ${option.value}`}
                        key={option._id}
                        style={{
                            marginBottom: 5,
                            width: widthAnswers,
                            minWidth: 120,
                            display: 'inline-block'
                        }}
                    />
                );
            });
        }
    }

    render() {
        const {
            question,
            valueAnswer,
            index,
            valueQuestion
        } = this.props;
        const styleWidthContentTitle2 = {
                  borderTop: 1,
                  borderTopStyle: 'solid',
                  borderTopColor: "#eee",
                  paddingTop: 10,
                  paddingBottom: 0,
                  verticalAlign: 'center'
              };
        return (
            <tr>
                <td style={styleWidthContentTitle2}>
                    <h3 style={{fontWeight: 400}}>{index}. </h3>
                </td>
                <td style={styleWidthContentTitle2}>
                    <RadioButtonGroup
                        name={question._id}
                        onChange={this._onChange}
                        valueSelected={valueQuestion}
                    >
                        {this.renderOptions(valueAnswer, _.get(question, 'itemList'))}
                    </RadioButtonGroup>
                </td>
            </tr>
        );
    }
}

QuestionListGroup.propTypes = {
    question: PropTypes.object.isRequired,
    valueAnswer: PropTypes.array.isRequired
};

function mapStateToProps({ questionsSurvey }, ownerProps) {
    return {
        valueQuestion: _.get(questionsSurvey.get('answered').toObject(), _.get(ownerProps, 'idQuestion'))
    };
}

export default connect(mapStateToProps)(QuestionListGroup);
