import React, {Component} from 'react';
import IconButton from 'material-ui/IconButton';
import UpIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-up';
import DownIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-down';
import {TableRowColumn, TableRow} from 'material-ui/Table';
import DeleteEntityModal from '../../components/deleteEntityModal/component';
import {LBLSURVEYS} from '../../constants/labels';

class sectionItem extends Component{
    constructor(props){
        super(props);
    }

    render() {
        const {
            question,
            questionsSize,
            index,
            titleIndex,
            upQuestions,
            downQuestions,
            removeQuestions
        } = this.props;
        const configDeleteModal = {
            display: "table-cell"
        };
        return (
            <TableRow>
                <TableRowColumn colSpan={2} style={{whiteSpace: 'normal'}}>
                    <h3 style={{fontWeight: 200}}>{titleIndex}. {question.title}</h3>
                </TableRowColumn>
                <TableRowColumn style={{whiteSpace: 'normal'}}>
                    <div style={{position: 'absolute', marginTop: -24, zIndex: 99}}>
                        <IconButton
                            children={<UpIcon/>}
                            disabled={index === 0}
                            tooltip="Subir"
                            tooltipPosition="top-center"
                            onClick={() => {upQuestions(index) }}
                        />
                        <IconButton
                            children={<DownIcon/>}
                            tooltip="Bajar"
                            tooltipPosition="top-center"
                            disabled={index === questionsSize - 1}
                            onClick={() => {downQuestions(index) }}
                        />
                        <DeleteEntityModal
                            tooltip={LBLSURVEYS.tooltips.btnDelete}
                            title={LBLSURVEYS.titleDeleteSectionQuestion}
                            message={LBLSURVEYS.msg.deleteModalSectionQuestion(question.title)}
                            fn={() => { removeQuestions(index) }}
                            args={[question]}
                            style={configDeleteModal}
                        />
                    </div>
                </TableRowColumn>
            </TableRow>
        );
    }
}

export default sectionItem;
