import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { List, ListItem } from "material-ui/List";
import _ from "lodash";
import { Row, Col } from "react-flexbox-grid";
import Paper from "material-ui/Paper";
import Subheader from "material-ui/Subheader";
import Divider from "material-ui/Divider";
import { blue700 } from "material-ui/styles/colors";
import CircularProgress from "material-ui/CircularProgress";
import { getSurveyMetadata } from "./actions";
import ButtonBack from "../../fields/buttonBack/buttonBack";
import AuthComponent from "../../AuthComponent";

const processing = (
  <Row>
    <Col xs={4} xsOffset={4} style={{ marginTop: 150 }}>
      <CircularProgress size={120} thickness={6} />
      <Subheader style={{ textAlign: "center" }}>
        Cargando, por favor espere...
      </Subheader>
    </Col>
  </Row>
);

const getOrderForData = (type) => {
  switch (type) {
    case "sumGrouper":
      return 1;
    case "freqGrouper":
      return 2;
    case "question":
      return 0;
    default:
      return 99;
  }
};

const cleanAndPrepareData = (data, event, disabled) =>
  _.chain(data)
    .map((item) =>
      _.assign({}, item, {
        order: getOrderForData(_.get(item, "entityType")),
      })
    )
    .filter((item) => !_.isEqual(item.entityType, "meta"))
    .orderBy(["order", "entityName"])
    .map((item, idx) => (
      <div key={item.entityId} style={{ borderRight: "1px solid #ccc" }}>
        <ListItem
          disabled={disabled}
          value={idx}
          primaryText={item.entityName}
          onClick={event(item)}
        />
        <Divider />
      </div>
    ))
    .value();

class DashboardResults extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: [],
      ws: new WebSocket(""),
      attempts: 1,
      processing: false,
    };
    this.handleRequest = this.handleRequest.bind(this);
    this.setupWebsocket = this.setupWebsocket.bind(this);
  }

  generateInterval(k) {
    return Math.min(30, 2 ** k - 1) * 1000;
  }

  logging(logline) {
    console.log(logline);
  }

  setupWebsocket() {
    const websocket = this.state.ws;

    websocket.onopen = () => {
      this.logging("Websocket connected");
    };

    websocket.onmessage = (evt) => {
      this.onMessage(JSON.parse(evt.data));
    };

    websocket.onclose = () => {
      this.logging("Websocket disconnected");

      if (this.props.reconnect) {
        const time = this.generateInterval(this.state.attempts);
        setTimeout(() => {
          this.setState({ attempts: this.state.attempts++ });
          this.setupWebsocket();
        }, time);
      }
    };
  }

  UNSAFE_componentWillMount() {
    const {
      params: { surveyId },
      getSurveyMetadata,
    } = this.props;
    getSurveyMetadata(surveyId);
    this.setupWebsocket();
  }

  componentWillUnmount() {
    const websocket = this.state.ws;
    websocket.close();
  }

  handleRequest(element, event) {
    this.setState({
      processing: true,
      selected: [],
    });
    const newMessage = {
      name: "calculate_family",
      entity: _.get(element, "entityId"), // GrouperId or questionId
      type: _.get(this, "props.params.projectId"), // projectId
      status: _.get(this, "props.params.surveyId"), // surveyId
    };
    this.state.ws.send(JSON.stringify(newMessage));
  }

  onMessage(data) {
    if (_.isArray(data)) {
      this.setState({
        selected: data,
        processing: false,
      });
    }
  }

  render() {
    const { data, params } = this.props;
    let urlBack = `/admin`;
    if (_.isEqual(_.get(params, "profile"), "comp")) {
      urlBack = `/dashboard/company/project/advance/comp/${params.company}/${params.projectId}`;
    } else {
      urlBack = `/admin/projects/advance/${_.get(params, "profile")}/${
        params.company
      }/${params.projectId}`;
    }
    return (
      <AuthComponent
        component={
          <Row style={{ marginTop: 20 }}>
            <Col md={10} mdOffset={1}>
              <ButtonBack url={urlBack} />
              <Paper>
                <Row>
                  <Col md={4}>
                    <List
                      style={{
                        height: "700px",
                        overflowY: "auto",
                        overflowX: "hidden",
                      }}
                    >
                      <Subheader
                        style={{
                          background: blue700,
                          color: "white",
                        }}
                      >
                        Seleccione...
                      </Subheader>
                      {cleanAndPrepareData(
                        data,
                        _.curry(this.handleRequest),
                        this.state.processing
                      )}
                    </List>
                  </Col>
                  <Col md={8} />
                </Row>
              </Paper>
            </Col>
          </Row>
        }
        permission="backend/projects:read"
        type="url"
      />
    );
  }
}

function mapStateToProps({ surveyResults }) {
  return {
    data: surveyResults.get("data"),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getSurveyMetadata,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardResults);
