import Immutable from 'immutable';
import _ from 'lodash';
import { GET_DATA_PROJECT_ADVANCE, DATA_PROJECT_ADVANCE_IN_SEARCH } from './constants';

const initialState = Immutable.Map({
    advance: null,
    inSearch: true
});

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_DATA_PROJECT_ADVANCE:
            return state.withMutations(function(state) {
                        state.set("advance", _.get(action, 'payload.data', null));
                        state.set("inSearch", false);
                    });
        case DATA_PROJECT_ADVANCE_IN_SEARCH:
            return state.set("inSearch", true);
        default:
            return state;
    }
}
