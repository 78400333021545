import React from 'react';
import { blue700 } from 'material-ui/styles/colors';
import Paper from 'material-ui/Paper';
import { Row, Col } from 'react-flexbox-grid';
import PropTypes from 'prop-types';
import ButtomBack from '../fields/buttonBack/buttonBack';

const styles = {
  stylePaper: {
    marginTop: 20,
    marginBottom: 20,
    textAlign: 'center',
    minHeight: '85vh',
  },
  contentTitle: {
    width: '100%',
    background: blue700,
    paddingTop: 5,
    paddingBottom: 5,
    color: '#FFF',
  },
  title: {
    textAlign: 'center',
    fontWeight: 400,
  },
  rowTitle: {
    textAlign: 'center',
  },
  flexContentMain: {
    display: 'flex',
    minHeight: '85vh',
  },
  contentMain: {
    minHeight: '85vh',
  },
};

/**
 * @author Jhoan Lopez <jhoanlt19@gmail.com>
 * @param {*} param0
 */
const ContentMain = ({
  children,
  title,
  flex,
  columns = 12,
  buttomBack = false,
  urlBack = '',
}) => {
  return (
    <Row>
      {buttomBack && (
        <Col xs={12} md={1}>
          <ButtomBack url={urlBack} />
        </Col>
      )}
      <Col xs={12} md={columns}>
        <Paper style={styles.stylePaper}>
          <div style={styles.contentTitle}>
            <h1 style={styles.title}>{title}</h1>
          </div>
          <div style={flex ? styles.flexContentMain : styles.contentMain}>
            {children}
          </div>
        </Paper>
      </Col>
    </Row>
  );
};

ContentMain.defaultProps = {
  columns: 12,
  buttomBack: false,
  urlBack: '',
};

ContentMain.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  flex: PropTypes.bool,
  columns: PropTypes.number,
  buttomBack: PropTypes.bool,
  urlBack: PropTypes.string,
};

export default ContentMain;
