import { axiosAPI } from '../../middleware/api';

export const GET_SURVEYS_GRID = "GET_SURVEYS_GRID";

export function FetchSurvey() {

    const request = axiosAPI.get(`/surveys`);

    return {
        type: GET_SURVEYS_GRID,
        payload: request
    }
}
