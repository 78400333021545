import React from "react";
import QuickSightLicenseToggle from "../quicksight";

type Props = {
  username: string;
  email: string;
};
const UserLicenses = (props: Props) => {
  return (
    <div className=" bg-gray-200 border-4 border-light-blue-500 border-opacity-50 py-10 ml-0">
      <h1 className="text-lg m-10">Licencias & Apps</h1>
      <div className="flex flex-col m-10">
        <QuickSightLicenseToggle
          username={props.username}
          email={props.email}
        />
      </div>
    </div>
  );
};

export default UserLicenses;
