import { axiosApiV2 } from "../../../middleware/api";
import * as actions from "./constants";

export function getCountries() {
  const request = axiosApiV2.get(`/location/countries`);

  return {
    type: actions.GET_COUNTRIES,
    payload: request,
  };
}

export function getNameCountry(idCountry) {
  const request = axiosApiV2.get(`/location/country/name/${idCountry}`);

  return {
    type: actions.GET_NAME_COUNTRY,
    payload: request,
  };
}

export function getDepartments(country) {
  const request = axiosApiV2.get(`/location/departments/${country}`);

  return {
    type: actions.GET_DEPARTMENTS,
    payload: request,
  };
}

export function getNameDepartment(idDepartment) {
  const request = axiosApiV2.get(`/location/department/name/${idDepartment}`);

  return {
    type: actions.GET_NAME_DEPARTMENT,
    payload: request,
  };
}

export function getCities(department) {
  const request = axiosApiV2.get(`/location/cities/${department}`);

  return {
    type: actions.GET_CITIES,
    payload: request,
  };
}

export function getNameCity(idCity) {
  const request = axiosApiV2.get(`/location/city/name/${idCity}`);

  return {
    type: actions.GET_NAME_CITY,
    payload: request,
  };
}

export function cleanCities() {
  return {
    type: actions.ClEAN_CITIES,
  };
}

export function deleteCountry(idCountry) {
  const request = axiosApiV2.delete(`/location/country/${idCountry}`, {
    data: { type: "logic" },
  });

  return {
    type: actions.DELETE_COUNTRY,
    payload: request,
    meta: {
      idCountry,
    },
  };
}

export function deleteDepartment(idDepartment) {
  const request = axiosApiV2.delete(`/location/department/${idDepartment}`, {
    data: { type: "logic" },
  });

  return {
    type: actions.DELETE_DEPARTMENT,
    payload: request,
    meta: {
      idDepartment,
    },
  };
}

export function deleteCity(idCity) {
  const request = axiosApiV2.delete(`/location/city/${idCity}`, {
    data: { type: "logic" },
  });

  return {
    type: actions.DELETE_CITY,
    payload: request,
    meta: {
      idCity,
    },
  };
}
