import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import RefreshIndicator from 'material-ui/RefreshIndicator';
import {LBLCOMPANIES} from '../../../constants/labels';
import Title from './Title';
import { getCountries, getDepartments } from '../../fields/Locations/actions';
import { saveCountryInQuestionDepartment } from './location/actions';
import SelectFieldFormat from '../../fields/SelectFieldFormat/SelectFieldFormat';

const styleWidthContent = {
    minWidth: 240
};
const style = {
  container: {
    position: 'relative',
  },
  refresh: {
    position: 'absolute',
    zIndex: 999
  },
};

class QuestionSelectDepartment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            arrayDepartment: [],
            showLoadingDepartament:false,
        };
        this._onChangeCountry = this._onChangeCountry.bind(this);
        this._onChangeDepartment = this._onChangeDepartment.bind(this);
    }

    UNSAFE_componentWillMount() {
        const {
            getCountries,
            getDepartments,
            idQuestion,
            valueCountry
        } = this.props;
        getCountries();
        if(!_.isNull(valueCountry) && !_.isUndefined(valueCountry)){
            this.setState({
                arrayDepartment: [],
                showLoadingDepartament: true
            });
            getDepartments(valueCountry)
                .then((data) => {
                    if (_.get(data, 'error') || (_.get(data, 'payload.status') !== 200)) {
                        this.setState({ showLoadingDepartament: false });
                    } else {
                        this.setState({
                            arrayDepartment: _.get(data, 'payload.data', []),
                            showLoadingDepartament: false
                        });
                    }
                }, (reason) => {
                    this.setState({ showLoadingDepartament: false });
                })
                .catch((data) => {
                    this.setState({ showLoadingDepartament: false });
                });
        }
    }

    _onChangeCountry(e, index, valueCountry) {
        const {
            getDepartments,
            idQuestion,
            _handlerUpdateValue,
            saveCountryInQuestionDepartment
        } = this.props;
        _handlerUpdateValue(e, '');
        saveCountryInQuestionDepartment(idQuestion, valueCountry);
        this.setState({
            arrayDepartment: [],
            showLoadingDepartament: true
        });
        getDepartments(valueCountry)
            .then((data) => {
                if (_.get(data, 'error') || (_.get(data, 'payload.status') !== 200)) {
                    console.log('Error',_.get(data, 'error'));
                } else {
                    const departments = _.get(data, 'payload.data', []);
                    this.setState({
                        arrayDepartment:departments,
                        showLoadingDepartament: false
                    });
                }
            }, (reason) => {
                this.setState({ showLoadingDepartament: false });
            })
            .catch(() => {
                this.setState({ showLoadingDepartament: false });
            });
    }

    _onChangeDepartment(e, index, valueDepartment) {
        const { _handlerUpdateValue } = this.props;
        _handlerUpdateValue(e, valueDepartment);
    }

    render() {
        const {
            question,
            countries,
            index,
            valueCountry,
            valueQuestion
        } = this.props;
        return (
            <div style={styleWidthContent}>
                <div style={{marginBottom: 10}}>
                    <Title question={question} index={index}/>
                </div>
                <SelectFieldFormat
                    config={{
                        floatingLabelText: LBLCOMPANIES.country,
                        autoWidth: true,
                        fullWidth: true,
                        value: valueCountry,
                        onChange: this._onChangeCountry
                    }}
                >
                    {
                        _.orderBy(countries, ['name']).map((item) => {
                            return (
                                <MenuItem key={item._id} value={item._id} primaryText={_.capitalize(item.name)}/>
                            );
                        })
                    }
                </SelectFieldFormat>
                <div style={style.container}>
                    <SelectFieldFormat
                        config={{
                            floatingLabelText: LBLCOMPANIES.department,
                            autoWidth: true,
                            fullWidth: true,
                            disabled: (this.state.showLoadingDepartament || _.isEmpty(this.state.arrayDepartment)),
                            value: valueQuestion,
                            onChange: this._onChangeDepartment
                        }}
                    >
                        {
                            _.orderBy(this.state.arrayDepartment, ['name']).map((item) => {
                                return (
                                    <MenuItem key={item._id} value={item._id} primaryText={_.capitalize(item.name)}/>
                                );
                            })
                        }
                    </SelectFieldFormat>
                     <RefreshIndicator
                       size={40}
                       left={100}
                       top={20}
                       status={this.state.showLoadingDepartament ? 'loading' :'hide'}
                       style={style.refresh}
                     />
               </div>
            </div>
        );
    }
}

QuestionSelectDepartment.propTypes = {
    question: PropTypes.object.isRequired
};

function mapStateToProps({ master, questionsSurvey, questionLocation }, ownProps) {
    return {
        countries: master.countries,
        valueCountry: _.get(questionLocation.get('locations'), `${_.get(ownProps, 'idQuestion', 'default')}.country`),
        valueQuestion: _.get(questionsSurvey.get('answered').toObject(), _.get(ownProps, 'idQuestion'))
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getCountries,
        getDepartments,
        saveCountryInQuestionDepartment
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionSelectDepartment);
