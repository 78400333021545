import Immutable from 'immutable';
import _ from 'lodash';
import * as actions from './constants';

const initialState = Immutable.Map(
    {
        groupers: []
    }
);

export default function (state = initialState, action) {
    switch (action.type) {
        case actions.INITIAL_STATE:
            return state.set("groupers",[]);
        case actions.ADD_GROUPER:
            var groupers = state.get("groupers");
            groupers.push(action.grouper);
            var newState = Immutable.Map(
                {
                    groupers
                }
            );
            return newState;
        case actions.REMOVE_GROUPER:
            var groupers = state.get("groupers");
            groupers = _.filter(groupers, (item)=>{
                return !_.isEqual(item.id, action.idGrouper);
            });
            var newState = Immutable.Map(
                {
                    groupers
                }
            );
            return newState;
        default:
            return state;
    }
}
