import { axiosAPI } from '../../middleware/api';

export const POST_LISTMASTER_INFO = "POST_LISTMASTER_INFO";

export function FetchListMasterParam(id) {

    const request = axiosAPI.get(`/listmaster/${id}`);

    return {
        type: POST_LISTMASTER_INFO,
        payload: request
    }
}
