import PropTypes from "prop-types";
import React, { Component } from "react";
import TableCell from "@material-ui/core/TableCell";
import _ from "lodash";
import { Link } from "react-router-dom";
import IconButton from "material-ui/IconButton";
import { connect } from "react-redux";
import EditIcon from "material-ui/svg-icons/editor/mode-edit";
import AuthComponent from "../../AuthComponent";
import { LBLTEMPLATES } from "../../../constants/labels";
import DeleteEntityModal from "../../deleteEntityModal/component";
import { deleteTemplate } from "../../../actions/template_form";
import { toggleSnackbar } from "../../../actions/commons";
import { removeRecord } from "../actions";

class TemplateEmailActionColumn extends Component {
  constructor(props) {
    super(props);
    this._handleDeleteTemplate = this._handleDeleteTemplate.bind(this);
  }

  _handleDeleteTemplate(data) {
    const { deleteTemplate, toggleSnackbar, removeRecord } = this.props;
    const idTemplate = _.get(data, "_id");
    deleteTemplate(idTemplate)
      .then(
        (data) => {
          if (_.get(data, "error") || _.get(data, "payload.status") !== 200) {
            toggleSnackbar(true, LBLTEMPLATES.msg.errorDelete);
          } else {
            removeRecord(idTemplate, "_id");
            toggleSnackbar(true, LBLTEMPLATES.msg.successDelete);
          }
        },
        (reason) => {
          toggleSnackbar(true, LBLTEMPLATES.msg.errorDelete);
        }
      )
      .catch((data) => {
        toggleSnackbar(true, LBLTEMPLATES.msg.errorDelete);
      });
  }

  render() {
    const { data, colSpan } = this.props;
    const cs = _.isUndefined(colSpan) ? 1 : colSpan;
    return (
      <TableCell colSpan={cs}>
        <div style={{ position: "absolute", marginTop: -24, zIndex: 99 }}>
          <AuthComponent
            component={
              <Link to={`/admin/templateemail/edit/${_.get(data, "_id")}`}>
                <IconButton
                  tooltip={LBLTEMPLATES.tooltips.btnEdit}
                  tooltipPosition="top-center"
                  children={<EditIcon />}
                />
              </Link>
            }
            permission="backend/templateEmail:write"
            type="component"
          />
          <AuthComponent
            component={
              <DeleteEntityModal
                tooltip={LBLTEMPLATES.tooltips.btnDelete}
                title={LBLTEMPLATES.titleDelete}
                message={LBLTEMPLATES.msg.deleteModal(_.get(data, "name"))}
                fn={this._handleDeleteTemplate}
                args={[data]}
                style={{ display: "table-cell" }}
              />
            }
            permission="backend/templateEmail:delete"
            type="component"
          />
        </div>
      </TableCell>
    );
  }
}

TemplateEmailActionColumn.propTypes = {
  data: PropTypes.object.isRequired,
  colSpan: PropTypes.number,
};

export default connect(null, { deleteTemplate, toggleSnackbar, removeRecord })(
  TemplateEmailActionColumn
);
