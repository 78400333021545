import React from 'react';
import FlatButton from 'material-ui/FlatButton';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useNewComplement } from '../data';

/**
 * Botón para guardar un complemento
 * @author Jhoan Lopez <jhoanlt19@gmail.com>
 * @param {*} param0
 */
const ButtonSaveComplement = ({ value, onCompleted }) => {
  const [mutate, { loading, error }] = useNewComplement(
    { name: value },
    onCompleted
  );
  return (
    <FlatButton
      data-testid="button-save-complement"
      label="Guardar"
      primary
      keyboardFocused
      onClick={mutate}
      disabled={value === '' || loading || error}
    />
  );
};

ButtonSaveComplement.defaultProps = {
  value: '',
  onCompleted: _.noop,
};

ButtonSaveComplement.propTypes = {
  value: PropTypes.string,
  onCompleted: PropTypes.func,
};

export default ButtonSaveComplement;
