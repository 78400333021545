import React from "react";
import { useAuth } from "../../context";

type Props = {
  permission: string;
  children: React.ReactNode;
};
const AuthComponent = (props: Props) => {
  const { permissions } = useAuth();
  const hasPermission = React.useMemo(
    () => permissions.has(props.permission),
    []
  );
  if (!hasPermission) {
    return null;
  }
  return props.children;
};

export { AuthComponent };
