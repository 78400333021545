import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles, Theme, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  })
);

type Props = {
  onClick: () => void;
  textTooltip: string;
  disabled: boolean;
  component: React.ElementType;
  to: string;
};

function FloatingActionButtonTooltip(props: Props) {
  const { textTooltip, onClick, disabled, component, to } = props;
  const classes = useStyles();
  return (
    <Tooltip title={textTooltip} aria-label={textTooltip}>
      <Fab
        className={classes.fab}
        color="primary"
        disabled={disabled}
        onClick={onClick}
        component={component}
        to={to}
      >
        <AddIcon />
      </Fab>
    </Tooltip>
  );
}

export default FloatingActionButtonTooltip;
