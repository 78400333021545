export const LOAD_ARL_INFO = "LOAD_ARL_INFO";
export const LOAD_COMPANIES_ARL_INFO = "LOAD_COMPANIES_ARL_INFO";
export const LOGOUT = "LOGOUT";
export const CLEAN_INFO = "CLEAN_INFO";
export const GET_PROJECTS_COMPANIES_ARL = 'GET_PROJECTS_COMPANIES_ARL';
export const GET_STATUS_PROJECTS_COMPANIES_ARL = 'GET_STATUS_PROJECTS_COMPANIES_ARL';
export const ADD_ID_PROJECTS_ARL = 'ADD_ID_PROJECTS_ARL';
export const REMOVE_ID_PROJECTS_ARL = 'REMOVE_ID_PROJECTS_ARL';
export const CLEAN_ID_PROJECTS_ARL = 'CLEAN_ID_PROJECTS_ARL';
export const IN_SEARCH_PROJECTS_ARL = 'IN_SEARCH_PROJECTS_ARL';
export const IN_SEARCH_STATUS_PROJECTS_ARL = 'IN_SEARCH_STATUS_PROJECTS_ARL';
export const RESULTS_PROJECTS_ARL = 'RESULTS_PROJECTS_ARL';
