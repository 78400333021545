import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { Row, Col } from "react-flexbox-grid";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Yup from "yup";
import _ from "lodash";
import Button from "@material-ui/core/Button";
import { LBLQUESTIONS } from "Labels";
import { isBlank } from "Utils";
import { toggleSnackbar } from "../../../actions/commons";
import {
  getValueSources,
  getValuesNameList,
  useQuestionMutation,
} from "./api-operations";
import QuestionCodeField from "./fields/question-code";
import SelectField from "./fields/select-field";
import AsyncAutoCompleteField from "./fields/async-auto-complete-field";
import ValueSourcePresentation from "./fields/value-sources-presentation";
import OtherField from "./fields/other-field";
import QuantitySelectableField from "./fields/quantity-selectable-field";
import FormikTextField from "./fields/text-field";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const FieldContainer = ({ children, md = 6 }) => {
  const classes = useStyles();
  return (
    <Col xs={12} md={md} className={classes.root}>
      {children}
    </Col>
  );
};

const ConfigurationContainer = ({ title, condition, children }) => {
  if (!condition) {
    return null;
  }

  return (
    <div className="flex flex-col max-w-sm mx-auto p-6 rounded-lg shadow-sm bg-gray-100">
      <span className="text-lg leading-8 tracking-tight text-gray-900 mb-2">
        {title}
      </span>
      {React.Children.map(children, (child) => {
        return <div className="mt-3">{child}</div>;
      })}
    </div>
  );
};

const defaultValues = {
  fieldName: "",
  type: "",
  title: "",
  otherWhich: false,
  quantitySelectable: "",
  valueSource: {
    list: {
      name: "",
    },
  },
  help: "",
  _id: "",
  itemList: "none",
  size: "",
  defaultValue: {},
  editMode: false,
};

const allowedTypes = [
  "AnswerOpen",
  "ListOpen",
  "ListClose",
  "Date",
  "Number",
  "AnswerOpenShort",
  "ListSelected",
  "LocationCountry",
  "LocationDepartment",
  "LocationCity",
];
const listTypes = ["ListOpen", "ListClose", "ListSelected"];
const oneAnswerList = ["ListClose", "ListSelected"];
const validationSchema = Yup.object().shape({
  fieldName: Yup.string()
    .min(2, "Código de pregunta muy corto")
    .max(50, "Código de pregunta muy largo")
    .required("Requerido"),
  type: Yup.string().oneOf(allowedTypes).required("Requerido"),
  title: Yup.string()
    .min(10, "Titulo muy corto")
    .max(180, "Titulo muy largo")
    .required("Requerido"),
  help: Yup.string().min(10, "Ayuda muy corto").max(240, "Ayuda muy largo"),
  valueSource: Yup.object().when("type", {
    is: (value) => listTypes.includes(value),
    then: Yup.object().required("Debe seleccionar una lista de valores"),
  }),
  quantitySelectable: Yup.number().when("type", {
    is: "ListOpen",
    then: Yup.number()
      .min(1)
      .required("Seleccione la cantidad máxima seleccionable"),
  }),
});

const QuestionForm = ({ editMode = false, initialValues, toggleSnackbar }) => {
  const [mutate, { isLoading }] = useQuestionMutation(editMode);

  return (
    <Formik
      onSubmit={(values, actions) => {
        mutate(values, {
          onError: () => {
            toggleSnackbar(true, LBLQUESTIONS.msg.errorSave);
          },
          onSuccess: () => {
            toggleSnackbar(true, LBLQUESTIONS.msg.successSave);
            window.location.replace(`/admin/questions/grid`);
          },
        });
      }}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ values, handleSubmit }) => {
        const isListType = listTypes.includes(values.type);
        const isListOpenType = values.type === "ListOpen";
        const listName = _.get(values, "valueSource.nameList");
        const isOneAnswerList = oneAnswerList.includes(values.type);

        return (
          <>
            <Row>
              <FieldContainer>
                <QuestionCodeField
                  name="fieldName"
                  label="Código de la pregunta"
                  fullWidth
                  disabled={editMode}
                  value={values.fieldName}
                />
              </FieldContainer>
              <FieldContainer>
                <SelectField
                  name="size"
                  helperText=""
                  id="question-size"
                  label={LBLQUESTIONS.sizeQuestion}
                  items={LBLQUESTIONS.arrSizesQuestions}
                />
              </FieldContainer>
              <FieldContainer md={12}>
                <FormikTextField
                  name="title"
                  label="Enunciado de la pregunta"
                  fullWidth
                />
              </FieldContainer>
              <FieldContainer md={12}>
                <FormikTextField
                  name="help"
                  label={LBLQUESTIONS.helpText}
                  fullWidth
                  multiline
                  rows={3}
                />
              </FieldContainer>
              <FieldContainer>
                <SelectField
                  name="type"
                  helperText=""
                  id="question-type"
                  disabled={editMode}
                  label={LBLQUESTIONS.type}
                  items={LBLQUESTIONS.singleQuestionTypes}
                />
              </FieldContainer>
              <FieldContainer>
                <ConfigurationContainer
                  title="Configuración de lista de respuestas"
                  condition={isListType}
                >
                  <AsyncAutoCompleteField
                    query={getValueSources}
                    id="value-sources"
                    label={LBLQUESTIONS.groupList}
                    getOptionLabel={(item) => item.alias || ""}
                    getOptionSelected={(item) =>
                      item._id === values.valueSource._id
                    }
                    name="valueSource"
                    disabled={editMode}
                  />
                  <SelectField
                    name="itemList"
                    helperText=""
                    variant="outlined"
                    id="item-list-enumeration"
                    label={LBLQUESTIONS.itemListSelectable}
                    items={LBLQUESTIONS.itemListEnumeration}
                  />
                  {isOneAnswerList ? (
                    <AsyncAutoCompleteField
                      query={getValuesNameList}
                      queryProps={{ listName }}
                      id={`list-${listName}`}
                      label="Valor de respuesta por defecto"
                      getOptionLabel={(item) => item.value || ""}
                      name="defaultValue"
                      disabled={isBlank(listName)}
                    />
                  ) : (
                    ""
                  )}
                  {isListOpenType ? (
                    <>
                      <OtherField
                        label={`Agregar opción, "Otro, ¿Cuál?"`}
                        name="otherWhich"
                        disabled={editMode || isBlank(listName)}
                      />
                      <QuantitySelectableField />
                    </>
                  ) : null}
                  <ValueSourcePresentation />
                </ConfigurationContainer>
              </FieldContainer>
            </Row>
            <Row end="xs">
              <Col xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isLoading}
                  onClick={handleSubmit}
                >
                  {editMode ? LBLQUESTIONS.titleEdit : LBLQUESTIONS.titleCreate}
                </Button>
              </Col>
            </Row>
          </>
        );
      }}
    </Formik>
  );
};

QuestionForm.defaultProps = {
  editMode: false,
  initialValues: {},
};

QuestionForm.propTypes = {
  editMode: PropTypes.bool,
  initialValues: PropTypes.shape({}),
  toggleSnackbar: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      toggleSnackbar,
    },
    dispatch
  );
}

export default connect(undefined, mapDispatchToProps)(QuestionForm);
