import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import AppBar from "material-ui/AppBar";
import IconMenu from "material-ui/IconMenu";
import MoreVertIcon from "material-ui/svg-icons/navigation/more-vert";
import { blue600 } from "material-ui/styles/colors";
import MenuItem from "material-ui/MenuItem";
import IconButton from "material-ui/IconButton";
import AccountCircleIcon from "material-ui/svg-icons/action/account-circle";
import HelpIcon from "material-ui/svg-icons/action/help";
import _ from "lodash";
import {
  showFaq as showFaqFn,
  setInSearch as setInSearchFn,
} from "../../../actions/faq_form";
import { LBLAPP, NameArlTest } from "../../../constants/labels";

import { logOut as logOutFn } from "./actions";
import useChangeUserPassword from "../../users/password/index.tsx";

function AppBarDashboardAr(props) {
  const { profile, loggedIn, showFaq, setInSearch, logOut } = props;

  const userName = loggedIn
    ? `${_.get(profile, "person.name")} ${_.get(profile, "person.lastName")}`
    : NameArlTest;

  const personId = _.get(profile, "person._id");
  const history = useHistory();

  const { MenuItem: ChangePasswordItem, Modal: ChangePasswordModal } =
    useChangeUserPassword();

  React.useEffect(() => {
    if (!personId) {
      history.replace(`/login`);
    }
  }, [personId, history]);

  const onTapFaq = () => {
    setInSearch();
    showFaq(true);
  };

  const _handleLogOut = () => {
    logOut();
    history.push("/login");
  };

  return (
    <AppBar
      title={LBLAPP.title}
      style={{ zIndex: 1400, position: "fixed", left: 0 }}
      showMenuIconButton={false}
      iconElementRight={
        <div>
          <IconButton
            touch
            tooltip={LBLAPP.menu.frecuenceQuestions}
            onClick={onTapFaq}
          >
            <HelpIcon color="#FFF" />
          </IconButton>
          <IconMenu
            iconButtonElement={
              <IconButton iconStyle={{ fill: "#FFF" }}>
                <MoreVertIcon />
              </IconButton>
            }
            targetOrigin={{
              horizontal: "right",
              vertical: "top",
            }}
            anchorOrigin={{
              horizontal: "right",
              vertical: "top",
            }}
          >
            <MenuItem
              primaryText={userName}
              rightIcon={<AccountCircleIcon color={blue600} />}
              disabled
              innerDivStyle={{
                paddingBottom: 5,
                borderBottom: "1px solid #CCC",
              }}
              style={{ color: "#2E2E2E" }}
            />
            <ChangePasswordItem />
            <MenuItem onClick={_handleLogOut} primaryText="Cerrar sesión" />
          </IconMenu>
          <ChangePasswordModal />
        </div>
      }
    />
  );
}

AppBarDashboardAr.propTypes = {
  profile: PropTypes.shape().isRequired,
  loggedIn: PropTypes.shape().isRequired,
  showFaq: PropTypes.func.isRequired,
  setInSearch: PropTypes.func.isRequired,
  logOut: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logOut: logOutFn,
      showFaq: showFaqFn,
      setInSearch: setInSearchFn,
    },
    dispatch
  );
}

function mapStateToProps({ login }) {
  return {
    profile: login.get("profile"),
    loggedIn: login.get("loggedIn"),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppBarDashboardAr);
