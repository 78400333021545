import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {arc, pie, select} from 'd3';
import Faux from 'react-faux-dom';
import {is} from 'immutable';

function explode(selected, x) {
    const offset = is(x.data, selected) ? 20 : 0;
    const angle = (x.startAngle + x.endAngle) / 2;
    const xOff = Math.sin(angle) * offset;
    const yOff = -Math.cos(angle) * offset;
    return `translate(${xOff},${yOff})`;
}


class PieTableFrecuency extends Component {

    constructor(props) {
        super(props);
    }

    static propTypes = {
        data: PropTypes.array.isRequired
    };

    render() {
        const { data } = this.props;
        const faux = Faux.createElement('div');
              const height = 250;
              const width = 250;
              const radius = Math.min(width, height) / 2;

        const arcFn = arc()
            .outerRadius(radius - 40)
            .innerRadius(0);

        const labelArc = arc()
            .outerRadius(radius - 80)
            .innerRadius(radius - 80);

        const pieFn = pie()
            .sort(null)
            .value(function (d) {
                return d.value
            });

        const svg = select(faux)
            .append("svg")
            .attr("width", width)
            .attr("height", height)
            .append("g")
            .attr("transform", `translate(85, 85)`);

        const g = svg
            .selectAll(".arc")
            .data(pieFn(data))
            .enter().append("g")
            .attr("class", "arc");

        g.append("path")
            .attr("d", arcFn)
            .style("fill", function (d) {
                return d.data.color;
            });

        g.append("text")
            .attr("transform", function (d) {
                return `translate(${  labelArc.centroid(d)  })`;
            })
            .attr("dy", ".35em")
            .text(function (d) {
                return d.data.index;
            });

        return faux.toReact();

    }
}

export default PieTableFrecuency;
