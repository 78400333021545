import React, {useCallback} from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import {useQuery} from "react-query";
import {useNotifications} from "Notifications";
import EnhancedTable from './EnhancedTable'
import {tableColumns} from './makeData'
import operations from "../../../containers/users/http";

/**
 * Users detail
 * @author Jhoan Lopez <jhoan.lopez@ias.com.co>
 * @returns {JSX.Element}
 * @constructor
 */
const UserList = () => {
    const columns = React.useMemo(
        () => tableColumns,
        []
    )
    const [variables, setVariables] = React.useState({})
    const {sendNotification} = useNotifications();
    const {data, remove} = useQuery(["users", {...variables}], operations.getUsers, {
        refetchOnMount: true,
        keepPreviousData: true,
    });

    const fetchData = useCallback(
        ({ pageSize, nextPage, search }) => {
            setVariables({
                limit: pageSize,
                skip: pageSize * nextPage,
                query: search?.toString().toLowerCase()
            })
        },
        []
    )

    const handleDelete = (items, arrayDelete) => {
        let i = 0;
        let error = false;
        do {
            operations.deleteUser({userId: items[arrayDelete[i]].user.userId})
                .then(() => {
                })
                .catch(() => {
                    sendNotification('Ocurrio un error al eliminar');
                    error = true;
                })
            i++
            if (i === arrayDelete.length) {
                sendNotification('Eliminación exitosa!');
                remove()
            }
        } while (i < arrayDelete.length && !error);
    }

    return (
        <div>
            <CssBaseline/>
            <EnhancedTable
                columns={columns}
                data={data ? data.items : []}
                numberResults={data ? data.count : 0}
                handleDelete={(items, arrayDelete) => handleDelete(items, arrayDelete)}
                fetchData={fetchData}
            />
        </div>
    )
}

export default UserList
