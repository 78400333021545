import { combineEpics } from "redux-observable";
// import { sendSarinEpic, loadProjectNameEpic } from "../components/sarin/ProjectDucks";
import {
  loadSurveyEpic$,
  saveCoiEpic$,
} from "../components/surveyMetadataEditor/editorDucks";
import { loginEpic$ } from "../containers/login/loginDucks";
import {
  loadSurveysEpic,
  loadCompaniesEpic,
  loadProjectsEpic,
  selectSurveyEpic,
  selectCompaniesEpic,
  saveJobEpic,
  resetGeneratorEpic$,
} from "../components/SurveyResultsExcelGenerator/excelGeneratorDucks";
import { submitRequestEpic$ } from "../components/reportEmployee/reportEmployeeDucks";
import { requestResetEpic$ } from "../components/resetSurvey/ducks";
import { resetPasswordMassiveEpic$ } from "../components/SendEmailMasive/ducks";
/* import {
  updateQueueEpic$,
  updateConversationEpic,
  getRefereeProjectsEpic,
} from "../components/chat/evaluator/ducks";
import { dequeueEpic$ } from "../components/chat/employee/ducks";
import { sendMessageEpic$ } from "../components/analyticsReport/ducks";
import {
  searchEpic$,
  typingSearchEpic$,
} from "../components/analyticsReport/searchBox/ducks";
import {
  dropElementEpic$,
  getPageConfigurationEpic$,
  updateComponentEpic$,
  updatePropsComponentEpic$,
  changePositionPropsComponentEpic$,
  updateTextComponentEpic$,
} from "../components/analyticsReportConfiguration/ducks";
*/
export default combineEpics(
  // sendSarinEpic,
  // loadProjectNameEpic,
  loadSurveyEpic$,
  saveCoiEpic$,
  loginEpic$,
  loadSurveysEpic,
  loadCompaniesEpic,
  loadProjectsEpic,
  selectSurveyEpic,
  selectCompaniesEpic,
  saveJobEpic,
  resetGeneratorEpic$,
  submitRequestEpic$,
  requestResetEpic$,
  resetPasswordMassiveEpic$
  /* updateQueueEpic$,
  updateConversationEpic,
  dequeueEpic$,
  getRefereeProjectsEpic,
  sendMessageEpic$,
  searchEpic$,
  typingSearchEpic$,
  dropElementEpic$,
  getPageConfigurationEpic$,
  updateComponentEpic$,
  updateTextComponentEpic$,
  updatePropsComponentEpic$,
  changePositionPropsComponentEpic$ */
);
