import * as React from 'react'
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
} from "@material-ui/core";
import {Form, Formik} from "formik";
import {Col, Row} from "react-flexbox-grid";
import {makeStyles} from "@material-ui/core/styles";
import {useNotifications} from "Notifications";
import * as Yup from "yup";
import {useAddDatasetColumnSource} from "../../../data";

const useStyles = makeStyles(theme => ({
    fieldContent: {
        textAlign: "center",
        marginTop: 20,
        paddingLeft: 0,
    },
    buttonContent: {
        marginTop: 36,
        marginBottom: 20,
    },
    helperTextColor: {
        color: "red"
    },
    formControl: {
        marginBottom: theme.spacing(2),
        minWidth: 200
    },
}))

type AddSourceProps = {
    open: boolean
    handleClose: () => void
    datasetId: string
    column: any
}

interface FormValues {
    source: string
}

const initialValues: FormValues = {
    source: ''
}

const schema = Yup.object().shape({
    source: Yup.string()
        .required("La fuente es requerida")
});

/**
 * Component to add an information source
 * @author Jhoan Lopez <jhoan.lopez@ias.com.co>
 * @param open
 * @param handleClose
 * @param datasetId
 * @param columnId
 * @constructor
 */
const AddSource: React.FC<AddSourceProps> = ({open, handleClose, datasetId, column}) => {
    const classes = useStyles()
    const [mutate, {loading}] = useAddDatasetColumnSource();
    const {sendNotification} = useNotifications();

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="add-source-dialog" fullWidth maxWidth="sm">
                <DialogTitle id="form-dialog-title">Agregar fuente</DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={schema}
                        onSubmit={(values) => {
                            mutate({variables: {request: {...values, datasetId, columnId: column.id}}})
                                .then(() => {
                                    handleClose();
                                    sendNotification('Fuente agregada correctamente');
                                })
                                .catch(() => {
                                    sendNotification('Ocurrió un error al agregar la fuente');
                                })
                        }}>
                        {({}) => {
                            return (
                                <Form>
                                    <Row>
                                        <Col xs className={classes.fieldContent}>
                                        </Col>
                                    </Row>
                                    <Row center="xs">
                                        <Col xs>
                                            <Button onClick={handleClose} className={classes.buttonContent}>
                                                Cancelar
                                            </Button>
                                        </Col>
                                        <Col xs>
                                            <Button
                                                type="submit"
                                                color="primary"
                                                className={classes.buttonContent}
                                                disabled={loading}
                                            >
                                                Guardar
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            )
                        }}
                    </Formik>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default AddSource;