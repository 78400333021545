import _ from "lodash";
import {
  GET_PROJECT_INFO,
  GET_PROJECT_ONLY,
  GET_PROJECT_COLORS,
  GET_PROJECT_ONLY_REFEREES_INFO,
  GET_PROJECT_ONLY_LAPSES_TIME_INFO,
  UNMOUNT_PROJECT,
  GET_TREE_GROUPERS_SURVEY,
  SELECT_GROUPER_TREE_SURVEY,
} from "../actions/info/projects_info_form";
import {
  DELETE_CONFIG_DAY_PROJECT,
  GET_ADVANCE_PROJECT,
  DISSASOCIATE_EMPLOYEE_PROJECT,
} from "../constants/index";

const INITIAL_STATE = {
  all: [],
  projectColors: [],
  project: null,
  lapsesTimeInfo: null,
  refereesInfo: [],
  treeGroupers: [],
  selectGroupers: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PROJECT_INFO:
      return _.assign({}, state, {
        selectGroupers: _.get(action, "payload.data.data.ranking"),
        all: _.get(action, "payload.data"),
      });
    case GET_PROJECT_ONLY:
      return _.assign({}, state, {
        all: action.payload.data,
      });
    case GET_PROJECT_COLORS:
      return _.assign({}, state, {
        projectColors: _.get(action, "payload.data.data", []),
      });
    case GET_PROJECT_ONLY_REFEREES_INFO:
      return _.assign({}, state, {
        refereesInfo: _.get(action, "payload.data.data"),
      });
    case GET_PROJECT_ONLY_LAPSES_TIME_INFO:
      return _.assign({}, state, {
        lapsesTimeInfo: _.get(action, "payload.data.data"),
      });
    case DELETE_CONFIG_DAY_PROJECT: {
      const newDaysWeek = _.filter(state.all.data.time.dayWeek, (item) => {
        return !_.isEqual(item.day, action.meta.day);
      });
      const newLapsesTime = _.filter(
        _.get(state, "lapsesTimeInfo.time.dayWeek", []),
        (item) => {
          return !_.isEqual(item.day, _.get(action, "meta.day"));
        }
      );
      const {
        all: {
          data: { time, ...restData },
        },
      } = state;
      const newData = {
        ...restData,
        time: {
          openDate: time.openDate,
          closeDate: time.closeDate,
          dayWeek: newDaysWeek,
        },
      };
      const lapses = _.omit(state.lapsesTimeInfo, ["time"]);
      const newDataLapses = {
        ...lapses,
        time: {
          ...lapses.time,
          dayWeek: newLapsesTime,
        },
      };

      return _.assign({}, state, {
        all: { data: newData },
        lapsesTimeInfo: newDataLapses,
      });
    }
    case GET_ADVANCE_PROJECT:
      return _.assign({}, state, {
        project: action.payload.data,
      });
    case UNMOUNT_PROJECT:
      return INITIAL_STATE;
    case DISSASOCIATE_EMPLOYEE_PROJECT: {
      let newProject = _.omit(state.all.data, ["employees"]);
      const employees = _.get(state, "all.data.employees", []);
      const newEmployees = _.filter(employees, (item) => {
        return !_.isEqual(_.get(item, "_id"), _.get(action, "meta.idEmployee"));
      });
      newProject = _.set(newProject, "employees", newEmployees);
      return _.assign({}, state, {
        all: {
          data: newProject,
        },
      });
    }
    case GET_TREE_GROUPERS_SURVEY:
      return _.assign({}, state, {
        treeGroupers: _.get(action, "payload.data", []),
      });
    case SELECT_GROUPER_TREE_SURVEY: {
      let groupersSelect = _.get(state, "selectGroupers", []);
      if (_.get(action, "meta.isSelect", false)) {
        groupersSelect.push(_.get(action, "meta.idGrouper", null));
        groupersSelect = _.filter(
          groupersSelect,
          (grouper) => !_.isNull(grouper)
        );
      } else {
        groupersSelect = _.filter(
          groupersSelect,
          (grouper) => !_.isEqual(grouper, _.get(action, "meta.idGrouper"))
        );
      }
      return _.assign({}, state, {
        selectGroupers: groupersSelect,
      });
    }
    default:
      return state;
  }
};
