import React from "react";
import { blue700 } from "material-ui/styles/colors";
import Paper from "material-ui/Paper";
import { Row, Col } from "react-flexbox-grid";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import FloatingActionButtonTooltip from "../../fields/FloatingActionButtonTooltip/index.tsx";
import { LBL_DATASET } from "../../../constants/labels";
import ButtonBack from "../../fields/buttonBack/buttonBack";
import CreateFormDialog from "./create/createFormDialog";

const styles = {
  stylePaper: {
    marginTop: 20,
    marginBottom: 20,
    textAlign: "center",
    //minHeight: "85vh",
  },
  contentTitle: {
    width: "100%",
    background: blue700,
    paddingTop: 5,
    paddingBottom: 5,
    color: "#FFF",
  },
  title: {
    textAlign: "center",
    fontWeight: 400,
  },
  flexContentMain: {
    display: "flex",
    minHeight: "85vh",
  },
  contentMain: {
    minHeight: "85vh",
    margin: 10
  },
};

/**
 * @author Jhoan Lopez <jhoanlt19@gmail.com>
 * @param {*} param0
 */
const Container = ({
  children,
  title,
  flex,
  addButton,
  routeBack,
  backButton,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Row style={{ justifyContent: "center" }}>
      <Col xs={12} md={10}>
        <Paper style={styles.stylePaper}>
          {backButton && <ButtonBack url={routeBack} />}
          <div style={styles.contentTitle}>
            <h1 style={styles.title}>{title}</h1>
          </div>
          <div style={flex ? styles.flexContentMain : styles.contentMain}>
            {children}
          </div>
          {addButton && (
              <FloatingActionButtonTooltip
                textTooltip={LBL_DATASET.tooltips.btnCreate}
                component={Link}
                onClick={handleClickOpen}
              />
          )}
          <CreateFormDialog open={open} handleClose={handleClose}/>
        </Paper>
      </Col>
    </Row>
  );
};

Container.defaultProps = {
  title: "Título",
  addButton: false,
  routeBack: "/admin",
  backButton: false,
  flex: false,
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  flex: PropTypes.bool,
  addButton: PropTypes.bool,
  routeBack: PropTypes.string,
  backButton: PropTypes.bool,
};

export default Container;
