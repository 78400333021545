import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { getListSections } from "./actions";
import { cleanListSections } from "./actions";
import IconButton from "material-ui/IconButton";
import { blue700, blue400, grey700, blue100 } from "material-ui/styles/colors";
import CircleIcon from "material-ui/svg-icons/av/fiber-manual-record";
import CircleCompleteIcon from "material-ui/svg-icons/image/lens";
import NextIcon from "material-ui/svg-icons/image/navigate-next";
import BeforeIcon from "material-ui/svg-icons/image/navigate-before";

const styleIcon = {
    marginLeft: -5,
  },
  styleItemList = {
    display: "inline",
  };

class ControlsSections extends Component {
  constructor(props) {
    super(props);
    this.validateSection = this.validateSection.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { getListSections, cleanListSections, idSurveys, isAdmin } =
      this.props;
    cleanListSections();
    if (idSurveys && isAdmin) {
      getListSections(idSurveys);
    }
  }

  validateSection() {
    // const {
    //     URL,
    //     currentSection,
    //     arraySections,
    //     idProjects,
    //     isAdmin
    // } = this.props;
    // let admin = isAdmin ? "/admin" : "/dashboard";
    // let URLPermission = admin + URL;
    // let idP = _.isUndefined(idProjects) || _.isNull(idProjects) || _.isEqual(idProjects, "") ? "" : "/"+idProjects;
    // if(!_.isNull(arraySections)){
    //     if(_.size(arraySections) === 0 || currentSection <= 0){
    //         browserHistory.push(`${URLPermission}${1}${idP}`)
    //     } else if(_.size(arraySections) < currentSection){
    //         browserHistory.push(`${URLPermission}${_.size(arraySections)}${idP}`)
    //     }
    // }
  }

  render() {
    const { URL, currentSection, arraySections, idProjects, isAdmin } =
      this.props;
    let admin = isAdmin ? "/admin" : "/dashboard";
    let URLPermission = admin + URL;
    let idP =
      _.isUndefined(idProjects) ||
      _.isNull(idProjects) ||
      _.isEqual(idProjects, "")
        ? ""
        : "/" + idProjects;
    let styleList = {
      padding: 0,
      margin: "0 auto",
      listStyleType: "none",
      listStyle: "none",
      background: "#FFF",
      paddingLeft: 5,
      border: 1,
      borderStyle: "solid",
      borderColor: blue400,
      width:
        _.size(_.isNull(arraySections) ? [] : arraySections) === 0
          ? 43 * 2
          : (_.size(_.isNull(arraySections) ? [] : arraySections) + 2) * 43 +
            10,
    };
    let styleContentList = {
      position: "fixed",
      zIndex: 990,
      padding: "0 0 24px 0",
      width: "100%",
      textAlign: "center",
      left: 0,
      display: isAdmin ? "block" : "none",
      top: 63,
      minWidth: 320,
      overflow: "auto",
    };
    return (
      <div style={styleContentList}>
        <ul style={styleList}>
          <li style={styleItemList}>
            <IconButton
              style={styleIcon}
              disabled={currentSection === 1}
              onClick={() => {
                window.location.replace(`${URLPermission}${currentSection - 1}${idP}`);
              }}
              children={
                <BeforeIcon color={currentSection === 1 ? grey700 : blue700} />
              }
            />
          </li>
          {_.map(_.isNull(arraySections) ? [] : arraySections, (val, ind) => {
            return (
              <li key={val._id} style={styleItemList}>
                <IconButton
                  style={styleIcon}
                  tooltip={`${val.title}`}
                  tooltipPosition="bottom-center"
                  onClick={() => {
                    window.location.replace(`${URLPermission}${val.order}${idP}`);
                  }}
                  children={
                    currentSection === val.order ? (
                      <CircleCompleteIcon color={blue700} />
                    ) : (
                      <CircleIcon color={blue100} />
                    )
                  }
                />
              </li>
            );
          })}
          <li style={styleItemList}>
            <IconButton
              style={styleIcon}
              disabled={
                currentSection + 1 >
                _.size(_.isNull(arraySections) ? [] : arraySections)
              }
              onClick={() => {
                  window.location.replace(`${URLPermission}${currentSection + 1}${idP}`);
              }}
              children={
                <NextIcon
                  color={
                    currentSection + 1 >
                    _.size(_.isNull(arraySections) ? [] : arraySections)
                      ? grey700
                      : blue700
                  }
                />
              }
            />
          </li>
        </ul>
      </div>
    );
  }
}

ControlsSections.propTypes = {
  idSurveys: PropTypes.string.isRequired,
  currentSection: PropTypes.number.isRequired,
  URL: PropTypes.string.isRequired,
  idProjects: PropTypes.string,
  isAdmin: PropTypes.bool.isRequired,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getListSections,
      cleanListSections,
    },
    dispatch
  );
}

function mapStateToProps({ controlsections }) {
  if (_.get(controlsections.get("values"), "size") !== 0) {
    if (_.size(_.get(controlsections.get("values"), "data"))) {
      return {
        arraySections: _.get(controlsections.get("values"), "data"),
      };
    } else {
      return { arraySections: null };
    }
  }
  return { arraySections: null };
}

export default connect(mapStateToProps, mapDispatchToProps)(ControlsSections);
