import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CircularProgress from 'material-ui/CircularProgress';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import _ from 'lodash';

const styleText = {
    whiteSpace: 'nowrap',
    width: 150,
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
};

class SpinnerLoading extends Component {

    constructor(props) {
        super(props);
        this.handleResize = this.handleResize.bind(this);
        this.state = {
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight
        };
    }

    handleResize(e) {
        this.setState({
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight
        });
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    render() {
        const {
            visible,
            message
            } = this.props;
        const heightComponent = _.isUndefined(message) ? 100 : _.isEqual(message, "") ? 100 : 120;
        const styleCircle = {
            border: 0,
            borderStyle: 'solid',
            borderColor: '#FF0',
            position: 'fixed',
            textAlign: 'center',
            zIndex: 9999999,
            left: (this.state.windowWidth / 2) - 85,
            top: (this.state.windowHeight / 2) - (heightComponent / 2),
            width: 170,
            heigth: heightComponent
        };
        if (visible) {
            return (
                <div style={styleCircle}>
                    <CircularProgress size={50} thickness={3}/>
                    <h3 style={styleText}>
                        {message}
                    </h3>
                </div>
            );
        } 
            return (
                <div />
            );
        
    }
}

function mapStateToProps({spinnerLoading}, ownProps) {
    return {
        visible: spinnerLoading.get('visible'),
        message: spinnerLoading.get('message')
    };

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SpinnerLoading);
