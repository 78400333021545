import _ from 'lodash';
import { GET_EMPLOYEES_GRID, GET_ANSWER_EMPLOYEES_GRID,
   FILTER_EMPLOYEES_COMPANY, SELECT_ALL_EMPLOYEES,
    UNSELECT_ALL_EMPLOYEES, TOGGLE_ROW_EMPLOYEE_TABLE,
    DELETE_EMPLOYEES, UNMOUNT_TABLE_EMPLOYEES } from '../actions/grid/employees_grid_form';
import { POST_EMPLOYEE_INFO } from '../actions/info/employees_info_form';
import { GET_EMPLOYEE } from '../actions/employees_form';
import { DELETE_EMPLOYEE } from '../constants/index';

const INITIAL_STATE = {
    all: [],
    employees: null,
    employee: null,
    numFound: 0,
    tags:[]
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_EMPLOYEES_GRID:
            var arrayEmployees = _.get(action, "payload.data.data", []);
            var initialStateEmployees = [];
            arrayEmployees.map(item=> {
                initialStateEmployees.push(_.set(item, "selected", false));
            });
            var tagsCompanyEmployeesGrid = _.uniq(getTagsEmployees(arrayEmployees));
            return  _.assign({}, state, {
                all: initialStateEmployees,
                tags: tagsCompanyEmployeesGrid,
                numFound: _.get(action, 'payload.data.numFound', 0),
                employees: initialStateEmployees
            });
        case GET_ANSWER_EMPLOYEES_GRID:
            return  _.assign({}, state, {
                all: action.payload.data
            });
        case POST_EMPLOYEE_INFO:
            return  _.assign({}, state, {
                all: action.payload.data
            });
        case GET_EMPLOYEE:
            return  _.assign({}, state, {
                employee: action.payload.data
            });
        case DELETE_EMPLOYEE:
            var countDeleted = 0;
            var newAll = _.filter(state.employees, item => {
                if(_.isEqual(item._id, action.meta.employeId)){
                    countDeleted += 1;
                }
                return !_.isEqual(item._id, action.meta.employeId);
            });
            var tagsCompanyDeleteEmployees = _.uniq(getTagsEmployees(newAll));
            return _.assign({}, state, {
                employees: newAll,
                all: newAll,
                tags: tagsCompanyDeleteEmployees,
                numFound: state.numFound - countDeleted
            });
        case FILTER_EMPLOYEES_COMPANY:
            return _.assign({}, state, {
                employees: _.get(action, "employees", []),
                numFound: _.size(_.get(action, "employees", []))
            });
        case SELECT_ALL_EMPLOYEES:
          var employees = _.get(state,'employees');
          var newEmployeesState = _.map(employees,(employee)=>{
              return _.set(employee, "selected", true);
          });
          return  _.assign({}, state, {
              employees: newEmployeesState
          });
        case UNSELECT_ALL_EMPLOYEES:
            var employeesUnselectAll = _.get(state,'employees');
            var newStEmployees = _.map(employeesUnselectAll, employee => {
                return _.set(employee, "selected", false);
            });
            return  _.assign({}, state, {
                employees: newStEmployees
            });
        case UNMOUNT_TABLE_EMPLOYEES:
            return  INITIAL_STATE;
        case TOGGLE_ROW_EMPLOYEE_TABLE:
            var arrayEmployeesToggleRow = state.employees;
            var index = _.findIndex(arrayEmployeesToggleRow, (item)=>_.isEqual(item._id, action.employee));
            _.set(arrayEmployeesToggleRow[index], "selected", !_.get(arrayEmployeesToggleRow[index], "selected"));
            return _.assign({}, state, {
                employees: arrayEmployees
            });
        case DELETE_EMPLOYEES:
            if (_.get(action, 'error') || (_.get(action, 'payload.status') !== 200)) {
                return state
            } 
                var newEmployees = _.filter(state.employees, employee => {
                    return !_.get(employee, "selected");
                });
                var tagsCompany = _.uniq(getTagsEmployees(newEmployees));
                return _.assign({}, state, {
                    employees: newEmployees,
                    all: newEmployees,
                    tags: tagsCompany,
                    numFound: _.size(newEmployees)
                });
            
        default:
            return state;
    }
}

function getTagsEmployees(employees) {
    const tags = [];
    if (!_.isUndefined(employees)) {
        employees.map((item)=> {
            const tagsEmployee = _.get(item, 'tags');
            if (_.size(tagsEmployee) > 0) {
                tagsEmployee.map((tag)=> {
                    tags.push(tag.value);
                });
            }
        });
    }
    return tags;
}
