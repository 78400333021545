import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import TextField from "@material-ui/core/TextField";
import { Col, Row } from "react-flexbox-grid";
import { FlatButton, RaisedButton } from "material-ui";
import PropTypes from "prop-types";
import AmplifyAuth from "@aws-amplify/auth";
import AuthContainer from "./container";
import AdaptiveSelector from "../../components/users/create/adaptiveSelector";
import operations from "../../containers/users/http";
import { v4 as uuidv4 } from "uuid";
import { InputAdornment } from "@material-ui/core";
import IconButton from "material-ui/IconButton";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const styles = {
  formRow: {
    marginTop: 40,
  },
  fieldContent: {
    textAlign: "center",
    width: "100%",
    marginTop: 25,
  },
  loginButton: {
    marginBottom: 20,
    minWidth: "50%",
  },
  input: {
    margin: "0 auto",
    textAlign: "left",
    minWidth: "50%",
  },
  loginFederate: {
    textAlign: "center",
    width: "100%",
  },
};

const schema = Yup.object().shape({
  identificationType: Yup.string().required("Tipo de identificación requerida"),
  username: Yup.string()
    .min("4", "El número de identificación debe ser de minimo 4 caracteres")
    .required("El número de identificación es requerido"),
  password: Yup.string()
    .min("8", "La contraseña debe ser de minimo 8 caracteres")
    .required("La contraseña es requerido"),
});

/**
 * Form to sign in
 * @param onForgotPassword
 * @param isSubmitting
 * @param onSubmit
 * @returns {JSX.Element}
 * @constructor
 */
const SignInForm = ({ onForgotPassword, isSubmitting, onSubmit }) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  }

  return (
    <AuthContainer>
      <Formik
        initialValues={{ identificationType: "", username: "", password: "" }}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {formik => {
          return (
            <Form>
              <Row style={styles.formRow}>
                <Col xs style={styles.fieldContent}>
                  <AdaptiveSelector
                    label="Tipo de documento"
                    name="identificationType"
                    value={formik.values.identificationType}
                    handleChange={formik.handleChange}
                    data={operations.getDocumentTypes}
                    error={
                      formik.touched.identificationType &&
                      Boolean(formik.errors.identificationType)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col xs style={styles.fieldContent}>
                  <TextField
                    label="Nro identificación"
                    style={styles.input}
                    name="username"
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.username && Boolean(formik.errors.username)
                    }
                    helperText={
                      formik.touched.username && formik.errors.username
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col xs style={styles.fieldContent}>
                  <TextField
                    label="Contraseña"
                    type={showPassword ? "text" : "password"}
                    style={styles.input}
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Ver contraseña"
                            onClick={handleShowPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs style={styles.fieldContent}>
                  <RaisedButton
                    label="Iniciar sesión"
                    secondary
                    style={styles.loginButton}
                    disabled={isSubmitting}
                    type="submit"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs style={styles.loginFederate}>
                  <RaisedButton
                    label="Admin"
                    style={styles.loginButton}
                    disabled={isSubmitting}
                    onClick={() =>
                      AmplifyAuth.federatedSignIn({
                        customProvider: "AZURE",
                        customState: uuidv4(),
                      })
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col xs style={styles.fieldContent}>
                  <FlatButton
                    style={{ textAlign: "left" }}
                    label="¿Olvidó su contraseña?"
                    secondary
                    onClick={onForgotPassword}
                  />
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </AuthContainer>
  );
};

SignInForm.propTypes = {
  onForgotPassword: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default SignInForm;