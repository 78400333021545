export const TOGGLE_NAV = "TOGGLE_NAV";
export const SELECT_MENU_ITEM = "SELECT_MENU_ITEM";

export function toggleNav(state) {
    return {
        type: TOGGLE_NAV,
        payload: state
    }
}

export function selectMenuItem(index) {
    return {
        type: SELECT_MENU_ITEM,
        selected: index
    }
}