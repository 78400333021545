import PropTypes from "prop-types";
import React, { Component } from "react";
import Checkbox from "material-ui/Checkbox";
import RadioCheckIcon from "material-ui/svg-icons/toggle/radio-button-checked";
import RadioUncheckIcon from "material-ui/svg-icons/toggle/radio-button-unchecked";
import CheckIcon from "material-ui/svg-icons/toggle/check-box";
import UncheckIcon from "material-ui/svg-icons/toggle/check-box-outline-blank";
import { bindActionCreators } from "redux";
import _ from "lodash";
import TextField from "material-ui/TextField";
import { connect } from "react-redux";
import Title from "./Title";
import { LBLQUESTIONS, LBLSURVEYS } from "../../../constants/labels";
import { EXPRESSION_OTHER_WHICH } from "../../../constants/regularExpressions";

const styleWidthContent = {
  minWidth: 240,
};

class QuestionListOpen extends Component {
  constructor(props) {
    super(props);
    this.onChangeTextOtherWich = this.onChangeTextOtherWich.bind(this);
    this._updateTextOnKeyUp = this._updateTextOnKeyUp.bind(this);
    this._onHandlerUpdateAnswers = this._onHandlerUpdateAnswers.bind(this);
    this._onHandlerUpdateTextAnswers =
      this._onHandlerUpdateTextAnswers.bind(this);
    this._onHandleChangeText = this._onHandleChangeText.bind(this);
    this.state = {
      otherWhichVisible: false,
      otherWhich: "",
      otherWhichError: "",
      visibleTextError: false,
      arrayAnswers: [],
    };
  }

  UNSAFE_componentWillMount() {
    const { question, valuesQuestion, idQuestion } = this.props;
    if (_.size(valuesQuestion) > 0) {
      const arrayValues = [];
      let otherWhichVisible = false;
      let otherWhich = "";
      _.forEach(valuesQuestion, (value) => {
        if (EXPRESSION_OTHER_WHICH.test(value)) {
          otherWhichVisible = true;
          otherWhich = _.replace(value, "otherWhich##", "");
          arrayValues.push("otherWhich##");
        } else {
          arrayValues.push(value);
        }
      });
      this.setState({
        arrayAnswers: arrayValues,
        otherWhichVisible,
        otherWhich,
      });
    }
  }

  _onChangeOnTouchTap(value, e) {
    let { arrayAnswers } = this.state;
    if (_.indexOf(arrayAnswers, value) === -1) {
      arrayAnswers.push(value);
      if (_.isEqual("otherWhich##", value)) {
        this.setState({ otherWhichVisible: true });
        setTimeout(() => {
          this.setFocus();
        }, 200);
      }
    } else {
      arrayAnswers = _.filter(arrayAnswers, (a) => !_.isEqual(a, value));
      if (_.isEqual("otherWhich##", value)) {
        this.setState({
          otherWhichVisible: false,
          otherWhich: "",
          visibleTextError: false,
        });
      }
    }
    this.setState({ arrayAnswers });
    this._onHandlerUpdateAnswers(arrayAnswers);
  }

  _onChangeOnKeyUp(value, e) {
    if (e.keyCode === 32) {
      let { arrayAnswers } = this.state;
      if (_.indexOf(arrayAnswers, value) === -1) {
        arrayAnswers.push(value);
        if (_.isEqual("otherWhich##", value)) {
          this.setState({ otherWhichVisible: true });
          setTimeout(() => {
            this.setFocus();
          }, 200);
        }
      } else {
        arrayAnswers = _.filter(arrayAnswers, (a) => !_.isEqual(a, value));
        if (_.isEqual("otherWhich##", value)) {
          this.setState({
            otherWhichVisible: false,
            otherWhich: "",
            visibleTextError: false,
          });
        }
      }
      this.setState({ arrayAnswers });
      this._onHandlerUpdateAnswers(arrayAnswers);
    }
  }

  onChangeTextOtherWich(value) {
    this._onHandleChangeText(value.target.value);
  }

  _updateTextOnKeyUp(value) {
    this._onHandleChangeText(value.target.value);
  }

  _onHandleChangeText(value) {
    this.setState({ otherWhich: value });
    this._onHandlerUpdateTextAnswers(this.state.arrayAnswers, value);
    if (
      !_.isEqual(value, "") &&
      !_.isNull(value, "") &&
      !_.isUndefined(value, "")
    ) {
      this.setState({ visibleTextError: true, otherWhichError: "" });
    } else if (this.state.visibleTextError) {
      this.setState({
        otherWhichError: `El campo "${LBLQUESTIONS.otherWhich}" no debe estar vació`,
      });
    }
  }

  _onHandlerUpdateTextAnswers(values, text) {
    const { _handlerUpdateValue, idQuestion } = this.props;
    if (isCheckedOption("otherWhich##", values)) {
      const answers = _.filter(values, (a) => !_.isEqual(a, "otherWhich##"));
      const textOtherW =
        _.isUndefined(this.state.otherWhich) ||
        _.isNull(this.state.otherWhich) ||
        _.isEqual(this.state.otherWhich, "")
          ? ""
          : this.state.otherWhich;
      answers.push(`otherWhich##${textOtherW}`);
      this._onHandlerUpdateAnswers(answers);
    } else {
      this._onHandlerUpdateAnswers(values);
    }
  }

  _onHandlerUpdateAnswers(values) {
    const { _handlerUpdateValue, idQuestion } = this.props;
    _handlerUpdateValue(idQuestion, values);
  }

  setFocus() {
    this.refs.otherWhich.focus();
  }

  renderOptions(
    options,
    quantitySelectable,
    otherWhich,
    itemList,
    arrayAnswers
  ) {
    const Pairs = OptionCouples(options);
    let array;
    if (_.isEqual(itemList, "lowerCase")) {
      array = LBLSURVEYS.arrays.lowercase;
    } else if (_.isEqual(itemList, "upperCase")) {
      array = LBLSURVEYS.arrays.uppercase;
    } else {
      array = [];
    }
    const OptionList = _.map(options, function (op) {
      return _.set(op, "key", parseInt(_.get(op, "key")));
    });
    return _.map(_.orderBy(OptionList, "key", "asc"), (option, position) => {
      const enumeration =
        _.size(array) !== 0
          ? _.size(array) <= position
            ? ""
            : `${array[position]}.`
          : _.isEqual(itemList, "numeric")
          ? `${position + 1}.`
          : "";
      return (
        <Checkbox
          key={`option${_.get(option, "_id")}`}
          label={`${enumeration} ${_.get(option, "value", "")}`}
          onClick={this._onChangeOnTouchTap.bind(this, _.get(option, "_id"))}
          onKeyUp={this._onChangeOnKeyUp.bind(this, _.get(option, "_id"))}
          checked={isCheckedOption(_.get(option, "_id"), arrayAnswers)}
          disabled={validateMaxSelectable(
            _.get(option, "_id"),
            quantitySelectable,
            arrayAnswers
          )}
          style={{
            width: Pairs,
            minWidth: 100,
            float: position % 2 === 0 && "left",
          }}
        />
      );
    });
  }

  render() {
    const self = this;
    const { question, index, valuesQuestion, idQuestion } = this.props;

    const itemList = _.has(question, "itemList")
      ? _.get(question, "itemList")
      : "none";
    const otherWhich = _.get(question, "otherWhich", false);
    const quantitySelectable = _.get(question, "quantitySelectable", 1);
    const optionValues = _.get(question, "valueSource.list.values", []);

    return (
      <div style={styleWidthContent}>
        <div style={{ marginBottom: 10 }}>
          <Title
            question={
              _.has(question, "fieldName") ? question : _.get(question, "_id")
            }
            index={index}
          />
        </div>
        <div style={{ display: "inline" }}>
          {this.renderOptions(
            optionValues,
            quantitySelectable,
            otherWhich,
            itemList,
            this.state.arrayAnswers
          )}
          <Checkbox
            key={`OptionOtherWhich${idQuestion}`}
            label={LBLQUESTIONS.otherWhich}
            onClick={this._onChangeOnTouchTap.bind(this, "otherWhich##")}
            onKeyUp={this._onChangeOnKeyUp.bind(this, "otherWhich##")}
            checked={isCheckedOption("otherWhich##", this.state.arrayAnswers)}
            disabled={validateMaxSelectable(
              "otherWhich##" + this.state.otherWhich,
              quantitySelectable,
              this.state.arrayAnswers
            )}
            style={
              otherWhich
                ? {
                    display: "block",
                    width: "50%",
                    float: "left",
                  }
                : { display: "none" }
            }
          />
          <div style={{ paddingTop: 20 }}>
            <TextField
              hintText={LBLQUESTIONS.otherWhichRequired}
              type="text"
              ref="otherWhich"
              fullWidth={true}
              onChange={this.onChangeTextOtherWich}
              onKeyUp={this._updateTextOnKeyUp}
              errorText={this.state.otherWhichError}
              style={{
                marginTop: 25,
                display:
                  this.state.otherWhichVisible &&
                  isCheckedOption("otherWhich##", this.state.arrayAnswers)
                    ? "block"
                    : "none",
              }}
              value={this.state.otherWhich}
            />
          </div>
        </div>
      </div>
    );
  }
}

function validateMaxSelectable(optionId, quantitySelectable, array) {
  if (_.isEqual(_.size(array), _.toInteger(quantitySelectable))) {
    return _.indexOf(array, optionId) === -1;
  }
  return false;
}

function isCheckedOption(optionId, array) {
  return _.indexOf(array, optionId) !== -1;
}

function OptionCouples(options) {
  let Pairs = "47%";
  options.map((option, position) => {
    const val = option.value;
    if (_.size(val.split(" ")) >= 3) {
      let sizeLetter = 0;
      val.split(" ").map((vl) => {
        if (_.size(vl) >= 4) {
          sizeLetter++;
        }
      });
      if (sizeLetter >= 2) {
        Pairs = "94%";
      }
    }
  });
  return Pairs;
}

QuestionListOpen.propTypes = {
  question: PropTypes.object.isRequired,
};

function mapStateToProps({ questionListOpen, questionsSurvey }, ownProps) {
  const valuesQuestion =
    _.isUndefined(
      _.get(
        questionsSurvey.get("answered").toObject(),
        _.get(ownProps, "idQuestion")
      )
    ) ||
    _.isNull(
      _.get(
        questionsSurvey.get("answered").toObject(),
        _.get(ownProps, "idQuestion")
      )
    )
      ? []
      : _.get(
          questionsSurvey.get("answered").toObject(),
          _.get(ownProps, "idQuestion")
        );
  return {
    valuesQuestion,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps)(QuestionListOpen);
