import Immutable from 'immutable';
import _ from 'lodash';
import { LOAD_COMPANY_PROJECTS, LOAD_COMPANY_INFO, CLEAR_LOAD_COMPANY_PROJECTS, GET_COMPANY_ID_USER } from './constants';

const initialState = Immutable.Map({
    projects: Immutable.List(),
    company: {},
    idCompany:  null
});

export default (state = initialState, action) => {
    switch (action.type) {
        case LOAD_COMPANY_PROJECTS:
            var projectsResponse = _.get(action, 'payload.data', []);
            var projectsList = Immutable.List(projectsResponse);
            return state.set('projects', projectsList);
        case LOAD_COMPANY_INFO:
            var companyInfo = _.get(action, 'payload.data.data', {});
            return state.set('company', companyInfo);
        case CLEAR_LOAD_COMPANY_PROJECTS:
            return state.withMutations(function(map) {
                map.set('projects', Immutable.List());
                map.set("idCompany", null);
            });
        case GET_COMPANY_ID_USER:
            return state.set('idCompany', _.get(action, 'meta.idCompany', null));
        default:
            return state;
    }
}
