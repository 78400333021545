import React, {Component} from "react";
import {blue600, blue700} from "material-ui/styles/colors";
import {Row, Col} from "react-flexbox-grid";
import Paper from "material-ui/Paper";
import {connect} from "react-redux";
import RaisedButton from "material-ui/RaisedButton";
import _ from "lodash";
import is from "is_js";
import TextField from "material-ui/TextField";
import {SaveAssociationEmployees} from "../../actions/projects_form";
import {LBLEMPLOYESS} from "../../constants/labels";
import {FetchCompanyParam} from "../../actions/info/companies_info_form";
import {FetchProjectParam} from "../../actions/info/projects_info_form";
import {toggleSnackbar} from "../../actions/commons";
import ButtonBack from "../fields/buttonBack/buttonBack";
import AuthComponent from "../AuthComponent";
import TablePagination from "../TablePagination/tablePagination";
import TagsEmployee from "../TagsEmployee/TagsEmployee";
import {setInitSelected} from "../TablePagination/actions";

let urlBack;

class EmployeesGridAssoc extends Component {

    constructor(props) {
        super(props);
        this.state = {textValueTag: null, textTag: null, email: "", emailError: ""};
        this._onChangeTagForTable = this._onChangeTagForTable.bind(this);
        this._onChangeValueTagForTable = this._onChangeValueTagForTable.bind(this);
    }

    UNSAFE_componentWillMount() {
        const {
            FetchCompanyParam,
            FetchProjectParam,
            setInitSelected,
            params,
        } = this.props;
        FetchProjectParam(_.get(params, "_id")).then((data) => {
            setInitSelected(_.get(data, "payload.data.data.employees", []));
        });
        FetchCompanyParam(_.get(params, "company"));
        urlBack = `/admin/projects/info/${_.get(params, "profile")}/`;
    }

    saveEmployeesAssociate = () => {
        const {
            selected,
            params,
            toggleSnackbar,
            SaveAssociationEmployees,
        } = this.props;
        const {email, emailError} = this.state;
        if (
            (_.isEqual(email, "") && !_.isEqual(emailError, "")) ||
            (_.isEmpty(email) && !_.isEmpty(emailError))
        ) {
            this.setState({
                emailError: "El correo electrónico no debe estar vació",
            });
        } else if (!is.email(email)) {
            this.setState({emailError: "El correo electrónico no es válido"});
        } else {
            SaveAssociationEmployees(
                {employees: selected},
                email,
                _.get(params, "_id")
            )
                .then(
                    (data) => {
                        if (_.get(data, "error") || _.get(data, "payload.status") !== 200) {
                            toggleSnackbar(true, "Hubo un error al guardar");
                        } else {
                            toggleSnackbar(
                                true,
                                "Cuando la asociación termine, se le notificará al correo electrónico asociado."
                            );
                            this.props.history.push(
                                `/admin/projects/info/${_.get(params, "profile")}/${
                                    params._id
                                }/${params.company}`
                            );
                        }
                    },
                    (reason) => {
                        toggleSnackbar(true, "Hubo un error al guardar");
                    }
                )
                .catch((data) => {
                    toggleSnackbar(true, "Hubo un error al guardar");
                });
        }
    };

    _onChangeEmail = (e, text) => {
        if (
            !_.isEqual(text, "") &&
            !_.isNull(text, "") &&
            !_.isUndefined(text, "")
        ) {
            if (!is.email(text)) {
                this.setState({
                    email: text,
                    emailError: "El correo electrónico no es válido",
                });
            } else {
                this.setState({email: text, emailError: ""});
            }
        } else {
            this.setState({
                email: text,
                emailError: "El correo electrónico no debe estar vació",
            });
        }
    };

    _onChangeTagForTable(value) {
        this.setState({textTag: value});
    }

    _onChangeValueTagForTable(value) {
        this.setState({textValueTag: value});
    }

    render() {
        const {
            params,
            nameProject,
            businessName,
            selected,
            textTagId
        } = this.props;
        const {emailError, email, textTag, textValueTag} = this.state;
        const body = [
            {
                field: "id",
                title: "Identificación",
                filter: true,
            },
            {
                field: "nameText",
                title: "Nombre",
                filter: true,
            },
            {
                field: "lastNameText",
                title: "Apellido(s)",
                filter: true,
            },
            {
                field: "email",
                title: "Correo electrónico",
            },
        ];
        const sizeSelected = _.size(selected);
        let extra = {
            company: _.get(params, "company"),
            profile: _.get(params, "profile"),
            project: _.get(params, "_id"),
            source: 'project_and_company'
        };
        if (!_.isNull(textValueTag) && !_.isNull(textTag)) {
            extra = _.chain(extra)
                .set("textTag", textTag)
                .set("textValueTag", textValueTag)
                .set("textTagId", textTagId)
                .value();
        }

        return (
            <Row>
                <AuthComponent
                    component={
                        <Col xs={12} mdOffset={2} md={8}>
                            <Paper style={{marginTop: 20, marginBottom: 20}}>
                                <ButtonBack url={`${urlBack}${params._id}/${params.company}`}/>
                                <Row>
                                    <Col xs>
                                        <div
                                            style={{
                                                width: "100%",
                                                background: blue600,
                                                paddingTop: 5,
                                                paddingBottom: 5,
                                                color: "#FFF",
                                            }}
                                        >
                                            <h2 style={{textAlign: "center", fontWeight: 400}}>
                                                {businessName}
                                            </h2>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs>
                                        <div
                                            style={{
                                                width: "100%",
                                                background: blue700,
                                                paddingTop: 5,
                                                paddingBottom: 5,
                                                color: "#FFF",
                                            }}
                                        >
                                            <h1
                                                style={{
                                                    textAlign: "center",
                                                    fontWeight: 400,
                                                    lineHeight: 1.3,
                                                }}
                                            >{`${LBLEMPLOYESS.titleAssociate} a ${nameProject}`}</h1>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs>
                                        <TagsEmployee
                                            companyId={_.get(params, "company")}
                                            URL={`/projects/employees/${_.get(
                                                params,
                                                "company"
                                            )}/${_.get(params, "_id")}`}
                                            version={2}
                                            extra={extra}
                                            body={body}
                                            onChangeTagForTable={this._onChangeTagForTable}
                                            onChangeValueTagForTable={this._onChangeValueTagForTable}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs>
                                        <TablePagination
                                            name="configemployeesave"
                                            URL={`/projects/employees/${_.get(
                                                params,
                                                "company"
                                            )}/${_.get(params, "_id")}`}
                                            version={2}
                                            body={body}
                                            selection
                                            fieldSelection="_id"
                                            extra={extra}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs>
                                        <div style={{padding: "3px 3px 3px 17px"}}>
                                            <TextField
                                                style={{marginTop: 10}}
                                                floatingLabelText="Correo electrónico"
                                                errorText={emailError}
                                                onChange={this._onChangeEmail}
                                                value={email}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs>
                                        <AuthComponent
                                            component={
                                                <RaisedButton
                                                    label={`${LBLEMPLOYESS.buttons.save} (${sizeSelected})`}
                                                    secondary
                                                    disabled={
                                                        (_.isEqual(email, "") &&
                                                            !_.isEqual(emailError, "")) ||
                                                        (_.isEmpty(email) && !_.isEmpty(emailError)) ||
                                                        !is.email(email)
                                                    }
                                                    style={{margin: 17, float: "right"}}
                                                    onClick={this.saveEmployeesAssociate}
                                                />
                                            }
                                            permission="backend/employees:write"
                                            type="component"
                                        />
                                    </Col>
                                </Row>
                            </Paper>
                        </Col>
                    }
                    permission="backend/employees:write"
                    type="url"
                />
            </Row>
        );
    }
}

function mapStateToProps(
    {companies, projects, tablePagination, tagsEmployee}
) {
    return {
        businessName: _.get(companies, "company.data.businessName", ""),
        nameProject: _.get(projects, "all.data.name", ""),
        selected: tablePagination.get("selected"),
        textTagId: tagsEmployee.get("textTag"),
    };
}

export default connect(mapStateToProps, {
    SaveAssociationEmployees,
    FetchCompanyParam,
    FetchProjectParam,
    setInitSelected,
    toggleSnackbar,
})(EmployeesGridAssoc);
