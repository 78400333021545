import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Col } from "react-flexbox-grid";
import { reduxForm } from "redux-form";
import Dialog from "material-ui/Dialog";
import { blue700 } from "material-ui/styles/colors";
import FlatButton from "material-ui/FlatButton";
import TextField from "material-ui/TextField";
import _ from "lodash";
import { LBLLOCATIONS } from "../../constants/labels";
import { toggleSnackbar } from "../../actions/commons";
import { closeModal, saveLocation } from "./actions";
import {
    changeFieldRecord,
    getRecords,
    resetOrderRecord,
    setInSearchRecords,
} from "../TablePagination/actions";

class FormLocationModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
        this._saveLocation = this._saveLocation.bind(this);
        this._handleCloseAction = this._handleCloseAction.bind(this);
    }

    _saveLocation(formData) {
        const {
            id,
            idParent,
            location,
            closeModal,
            resetForm,
            saveLocation,
            toggleSnackbar,
            changeFieldRecord,
            editMode,
            limit,
            resetOrderRecord,
            setInSearchRecords,
            getRecords,
        } = this.props;
        const idData = id;
        const fieldDataName = _.get(formData, "name");
        const fieldDataCode = _.get(formData, "code");

        const extraDepartment = { country: idParent };
        const extraCity = { department: idParent };

        saveLocation(
            id,
            fieldDataCode,
            fieldDataName,
            location,
            idParent,
            editMode
        )
            .then(
                (data) => {
                    closeModal();
                    resetForm();
                    if (
                        _.get(data, "error") ||
                        _.get(data, "payload.status") !== 200
                    ) {
                        toggleSnackbar(true, LBLLOCATIONS.msg.errorSave);
                    } else {
                        const textMsg = _.isNull(id)
                            ? "guardado"
                            : "modificado";
                        resetOrderRecord();
                        setInSearchRecords();
                        switch (location) {
                            case "country":
                                changeFieldRecord(
                                    idData,
                                    ["name", "code"],
                                    [fieldDataName, fieldDataCode]
                                );
                                toggleSnackbar(
                                    true,
                                    `País ${textMsg} con éxito`
                                );
                                getRecords(
                                    "/location/countries",
                                    1,
                                    0,
                                    limit,
                                    "null",
                                    0,
                                    ["name"],
                                    ""
                                );
                                break;
                            case "department":
                                changeFieldRecord(
                                    idData,
                                    ["name", "code"],
                                    [fieldDataName, fieldDataCode]
                                );
                                toggleSnackbar(
                                    true,
                                    `Departamento ${textMsg} con éxito`
                                );
                                getRecords(
                                    "/location/departments",
                                    1,
                                    0,
                                    limit,
                                    "null",
                                    0,
                                    ["name"],
                                    "",
                                    extraDepartment
                                );
                                break;
                            case "city":
                                changeFieldRecord(
                                    idData,
                                    ["name", "code"],
                                    [fieldDataName, fieldDataCode]
                                );
                                toggleSnackbar(
                                    true,
                                    `Municipio ${textMsg} con éxito`
                                );
                                getRecords(
                                    "/location/cities",
                                    1,
                                    0,
                                    limit,
                                    "null",
                                    0,
                                    ["name"],
                                    "",
                                    extraCity
                                );
                                break;
                        }
                    }
                },
                (reason) => {
                    toggleSnackbar(true, LBLLOCATIONS.msg.errorSave);
                    closeModal();
                    resetForm();
                }
            )
            .catch(() => {
                toggleSnackbar(true, LBLLOCATIONS.msg.errorSave);
                closeModal();
                resetForm();
            });
    }

    _handleCloseAction() {
        this.props.resetForm();
        this.props.closeModal();
        this.props.history.push("/location/countries")
    }

    render() {
        const {
            title,
            fields: { code, name },
            handleSubmit,
            open,
        } = this.props;
        return (
            <Dialog
                title={title}
                titleStyle={{ textAlign: "center", color: blue700 }}
                contentStyle={{
                    maxWidth: 600,
                    minWidth: 300,
                    textAlign: "justify",
                }}
                autoScrollBodyContent
                autoDetectWindowHeight
                modal={false}
                open={open}
                onRequestClose={this._handleCloseAction}
            >
                <form onSubmit={handleSubmit(this._saveLocation)}>
                    <Row>
                        <Col xsOffset={2} xs={2}>
                            <TextField
                                ref="code"
                                hintText={LBLLOCATIONS.code}
                                style={{ width: "70%", marginRight: 20 }}
                                floatingLabelText={LBLLOCATIONS.code}
                                errorText={
                                    code.touched && code.error ? code.error : ""
                                }
                                type="text"
                                {...code}
                            />
                        </Col>
                        <Col xs={8}>
                            <TextField
                                ref="name"
                                hintText={LBLLOCATIONS.name}
                                floatingLabelText={LBLLOCATIONS.name}
                                type="text"
                                errorText={
                                    name.touched && name.error ? name.error : ""
                                }
                                {...name}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xsOffset={6} xs={6}>
                            <div style={{ marginTop: 20 }}>
                                <FlatButton
                                    label={LBLLOCATIONS.buttons.cancel}
                                    secondary
                                    onClick={this._handleCloseAction}
                                />
                                <FlatButton
                                    style={{ marginLeft: 10 }}
                                    label={LBLLOCATIONS.buttons.save}
                                    primary
                                    type="submit"
                                />
                            </div>
                        </Col>
                    </Row>
                </form>
            </Dialog>
        );
    }
}

const validate = (values) => {
    const errors = {};
    if (!values.code) {
        errors.code = LBLLOCATIONS.validations.code;
    }
    if (!values.name) {
        errors.name = LBLLOCATIONS.validations.name;
    }
    return errors;
};

function mapStateToProps({ formLocation, tablePagination }, ownProps) {
    return {
        initialValues: {
            code: formLocation.get("code"),
            name: _.capitalize(formLocation.get("name")),
        },
        title: formLocation.get("title"),
        open: formLocation.get("openModal"),
        location: formLocation.get("location"),
        id: formLocation.get("id"),
        idParent: formLocation.get("idParent"),
        editMode: formLocation.get("editMode"),
        limit: tablePagination.get("limit"),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            toggleSnackbar,
            closeModal,
            saveLocation,
            changeFieldRecord,
            getRecords,
            resetOrderRecord,
            setInSearchRecords,
        },
        dispatch
    );
}

export default reduxForm(
    {
        form: "LocationForm",
        fields: ["code", "name"],
        validate,
    },
    mapStateToProps,
    mapDispatchToProps
)(FormLocationModal);
