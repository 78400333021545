import {IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Typography} from '@material-ui/core';
import * as React from 'react'
import DeleteIcon from '@material-ui/icons/Delete'
import {Col, Row} from "react-flexbox-grid";
import {makeStyles} from "@material-ui/core/styles";
import AlertDialog from "../../../../commons/alert-dialog";
import {useRemoveDatasetColumnSource} from "../../../data";
import {useNotifications} from "Notifications";
import {metadata} from "./options";

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 12
    },
    deleteButton: {
        marginLeft: 12
    }
}));

type SourcesListProps = {
    column: any
    datasetId: string
}

/**
 * Component to render column information sources
 * @author Jhoan Lopez <jhoan.lopez@ias.com.co>
 * @param column
 * @param datasetId
 * @constructor
 */
const SourcesList: React.FC<SourcesListProps> = ({column, datasetId}) => {
    const classes = useStyles();
    const [openDelete, setOpenDelete] = React.useState(false);
    const [mutate, {loading, error, client}] = useRemoveDatasetColumnSource();
    const {sendNotification} = useNotifications();

    const handleClickOpen = () => {
        setOpenDelete(true);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    const handleRemoveDatasetColumnSource = async () => {
        await mutate({
            variables: {request: {datasetId, columnId: column.id, source: "country"}},
            optimisticResponse: {}
        })
            .then((res) => {
                handleCloseDelete()
                sendNotification('Fuente eliminada correctamente');
            })
            .catch(() => {
                sendNotification('Ocurrió un error al eliminar la fuente');
            })
    }

    return (
        <Row className={classes.root}>
            <Col xs>
                <Row center="xs">
                    {column.sources.length === 0 ?
                        <Typography variant="caption">No se han agregado fuentes de información</Typography> :
                        <div>
                            <Typography>Fuentes de información</Typography>
                            <List dense>
                                {column?.sources.map((source, key) => (
                                    <ListItem key={`${key}-${source}`}>
                                        <ListItemText
                                            secondary={`${metadata[column.type]} ${source}`}
                                        />
                                        <ListItemSecondaryAction className={classes.deleteButton}>
                                            <IconButton edge="end" aria-label="delete" onClick={handleClickOpen}
                                                        disableRipple disableTouchRipple disableFocusRipple
                                                        size="small">
                                                <DeleteIcon/>
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))
                                }
                            </List>
                        </div>
                    }
                </Row>
            </Col>
            <AlertDialog title="Eliminar fuente" description="" open={openDelete}
                         handleClose={handleCloseDelete} handleAccept={handleRemoveDatasetColumnSource}
                         disabled={loading}/>
        </Row>
    )
}

export default SourcesList
