import _ from "lodash";
import {
  GET_VALUES_TAGS_COMPANY,
  GET_VALUES_TAGS_EMPLOYEE,
  CHANGE_VALUE_TAG,
  CHANGE_TAG,
  CLEAN_VALUES_TAG_EMPLOYEE,
} from "./constants";
import { axiosApiV2 } from "../../middleware/api";

export function getValuesTagsCompany(idCompany) {
  const request = axiosApiV2.get(`/employees/valuestags/${idCompany}`);

  return {
    type: GET_VALUES_TAGS_COMPANY,
    payload: request,
  };
}

export function getValuesTagsEmployee(idCompany, tag) {
  const request = axiosApiV2.get(`/employees/valuestags/tag/${idCompany}`, {
    params: {
      tag: _.deburr(tag),
    },
  });

  return {
    type: GET_VALUES_TAGS_EMPLOYEE,
    payload: request,
  };
}

export function setValueTagEmployee(textValueTag) {
  return {
    type: CHANGE_VALUE_TAG,
    meta: {
      textValueTag,
    },
  };
}

export function setTagEmployee(textTag) {
  return {
    type: CHANGE_TAG,
    meta: {
      textTag,
    },
  };
}

export function cleanTagEmployee() {
  return {
    type: CLEAN_VALUES_TAG_EMPLOYEE,
  };
}
