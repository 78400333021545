import * as actions from "./constants";
import { axiosApiV2 } from "../../middleware/api";

export const SAVE_GROUP_GROUPER = "SAVE_GROUP_GROUPER";

export function saveGroupGroupers(formdata, groupers, idSurvey) {
  const level = getNextLevelGrouper(groupers);
  const children = _.chain(groupers)
    .map((group) => {
      return group.id;
    })
    .value();
  const grouper = {
    name: _.get(formdata, "name"),
    survey: idSurvey,
    typeEvaluation: _.get(formdata, "typeEvaluation"),
    visible: _.get(formdata, "visible"),
    priority: _.get(formdata, "priority"),
    level,
    children,
  };
  console.clear();
  const request = axiosApiV2.post(`/surveygroupers/grouper`, { grouper });
  return {
    type: SAVE_GROUP_GROUPER,
    payload: request,
  };
}

export function initialState() {
  return {
    type: actions.INITIAL_STATE,
  };
}

export function addGrouper(grouper) {
  return {
    type: actions.ADD_GROUPER,
    grouper,
  };
}

export function removeGrouper(idGrouper) {
  return {
    type: actions.REMOVE_GROUPER,
    idGrouper,
  };
}

function getNextLevelGrouper(groupers) {
  let level = 0;
  groupers.map((group) => {
    level = group.level > level ? group.level : level;
  });
  return level + 1;
}
