import { axiosAPI, axiosApiV2 } from "../../../middleware/api";
import * as actions from "./constants";

export function toggleProject(projectId, current) {
  return {
    type: actions.TOGGLE_PROJECT,
    project: projectId,
    current,
  };
}

export async function loadProjects(userId) {
  const request = await axiosAPI.get(`/projects/employee/${userId}`);
  return {
    type: actions.LOAD_PROJECTS,
    payload: request,
  };
}

export function initialProjects() {
  return {
    type: actions.INITIAL_DASHBOARD,
    payload: {},
  };
}

export function cleanDashboardEmployeeProjects() {
  return {
    type: actions.CLEAN_DASHBOARD_EMPLOYEE_PROJECT,
  };
}

export function fetchSolutionProcessed(projectId, personId) {
  const request = axiosApiV2.get(
    `/solutions/employee/processed/${projectId}/${personId}`
  );
  return {
    type: actions.FIND_SOLUTIONS_PROCESSED,
    payload: request,
  };
}

export function setLoadingProjects(state){
  return {
    type: actions.LOADING_PROJECTS,
    payload: state
  }
}
