import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import TextField from "@material-ui/core/TextField";

const FormikTextField = ({
    label,
    helperText,
    fullWidth,
    multiline,
    rows,
    ...props
}) => {
    const [field, meta] = useField(props);
    const hasError = meta.error && meta.touched;
    const helperContent = hasError ? meta.error : helperText;
    return (
        <TextField
            onChange={field.onChange}
            onBlur={field.onBlur}
            value={field.value}
            name={field.name}
            error={hasError}
            id={field.name}
            label={label}
            helperText={helperContent}
            fullWidth={fullWidth}
            rows={rows}
            multiline={multiline}
        />
    );
};

FormikTextField.defaultProps = {
    helperText: "",
    fullWidth: false,
    multiline: false,
    rows: 1,
};

FormikTextField.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    helperText: PropTypes.string,
    fullWidth: PropTypes.bool,
    multiline: PropTypes.bool,
    rows: PropTypes.number,
};

export default FormikTextField;
