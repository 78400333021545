import React, { Component } from "react";
import { Row, Col } from "react-flexbox-grid";
import Paper from "material-ui/Paper";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { blue700 } from "material-ui/styles/colors";
import { LBLCONSENTS } from "../../constants/labels";
import FloatingActionButtonTooltip from "../fields/FloatingActionButtonTooltip/index.tsx";
import AuthComponent from "../AuthComponent";
import TablePagination from "../TablePagination/tablePagination";

const stylePaper = {
  marginTop: 20,
  marginBottom: 20,
};

class ConsentsGrid extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const body = [
      {
        field: "name",
        title: "Nombre",
        filter: true,
        colspan: 3,
      },
      {
        actions: true,
        title: "Operaciones",
      },
    ];
    return (
      <Row>
        <AuthComponent
          component={
            <Col mdOffset={2} md={8} xs={12}>
              <Paper style={stylePaper}>
                <Row>
                  <Col xs>
                    <Row>
                      <Col xs>
                        <div
                          style={{
                            width: "100%",
                            background: blue700,
                            paddingTop: 5,
                            paddingBottom: 5,
                            color: "#FFF",
                          }}
                        >
                          <h1 style={{ textAlign: "center", fontWeight: 400 }}>
                            {LBLCONSENTS.titleGrid}
                          </h1>
                          <AuthComponent
                            component={
                                <FloatingActionButtonTooltip
                                  textTooltip={LBLCONSENTS.tooltips.btnCreate}
                                  to="/admin/consents/create"
                                  component={Link}
                                />
                            }
                            permission="backend/consents:write"
                            type="component"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs>
                        <TablePagination
                          name="consents"
                          URL="/consents"
                          version={1}
                          body={body}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Paper>
            </Col>
          }
          permission="backend/consent:read"
          type="url"
        />
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return { consents: state.consents.all };
}

export default connect(mapStateToProps)(ConsentsGrid);
