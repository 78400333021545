import PropTypes from "prop-types";
import React, { Component } from "react";
import { addArrayValue, reduxForm } from "redux-form";
import { Row, Col } from "react-flexbox-grid";
import TextField from "material-ui/TextField";
import Divider from "material-ui/Divider";
import RaisedButton from "material-ui/RaisedButton";
import Delete from "material-ui/svg-icons/action/delete";
import IconButton from "material-ui/IconButton";
import _ from "lodash";
import Checkbox from "material-ui/Checkbox";
import { blue700 } from "material-ui/styles/colors";
import { saveCoi } from "./editorDucks";
import {
    joinErrors,
    requireFields,
    evaluateRanges,
    evaluateValueMaxAndMin,
} from "./metadataEditorFunctions";

const validateCoi = (data) => {
    const errors = {};
    const requiredValidation = requireFields(
        "label",
        "initialValue",
        "endValue"
    );
    const rangeOverlap = evaluateRanges(data.coi);
    const valueMaxAndMin = evaluateValueMaxAndMin(data.coi);
    errors.coi = data.coi
        .map(requiredValidation)
        .map(joinErrors(valueMaxAndMin))
        .map(joinErrors(rangeOverlap));
    return errors;
};

const filterDefaultCoiType = (type) => (coi) => {
    const value = _.get(coi, "type.value", "SE");
    const typeOptional = _.isNil(value) || _.isEqual(value, "") ? "SE" : value;
    return _.isEqual(typeOptional, type);
};

class EditorItem extends Component {
    static propTypes = {
        surveyId: PropTypes.string.isRequired,
        grouperId: PropTypes.string.isRequired,
        conditionId: PropTypes.string.isRequired,
        maxValues: PropTypes.number.isRequired,
        type: PropTypes.oneOf(["SE", "EE"]),
    };

    render() {
        const {
            fields: { coi },
            handleSubmit,
            surveyId,
            grouperId,
            conditionId,
            saveCoi,
            maxValues,
            type,
        } = this.props;
        const elementsToRender = coi.filter(filterDefaultCoiType(type));
        const mappedCoi = elementsToRender.map(
            ({ label, initialValue, endValue, coiDefault }, i) => {
                return (
                    <div key={`value-coi-${i}`}>
                        <Row>
                            <Col xs>
                                <Row>
                                    <Col xs>
                                        <TextField
                                            fullWidth
                                            ref="label"
                                            hintText="Ej: Interpretación X"
                                            floatingLabelText="Condición de interpretación"
                                            type="text"
                                            multiLine
                                            rows={2}
                                            {...label}
                                            errorText={
                                                label.touched && label.error
                                                    ? label.error
                                                    : ""
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={2} md={2}>
                                        <div style={{ width: 140 }}>
                                            <div
                                                style={{
                                                    display: "inline-block",
                                                }}
                                            >
                                                <TextField
                                                    ref="initialValue"
                                                    hintText="Ej: 20"
                                                    floatingLabelText="Valor inicial (%)"
                                                    style={{ width: 120 }}
                                                    type="number"
                                                    errorText={
                                                        (initialValue.touched ||
                                                            initialValue.value) &&
                                                        initialValue.error
                                                            ? initialValue.error
                                                            : ""
                                                    }
                                                    {...initialValue}
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    display: "inline-block",
                                                }}
                                            >
                                                <h3
                                                    style={{
                                                        textAlign: "left",
                                                        fontWeight: 400,
                                                    }}
                                                >
                                                    %
                                                </h3>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col
                                        xs={2}
                                        md={2}
                                        mdOffset={1}
                                        xsOffset={1}
                                    >
                                        <div
                                            style={{
                                                width: 140,
                                                paddingLeft: 10,
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "inline-block",
                                                }}
                                            >
                                                <TextField
                                                    ref="endValue"
                                                    hintText="Ej: 70"
                                                    floatingLabelText="Valor final (%)"
                                                    style={{ width: 120 }}
                                                    type="number"
                                                    errorText={
                                                        (endValue.touched ||
                                                            endValue.value) &&
                                                        endValue.error
                                                            ? endValue.error
                                                            : ""
                                                    }
                                                    {...endValue}
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    display: "inline-block",
                                                }}
                                            >
                                                <h3
                                                    style={{
                                                        textAlign: "left",
                                                        fontWeight: 400,
                                                    }}
                                                >
                                                    %
                                                </h3>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col
                                        md={1}
                                        xs={1}
                                        mdOffset={2}
                                        xsOffset={2}
                                    >
                                        <IconButton
                                            style={{ marginTop: "8px" }}
                                            tooltip="Eliminar"
                                            onClick={() => coi.removeField(i)}
                                        >
                                            <Delete />
                                        </IconButton>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {_.isEqual(type, "EE") && (
                            <Row style={{ paddingTop: 20 }}>
                                <Col xs={4} xsOffset={0}>
                                    <Checkbox
                                        label="Condición por defecto"
                                        checked={_.get(
                                            coiDefault,
                                            "value",
                                            false
                                        )}
                                        defaultChecked={_.get(
                                            coiDefault,
                                            "initial",
                                            false
                                        )}
                                        style={{ marginBottom: 16 }}
                                        onCheck={(e, isCheck) =>
                                            coiDefault.onChange(isCheck)
                                        }
                                    />
                                </Col>
                                <Col xs={6} xsOffset={1}>
                                    {_.get(coiDefault, "value", false) && (
                                        <h4
                                            style={{
                                                fontWeight: 400,
                                                margin: 0,
                                                textAlign: "justify",
                                                color: blue700,
                                            }}
                                        >
                                            Si los resultados de este agrupador, no pertenecen a ningún rango configurado; éste será el valor por defecto en el informe.
                                        </h4>
                                    )}
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col xs>
                                <Divider
                                    style={{ marginTop: 23, marginBottom: 23 }}
                                />
                            </Col>
                        </Row>
                    </div>
                );
            }
        );
        return (
            <form
                onSubmit={handleSubmit((data) =>
                    saveCoi(data.coi, surveyId, grouperId, conditionId, type)
                )}
            >
                <Row>
                    <Col xs={10} xsOffset={1} md={10} mdOffset={1}>
                        <Row>
                            <Col xs={12} md={12}>
                                <Row>
                                    <Col xs={8} md={8}>
                                        <h3>Condiciones de interpretación</h3>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs>{mappedCoi}</Col>
                                </Row>
                                <Row>
                                    <Col xs>
                                        <RaisedButton
                                            label="Agregar Condición"
                                            style={{
                                                margin: 12,
                                                float: "right",
                                                display:
                                                    _.isEqual(type, "EE") &&
                                                    _.gte(
                                                        elementsToRender.length,
                                                        maxValues
                                                    )
                                                        ? "none"
                                                        : "inline",
                                            }}
                                            onClick={() => {
                                                coi.addField({ type });
                                            }}
                                            disabled={_.gte(
                                                elementsToRender.length,
                                                maxValues
                                            )}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <RaisedButton
                                    label="Guardar cambios"
                                    disableTouchRipple
                                    disableFocusRipple
                                    primary
                                    type="submit"
                                    style={{ marginBottom: 20 }}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </form>
        );
    }
}

export default (nameForm, data, type, maxValues) => {
    return reduxForm(
        {
            form: `${nameForm}_${type}`,
            fields: [
                "coi[].label",
                "coi[].initialValue",
                "coi[].endValue",
                "coi[].type",
                "coi[].coiDefault",
            ],
            validate: validateCoi,
        },
        () => ({
            // mapStateToProps
            initialValues: data,
            maxValues,
            type,
        }),
        {
            addValue: addArrayValue,
            saveCoi,
        }
    )(EditorItem);
};
