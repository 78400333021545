import React, { Component } from "react";
import MenuItem from "material-ui/MenuItem";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { getCities } from "./actions";
import SelectFieldFormat from "../SelectFieldFormat/SelectFieldFormat";

class SelectCities extends Component {
    state = {
        firstInto: false,
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { initialValue } = nextProps;
        if (!_.isUndefined(initialValue) && !this.state.firstInto) {
            nextProps.getCities(initialValue);
            this.setState({
                firstInto: true,
            });
        }
    }

    render() {
        const { config, field, cities } = this.props;
        return (
            <SelectFieldFormat
                config={_.assign(
                    {
                        value: field.value,
                        autoWidth: true,
                        errorText: field.touched && field.error ? field.error : "",
                        onChange: (e, index, value) => {
                            field.onChange(value);
                        },
                    },
                    { ...config },
                )}
            >
                {_.orderBy(cities, ["name"]).map(item => (
                    <MenuItem key={item._id} value={item._id} primaryText={item.name} />
                ))}
            </SelectFieldFormat>
        );
    }
}

function mapStateToProps({ master }) {
    return {
        cities: master.cities,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getCities,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectCities);
