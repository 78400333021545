import PropTypes from "prop-types";
import React, { Component } from "react";
import _ from "lodash";
import SelectField from "material-ui/SelectField";

const styles = {
    field: {
        zIndex: 10,
        minHeight: 72,
        height: "auto",
    },
    labelStyle: {
        whiteSpace: "normal",
        lineHeight: 1.3,
        paddingTop: 20,
        paddingBottom: 5,
        height: "auto",
    },
    floatingLabelStyle: {
        zIndex: "-1",
    },
    errorStyle: {
        marginTop: 38,
        marginBottom: -36,
    },
};

/* const styles = {
    field: {
        zIndex: 10,
        lineHeight: 2,
        minHeight: 72,
    },
    labelStyle: {
        lineHeight: 1.3,
        paddingTop: 15,
        paddingBottom: 5,
        whiteSpace: "normal",
        minHeight: 23,
    },
    floatingLabelStyle: {
        zIndex: "-1",
    },
    errorStyle: {
        marginTop: 32,
        marginBottom: -41,
    },
}; */

function hasErrorText(config) {
    if (_.has(config, "errorText")) {
        return !_.isEmpty(_.get(config, "errorText", null));
    }
    return false;
}

function hasValue(config) {
    if (_.has(config, "value")) {
        return !_.isEmpty(_.get(config, "value", null)) && !_.isEqual(_.get(config, "value", ""), "");
    }
    return false;
}

function getStyleLabel(config) {
    if (hasErrorText(config) && hasValue(config)) {
        return { marginBottom: 0 };
    }
    return { marginBottom: 15 };
}

function getStyleField(config) {
    if (!hasErrorText(config)) {
        return { paddingBottom: 18 };
    }
    return {};
}

function getStyleErrorText(config) {
    if (!hasValue(config)) {
        return { marginTop: 32 };
    }
    return {};
}

class SelectFieldFormat extends Component {
    static propTypes = {
        config: PropTypes.object.isRequired,
    };

    render() {
        const { config, children } = this.props;
        const styleExtraLabel = getStyleLabel(config);
        const styleExtraField = getStyleField(config);
        const styleExtraErrorText = getStyleErrorText(config);
        return (
            <SelectField
                floatingLabelStyle={{ ...styles.floatingLabelStyle }}
                labelStyle={{ ...styles.labelStyle, ...styleExtraLabel }}
                errorStyle={{ ...styles.errorStyle, ...styleExtraErrorText }}
                style={{ ...styles.field }}
                autoWidth
                {...config}
            >
                {children}
            </SelectField>
        );
    }
}

export default SelectFieldFormat;
