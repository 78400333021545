import _ from "lodash";
import { axiosAPI } from "Api";
import {
  POST_CONSENT,
  LOAD_ALL_CONSENT,
  LOAD_APPROVAL_CONSENTS,
  POST_APPROVAL_CONSENTS,
  DELETE_CONSENT,
  LOADING_QUERIES,
} from "../constants/index";
import { EXPRESSION_STRING } from "../constants/regularExpressions";

export function createConsent(props, editMode) {
  function getProps() {
    const placeholders = [];
    const properties =
      _.isUndefined(props._id) || _.isEqual(props._id, "")
        ? _.omit(props, ["_id"])
        : props;
    if (_.get(properties, "agreement")) {
      let index = 1;
      const values = properties.agreement.split(/(?:\r\n\s|\r|\n|\s)/g);
      _.each(values, function (value) {
        if (value.indexOf("##") !== -1) {
          if (value.length > 2) {
            const NombreCampo = value.replace("##", "").split("");
            let Campo = "";
            let bandera = true;
            _.each(NombreCampo, function (nombre) {
              if (EXPRESSION_STRING.test(nombre) && bandera) {
                Campo += nombre;
              } else {
                bandera = false;
              }
            });
            placeholders.push({
              id: index,
              description: Campo,
              title: Campo.replace("##", "")
                .split(/(?=[A-Z])/)
                .join(" ")
                .toLowerCase(),
            });
            index++;
          }
        }
      });
    }

    return {
      consent: {
        ...properties,
        placeholders,
        required: _.get(properties, "required") == "1",
      },
    };
  }

  let request = null;
  if (editMode) {
    request = axiosAPI.put(`/consents`, getProps());
  } else {
    request = axiosAPI.post(`/consents`, getProps());
  }

  return {
    type: POST_CONSENT,
    payload: request,
  };
}

export function createApprovalConsents(props, userId, companyId) {
  function getPropsAccept() {
    const approvalConsent = {
      employee: userId,
      project: _.get(props, "project"),
      consents: _.get(props, "consents"),
      acceptSurvey: true,
      company: companyId,
    };
    approvalConsent.consents = approvalConsent.consents.map((consent) => {
      consent = _.set(consent, "name", _.get(consent, "_id.name"));
      consent = _.set(consent, "time", _.get(consent, "fecha"));
      consent = _.set(consent, "accept", _.get(consent, "accept") === "true");
      return _.omit(consent, ["value", "fecha", "_id", "approval"]);
    });
    return { approval: approvalConsent };
  }

  const request = axiosAPI.post(`/approvalconsents`, getPropsAccept());

  return {
    type: POST_APPROVAL_CONSENTS,
    payload: request,
  };
}

export function declineSurvey(project, consents, userId, companyId) {
  function getPropsAccept() {
    const approvalConsent = {
      employee: userId,
      project,
      consents,
      acceptSurvey: false,
      company: companyId,
    };
    approvalConsent.consents = approvalConsent.consents.map((consent) => {
      consent = _.set(consent, "name", _.get(consent, "_id.value.name"));
      consent = _.set(
        consent,
        "time",
        !_.isEmpty(_.get(consent, "fecha.value"))
          ? _.get(consent, "fecha.value")
          : new Date()
      );
      consent = _.set(consent, "consent", _.get(consent, "consent.value"));
      consent = _.set(consent, "required", _.get(consent, "required.value"));
      consent = _.set(
        consent,
        "accept",
        _.get(consent, "accept.value") === "true"
      );
      return _.omit(consent, ["value", "fecha", "_id", "approval"]);
    });
    return { approval: approvalConsent };
  }

  const request = axiosAPI.post(`/approvalconsents`, getPropsAccept());

  return {
    type: POST_APPROVAL_CONSENTS,
    payload: request,
  };
}

export function loadConsentsProject() {
  const request = axiosAPI.get(`/consents`);

  return {
    type: LOAD_ALL_CONSENT,
    payload: request,
  };
}

export function loadConsentsProjectName() {
  const request = axiosAPI.get(`/consents/names`);

  return {
    type: LOAD_ALL_CONSENT,
    payload: request,
  };
}

/**
 * @param employee
 * @param project
 */
export async function loadApprovalConsentsProject(employee, project) {
  const request = await axiosAPI.get(`/approvalconsents/${employee}/${project}`);

  return {
    type: LOAD_APPROVAL_CONSENTS,
    payload: request,
  };
}

export function deleteConsent(consentId) {
  const request = axiosAPI.delete(`/consents/${consentId}`, {
    data: { type: "logic" },
  });

  return {
    type: DELETE_CONSENT,
    payload: request,
    meta: {
      consentId,
    },
  };
}

export function setLoadingQueries(state){
  return {
    type: LOADING_QUERIES,
    payload: state
  }
}
