import _ from "lodash";
import {
  POST_PROFILE,
  ADD_PERMISSION,
  CLEAR_PROFILE,
} from "../constants/index";
import { axiosApiV2 } from "../middleware/api";

export function createProfile(props, permissions, editMode) {
  let request = null;
  if (editMode) {
    request = axiosApiV2.put(`/profiles`, {
      profile: _.set(props, "permissions", permissions),
    });
  } else {
    request = axiosApiV2.post(`/profiles`, {
      profile: _.set(props, "permissions", permissions),
    });
  }

  return {
    type: POST_PROFILE,
    payload: request,
  };
}

export function addPermission(value, idPermission) {
  return {
    type: ADD_PERMISSION,
    meta: {
      value,
      idPermission,
    },
  };
}

export function ClearState() {
  return {
    type: CLEAR_PROFILE,
  };
}
