import { GET_DOCUMENTS_TYPES } from "../../constants/index";
import { axiosAPI, axiosApiV2 } from "../../middleware/api";

export const GET_REFEREE_INFO = "GET_REFEREE_INFO";
export const GET_STUDY_LEVELS = "GET_STUDY_LEVELS";

export function FetchRefereeParam(id) {
  const request = axiosAPI.get(`/referees/${id}`);

  return {
    type: GET_REFEREE_INFO,
    payload: request,
  };
}

export function getDocumentsTypes() {
  const request = axiosApiV2.get(`/master/documentsTypes`);

  return {
    type: GET_DOCUMENTS_TYPES,
    payload: request,
  };
}

export function getStudyLevels() {
  const request = axiosApiV2.get(`/master/studiesLevels`);

  return {
    type: GET_STUDY_LEVELS,
    payload: request,
  };
}
