import Immutable from 'immutable';
import _ from 'lodash';
import {LOAD_DATA_FN_TABLE, DELETE_USER, CLEAN_TABLE_USER} from '../actions/fnTable';

const initialState = Immutable.Map({
    users: Immutable.List(),
    persons: Immutable.List()
});

export default (state = initialState, action) => {
    switch (action.type) {
        case LOAD_DATA_FN_TABLE:
            var {storeName} = action.meta;
            var currentStore = state.get(storeName);
            var data = _.get(action, 'payload.data', []);
            var dataConcat = currentStore.concat(data);
            var dataFilter = _.uniqBy(dataConcat.toArray(), "_id");
            dataFilter = _.orderBy(dataFilter, ['person.name', 'person.lastName'], ['asc', 'asc']);
            return state.set(storeName, Immutable.List(dataFilter));
        case DELETE_USER:
            var currentStoreDelete = state.get("users");
            var dataFilterDelete = _.filter(currentStoreDelete.toArray(),
                (user) => {
                return !_.isEqual(action.meta.userId, user._id);
            });
            return state.set("users", Immutable.List(dataFilterDelete));
        case CLEAN_TABLE_USER:
            return state.set(action.meta.storeName, Immutable.List());
        default:
            return state;
    }
};
