import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Col, Row } from "react-flexbox-grid";
import {
  blue700,
  orange700,
  green700,
  red700,
  purple700,
  brown700,
} from "material-ui/styles/colors";
import _ from "lodash";
import moment from "moment";
import {
  loadProjects,
  cleanDashboardEmployeeProjects,
  setLoadingProjects,
} from "./actions";
import { idEmployeeTest } from "../../../constants/labels";
import { spanishLocale } from "../../../constants/index";
import ProjectCardComponent from "../commons/ProjectCardComponent";
import ProjectCardActions from "./projectCardActionsEmployee";
import LoadingPage from "../../commons/loadingPage";

class EmployeeDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: "",
    };
    this._renderProjects = this._renderProjects.bind(this);
  }

  async UNSAFE_componentWillMount() {
    const {
      profile,
      loadProjects,
      loggedIn,
      cleanDashboardEmployeeProjects,
      setLoadingProjects,
    } = this.props;
    cleanDashboardEmployeeProjects();
    if (loggedIn) {
      this.setState({
        userId: _.get(profile, "person._id"),
      });
      setLoadingProjects(true);
      await loadProjects(_.get(profile, "person._id"));
      setLoadingProjects(false);
    } else {
      this.setState({
        userId: idEmployeeTest,
      });
      console.error("No hay un usuario logeado");
      this.props.history.push(`/login`);
    }
  }

  convertDates(openDate, closeDate) {
    return `De ${moment(openDate).format("DD/MMM/YYYY")} hasta ${moment(
      closeDate
    ).format("DD/MMM/YYYY")}`;
  }

  _renderProjects(project, idx) {
    const projectName = project.name;
    const projectInactive = _.isUndefined(_.get(project, "inactive"))
      ? 20
      : _.get(project, "inactive");
    const idProject = project._id;
    const projectDescription = project.description;
    const businessId = project.company._id;
    const { businessName } = project.company;
    const businessAcronym = project.company.acronym;
    let status = "";
    const statusData = {
      toInit: {
        textButton: "Comenzar",
        URlAction: `/dashboard/employee/project/appl/empl/${project._id}/${this.state.userId}`,
      },
      notStarted: {
        textButton: "No iniciado",
        disabled: true,
        color: `${purple700}`,
      },
      finished: {
        textButton: "Finalizado",
        disabled: true,
        color: `${green700}`,
      },
      viewResults: {
        textButton: "Ver resultados",
        disabled: true,
        URlAction: `/dashboard/employee/projects/empl/${project._id}/surveys/${project.survey}/employee/${this.state.userId}/results`,
        color: `${green700}`,
      },
      toContinue: {
        textButton: "Continuar",
        URlAction: `/dashboard/employee/survey/appl/${project.survey}/${
          project.totalSections + 1
        }/${project._id}`,
        color: `${orange700}`,
      },
      notEnded: {
        textButton: "No terminado",
        disabled: true,
        color: `${brown700}`,
      },
      declined: {
        textButton: "Rechazado",
        disabled: true,
        color: `${red700}`,
      },
    };

    if (_.isEqual(project.finalized, true)) {
      status = "finished";
      if (_.isEqual(project.solutionProcessed, true)) {
        status = "viewResults";
      }
    } else {
      if (
        _.isEqual(project.hasSolution, false) &&
        _.isEqual(project.hasApprovalConsent, false)
      ) {
        status = "toInit";
        if (moment().isAfter(moment(project.time.closeDate))) {
          status = "notStarted";
        }
      }
      if (
        _.isEqual(project.hasSolution, true) &&
        _.isEqual(project.hasApprovalConsent, true)
      ) {
        status = "toContinue";
        if (moment().isAfter(moment(project.time.closeDate))) {
          status = "notEnded";
        }
      }
      if (
        _.isEqual(project.hasApprovalConsent, true) &&
        _.isEqual(project.acceptSurvey, false)
      ) {
        status = "declined";
      }
      if (
        _.isEqual(project.hasApprovalConsent, true) &&
        _.isEqual(project.hasSolution, false) &&
        _.isEqual(project.acceptSurvey, true)
      ) {
        status = "toContinue";
        if (moment().isAfter(moment(project.time.closeDate))) {
          status = "notStarted";
        }
      }
    }
    return (
      <Row key={idx}>
        <Col xs>
          <ProjectCardComponent
            key={idx}
            urlAction={_.get(statusData, `${status}.URlAction`, "")}
            projectDates={this.convertDates(
              project.time.openDate,
              project.time.closeDate
            )}
            buttonName={_.get(statusData, `${status}.textButton`, "")}
            buttonDisabled={_.get(statusData, `${status}.disabled`, false)}
            projectColor={_.get(statusData, `${status}.color`, `${blue700}`)}
            businessId={businessId}
            projectName={projectName}
            projectDays={_.get(project, "time.dayWeek", [])}
            projectDescription={projectDescription}
            businessName={businessName}
            businessAcronym={businessAcronym}
            CardActionsComponent={ProjectCardActions}
            projectTime={project.time}
            projectId={project._id}
            projectFinalized={project.finalized}
            projectInactive={projectInactive}
            idProject={idProject}
            user="employee"
          />
        </Col>
      </Row>
    );
  }

  render() {
    const { projects, loadingProjects } = this.props;
    moment.locale(spanishLocale);

    if (loadingProjects) {
      return <LoadingPage visible text="Cargando evaluaciones" size={80} />;
    }

    if (projects.length === 0 && !loadingProjects) {
      return (
        <h1
          style={{
            marginLeft: "35%",
            marginTop: "20%",
            fontSize: "300%",
            fontFamily: "inherit",
            color: "#808080",
          }}
        >
          No se encontraron evaluaciones asociadas
        </h1>
      );
    }

    return (
      <Row style={{ paddingTop: 80 }}>
        <Col xs={12} mdOffset={2} md={8}>
          {_.map(projects, this._renderProjects)}
        </Col>
      </Row>
    );
  }
}

function mapStateToProps({ dashboard, login }) {
  return {
    projects: dashboard.get("projects"),
    profile: login.get("profile"),
    loggedIn: login.get("loggedIn"),
    loadingProjects: dashboard.get("loadingProjects"),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadProjects,
      cleanDashboardEmployeeProjects,
      setLoadingProjects,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeDashboard);
