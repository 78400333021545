import { axiosAPI } from '../../middleware/api';

export const GET_LIST_GRID = "GET_LIST_GRID";

export function FetchList() {

    const request = axiosAPI.get(`/list`);

    return {
        type: GET_LIST_GRID,
        payload: request
    }
}
