import Immutable from 'immutable';
import _ from 'lodash';
import { OPEN_MODAL_FORM_GROUPER, CLOSE_MODAL_FORM_GROUPER } from './constants';

const INITIAL_STATE = Immutable.Map({
    id: null,
    name: "",
    openModalGrouper: false,
    visible: "false",
    priority: 0
});

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case OPEN_MODAL_FORM_GROUPER:
            return state.withMutations(s => {
                        s.set('id', _.get(action, 'meta.id', null));
                        s.set('name', _.get(action, 'meta.name', ""));
                        s.set('openModalGrouper', true);
                        s.set('visible', _.get(action, 'meta.visible', "false"));
                        s.set('priority', _.get(action, 'meta.priority', 0));
                    });
        case CLOSE_MODAL_FORM_GROUPER:
            return state.set("openModalGrouper", false);
        default:
            return state;
    }
}
