import React, {Component} from 'react';
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn} from 'material-ui/Table';
import {Row, Col} from 'react-flexbox-grid';
import RaisedButton from 'material-ui/RaisedButton';
import Paper from 'material-ui/Paper';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {blue700} from 'material-ui/styles/colors';
import {LBLQUESTIONS} from '../../constants/labels';
import LoadingPage from "../commons/loadingPage";
import AuthComponent from '../AuthComponent';
import TablePagination from '../TablePagination/tablePagination';

const style = {
        margin: 20,
        float: 'right'
    };
    const stylePaper = {
        marginTop: 20,
        marginBottom: 20
    };

class QuestionsGrid extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const body = [
            {
                field: "fieldName",
                title: "Código"
            },
            {
                field: "title",
                colspan: 3,
                title: "Pregunta",
                filter: true
            },
            {
                field: "type",
                title: "Tipo"
            },
            {
                actions: true,
                title: "Operaciones"
            }
        ];
        return (
            <Row>
                <AuthComponent
                    component={
                        <Col mdOffset={2} md={8} xs={12}>
                            <Paper style={stylePaper}>
                                <Row>
                                    <Col xs>
                                        <div style={{width: '100%', background: blue700, paddingTop: 5, paddingBottom:5, color: "#FFF"}}>
                                            <h1 style={{textAlign: 'center', fontWeight: 400}}>{LBLQUESTIONS.titleGrid}</h1>
                                        </div>
                                    </Col>
                                </Row>
                                <AuthComponent
                                    component={<Row>
                                                    <Col xs>
                                                        <Link to="/admin/questions/group/create">
                                                            <RaisedButton label="Agregar pregunta agrupada" secondary style={style}/>
                                                        </Link>
                                                        <Link to="/admin/questions/create">
                                                            <RaisedButton label="Agregar pregunta" secondary style={style}/>
                                                        </Link>
                                                    </Col>
                                                </Row>}
                                    permission="backend/questions:write"
                                    type="component"
                                />
                                <Row>
                                    <Col xs>
                                        <TablePagination
                                            name="questions"
                                            URL="/questions"
                                            version={1}
                                            body={body}
                                        />
                                    </Col>
                                </Row>
                            </Paper>
                        </Col>
                    }
                    permission="backend/questions:read"
                    type="url"
                />
            </Row>
        );
    }
}

function mapStateToProps({questions}) {
    if (questions.all.data instanceof Array) {
        return {questions: questions.all};
    } 
        return {};
    
}

export default connect(mapStateToProps)(QuestionsGrid);
