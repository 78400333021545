import PropTypes from "prop-types";
import React, { Component } from "react";
import TableCell from "@material-ui/core/TableCell";
import _ from "lodash";
import { Link } from "react-router-dom";
import IconButton from "material-ui/IconButton";
import { connect } from "react-redux";
import EditIcon from "material-ui/svg-icons/editor/mode-edit";
import { LBLPROFILE } from "../../../constants/labels";
import AuthComponent from "../../AuthComponent";

class ProfileActionColumn extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { data, colSpan } = this.props;
    const cs = _.isUndefined(colSpan) ? 1 : colSpan;
    return (
      <TableCell colSpan={cs}>
        <div style={{ position: "absolute", marginTop: -24, zIndex: 99 }}>
          <AuthComponent
            component={
              <Link to={`/admin/profiles/edit/${_.get(data, "_id")}`}>
                <IconButton
                  tooltip={LBLPROFILE.tooltips.btnEdit}
                  tooltipPosition="top-center"
                  children={<EditIcon />}
                />
              </Link>
            }
            permission="backend/profiles:write"
            type="component"
          />
        </div>
      </TableCell>
    );
  }
}

ProfileActionColumn.propTypes = {
  data: PropTypes.object.isRequired,
  colSpan: PropTypes.number,
};

export default connect(null, {})(ProfileActionColumn);
