import React, { Component } from "react";
import _ from "lodash";
import { Row, Col } from "react-flexbox-grid";
import Sunburst from "../d3/sunburst/sunburstComponent";

class TabSumQuestions extends Component {
    render() {
        const { data, showAll } = this.props;
        const childrenToChart = _.filter(data.children, item => _.get(item, "visible"));
        const dataToChart = showAll ? data : _.set(data, "children", childrenToChart);
        return (
            <Row>
                <Col xs={12}>
                    <Sunburst data={dataToChart} />
                </Col>
            </Row>
        );
    }
}

export default TabSumQuestions;
