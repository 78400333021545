import moment from "moment";
import _ from "lodash";
import {
  POST_PROJECT,
  DELETE_PROJECT,
  POST_PROJECT_REFEREES,
  PUT_PROJECT_COLORS,
  PUT_PROJECT_FILTER,
  POST_PROJECT_EMPLOYEES,
  GET_ADVANCE_PROJECT,
  DISSASOCIATE_EMPLOYEE_PROJECT,
  POST_RANKING_PROJECT,
} from "../constants/index";
import { axiosAPI } from "../middleware/api";

function getPropsConsent(consents) {
  const consentimientos = [];
  consents.map((consent, index) => {
    let consen = consent.agreement;
    consent.placeholders.map((placeholder) => {
      const descrip = `##${_.get(placeholder, "description")}`;
      const valor = _.get(placeholder, "value");
      consen = consen.replace(descrip, valor);
    });
    consentimientos.push({
      _id: consent._id,
      value: index + 1,
      consent: consen,
      required: _.isUndefined(consent.required) ? false : consent.required,
    });
  });
  return consentimientos;
}

export function createProjects(props) {
  function getProps() {
    return {
      project: {
        name: _.get(props, "name"),
        description: _.get(props, "description"),
        company: _.get(props, "company"),
        survey: _.get(props, "survey"),
        inactive: _.get(props, "inactive", 60),
        consent: getPropsConsent(props.consents),
        time: {
          openDate: moment(_.get(props, "openDate"))
            .hour(0)
            .minutes(0)
            .toDate(),
          closeDate: moment(_.get(props, "closeDate"))
            .hour(23)
            .minutes(59)
            .toDate(),
        },
      },
    };
  }

  const request = axiosAPI.post(`/projects`, getProps());

  return {
    type: POST_PROJECT,
    payload: request,
  };
}

export function updateProjects(props, id) {
  function getProps() {
    return {
      project: {
        name: _.get(props, "name"),
        description: _.get(props, "description"),
        inactive: _.get(props, "inactive"),
        time: {
          openDate: moment(_.get(props, "openDate"))
            .hour(0)
            .minutes(0)
            .toDate(),
          closeDate: moment(_.get(props, "closeDate"))
            .hour(23)
            .minutes(59)
            .toDate(),
        },
      },
    };
  }
  const request = axiosAPI.put(`/projects/info/${id}`, getProps());

  return {
    type: POST_PROJECT,
    payload: request,
  };
}

export function updateRankingProject(selectGrouper, idProject) {
  const request = axiosAPI.put(`/projects/ranking/${idProject}`, {
    project: {
      ranking: selectGrouper,
    },
  });

  return {
    type: POST_RANKING_PROJECT,
    payload: request,
  };
}

export function createListReferees(props, id) {
  const objectToSend = {
    ...props,
    referees: _.get(props, "collections"),
    collections: undefined,
    _id: undefined,
  };
  const request = axiosAPI.post(`/projects/${id}/referees`, {
    project: objectToSend,
  });

  return {
    type: POST_PROJECT_REFEREES,
    payload: request,
  };
}

/**
 *
 * @param id project
 * @returns {{type, payload: axios.Promise}}
 */
export function getAdvanceProjectSurvey(id) {
  const request = axiosAPI.get(`/projects/advance/${id}`, {insecureHTTPParser: true});

  return {
    type: GET_ADVANCE_PROJECT,
    payload: request,
  };
}

export function SaveAssociationEmployees(employeesAssociated, email, id) {
  const request = axiosAPI.post(`/projects/employees/${id}`, {
    project: employeesAssociated,
    email,
  });

  return {
    type: POST_PROJECT_EMPLOYEES,
    payload: request,
  };
}

export function disassociateEmployeeOfProject(
  disassociateEmployee,
  idEmployee,
  idProject
) {
  const request = axiosAPI.put(`/projects/employees/${idProject}`, {
    project: disassociateEmployee,
  });

  return {
    type: DISSASOCIATE_EMPLOYEE_PROJECT,
    payload: request,
    meta: {
      idEmployee,
    },
  };
}

export function deleteProject(projectId) {
  const request = axiosAPI.delete(`/projects/${projectId}`, {
    data: { type: "logic" },
  });

  return {
    type: DELETE_PROJECT,
    payload: request,
    meta: {
      projectId,
    },
  };
}

export function updateColorsProject({ colors = [] }, projectId) {
  const objectToSend = colors
    .map((color, index) => ({
      order: index,
      color: _.get(color, "value", null),
    }))
    .filter((color) => !_.isNull(_.get(color, "color", null)))
    .map((color, index) => _.set(color, "order", index));

  const request = axiosAPI.put(`/projects/colors`, {
    project: {
      project: projectId,
      colors: objectToSend,
    },
  });

  return {
    type: PUT_PROJECT_COLORS,
    payload: request,
  };
}

export function updateFilterProject(filter, projectId) {
  const request = axiosAPI.put(`/projects/filter`, {
    project: {
      project: projectId,
      filter,
    },
  });

  return {
    type: PUT_PROJECT_FILTER,
    payload: request,
  };
}
