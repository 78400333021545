import React, { Component } from "react";
import { scaleLinear, arc as d3Arc, select, interpolate, partition as initPartition, hierarchy } from "d3";
import Faux from "react-faux-dom";
import { Row, Col } from "react-flexbox-grid";
import _ from "lodash";

const styles = {
    grouperTitle: {
        wordBreak: "keep-all",
        textAlign: "left",
        whiteSpace: "normal",
        lineHeight: "130%",
    },
    grouperSelectedTitle: {
        textAlign: "center",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontWeight: 400,
        padding: 8,
        borderRadius: 8,
        backgroundColor: "#f1f1f1",
    },
};

class Sunburst extends Component {
    state = {
        chart: <span />,
        titleGrouper: "",
        tempTitleGrouper: "",
        result: 0,
        conditions: [],
        tempConditions: [],
        selected: false,
        visible: false,
        tempVisible: false,
        viewInfoSelected: false,
    };

    componentDidMount() {
        const { data } = this.props;
        const element = Faux.createElement("div");

        const self = this;
        const width = 350;
        const height = 370;
        const radius = Math.min(width, height) / 2;

        const x = scaleLinear().range([0, 2 * Math.PI]);

        const y = scaleLinear().range([0, radius]);

        const svg = select("#element")
            .style("text-align", "center")
            .append("svg")
            .attr("width", width)
            .attr("height", height)
            .append("g")
            .attr("transform", `translate(${width / 2},${height / 2 + 10})`);

        const partition = initPartition();

        const arc = d3Arc()
            .startAngle(d => Math.max(0, Math.min(2 * Math.PI, x(d.x0))))
            .endAngle(d => Math.max(0, Math.min(2 * Math.PI, x(d.x1))))
            .innerRadius(d => Math.max(0, y(d.y0)))
            .outerRadius(d => Math.max(0, y(d.y1)));

        const root = hierarchy(data);

        root.sum(d => d.entitySize);

        const g = svg
            .selectAll("g")
            .data(partition(root).descendants())
            .enter()
            .append("g");

        svg
            .selectAll("path")
            .data(partition(root).descendants())
            .enter()
            .append("path")
            .attr("d", arc)
            .style("fill", d => {
                const colorFromCondition = _.get(d, "data.conditions.0.color");
                const defaultColorForNoCondition = "#F0F0F0";
                return colorFromCondition || defaultColorForNoCondition;
            })
            .style("stroke", () => "#fff")
            .style("fill-rule", () => "evenodd")
            .on("mouseover", e => {
                self.setState({
                    selected: true,
                    titleGrouper: e.data.name,
                    result: e.data.size,
                    conditions: e.data.conditions,
                    visible: e.data.visible,
                });
            })
            .on("mouseout", e => {
                self.setState({
                    selected: false,
                    visible: false,
                    titleGrouper: "",
                    result: 0,
                    conditions: [],
                });
            })
            .on("click", click);

        const text = g
            .append("text")
            .attr("transform", d => `rotate(${computeTextRotation(d)})`)
            .attr("x", d => y(d.y0))
            .attr("dx", "6") // margin
            .attr("dy", ".35em") // vertical-align
            .text(d => "");

        function click(d) {
            self.setState({
                tempTitleGrouper: d.data.name,
                tempConditions: d.data.conditions,
                viewInfoSelected: true,
                tempVisible: d.data.visible,
            });
            console.clear();
            svg
                .transition()
                .duration(750)
                .tween("scale", () => {
                    const xd = interpolate(x.domain(), [d.x0, d.x1]);
                        const yd = interpolate(y.domain(), [d.y0, 1]);
                        const yr = interpolate(y.range(), [d.y0 ? 20 : 0, radius]);
                    return function(t) {
                        x.domain(xd(t));
                        y.domain(yd(t)).range(yr(t));
                    };
                })
                .selectAll("path")
                .attrTween(
                    "d",
                    d =>
                        function() {
                            return arc(d);
                        },
                );
        }

        self.setState({
            chart: element,
        });

        select(self.frameElement).style("height", `${height}px`);

        function computeTextRotation(d) {
            return (x(d.x0 + d.x1 / 2) - Math.PI / 2) / Math.PI * 180;
        }
    }

    renderDescriptionFocusSelected = (titleGrouper, isSelected) => {
        if (isSelected) {
            return <h3 style={{ ...styles.grouperSelectedTitle }}>{titleGrouper}</h3>;
        }
        return <span />;
    };

    renderDescriptionSelected = (titleGrouper, conditions = [], visible = false, extraStyle = {}) => (
        <Row style={extraStyle}>
            <Col xs={12}>
                <h3 style={styles.grouperTitle}>{titleGrouper}</h3>
            </Col>
            <Col xs={12} style={{ display: _.size(conditions) > 0 && visible ? "block" : "none" }}>
                <h3 style={{ wordBreak: "keep-all", textAlign: "left", whiteSpace: "normal", fontWeight: 400 }}>
                    <ul>
                        {_.map(conditions, (cond, idx) => (
                            <li key={`conditionDescriptionUser${idx}`}>{_.get(cond, "descriptionUser")}</li>
                        ))}
                    </ul>
                </h3>
            </Col>
        </Row>
    );

    render() {
        const {
            selected,
            titleGrouper,
            conditions,
            visible,
            viewInfoSelected,
            tempTitleGrouper,
            tempConditions,
            tempVisible,
        } = this.state;
        const styleInfoRight = {
            height: 60,
        };
        const styleInfoDown = {
            display: viewInfoSelected ? "block" : "none",
            borderRadius: 10,
            margin: "23px 10px",
        };

        return (
            <div style={{ padding: 15 }}>
                <Row>
                    <Col xs={12}>
                        <p style={{ textAlign: "justify", color: "#828282", padding: "0 5px" }}>
                            Esta gráfica representa los resultados generales de la prueba ubicados en la parte superior.
                            Para ver la información detallada de los resultados individuales,{" "}
                            <strong>pase el cursor por encima de las porciones del gráficas.</strong> Cuando se da clic
                            sobre alguna porción de la gráfica aparecerá un cuadro con la información seleccionada en la
                            parte de abajo del gráfico. También, el gráfico será modificado para visualizar en detalle
                            la opción seleccionada (tener en cuenta que si necesita visualizar todo el gráfico completo,
                            se debe dar clic en el centro del gráfico; entre más fuera este la porción seleccionada,
                            mayor el número de clic que se deben de realizar para ver el gráfico completo).
                        </p>
                    </Col>
                </Row>
                <Row style={{ ...styleInfoRight }}>
                    <Col xs={10} xsOffset={1}>
                        {this.renderDescriptionFocusSelected(titleGrouper, selected)}
                    </Col>
                </Row>
                <Row>
                    <Col xs>
                        <div id="element" />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        {this.renderDescriptionSelected(tempTitleGrouper, tempConditions, tempVisible, styleInfoDown)}
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Sunburst;
