import React from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import PageviewIcon from "@material-ui/icons/Pageview";
import { parseISO, format } from "date-fns";
import { useHistory } from "react-router-dom";

import Loader from "../../commons/apollo-loaders";
import { useDashboards } from "./data";

function parseSimpleDate(unsafeDate: string) {
    try {
        const parsedDate = parseISO(unsafeDate);
        return format(parsedDate, "MM-dd-yyyy hh:mm aaa");
    } catch (_e) {
        return unsafeDate;
    }
}

const ReportsDashboard = () => {
    const { dashboards, fetchMore, hasMore, error, loading } = useDashboards();
    const history = useHistory();
    return (
        <Loader error={error} isLoading={loading}>
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Nombre</TableCell>
                            <TableCell>Fecha de creación</TableCell>
                            <TableCell>Última actualización</TableCell>
                            <TableCell>Ver reporte</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dashboards.map((dashboard) => (
                            <TableRow key={dashboard.id}>
                                <TableCell>{dashboard.name}</TableCell>
                                <TableCell>{parseSimpleDate(dashboard.createdtime)}</TableCell>
                                <TableCell>
                                    {parseSimpleDate(dashboard.lastUpdatedTime)}
                                </TableCell>
                                <TableCell>
                                    <IconButton
                                        aria-label="go to report"
                                        onClick={() => {
                                            history.push({
                                                pathname: `/admin/analytics/report/${dashboard.id}`,
                                                state: {
                                                    dashboardName: dashboard.name,
                                                },
                                            });
                                        }}
                                    >
                                        <PageviewIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell align="center" className="m-2" colSpan={6}>
                                <Button
                                    color="primary"
                                    onClick={fetchMore}
                                    disabled={!hasMore}
                                    variant="outlined"
                                >
                                    Cargar más
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Loader>
    );
};

export default ReportsDashboard