import React from "react";
import { Route } from "react-router-dom";
import legacyWrapper from "./legacy-route-wrapper";
import CompaniesCreate from "../containers/form_companies";
import EmployeesCreate from "../containers/form_employees";
import ConsentsCreate from "../containers/form_consents";
import SurveysCreate from "../containers/form_surveys";
import MetricalSection from "../containers/metrical_section_form";
import GrouperQuestionSection from "../containers/grouper_questions_form";
import GroupersGroup from "../components/GroupersGroup/componentGrouperGroup";
import GrouperQuestionConditionals from "../containers/form_grouper_conditionals";
import FaqCreate from "../containers/form_faq";
import ListMasterCreate from "../containers/form_listMaster";
import QuestionCreate from "../components/questions/form";
import QuestionGroupCreate from "../containers/form_questions_group";
import RefereeCreate from "../containers/form_referees";
import SectionCreate from "../containers/form_sections";
import ProjectCreate from "../containers/form_projects";
import ProjectEdit from "../containers/form_edit_projects";
import ProjectColors from "../containers/form_colors_project";
import ProjectRanking from "../containers/form_ranking_projects";
import ConfigProjectCreate from "../containers/form_configDaysProject";
import ProjectRefereesCreate from "../containers/form_projectsReferees";
import AdminCountries from "../components/AdminLocations/componentAdminCountries";
import AdminDepartments from "../components/AdminLocations/componentAdminDepartments";
import AdminCities from "../components/AdminLocations/componentAdminCities";
import FormTemplateEmails from "../containers/form_template_emails";
import ProfileCreate from "../containers/form_profile";
import UserCreate from "../components/users/create/userCreate";
import CompaniesGrid from "../components/table/companies_table";
import EmployeesGrid from "../components/table/employees_table";
import EmployeesGridAssoc from "../components/tableProjectAssociated/table_employees_assoc";
import RefereesGrid from "../components/table/referees_table";
import ConsentsGrid from "../components/table/consents_table";
import ListMasterGrid from "../components/table/listmaster_table";
import ListGrid from "../components/table/list_table";
import SurveysGrid from "../components/table/surveys_table";
import FaqGrid from "../components/table/faq_table";
import FaqList from "../components/table/faq_list";
import SectionsGrid from "../components/table/sections_table";
import ProfilesGrid from "../components/table/profile_table";
import QuestionsGrid from "../components/table/questions_table";
import UsersGrid from "../containers/users/users";
import SendEmailMasive from "../components/SendEmailMasive/componentSendEmail";
import SendEmailMasiveFilter from "../components/SendEmailMasive/componentSendEmailFilter";
import TableTemplateEmails from "../components/table/templateemail_table";
import TableReportEmployeeReferee from "../components/reportEmployee/reportEmployeeTable";
import CompaniesInfo from "../components/info/companies_info";
import companiesInfoProject from "../components/info/companies_info_project";
import EmployeesInfo from "../components/info/employees_info";
import RefereesInfo from "../components/info/referees_info";
import ConsentsInfo from "../components/info/consents_info";
import ListMasterInfo from "../components/info/listmaster_info";
import ListInfo from "../components/info/list_info";
import SurveysInfo from "../components/info/surveys_info";
import SurveysInfoSections from "../components/info/surveys_sections_info";
import SurveysInfoSectionsMetrical from "../components/info/surveys_sections_metrical";
import SurveyAppl from "../components/survey/surveyAppl";
import SurveyGlance from "../components/survey/surveyApplGlance";
import AcceptConsent from "../containers/accept_consents";
import SectionsInfo from "../components/info/sections_info";
import ProjectsInfo from "../components/info/projects_info";
import AdminDashboard from "../components/dashboards/admin/componentAdmin";
import EmployeeDashboard from "../components/dashboards/employee/component";
import CompanyDashboard from "../components/dashboards/company/dashboard";
import ArlDashboard from "../components/dashboards/arl/dashboard";
import MenuDashboard from "../components/dashboards/appBar";
import WarningMessage from "../components/fields/Messages/Warning";
import TableConfigGroupers from "../components/groupersConfiguration/tableConfigGroupers";
import AuditView from "../components/Audit/componentViewAudit";
import advanceProjectSurvey from "../components/dashboards/referee/advanceProjectSurvey";
import advanceProjectSurveyCompany from "../components/dashboards/company/advanceProjectSurvey";
import ProjectsEmployee from "../components/dashboards/employee/ProjectsEmployee";
import ProjectsCompany from "../components/dashboards/company/projectsComponent";
import CompaniesArl from "../components/dashboards/arl/companiesComponent";
import ProjectsReferee from "../components/dashboards/referee/projectsComponent";
import surveyResults from "../components/surveyResults/componentResults";
import componentResultsReferee from "../components/surveyResultsReferee/componentResults";
import MetadataDashboard from "../components/metadataEditor/MetadataEditorComponent";
import surveyResultsMetadata from "../components/surveyResults/dashboard/dashboardResults";
import surveyEditorMetadata from "../components/surveyMetadataEditor/editorComponent";
import SurveyResultsExcelGenerator from "../components/SurveyResultsExcelGenerator/excelGeneratorComponent";
import Complements from "../components/complements";
import ComplementValue from "../components/complements/complementValue";
import DataSet from "../components/dataset";
import DatasetDetail from "../components/dataset/components/detail";
import DashboardView from "../components/quicksight/reports/view-dashboard";
import ReportsDashboardsList from "../components/quicksight/reports";
import UserGridDetail from "../containers/users/userGridDetail";

function CreateRoutes() {
  return (
    <>
      <Route path="/admin">
        <AdminDashboard>
          <Route path="/admin/companies">
            <Route
              path="/admin/companies/create"
              component={legacyWrapper(CompaniesCreate)}
            />
            <Route
              path="/admin/companies/edit/:_id"
              component={legacyWrapper(CompaniesCreate)}
            />
            <Route path="/admin/companies/grid" component={CompaniesGrid} />
            <Route
              path="/admin/companies/info/:_id"
              component={legacyWrapper(CompaniesInfo)}
            />
            <Route
              path="/admin/companies/info/projects/:_id"
              component={legacyWrapper(companiesInfoProject)}
            />
          </Route>
          <Route path="/admin/employees">
            <Route
              path="/admin/employees/create/:profile/:_id"
              component={legacyWrapper(EmployeesCreate)}
            />
            <Route
              path="/admin/employees/edit/:profile/:company/:_id"
              component={legacyWrapper(EmployeesCreate)}
            />
            <Route
              path="/admin/employees/grid/:profile/:_id"
              component={legacyWrapper(EmployeesGrid)}
            />
            <Route
              path="/admin/employees/info/:_id/:id"
              component={legacyWrapper(EmployeesInfo)}
            />
          </Route>
          <Route path="/admin/surveys">
            <Route
              path="/admin/surveys/create"
              component={legacyWrapper(SurveysCreate)}
            />
            <Route path="/admin/surveys/grid" component={SurveysGrid} />
            <Route
              path="/admin/surveys/edit/:_id"
              component={legacyWrapper(SurveysCreate)}
            />
            <Route
              exact
              path="/admin/surveys/info/:_id"
              component={legacyWrapper(SurveysInfo)}
            />
            <Route
              exact
              path="/admin/surveys/info/:profile/:_id/:project/:company"
              component={legacyWrapper(SurveysInfoSections)}
            />
            <Route
              path="/admin/surveys/metrical/:survey"
              component={legacyWrapper(SurveysInfoSectionsMetrical)}
            />
            <Route
              path="/admin/surveys/metrical/assign/:survey/:section"
              component={legacyWrapper(MetricalSection)}
            />
            <Route
              path="/admin/surveys/appl/:profile/:company/:survey/:order/:project"
              component={legacyWrapper(SurveyAppl)}
            />
            <Route
              path="/admin/surveys/appl/:survey/:order"
              component={legacyWrapper(SurveyGlance)}
            />
            <Route
              path="/admin/surveys/groupers/:survey"
              component={legacyWrapper(TableConfigGroupers)}
              exact
            />
            <Route
              path="/admin/surveys/:survey/metadata"
              component={legacyWrapper(surveyEditorMetadata)}
            />
            <Route
              path="/admin/surveys/groupers/:survey/conditionals/:grouper"
              component={legacyWrapper(GrouperQuestionConditionals)}
              exact
            />
            <Route
              path="/admin/surveys/groupers/create/:survey"
              component={legacyWrapper(GroupersGroup)}
            />
            <Route
              path="/admin/surveys/groupers/questions/:survey"
              component={legacyWrapper(GrouperQuestionSection)}
            />
            <Route
              path="/admin/surveys/export/applications"
              component={SurveyResultsExcelGenerator}
            />
          </Route>
          <Route path="/admin/consents">
            <Route
              path="/admin/consents/create"
              component={legacyWrapper(ConsentsCreate)}
            />
            <Route
              path="/admin/consents/edit/:_id"
              component={legacyWrapper(ConsentsCreate)}
            />
            <Route path="/admin/consents/grid" component={ConsentsGrid} />
            <Route
              path="/admin/consents/info/:_id"
              component={legacyWrapper(ConsentsInfo)}
            />
          </Route>
          <Route path="/admin/lists">
            <Route
              path="/admin/lists/create"
              component={legacyWrapper(ListMasterCreate)}
            />
            <Route path="/admin/lists/grid" component={ListMasterGrid} />
            <Route
              path="/admin/lists/edit/:_id"
              component={legacyWrapper(ListMasterCreate)}
            />
            <Route
              path="/admin/lists/info/:_id"
              component={legacyWrapper(ListMasterInfo)}
            />
          </Route>
          <Route path="/admin/questions">
            <Route
              path="/admin/questions/create"
              component={legacyWrapper(QuestionCreate)}
            />
            <Route path="/admin/questions/grid" component={QuestionsGrid} />
            <Route
              path="/admin/questions/edit/:_id"
              component={legacyWrapper(QuestionCreate)}
            />
            <Route path="/admin/questions/group">
              <Route
                path="/admin/questions/group/create"
                component={legacyWrapper(QuestionGroupCreate)}
              />
              <Route
                path="/admin/questions/group/edit/:_id"
                component={legacyWrapper(QuestionGroupCreate)}
              />
            </Route>
          </Route>
          <Route path="/admin/referees">
            <Route
              path="/admin/referees/create"
              component={legacyWrapper(RefereeCreate)}
            />
            <Route
              path="/admin/referees/edit/:_id"
              component={legacyWrapper(RefereeCreate)}
            />
            <Route
              path="/admin/referees/info/:_id/:id"
              component={legacyWrapper(RefereesInfo)}
            />
            <Route path="/admin/referees/grid" component={RefereesGrid} />
          </Route>
          <Route path="/admin/projects">
            <Route
              path="/admin/projects/create/:_id"
              component={legacyWrapper(ProjectCreate)}
            />
            <Route
              path="/admin/projects/edit/:profile/:_id/:company"
              component={legacyWrapper(ProjectEdit)}
            />
            <Route
              path="/admin/projects/colors/:profile/:project/:company"
              component={legacyWrapper(ProjectColors)}
            />
            <Route
              path="/admin/projects/ranking/:profile/:project/:survey/:company"
              component={legacyWrapper(ProjectRanking)}
            />
            {/* <Route
            path="/admin/projects/filter/:action/:profile/:project/:company"
            component={ContentFilterReport}
          /> */}
            <Route
              path="/admin/projects/edit_referees/:profile/:_id/:company"
              component={legacyWrapper(ProjectRefereesCreate)}
            />
            <Route
              path="/admin/projects/info/:profile/:_id/:company"
              component={legacyWrapper(ProjectsInfo)}
            />
            <Route
              path="/admin/projects/email/employees/:profile/:_id/:company"
              component={legacyWrapper(SendEmailMasive)}
            />
            <Route
              exact
              path="/admin/projects/associate/employees/:profile/:_id/:company"
              component={legacyWrapper(EmployeesGridAssoc)}
            />
            <Route
              path="/admin/projects/configDay/:profile/:_id/:company/:day"
              component={legacyWrapper(ConfigProjectCreate)}
            />
            <Route
              path="/admin/projects/advance/:profile/:company/:project"
              component={legacyWrapper(advanceProjectSurvey)}
            />
            <Route
              path="/admin/projects/report/:profile/:company/:project"
              component={legacyWrapper(TableReportEmployeeReferee)}
            />
            <Route
              exact
              path="/admin/projects/email/:profile/:company/:idProject"
              component={legacyWrapper(SendEmailMasiveFilter)}
            />
            <Route
              path="/admin/projects/employees/company/:profile/:company/project/:project/surveys/:survey/employee/:employee/results"
              component={legacyWrapper(surveyResults)}
            />
            <Route
              path="/admin/projects/employee/:profile/:employee/company/:company/project/:project/results"
              component={legacyWrapper(componentResultsReferee)}
            />
            <Route
              path="/admin/projects/results/:profile/:company/:surveyId(/:projectId)"
              component={legacyWrapper(surveyResultsMetadata)}
            />
            {/* <Route
                        path="/admin/projects/sarin/:profile/:company/:projectId"
                        component={SarinDashboard}
                    /> */}
            <Route
              path="/admin/projects/metadata/:companyId/:projectId"
              component={legacyWrapper(MetadataDashboard)}
            />
          </Route>
          <Route path="/admin/sections">
            <Route
              path="/admin/sections/create/:_id"
              component={legacyWrapper(SectionCreate)}
            />
            <Route
              path="/admin/sections/edit/:_id/:section"
              component={legacyWrapper(SectionCreate)}
            />
            <Route path="/admin/sections/grid" component={SectionsGrid} />
            <Route
              path="/admin/sections/info/:_id"
              component={legacyWrapper(SectionsInfo)}
            />
          </Route>
          <Route path="/admin/faq">
            <Route
              path="/admin/faq/create"
              component={legacyWrapper(FaqCreate)}
            />
            <Route path="/admin/faq/grid" component={FaqGrid} />
            <Route
              path="/admin/faq/edit/:_id"
              component={legacyWrapper(FaqCreate)}
            />
          </Route>
          <Route path="/admin/locations">
            <Route
              path="/admin/locations/countries"
              component={AdminCountries}
            />
            <Route
              path="/admin/locations/departments/:countryId"
              component={legacyWrapper(AdminDepartments)}
            />
            <Route
              path="/admin/locations/cities/:departmentId/:countryId"
              component={legacyWrapper(AdminCities)}
            />
          </Route>
          <Route path="/admin/templateemail">
            <Route
              path="/admin/templateemail/create"
              component={legacyWrapper(FormTemplateEmails)}
            />
            <Route
              path="/admin/templateemail/edit/:_id"
              component={legacyWrapper(FormTemplateEmails)}
            />
            <Route
              path="/admin/templateemail/grid"
              component={TableTemplateEmails}
            />
          </Route>
          <Route path="/admin/profiles">
            <Route path="/admin/profiles/grid" component={ProfilesGrid} />
            <Route
              path="/admin/profiles/create"
              component={legacyWrapper(ProfileCreate)}
            />
            <Route
              path="/admin/profiles/edit/:profile"
              component={legacyWrapper(ProfileCreate)}
            />
          </Route>
          <Route path="/admin/users">
            <Route path="/admin/users/grid" component={UsersGrid} />
            <Route
              path="/admin/users/user-grid-detail/:id"
              component={UserGridDetail}
            />

            <Route
              path="/admin/users/create"
              component={legacyWrapper(UserCreate)}
            />

            <Route
              path="/admin/users/edit/:user"
              component={legacyWrapper(UserCreate)}
            />
          </Route>
          <Route path="/admin/me">
            <Route
              path="/admin/me/assignprojects"
              component={ProjectsReferee}
            />
            <Route path="/admin/me/projects" component={ProjectsEmployee} />
          </Route>
          <Route path="/admin/audit/view" component={AuditView} />
          <Route path="/admin/list/grid" component={ListGrid} />
          <Route
            path="/admin/list/info/:_id/:id"
            component={legacyWrapper(ListInfo)}
          />
          <Route path="/admin/complements">
            <Route path="/admin/complements/main" component={Complements} />
            <Route
              path="/admin/complements/value/:id"
              component={legacyWrapper(ComplementValue)}
            />
          </Route>
          <Route path="/admin/analytics">
            <Route
              exact
              path="/admin/analytics/reports"
              component={ReportsDashboardsList}
            />
            <Route
              exact
              path="/admin/analytics/report/:dashboardId"
              component={DashboardView}
            />
            <Route
              exact
              path="/admin/analytics/dataset/main"
              component={DataSet}
            />
            <Route
              exact
              path="/admin/analytics/dataset/detail/:id"
              component={DatasetDetail}
            />
          </Route>
        </AdminDashboard>
      </Route>
      <Route path="/dashboard">
        <MenuDashboard>
          <Route path="/dashboard/employee">
            <EmployeeDashboard>
              <Route
                path="/dashboard/employee/projects"
                component={ProjectsEmployee}
              />
              <Route
                path="/dashboard/employee/survey/appl/:survey/:order/:project"
                component={legacyWrapper(SurveyAppl)}
              />
              <Route
                path="/dashboard/employee/project/appl/:profile/:project/:userId"
                component={legacyWrapper(AcceptConsent)}
              />
              <Route
                path="/dashboard/employee/projects/:profile/:project/surveys/:survey/employee/:employee/results"
                component={legacyWrapper(surveyResults)}
              />
            </EmployeeDashboard>
          </Route>
          <Route path="/dashboard/company">
            <CompanyDashboard>
              <Route
                path="/dashboard/company/projects"
                component={ProjectsCompany}
              />
              <Route
                path="/dashboard/company/project/advance/:profile/:company/:project"
                component={legacyWrapper(advanceProjectSurveyCompany)}
              />
              <Route
                path="/dashboard/company/project/results/:profile/:company/:surveyId/:projectId"
                component={legacyWrapper(surveyResultsMetadata)}
              />
              {/* <Route
                        path="/dashboard/company/project/sarin/:profile/:company/:projectId"
                        component={SarinDashboard}
                    /> */}
              {/* <Route
                        path="/dashboard/company/project/report/:profile/company/:company/project/:project/survey/:survey"
                        component={ContentFilterReport}
                    /> */}
            </CompanyDashboard>
          </Route>
          <Route path="/dashboard/arl">
            <ArlDashboard>
              <Route path="/dashboard/arl/companies" component={CompaniesArl} />
            </ArlDashboard>
          </Route>
        </MenuDashboard>
      </Route>
      <Route path="/faq" component={FaqList} />
      <Route path="/message">
        <Route
          path="/message/warning/:survey/:sectionValid/:project"
          component={legacyWrapper(WarningMessage)}
        />
      </Route>
    </>
  );
}

export default CreateRoutes;
