import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import MoreIcon from 'material-ui/svg-icons/navigation/more-horiz';
import IconButton from 'material-ui/IconButton';

const styleCollapseHide = {
    width: '100%',
    height: 0,
    overflow: 'hidden',
    textAlign: 'justify',
    transition: 'height 0.5s',
    WebkitTransition: 'height 0.5s',
    MsTransition: 'height 0.5s',
    MozTransition: 'height 0.5s',
    OTransition: 'height 0.5s'
}; const styleCollapseShow = {
    width: '100%',
    height: 'auto',
    overflow: 'hidden',
    textAlign: 'justify',
    transition: 'height 0.5s',
    WebkitTransition: 'height 0.5s',
    MsTransition: 'height 0.5s',
    MozTransition: 'height 0.5s',
    OTransition: 'height 0.5s'
};

class textCollapsible extends Component {

    constructor(props){
        super(props);
        this.HideAndShowText = this.HideAndShowText.bind(this);
        this.state = {
            collapse: false
        };
    }

    HideAndShowText(){
        this.setState({collapse: !this.state.collapse});
    }

    renderDescription(descrip){
        let description = "";
        if(!_.isUndefined(descrip) && !_.isEqual(descrip, "")){
            description = _.split(descrip, /\r\n|\r|\n/g);
        }
        if(description instanceof Array){
            return (
                <h3 style={{textAlign: 'justify', fontWeight: 200}}>
                    {description.map((value, index) => {
                        if(!_.isEqual(value, '')){
                            return (
                                <div key={index}>{value}<br/><br/></div>
                            );
                        }
                    })}
                </h3>
            );
        } 
            return (
                <h3 style={{textAlign: 'justify', fontWeight: 200}}>{description}</h3>
            );
        
    }

    render() {
        const {
            textHeader,
            textbody
        } = this.props;
        return (
            <div style={{padding: '0px 20px 25px 20px'}}>
                <h2 style={{textAlign: 'center', fontWeight: 400, marginTop: 5}}>
                    {textHeader}<IconButton style={{marginBottom: -15, marginTop: -5}} tooltip="Descripción" tooltipPosition="top-center"
                                            onClick={this.HideAndShowText} children={<MoreIcon color="#FFF"/>}/>
                </h2>
                <div style={this.state.collapse ? styleCollapseShow : styleCollapseHide}>
                    {this.renderDescription(textbody)}
                </div>
            </div>
        );
    }
}

textCollapsible.propTypes = {
    textHeader: PropTypes.string.isRequired,
    textbody: PropTypes.string.isRequired
};

export default textCollapsible;
