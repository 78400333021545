import PropTypes from "prop-types";
import React, { Component } from "react";
import { Row, Col, Grid } from "react-flexbox-grid";
import * as Rx from "rxjs";
import Paper from "material-ui/Paper";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { blue700 } from "material-ui/styles/colors";
import Divider from "material-ui/Divider";
import _ from "lodash";
import { idEmployeeTest } from "Labels";
import {
  FetchSurveyOrderParam,
  FetchProjectSurveyOrderUserParam,
} from "../../actions/info/surveys_info_form";
import { setInitialSection } from "./question/actions";
import { setInitialLocationQuestionState } from "./question/location/actions";
import TextCollapsible from "../fields/TextCollapsible/textCollapsible";
import ControlsSection from "../fields/ControlsSection/ControlsSection";
import SurveyAdvanceEmployee from "../fields/SurveyAdvance/SurveyAdvanceEmployee";
import ControlsSectionButton from "../validateSectionNext/componentButtonNextSection";
import Question from "./question";
import * as ps from "../../lib/projectScheduler";
import ModalExitSurvey from "../ModalExitSurvey/componentModal";
import { showModalExitSurvey } from "../ModalExitSurvey/actions";
import IdleMonitor from "../fields/IdleMonitor/IdleMonitor";
import AuthComponent from "../AuthComponent";
import ButtonBack from "../fields/buttonBack/buttonBack";
import MissingQuestions from "../fields/MissingQuestions/MissingQuestions";
import LoadingPage from "../commons/loadingPage";
import withAuthHook from "../commons/withAuthHook";
import { getUserTypes } from "../TablePagination/actions";

const { Scheduler } = Rx;
const { queue } = Scheduler;

const stylePaper = {
  marginTop: 80,
  marginBottom: 95,
  paddingBottom: 40,
};
const ColsQuestions = {
  paddingBottom: 21,
  paddingRight: 14,
  paddingLeft: 14,
  minWidth: 290,
  border: 0,
  borderStyle: "solid",
  borderColor: "#000",
};

let cs = 0;
let urlBack;
let isAdmin = false;
let isApply = false;

class SurveyAppl extends Component {
  static childContextTypes = {
    router: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      loadingQueries: false,
    };
    this.renderQuestions = this.renderQuestions.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  handleResize(e) {
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    });
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  async UNSAFE_componentWillMount() {
    const {
      FetchSurveyOrderParam,
      FetchProjectSurveyOrderUserParam,
      setInitialLocationQuestionState,
      params,
      login,
    } = this.props;
    const userID = login.get("loggedIn")
      ? _.get(login.get("profile"), "person._id")
      : idEmployeeTest;
    setInitialLocationQuestionState();
    this.setState({ loadingQueries: true });
    if (_.isUndefined(params.project)) {
      await FetchSurveyOrderParam(params.survey, params.order);
      this.setState({ loadingQueries: false });
    } else {
      await FetchProjectSurveyOrderUserParam(
        params.project,
        params.survey,
        params.order,
        userID
      );
      this.setState({ loadingQueries: false });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      setInitialSection,
      setInitialLocationQuestionState,
      surveys,
      params,
      showModalExitSurvey,
      history,
    } = nextProps;

    if (_.isUndefined(surveys?.data) && (!isAdmin || isApply)) {
      history.replace(urlBack);
      return false;
    }
    if (
      _.isEqual(this.state.windowWidth, _.toInteger(window.innerWidth)) &&
      _.isEqual(this.state.windowHeight, _.toInteger(window.innerHeight))
    ) {
      setInitialLocationQuestionState();
      setInitialSection(
        surveys.data.questions,
        validateQuestionsGroups(surveys.data.questions)
      );
    }

    if (!_.isUndefined(surveys.data.project) && (!isAdmin || isApply)) {
      setInterval(() => {
        const { time } = surveys.data.project;
        const validPT = ps.validateProjectToday(time);

        if (validPT) {
          let URL = `/dashboard/surveys/appl/${params.survey}/${surveys.data.NextSection}/${params.project}`;
          if (
            !_.isUndefined(surveys.data.acceptRoute) &&
            !_.isUndefined(surveys.data.NextSection) &&
            !_.isUndefined(surveys.data.numSections) &&
            !_.isUndefined(surveys.data.allowed)
          ) {
            if (surveys.data.allowed) {
              if (!surveys.data.acceptRoute) {
                if (surveys.data.NextSection > surveys.data.numSections) {
                  window.location.replace(urlBack);
                } else {
                  URL = `/message/warning/${params.survey}/${surveys.data.NextSection}/${params.project}`;
                  window.location.replace(URL);
                  return false;
                }
              }
            } else {
              window.location.replace(urlBack);
              return false;
            }
          } else {
            return true;
          }
        } else {
          window.location.replace(urlBack);
          return false;
        }
      }, 3000);
    }
    return true;
  }

  getUserProfile = ({ attributes, challengeParam }) => {
    const { "custom:Role": role, "custom:user_profile": userProfile } = attributes || {};
    const { userAttributes } = challengeParam || {};
    const { "custom:Role": challengeRole, "custom:user_profile": challengeUserProfile } = userAttributes || {};

    return role || userProfile || challengeRole || challengeUserProfile || "EMPLOYEE";
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { params, login } = nextProps;
    const { FetchSurveyOrderParam, FetchProjectSurveyOrderUserParam, user } =
      this.props;

    if (cs !== parseInt(params.order)) {
      cs = parseInt(params.order);
      const userID = login.get("loggedIn")
        ? _.get(login.get("profile"), "person._id")
        : idEmployeeTest;
      const userType = this.getUserProfile(user);
      isAdmin = !_.isEqual(userType, "EMPLOYEE");
      if (isAdmin && _.isUndefined(params.company)) {
        isApply = true;
      }
      this.setState({ loadingQueries: true });
      if (
        _.isEqual(_.get(params, "profile"), "adm") ||
        _.isEqual(_.get(params, "profile"), "ref")
      ) {
        await FetchSurveyOrderParam(params.survey, params.order);
        this.setState({ loadingQueries: false });
      } else {
        await FetchProjectSurveyOrderUserParam(
          params.project,
          params.survey,
          params.order,
          userID
        );
        this.setState({ loadingQueries: false });
      }
      window.scrollTo(0, 0);
    }
  }

  renderAdvanceSurvey(isEmployee) {
    const { params } = this.props;
    if (isEmployee) {
      return (
        <Row>
          <Col xs>
            <div
              style={{
                width: "100%",
                background: blue700,
                paddingTop: 5,
                paddingBottom: 5,
                marginTop: -25,
              }}
            >
              <div>
                <SurveyAdvanceEmployee
                  idSurveys={params.survey}
                  currentSection={parseInt(params.order)}
                  idProject={_.get(params, "project")}
                  isAdmin={isApply ? false : isAdmin}
                />
              </div>
            </div>
          </Col>
        </Row>
      );
    }
  }

  static renderProjectCompanyName(isEmployee, project) {
    if (isEmployee && !_.isUndefined(project)) {
      return (
        <Row>
          <Col xs>
            <div
              style={{
                width: "100%",
                background: blue700,
                paddingTop: 5,
                paddingBottom: 5,
                color: "#FFF",
              }}
            >
              <div style={{ padding: "0px 10px" }}>
                <h2
                  style={{
                    textAlign: "center",
                    fontWeight: 400,
                  }}
                >
                  {_.get(project, "company.businessName")}
                </h2>
                <TextCollapsible
                  textHeader={project.name}
                  textbody={project.description}
                />
              </div>
            </div>
          </Col>
        </Row>
      );
    }
  }

  renderDescription(textDescription) {
    let description = "";
    if (!_.isUndefined(textDescription) && !_.isEqual(textDescription, "")) {
      description = _.split(textDescription, /\r\n|\r|\n/g);
    }
    if (description instanceof Array) {
      return (
        <h3 style={{ textAlign: "justify", fontWeight: 200 }}>
          {description.map((value, index) => {
            if (!_.isEqual(value, "")) {
              return (
                <div key={index}>
                  {value}
                  <br />
                  <br />
                </div>
              );
            }
          })}
        </h3>
      );
    }
    return (
      <h3 style={{ textAlign: "justify", fontWeight: 200 }}>{description}</h3>
    );
  }

  renderQuestions(questions) {
    const params = _.get(this, "props.params");
    let index = 0;
    const resultQuestion = [];
    for (let position = 0; position < _.size(questions); position++) {
      const qt = _.has(questions[position], "fieldName")
        ? questions[position]
        : _.get(questions[position], "_id");
      let indextext = "0";
      let indextext2 = "0";
      if (
        qt.type === "GroupClose" ||
        qt.type === "Group" ||
        qt.type === "GroupList"
      ) {
        indextext = `${index + 1}-${index + parseInt(_.get(qt, "sizeList"))}`;
        index += parseInt(_.get(qt, "sizeList"));
      } else {
        index += 1;
        indextext = index;
      }
      if (
        _.isEqual(_.get(qt, "size"), "long") ||
        qt.type === "GroupClose" ||
        qt.type === "Group" ||
        qt.type === "GroupList"
      ) {
        resultQuestion.push(
          <Row key={position}>
            <Col xs={12} md={12} style={ColsQuestions}>
              <Question
                question={questions[position]}
                idProject={params.project}
                idSurvey={params.survey}
                idQuestion={_.get(questions[position], "_id")}
                sectionOrder={params.order}
                index={indextext}
              />
            </Col>
          </Row>
        );
      } else if (position + 1 < _.size(questions)) {
        const qt2 = _.has(questions[position + 1], "fieldName")
          ? questions[position + 1]
          : _.get(questions[position + 1], "_id");
        if (_.isEqual(_.get(qt2, "size"), "long")) {
          resultQuestion.push(
            <Row key={position}>
              <Col xs md style={ColsQuestions}>
                <Question
                  question={questions[position]}
                  idProject={params.project}
                  idSurvey={params.survey}
                  idQuestion={_.get(questions[position], "_id")}
                  sectionOrder={params.order}
                  index={indextext}
                />
              </Col>
              <Col key={position} xs md style={ColsQuestions}>
                <div
                  style={{
                    minWidth: 320,
                    height: "auto",
                    display: "inline-block",
                  }}
                />
              </Col>
            </Row>
          );
        } else {
          if (
            qt2.type === "GroupClose" ||
            qt2.type === "Group" ||
            qt2.type === "GroupList"
          ) {
            indextext2 = `${index + 1}-${index + parseInt(qt2.sizeList)}`;
            index += parseInt(qt2.sizeList);
          } else {
            index += 1;
            indextext2 = index;
          }
          resultQuestion.push(
            <Row key={position}>
              <Col xs md style={ColsQuestions}>
                <Question
                  question={questions[position]}
                  idProject={params.project}
                  idSurvey={params.survey}
                  idQuestion={_.get(questions[position], "_id")}
                  sectionOrder={params.order}
                  index={indextext}
                />
              </Col>
              <Col key={position} xs md style={ColsQuestions}>
                <Question
                  question={questions[position + 1]}
                  idProject={params.project}
                  idSurvey={params.survey}
                  idQuestion={_.get(questions[position + 1], "_id")}
                  sectionOrder={params.order}
                  index={indextext2}
                />
              </Col>
            </Row>
          );
          position += 1;
        }
      } else {
        resultQuestion.push(
          <Row key={position}>
            <Col xs md style={ColsQuestions}>
              <Question
                question={questions[position]}
                idProject={params.project}
                idSurvey={params.survey}
                idQuestion={_.get(questions[position], "_id")}
                sectionOrder={params.order}
                index={indextext}
              />
            </Col>
            <Col key={position} xs md style={ColsQuestions}>
              <div
                style={{
                  minWidth: 320,
                  height: "auto",
                  display: "inline-block",
                }}
              />
            </Col>
          </Row>
        );
      }
    }
    return resultQuestion;
  }

  render() {
    const { inactiveTime, params, user } = this.props;
    const userType = this.getUserProfile(user)

    isAdmin = !_.isEqual(userType, "EMPLOYEE");
    if (isAdmin) {
      if (!_.isUndefined(params.company)) {
        urlBack = `/admin/projects/info/${_.get(params, "profile")}/${
          params.project
        }/${params.company}`;
      } else {
        isApply = true;
        urlBack = "/admin/me/projects";
      }
    } else {
      urlBack = "/dashboard/employee";
    }

    if (this.state.loadingQueries) {
      return <LoadingPage visible size={80} text="Cargando..." />;
    }

    if (
      !_.isNull(_.get(this, "props.surveys.data")) &&
      !_.isUndefined(_.get(this, "props.surveys.data")) &&
      !this.state.loadingQueries
    ) {
      return (
        <Grid>
          <ButtonBack url={urlBack} />
          <AuthComponent
            component={
              <Row>
                <Col xs={12} mdOffset={2} md={8}>
                  {this.renderAdvanceSurvey(isApply || !isAdmin)}
                  <Paper style={stylePaper}>
                    <Row>
                      <Col xs>
                        <div>
                          <IdleMonitor
                            time={inactiveTime}
                            isAdmin={isApply ? false : isAdmin}
                          />
                        </div>
                      </Col>
                    </Row>
                    {SurveyAppl.renderProjectCompanyName(
                      isApply || !isAdmin,
                      _.get(this, "props.surveys.data.project")
                    )}
                    <Row>
                      <Col xs>
                        <ControlsSection
                          idSurveys={this.props.params.survey}
                          currentSection={parseInt(this.props.params.order)}
                          URL={`/surveys/appl/${_.get(params, "profile")}/${
                            params.company
                          }/${_.get(params, "survey")}/`}
                          idProjects={_.get(params, "project")}
                          isAdmin={isApply ? false : isAdmin}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xsOffset={1} xs={10}>
                        <h1
                          style={{
                            textAlign: "center",
                            fontWeight: 400,
                            lineHeight: 1.5,
                          }}
                        >
                          {_.get(this, "props.surveys.data.title")}
                        </h1>
                      </Col>
                    </Row>
                    <Row>
                      <Col xsOffset={1} xs={10}>
                        {this.renderDescription(
                          _.get(this, "props.surveys.data.description")
                        )}
                      </Col>
                    </Row>
                    <div
                      style={{
                        paddingTop: 0,
                        paddingBottom: 0,
                        paddingLeft:
                          this.state.windowWidth < 501
                            ? 20
                            : this.state.windowWidth < 651
                            ? 35
                            : this.state.windowWidth < 801
                            ? 50
                            : this.state.windowWidth < 951
                            ? 60
                            : 70,
                        paddingRight:
                          this.state.windowWidth < 501
                            ? 20
                            : this.state.windowWidth < 651
                            ? 35
                            : this.state.windowWidth < 801
                            ? 50
                            : this.state.windowWidth < 951
                            ? 60
                            : 70,
                      }}
                    >
                      {this.renderQuestions(
                        _.get(this, "props.surveys.data.questions")
                      )}
                    </div>
                    <Row>
                      <Col
                        xs
                        style={{
                          textAlign: "center",
                          paddingTop: 40,
                        }}
                      >
                        <ControlsSectionButton
                          idSurveys={params.survey}
                          idProjects={_.get(params, "project", null)}
                          isAdmin={isApply ? false : isAdmin}
                          adminApply={isAdmin}
                        />
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginTop: 15,
                        display: (isApply ? false : isAdmin)
                          ? "none"
                          : "display",
                      }}
                    >
                      <Col xs>
                        <Divider />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs>
                        <MissingQuestions isAdmin={isApply ? false : isAdmin} />
                      </Col>
                    </Row>
                    {/* <AuthComponent
                      component={
                        <Row>
                          <Col xs>
                            <ChatButton
                                                            project={
                                                                params.project
                                                            }
                                                            chatWebSocketManager={
                                                                chatWebSocketManager
                                                            }
                                                        />
                          </Col>
                        </Row>
                      }
                      permission="cgr_chat_use"
                      type="component"
                    /> */}
                  </Paper>
                </Col>
              </Row>
            }
            permission={
              (isApply ? false : isAdmin)
                ? "backend/surveys:write"
                : "backend/surveys:write"
            }
            type="url"
          />
          <ModalExitSurvey />
        </Grid>
      );
    }
    return <div />;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      FetchSurveyOrderParam,
      FetchProjectSurveyOrderUserParam,
      setInitialSection,
      setInitialLocationQuestionState,
      showModalExitSurvey,
    },
    dispatch
  );
}

function mapStateToProps({ surveys, login }) {
  return {
    inactiveTime: _.isUndefined(_.get(surveys, "survey.data.project.inactive"))
      ? 20
      : _.get(surveys, "survey.data.project.inactive"),
    surveys: _.isUndefined(surveys.survey) ? null : surveys.survey,
    login,
  };
}

function validateQuestionsGroups(arrayQuestion) {
  let questionsGroups = 0;
  _.map(arrayQuestion, item => {
    switch (item.type) {
      case "GroupList":
        if (_.isEqual(typeof item.sizeList, "string")) {
          questionsGroups += _.size(_.get(item, "valueSource.groupList.name"));
        } else if (_.isEqual(typeof item.sizeList, "number")) {
          questionsGroups += item.sizeList;
        }
        break;
      case "Group":
        if (_.isEqual(typeof item.sizeList, "string")) {
          questionsGroups += _.size(_.get(item, "valueSource.group.name"));
        } else if (_.isEqual(typeof item.sizeList, "number")) {
          questionsGroups += item.sizeList;
        }
        break;
      case "GroupClose":
        if (_.isEqual(typeof item.sizeList, "string")) {
          questionsGroups += _.size(_.get(item, "valueSource.groupClose.name"));
        } else if (_.isEqual(typeof item.sizeList, "number")) {
          questionsGroups += item.sizeList;
        }
        break;
      default:
        questionsGroups++;
        break;
    }
  });
  return questionsGroups;
}

export default withAuthHook(
  connect(mapStateToProps, mapDispatchToProps)(SurveyAppl)
);
