import React from "react";
import ComplementMain from "./complementMain";
import ComplementProvider from "./context/complementProvider";
import ContentMain from "./contentMain";

const Complements = () => {
  return (
    <ContentMain title="Complementos" flex>
      <ComplementProvider>
        <ComplementMain />
      </ComplementProvider>
    </ContentMain>
  );
};

export default Complements;
