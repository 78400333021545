import PropTypes from "prop-types";
import React, { Component } from "react";
import { Row, Col } from "react-flexbox-grid";
import { connect } from "react-redux";
import Paper from "material-ui/Paper";
import { blue700 } from "material-ui/styles/colors";
import { bindActionCreators } from "redux";
import _ from "lodash";
import Checkbox from "material-ui/Checkbox";
import { List, ListItem } from "material-ui/List";
import RaisedButton from "material-ui/RaisedButton";
import { getRamdonString } from "../lib/util";
import { toggleSnackbar } from "../actions/commons";
import ButtonBack from "../components/fields/buttonBack/buttonBack";
import {
  FetchTreeGroupersSurveyParam,
  selectGrouperForProject,
  FetchProjectParam,
} from "../actions/info/projects_info_form";

import { updateRankingProject } from "../actions/projects_form";
import AuthComponent from "../components/AuthComponent";
import { LBLPROJECTS } from "../constants/labels";

let urlBack = "";

class ProjectsRankingNews extends Component {
  constructor(props) {
    super(props);
    this._hanlderSelectGrouper = this._hanlderSelectGrouper.bind(this);
    this.renderChildrens = this.renderChildrens.bind(this);
    this._hanlderSaveRanking = this._hanlderSaveRanking.bind(this);
    this.state = {
      isReferee: false,
    };
  }

  UNSAFE_componentWillMount() {
    const {
      profile,
      params,
      FetchTreeGroupersSurveyParam,
      FetchProjectParam,
    } = this.props;
    if (!_.isEmpty(profile)) {
      this.state = {
        isReferee: _.isEqual(_.get(profile, "role"), "referee"),
      };
    }
    urlBack = `/admin/projects/info/${_.get(params, "profile")}/${_.get(
      params,
      "project"
    )}/${_.get(params, "company")}`;
    FetchProjectParam(_.get(params, "project"));
    FetchTreeGroupersSurveyParam(_.get(params, "survey"));
  }

  _hanlderSaveRanking() {
    const { selectGroupers, updateRankingProject, params } = this.props;
    updateRankingProject(selectGroupers, _.get(params, "project"))
      .then(
        (data) => {
          if (_.get(data, "error") || _.get(data, "payload.status") !== 200) {
            toggleSnackbar(true, LBLPROJECTS.msg.errorProjectRanking);
          } else {
            toggleSnackbar(true, LBLPROJECTS.msg.successProjectRanking);
            this.props.history.push(urlBack);
          }
        },
        (reason) => {
          toggleSnackbar(true, LBLPROJECTS.msg.errorProjectRanking);
        }
      )
      .catch((data) => {
        toggleSnackbar(true, LBLPROJECTS.msg.errorProjectRanking);
      });
  }

  _hanlderSelectGrouper(idGrouper, event, isCheck) {
    const { selectGrouperForProject } = this.props;
    selectGrouperForProject(idGrouper, isCheck);
  }

  renderChildrens(childrens) {
    const { selectGroupers } = this.props;
    return _.map(childrens, (children) => {
      return (
        <ListItem
          key={getRamdonString()}
          leftCheckbox={
            <Checkbox
              checked={isChecked(selectGroupers, _.get(children, "_id"))}
              defaultChecked={isChecked(selectGroupers, _.get(children, "_id"))}
              onCheck={this._hanlderSelectGrouper.bind(
                this,
                _.get(children, "_id")
              )}
            />
          }
          primaryText={_.get(children, "name", "")}
          secondaryText={`Nivel: ${_.get(children, "level")}`}
          innerDivStyle={{ paddingRight: 35 }}
          autoGenerateNestedIndicator={false}
          disabled
          initiallyOpen
          primaryTogglesNestedList
          nestedListStyle={{ paddingLeft: 20 }}
          secondaryTextLines={1}
          nestedItems={
            !_.isEqual(_.get(children, "level", 1), 1)
              ? this.renderChildrens(_.get(children, "children", []))
              : []
          }
        />
      );
    });
  }

  render() {
    const { tree, nameProject } = this.props;
    return (
      <Row>
        <AuthComponent
          component={
            <Col
              xs={12}
              smOffset={1}
              sm={10}
              mdOffset={2}
              md={8}
              lgOffset={3}
              lg={6}
            >
              <Paper style={{ marginTop: 20, marginBottom: 20 }}>
                <Row>
                  <Col xs={12}>
                    <ButtonBack url={`${urlBack}`} />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <div
                      style={{
                        width: "100%",
                        background: blue700,
                        paddingTop: 5,
                        paddingBottom: 5,
                        color: "#FFF",
                      }}
                    >
                      <h1
                        style={{
                          textAlign: "center",
                          fontWeight: 400,
                        }}
                      >
                        {`Ranking ${nameProject}`}
                      </h1>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <List>{this.renderChildrens(tree)}</List>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <AuthComponent
                      component={
                        <RaisedButton
                          onClick={this._hanlderSaveRanking}
                          label={LBLPROJECTS.buttons.saveRanking}
                          secondary
                          style={{
                            margin: "40px 20px 20px",
                            float: "right",
                          }}
                        />
                      }
                      permission="backend/projects:write"
                      type="component"
                    />
                  </Col>
                </Row>
              </Paper>
            </Col>
          }
          permission="backend/projects:write"
          type="url"
        />
      </Row>
    );
  }
}

function isChecked(array, id) {
  return _.indexOf(array, id) !== -1;
}

function mapStateToProps({ projects, login }) {
  return {
    profile: login.get("profile"),
    tree: _.get(projects, "treeGroupers"),
    selectGroupers: _.get(projects, "selectGroupers"),
    nameProject: _.isNull(_.get(projects, "all.data.name", null))
      ? ""
      : `- ${_.get(projects, "all.data.name", "")}`,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      toggleSnackbar,
      FetchTreeGroupersSurveyParam,
      selectGrouperForProject,
      updateRankingProject,
      FetchProjectParam,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectsRankingNews);
