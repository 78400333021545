import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {blue700} from 'material-ui/styles/colors';
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn} from 'material-ui/Table';
import _ from 'lodash';
import Checkbox from 'material-ui/Checkbox';
import loadingData from './loadingData';
import {
    selectCompanies,
    LOADING_COMPANIES,
    LOAD_COMPANIES_FULFILLED,
    LOAD_COMPANIES_REJECTED,
    getAll,
    getItemsId,
} from './excelGeneratorDucks';

class CompaniesTable extends Component {

    state = {
        selectedAll: false
    };

    renderSurveyRow = (company, index) => {
        const {select, selectedCompanies} = this.props;
        const id = company._id;
        const currentRowSelected = _.get(selectedCompanies.get(id), 'selected', false);
        return (
            <TableRow key={id} hovered={false} striped={false} selectable={false}>
                <TableRowColumn style={{width: 15}}>
                    <Checkbox
                        onCheck={() => {
                            this.setState({selectedAll: false});
                            const selectedItems = getItemsId([index], this.props.companies);
                            select(selectedItems);
                        }}
                        checked={currentRowSelected}
                    />
                </TableRowColumn>
                <TableRowColumn>{_.get(company, 'businessName', '')} - <span
                    style={{ color: blue700 }}>{_.get(company, 'nit', '')}</span></TableRowColumn>
            </TableRow>
        );
    };

    selectAll = (e, checked) => {
        const {companies, select, selectedCompanies} = this.props;
        this.setState({selectedAll: checked});
        const row = getAll(companies, selectedCompanies, checked);
        const selectedItems = getItemsId(row.toArray(), companies);
        select(selectedItems);
    };

    render() {
        const {companies, status} = this.props;
        const companiesComponent = (
            <Table multiSelectable>
                <TableHeader enableSelectAll={false} displaySelectAll={false} adjustForCheckbox={false}>
                    <TableRow>
                        <TableHeaderColumn style={{width: 15}}>
                            <Checkbox
                                onCheck={this.selectAll}
                                checked={this.state.selectedAll}
                            />
                        </TableHeaderColumn>
                        <TableHeaderColumn>Nombre</TableHeaderColumn>
                    </TableRow>
                </TableHeader>
                <TableBody deselectOnClickaway={false} displayRowCheckbox={false}>
                    {companies.map(this.renderSurveyRow)}
                </TableBody>
            </Table>
        );
        return loadingData(LOADING_COMPANIES, LOAD_COMPANIES_REJECTED, LOAD_COMPANIES_FULFILLED, status, 'compañías', companiesComponent);
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({select: selectCompanies}, dispatch);
}

function mapStateToProps({surveysExcelGenerator}) {
    return {
        companies: surveysExcelGenerator.get('companies'),
        selectedCompanies: surveysExcelGenerator.get('selectedCompanies'),
        status: surveysExcelGenerator.get('statusCompanies'),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesTable);
