import PropTypes from "prop-types";
import React, { Component } from "react";
import TableCell from "@material-ui/core/TableCell";
import _ from "lodash";
import { Link } from "react-router-dom";
import IconButton from "material-ui/IconButton";
import { connect } from "react-redux";
import EditIcon from "material-ui/svg-icons/editor/mode-edit";
import SendIcon from "material-ui/svg-icons/content/send";
import AuthComponent from "../../AuthComponent";
import { LBLEVALUATORS } from "../../../constants/labels";
import DeleteEntityModal from "../../deleteEntityModal/component";
import { openModalSendEmail } from "../../ModalSendEmail/action";
import { deleteReferee } from "../../../actions/referees_form";
import { toggleSnackbar } from "../../../actions/commons";
import { removeRecord } from "../actions";

class RefereeActionColumn extends Component {
  constructor(props) {
    super(props);
    this._handleDeleteReferee = this._handleDeleteReferee.bind(this);
  }

  handleModalSendEmail = (data) => {
    const { openModalSendEmail } = this.props;
    openModalSendEmail([data], "referee");
  };

  _handleDeleteReferee(data) {
    const { deleteReferee, toggleSnackbar, removeRecord } = this.props;
    const idReferee = _.get(data, "_id");
    deleteReferee(idReferee)
      .then(
        (data) => {
          if (_.get(data, "error") || _.get(data, "payload.status") !== 200) {
            toggleSnackbar(true, LBLEVALUATORS.msg.errorDelete);
          } else {
            removeRecord(idReferee, "_id");
            toggleSnackbar(true, LBLEVALUATORS.msg.successDelete);
          }
        },
        (reason) => {
          toggleSnackbar(true, LBLEVALUATORS.msg.errorDelete);
        }
      )
      .catch((data) => {
        toggleSnackbar(true, LBLEVALUATORS.msg.errorDelete);
      });
  }

  render() {
    const { data, colSpan } = this.props;
    const cs = _.isUndefined(colSpan) ? 1 : colSpan;
    return (
      <TableCell colSpan={cs}>
        <div style={{ position: "absolute", marginTop: -24, zIndex: 99 }}>
          <AuthComponent
            component={
              <Link to={`/admin/referees/edit/${_.get(data, "_id")}`}>
                <IconButton
                  tooltip={LBLEVALUATORS.tooltips.btnEdit}
                  tooltipPosition="top-center"
                  children={<EditIcon />}
                />
              </Link>
            }
            permission="backend/referees:write"
            type="component"
          />
          <AuthComponent
            component={
              <DeleteEntityModal
                tooltip={LBLEVALUATORS.tooltips.btnDelete}
                title={LBLEVALUATORS.titleDelete}
                message={LBLEVALUATORS.msg.deleteModal(
                  `${_.get(data, "name")} ${_.get(data, "lastName")}`
                )}
                fn={this._handleDeleteReferee}
                args={[data]}
                style={{ display: "table-cell" }}
              />
            }
            permission="backend/referees:delete"
            type="component"
          />
          <AuthComponent
            component={
              <IconButton
                tooltip={LBLEVALUATORS.tooltips.btnSendMail}
                tooltipPosition="top-center"
                children={<SendIcon />}
                onClick={this.handleModalSendEmail.bind(this, data)}
              />
            }
            permission="backend/email:write"
            type="component"
          />
        </div>
      </TableCell>
    );
  }
}

RefereeActionColumn.propTypes = {
  data: PropTypes.object.isRequired,
  colSpan: PropTypes.number,
};

export default connect(null, {
  deleteReferee,
  toggleSnackbar,
  removeRecord,
  openModalSendEmail,
})(RefereeActionColumn);
