import PropTypes from "prop-types";
import React, { Component } from "react";
import TableCell from "@material-ui/core/TableCell";
import _ from "lodash";
import { Link } from "react-router-dom";
import IconButton from "material-ui/IconButton";
import { connect } from "react-redux";
import EditIcon from "material-ui/svg-icons/editor/mode-edit";
import AuthComponent from "../../AuthComponent";
import { LBLEMPLOYESS } from "../../../constants/labels";
import DeleteEntityModal from "../../deleteEntityModal/component";
import { deleteEmploye } from "../../../actions/employees_form";
import { toggleSnackbar } from "../../../actions/commons";
import { removeRecord } from "../actions";

class EmployeeActionColumn extends Component {
  constructor(props) {
    super(props);
    this.handleTouchDelete = this.handleTouchDelete.bind(this);
  }

  handleTouchDelete(data) {
    const { toggleSnackbar, deleteEmploye, removeRecord } = this.props;
    const idEmployee = _.get(data, "_id");
    deleteEmploye(idEmployee)
      .then(
        (data) => {
          if (_.get(data, "error") || _.get(data, "payload.status") !== 200) {
            toggleSnackbar(true, LBLEMPLOYESS.msg.errorDelete);
          } else {
            removeRecord(idEmployee, "_id");
            toggleSnackbar(true, LBLEMPLOYESS.msg.successDelete);
          }
        },
        () => {
          toggleSnackbar(true, LBLEMPLOYESS.msg.errorDelete);
        }
      )
      .catch(() => {
        toggleSnackbar(true, LBLEMPLOYESS.msg.errorDelete);
      });
  }

  render() {
    const { data, colSpan, extra } = this.props;
    const cs = _.isUndefined(colSpan) ? 1 : colSpan;

    return (
      <TableCell colSpan={cs}>
        <div style={{ position: "absolute", marginTop: -24, zIndex: 99 }}>
          <AuthComponent
            component={
              <Link
                to={`/admin/employees/edit/${_.get(extra, "profile")}/${_.get(
                  extra,
                  "company"
                )}/${_.get(data, "_id")}`}
              >
                <IconButton
                  tooltip={LBLEMPLOYESS.tooltips.btnEdit}
                  tooltipPosition="top-center"
                >
                  <EditIcon />
                </IconButton>
              </Link>
            }
            permission="backend/employees:write"
            type="component"
          />
          <AuthComponent
            component={
              <DeleteEntityModal
                tooltip={LBLEMPLOYESS.tooltips.btnDelete}
                title={LBLEMPLOYESS.titleDelete}
                message={LBLEMPLOYESS.msg.deleteModal(
                  `${_.get(data?.person, "name")} ${_.get(data?.person, "lastName")}`
                )}
                fn={this.handleTouchDelete}
                args={[data]}
                style={{ display: "table-cell" }}
              />
            }
            permission="backend/employees:delete"
            type="component"
          />
        </div>
      </TableCell>
    );
  }
}

EmployeeActionColumn.propTypes = {
  data: PropTypes.object.isRequired,
  colSpan: PropTypes.number,
  extra: PropTypes.object,
};

export default connect(null, { deleteEmploye, toggleSnackbar, removeRecord })(
  EmployeeActionColumn
);
