import {axiosAPI} from '../../middleware/api';

export const LOAD_SECTIONS = "LOAD_SECTIONS";

export function loadSections(surveyId) {
    const request = axiosAPI.get(`/surveys/sections/${surveyId}`);

    return {
        type: LOAD_SECTIONS,
        payload: request
    }
}
