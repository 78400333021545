import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";

const dashboardsQuery = gql`
  query($nextToken: String) {
    getMyDashboards(nextToken: $nextToken) {
      dashboards {
        id
        name
        createdtime
        lastUpdatedTime
      }
      nextToken
    }
  }
`;

export const dashboardSignedUrlQuery = gql`
  query($dashboardId: ID!) {
    getDashboardLink(dashboardId: $dashboardId)
  }
`;

export function useDashboards() {
  const { fetchMore, loading, error, data } = useQuery(dashboardsQuery);
  const isReady = !loading && !error;
  const nextToken = isReady ? data.getMyDashboards.nextToken : "";
  const hasMore = nextToken !== "";
  const dashboards = isReady ? data.getMyDashboards.dashboards : [];
  const handleFetchMore = () => {
    fetchMore({
      variables: {
        nextToken,
      },
    });
  };

  return {
    error,
    loading,
    hasMore,
    dashboards,
    fetchMore: handleFetchMore,
  };
}
