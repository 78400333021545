import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {RadioButton, RadioButtonGroup} from 'material-ui/RadioButton';
import {connect} from 'react-redux';
import {blue700} from 'material-ui/styles/colors';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import TitleGroup from './TitleGroup';

const styleWidthContentTitle = {
          padding: '0px 20px 0px 0px',
          borderTop: 1,
          borderTopStyle: 'solid',
          borderTopColor: "#eee",
      }; const styleWidthContentTitle2 = {
          borderTop: 1,
          borderTopStyle: 'solid',
          borderTopColor: "#eee",
          paddingTop: 10,
          paddingBottom: 0,
          width: 90,
          verticalAlign: 'center'
      };

class QuestionCloseGroup extends Component {

    constructor(props) {
        super(props);
        this._onChange = this._onChange.bind(this);
    }

    _onChange(e, value) {
        this.props._handlerUpdateValue(e, value);
    }

    render() {
        const self = this;
        const {
            question,
            index,
            valueQuestion
        } = this.props;
        return (
            <tr>
                <td style={styleWidthContentTitle}>
                    <TitleGroup title={`${index  }. ${ _.get(question, 'title')}`}/>
                </td>
                <td style={styleWidthContentTitle2}>
                    <div>
                        <RadioButtonGroup
                            name={question.title}
                            style={{display: 'block'}}
                            onChange={this._onChange}
                            valueSelected={valueQuestion}
                        >
                            <RadioButton
                                value="Si"
                                style={{
                                    marginBottom: 5,
                                    display: 'inline-block',
                                    width: '50%'
                                }}
                            />
                            <RadioButton
                                value="No"
                                style={{
                                    marginBottom: 5,
                                    display: 'inline-block',
                                    width: '50%'
                                }}
                            />
                        </RadioButtonGroup>
                    </div>
                </td>
            </tr>
        );
    }
}

QuestionCloseGroup.propTypes = {
    question: PropTypes.object.isRequired
};

function mapStateToProps({ questionsSurvey }, ownerProps) {
    return {
        valueQuestion: _.get(questionsSurvey.get('answered').toObject(), _.get(ownerProps, 'idQuestion'))
    };
}

export default connect(mapStateToProps)(QuestionCloseGroup);
