import React, { Component } from "react";
import { blue700 } from "material-ui/styles/colors";
import { Row, Col } from "react-flexbox-grid";
import Paper from "material-ui/Paper";
import { connect } from "react-redux";
import RaisedButton from "material-ui/RaisedButton";
import AutoComplete from "material-ui/AutoComplete";
import MenuItem from "material-ui/MenuItem";
import Divider from "material-ui/Divider";
import IconButton from "material-ui/IconButton";
import CleanIcon from "material-ui/svg-icons/content/clear";
import moment from "moment";
import CircularProgress from "material-ui/CircularProgress";
import _ from "lodash";
import {
  FetchCompanyParam,
  getCompaniesName,
  cleanProject,
  inSearchProjectCompanies,
} from "../../actions/info/companies_info_form";
import { getAllUsers, clearUsersAudit, inSearchUsersAudit } from "./actions";
import { toggleSnackbar } from "../../actions/commons";
import SelectFieldFormat from "../fields/SelectFieldFormat/SelectFieldFormat";
import SpinnerLoading from "../SpinnerLoading/componentSpinner";
import { spanishLocale } from "../../constants/index";
import AuthComponent from "../AuthComponent";
import TablePagination from "../TablePagination/tablePagination";
import { setInSearchRecords, getRecords } from "../TablePagination/actions";

class ViewAudit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isReferee: false,
      valueCompany: undefined,
      valueProject: undefined,
      valueWho: "",
      valueWhom: "",
      valueWhoName: "",
      valueWhomName: "",
    };
    this.renderSelectProject = this.renderSelectProject.bind(this);
    this.renderSelectUsers = this.renderSelectUsers.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleCleanFilter = this.handleCleanFilter.bind(this);
    this.getProjectsByCompany = this.getProjectsByCompany.bind(this);
    this.getUsersAuditByProject = this.getUsersAuditByProject.bind(this);
    this.handleCleanWhoFilter = this.handleCleanWhoFilter.bind(this);
    this.handleCleanWhomFilter = this.handleCleanWhomFilter.bind(this);
    this.handleOnNewRequestWho = this.handleOnNewRequestWho.bind(this);
    this.handleOnNewRequestWhom = this.handleOnNewRequestWhom.bind(this);
  }

  UNSAFE_componentWillMount() {
    const {
      getCompaniesName,
      cleanProject,
      clearUsersAudit,
      profile,
    } = this.props;
    getCompaniesName();
    clearUsersAudit();
    cleanProject();
    if (!_.isEmpty(profile)) {
      this.state = {
        isReferee: _.isEqual(_.get(profile, "role"), "referee"),
      };
    }
  }

  componentWillUnmount() {
    const { cleanProject, clearUsersAudit } = this.props;
    cleanProject();
    clearUsersAudit();
  }

  handleCleanFilter() {
    const {
      cleanProject,
      clearUsersAudit,
      limit,
      orderName,
      orderType,
      arrayFields,
      getRecords,
    } = this.props;
    this.setState({
      valueCompany: undefined,
      valueProject: undefined,
      valueWho: "",
      valueWhom: "",
      valueWhoName: "",
      valueWhomName: "",
    });
    this.refs.who.state.searchText = "";
    this.refs.whom.state.searchText = "";
    getRecords(
      "/audit",
      2,
      0,
      limit,
      orderName,
      orderType,
      arrayFields,
      "",
      {}
    );
    cleanProject();
    clearUsersAudit();
  }

  handleFilter(extra) {
    const {
      skip,
      limit,
      orderName,
      orderType,
      arrayFields,
      getRecords,
      setInSearchRecords,
    } = this.props;
    setInSearchRecords();
    getRecords(
      "/audit",
      2,
      skip,
      limit,
      orderName,
      orderType,
      arrayFields,
      "",
      extra
    );
  }

  handleFilterAutocomplete(searchText, key) {
    if (searchText.length === 0) {
      return false;
    }
    return !_.isEqual(key.toLowerCase().search(searchText.toLowerCase()), -1);
  }

  getProjectsByCompany(e, index, idCompany) {
    const {
      FetchCompanyParam,
      clearUsersAudit,
      inSearchProjectCompanies,
    } = this.props;
    this.setState({
      valueCompany: idCompany,
      valueProject: undefined,
      valueWho: "",
      valueWhom: "",
      valueWhoName: "",
      valueWhomName: "",
    });
    inSearchProjectCompanies();
    FetchCompanyParam(idCompany);
    clearUsersAudit();
  }

  getUsersAuditByProject(e, index, idProject) {
    const { getAllUsers, inSearchUsersAudit } = this.props;
    this.setState({ valueProject: idProject });
    const extra = { idProject };
    this.handleFilter(extra);
    inSearchUsersAudit();
    getAllUsers(idProject);
  }

  renderSelectProject() {
    const { inSearchProjectsCompanies, projects } = this.props;
    const { valueCompany } = this.state;
    const disabledProject =
      _.isNull(valueCompany) ||
      _.isUndefined(valueCompany) ||
      _.isEqual(valueCompany, "");
    const hasProject = _.size(projects) > 0;
    if (!inSearchProjectsCompanies) {
      if (hasProject && !disabledProject) {
        return (
          <Col md={4} xsOffset={1} xs={10}>
            <SelectFieldFormat
              config={{
                floatingLabelText: "Proyectos",
                ref: "projects",
                value: this.state.valueProject,
                autoWidth: true,
                fullWidth: true,
                disabled: disabledProject,
                onChange: this.getUsersAuditByProject,
              }}
            >
              {_.map(_.orderBy(projects, ["name"]), (item) => {
                return (
                  <MenuItem
                    key={item._id}
                    value={item._id}
                    primaryText={item.name}
                  />
                );
              })}
            </SelectFieldFormat>
          </Col>
        );
      }
      if (disabledProject) {
        return (
          <Col md={4} xsOffset={1} xs={10}>
            <SelectFieldFormat
              config={{
                floatingLabelText: "Proyectos",
                autoWidth: true,
                fullWidth: true,
                disabled: disabledProject,
              }}
            />
          </Col>
        );
      }
      return (
        <Col md={4} xsOffset={1} xs={10}>
          <h3
            style={{
              fontWeight: 400,
              margin: "35px 0 0 0",
            }}
          >
            La empresa seleccionada no tiene ningún proyecto
          </h3>
        </Col>
      );
    }
    return (
      <Col md={4} xsOffset={1} xs={10}>
        <CircularProgress
          size={30}
          thickness={3}
          style={{ margin: "35px 0 0 0" }}
        />
      </Col>
    );
  }

  renderSelectUsers() {
    const {
      inSearchUsers,
      projects,
      dataSourceUsersWho,
      dataSourceUsersWhom,
    } = this.props;
    const { valueCompany, valueProject } = this.state;
    const disabledWhoAndWhom =
      _.size(projects) === 0 ||
      _.isNull(valueCompany) ||
      _.isUndefined(valueCompany) ||
      _.isEqual(valueCompany, "") ||
      _.isNull(valueProject) ||
      _.isUndefined(valueProject) ||
      _.isEqual(valueProject, "");
    const disabledProject =
      _.isNull(valueCompany) ||
      _.isUndefined(valueCompany) ||
      _.isEqual(valueCompany, "");
    const hasUsers =
      _.size(dataSourceUsersWho) > 0 && _.size(dataSourceUsersWhom) > 0;

    if (!inSearchUsers) {
      if (hasUsers && !disabledWhoAndWhom) {
        return (
          <div>
            <Row>
              <Col mdOffset={1} md={4} xsOffset={1} xs={10}>
                <AutoComplete
                  hintText="Buscar quién"
                  dataSource={dataSourceUsersWho}
                  ref="who"
                  maxSearchResults={5}
                  filter={this.handleFilterAutocomplete}
                  floatingLabelText="Quién"
                  searchText={this.state.valueWhoName}
                  animated
                  fullWidth
                  disabled={disabledWhoAndWhom}
                  onNewRequest={this.handleOnNewRequestWho}
                  onUpdateInput={(value) => {
                    let id = value;
                    if (_.has(value, "id")) {
                      id = value.id;
                    }
                    this.setState({ valueWhoName: id });
                  }}
                />
                <span
                  style={{
                    position: "absolute",
                    margin: "20px 0px 0px -7px",
                  }}
                >
                  <IconButton
                    tooltip="Limpiar"
                    tooltipPosition="top-center"
                    disabled={disabledWhoAndWhom}
                    onClick={this.handleCleanWhoFilter}
                  >
                    <CleanIcon />
                  </IconButton>
                </span>
              </Col>
              <Col md={4} xsOffset={1} xs={10}>
                <AutoComplete
                  hintText="Buscar a quién"
                  floatingLabelText="A Quién"
                  maxSearchResults={5}
                  ref="whom"
                  animated
                  filter={this.handleFilterAutocomplete}
                  dataSource={dataSourceUsersWhom}
                  searchText={this.state.valueWhomName}
                  fullWidth
                  disabled={disabledWhoAndWhom}
                  onNewRequest={this.handleOnNewRequestWhom}
                  onUpdateInput={(value) => {
                    let id = value;
                    if (_.has(value, "id")) {
                      id = _.get(value, "id");
                    }
                    this.setState({ valueWhomName: id });
                  }}
                />
                <span
                  style={{
                    position: "absolute",
                    margin: "20px 0px 0px -7px",
                  }}
                >
                  <IconButton
                    tooltip="Limpiar"
                    tooltipPosition="top-center"
                    disabled={disabledWhoAndWhom}
                    onClick={this.handleCleanWhomFilter}
                  >
                    <CleanIcon />
                  </IconButton>
                </span>
              </Col>
            </Row>
          </div>
        );
      }
      if (disabledWhoAndWhom) {
        return (
          <Row>
            <Col mdOffset={1} md={4} xsOffset={1} xs={10}>
              <AutoComplete
                hintText="Buscar quién"
                floatingLabelText="Quién"
                animated
                fullWidth
                dataSource={[]}
                disabled={disabledWhoAndWhom}
              />
              <span
                style={{
                  position: "absolute",
                  margin: "20px 0px 0px -7px",
                }}
              >
                <IconButton
                  tooltip="Limpiar"
                  tooltipPosition="top-center"
                  disabled={disabledWhoAndWhom}
                >
                  <CleanIcon />
                </IconButton>
              </span>
            </Col>
            <Col md={4} xsOffset={1} xs={10}>
              <AutoComplete
                hintText="Buscar a quién"
                floatingLabelText="A Quién"
                animated
                fullWidth
                dataSource={[]}
                disabled={disabledWhoAndWhom}
              />
              <span
                style={{
                  position: "absolute",
                  margin: "20px 0px 0px -7px",
                }}
              >
                <IconButton
                  tooltip="Limpiar"
                  tooltipPosition="top-center"
                  disabled={disabledWhoAndWhom}
                >
                  <CleanIcon />
                </IconButton>
              </span>
            </Col>
          </Row>
        );
      }
      return (
        <Row>
          <Col xsOffset={1} xs={10}>
            <h3
              style={{
                fontWeight: 400,
                height: 72,
                margin: "35px auto 15px auto",
                textAlign: "center",
              }}
            >
              La empresa y el proyecto seleccionado no tienen usuarios para
              realizar el filtro
            </h3>
          </Col>
        </Row>
      );
    }
    return (
      <Row>
        <Col xsOffset={1} xs={10}>
          <div style={{ textAlign: "center", height: 72 }}>
            <CircularProgress
              size={42}
              thickness={2}
              style={{ margin: "35px 0 15px 0" }}
            />
          </div>
        </Col>
      </Row>
    );
  }

  handleCleanWhoFilter() {
    const extra = {
      who: "0",
      whom: _.isEmpty(_.get(this, "state.valueWhom"))
        ? "0"
        : _.get(this, "state.valueWhom"),
      idProject: _.get(this, "state.valueProject"),
    };
    this.setState({ valueWho: "", valueWhoName: "" });
    this.handleFilter(extra);
  }

  handleCleanWhomFilter() {
    const extra = {
      who: _.isEmpty(_.get(this, "state.valueWho"))
        ? "0"
        : _.get(this, "state.valueWho"),
      whom: "0",
      idProject: _.get(this, "state.valueProject"),
    };
    this.setState({ valueWhom: "", valueWhomName: "" });
    this.handleFilter(extra);
  }

  handleOnNewRequestWho(value) {
    let id = value;
    let name = value;
    if (_.has(value, "id") && _.has(value, "value")) {
      id = _.get(value, "id");
      name = _.get(value, "value");
    }
    this.setState({ valueWho: id, valueWhoName: name });
    const extra = {
      who: id,
      whom: _.isEmpty(_.get(this, "state.valueWhom"))
        ? "0"
        : _.get(this, "state.valueWhom"),
      idProject: _.get(this, "state.valueProject"),
    };
    this.handleFilter(extra);
  }

  handleOnNewRequestWhom(value) {
    let id = value;
    let name = value;
    if (_.has(value, "id") && _.has(value, "value")) {
      id = _.get(value, "id");
      name = _.get(value, "value");
    }
    this.setState({ valueWhom: id, valueWhomName: name });
    const extra = {
      who: _.isEmpty(_.get(this, "state.valueWho"))
        ? "0"
        : _.get(this, "state.valueWho"),
      whom: id,
      idProject: _.get(this, "state.valueProject"),
    };
    this.handleFilter(extra);
  }

  render() {
    const body = [
      {
        field: "date",
        title: "Fecha",
      },
      {
        field: "who",
        title: "Quién",
        order: false,
        colspan: 2,
      },
      {
        field: "event",
        title: "Evento",
        order: false,
      },
      {
        field: "whom",
        title: "A quién",
        order: false,
        colspan: 2,
      },
      {
        field: "project.survey.name",
        title: "Encuesta",
        order: false,
        colspan: 2,
      },
    ];

    moment.locale(spanishLocale);

    const {
      companies,
      projects,
      inSearchUsers,
      inSearchProjectsCompanies,
    } = this.props;

    const extra = {
      who: _.isEmpty(_.get(this, "state.valueWho"))
        ? "0"
        : _.get(this, "state.valueWho"),
      whom: _.isEmpty(_.get(this, "state.valueWhom"))
        ? "0"
        : _.get(this, "state.valueWhom"),
      idProject: _.get(this, "state.valueProject"),
    };

    return (
      <Row>
        <AuthComponent
          component={
            <Col xsOffset={1} xs={10}>
              <Paper
                style={{
                  marginTop: 40,
                  marginBottom: 20,
                  width: "100%",
                }}
              >
                <Row>
                  <Col xs>
                    <div
                      style={{
                        width: "100%",
                        background: blue700,
                        paddingTop: 5,
                        paddingBottom: 5,
                        color: "#FFF",
                      }}
                    >
                      <h1
                        style={{
                          textAlign: "center",
                          fontWeight: 400,
                        }}
                      >
                        Ver auditorías
                      </h1>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col mdOffset={1} md={4} xsOffset={1} xs={10}>
                    <SelectFieldFormat
                      config={{
                        floatingLabelText: "Empresas",
                        ref: "companies",
                        value: this.state.valueCompany,
                        autoWidth: true,
                        fullWidth: true,
                        onChange: this.getProjectsByCompany,
                      }}
                    >
                      {_.map(_.orderBy(companies, ["businessName"]), (item) => {
                        return (
                          <MenuItem
                            key={item._id}
                            value={item._id}
                            primaryText={item.businessName}
                          />
                        );
                      })}
                    </SelectFieldFormat>
                  </Col>
                  {this.renderSelectProject()}
                </Row>
                <AuthComponent
                  component={<div>{this.renderSelectUsers()}</div>}
                  permission="backend/audit:read"
                  type="component"
                />
                <Row>
                  <Col xsOffset={1} xs={4}>
                    <RaisedButton
                      label="Limpiar todo"
                      secondary
                      style={{ margin: "17px 0" }}
                      onClick={this.handleCleanFilter}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs>
                    <TablePagination
                      name="audit"
                      URL="/audit"
                      version={2}
                      extra={extra}
                      body={body}
                      hasFilter={false}
                      hasRefreshNotFound={false}
                    />
                  </Col>
                </Row>
              </Paper>
              <SpinnerLoading />
            </Col>
          }
          permission="backend/audit:read"
          type="url"
        />
      </Row>
    );
  }
}

function mapStateToProps(
  { companies, login, audits, tablePagination },
  owersProps
) {
  return {
    companies: _.get(companies, "all", []),
    inSearchProjectsCompanies: _.get(
      companies,
      "inSearchProjectsCompanies",
      false
    ),
    projects: _.get(companies, "company.data.projects", []),
    profile: login.get("profile"),
    dataSourceUsersWho: audits.get("storeUsersWho"),
    dataSourceUsersWhom: audits.get("storeUsersWhom"),
    inSearchUsers: audits.get("inSearchUsers"),
    limit: tablePagination.get("limit"),
    skip: tablePagination.get("skip"),
    orderName: tablePagination.get("orderName"),
    orderType: tablePagination.get("orderType"),
    arrayFields: tablePagination.get("arrayFields"),
  };
}

export default connect(mapStateToProps, {
  FetchCompanyParam,
  getCompaniesName,
  toggleSnackbar,
  getRecords,
  setInSearchRecords,
  cleanProject,
  getAllUsers,
  clearUsersAudit,
  inSearchProjectCompanies,
  inSearchUsersAudit,
})(ViewAudit);
