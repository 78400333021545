import React, { Component } from 'react';
import assign from 'lodash/assign';
import QuestionListOpen from './question/QuestionListOpen';
import QuestionListClose from './question/QuestionListClose';
import QuestionClose from './question/QuestionClose';
import QuestionOpen from './question/QuestionOpen';
import QuestionDate from './question/QuestionDate';
import QuestionSelect from './question/QuestionSelect';
import QuestionLocationCountry from './question/QuestionLocationCountry';
import QuestionLocationDepartment from './question/QuestionLocationDepartment';
import QuestionLocationCity from './question/QuestionLocationCity';

import GroupClose from './question/group/GroupClose';
import GroupList from './question/group/GroupList';
import Group from './question/group/Group';

import MixinQuestion from './question/mixinQuestion';

class Question extends Component {

    render() {
        const {
            question,
            idSurvey,
            idProject,
            idQuestion,
            sectionOrder,
            index
        } = this.props;
        const propsToSet = assign({}, {
            idSurvey,
            idProject,
            idQuestion: idQuestion instanceof Object ? idQuestion._id : idQuestion,
            sectionOrder,
            question
        });
        
        switch (question.type) {
            case 'AnswerOpen':
                var MixinTextQuestions = MixinQuestion(QuestionOpen);
                return <MixinTextQuestions
                    {...propsToSet}
                    type="text"
                    index={index}
                />;
            case 'AnswerOpenShort':
                var MixinTextShortQuestions = MixinQuestion(QuestionOpen);
                return <MixinTextShortQuestions
                    {...propsToSet}
                    type="textShort"
                    index={index}
                />;
            case 'AnswerClose':
                var MixinCloseQuestions = MixinQuestion(QuestionClose);
                return <MixinCloseQuestions
                    {...propsToSet}
                    index={index}
                />;
            case 'ListOpen':
                var MixinListOpenQuestions = MixinQuestion(QuestionListOpen);
                return <MixinListOpenQuestions
                    {...propsToSet}
                    index={index}
                />;
            case 'Date':
                var MixinDateQuestions = MixinQuestion(QuestionDate);
                return <MixinDateQuestions
                    {...propsToSet}
                    index={index}
                />;
            case 'ListClose':
                var MixinListCloseQuestions = MixinQuestion(QuestionListClose);
                return <MixinListCloseQuestions
                    {...propsToSet}
                    index={index}
                />;
            case 'ListSelected':
                var MixinListSeletedQuestions = MixinQuestion(QuestionSelect);
                return <MixinListSeletedQuestions
                    {...propsToSet}
                    index={index}
                />;
            case 'LocationCountry':
                var MixinQuestionLocationCountry = MixinQuestion(QuestionLocationCountry);
                return <MixinQuestionLocationCountry
                    {...propsToSet}
                    index={index}
                />;
            case 'LocationDepartment':
                var MixinQuestionLocationDepartment = MixinQuestion(QuestionLocationDepartment);
                return <MixinQuestionLocationDepartment
                    {...propsToSet}
                    index={index}
                />;
            case 'LocationCity':
                var MixinQuestionLocationCity = MixinQuestion(QuestionLocationCity);
                return <MixinQuestionLocationCity
                    {...propsToSet}
                    index={index}
                />;
            case 'Number':
                var MixinNumberQuestions = MixinQuestion(QuestionOpen);
                return <MixinNumberQuestions
                    {...propsToSet}
                    type="number"
                    index={index}
                />;
            case 'GroupClose':
                return <GroupClose question={question} propsToSet={propsToSet} index={index}/>;
            case 'GroupList':
                return <GroupList question={question} propsToSet={propsToSet} index={index}/>;
            case 'Group':
                return <Group question={question} propsToSet={propsToSet} index={index}/>;
            default:
                return <div />;
        }
    }
}

export default Question;
