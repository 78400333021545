import immutable from 'immutable';
import _ from 'lodash';
import { GET_ALL_USERS_AUDITS, CLEAR_ALL_USERS_AUDITS, GET_USERS_AUDITS_IN_SEARCH } from './constants';

const initialState = immutable.Map({
    storeUsersWho: [],
    storeUsersWhom: [],
    inSearchUsers: false
});

export default function (state = initialState, action) {
    switch (_.get(action, 'type')) {
        case GET_ALL_USERS_AUDITS:
            return state.withMutations(function(s) {
                        s.set("storeUsersWho", _.map(_.get(action, "payload.data.usersWho", []), user => ({
                                                                      text: `${_.get(user, 'name')} ${_.get(user, 'lastName')} - ${_.get(user, 'id')}`,
                                                                      value: `${_.get(user, 'name')} ${_.get(user, 'lastName')} - ${_.get(user, 'id')}`,
                                                                      id: _.get(user, '_id')
                                                                  }) ));

                        s.set("storeUsersWhom", _.map(_.get(action, "payload.data.usersWhom", []), user => ({
                                                                      text: `${_.get(user, 'name')} ${_.get(user, 'lastName')} - ${_.get(user, 'id')}`,
                                                                      value: `${_.get(user, 'name')} ${_.get(user, 'lastName')} - ${_.get(user, 'id')}`,
                                                                      id: _.get(user, '_id')
                                                                  }) ));
                        s.set("inSearchUsers", false);
                    });
        case CLEAR_ALL_USERS_AUDITS:
            return state.withMutations(function(s) {
                        s.set("storeUsersWho", []);
                        s.set("storeUsersWhom", []);
                        s.set("inSearchUsers", false);
                    });
        case GET_USERS_AUDITS_IN_SEARCH:
            return state.set("inSearchUsers", true);
        default:
            return state;
    }
}
