import React from "react";
import { useField } from "formik";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const OtherField = ({ disabled, label, ...props }) => {
  const [field] = useField(props);
  return (
    <FormControlLabel
      control={<Checkbox {...field} color="primary" />}
      label={label}
    />
  );
};

export default OtherField;
