import React, {useEffect, useState} from "react";
import Checkbox from "@material-ui/core/Checkbox";
import MaUTable from "@material-ui/core/Table";
import PropTypes from "prop-types";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import {
    useGlobalFilter,
    usePagination,
    useRowSelect,
    useSortBy,
    useTable,
} from "react-table";
import {Link as RouterLink} from "react-router-dom";
import {Link} from "@material-ui/core";
import {removeAliasesEmail} from "Utils";
import Index from "../../commons/alert-dialog";
import TableToolbar from "./TableToolbar";
import TablePaginationActions from "./TablePaginationActions";
import {LOCALE_USER_TYPE, LOCALE_PROFILE} from "../localTranslation";

const IndeterminateCheckbox = React.forwardRef(
    ({indeterminate, ...rest}, ref) => {
        const defaultRef = React.useRef();
        const resolvedRef = ref || defaultRef;

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);

        return (
            <>
                <Checkbox ref={resolvedRef} {...rest} />
            </>
        );
    }
);

const LinkBehavior = React.forwardRef((props, ref) => (
    <RouterLink ref={ref} {...props} />
));

/**
 * Table for the detail of users
 * @author Jhoan Lopez <jhoan.lopez@ias.com.co>
 * @param columns
 * @param data
 * @param numberResults
 * @param fetchData
 * @param handleDelete
 * @returns {JSX.Element}
 * @constructor
 */
const EnhancedTable = ({columns, data, numberResults, fetchData, handleDelete}) => {
    const {
        getTableProps,
        headerGroups,
        prepareRow,
        page,
        setPageSize,
        state: {pageSize, selectedRowIds},
    } = useTable(
        {
            columns,
            data,
            initialState: {pageSize: 25},
            handleDelete
        },
        useGlobalFilter,
        useSortBy,
        usePagination,
        useRowSelect,
        (hooks) => {
            hooks.allColumns.push((columns) => [
                {
                    id: "selection",
                    Cell: ({row}) => (
                        <div>
                            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                        </div>
                    ),
                },
                ...columns,
            ]);
        }
    );
    const [openAlertDialog, setOpenAlertDialog] = useState(false);
    const [nextPage, setNextPage] = useState(0);
    const [search, setSearch] = useState('');

    const timeout = React.useRef();
    useEffect(() => {
        if (timeout.current) clearTimeout(timeout.current);
        timeout.current = setTimeout(
            () =>
                fetchData && fetchData({nextPage, pageSize, search}),
            500
        );
    }, [fetchData, pageSize, nextPage, search])

    const handleChangePage = (event, newPage) => {
        setNextPage(newPage)
    };

    const handleChangeRowsPerPage = (event) => {
        setPageSize(Number(event.target.value));
    };

    const handleChangeSearchFilter = (value) => {
        setNextPage(0);
        setSearch(value)
    }

    const handleOpenAlertDialog = () => {
        setOpenAlertDialog(true);
    };

    const handleCloseAlertDialog = () => {
        setOpenAlertDialog(false);
    };

    const deleteUserHandler = () => {
        const indexes = Object.keys(selectedRowIds).map((x) => parseInt(x, 10));
        handleDelete(data, indexes);
        setOpenAlertDialog(false);
    };

    function renderSwitch({row, cell}) {
        const {person, user} = row.original
        switch (cell.column.Header) {
            case 'Identificación':
                return (
                    <TableCell key={`${cell.column.id}`}>
                        <Link
                            component={LinkBehavior}
                            to={{
                                pathname: `/admin/users/user-grid-detail/${person.information.identification.number}`,
                                state: cell.row.original,
                            }}
                        >
                            {cell.render("Cell")}
                        </Link>
                    </TableCell>
                );
            case 'Email':
                return (
                    <TableCell align="left" key={`${cell.column.id}`}>
                        {removeAliasesEmail(person.information.email)}
                    </TableCell>
                )
            case 'Tipo de usuario':
                return (
                    <TableCell align="left" key={`${cell.column.id}`}>
                        {LOCALE_USER_TYPE[user.type.kind]
                            ? LOCALE_USER_TYPE[user.type.kind]
                            : user.type.kind}
                    </TableCell>
                )
            case 'Perfil':
                return (
                    <TableCell align="left" key={`${cell.column.id}`}>
                        {LOCALE_PROFILE[user.profiles[0]]
                            ? LOCALE_PROFILE[user.profiles[0]]
                            : user.profiles[0]}
                    </TableCell>
                )
            default:
                return (
                    <TableCell {...cell.getCellProps()} key={`${cell.column.id}`}>
                        {cell.render("Cell")}
                    </TableCell>
                );
        }
    }

    return (
        <div>
            <TableContainer>
                <TableToolbar
                    numSelected={Object.keys(selectedRowIds).length}
                    deleteUserHandler={handleOpenAlertDialog}
                    search={search}
                    setSearch={handleChangeSearchFilter}
                />
                <MaUTable {...getTableProps()}>
                    <TableHead>
                        {headerGroups.map((headerGroup) => (
                            <TableRow
                                style={{backgroundColor: "#f5f5f5", paddingTop: 100}}
                                {...headerGroup.getHeaderGroupProps()}
                            >
                                {headerGroup.headers.map((column) => (
                                    <TableCell
                                        {...(column.id === "selection"
                                            ? column.getHeaderProps()
                                            : column.getHeaderProps(column.getSortByToggleProps()))}
                                    >
                                        {column.render("Header")}
                                        {column.id !== "selection" ? (
                                            <TableSortLabel
                                                active={column.isSorted}
                                                direction={column.isSortedDesc ? "desc" : "asc"}
                                            />
                                        ) : null}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableHead>
                    <TableBody>
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <TableRow {...row.getRowProps()}>
                                    {row.cells.map((cell) => renderSwitch({row, cell}))}
                                </TableRow>
                            );
                        })}
                    </TableBody>

                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[
                                    25,
                                    50,
                                    100,
                                    {label: "Todos", value: numberResults},
                                ]}
                                colSpan={8}
                                count={numberResults}
                                rowsPerPage={pageSize}
                                page={nextPage}
                                SelectProps={{
                                    inputProps: {"aria-label": "registros por página"},
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </MaUTable>
            </TableContainer>
            <Index
                title="Eliminar usuario"
                description="¿Está seguro que desea eliminar el(los) usuario(s)?"
                open={openAlertDialog}
                handleClose={handleCloseAlertDialog}
                handleAccept={deleteUserHandler}
            />
        </div>
    );
};

EnhancedTable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    handleDelete: PropTypes.func.isRequired,
    numberResults: PropTypes.number.isRequired,
    fetchData: PropTypes.func.isRequired
};

export default EnhancedTable;
