import { axiosAPI } from '../../../middleware/api';

export const GET_VALUES_LIST_SECTIONS = "GET_VALUES_LIST_SECTIONS";
export const CLEAN_STATE_TREE_MAP = "CLEAN_STATE_TREE_MAP";

/**
 *
 * @param idSection
 */
export function getValuesList(idSection) {
    const request = axiosAPI.get(`/sections/questions/${idSection}`);

    return {
        type: GET_VALUES_LIST_SECTIONS,
        payload: request
    };
}


export function cleanStateTreeMap(storeName) {
    return {
        type: CLEAN_STATE_TREE_MAP,
        storeName
    }
}
