import _ from "lodash";
import { useQuery, useMutation, gql } from "@apollo/client";
import client from "../../middleware/graphql.ts";

const GET_COMPLEMENTS = gql`
  query {
    complementsWithValues {
      _id
      name
      values {
        _id
        name
      }
    }
  }
`;

const CREATE_COMPLEMENT = gql`
  mutation($input: CreateComplementInput!) {
    newComplement(input: $input) {
      _id
      name
    }
  }
`;

const EDIT_COMPLEMENT = gql`
  mutation editComplement($input: EditComplementInput!) {
    editComplement(input: $input) {
      _id
      name
    }
  }
`;

const CREATE_COMPLEMENT_VALUE = gql`
  mutation newComplementValue($input: ComplementValueInput!) {
    newComplementValue(input: $input) {
      _id
      name
      complement {
        _id
      }
    }
  }
`;

const DELETE_COMPLEMENT = gql`
  mutation deleteComplement($input: ComplementIdInput!) {
    deleteComplement(input: $input) {
      _id
      name
    }
  }
`;

const GET_VALUE_BY_ID = gql`
  query listComplementValueById($input: ComplementValueIdInput!) {
    listComplementValueById(input: $input) {
      _id
      name
      complement {
        _id
      }
    }
  }
`;

const DELETE_VALUE = gql`
  mutation deleteComplementValue($input: ComplementValueIdInput!) {
    deleteComplementValue(input: $input) {
      _id
      name
      complement {
        _id
      }
    }
  }
`;

const EDIT_VALUE = gql`
  mutation editComplementValue($input: ComplementValueEditInput!) {
    editComplementValue(input: $input) {
      _id
      name
      complement {
        _id
      }
    }
  }
`;

export function useGetComplements() {
  const { loading, error, data } = useQuery(GET_COMPLEMENTS, {
    client,
  });

  return {
    loading,
    error,
    data: _.get(data, "complementsWithValues", []),
  };
}

export function useNewComplement(args, onCompleted) {
  return useMutation(CREATE_COMPLEMENT, {
    client,
    variables: { input: { ...args } },
    onCompleted,
  });
}

export function useEditComplement(args, onCompleted) {
  return useMutation(EDIT_COMPLEMENT, {
    client,
    variables: { input: { ...args } },
    onCompleted,
  });
}

export function useNewComplementValue(args, onCompleted) {
  return useMutation(CREATE_COMPLEMENT_VALUE, {
    client,
    variables: { input: { ...args } },
    onCompleted,
  });
}

export function useGetValueById(args) {
  const { loading, error, data } = useQuery(GET_VALUE_BY_ID, {
    client,
    variables: { input: { ...args } },
  });

  return {
    loading,
    error,
    data: _.get(data, "listComplementValueById", []),
  };
}

export function useDeleteComplement(args, onCompleted) {
  return useMutation(DELETE_COMPLEMENT, {
    client,
    variables: { input: { ...args } },
    onCompleted,
  });
}

export function useDeleteValue(args, onCompleted) {
  return useMutation(DELETE_VALUE, {
    client,
    variables: { input: { ...args } },
    refetchQueries: [{ query: GET_COMPLEMENTS }],
    onCompleted,
  });
}

export function useEditValue(args, onCompleted) {
  return useMutation(EDIT_VALUE, {
    client,
    variables: { input: { ...args } },
    refetchQueries: [{ query: GET_COMPLEMENTS }],
    onCompleted,
  });
}

function fetchById(id) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      const complement = _.find(data, { _id: parseInt(id) });
      resolve(complement);
    }, 1000);
  });
}

export { fetchById };
