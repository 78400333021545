import _ from "lodash";
import Immutable from "immutable";
import * as Rx from "rxjs";
import { FIRST_CHANGE_PASSWORD } from "../../components/ModalChangePassword/actions";
// import { axiosAuth } from "../../middleware/api";

// actions
export const LOGIN = "nss/login-box/LOGIN";
export const LOGIN_CLEAN = "nss/login-box/LOGIN_CLEAN";
export const LOGIN_IN_PROGRESS = "nss/login-box/LOGIN_IN_PROGRESS";
export const LOGIN_FULFILLED = "nss/login-box/LOGIN_FULFILLED";
export const LOGIN_REJECTED_AUTH = "nss/login-box/LOGIN_REJECTED_AUTH";
export const LOGIN_REJECTED_SERVER = "nss/login-box/LOGIN_REJECTED_SERVER";
export const LOGIN_CANCELED = "nss/login-box/LOGIN_CANCELED";
export const LOGOUT = "nss/login-box/LOGOUT";
export const CHECK_LOGIN = "nss/login-box/CHECK_LOGIN";
export const PROFILE_DATA = "nss/login-box/PROFILE_DATA";

export function login(username, password) {
    return {
        type: LOGIN,
        payload: {
            username,
            password,
        },
    };
}

export const loginProgress = () => ({ type: LOGIN_IN_PROGRESS });
export const cleanStateUsingLogOut = () => ({ type: LOGOUT });
export const cleanLoginStatus = () => ({ type: LOGIN_CLEAN });
export const loginFulfilled = ({ user, profile }) => ({
    type: LOGIN_FULFILLED,
    payload: { user, profile },
});
export const loginRejected = (err, code) => ({
    type: code === 401 ? LOGIN_REJECTED_AUTH : LOGIN_REJECTED_SERVER,
    payload: { err },
});

// reducer
const initialState = Immutable.Map({
    loggedIn: false,
    shouldRedirect: false,
    err: null,
    token: null,
    permissions: [],
    profile: {},
    status: LOGIN_CLEAN,
    uri: undefined,
});

export default (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_IN_PROGRESS:
            return state.set("status", LOGIN_IN_PROGRESS);
        case LOGIN_CANCELED:
            return state.set("status", LOGIN_CLEAN);
        case LOGIN_FULFILLED:
            return state.withMutations((map) => {
                map.set("status", LOGIN_FULFILLED)
                    .set("loggedIn", true)
                    .set(
                        "token",
                        _.get(
                            action,
                            "payload.user.signInUserSession.accessToken.jwtToken"
                        )
                    )
                    .set("uri", _.get(action, "payload.profile.redirectUri"))
                    .set("profile", _.get(action, "payload.profile.profile"))
                    .set(
                        "permissions",
                        _.get(action, "payload.profile.permissions")
                    );
            });
        case LOGIN_CLEAN:
            return state.set("status", LOGIN_CLEAN);
        case LOGIN_REJECTED_AUTH:
        case LOGIN_REJECTED_SERVER:
            return state.withMutations((map) => {
                map.set("status", action.type).set("err", action.payload.error);
            });
        case PROFILE_DATA:
            return state.withMutations((map) => {
                map.set("profile", _.get(action, "payload.data.profile")).set(
                    "permissions",
                    _.get(action, "payload.data.permissions")
                );
            });
        case LOGOUT:
            return initialState;
        case FIRST_CHANGE_PASSWORD: {
            const profile = state.get("profile");
            const firstPassword = !profile.firstPassword;
            return state.withMutations((map) => {
                map.set(
                    "profile",
                    _.set(profile, "firstPassword", firstPassword)
                );
            });
        }
        default:
            return state;
    }
};

// Epics
export const loginEpic$ = (action$) =>
    action$.ofType(LOGIN).mergeMap(() => {
        return Rx.Observable.empty();
    });
