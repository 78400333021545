import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import {Toolbar, Button, Grid} from '@material-ui/core'
import SearchFilter from './SearchFilter'

const useStyles = makeStyles(theme => ({
    root: {
        margin: "8px"
    },
    gridSearch: {
        display: "flex"
    }
}))

const TableToolbar = props => {
    const {
        numSelected,
        deleteUserHandler,
        search,
        setSearch
    } = props
    const classes = useStyles();

    return (
        <Toolbar>
            <Grid
                justifyContent="space-between"
                container
                className={classes.root}
            >
                <Grid className={classes.gridSearch}>
                    <SearchFilter
                        search={search}
                        setSearch={setSearch}
                    />
                </Grid>
                <Grid item>
                    <Button variant="contained" color="primary" aria-label="delete" onClick={deleteUserHandler} disabled={numSelected === 0}>
                        Eliminar
                    </Button>
                </Grid>
            </Grid>
        </Toolbar>
    )
}

TableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    deleteUserHandler: PropTypes.func.isRequired,
    search: PropTypes.string.isRequired,
    setSearch: PropTypes.func.isRequired
}

export default TableToolbar
