import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

type Props = {
  tooltip: string;
  onClick?: () => void;
  disabled?: boolean;
  icon: React.ReactNode;
};
const TooltipIconButton = (props: Props) => {
  const { tooltip, onClick, disabled, icon } = props;
  return (
    <Tooltip title={tooltip}>
      <span>
        <IconButton onClick={onClick} disabled={disabled}>
          {icon}
        </IconButton>
      </span>
    </Tooltip>
  );
};

export { TooltipIconButton };
