import _ from "lodash";
import { GET_COMPANIES_GRID, GET_COMPANIES_NAMES } from "../actions/grid/companies_grid_form";
import {
    POST_COMPANY_INFO,
    GET_PROJECTS_COMPANIES_IN_SEARCH,
    CLEAN_COMPANY_INFO,
} from "../actions/info/companies_info_form";
import { DELETE_COMPANY, DELETE_PROJECT, CLEAN_PROJECT } from "../constants/index";

const INITIAL_STATE = {
    all: [],
    companies: null,
    company: {},
    numFound: 0,
    inSearchProjectsCompanies: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_COMPANIES_GRID:
            return _.assign({}, state, {
                all: _.get(action, "payload.data.data", []),
                numFound: _.get(action, "payload.data.numFound", 0),
            });
        case GET_COMPANIES_NAMES:
            return _.assign({}, state, {
                all: _.get(action, "payload.data.data", []),
                inSearchProjectsCompanies: false,
            });
        case POST_COMPANY_INFO:
            return _.assign({}, state, {
                company: _.get(action, "payload.data"),
                inSearchProjectsCompanies: false,
            });
        case CLEAN_COMPANY_INFO:
            return _.assign({}, state, {
                company: {},
            });
        case GET_PROJECTS_COMPANIES_IN_SEARCH:
            return _.assign({}, state, {
                inSearchProjectsCompanies: true,
            });
        case DELETE_COMPANY:
            var countDeleted = 0;
            var newAll = _.filter(state.all, item => {
                if (_.isEqual(item._id, action.meta.companyId)) {
                    countDeleted++;
                }
                return !_.isEqual(item._id, action.meta.companyId);
            });
            return _.assign({}, state, {
                all: newAll,
                numFound: state.numFound - countDeleted,
            });
        case DELETE_PROJECT:
            var newProjectsAll = _.filter(
                state.company.data.projects,
                item => !_.isEqual(item._id, action.meta.projectId),
            );
            var dataCompany = _.omit(state.company.data, ["projects"]);
            dataCompany = _.set(dataCompany, "projects", newProjectsAll);
            return _.assign({}, state, {
                company: {
                    data: dataCompany,
                },
            });
        case CLEAN_PROJECT:
            return _.assign({}, state, {
                company: {},
            });
        default:
            return state;
    }
};
