import Immutable from 'immutable';
import _ from 'lodash';
import {
    GET_VALUES_ACTIVITY_ECONOMIC_FILTER_COMPANY,
    CHANGE_VALUE_ACTIVITY_ECONOMIC_FILTER_COMPANY,
    GET_VALUES_EMPLOYEES_NUMBER_FILTER_COMPANY,
    CHANGE_VALUE_EMPLOYEES_NUMBER_FILTER_COMPANY,
    GET_VALUES_COUNTRIES_FILTER_COMPANY,
    CHANGE_VALUE_COUNTRIES_FILTER_COMPANY,
    GET_VALUES_DEPARTMENTS_FILTER_COMPANY,
    CHANGE_VALUE_DEPARTMENTS_FILTER_COMPANY,
    GET_VALUES_CITIES_FILTER_COMPANY,
    CHANGE_VALUE_CITIES_FILTER_COMPANY,
    CLEAN_VALUES_FILTER_COMPANY
} from './constants';

const initialState = Immutable.Map({
    economicsActivities: Immutable.List(),
    economicActivity: 'all',
    employeesNumber: Immutable.List(),
    employeesNum: 'all',
    countries: Immutable.List(),
    country: 'all',
    departments: Immutable.List(),
    department: 'all',
    cities: Immutable.List(),
    city: 'all'
});

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_VALUES_ACTIVITY_ECONOMIC_FILTER_COMPANY:
            return state.set("economicsActivities", _.get(action, 'payload.data', []));
        case CHANGE_VALUE_ACTIVITY_ECONOMIC_FILTER_COMPANY:
            return state.set("economicActivity", _.get(action, 'meta.economicActivity', 'all'));
        case GET_VALUES_EMPLOYEES_NUMBER_FILTER_COMPANY:
            return state.set("employeesNumber", _.get(action, 'payload.data', []));
        case CHANGE_VALUE_EMPLOYEES_NUMBER_FILTER_COMPANY:
            return state.set("employeesNum", _.get(action, 'meta.employeesNum', 'all'));
        case GET_VALUES_COUNTRIES_FILTER_COMPANY:
            return state.set("countries", _.get(action, 'payload.data', []));
        case CHANGE_VALUE_COUNTRIES_FILTER_COMPANY:
            return state.withMutations(function(state) {
                        state.set("country", _.get(action, 'meta.country', 'all'));
                        state.set("department", 'all');
                        state.set("city", 'all');
                   });
        case GET_VALUES_DEPARTMENTS_FILTER_COMPANY:
            return state.set("departments", _.get(action, 'payload.data', []));
        case CHANGE_VALUE_DEPARTMENTS_FILTER_COMPANY:
            return state.withMutations(function(state) {
                        state.set("department", _.get(action, 'meta.department', 'all'));
                        state.set("city", 'all');
                   });
        case GET_VALUES_CITIES_FILTER_COMPANY:
            return state.set("cities", _.get(action, 'payload.data', []));
        case CHANGE_VALUE_CITIES_FILTER_COMPANY:
            return state.set("city", _.get(action, 'meta.city', 'all'));
        case CLEAN_VALUES_FILTER_COMPANY:
            return state.withMutations(function(state) {
                        state.set("economicsActivities", Immutable.List());
                        state.set("economicActivity", 'all');
                        state.set("employeesNumber", Immutable.List());
                        state.set("employeesNum", 'all');
                        state.set("countries", Immutable.List());
                        state.set("country", 'all');
                        state.set("departments", Immutable.List());
                        state.set("department", 'all');
                        state.set("cities", Immutable.List());
                        state.set("city", 'all');
                   });
        default:
            return state;
    }
}
