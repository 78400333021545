import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui/Table';
import IconButton from 'material-ui/IconButton';
import VisibilityIcon from 'material-ui/svg-icons/action/visibility';
import { Link } from 'react-router-dom';
import { FloatingActionButton } from 'material-ui';
import ContentAdd from 'material-ui/svg-icons/content/add';
import PropTypes from 'prop-types';
import DialogAddValue from './dialogAddValue';

const styles = {
  colFields: {
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    textAlign: 'justify',
  },
  contentActions: {
    position: 'absolute',
    marginTop: -24,
    zIndex: 99,
  },
  table: {
    padding: 24,
  },
  contentAddButton: {
    textAlign: 'end',
  },
  addButton: {
    margin: 12,
  },
};

/**
 * Componente para el listado de valores del complemento
 * @author Jhoan Lopez <jhoanlt19@gmail.com>
 * @param {*} param0
 */
const ListComplementValue = ({ selectedComplement }) => {
  const [stateModal, setStateModal] = useState(false);

  const handleChangeStateModal = () => {
    setStateModal(currentState => !currentState);
  };

  return (
    <div data-testid="content-table">
      <div style={styles.contentAddButton}>
        <FloatingActionButton
          data-testid="add-value-button"
          style={styles.addButton}
          onClick={handleChangeStateModal}
          disabled={Object.entries(selectedComplement).length === 0}
          mini
        >
          <ContentAdd />
        </FloatingActionButton>
        <DialogAddValue
          title={`Agregar valor para el complemento (${selectedComplement.name})`}
          label="Nombre"
          open={stateModal}
          handleClose={handleChangeStateModal}
          selectedComplement={selectedComplement}
        />
      </div>
      {Object.entries(selectedComplement).length > 0 &&
      selectedComplement.values.length > 0 ? (
        <Table selectable={false}>
          <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
            <TableRow>
              <TableHeaderColumn>Nombre</TableHeaderColumn>
              <TableHeaderColumn>Opciones</TableHeaderColumn>
            </TableRow>
          </TableHeader>
          <TableBody displayRowCheckbox={false}>
            {selectedComplement.values.map(item => (
              <TableRow key={`${item._id}-value`} data-testid="list-value">
                <TableRowColumn style={styles.colFields}>
                  {item.name}
                </TableRowColumn>
                <TableRowColumn style={styles.colFields}>
                  <div style={styles.contentActions}>
                    <IconButton
                      data-testid="view-button"
                      tooltipPosition="top-center"
                      children={<VisibilityIcon />}
                      containerElement={
                        <Link to={`/admin/complements/value/${item._id}`}>
                          Ver
                        </Link>
                      }
                    />
                  </div>
                </TableRowColumn>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <p>
          {Object.entries(selectedComplement).length > 0 &&
          selectedComplement.values.length === 0
            ? 'El complemento seleccionado no tiene valores agregados'
            : 'Sin información para mostrar'}
        </p>
      )}
    </div>
  );
};

ListComplementValue.defaultProps = {
  selectedComplement: {},
};

ListComplementValue.propTypes = {
  selectedComplement: PropTypes.shape({}),
};

export default ListComplementValue;
