import PropTypes from "prop-types";
import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Row, Col } from "react-flexbox-grid";
import Paper from "material-ui/Paper";
import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";
import _ from "lodash";
import { createFaq } from "../actions/faq_form";
import { FetchFaqParam } from "../actions/info/faq_info_form";
import { toggleSnackbar } from "../actions/commons";
import ButtonBack from "../components/fields/buttonBack/buttonBack";
import { LBLFAQS } from "../constants/labels";
import AuthComponent from "../components/AuthComponent";

const style = {
  margin: 20,
  float: "right",
};
const stylePaper = {
  marginTop: 20,
  marginBottom: 20,
};

class FaqNews extends Component {
  constructor(props) {
    super(props);
    this._saveFaq = this._saveFaq.bind(this);
  }

  _saveFaq(formData) {
    const { createFaq, toggleSnackbar, resetForm, params } = this.props;
    const editMode = _.has(params, "_id");
    createFaq(editMode ? formData : _.omit(formData, "_id"), editMode)
      .then(
        data => {
          if (_.get(data, "error") || _.get(data, "payload.status") !== 200) {
            toggleSnackbar(true, LBLFAQS.msg.errorSave);
          } else {
            resetForm();
            toggleSnackbar(true, LBLFAQS.msg.successSave);
            this.props.history.push(`/admin/faq/grid`);
          }
        },
        reason => {
          toggleSnackbar(true, LBLFAQS.msg.errorSave);
        }
      )
      .catch(data => {
        toggleSnackbar(true, LBLFAQS.msg.errorSave);
      });
  }

  UNSAFE_componentWillMount() {
    if (_.has(this, "props.params._id")) {
      this.props.FetchFaqParam(this.props.params._id);
    }
  }

  render() {
    const {
      fields: { name, description, _id },
      handleSubmit,
    } = this.props;
    const editMode = _.has(this, "props.params._id");
    return (
      <Row>
        <AuthComponent
          component={
            <Col xsOffset={3} xs={6}>
              <Paper style={stylePaper}>
                <Row>
                  <Col xsOffset={1} xs={10}>
                    <h1 style={{ textAlign: "center", fontWeight: 400 }}>
                      {editMode ? LBLFAQS.titleEdit : LBLFAQS.titleCreate}
                    </h1>
                    <ButtonBack url="/admin/faq/grid" />
                    <form onSubmit={handleSubmit(this._saveFaq)}>
                      <Row>
                        <Col xs>
                          <TextField
                            floatingLabelText={LBLFAQS.questionFAQ}
                            type="text"
                            fullWidth
                            errorText={
                              name.touched && name.error ? name.error : ""
                            }
                            {...name}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs>
                          <TextField
                            hintText=""
                            floatingLabelText={LBLFAQS.answer}
                            multiLine
                            rows={4}
                            fullWidth
                            errorText={
                              description.touched && description.error
                                ? description.error
                                : ""
                            }
                            {...description}
                          />
                        </Col>
                      </Row>
                      <AuthComponent
                        component={
                          <RaisedButton
                            type="submit"
                            label={
                              editMode
                                ? LBLFAQS.buttons.update
                                : LBLFAQS.buttons.save
                            }
                            secondary
                            style={style}
                          />
                        }
                        permission="backend/faqs:write"
                        type="component"
                      />
                    </form>
                  </Col>
                </Row>
              </Paper>
            </Col>
          }
          permission="backend/faqs:write"
          type="url"
        />
      </Row>
    );
  }
}

const validate = values => {
  const errors = {};
  if (!values.name) {
    errors.name = LBLFAQS.validations.questionFAQ;
  }
  if (!values.description) {
    errors.description = LBLFAQS.validations.answer;
  }
  return errors;
};

function mapStateToProps({ faq }, ownProps) {
  if (_.has(ownProps, "params._id")) {
    return {
      initialValues: faq.get("faq"),
      _id: ownProps.params._id,
    };
  }
  return {
    initialValues: {
      name: "",
      description: "",
      _id: "",
    },
  };
}

FaqNews.propTypes = {
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  router: PropTypes.object,
};

export default reduxForm(
  {
    form: "FaqNewsForm",
    fields: ["name", "description", "_id"],
    validate,
  },
  mapStateToProps,
  { createFaq, FetchFaqParam, toggleSnackbar }
)(FaqNews);
