import PropTypes from "prop-types";
import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import { Row, Col } from "react-flexbox-grid";
import Paper from "material-ui/Paper";
import RaisedButton from "material-ui/RaisedButton";
import { blue700 } from "material-ui/styles/colors";
import TextField from "material-ui/TextField";
import _ from "lodash";
import CircularProgress from "material-ui/CircularProgress";
import { createProfile, ClearState } from "../actions/profile_form";
import { FetchProfileParam } from "../actions/info/profile_info_form";
import { FetchResource } from "../actions/grid/profile_grid_form";
import { axiosApiV2 } from "../middleware/api";
import { toggleSnackbar } from "../actions/commons";
import ButtonBack from "../components/fields/buttonBack/buttonBack";
import { LBLPROFILE } from "../constants/labels";
import ContainerResources from "./profile/containerResources";
import AuthComponent from "../components/AuthComponent";

class ProfilesNews extends Component {
  constructor(props) {
    super(props);

    this._saveProfile = this._saveProfile.bind(this);
  }

  UNSAFE_componentWillMount() {
    const {
      FetchResource,
      FetchProfileParam,
      ClearState,
      params,
      resetForm,
    } = this.props;
    FetchResource();
    ClearState();
    resetForm();
    if (_.has(params, "profile")) {
      FetchProfileParam(params.profile);
    }
  }

  _saveProfile(formData) {
    const {
      createProfile,
      toggleSnackbar,
      resetForm,
      permissions,
      params,
    } = this.props;
    const editMode = _.has(params, "profile");
    createProfile(
      editMode ? formData : _.omit(formData, "_id"),
      permissions,
      editMode
    )
      .then(
        (data) => {
          if (_.get(data, "error") || _.get(data, "payload.status") !== 200) {
            toggleSnackbar(true, LBLPROFILE.msg.errorSave);
          } else {
            resetForm();
            toggleSnackbar(true, LBLPROFILE.msg.successSave);
            this.props.history.push(`/admin/profiles/grid`);
          }
        },
        (reason) => {
          toggleSnackbar(true, LBLPROFILE.msg.errorSave);
        }
      )
      .catch((data) => {
        toggleSnackbar(true, LBLPROFILE.msg.errorSave);
      });
  }

  render() {
    const {
      asyncValidating,
      fields: { name, description },
      handleSubmit,
      params,
      resources,
      permissions,
    } = this.props;
    const editMode = _.has(params, "profile");
    if (!_.isNull(resources)) {
      return (
        <Row>
          <AuthComponent
            component={
              <Col xs={12} mdOffset={2} md={8}>
                <Paper style={{ marginTop: 20, marginBottom: 20 }}>
                  <Row>
                    <Col xs={10} xsOffset={1}>
                      <ButtonBack url="/admin/profiles/grid" />
                      <h1 style={{ textAlign: "center", fontWeight: 400 }}>
                        {editMode
                          ? LBLPROFILE.titleEdit
                          : LBLPROFILE.titleCreate}
                      </h1>
                      <form onSubmit={handleSubmit(this._saveProfile)}>
                        <Row>
                          <Col xs>
                            <TextField
                              hintText=""
                              floatingLabelText={LBLPROFILE.name}
                              disabled={editMode}
                              errorText={
                                name.touched && name.error ? name.error : ""
                              }
                              type="text"
                              {...name}
                            />
                            {asyncValidating === "name" && (
                              <CircularProgress
                                size={25}
                                thickness={2}
                                style={{
                                  position: "absolute",
                                  float: "right",
                                  marginTop: 35,
                                }}
                              />
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs>
                            <TextField
                              hintText=""
                              multiLine
                              fullWidth
                              rows={1}
                              floatingLabelText={LBLPROFILE.description}
                              errorText={
                                description.touched && description.error
                                  ? description.error
                                  : ""
                              }
                              type="text"
                              {...description}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs>
                            <h2
                              style={{
                                textAlign: "center",
                                fontWeight: 400,
                                marginTop: 25,
                                marginBottom: 25,
                              }}
                            >
                              {LBLPROFILE.titlePermissions}
                            </h2>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs>
                            <ContainerResources resources={resources} />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs>
                            <h3
                              style={{
                                textAlign: "center",
                                marginTop: 20,
                                marginBottom: 20,
                                color: blue700,
                                fontWeight: 400,
                              }}
                            >
                              {_.size(permissions)} permisos seleccionados
                            </h3>
                          </Col>
                        </Row>
                        <AuthComponent
                          component={
                            <RaisedButton
                              type="submit"
                              label={
                                editMode
                                  ? LBLPROFILE.buttons.update
                                  : LBLPROFILE.buttons.save
                              }
                              secondary
                              style={{ margin: 20, float: "right" }}
                            />
                          }
                          permission="backend/profiles:write"
                          type="component"
                        />
                      </form>
                    </Col>
                  </Row>
                </Paper>
              </Col>
            }
            permission="backend/profiles:write"
            type="url"
          />
        </Row>
      );
    }
    return <div />;
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = LBLPROFILE.validations.name;
  }
  if (!values.description) {
    errors.description = LBLPROFILE.validations.description;
  }
  return errors;
};

const asyncValidate = (values /* , dispatch */) => {
  return new Promise((resolve, reject) => {
    axiosApiV2
      .get(`/profiles/name`, {
        params: { name: values.name },
      })
      .then((data) => {
        let resp = _.get(data, "data");
        if (_.isEqual(resp, "")) {
          resp = null;
        }
        if (!_.isNull(resp)) {
          if (_.isNull(values._id)) {
            reject({ name: LBLPROFILE.msg.errorExistName });
          } else {
            resolve();
          }
        } else {
          resolve();
        }
      })
      .catch((data) => {
        console.log("Error", data);
      });
  });
};

function mapStateToProps({ profileForm }, ownProps) {
  if (_.has(ownProps, "params.profile")) {
    return {
      resources: profileForm.get("resources"),
      permissions: profileForm.get("permissions").toArray(),
      initialValues: profileForm.get("profile"),
    };
  }
  return {
    resources: profileForm.get("resources"),
    permissions: profileForm.get("permissions").toArray(),
    initialValues: {
      name: "",
      description: "",
      _id: null,
    },
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createProfile,
      FetchResource,
      FetchProfileParam,
      ClearState,
      toggleSnackbar,
    },
    dispatch
  );
}

export default reduxForm(
  {
    form: "ProfilesNewsForm",
    fields: ["name", "description", "_id"],
    asyncValidate,
    asyncBlurFields: ["name"],
    validate,
  },
  mapStateToProps,
  mapDispatchToProps
)(ProfilesNews);
