import { axiosAPI } from '../../middleware/api';

export const GET_FAQ_INFO = "GET_FAQ_INFO";

export function FetchFaqParam(id) {
    const request = axiosAPI.get(`/faqs/${id}`);

    return {
        type: GET_FAQ_INFO,
        payload: request
    }
}
