import React, {Component} from 'react';
import {Row, Col} from 'react-flexbox-grid';
import _ from 'lodash';
import {blue700} from 'material-ui/styles/colors';
import Divider from 'material-ui/Divider';
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn} from 'material-ui/Table';
import PieTableFrecuency from './PieTableFrecuency';

class TableFreqResult extends Component {

    constructor(props) {
        super(props);
    }

    renderConditions(conditionsArray, showAll){
        return _.orderBy(conditionsArray, ['category'], 'asc').map((condition, idx) => {
            const result = getResult(condition, showAll);
            return(
                <TableRow key={`freq_result_${_.get(condition, "_id")}_${idx}`} selectable={false}>
                    <TableRowColumn style={{width:5}}>{`#${(idx + 1)}`}</TableRowColumn>
                    <TableRowColumn style={{width:5}}>
                        <div style={{backgroundColor: _.get(condition, "color"), borderRadius:'100%', height: '20px', width: '20px'}}/>
                    </TableRowColumn>
                    <TableRowColumn>{_.get(condition, "category")}</TableRowColumn>
                    <TableRowColumn>{result}</TableRowColumn>
                </TableRow>
            );
        });
    }

    renderTableFrenquency(dataFrenquencies, showAll){
        if(!_.isEmpty(dataFrenquencies)){
            return _.map(dataFrenquencies, (dataFrenquency, idx) => {
                if(_.get(dataFrenquency, "visible") || showAll){

                    const values = [];

                    _.orderBy(dataFrenquency.conditions, ['category'], 'asc').map((item) => {
                        _.orderBy(dataFrenquency.result, ['key'], 'asc').map((itemResult) => {
                            if(_.isEqual(item.category, itemResult.key)){
                                values.push({ x:item.category, y: itemResult.value, fill: item.color, color:item.color});
                            }
                        });
                    });

                    const tickValues = _.map(values, item => item.x);
                    const count = _.size(values);
                    const sum = _.chain(values).sumBy('y').value();
                    const valuesToBarChart = _.chain(values).map(item => {
                    const value = _.chain(item).get('y').divide(sum).multiply(100).value();
                        return _.assign({},item,{
                            y:  value,
                            label: `${Math.trunc(value)}%`
                        });
                    }).value();
                    const maxValue = _.chain(valuesToBarChart).maxBy('y').get('y').value();
                    const frecuencyPie = _.map(_.orderBy(_.get(dataFrenquency, "conditions", []), ['category'], 'asc'), (condition, index) => {
                        const result = getResult(condition, showAll);
                        return {
                            value: getValueCategory(_.get(dataFrenquency, 'result', []), _.get(condition, 'category')),
                            color: _.get(condition, "color", "#000"),
                            index: `#${(index+1)}`
                        };
                    });

                    return (
                        <Col xs={12} key={`table_freq_result_${idx}`}>
                            <div style={{width: '100%', background: blue700, paddingTop: 5, paddingBottom:5, color: "#FFF"}}>
                                <h1 style={{textAlign: 'center', fontWeight: 400}}>
                                    {_.get(dataFrenquency, "name")}
                                </h1>
                            </div>
                            <Row>
                                <Col xs={12} sm={6} md={6} lg={6} style={{padding:15}}>
                                    <div  style={{padding:15, width: 175, height: 175, margin: '0 auto'}}>
                                        <PieTableFrecuency data={frecuencyPie} />
                                    </div>
                                </Col>
                                <Col xs={12} sm={6} md={6} lg={6} style={{padding:15}}>
                                    <Table selectable={false}  fixedHeader={false}>
                                        <TableHeader displaySelectAll={false} enableSelectAll={false} adjustForCheckbox={false}>
                                            <TableRow>
                                                <TableHeaderColumn style={{width:5}} />
                                                <TableHeaderColumn style={{width:5}} />
                                                <TableHeaderColumn>Categoría</TableHeaderColumn>
                                                <TableHeaderColumn>Resultado</TableHeaderColumn>
                                            </TableRow>
                                        </TableHeader>
                                        <TableBody displayRowCheckbox={false}>
                                            {this.renderConditions(_.get(dataFrenquency, "conditions"), showAll)}
                                        </TableBody>
                                    </Table>
                                </Col>
                            </Row>
                            <br/>
                        </Col>
                    );
                }
            });
       } 
         return ( <div /> );
       
    }

    render() {
        const {data, showAll} = this.props;
        return (
            <Row>
                {this.renderTableFrenquency(data, showAll)}
            </Row>
        );
    }
}

function getResult(condition, showAll){
    return showAll ? _.get(condition, "reportDescription") : _.get(condition, "descriptionUser");
}

function getValueCategory(results, category){
    if(!_.isUndefined(category) && !_.isNull(category)){
        const item = _.findIndex(results, { "key": category });
        if(!_.isEqual(item, -1)){
            return _.get(results, `${item}.value`, 0);
        }
    }
    return 0;
}

export default TableFreqResult;
