import Immutable from 'immutable';
import _ from 'lodash';
import {
    SET_INITIAL_LOCATION_QUESTION,
    SAVE_VALUE_COUNTRY_QUESTION_LOCATION,
    SAVE_VALUE_DEPARTMENT_QUESTION_LOCATION,
    DELETE_VALUE_DEPARTMENT_QUESTION_LOCATION,
    GET_COUNTRIES_QUESTION_SURVEY,
    GET_DEPARTMENTS_QUESTION_SURVEY,
    GET_CITIES_QUESTION_SURVEY
} from './constants';

const initialState = Immutable.Map({
    locations: {}
});

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_COUNTRIES_QUESTION_SURVEY:
            var locationCountries = state.get('locations');
            return state.set('locations', _.set(locationCountries, `${_.get(action, 'meta.id')}.countries`, _.get(action, 'payload.data', [])));
        case SAVE_VALUE_COUNTRY_QUESTION_LOCATION:
            var countrySave = state.get('locations');
            return state.set('locations', _.set(countrySave, `${_.get(action, 'meta.id')}.country`, _.get(action, 'meta.idCountry')));
        case SAVE_VALUE_DEPARTMENT_QUESTION_LOCATION:
            var departmentSave = state.get('locations');
            return state.set('locations', _.set(departmentSave, `${_.get(action, 'meta.id')}.department`, _.get(action, 'meta.idDepartment')));
        case DELETE_VALUE_DEPARTMENT_QUESTION_LOCATION:
            var departmentDelete = state.get('locations');
            var valuesQuestion = _.get(departmentDelete, _.get(action, 'meta.id'), null);
            if(_.isNull(valuesQuestion)){
                return state;
            } 
                return state.set('locations', _.set(departmentDelete, _.get(action, 'meta.id'), _.omit(valuesQuestion, ['department'])));
            
        case SET_INITIAL_LOCATION_QUESTION:
            return state.set("locations", {});
        default:
            return state;
    }
}
