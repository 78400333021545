import React, { useState } from 'react';
import SendCodeForm from './sendCodeForm';
import ChangePasswordForm from './changePasswordForm';

const ForgotPassword = () => {
  const [identStatus, setIdentStatus] = useState(false);
  const [username, setUsername] = useState('');

  const handleIdentMade = username => {
    setUsername(username);
    setIdentStatus(true);
  };

  return (
    <div>
      {!identStatus ? (
        <SendCodeForm handleIdentMade={handleIdentMade} />
      ) : (
        <ChangePasswordForm username={username} />
      )}
    </div>
  );
};

export default ForgotPassword;
