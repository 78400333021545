import { axiosAPI } from '../../middleware/api';

export const ADD_VALUE_STORE = "ADD_VALUE_STORE";
export const ADD_LIST_VALUES_STORE = "ADD_LIST_VALUES_STORE";
export const REMOVE_VALUE_STORE = "REMOVE_VALUE_STORE";
export const CHANGE_POSITION_VALUE_STORE = "CHANGE_POSITION_VALUE_STORE";
export const REMOVE_LIST_VALUES_STORE = 'REMOVE_LIST_VALUES_STORE';

export const addValueStore = (value, store) => {
    return {
        type: ADD_VALUE_STORE,
        value,
        store
    };
};

export const removeValueStore = (index, store) => {
    return {
        type: REMOVE_VALUE_STORE,
        index,
        store
    };
};

export const addListValuesStore = (_id, store) => {
    switch (store) {
      case 'questions':
          var requestSection = axiosAPI.get(`/sections/questions/${_id}`);
          return {
              type: ADD_LIST_VALUES_STORE,
              payload: requestSection,
              meta: {
                  store
              }
          };
      case 'referees':
          var requestReferee = axiosAPI.get(`/projects/${_id}/referees`);
          return {
              type: ADD_LIST_VALUES_STORE,
              payload: requestReferee,
              meta: {
                  store
              }
          };
      default:
          return {
              type: ADD_LIST_VALUES_STORE,
              payload: { data: { data: [] } },
              meta: {
                  store
              }
          };
    }
};

export const removeListValuesStore = (store) => {
    return {
        type: REMOVE_LIST_VALUES_STORE,
        meta: {
            store
        }
    };
};

export const changePositionValueStore = (index, store, direction) => {
    return {
        type: CHANGE_POSITION_VALUE_STORE,
        direction,
        index,
        store
    };
};
