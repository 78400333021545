import Immutable from 'immutable';
import _ from 'lodash';
import {
    LOAD_ARL_INFO,
    LOAD_COMPANIES_ARL_INFO,
    CLEAN_INFO,
    GET_PROJECTS_COMPANIES_ARL,
    GET_STATUS_PROJECTS_COMPANIES_ARL,
    ADD_ID_PROJECTS_ARL,
    REMOVE_ID_PROJECTS_ARL,
    CLEAN_ID_PROJECTS_ARL,
    IN_SEARCH_PROJECTS_ARL,
    IN_SEARCH_STATUS_PROJECTS_ARL,
    RESULTS_PROJECTS_ARL
} from './constants';

const initialState = Immutable.Map({
    arl: {},
    companies: null,
    arlId: null,
    projects: [],
    statusProjects: [],
    idProjects: [],
    totalProjects: 0,
    inSearchProject: false,
    inSearchStatusProject: false,
    projectsResults: [],
    nameSurvey: "",
});

export default (state = initialState, action) => {
    switch (action.type) {
        case LOAD_ARL_INFO:
            var arlInfo = _.get(action, 'payload.data', {});
            return state.set('arl', arlInfo);
        case LOAD_COMPANIES_ARL_INFO:
            var companiesInfo = _.get(action, 'payload.data', {});
            return state.withMutations(function(state) {
                        state.set('companies', companiesInfo);
                        state.set('arlId', _.get(action, 'meta.arlId', null));
                    });
        case GET_PROJECTS_COMPANIES_ARL:
            return state.withMutations(function(state) {
                        state.set('totalProjects', _.get(action, 'payload.data.count', 0));
                        state.set('projects', _.get(action, 'payload.data.projects', []));
                        state.set('inSearchProject', false);
                    });
        case GET_STATUS_PROJECTS_COMPANIES_ARL:
            return state.withMutations(function(state) {
                        state.set('statusProjects', _.get(action, 'payload.data', []));
                        state.set('inSearchStatusProject', false);
                    });
        case ADD_ID_PROJECTS_ARL:
            var addIdProjects = _.concat(state.get('idProjects'), _.get(action, 'meta.id'));
            return state.set('idProjects', addIdProjects);
        case REMOVE_ID_PROJECTS_ARL:
            var removeIdProjects = state.get('idProjects').filter( item => {
                                          return !_.isEqual(item, _.get(action, 'meta.id'));
                                      });
            return state.set('idProjects', removeIdProjects);
        case CLEAN_ID_PROJECTS_ARL:
            return state.set('idProjects', []);
        case CLEAN_INFO:
            return state.withMutations(function(state) {
                        state.set('companies', null);
                        state.set('projects', []);
                        state.set('statusProjects', []);
                        state.set('totalProjects', 0);
                        state.set('inSearchStatusProject', false);
                        state.set('inSearchProject', false);
                        state.set('projectsResults', []);
                        state.set('nameSurvey', "");
                    });
        case IN_SEARCH_PROJECTS_ARL:
            return state.set('inSearchProject', true);
        case IN_SEARCH_STATUS_PROJECTS_ARL:
            return state.set('inSearchStatusProject', true);
        case RESULTS_PROJECTS_ARL:
            return state.withMutations(function(state) {
                        state.set('projectsResults', _.get(action, 'meta.projects', []));
                        state.set('nameSurvey', _.get(action, 'meta.nameSurvey', ""));
                    });
        default:
            return state;
    }
}
