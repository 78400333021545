import React, { Component } from "react";
import { Row, Col } from "react-flexbox-grid";
import Paper from "material-ui/Paper";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { blue700 } from "material-ui/styles/colors";
import _ from "lodash";
import { LBLTEMPLATES } from "../../constants/labels";
import FloatingActionButtonTooltip from "../fields/FloatingActionButtonTooltip/index.tsx";
import LoadingPage from "../commons/loadingPage";
import AuthComponent from "../AuthComponent";
import TablePagination from "../TablePagination/tablePagination";

const stylePaper = {
  marginTop: 20,
  marginBottom: 20,
};

class TemplateEmailGrid extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const body = [
      {
        field: "name",
        title: "Nombre",
        colspan: 3,
        filter: true,
      },
      {
        actions: true,
        title: "Operaciones",
        colspan: 1,
      },
    ];
    return (
      <Row>
        <AuthComponent
          component={
            <Col mdOffset={2} md={8} xs={12}>
              <Paper style={stylePaper}>
                <Row>
                  <Col xs>
                    <div
                      style={{
                        width: "100%",
                        background: blue700,
                        paddingTop: 5,
                        paddingBottom: 5,
                        color: "#FFF",
                      }}
                    >
                      <h1 style={{ textAlign: "center", fontWeight: 400 }}>
                        {LBLTEMPLATES.titleGrid}
                      </h1>
                      <AuthComponent
                        component={
                            <FloatingActionButtonTooltip
                              textTooltip={LBLTEMPLATES.tooltips.btnCreate}
                              to="/admin/templateemail/create"
                              component={Link}
                            />
                        }
                        permission="backend/templateEmail:write"
                        type="component"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs>
                    <TablePagination
                      name="templateEmail"
                      URL="/templateEmail"
                      version={1}
                      body={body}
                    />
                  </Col>
                </Row>
              </Paper>
            </Col>
          }
          permission="backend/templateEmail:read"
          type="url"
        />
      </Row>
    );
  }
}

function mapStateToProps({ templateemails }) {
  return { templates: templateemails.get("all").toArray() };
}

export default connect(mapStateToProps)(TemplateEmailGrid);
