import React from "react";
import { useField } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const SelectField = ({
  disabled = false,
  helperText = "",
  label,
  id,
  items = [],
  variant = "standard",
  ...props
}) => {
  const [field, meta] = useField(props);

  const classes = useStyles();
  const labelId = `${id}-select-label`;
  const options = items.map((item) => {
    return (
      <MenuItem key={item.id} value={item.id}>
        {item.label}
      </MenuItem>
    );
  });
  const hasError = meta.touched && meta.error;
  const errorMessage = meta.error;
  const helperTextContent = hasError ? errorMessage : helperText;
  return (
    <FormControl
      className={classes.formControl}
      disabled={disabled}
      error={hasError}
      autoWidth
      fullWidth
      variant={variant}
    >
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select {...field} label={label} labelId={labelId} id={`${id}-select`}>
        {options}
      </Select>
      <FormHelperText>{helperTextContent}</FormHelperText>
    </FormControl>
  );
};

export default SelectField;
