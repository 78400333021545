import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toggleSnackbar } from "../../actions/commons";

type Props = {
  snackbar: {
    status: boolean;
    message: string;
  };
  toggleSnackbar: (input: boolean) => void;
};
const SnackbarMessage: React.FC<Props> = ({ snackbar, toggleSnackbar }) => {
  const { status, message } = snackbar;
  const handleClose = () => {
    toggleSnackbar(false);
  };
  return (
    <Snackbar
      open={status}
      message={message}
      autoHideDuration={6000}
      onClose={handleClose}
    />
  );
};

function mapStateToProps({ snackbar }) {
  return { snackbar };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      toggleSnackbar,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SnackbarMessage as any);
