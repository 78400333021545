import React, { useState } from "react";
import { Dialog, TextField } from "material-ui";
import FlatButton from "material-ui/FlatButton";
import PropTypes from "prop-types";
import { useComplementContext } from "../context/complementProvider";
import ButtonSaveComplement from "./buttonSaveComplement";

const styles = {
  contentTextField: {
    textAlign: "center",
  },
};

/**
 * Componente para agregar complementos
 * @author Jhoan Lopez <jhoanlt19@gmail.com>
 * @param {*} param0
 */
const DialogAddComplement = ({ open, handleClose, title, label }) => {
  const [complement, setComplement] = useState("");
  const { complements, setComplements } = useComplementContext();

  const handleChangeComplement = ({ target }) => {
    setComplement(target.value);
  };

  const handleCloseDialog = () => {
    setComplement("");
    handleClose();
  };

  const handleSaveComplement = ({ newComplement }) => {
    const newData = [
      ...complements,
      { _id: newComplement._id, name: newComplement.name, values: [] },
    ];
    setComplements(newData);
    setComplement("");
    handleClose();
  };

  const actions = [
    <FlatButton label="Cancelar" primary onClick={handleCloseDialog} />,
    <ButtonSaveComplement
      value={complement}
      onCompleted={handleSaveComplement}
    />,
  ];

  return (
    <div>
      <Dialog
        title={title}
        actions={actions}
        modal={false}
        open={open}
        onRequestClose={handleCloseDialog}
      >
        <div style={styles.contentTextField}>
          <TextField
            name="text"
            data-testid="complement-add"
            floatingLabelText={label}
            value={complement}
            onChange={handleChangeComplement}
          />
        </div>
      </Dialog>
    </div>
  );
};

DialogAddComplement.defaultProps = {
  saveDisabled: false,
};

DialogAddComplement.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  label: PropTypes.string,
};

export default DialogAddComplement;
