import Immutable from 'immutable';
import {TOGGLE_NAV, SELECT_MENU_ITEM} from '../actions/left_navbar';

const INITIAL_STATE = Immutable.Map({
    menuState: false,
    selected: null
});

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
    case TOGGLE_NAV:
        return state.set('menuState', action.payload);
    case SELECT_MENU_ITEM:
        return state.set('selected', action.payload);
    default:
        return state;
    }
}
