import _ from "lodash";
import nomenclatures from "../lib/nomenclatures";

export const LBLAPP = {
    title:
        "Consultoría en Gestión de Riesgos Suramericana - Sistema de Encuestas ",
    menu: {
        companies: "Empresas",
        surveys: "Encuestas",
        consents: "Consentimientos",
        reports: "Mis reportes",
        excelSurveyReport: "Soluciones",
        filterReport: "Reporte",
        lists: "Opciones de respuesta",
        questions: "Preguntas",
        questionsgroup: "Preguntas (Grupos)",
        evaluators: "Evaluadores",
        faq: "FAQ",
        frecuenceQuestions: "Preguntas frecuentes",
        adminLocations: "Admin. ubicaciones",
        adminTemplateEmail: "Admin. plantillas",
        audit: "Auditoría",
        users: "Usuarios",
        tests: "Pruebas",
        profiles: "Perfiles",
        myprojects: "Evaluaciones asociadas",
        assignprojects: "Proyectos asignados",
        adminComplements: "Admin. Complementos",
        complements: "Complementos",
        dataset: "Dataset",
        analytics: 'Analítica'
    },
    rigthMenu: {
        refresh: "Refrescar",
        frecuenceQuestions: "Preguntas frecuentes",
        help: "Ayuda",
        login: "Iniciar sesión",
    },
};
/**
 * constante para tener un id empleado, si no se cuenta con una sesión.
 * @type {string}
 */
export const idEmployeeTest = "56eb22f9a00e08d3a53d9948";
export const NameEmployeeTest = "(Usuario)";
export const NameArlTest = "ARL";

export const LBLLOGIN = {
    label: "Inicio de sesión",
    user: "Usuario",
    password: "Contraseña",
    buttons: {
        save: "Iniciar sesión",
    },
    tooltips: {
        btnEdit: "Modificar",
        btnDelete: "Eliminar",
    },
    validations: {
        user: "Ingrese un usuario",
        passaword: "Ingrese la contraseña",
    },
    msg: {},
};

export const LBLCOMPANIES = {
    label: "Empresa",
    titleGrid: "Empresas registradas",
    titleCreate: "Crear empresa",
    titleEdit: "Modificar empresa",
    titleDelete: "Eliminar empresa",
    nit: "NIT",
    businessName: "Razón social",
    address: "Dirección de la sede principal",
    phone: "Teléfono",
    country: "País",
    department: "Departamento",
    city: "Municipio",
    phoneContact: "Teléfono de contacto",
    emailContact: "Correo electrónico de contacto",
    tagsCompany: "Nombre de la etiqueta",
    email: "Correo electrónico",
    numberEmployees: "Número de empleados",
    arrNumberEmployees: [
        "0 – 10 trabajadores",
        "11 – 50 trabajadores",
        "51 – 200 trabajadores",
        "201 – 500 trabajadores",
        "501 – 2000 trabajadores",
        "2001 – 5000 trabajadores",
        "5001 – 10000 trabajadores",
        "10001 – 50000 trabajadores",
        "Más de 50000 trabajadores",
    ],
    ActivityEconomic: "Actividad económica",
    ARL: "ARL",
    arrActivityEconomic: [
        "TODOS",
        "Administración pública y defensa",
        "Agricultura, ganadería, caza y silvicultura",
        "Comercio",
        "Construcción",
        "Educación",
        "Desarrollo de Software",
        "Eléctrico, gas y agua",
        "Financiero",
        "Hoteles y restaurantes",
        "Industria manufacturera",
        "Inmobiliario",
        "Minas y canteras",
        "Órganos extraterritoriales",
        "Pesca",
        "Servicio doméstico",
        "Servicios comunitarios, sociales y personales",
        "Servicios sociales y de salud",
        "Transporte, almacenamiento y comunicaciones",
    ],
    abbreviation: "Abreviatura",
    actions: "Operaciones",
    infoContact: "Información del contacto de la empresa",
    Tags: "Etiquetas",
    deleteTags: "Eliminar etiqueta",
    nameContact: "Nombre del contacto",
    buttons: {
        save: "Guardar",
        update: "Modificar",
        listEmployess: "Listar colaboradores",
        addTags: "Agregar etiqueta",
    },
    tooltips: {
        btnView: "Configurar",
        btnEdit: "Modificar",
        btnDelete: "Eliminar",
        btnCreate: "Crear nueva empresa",
    },
    validations: {
        nit: "Ingrese el nit",
        validateNit: "Ingrese un número de nit válido",
        businessName: "Ingrese la razón social",
        address: "Ingrese la dirección de la sede principal",
        phone: "Ingrese el teléfono",
        email: "Ingrese el correo electrónico",
        validateEmail: "Ingrese un correo electrónico válido",
        numberEmployees: "Seleccione el rango de colaboradores",
        ActivityEconomic: "Ingrese la actividad económica",
        abbreviation: "Ingrese la Abreviatura",
        actions: "Operaciones",
        nameContact: "Ingrese el nombre",
        country: "Seleccione el país",
        department: "Seleccione el departamento",
        city: "Seleccione el municipio",
        isRequired: "Es requerido",
        arl: "Seleccione la ARL a la que pertenece",
    },
    msg: {
        errorSave: "Hubo un error al guardar",
        successSave: "Se guardó correctamente la empresa",
        successUpdate: "Se modificó correctamente la empresa",
        errorExistNit: "Ya existe una empresa con este NIT",
        deleteModal(companyName) {
            return `¿Está seguro que desea eliminar la empresa ${companyName}?`;
        },
        successDelete: "Se eliminó correctamente la empresa",
        errorDelete: "Hubo un error al eliminar",
        deleteTags() {
            return `¿Está seguro que desea eliminar la etiqueta?`;
        },
    },
};

export const LBLSURVEYS = {
    label: "Prueba",
    titleGrid: "Pruebas registradas",
    titleCreate: "Crear prueba",
    titleEdit: "Modificar prueba",
    titleDeleteSectionQuestion: "Eliminar pregunta de la sección",
    titleDeleteSection: "Eliminar sección",
    name: "Nombre",
    description: "Descripción",
    footer: "Pie de página",
    header: "Encabezado de página",
    actions: "Operaciones",
    titleDelete: "Eliminar prueba",
    titleLock: "Congelar prueba",
    titleClone: "Clonar prueba",
    confirm: "Confirmación",
    sections: {
        titleCreate: "Crear sección",
        titleEdit: "Modificar sección",
        title: "Título",
        searchQuestion: "Ingrese el texto y luego presione Enter para buscar",
        resultFind: "Resultados de la búsqueda",
        questions: "Preguntas",
        listsQuestions: "Listado de preguntas",
        noValues: "No hay valores",
    },
    configConditional: {
        descriptionReport: "Condición",
        descriptionUser: "Descripción para evaluado",
        endValue: "Final",
        initValue: "Inicial",
        priority: "Priorización",
        equivalence: "Equivalencia",
    },
    surveyValidate: {
        titleModal: "Prueba terminada",
        messageModal:
            "Tiempo finalizado. El periodo de tiempo dispuesto para realizar la prueba ha terminado. Muchas gracias.",
    },
    metrical: {
        validations: {
            initValue: "Ingrese el valor inicial",
            endValue: "Ingrese el valor final",
            reportDescription: "Ingrese la condición",
            descriptionUser: "Ingrese la descripción para evaluado",
            color: "Seleccione el color para esta condición",
            equivalence: "Seleccione la equivalencia de la condición",
            priority: "Seleccione la prioridad de la condición",
            lessInitValue: "El valor inicial debe ser menor que el valor final",
            invalidInitValueEndValue:
                "El valor inicial y el valor final se está interceptando con otra condición",
            category: "Seleccione la categoría",
        },
        fields: {
            name: "Nombre",
            titleName: "Nombre del agrupador",
            titleVisible: "Visible para el usuario",
            initValue: "Valor inicial",
            endValue: "Valor final",
            reportDescription: "Condición",
            descriptionUser: "Descripción para evaluado",
            color: "Color",
        },
        resume: {
            incomplete: "incompleto",
            isNull: "sin diligenciar",
            complete: "correcto",
        },
        grouperQuestions: {
            sumatory: "Sumatoria",
            frecuency: "Frecuencia",
        },
        buttons: {
            delete: "Eliminar",
            save: "Guardar",
            cancel: "Cancelar",
            saveGrouperQuestion: "Guardar grupo",
            saveGrouperConditional: "Guardar condiciones",
            addGrouperConditional: "Agregar condición",
            yes: "Si",
            no: "No",
        },
        msg: {
            nameRequired: "Ingrese el nombre del agrupador",
            priorityRequired: "Seleccione la prioridad del agrupador",
            deleteCondition: "¿Está seguro que desea eliminar la condición?",
            errorSave: "Hubo un error al guardar la condición",
            successSave: "Se guardó correctamente las condiciones",
            errorDelete: "Hubo un error al eliminar la condición",
            successDelete: "Se eliminó correctamente la condición",
        },
        descriptionDialogQuestionGroup:
            "Esta pregunta agrupada se calcula como frecuencia, así que se convierte en un agrupador.",
        titleConditional: "Configuración de condiciones",
        titleNoValues: "No hay condiciones definidas para este agrupador",
        titleDialogQuestionGroup: "Guardar grupo de preguntas",
        titleQuestionGroup: "Pregunta agrupada, ",
        titleDeleteCondition: "Eliminar condición",
        titleSections: "Lista de secciones",
        titleListGroupers: "Lista de agrupadores",
        titleConfigMetricals: "Configuración de métricas",
        titleConfigGrouperQuestion: "Agrupador de preguntas",
        titleConfigGrouperGroups: "Agrupador de agrupadores",
        titleGroupsToGrouper: "Agrupadores a agrupar",
        titleRequiredConfigMetricals:
            "Las preguntas que no tengan ningún valor en sus ítems, no se tomarán en cuenta al momento de guardar. Para las preguntas agrupadas, se deben diligenciar TODAS las preguntas o no se tomará en cuenta ninguna pregunta.",
    },
    buttons: {
        save: "Guardar",
        edit: "Editar información",
        configReport: "Configurar estructura del reporte",
        update: "Modificar",
        saveSections: "Guardar sección",
        editSections: "Modificar sección",
        next: "Ir a siguiente sección y guardar",
        finilize: "Finalizar prueba",
        acept: "Aceptar",
        cancel: "Cancelar",
        saveMetrical: "Guardar métricas",
    },
    tooltips: {
        btnConfig: "Configurar",
        btnView: "Visualizar",
        btnEdit: "Modificar",
        btnPreview: "Previsualizar",
        btnClone: "Clonar prueba",
        btnConfigMetrics: "Configurar métricas",
        btnConfigGroupers: "Configurar agrupadores",
        btnCreate: "Crear nueva prueba",
        btnDelete: "Eliminar",
        btnlock: "Congelar",
        btnUpdateMetadata: "Actualizar metadatos",
        btnConfigMetadata: "Configurar Metadata",
    },
    validations: {
        title: "Ingrese el título",
        name: "Ingrese el nombre de la prueba",
        existName: "Ya existe una prueba con ese nombre",
        description: "Ingrese la descripción",
    },
    msg: {
        errorSave: "Hubo un error al guardar",
        errorLock: "Hubo un error al congelar la prueba",
        errorClone: "Hubo un error al clonar la prueba",
        errorMetricsSave: "Hubo un error al guardar las métricas",
        errorSaveDeclineSurvey: "Hubo un error al ",
        errorSaveSection: "Hubo un error al guardar la sección",
        errorSaveSectionExistsSolution:
            "El usuario ya tiene una solución para esta sección",
        errorSaveGroupQuestions:
            "Hubo un error al guardar el grupo de preguntas",
        errorSaveGroupGroup: "Hubo un error al guardar el agrupador",
        errorDelete: "Hubo un error al eliminar",
        successSave: "Se guardó correctamente la prueba",
        successLock: "Se congeló correctamente la prueba",
        successClone: "Se clonó correctamente la prueba",
        successMetricsSave: "Se guardó correctamente las métricas",
        successSaveGroupQuestions:
            "Se guardó correctamente el grupo de preguntas",
        successSaveGroupGroup: "Se guardó correctamente el agrupador",
        successUpdate: "Se modificó correctamente la prueba",
        successSaveSection: "Se guardó correctamente la sección",
        successDelete: "Se eliminó correctamente la prueba",
        deleteModal(surveyName) {
            return `¿Está seguro que desea eliminar la prueba ${surveyName}?`;
        },
        lockModal(surveyName) {
            return `¿Está seguro que desea congelar la prueba ${surveyName}?`;
        },
        cloneModal(surveyName) {
            return `¿Está seguro que desea clonar la prueba ${surveyName}?`;
        },
        deleteModalSection(sectionName) {
            return `¿Está seguro que desea eliminar la sección ${sectionName}?`;
        },
        deleteModalSectionQuestion(questionTitle) {
            return `¿Está seguro que desea eliminar la pregunta "${questionTitle}" de esta sección?. Recuerde que hecho esto, si la pregunta pertenece a algún agrupador configurado en esta prueba, se eliminará de dicho agrupador.`;
        },
        confirmSectionNext:
            "¿Seguro que desea continuar con la siguiente sección?",
        confirmSectionFinalize: "¿Seguro que desea finalizar la prueba?",
        rememberConfirm:
            "Recuerde que una vez finalizada esta sección, luego no se podrá modificar.",
        confirmDeclineSurvey: "¿Está seguro que no desea realizar la prueba?",
        rememberDecline: "Recuerde que luego no podrá realizarla.",
        declineSurveyConfirm: "No deseo realizar la prueba.",
    },
    arrays: {
        lowercase: nomenclatures.nomenclatureLyricsLowerCase(), // ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'],
        uppercase: nomenclatures.nomenclatureLyricsUpperCase(), // ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']
    },
};

export const LBLGROUPERS = {
    titleGridConfigGrouper: "Configurar agrupadores",
    name: "Nombre",
    titleDelete: "Eliminar agrupador",
    level: "Nivel",
    actions: "Operaciones",
    viewEmployee: "Visible",
    typeOperation: "Tipo operación",
    titleVisible: "Visible para el usuario",
    buttons: {
        valueItems: "Valorar ítems",
        grouperQuestions: "Agrupar preguntas",
        groupGroupers: "Agrupar agrupadores",
        back: "Regresar",
        cancel: "Cancelar",
        save: "Guardar",
        no: "No",
        yes: "Sí",
    },
    tooltips: {
        btnConfig: "Configurar condiciones",
        btnView: "Visualizar estructura",
        btnDelete: "Eliminar",
        btnEdit: "Modificar nombre",
    },
    validations: {
        name: "Ingrese el nombre del agrupador",
    },
    msg: {
        errorSave: "Hubo un error al guardar",
        successDelete: "Se eliminó correctamente el agrupador",
        errorDelete: "Hubo un error al eliminar",
        deleteModal(grouperName) {
            return `¿Está seguro que desea eliminar el agrupador ${grouperName}?`;
        },
    },
};

export const LBLTEMPLATES = {
    titleCreate: "Crear plantilla para los correos electrónicos",
    titleEdit: "Modificar plantilla para los correos electrónicos",
    titleDelete: "Eliminar etiqueta",
    titleGrid: "Etiquetas de correos electrónicos",
    titleTableName: "Nombre",
    actions: "Operaciones",
    name: "Nombre de la plantilla",
    type: "Tipo de plantilla",
    arrTypeTemplate: ["Genérico", "Ingreso al sistema"],
    buttons: {
        save: "Guardar",
        update: "Modificar",
    },
    tooltips: {
        btnView: "Configurar",
        btnEdit: "Modificar",
        btnDelete: "Eliminar",
        btnCreate: "Crear plantilla",
    },
    validations: {
        name: "Ingrese el nombre de la plantilla",
        description: "Ingrese el texto para la plantilla",
        type: "Seleccione el tipo de plantilla",
        placeholderPassword:
            "La etiqueta ##clave es obligatorio para este tipo de plantilla.",
        placeholderUser:
            "La etiqueta ##usuario es obligatorio para este tipo de plantilla.",
    },
    msg: {
        errorSave: "Hubo un error al guardar",
        errorDelete: "Hubo un error al eliminar",
        successSave: "Se guardó correctamente la plantilla",
        successDelete: "Se eliminó correctamente la plantilla",
        deleteModal(nameTemplate) {
            return `¿Está seguro que desea eliminar la plantilla ${nameTemplate}?`;
        },
    },
    labels: {
        valuesAllowed:
            "La siguiente lista de valores son tratados como etiquetas, es decir, una vez se envían al correo electrónico del usuario, se reemplazará con la información del empleado. Según el tipo de plantilla, tiene las siguientes reglas:",
        arrayValuesAllowed: [
            {
                type: "Genérico",
                array: [
                    "##nombreEmpleado (opcional)",
                    "##usuario (opcional)",
                    "##URLIngreso (opcional)",
                ],
            },
            {
                type:
                    "Ingreso al sistema: cuando se envía un correo electrónico con esté tipo, la clave se cambia por una nueva generada por el sistema.",
                array: [
                    "##nombreEmpleado (opcional)",
                    "##usuario (obligatorio)",
                    "##clave (obligatorio)",
                    "##URLIngreso (opcional)",
                ],
            },
        ],
    },
};

export const LBLQUESTIONS = {
    label: "Preguntas",
    titleGrid: "Banco de preguntas",
    titleCreate: "Crear pregunta",
    titleEdit: "Modificar pregunta",
    question: "Pregunta",
    fieldNameQuestion: "Código",
    type: "Tipo",
    addOtherWhich: "Agregar opción otro, ¿cuál?",
    otherWhich: "Otro, ¿cuál?",
    otherWhichRequired: "Este campo es requerido.",
    optionsSelectable: "Cantidad máxima seleccionable",
    groupList: "Opciones de respuesta",
    code: "Código pregunta",
    statement: "Enunciado pregunta",
    helpText: "Texto de ayuda para la pregunta",
    sizeQuestion: "Tamaño de la pregunta",
    itemListSelectable: "Seleccione el tipo de enumeración",
    actions: "Operaciones",
    singleQuestionTypes: [
        { id: "AnswerOpen", label: "Abierta" },
        { id: "ListOpen", label: "Opción multiple" },
        { id: "ListClose", label: "Opción única" },
        { id: "Date", label: "Fecha" },
        { id: "Number", label: "Número" },
        { id: "AnswerOpenShort", label: "Abierta Corta" },
        { id: "ListSelected", label: "Lista desplegable" },
        { id: "LocationCountry", label: "Ubicación país" },
        { id: "LocationDepartment", label: "Ubicación departamento" },
        { id: "LocationCity", label: "Ubicación municipio" },
    ],
    arrTypesQuestions: [
        "Abierta",
        "Cerradas",
        "Opción multiple",
        "Opción única",
        "Fecha",
        "Número",
        "Abierta Corta",
        "Cuadrícula (Sí o No)",
        "Cuadrícula (lista)",
        "Lista de opciones",
        "Lista desplegable",
        "Ubicación país",
        "Ubicación departamento",
        "Ubicación municipio",
    ],
    arrSizesQuestions: [
        {
            id: "middle",
            label: "Una columna",
        },
        {
            id: "long",
            label: "Dos columnas",
        },
    ],
    itemListEnumeration: [
        { id: "none", label: "Ninguna" },
        { id: "numeric", label: "1, 2, 3, 4..." },
        { id: "lowerCase", label: "a, b, c, d..." },
        { id: "upperCase", label: "A, B, C, D..." },
    ],
    arrItemList: ["Ninguna", "1, 2, 3, 4...", "a, b, c, d...", "A, B, C, D..."],
    titleDelete: "Eliminar pregunta",
    buttons: {
        save: "Guardar",
    },
    tooltips: {
        btnView: "Visualizar",
        btnEdit: "Modificar",
        btnDelete: "Eliminar",
        btnCreate: "Crear nueva pregunta",
    },
    validations: {
        code: "Ingrese el código de la pregunta",
        codeValue:
            "El código de la pregunta no puede tener caracteres especiales",
        groupList: "Seleccione un grupo lista",
        type: "Seleccione un tipo de pregunta",
        optionsSelectable: "Seleccione la cantidad máxima seleccionable",
        statement: "Ingrese un enunciado para la pregunta",
        helpText: "Ingrese el texto de ayuda",
        codeExist: "Ya existe una preguna con este código",
    },
    msg: {
        errorSave: "Hubo un error al guardar",
        errorDelete: "Hubo un error al eliminar la pregunta",
        successSave: "Se guardó correctamente la pregunta",
        successUpdate: "Se modificó correctamente la pregunta",
        successDelete: "Se eliminó correctamente la pregunta",
        deleteModal(questionName) {
            return `¿Está seguro que desea eliminar la pregunta ${questionName}?`;
        },
    },
};
export const LBLQUESTIONSGROUP = {
    label: "Preguntas",
    titleGrid: "Banco de preguntas agrupadas",
    titleCreate: "Crear pregunta agrupada",
    titleEdit: "Modificar pregunta agrupada",
    question: "Pregunta",
    noValues: "No hay valores",
    type: "Tipo",
    groupList: "listado de opciones de respuesta",
    code: "Código de la pregunta",
    statement: "Enunciado de la pregunta",
    helpText: "Texto de ayuda",
    actions: "Operaciones",
    arrTypesQuestions: [
        "Abierta",
        "Cerradas",
        "Opción multiple",
        "Opción única",
        "Fecha",
        "Número",
        "Abierta Corta",
        "Cuadrícula (Sí o No)",
        "Cuadrícula (lista)",
        "Lista de opciones",
        "Lista desplegable",
    ],
    titleDelete: "Eliminar pregunta",
    buttons: {
        save: "Crear pregunta",
        addStatement: "Agregar enunciado",
        update: "Modificar pregunta",
    },
    tooltips: {
        btnView: "Visualizar",
        btnEdit: "Modificar",
        btnDelete: "Eliminar",
        btnCreate: "Crear nueva pregunta",
        btnUp: "Subir",
        btnDown: "Bajar",
    },
    validations: {
        code: "Ingrese el código de la pregunta",
        codeValue:
            "El código de la pregunta no puede tener caracteres especiales",
        validateListQuestion:
            "Seleccione mínimo 2 opciónes de respuesta para que se asigne a la pregunta",
        validateList: "Seleccione una lista para los enunciados",
        groupList: "Seleccione un grupo lista",
        statement: "Ingrese un enunciado para la pregunta",
        helpText: "Ingrese el texto de ayuda",
        isRequired: "Es requerido",
        codeExist: "Ya existe una preguna con este código",
    },
    msg: {
        errorSave: "Hubo un error al guardar",
        successSave:
            "Se guardó correctamente la pregunta con los grupos de valores",
        successUpdate: "Se modificó correctamente la pregunta",
        deleteModal(questionName) {
            return `¿Está seguro que desea eliminar la pregunta ${questionName}?`;
        },
    },
};

export const LBLLISTS = {
    label: "Opciones de respuesta",
    infoCreateList:
        "Recuerde que mínimo son dos opciones de respuesta para la lista",
    titleGrid: "Listado de opciones de respuesta",
    titleCreate: "Crear lista",
    titleEdit: "Modificar lista",
    titleDuplicateList: "Duplicar opción de respuesta",
    actions: "Operaciones",
    help:
        "Mínimo son dos opciones de respuesta, los campos no pueden estar vacíos",
    nameList: "Opción",
    name: "Código / Nombre",
    noValues: "No hay valores",
    description: "Descripción",
    titleDelete: "Eliminar opciones de respuesta",
    buttons: {
        save: "Guardar",
        saveDuplicate: "Guardar como nueva opciones de respuesta",
        addOption: "Agregar opción",
    },
    tooltips: {
        btnCopy: "Crear copia",
        btnInfo: "Ver opciones de respuesta",
        btnDelete: "Eliminar",
        btnCreate: "Crear opciones de respuesta",
        btnUp: "Subir",
        btnDown: "Bajar",
    },
    validations: {
        name: "Ingrese el código / nombre",
        optionList: "Ingrese el nombre de la opción",
        description: "Ingrese la descripción",
        existName: "Ya existe unas opciones de respuesta con ese nombre",
        isRequired: "Es requerido",
    },
    msg: {
        errorSave: "Hubo un error al guardar",
        errorDelete: "Hubo un error al eliminar el registro",
        successSave: "Se guardó correctamente las opciones de respuesta",
        successDelete: "Se eliminó correctamente las opciones de respuesta",
        successUpdate: "Se modificó correctamente las opciones de respuesta",
        deleteModal(listName) {
            return `¿Está seguro que desea eliminar las opciones de respuesta ${listName}?`;
        },
    },
};

export const LBLCONSENTS = {
    label: "Consentimientos",
    titleEdit: "Modificar consentimiento",
    titleGrid: "Consentimientos registrados",
    titleCreate: "Crear consentimiento",
    titleDelete: "Eliminar consentimiento",
    titleRequired: "¿Este consentimiento es obligatorio?",
    name: "Nombre",
    actions: "Operaciones",
    agreementsConsent: "Acuerdos y consentimientos",
    requiredConsent(required) {
        return required
            ? `Este consentimiento SI es obligatorio.`
            : `Este consentimiento NO es obligatorio.`;
    },
    order: "Orden",
    buttons: {
        save: "Guardar",
        update: "Modificar",
    },
    tooltips: {
        btnEdit: "Modificar",
        btnDelete: "Eliminar",
        btnCreate: "Crear nuevo consentimiento",
    },
    validations: {
        name: "Ingrese el nombre del consentimiento",
        agreementsConsent: "Ingrese el consentimiento",
    },
    msg: {
        errorSave: "Hubo un error al guardar",
        errorDelete: "Hubo un error al eliminar",
        successSave: "Se guardó correctamente el consentimiento",
        successUpdate: "Se modificó correctamente el consentimiento",
        successDelete: "Se eliminó correctamente el consentimiento",
        deleteModal(consentName) {
            return `¿Está seguro que desea eliminar el consentimiento ${consentName}?`;
        },
    },
};

export const LBLEVALUATORS = {
    label: "Evaluadores",
    titleGrid: "Evaluadores",
    titleCreate: "Crear evaluador",
    titleEdit: "Modificar evaluador",
    titleTap: "Evaluadores asociados",
    id: "Identificación",
    typeId: "Tipo de identificación",
    name: "Nombre",
    names: "Nombres",
    process: "Gerencia / Proceso",
    lastNames: "Apellidos",
    profession: "Profesión",
    arrayTypesId: [
        "Tarjeta de identidad",
        "Cédula de ciudadanía",
        "Cédula de extranjería",
        "Pasaporte",
    ],
    cardProfessional: "No. Tarjeta profesional",
    licensedOccupationalHealth: "No. licencia en salud ocupacional",
    licenseIssuanceDate: "Fecha expedición de la licencia",
    levelStudy: "Nivel de estudio",
    arrayLevelsStudys: [
        "Básica",
        "Media",
        "Técnica",
        "Tecnológica",
        "Profesional",
        "Especialización",
        "Maestría",
        "Doctorado",
    ],
    email: "Correo electrónico",
    company: "Empresa",
    actions: "Operaciones",
    titleDelete: "Eliminar evaluador",
    buttons: {
        save: "Guardar",
        update: "Modificar",
    },
    tooltips: {
        btnEdit: "Modificar",
        btnDelete: "Eliminar",
        btnCreate: "Crear nuevo evaluador",
        btnAdd: "Agregar evaluadores",
        btnSendMail: "Enviar correo",
    },
    validations: {
        id: "Ingrese la identificación",
        typeId: "Seleccione un tipo de documento",
        name: "Ingrese el nombre",
        lastName: "Ingrese los apellidos",
        company: "Seleccione la empresa a la que pertenece",
        levelStudy: "Seleccione el nivel de educación",
        profession: "Ingrese la profesión",
        cardProfessional: "Ingrese el número de tarjeta profesional",
        licensedOccupationalHealth: "Ingrese la licencia de salud ocupacional",
        licenseIssuanceDate:
            "Ingrese la fecha de expedición de la licencia de salud ocupacional",
        email: "Ingrese el correo electrónico",
        emailValid: "Ingrese un correo electrónico válido",
        process: "Ingrese la gerencia o el proceso en el que trabaja",
        idExist: "Ya existe un evaluador con esta identificación",
        emailExist: "Ya existe un evaluador con este correo electrónico",
    },
    msg: {
        errorSave: "Hubo un error al guardar",
        errorDelete: "Hubo un error al eliminar",
        successSave: "Se guardó correctamente el evaluador",
        successUpdate: "Se modificó correctamente el evaluador",
        successDelete: "Se eliminó correctamente el evaluador",
        deleteModal(evaluetorName) {
            return `¿Está seguro que desea eliminar el evaluador ${evaluetorName}?`;
        },
    },
};

export const LBLFAQS = {
    label: "FAQ",
    titleGrid: "Preguntas frecuentes (FAQs)",
    titleCreate: "Crear pregunta frecuente (FAQ)",
    titleEdit: "Modificar pregunta frecuente (FAQ)",
    questionFAQ: "Nombre ó pregunta",
    answer: "Respuesta sugerida",
    actions: "Operaciones",
    titleDelete: "Eliminar FAQ",
    buttons: {
        save: "Guardar",
        update: "M0dificar",
    },
    tooltips: {
        btnEdit: "Modificar",
        btnDelete: "Eliminar",
        btnCreate: "Crear nueva Pregunta frecuente",
    },
    validations: {
        questionFAQ: "Ingrese el nombre ó pregunta para el FAQ",
        answer: "Ingrese la respuesta para el FAQ",
    },
    msg: {
        errorSave: "Hubo un error al guardar",
        successSave: "Se guardó correctamente la pregunta frecuente",
        successUpdate: "Se modificó correctamente la pregunta frecuente",
        errorDelete: "Hubo un error al eliminar",
        successDelete: "Se eliminó correctamente la pregunta frecuente",
        deleteModal(faqName) {
            return `¿Está seguro que desea eliminar el FAQ ${faqName}?`;
        },
    },
};

export const LBLLOCATIONS = {
    label: "Ubicaciones",
    titleGrid: "Administrar ubicaciones",
    titleCreate: "Crear ubicación",
    titleEdit: "Modificar ubicación",
    countries: "Paises",
    departments: "Departamentos",
    cities: "Municipios",
    name: "Nombre",
    code: "Código",
    actions: "Operaciones",
    titleDelete: "Eliminar",
    buttons: {
        save: "Guardar",
        update: "Modificar",
        cancel: "Cancelar",
    },
    tooltips: {
        btnEdit: "Modificar",
        btnDelete: "Eliminar",
        btnCreate: "Crear",
        btnCreateCity: "Crear municipio",
        btnCreateDepartment: "Crear departamento",
        btnCreateCountry: "Crear país",
    },
    validations: {
        name: "Ingrese el nombre",
        code: "Ingrese el código",
    },
    msg: {
        errorSave: "Hubo un error al guardar",
        errorDelete: "Hubo un error al eliminar",
        successSave: "Se guardó correctamente",
        successUpdate: "Se modificó correctamente",
        successDelete: "Se eliminó correctamente",
        deleteModal(ubicationName) {
            return `¿Está seguro que desea eliminar ${ubicationName}?`;
        },
    },
};

export const LBLUSERS = {
    label: "Usuarios",
    titleGrid: "Usuarios",
    titleGridUser: "Usuario : ",
    titleCreate: "Crear usuario",
    titleEdit: "Modificar usuario",
    titleModalChangeFirstPass: "Debe cambiar la contraseña",
    titleModalChangePass: "Cambiar contraseña",
    id: "Identificación",
    findUser: "Buscar usuario..",
    seeker: "Buscar",
    name: "Nombres",
    ARL: "ARL",
    email: "Correo electrónico",
    profile: "Rol en el sistema",
    companies: "Empresa(s)",
    profiles: "Seleccione perfil",
    typeId: "Tipo documento",
    lastName: "Apellidos",
    titleProfileSelected: "Seleccione los perfiles para este usuario",
    loadMore: "Cargar más",
    titleDelete: "Eliminar usuario",
    currentPassword: "Contraseña actual",
    typeUser: "Tipo de usuario",
    username: "Nombre de usuario",
    arrTypesId: [
        "Cédula de ciudadanía",
        "Tarjeta de identidad",
        "Cédula de extranjería",
        "Pasaporte",
    ],
    newPassword: "Nueva contraseña",
    confirmPassword: "Confirmar contraseña",
    buttons: {
        save: "Guardar",
        update: "Modificar",
        exit: "Salir",
        cancel: "Cancelar",
        changePass: "Cambiar contraseña",
    },
    tooltips: {
        btnEdit: "Modificar",
        btnDelete: "Eliminar",
        btnCreate: "Crear nuevo usuario",
    },
    validations: {
        newPassword: "Ingrese la nueva contraseña",
        currentPassword: "Ingrese la contraseña actual",
        confirmPassword: "Confirme la nueva contraseña",
        notEqualsPasswords: "Las contraseñas no coinciden",
        notBlanksSpaces: "La contraseña no puede contener espacios en blanco",
        passwordStrength:
            "La contraseña debe ser de mínimo 8 caracteres, contener letras y números",
        equalsPasswords: "Debe ser diferente a la actual",
        invalidEmail: "Dirección de correo electrónico inválida",
        email: "Ingrese el correo electrónico",
        name: "Ingrese el nombre",
        arl: "Seleccione el ARL del usuario",
        lastName: "Ingrese los apellidos",
        id: "Ingrese la identificación",
        typeUser: "Seleccione el tipo de usuario",
        // role: "Seleccione el rol que tendrá en el sistema",
        typeId: "Seleccione el tipo documento",
        idExist: "Ya existe un usuario con esta identificación",
        profiles: "Seleccione por lo menos un perfil para el usuario"
    },
    msg: {
        errorSave: "Hubo un error al guardar",
        errorDelete: "Ocurrió un error al eliminar el usuario.",
        errorChangePassword:
            "Error al cambiar la contraseña, intentelo de nuevo más tarde.",
        currentPasswordNotValid: "Contraseña actual no válida",
        successChangePassword: "Contraseña cambiada correctamente",
        successSave: "Se guardó correctamente el usuario",
        successUpdate: "Se modificó correctamente el usuario",
        successDelete: "Se eliminó correctamente el usuario",
        deleteModal(userName) {
            return `¿Está seguro que desea eliminar el usuario ${userName}?`;
        },
    },
};

export const LBLPROJECTS = {
    label: "Proyectos",
    titleGrid: "Proyectos asociados",
    titleCreate: "Crear proyecto",
    titleEdit: "Modificar información básica del proyecto",
    titleDeleteReferee: "Eliminar evaluador asociado",
    name: "Nombre",
    consents: "Consentimientos",
    resultSearch: "Resultados de la búsqueda",
    initialDate: "Fecha inicio",
    initialDateHint: "Fecha inicio de evaluación",
    finalDate: "Fecha final",
    finalDateHint: "Fecha final de evaluación",
    survey: "Prueba",
    description: "Descripción",
    titleDelete: "Eliminar proyecto",
    inactiveTime: "Tiempo de inactividad (Minutos)",
    titleDeleteConfigDay: "Eliminar lapso",
    titleDeleteDisassociateEmployee: "Desasociar empleado",
    actions: "Operaciones",
    taplapse: "Lapsos",
    tapEvaluators: "Evaluadores",
    tapReport: "Informe",
    tapSurveys: "Prueba",
    tapEmployees: "Colaboradores",
    searchConsents: "Buscar consentimientos",
    required(required) {
        return _.isUndefined(required) ? "" : required ? "¡Obligatorio!" : "";
    },
    config: {
        toDay: "Configuración para el día ",
        firstLapseTime: "Primer lapso (Requerido)",
        secondLapseTime: "Segundo lapso (opcional)",
        thirdLapseTime: "Tercero lapso (opcional)",
        fourthLapseTime: "Cuarto lapso (opcional)",
        hourInitialTest: "Hora de inicio de la prueba",
        hourCloseTest: "Hora de cierre de la prueba",
        inactiveTime: "",
    },
    associateEvaluator: {
        title: "Asociación de evaluadores",
        helpSearch: "Ingrese los datos que desea buscar y luego presione enter",
        evaluator: "Evaluador",
        resultSearch: "Resultados de la búsqueda",
        listEvaluators: "Listado de evaluadores",
        noEvaluators: "No hay evaluadores",
    },
    timeLapse: {
        week: [
            "Lunes",
            "Martes",
            "Miércoles",
            "Jueves",
            "Viernes",
            "Sábado",
            "Domingo",
        ],
        title: "Lapsos",
        configTitle: "Configurar lapsos",
        configInactiveTitle: "Configurar tiempo de inactividad del usuario",
        day: "Día",
        firstLapse: "Primer lapso",
        secondLapse: "Segundo lapso",
        thirdLapse: "Tercer lapso",
        fourthLapse: "Cuarto lapso",
        actions: "Operaciones",
    },
    buttons: {
        save: "Guardar",
        saveConfig: "Guardar Configuración",
        edit: "Editar información",
        editColors: "Editar colores",
        editFilter: "Configurar",
        editRanking: "Configurar",
        viewResultStats: "Ver resultados",
        viewReportProject: "Ver informe",
        toUpdate: "Actualizar",
        saveRanking: "Guardar ranking",
    },
    tooltips: {
        btnEdit: "Modificar",
        btnDelete: "Eliminar",
        btnDisassaciate: "Desasociar",
        btnCreate: "Crear nuevo proyecto",
        btnView: "Configurar",
        btnClear: "Limpiar",
    },
    validations: {
        initialDate: "Ingrese la fecha de inicio",
        finalDate: "Ingrese la fecha de cierre",
        validateDate: "La fecha de inicio debe ser menor a la fecha de cierre",
        name: "Ingrese el nombre del proyecto",
        description: "Ingrese la descripción del proyecto",
        survey: "Seleccione la evaluación que asignará al proyecto",
        isRequired: "Es requerido",
        hourInitialTest: "Ingrese la hora de inicio",
        hourCloseTest: "Ingrese la hora de cierre",
        validateHour: "La hora de cierre debe ser mayor a la hora de inicio",
        validateHourPreviousTime:
            "La hora de inicio debe ser mayor a la hora de cierre del lapso anterior",
        validateHourL1:
            "Esta hora debe ser mayor a la hora de cierre del primer lapso.",
        validateHourL2:
            "Esta hora debe ser mayor a la hora de cierre del segundo lapso",
        validateHourL3:
            "Esta hora debe ser mayor a la hora de cierre del tercer lapso",
        validateInactiveTime:
            "Ingrese el tiempo de inactividad para este proyecto",
        validateInactiveTimeOnlyNumber:
            "Ingrese un número válido y mayor o igual a cero para el tiempo de inactividad",
    },
    msg: {
        errorSave: "Hubo un error al guardar",
        successDelete: "Se eliminó correctamente el lapso",
        successSave: "Se guardó correctamente el proyecto",
        successSaveConfig: "La configuración se guardó correctamente",
        successUpdate: "Se modificó correctamente el proyecto",
        successEvaluator: "Éxito, evaluadores guardados.",
        errorSaveInactiveTime:
            "Hubo un error al guardar el tiempo de inactividad",
        successSaveInactiveTime:
            "Se guardó correctamente el tiempo de inactividad",
        errorProjectDelete: "Hubo un error al eliminar",
        successProjectDelete: "Se eliminó correctamente el proyecto",
        errorProjectDisassaciateEmployee:
            "Hubo un error al desasociar al colaborador",
        successProjectDisassaciateEmployee:
            "Se desasoció correctamente al colaborador",
        successProjectNotDisassaciateEmployee:
            "No es permitido desasociar al colaborador porque ya ha iniciado la prueba.",
        errorProjectRanking:
            "Hubo un error al guardar la configuración del ranking",
        successProjectRanking:
            "Se guardó correctamente la configuración del ranking",
        deleteModal(projectName) {
            return `¿Está seguro que desea eliminar el proyecto ${projectName}?`;
        },
        deleteConfigDayModal(day) {
            return `¿Está seguro que desea eliminar los lapsos para el día ${day}?`;
        },
        deleteEmployeeAssociated(employeeName) {
            return `¿Está seguro que desea eliminar del proyecto a ${employeeName}?`;
        },
        deleteModalReferee(RefereeName) {
            return `¿Está seguro que desea eliminar al evaluador ${RefereeName} del proyecto?`;
        },
        inactiveMessage(time) {
            return `El tiempo de inactividad que el usuario tiene para este proyecto es de ${time} minutos.`;
        },
        inactiveMessageIDLE(time) {
            return `El tiempo permitido de inactividad para este proyecto, es de ${time} minutos. Usted será dirigido al ingreso del sistema.`;
        },
    },
    advance: {
        deserted: "Desertores",
        pending: "Pendientes",
        started: "En proceso",
        finished: "Finalizados",
        notFinished: "Sin finalizar",
        person: {
            id: "Identificación",
            name: "Nombre completo",
            email: "Correo Electrónico",
            actions: "Acciones",
        },
        notFoundUser: "No hay colaboradores con este estado",
    },
};

export const LBLPROFILE = {
    titleGrid: "Perfiles",
    titleEdit: "Modificar Perfil",
    titleCreate: "Crear Perfil",
    titlePermissions: "Permisos",
    name: "Nombre del perfil",
    description: "Descripción del perfil",
    nameTable: "Nombre",
    descriptionTable: "Descripción",
    actionsTable: "Acciones",
    buttons: {
        update: "Modificar",
        save: "Guardar",
    },
    validations: {
        name: "Ingrese el nombre",
        description: "Ingrese la descripción",
    },
    tooltips: {
        btnEdit: "Editar",
        btnCreate: "Crear perfil",
    },
    msg: {
        errorSave: "Hubo un error al guardar",
        successSave: "Se guardó correctamente el perfil",
        successUpdate: "Se modificó correctamente el perfil",
        errorExistName: "Ya existe un perfil con este nombre",
        deleteModal(companyName) {
            return `¿Está seguro que desea eliminar el perfil ${companyName}?`;
        },
    },
};

export const LBLEMPLOYESS = {
    label: "Colaboradores",
    titleGrid: "Colaboradores",
    titleAssociated: "Colaboradores asociados",
    titleAssociate: "Asociar colaboradores",
    titleSendEmailMasive: "Operaciones Masivas",
    titleCreate: "Crear colaborador",
    titleEdit: "Modificar colaborador",
    typeId: "Tipo documento",
    id: "Identificación",
    Tags: "Etiquetas",
    arrTypesId: [
        "Cédula de ciudadanía",
        "Tarjeta de identidad",
        "Cédula de extranjería",
        "Pasaporte",
    ],
    name: "Nombre",
    names: "Nombres",
    lastNames: "Apellidos",
    email: "Correo electrónico",
    actions: "Operaciones",
    titleDelete: "Eliminar colaborador",
    titleDeleteMasive: "Eliminar colaboradores",
    lblSendEmail: "Enviar correo electrónico",
    subject: "Asunto",
    emailTo: "Para",
    template: "Plantilla",
    message: "Mensaje",
    buttons: {
        save: "Guardar",
        update: "Modificar",
        cancel: "Cancelar",
        send: "Enviar",
        clear: "Limpiar",
        masiveOperations: "Operaciones masivas",
        sendingBulkMail: "Correo masivo",
        deleteBulkEmployees: "Eliminar masivo",
    },
    tooltips: {
        btnEdit: "Modificar",
        btnDelete: "Eliminar",
        btnCreate: "Crear nuevo colaborador",
        btnAssociate: "Asociar colaboradores",
        btnSendMail: "Enviar correo",
    },
    hintText: {
        email: "Ej: ejemplo@dominio.com",
    },
    validations: {
        typeId: "Seleccione el tipo documento",
        id: "Ingrese la identificación",
        existId: "Ya existe un colaborador con esta identificación",
        validateId:
            "Ingrese un número de identificación válido (mínimo 5 - máximo 13 caracteres y sin espacios)",
        names: "Ingrese el nombre",
        lastNames: "Ingrese los apellidos",
        email: "Ingrese el correo electrónico",
        validateEmail:
            "Ingrese un correo electrónico válido. Ej: ejemplo@dominio.com",
        existEmail:
            "Este correo electrónico se encuentra asociado a otra persona",
        isRequired: "Es requerido",
    },
    msg: {
        errorSave: "Hubo un error al guardar",
        successDeleteEmployees: "Colaboradores eliminados con éxito.",
        errorDeleteEmployees:
            "Ocurrió un error elimnado los colaboradores, intente de nuevo",
        successSave: "Se guardó correctamente el colaborador",
        successUpdate: "Se modificó correctamente el colaborador",
        successDelete: "Se eliminó correctamente el colaborador",
        errorDelete: "Hubo un error al eliminar",
        deleteModal(employeName) {
            return `¿Está seguro que desea eliminar el colaborador ${employeName}?`;
        },
        deleteModalMasive(count) {
            if (count === 1) {
                return `¿Está seguro que desea eliminar ${count} colaborador?`;
            }
            return `¿Está seguro que desea eliminar ${count} colaboradores?`;
        },
    },
};

export const LBL_METADATA = {
    label: "Condiciones de interpretación",
    saving: "Guardando condiciones de interpretación ...",
    saved: "Condiciones de interpretación guardadas correctamente.",
    error: "Error guardando las condiciones de interpretación.",
    maxMinVal: "Valor inicial mayor que valor final",
    valueOverlap: "Existe solapamiento de valores",
};

export const LBL_COMPLEMENTS = {
    titleGrid: "Complementos",
    titleCreate: "Crear complemento",
    titleEdit: "Modificar complemento",
    titleDelete: "Eliminar complemento",
    titleDeleteValue: "Eliminar valor",
    tooltips: {
        btnEdit: "Modificar",
        btnDelete: "Eliminar",
        btnCreate: "Crear complemento",
    },
    msg: {
        errorSave: "Hubo un error al guardar",
        successSave: "Se guardó correctamente el complemento",
        successUpdate: "Se modificó correctamente el complemento",
        errorDelete: "Hubo un error al eliminar",
        successDelete: "Se eliminó correctamente el complemento",
        deleteModal(complementName) {
            return `¿Está seguro que desea eliminar ${complementName}?`;
        },
    },
    titleListComplement: "Valores",
    titleListValues: "Condiciones",
    buttonAdd: "Agregar",
};

export const LBL_DATASET = {
    titleGrid: "Dataset",
    titleAnswer: "Agregar pregunta",
    titleTags: "Agregar etiqueta",
    titleSolution: "Solución",
    tooltips: {
        btnCreate: "Crear"
    }
};
