import PropTypes from "prop-types";
import React, { Component } from "react";
import IconButton from "material-ui/IconButton";
import HelpIcon from "material-ui/svg-icons/action/help";
import { blue700, blue300 } from "material-ui/styles/colors";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import _ from "lodash";

const customContentStyle = {
    maxWidth: 600,
    minWidth: 280,
    textAlign: "justify",
    paddingTop: 20,
};

class Title extends Component {
    constructor(props) {
        super(props);
        this.state = { open: false };
    }

    handleOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    renderDescription(descrip) {
        let description = "";
        if (!_.isUndefined(descrip) && !_.isEqual(descrip, "")) {
            description = _.split(descrip, /\r\n|\r|\n/g);
        }
        if (description instanceof Array) {
            return (
                <h3
                    style={{
                        textAlign: "justify",
                        fontWeight: 200,
                        wordWrap: "break-word",
                    }}
                >
                    {description.map((value, index) => {
                        if (!_.isEqual(value, "")) {
                            return (
                                <div key={index}>
                                    {value}
                                    <br />
                                    <br />
                                </div>
                            );
                        }
                    })}
                </h3>
            );
        } 
            return (
                <h3
                    style={{
                        textAlign: "justify",
                        fontWeight: 200,
                        wordWrap: "break-word",
                    }}
                >
                    {description}
                </h3>
            );
        
    }

    renderDialog(exit, help, title) {
        if (exit && !_.isEqual(help, "")) {
            return (
                <Dialog
                    title="¡AYUDA!"
                    titleStyle={{ textAlign: "center", color: blue700 }}
                    actions={[
                        <FlatButton
                            label="Aceptar"
                            secondary
                            keyboardFocused
                            onClick={this.handleClose}
                        />,
                    ]}
                    modal
                    contentStyle={customContentStyle}
                    autoScrollBodyContent
                    open={this.state.open}
                >
                    <h2
                        style={{
                            fontWeight: 200,
                            textAlign: "center",
                            marginTop: 20,
                            marginBottom: 30,
                            wordWrap: "break-word",
                        }}
                    >
                        {title}
                    </h2>
                    {this.renderDescription(help)}
                </Dialog>
            );
        } 
            return <div />;
        
    }

    getTitle(question, index) {
        const text =
            _.get(question, "quantitySelectable") > 1
                ? " opciones)"
                : " opción)";
        if (
            _.has(question, "quantitySelectable") &&
            _.get(question, "quantitySelectable") >= 1
        ) {
            return (
                `${index +
                _.get(question, "title") 
                } (Máximo ${ 
                _.get(question, "quantitySelectable") 
                }${text}`
            );
        }
        return index + _.get(question, "title");
    }

    render() {
        const { question, index } = this.props;
        const idx = _.isUndefined(index) ? "" : `${index  }. `;
        const exit = !_.isUndefined(question.help);
        const exitHelp = _.has(question, "help")
            ? !_.isEqual(_.get(question, "help"), "")
            : false;
        return (
            <div
                style={{
                    width: exitHelp ? "88%" : "95%",
                    textAlign: "justify",
                    paddingLeft: exitHelp ? 28 : 0,
                }}
            >
                <h3 style={{ fontWeight: 400 }}>
                    <IconButton
                        tooltip="Clic para obtener ayuda"
                        tooltipPosition="top-right"
                        onClick={this.handleOpen}
                        style={{
                            position: "absolute",
                            marginTop: -15,
                            marginLeft: -40,
                            display: exit
                                ? !_.isEqual(question.help, "")
                                    ? "block"
                                    : "none"
                                : "none",
                        }}
                        children={<HelpIcon color={blue300} />}
                    />
                    {this.getTitle(question, idx)}
                </h3>
                {this.renderDialog(exit, question.help, question.title)}
            </div>
        );
    }
}

Title.propTypes = {
    question: PropTypes.object.isRequired,
};

export default Title;
