import React from 'react';
import {makeStyles, Typography, Tooltip, Fab} from '@material-ui/core';
import {Col, Row} from 'react-flexbox-grid';
import AddColumn from "./addColumn";
import RenderColumn from "./renderColumn";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles(theme => ({
    root: {
        padding: 20,
        minHeight: 294
    },
    addColumnButton: {
        marginRight: theme.spacing(2),
    }
}))

type ColumnsTabProps = {
    item: any
}

/**
 * Component to add columns to dataset
 * @author Jhoan Lopez <jhoan.lopez@ias.com.co>
 * @param item
 * @constructor
 */
const ColumnsTab: React.FC<ColumnsTabProps> = ({item}) => {
    const classes = useStyles()
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Row className={classes.root}>
            <Col xs={12}>
                <Row end="xs">
                    <Tooltip title="Agregar columna">
                        <Fab
                            size="small"
                            color="secondary"
                            onClick={handleClickOpen}
                            className={classes.addColumnButton}
                        >
                            <AddIcon/>
                        </Fab>
                    </Tooltip>
                </Row>
            </Col>
            <Col xs>
                <Row center="xs">
                    {item.columns.length > 0 ? item?.columns.map((column) => (
                            <RenderColumn column={column} dataset={item} key={column.id}/>
                        )) :
                        <Typography>No se han agregado columnas</Typography>
                    }
                </Row>
            </Col>
            <AddColumn open={open} handleClose={handleClose} datasetId={item.datasetId}/>
        </Row>
    );
};

export default ColumnsTab;
    