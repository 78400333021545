import PropTypes from "prop-types";
import React, { Component } from "react";
import RaisedButton from "material-ui/RaisedButton";
import Dialog from "material-ui/Dialog";
import TextField from "material-ui/TextField";
import FlatButton from "material-ui/FlatButton";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import is from "is_js";
import {
    sendUserResetBulk,
    RESET_PASSWORD_MASSIVE_IN_PROGRESS,
    RESET_PASSWORD_MASSIVE_REJECTED,
    RESET_PASSWORD_MASSIVE_FULFILLED,
    resetUserResetBulk,
} from "./ducks";
import { toggleSnackbar } from "../../actions/commons";

const validateEmail = (email) => {
    return is.email(email)
        ? undefined
        : "Ingrese una dirección de correo válida";
};

class ResetUsersAuth extends Component {
    state = {
        open: false,
        email: "",
    };

    static propTypes = {
        selected: PropTypes.array.isRequired,
        disabled: PropTypes.bool.isRequired,
        style: PropTypes.object,
    };

    UNSAFE_componentWillReceiveProps({ status }) {
        const { toggleSnackbar, resetUserResetBulk } = this.props;
        if (RESET_PASSWORD_MASSIVE_IN_PROGRESS === status) {
            toggleSnackbar(true, "Enviando petición...");
        } else if (RESET_PASSWORD_MASSIVE_REJECTED === status) {
            toggleSnackbar(true, "Error al enviar la petición");
            resetUserResetBulk();
        } else if (RESET_PASSWORD_MASSIVE_FULFILLED == status) {
            toggleSnackbar(
                true,
                "Exito al enviar la petición, se te notificará al correo electrónico."
            );
        }
    }

    handleOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        const {
            selected,
            disabled,
            style,
            status,
            sendUserResetBulk,
        } = this.props;
        const actions = [
            <FlatButton
                label="Cancelar"
                primary
                onClick={this.handleClose}
            />,
            <FlatButton
                label="Confirmar"
                primary
                keyboardFocused
                disabled={!is.email(this.state.email)}
                onClick={() => {
                    sendUserResetBulk(selected, this.state.email);
                    this.handleClose();
                }}
            />,
        ];

        return (
            <span>
                <RaisedButton
                    primary
                    label={`Generar contraseñas (${selected.length})`}
                    disabled={disabled}
                    style={style}
                    onClick={this.handleOpen}
                />
                <Dialog
                    title="Reiniciar contraseñas de manera masiva"
                    actions={actions}
                    modal={false}
                    open={this.state.open}
                    onRequestClose={this.handleClose}
                >
                    Ingrese la dirección de correo dónde desea que llegue el
                    archivo con la información de ingreso de los usuarios:
                    <br />
                    <TextField
                        hintText="user@dominio.com"
                        floatingLabelText="Correo electrónico"
                        onChange={(e, next) => {
                            this.setState({ email: next });
                        }}
                        value={this.state.email}
                    />
                    <br />
                </Dialog>
            </span>
        );
    }
}

function mapStateToProps({ employeesAssocProject }) {
    return {
        status: employeesAssocProject.statusBulkReset,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            sendUserResetBulk,
            toggleSnackbar,
            resetUserResetBulk,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetUsersAuth);
