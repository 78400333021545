import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import _ from 'lodash';
import Paper from 'material-ui/Paper';
import {Row, Col} from 'react-flexbox-grid';
import {blue700} from 'material-ui/styles/colors';
import Divider from 'material-ui/Divider';
import DocumentTitle  from 'react-document-title';
import SnackbarMessage from "../commons/snackBarMessage";
import {toggleSnackbar} from '../../actions/commons';
import { loadResultsReferees, cleanResultsReferees, inSearchResultsReferees } from './actions';
import LoadingPage from '../commons/loadingPage';
import FaqMessage from "../fields/FaqMessage/FaqMessage";
import About from "../fields/about/About";
import ButtonBack from "../fields/buttonBack/buttonBack";
import AuthComponent from "../AuthComponent";
import DataPerson from './components/dataPerson';
import DataCompany from './components/dataCompany';
import DataConsents from './components/dataConsents';
import DataQuestionsRaw from './components/dataQuestionsRaw';
import DataGroupersProcessed from './components/dataGroupersProcessed';

class componentResultsReferee extends Component {

    constructor(props) {
        super(props);
        this.renderResults = this.renderResults.bind(this);
    }

    UNSAFE_componentWillMount() {
        const { loadResultsReferees, cleanResultsReferees, inSearchResultsReferees, params } = this.props;
        cleanResultsReferees();
        inSearchResultsReferees(true);
        loadResultsReferees(_.get(params, 'project'), _.get(params, 'employee'));
    }

    // toggleSnackbar(true, "Ocurrió un error al guardar el comentario");
/* import ReactDOMServer from 'react-dom/server';
import wkhtmltopdf from 'wkhtmltopdf';

const FormComponent = React.createElement(Form, { data: req.body });
const html = ReactDOMServer.renderToStaticMarkup(FormComponent);

wkhtmltopdf(html, {
        userStyleSheet: 'public/form.css',
        printMediaType: true
    })
    .pipe(fs.createWriteStream('public/build/generated.pdf'));
*/
    renderResults(){
        const { result } = this.props;
        if(_.has(result, 'notFound')){
            return (
                <h2 style={{marginTop: 45, marginBottom: 40, textAlign: 'center', fontWeight: 200}}>
                    {_.get(result, 'notFound')}
                </h2>
            );
        } 
            return (
                <div>
                    <DataPerson result={result} />
                    <Divider style={{marginTop: 20}}/>
                    <DataCompany result={result} />
                    <Divider style={{marginTop: 20}}/>
                    <DataConsents result={result} />
                    <Divider style={{marginTop: 20}}/>
                    <DataQuestionsRaw result={result} />
                    <Divider style={{marginTop: 20}}/>
                    <DataGroupersProcessed result={result} />
                </div>
            );
        
    }

    render() {
        const { params, inSearch } = this.props;
        return (
            <DocumentTitle title='Resultado individual'>
                <Row style={{marginTop: 40}}>
                    <AuthComponent
                        component={
                            <Col xs={12} mdOffset={2} md={8}>
                                <Paper>
                                    <Row>
                                        <Col xs>
                                            <ButtonBack url={`/admin/projects/advance/${_.get(params, 'profile')}/${_.get(params, 'company')}/${_.get(params, 'project')}`}/>
                                            <div style={{width: '100%', background: blue700, paddingTop: 5, paddingBottom:5, color: "#FFF"}}>
                                                <h1 style={{textAlign: 'center', fontWeight: 400}}>
                                                    Resultado individual
                                                </h1>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row style={{paddingBottom: 100}}>
                                        <Col xs>
                                            {this.renderResults()}
                                        </Col>
                                    </Row>
                                </Paper>
                            </Col>
                        }
                        permission="backend/surveyResults:read"
                        type="url"
                    />
                    <SnackbarMessage/>
                    <FaqMessage />
                    <About />
                    <LoadingPage visible={inSearch} />
                </Row>
            </DocumentTitle>
        );
    }
}

function mapStateToProps({resultsSurveyReferee}) {
    return {
        inSearch: resultsSurveyReferee.get("inSearch"),
        result: resultsSurveyReferee.get("result")
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        loadResultsReferees,
        cleanResultsReferees,
        inSearchResultsReferees,
        toggleSnackbar
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(componentResultsReferee);
