import { SHOW_ABOUT_MESSAGE_INFO, HIDE_ABOUT_MESSAGE_INFO } from '../constants/index';

export function showAboutAppMessage() {

    return {
        type: SHOW_ABOUT_MESSAGE_INFO
    }
}

export function hideAboutAppMessage() {

    return {
        type: HIDE_ABOUT_MESSAGE_INFO
    }
}
