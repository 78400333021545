import React, { Component } from "react";
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from "material-ui/Table";
import { Row, Col } from "react-flexbox-grid";
import Paper from "material-ui/Paper";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import IconButton from "material-ui/IconButton";
import ContentCopyIcon from "material-ui/svg-icons/content/content-copy";
import VisibilityIcon from "material-ui/svg-icons/action/visibility";
import FlatButton from "material-ui/FlatButton";
import { blue700, blue300 } from "material-ui/styles/colors";
import _ from "lodash";
import { FetchListMaster } from "../../actions/grid/listmaster_grid_form";
import { deleteMasterList } from "../../actions/listMaster_form";
import { toggleSnackbar } from "../../actions/commons";
import { LBLLISTS } from "../../constants/labels";
import FloatingActionButtonTooltip from "../fields/FloatingActionButtonTooltip/index.tsx";
import DeleteEntityModal from "../deleteEntityModal/component";
import LoadingPage from "../commons/loadingPage";
import AuthComponent from "../AuthComponent";
import TablePagination from "../TablePagination/tablePagination";

const stylePaper = {
  marginTop: 20,
  marginBottom: 20,
};

const customContentStyle = {
  maxWidth: 600,
  minWidth: 300,
  textAlign: "justify",
};

class ListMasterGrid extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const body = [
      {
        field: "alias",
        title: "Código / Nombre",
        colspan: 1,
        filter: true,
      },
      {
        field: "description",
        title: "Descripción",
        colspan: 3,
      },
      {
        actions: true,
        title: "Operaciones",
        colspan: 1,
      },
    ];
    return (
      <Row>
        <AuthComponent
          component={
            <Col mdOffset={2} md={8} xs={12}>
              <Paper style={stylePaper}>
                <Row>
                  <Col xs>
                    <div
                      style={{
                        width: "100%",
                        background: blue700,
                        paddingTop: 5,
                        paddingBottom: 5,
                        color: "#FFF",
                      }}
                    >
                      <h1 style={{ textAlign: "center", fontWeight: 400 }}>
                        {LBLLISTS.titleGrid}
                      </h1>
                      <AuthComponent
                        component={
                            <FloatingActionButtonTooltip
                              textTooltip={LBLLISTS.tooltips.btnCreate}
                              to="/admin/lists/create"
                              component={Link}
                            />
                        }
                        permission="backend/answer:write"
                        type="component"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs>
                    <TablePagination
                      name="listmaster"
                      URL="/listmaster"
                      version={1}
                      body={body}
                    />
                  </Col>
                </Row>
              </Paper>
            </Col>
          }
          permission="backend/answer:read"
          type="url"
        />
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return { listmaster: state.listmaster.all };
}

export default connect(mapStateToProps, {
  FetchListMaster,
  deleteMasterList,
  toggleSnackbar,
})(ListMasterGrid);
