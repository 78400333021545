import React, {useState} from "react";
import {Row, Col} from "react-flexbox-grid";
import PropTypes from "prop-types";
import {TextField, RaisedButton, FlatButton} from "material-ui";
import Container from "./container";
import AdaptiveSelector from "../../components/users/create/adaptiveSelector";
import operations from "../../containers/users/http";

const styles = {
    loginBox: {
        marginTop: 80,
    },
    paper: {
        paddingBottom: 25,
        paddingLeft: 0,
    },
    formRow: {
        marginTop: 40,
    },
    IDField: {
        margin: "0 auto",
        textAlign: "left",
        minWidth: "50%",
    },
    link: {
        color: "blue",
    },
    fieldContent: {
        textAlign: "center",
    },
    senCodeButton: {
        textAlign: "center",
        marginTop: 36,
        marginBottom: 20,
        minWidth: "50%",
    },
};

const initialStateForm = {
    username: "",
    identificationType: "",
    errors: {},
};

const ForgotPasswordForm = ({onSubmit, isLoading, goToLogin}) => {
    const [formState, setFormState] = useState(initialStateForm);

    const validate = () => {
        const {username, identificationType} = formState;
        let isValid = true;
        const errors = {};

        if (!username || !identificationType) {
            isValid = false;
            errors.username = "Ingresa número de identificación";
            errors.identificationType = true;
        }
        setFormState(() => ({...formState, errors}));

        return isValid;
    };

    const forgotPassword = async (e) => {
        e.preventDefault();
        const {identificationType, username: user} = formState;
        const username = `${identificationType}_${user}`

        if (!validate()) {
            return;
        }
        onSubmit(username);
    };

    const onChange = (e) => {
        e.persist();
        setFormState(() => ({...formState, [e.target.name]: e.target.value}));
    };

    return (
        <Container>
            <form onSubmit={forgotPassword}>
                <Row style={styles.formRow}>
                    <Col xs style={styles.fieldContent}>
                        <AdaptiveSelector label="Tipo de documento" name="identificationType"
                                          value={formState.identificationType}
                                          handleChange={onChange}
                                          data={operations.getDocumentTypes}
                                          error={Boolean(formState.errors.identificationType)}

                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs style={styles.fieldContent}>
                        <TextField
                            maxLength="15"
                            floatingLabelText="Nro de identificación"
                            style={styles.IDField}
                            name="username"
                            onChange={onChange}
                            errorText={formState.errors.username}
                            value={formState.username}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs style={styles.fieldContent}>
                        <RaisedButton
                            label="Enviar código"
                            secondary
                            disabled={isLoading}
                            type="submit"
                            style={styles.senCodeButton}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs style={{textAlign: "center"}}>
                        <FlatButton
                            label="Ir a iniciar sesión"
                            secondary
                            onClick={goToLogin}
                        />
                    </Col>
                </Row>
            </form>
        </Container>
    );
};

ForgotPasswordForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    goToLogin: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

export default ForgotPasswordForm;
