import PropTypes from "prop-types";
import React, { Component } from "react";
import MenuItem from "material-ui/MenuItem";
import _ from "lodash";
import { connect } from "react-redux";
import Title from "./Title";
import { LBLSURVEYS } from "../../../constants/labels";
import SelectFieldFormat from "../../fields/SelectFieldFormat/SelectFieldFormat";

const styleWidthContent = {
    minWidth: 240,
};

class QuestionSelect extends Component {
    constructor(props) {
        super(props);
        this._onChange = this._onChange.bind(this);
    }

    _onChange(e, index, value) {
        this.props._handlerUpdateValue(e, value);
    }

    renderOptions(options, itemList) {
        let array;
        if (_.isEqual(itemList, "lowerCase")) {
            array = LBLSURVEYS.arrays.lowercase;
        } else if (_.isEqual(itemList, "upperCase")) {
            array = LBLSURVEYS.arrays.uppercase;
        } else {
            array = [];
        }
        return _.map(options, (option, position) => {
            const enumeration =
                _.size(array) !== 0
                    ? _.size(array) <= position
                        ? ""
                        : `${array[position]  }.`
                    : _.isEqual(itemList, "numeric")
                    ? `${position + 1  }.`
                    : "";
            return (
                <MenuItem
                    value={option._id}
                    key={option._id}
                    primaryText={`${enumeration} ${option.value}`}
                />
            );
        });
    }

    render() {
        const { question, index, valueQuestion } = this.props;
        const itemList = _.has(question, "itemList")
            ? _.get(question, "itemList")
            : "none";
        return (
            <div style={styleWidthContent}>
                <div style={{ marginBottom: 10 }}>
                    <Title
                        question={
                            _.has(question, "fieldName")
                                ? question
                                : _.get(question, "_id")
                        }
                        index={index}
                    />
                </div>
                <SelectFieldFormat
                    config={{
                        floatingLabelText: "Seleccione respuesta",
                        onChange: this._onChange,
                        autoWidth: true,
                        fullWidth: true,
                        value: valueQuestion,
                    }}
                >
                    {this.renderOptions(
                        question.valueSource.list.values,
                        itemList
                    )}
                </SelectFieldFormat>
            </div>
        );
    }
}

QuestionSelect.propTypes = {
    question: PropTypes.object.isRequired,
};

function mapStateToProps({ questionsSurvey }, ownerProps) {
    return {
        valueQuestion: _.get(
            questionsSurvey.get("answered").toObject(),
            _.get(ownerProps, "idQuestion")
        ),
    };
}

export default connect(mapStateToProps)(QuestionSelect);
