
export const STATE_MODAL_MESSAGE = "STATE_MODAL_MESSAGE";

export function showModalResult(show, title, message) {
    return {
        type: STATE_MODAL_MESSAGE,
        show,
        title,
        message
    }
}
