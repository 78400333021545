import PropTypes from "prop-types";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { List, ListItem, makeSelectable } from "material-ui/List";
import { Row, Col } from "react-flexbox-grid";
import { Tabs, Tab } from "material-ui/Tabs";
import Paper from "material-ui/Paper";
import { Step, Stepper, StepLabel, StepContent } from "material-ui/Stepper";
import RaisedButton from "material-ui/RaisedButton";
import FlatButton from "material-ui/FlatButton";
import MetadataItem from "./MetadataItem";
import ButtonBack from "../fields/buttonBack/buttonBack";
import { generateUUID } from "../../lib/util";
import { loadProjectData, updateMetadata } from "./MetadataDucks";

const getTitle = function (key) {
    switch (key) {
        case "question":
            return "Preguntas";
        case "sum":
            return "Sumatorias";
        case "freq":
            return "Frecuencias";
        default:
            return key;
    }
};

let SelectableList = makeSelectable(List);

function wrapState(ComposedComponent) {
    return class SelectableList extends Component {
        static propTypes = {
            children: PropTypes.node.isRequired,
            defaultValue: PropTypes.number.isRequired,
        };

        UNSAFE_componentWillMount() {
            this.setState({
                selectedIndex: this.props.defaultValue,
            });
        }

        handleRequestChange = (event, index) => {
            this.setState({
                selectedIndex: index,
            });
        };

        render() {
            return (
                <ComposedComponent
                    value={this.state.selectedIndex}
                    onChange={this.handleRequestChange}
                >
                    {this.props.children}
                </ComposedComponent>
            );
        }
    };
}

SelectableList = wrapState(SelectableList);

const mapItems = (projectId, value, index) => {
    const MetadataItemComponent = MetadataItem(generateUUID(), value);
    return (
        <MetadataItemComponent
            key={index}
            value={value}
            projectId={projectId}
        />
    );
};

const mapTabs = (projectId, list, index) => {
    const key = list[0];
    const values = list[1];
    const mappedItems = _.map(values, mapItems.bind(null, projectId));
    return (
        <Tab label={getTitle(key)}>
            <div>
                <List
                    key={`list-metadata-${index}`}
                    id="List"
                    style={{ padding: 0 }}
                >
                    {mappedItems}
                </List>
            </div>
        </Tab>
    );
};

class MetadataEditorComponent extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        finished: false,
        stepIndex: 0,
        selectedIndexS1: 0,
    };

    handleRequestChange = (index, key) => {
        this.setState({
            [key]: index,
        });
    };

    handleNext = () => {
        const { stepIndex } = this.state;
        this.setState({
            stepIndex: stepIndex + 1,
            finished: stepIndex >= 3,
        });
    };

    handlePrev = () => {
        const { stepIndex } = this.state;
        if (stepIndex > 0) {
            this.setState({ stepIndex: stepIndex - 1 });
        }
    };

    renderStepActions(step) {
        const { stepIndex } = this.state;

        return (
            <div style={{ margin: "12px 0" }}>
                <RaisedButton
                    label={stepIndex === 3 ? "Finalizar" : "Siguiente"}
                    disableTouchRipple
                    disableFocusRipple
                    primary
                    onClick={this.handleNext}
                    style={{ marginRight: 12 }}
                />
                {step > 0 && (
                    <FlatButton
                        label="Atrás"
                        disabled={stepIndex === 0}
                        disableTouchRipple
                        disableFocusRipple
                        onClick={this.handlePrev}
                    />
                )}
            </div>
        );
    }

    step1Content = () => {
        return (
            <SelectableList defaultValue={-1}>
                <ListItem
                    value={1}
                    primaryText="Respuestas Básicas"
                    onClick={(e, index) => {
                        this.handleRequestChange(1, "selectedIndexS1");
                    }}
                />
                <ListItem
                    value={2}
                    primaryText="Indicadores"
                    onClick={(e, index) => {
                        this.handleRequestChange(2, "selectedIndexS1");
                    }}
                />
            </SelectableList>
        );
    };

    step2Content = () => {
        const { selectedIndexS1 } = this.state;
        const mappedItems = _.chain(this.props.metadata)
            .get("records", [])
            .map((item, index) => _.set(item, "indexElement", index))
            .filter((item) => {
                if (selectedIndexS1 === 1) {
                    return item.type === "question";
                } if (selectedIndexS1 === 2) {
                    return item.type === "sum" || item.type === "freq";
                } 
                    return false;
                
            })
            .sortBy("weight")
            .map((item) => {
                return (
                    <ListItem
                        key={item.indexElement}
                        value={item.indexElement}
                        primaryText={item.name}
                        onClick={() => {
                            this.handleRequestChange(
                                item.indexElement,
                                "selectedIndexS2"
                            );
                        }}
                    />
                );
            })
            .value();
        return <SelectableList defaultValue={-1}>{mappedItems}</SelectableList>;
    };

    step3Content = () => {
        const { selectedIndexS2 } = this.state;
        const element = _.get(
            this,
            `props.metadata.records.${selectedIndexS2}`,
            {}
        );
        const subItems = _.get(element, "subItems", []);
        const type = _.get(element, "type");
        let elements = [];
        if (type === "freq") {
            elements = _.chain(subItems)
                .uniqBy("id")
                .map((item) => ({
                    id: item.id,
                    item: item.id,
                    value: item.id,
                }))
                .map((it, idx) => _.set(it, "indexElement", idx))
                .value();
        } else {
            elements = _.chain(subItems)
                .map((it, idx) => _.set(it, "indexElement", idx))
                .value();
        }

        const subItemsList = _.map(elements, (item) => {
            return (
                <ListItem
                    key={item.indexElement}
                    value={item.item}
                    primaryText={item.item}
                    onClick={() => {
                        this.handleRequestChange(item.item, "selectedIndexS3");
                    }}
                />
            );
        });

        return (
            <SelectableList defaultValue={-1}>{subItemsList}</SelectableList>
        );
    };

    step4Content = () => {
        const {
            params: { projectId },
            updateMetadata,
        } = this.props;
        const { selectedIndexS3, selectedIndexS2 } = this.state;
        const item = _.get(
            this,
            `props.metadata.records.${selectedIndexS2}`,
            {}
        );
        const subItems = _.get(item, `subItems`, []);
        const element = _.find(subItems, (item) => {
            return _.isEqual(item.id, selectedIndexS3);
        });
        const elementWithCoi = _.assign({}, element, {
            coi: _.get(item, `coi.${selectedIndexS3}`, []),
            item: selectedIndexS3,
        });
        const MetadataItemComponent = MetadataItem(
            elementWithCoi,
            generateUUID()
        );
        const updateMetadataFunction = (data) => {
            updateMetadata(projectId, item.id, selectedIndexS3, data.coi);
        };
        return (
            <MetadataItemComponent
                handlePrev={this.handlePrev}
                handleNext={updateMetadataFunction}
            />
        );
    };

    componentDidMount() {
        const {
            params: { projectId },
            loadProjectData,
        } = this.props;
        loadProjectData(projectId);
    }

    render() {
        const {
            metadata,
            project,
            params: { projectId, companyId },
            updateMetadata,
        } = this.props;
        const itemsGrouped = "";

        const { finished, stepIndex } = this.state;
        return (
            <Row>
                <ButtonBack url={`/admin/companies/info/${companyId}`} />
                <Col xs={12} mdOffset={2} md={8}>
                    <Paper style={{ marginTop: 20, marginBottom: 20 }}>
                        <Stepper activeStep={stepIndex} orientation="vertical">
                            <Step>
                                <StepLabel>Tipo de elemento</StepLabel>
                                <StepContent>
                                    {this.step1Content()}
                                    {this.renderStepActions(0)}
                                </StepContent>
                            </Step>
                            <Step>
                                <StepLabel>Elemento</StepLabel>
                                <StepContent>
                                    {this.step2Content()}
                                    {this.renderStepActions(1)}
                                </StepContent>
                            </Step>
                            <Step>
                                <StepLabel>Sub Elemento</StepLabel>
                                <StepContent>
                                    {this.step3Content()}
                                    {this.renderStepActions(2)}
                                </StepContent>
                            </Step>
                            <Step>
                                <StepLabel>Condiciones</StepLabel>
                                <StepContent>{this.step4Content()}</StepContent>
                            </Step>
                        </Stepper>
                    </Paper>
                </Col>
            </Row>
        );
    }
}

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            loadProjectData,
            updateMetadata,
        },
        dispatch
    );

const mapStateToProps = ({ metadataProject }) => {
    return {
        metadata: metadataProject.get("metadata"),
        project: metadataProject.get("project"),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MetadataEditorComponent);
