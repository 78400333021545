import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Row, Col, Grid} from 'react-flexbox-grid';
import AppBar from './menu_bar';

class EmployeeDashboard extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <Grid fluid>
                    <Row>
                        <Col xs>
                            <AppBar/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs>
                            {this.props.children}
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

function mapStateToProps({login}) {
    return {
        profile: login.get('profile')
    }
}

export default connect(mapStateToProps, null)(EmployeeDashboard);
