export const API_URL = `${process.env.CONFIG_API}/api/v1`;
export const API_URL_V2 = `${process.env.CONFIG_API}/api/v2`;
export const URL_LOGIN = `${process.env.CONFIG_HOST_URL}/login`;
export const API_CGR_AUTH = `${process.env.CONFIG_API}/api/v3/users`;

export const spanishLocale = "es-ES";

/**
 * Companies actions
 */

export const POST_COMPANY = "POST_COMPANY";
export const DELETE_COMPANY = "DELETE_COMPANY";
export const PUT_COMPANY = "PUT_COMPANY";

/**
 * Profiles actions
 */

export const POST_PROFILE = "POST_PROFILE";
export const GET_PROFILES = "GET_PROFILES";
export const GET_PROFILE = "GET_PROFILE";
export const GET_RESOURCES = "GET_RESOURCES";
export const ADD_PERMISSION = "ADD_PERMISSION";
export const CLEAR_PROFILE = "CLEAR_PROFILE";

/**
 * Employees actions
 */

export const POST_EMPLOYEE = "POST_EMPLOYEE";
export const GET_EMPLOYEE = "GET_EMPLOYEE";
export const DELETE_EMPLOYEE = "DELETE_EMPLOYEE";

/**
 * Surveys actions
 */

export const POST_SURVEY = "POST_SURVEY";
export const POST_METRICS_SECTION_SURVEY = "POST_METRICS_SECTION_SURVEY";
export const POST_CONDITIONS_GROUPER_QUESTIONS =
  "POST_CONDITIONS_GROUPER_QUESTIONS";
export const GET_GROUPER_QUESTION_METRICS = "GET_GROUPER_QUESTION_METRICS";
export const CLEAN_GROUPER_QUESTION_METRICS = "CLEAN_GROUPER_QUESTION_METRICS";
export const DELETE_SURVEY = "DELETE_SURVEY";
export const LOCK_SURVEY = "LOCK_SURVEY";
export const CLONE_SURVEY = "CLONE_SURVEY";

/**
 * Consents actions
 */

export const POST_CONSENT = "POST_CONSENT";
export const LOAD_ALL_CONSENT = "LOAD_ALL_CONSENT";
export const LOAD_APPROVAL_CONSENTS = "LOAD_APPROVAL_CONSENTS";
export const POST_APPROVAL_CONSENTS = "POST_APPROVAL_CONSENTS";
export const DELETE_CONSENT = "DELETE_CONSENT";
export const LOADING_QUERIES = "LOADING_QUERIES";

/**
 * List actions
 */

export const POST_LIST = "POST_LIST";

/**
 * ListMaster actions
 */

export const POST_LIST_MASTER = "POST_LIST_MASTER";
export const GET_LISTMASTER = "GET_LISTMASTER";
export const GET_LISTMASTER_PARAMS = "GET_LISTMASTER_PARAMS";
export const DELETE_LISTMASTER = "DELETE_LISTMASTER";

/**
 * Questions actions
 */

export const POST_QUESTION = "POST_QUESTION";
export const GET_QUESTION = "GET_QUESTION";
export const GET_QUESTIONS_GROUP_SURVEYS = "GET_QUESTIONS_GROUP_SURVEYS";
export const DELETE_QUESTION = "DELETE_QUESTION";

/**
 * Questions actions
 */

export const POST_REFEREE = "POST_REFEREE";
export const DELETE_REFEREE = "DELETE_REFEREE";

/**
 * Sections actions
 */

export const POST_SECTION = "POST_SECTION";
export const DELETE_SECTION = "DELETE_SECTION";
export const GET_QUESTIONS_SELECTORS_SECTIONS =
  "GET_QUESTIONS_SELECTORS_SECTIONS";
export const GET_SECTION_INFO = "GET_SECTION_INFO";
export const GET_ALL_QUESTIONS_SURVEY = "GET_ALL_QUESTIONS_SURVEY";
export const CLEAN_SECTION_INFO = "CLEAN_SECTION_INFO";
export const GET_SECTIONS_GRID = "GET_SECTIONS_GRID";

/**
 * Projects actions
 */

export const POST_PROJECT = "POST_PROJECT";
export const POST_RANKING_PROJECT = "POST_RANKING_PROJECT";
export const LOAD_SURVEYS_PROJECT = "LOAD_SURVEYS_PROJECT";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const CLEAN_PROJECT = "CLEAN_PROJECT";
export const POST_PROJECT_REFEREES = "POST_PROJECT_REFEREES";
export const PUT_PROJECT_COLORS = "PUT_PROJECT_COLORS";
export const PUT_PROJECT_FILTER = "PUT_PROJECT_FILTER";
export const POST_PROJECT_EMPLOYEES = "POST_PROJECT_EMPLOYEES";
export const GET_ADVANCE_PROJECT = "GET_ADVANCE_PROJECT";
export const DISSASOCIATE_EMPLOYEE_PROJECT = "DISSASOCIATE_EMPLOYEE_PROJECT";
export const DELETE_CONFIG_DAY_PROJECT = "DELETE_CONFIG_DAY_PROJECT";

/**
 * FAQ actions
 */

export const POST_FAQ = "POST_FAQ";
export const DELETE_FAQ = "DELETE_FAQ";
export const SHOW_FAQ = "SHOW_FAQ";
export const SET_IN_SEARCH_FAQS = "SET_IN_SEARCH_FAQS";

/**
 * Inactive project actions
 */

export const GET_TIME_INACTIVE = "GET_TIME_INACTIVE";
export const POST_TIME_INACTIVE = "POST_TIME_INACTIVE";

/**
 * Projects actions
 */

export const POST_CONFIG_PROJECT = "POST_CONFIG_PROJECT";

/**
 * Download and upload file
 */
export const DOWNLOAD_FILE = "DOWNLOAD_FILE";
export const UPLOAD_FILE = "UPLOAD_FILE";

/**
 * Conditionals actions
 */
export const GET_CONDITIONALS_TYPE = "GET_CONDITIONALS_TYPE";
export const POST_CONDITIONAL = "POST_CONDITIONAL";
export const DELETE_CONDITIONAL = "DELETE_CONDITIONAL";

/**
 * Template actions
 */
export const GET_TEMPLATE = "GET_TEMPLATE";
export const POST_TEMPLATE = "POST_TEMPLATE";
export const DELETE_TEMPLATE = "DELETE_TEMPLATE";
export const TEMPLATE_GRID = "TEMPLATE_GRID";

/**
 * Template actions
 */
export const GET_DOCUMENTS_TYPES = "GET_DOCUMENTS_TYPES";

/**
 * Report result employees
 */
export const REPORT_EMPLOYEE_REFEREE = "REPORT_EMPLOYEE_REFEREE";

/**
 * About App
 */
export const SHOW_ABOUT_MESSAGE_INFO = "SHOW_ABOUT_MESSAGE_INFO";
export const HIDE_ABOUT_MESSAGE_INFO = "HIDE_ABOUT_MESSAGE_INFO";

export const PEGASUS_URL = '';

/**
 * Style App
 */

export const STYLE_GLOBAL = {
  style: {
    margin: 20,
    float: "right",
  },
  stylePaper: {
    marginTop: 20,
  },
  stylePopover: {
    padding: 20,
  },
};
