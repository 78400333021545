import Immutable from 'immutable';
import _ from 'lodash';
import {
    GENERIC_GET_RECORDS,
    GENERIC_ADD_RECORD,
    GENERIC_CLEAN_TABLE,
    GENERIC_REMOVE_RECORD,
    GENERIC_REMOVE_MASSIVE_RECORD,
    GENERIC_CHANGE_FIELD_RECORD,
    GENERIC_LOCKED_RECORD,
    GENERIC_CHANGE_STATUS_RECORD,
    GENERIC_RESET_ORDER_RECORDS,
    GENERIC_SET_ARRAY_FIELDS_FILTER,
    GENERIC_SET_FIELD_SELECTION,
    GENERIC_CLEAN_SELECTED,
    GENERIC_ADD_SELECTED,
    GENERIC_REMOVE_SELECTED,
    GENERIC_ADD_MASSIVE_SELECTED,
    GENERIC_REMOVE_MASSIVE_SELECTED,
    GENERIC_SET_SELECTED,
    GENERIC_SET_STATUS_FILTER,
    GENERIC_SET_IN_SEARCH_RECORDS,
    GENERIC_CHANGE_LIMIT_RECORDS
} from './constants';

const initialState = Immutable.Map({
    default: getDefaultState(),
    companies: getDefaultState(),
    projects: getDefaultState(),
    projectsARL: getDefaultState()
});

export default function (state = initialState, action) {
    switch (action.type) {
        case GENERIC_GET_RECORDS:
            return returnState(state,
                      getRecords(action, state.get(_.get(action, 'meta.store', 'default'))),
                      _.get(action, 'meta.store', 'default')
                  );
        case GENERIC_ADD_RECORD:
            return returnState(state,
                      getAddRecordInList(action, state.get(_.get(action, 'meta.store', 'default'))),
                      _.get(action, 'meta.store', 'default')
                  );
        case GENERIC_CLEAN_TABLE:
            return returnState(state,
                      getCleanRecords(action, state.get(_.get(action, 'meta.store', 'default'))),
                      _.get(action, 'meta.store', 'default')
                  );
        case GENERIC_REMOVE_RECORD:
            return returnState(state,
                      getRemoveRecord(action, state.get(_.get(action, 'meta.store', 'default'))),
                      _.get(action, 'meta.store', 'default')
                  );
        case GENERIC_REMOVE_MASSIVE_RECORD:
            return returnState(state,
                      getRemoveMassiveRecords(action, state.get(_.get(action, 'meta.store', 'default'))),
                      _.get(action, 'meta.store', 'default')
                  );
        case GENERIC_CHANGE_FIELD_RECORD:
            return returnState(state,
                      getChangeFieldRecord(action, state.get(_.get(action, 'meta.store', 'default'))),
                      _.get(action, 'meta.store', 'default')
                  );
        case GENERIC_LOCKED_RECORD:
            return returnState(state,
                      getLockedRecord(action, state.get(_.get(action, 'meta.store', 'default'))),
                      _.get(action, 'meta.store', 'default')
                  );
        case GENERIC_CHANGE_STATUS_RECORD:
            return returnState(state,
                      getChangeStatusRecord(action, state.get(_.get(action, 'meta.store', 'default'))),
                      _.get(action, 'meta.store', 'default')
                  );
        case GENERIC_RESET_ORDER_RECORDS:
            return returnState(state,
                      getResetOrderRecords(action, state.get(_.get(action, 'meta.store', 'default'))),
                      _.get(action, 'meta.store', 'default')
                  );
        case GENERIC_SET_ARRAY_FIELDS_FILTER:
            return returnState(state,
                      getSetArrayFieldsFilter(action, state.get(_.get(action, 'meta.store', 'default'))),
                      _.get(action, 'meta.store', 'default')
                  );
        case GENERIC_SET_FIELD_SELECTION:
            return returnState(state,
                      getSetFieldSelection(action, state.get(_.get(action, 'meta.store', 'default'))),
                      _.get(action, 'meta.store', 'default')
                  );
        case GENERIC_CLEAN_SELECTED:
            return returnState(state,
                      getCleanSelection(action, state.get(_.get(action, 'meta.store', 'default'))),
                      _.get(action, 'meta.store', 'default')
                  );
        case GENERIC_ADD_SELECTED:
            return returnState(state,
                      getAddSelected(action, state.get(_.get(action, 'meta.store', 'default'))),
                      _.get(action, 'meta.store', 'default')
                  );
        case GENERIC_REMOVE_SELECTED:
            return returnState(state,
                      getRemoveSelected(action, state.get(_.get(action, 'meta.store', 'default'))),
                      _.get(action, 'meta.store', 'default')
                  );
        case GENERIC_ADD_MASSIVE_SELECTED:
            return returnState(state,
                      getAddMassiveSelected(action, state.get(_.get(action, 'meta.store', 'default'))),
                      _.get(action, 'meta.store', 'default')
                  );
        case GENERIC_REMOVE_MASSIVE_SELECTED:
            return returnState(state,
                      getRemoveMassiveSelected(action, state.get(_.get(action, 'meta.store', 'default'))),
                      _.get(action, 'meta.store', 'default')
                  );
        case GENERIC_SET_SELECTED:
            return returnState(state,
                      getSetSelected(action, state.get(_.get(action, 'meta.store', 'default'))),
                      _.get(action, 'meta.store', 'default')
                  );
        case GENERIC_SET_STATUS_FILTER:
            return returnState(state,
                      getSetStatusFilter(action, state.get(_.get(action, 'meta.store', 'default'))),
                      _.get(action, 'meta.store', 'default')
                  );
        case GENERIC_SET_IN_SEARCH_RECORDS:
            return returnState(state,
                      getSetInSearchRecords(action, state.get(_.get(action, 'meta.store', 'default'))),
                      _.get(action, 'meta.store', 'default')
                  );
        case GENERIC_CHANGE_LIMIT_RECORDS:
            return returnState(state,
                      getchangeLimitRecords(action, state.get(_.get(action, 'meta.store', 'default'))),
                      _.get(action, 'meta.store', 'default')
                  );
        default:
            return state;
    }
}

function getRecords(action, stateTable){
    const arrayRecord = _.get(action, 'payload.data.data', _.get(action, 'payload.data', []));
    const listSelected = stateTable.get("selected");
    const array = _.map(arrayRecord, item => {
                      return _.findIndex(listSelected, function (record) {
                                  return _.isEqual(_.get(item, stateTable.get("fieldSelection")), record);
                              }) !== -1 ? _.set(item, 'selected', true) : item;
                  });
    const total = _.get(action, 'payload.data.numFound', _.get(action, 'payload.numFound', _.size(array)));
    return stateTable.withMutations(function(stateTable) {
                stateTable.set("records", array);
                stateTable.set("skip", _.get(action, 'meta.skip', 0));
                stateTable.set("total", total);
                stateTable.set("orderName", _.get(action, 'meta.orderName', 'null'));
                stateTable.set("orderType", _.get(action, 'meta.orderType', 0));
                stateTable.set("textFilter", _.get(action, 'meta.textFilter', ""));
                stateTable.set("selected", listSelected);
                stateTable.set("inSearch", false);
            });
}

function getAddRecordInList(action, stateTable){
    const arrayAddRecord = stateTable.get('records');
    if(!_.isUndefined(_.get(action, 'meta.record')) && !_.isNull(_.get(action, 'meta.record'))){
        arrayAddRecord.push(_.get(action, 'meta.record'));
    }
    return stateTable.set('records', arrayAddRecord);
}

function getCleanRecords(action, stateTable){
    return stateTable.withMutations(function(stateTable) {
                stateTable.set("records", Immutable.List());
                stateTable.set("skip", 0);
                stateTable.set("total", 0);
                stateTable.set("orderName", 'null');
                stateTable.set("orderType", 0);
                stateTable.set("textFilter", "");
                stateTable.set("arrayFields", []);
                // stateTable.set("selected", []);
                stateTable.set("status", null);
                stateTable.set("inSearch", false);
                stateTable.set("limit", 25);
            });
}

function getRemoveRecord(action, stateTable){
    const listRecordDeleted = stateTable.get('records').filter( item => {
                                  return !_.isEqual(_.get(item, _.get(action, 'meta.field', '_id')), _.get(action, 'meta.id'));
                              });
    const listSelectedDeleted = stateTable.get('selected').filter( item => {
                                    return !_.isEqual(item, _.get(action, 'meta.id'));
                                });
    return stateTable.withMutations(function(stateTable) {
                stateTable.set("records", listRecordDeleted);
                stateTable.set("total", stateTable.get('total') - 1);
                stateTable.set("selected", listSelectedDeleted);
            });
}

function getRemoveMassiveRecords(action, stateTable){
    const listRecordMassiveDeleted = stateTable.get('records').filter( item => {
                                          return _.findIndex(_.get(action, 'meta.arrayId'), function (idRecord) {
                                                      return _.isEqual(_.get(item, stateTable.get("fieldSelection")), idRecord);
                                                  }) === -1;
                                      });
    const listSelectedMassiveDeleted = stateTable.get('selected').filter( item => {
                                    return _.findIndex(_.get(action, 'meta.arrayId'), function (idRecord) {
                                                return _.isEqual(item, idRecord);
                                            }) === -1;
                                });
    return stateTable.withMutations(function(stateTable) {
                stateTable.set("records", listRecordMassiveDeleted);
                stateTable.set("total", stateTable.get('total') - _.size(_.get(action, 'meta.arrayId')));
                stateTable.set("selected", listSelectedMassiveDeleted);
            });
}

function getChangeFieldRecord(action, stateTable){
    const listRecordChange = stateTable.get('records').map( item => {
                                return _.isEqual(_.get(item, '_id'), _.get(action, 'meta.id'))
                                        ? changeValueRecord(item, _.get(action, 'meta.field'), _.get(action, 'meta.value'))
                                        : item;
                            });
    return stateTable.set('records', listRecordChange);
}

function getLockedRecord(action, stateTable){
    const listRecordLocked = stateTable.get('records').map( item => {
                                  return _.isEqual(_.get(item, '_id'), _.get(action, 'meta.id'))
                                          ? _.set(item, 'locked', true)
                                          : item;
                              });
    return stateTable.set('records', listRecordLocked);
}

function getChangeStatusRecord(action, stateTable){
    const listRecordStatus = stateTable.get('records').map( item => {
                                  return _.indexOf(_.get(action, 'meta.ids', []), _.get(item, '_id')) >= 0
                                          ? _.set(item, 'status', _.get(action, 'meta.value', ""))
                                          : item;
                              });
    return stateTable.set('records', listRecordStatus);
}

function getResetOrderRecords(action, stateTable){
    return stateTable.withMutations(function(stateTable) {
                stateTable.set("orderName", 'null');
                stateTable.set("orderType", 0);
            });
}

function getSetArrayFieldsFilter(action, stateTable){
    return stateTable.set("arrayFields", _.get(action, 'meta.arrayFields', []));
}

function getSetFieldSelection(action, stateTable){
    return stateTable.set("fieldSelection", _.get(action, 'meta.fieldSelection', null));
}

function getCleanSelection(action, stateTable){
    const arrayRecordCleanSelected = stateTable.get('records').map( item => {
                                          return _.set(item, 'selected', false);
                                      });
    return stateTable.withMutations(function(stateT) {
                stateT.set("records", arrayRecordCleanSelected);
                stateT.set('selected', []);
            });
}

function getAddSelected(action, stateTable){
    const listSelectedAdd = _.concat(stateTable.get('selected'), _.get(action, 'meta.id'));
    const listRecordAddSelected = stateTable.get('records').map( item => {
                                      return _.isEqual(_.get(item, stateTable.get("fieldSelection")), _.get(action, 'meta.id'))
                                              ? _.set(item, 'selected', true)
                                              : item;
                                  });
    return stateTable.withMutations(function(stateTable) {
                stateTable.set("records", listRecordAddSelected);
                stateTable.set("selected", listSelectedAdd);
            });
}

function getRemoveSelected(action, stateTable){
    const listSelectedRemove = stateTable.get('selected').filter( item => {
                                  return !_.isEqual(item, _.get(action, 'meta.id'));
                              });
    const listRecordRemoveSelected = stateTable.get('records').map( item => {
                                          return _.isEqual(_.get(item, stateTable.get("fieldSelection")), _.get(action, 'meta.id'))
                                                  ? _.set(item, 'selected', false)
                                                  : item;
                                      });
    return stateTable.withMutations(function(stateTable) {
                stateTable.set("records", listRecordRemoveSelected);
                stateTable.set("selected", listSelectedRemove);
            });
}

function getAddMassiveSelected(action, stateTable){
    const listSelectedMassiveAdd = _.concat(stateTable.get('selected'), _.get(action, 'meta.array'));
    const listRecordAddMassiveSelected = stateTable.get('records').map( item => {
                                              return _.set(item, 'selected', true);
                                          });
    return stateTable.withMutations(function(stateTable) {
                stateTable.set("records", listRecordAddMassiveSelected);
                stateTable.set('selected', _.uniq(listSelectedMassiveAdd));
            });
}

function getRemoveMassiveSelected(action, stateTable){
    const listSelectedMassiveRemove = _.difference(stateTable.get('selected'), _.get(action, 'meta.array'));
    const listRecordRemoveMassiveSelected = stateTable.get('records').map( item => {
                                              return _.set(item, 'selected', false);
                                          });
    return stateTable.withMutations(function(stateTable) {
                stateTable.set("records", listRecordRemoveMassiveSelected);
                stateTable.set('selected', _.uniq(listSelectedMassiveRemove));
            });
}

function getSetSelected(action, stateTable){
    return stateTable.set('selected', _.get(action, 'meta.selected', []));
}

function getSetStatusFilter(action, stateTable){
    return stateTable.set("status", _.get(action, 'meta.status', null));
}

function getSetInSearchRecords(action, stateTable){
    return stateTable.set("inSearch", true);
}

function getchangeLimitRecords(action, stateTable){
    return stateTable.set("limit", _.get(action, 'meta.limit', 25));
}

function returnState(state, modState, store){
    return state.set(store, modState);
}

function getDefaultState(){
    return Immutable.Map({
              skip: 0,
              limit: 25,
              records: Immutable.List(),
              total: 0,
              orderName: 'null',
              orderType: 0,
              textFilter: "",
              arrayFields: [],
              selected: [],
              fieldSelection: null,
              status: null,
              inSearch: false
          });
}

function changeValueRecord(item, arrayFields, arrayValues){
    let record = item;
    _.map(arrayFields, function(field, index){
        record = _.set(record, field, arrayValues[index]);
    });
    return record;
}
