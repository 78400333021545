import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import {Row, Col} from 'react-flexbox-grid';
import {blue700, grey300} from 'material-ui/styles/colors';
import {List, ListItem} from 'material-ui/List';

class DataConsents extends Component {

    constructor(props) {
        super(props);
    }

    renderDescription(textDescription){
        let description = "";
        if(!_.isUndefined(textDescription) && !_.isEqual(textDescription, "")){
            description = _.split(textDescription, /\r\n|\r|\n/g);
        }
        if(description instanceof Array){
            return (
                <h3 style={{textAlign: 'justify', fontWeight: 200}}>
                    {_.map(description, (value, index) => {
                        if(!_.isEqual(value, '')){
                            return (
                                <div key={`consentDescription${index}`}><h4 style={{fontWeight: 400, margin: 0, textAlign: 'justify'}}>{value}</h4><br/></div>
                            );
                        }
                    })}
                </h3>
            );
        } 
            return (
                <h4 style={{fontWeight: 400, margin: 0, textAlign: 'justify'}}>{description}</h4>
            );
        
    }

    render() {
        const { result } = this.props;
        if(_.isNull(result)){
            return ( <div /> )
        } 
            return (
                <div style={{padding: '0 20px'}}>
                    <Row>
                        <Col xs>
                            <h1 style={{fontWeight: 400, marginTop: 30}}>Consentimientos</h1>
                        </Col>
                    </Row>
                    {_.map(_.get(result, 'consents', []), (consent, index) => {
                        return (
                            <div key={`consent${index}`}>
                                <Row>
                                    <Col xs>
                                        <h3 style={{fontWeight: 500, margin: '20px 0 7px 0'}}>{_.get(consent, 'name')}</h3>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs>
                                        {this.renderDescription(_.get(consent, 'consent'))}
                                    </Col>
                                </Row>
                            </div>
                        );
                    })}
                </div>
            );
        
    }
}

export default DataConsents;
