import PropTypes from "prop-types";
import React, { Component } from "react";
import CircularProgress from "material-ui/CircularProgress";
import _ from "lodash";

const styleText = {
  whiteSpace: "nowrap",
  width: 150,
  textAlign: "center",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

class LoadingPage extends Component {
  constructor(props) {
    super(props);
    this.handleResize = this.handleResize.bind(this);
    this.state = {
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    };
  }

  handleResize(e) {
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    });
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    const { visible, error, text, size = 120 } = this.props;
    const visibleLoading = _.isUndefined(visible) ? false : visible;
    const errorLoading = _.isUndefined(error) ? false : error;
    const heightComponent = _.isUndefined(text)
      ? 100
      : _.isEqual(text, "")
      ? 100
      : 120;
    const styleCircle = {
      border: 0,
      borderStyle: "solid",
      borderColor: "#FF0",
      position: "fixed",
      textAlign: "center",
      zIndex: 999,
      left: this.state.windowWidth / 2 - 85,
      top: this.state.windowHeight / 2 - heightComponent / 2,
      width: 170,
      heigth: heightComponent,
    };
    if (visibleLoading) {
      return (
        <div style={styleCircle}>
          <CircularProgress size={size} thickness={8} />
          <h3 style={styleText}>{text}</h3>
        </div>
      );
    }
    if (errorLoading) {
      return <div>Error!!</div>;
    }
    return <div />;
  }
}

LoadingPage.propTypes = {
  visible: PropTypes.bool,
  error: PropTypes.bool,
  text: PropTypes.string,
  size: PropTypes.number,
};

export default LoadingPage;
