import React, { useState } from "react";
import { Dialog, TextField } from "material-ui";
import FlatButton from "material-ui/FlatButton";
import PropTypes from "prop-types";
import _ from "lodash";
import { useComplementContext } from "../context/complementProvider";
import ButtonSaveValue from "./buttonSaveValue";

const styles = {
  contentTextField: {
    textAlign: "center",
  },
};

/**
 * Componente para agregar valores
 * @author Jhoan Lopez <jhoanlt19@gmail.com>
 * @param {*} param0
 */
const DialogAddValue = ({
  open,
  handleClose,
  title,
  label,
  selectedComplement,
}) => {
  const [value, setValue] = useState("");
  const { complements, setComplements } = useComplementContext();

  const handleChangeValue = ({ target }) => {
    setValue(target.value);
  };

  const handleCloseDialog = () => {
    setValue("");
    handleClose();
  };

  const handleSaveValue = ({ newComplementValue }) => {
    const newData = _.map(complements, (c) => {
      if (c._id === selectedComplement._id) {
        c.values = [...c.values, newComplementValue];
      }
      return c;
    });
    setComplements(newData);
    setValue("");
    handleClose();
  };

  const actions = [
    <FlatButton label="Cancelar" primary onClick={handleCloseDialog} />,
    <ButtonSaveValue
      values={{ name: value, complement: selectedComplement._id }}
      onCompleted={handleSaveValue}
    />,
  ];

  return (
    <div>
      <Dialog
        title={title}
        actions={actions}
        modal={false}
        open={open}
        onRequestClose={handleCloseDialog}
      >
        <div style={styles.contentTextField}>
          <TextField
            name="text"
            data-testid="value-add"
            floatingLabelText={label}
            value={value}
            onChange={handleChangeValue}
          />
        </div>
      </Dialog>
    </div>
  );
};

DialogAddValue.defaultProps = {
  saveDisabled: false,
};

DialogAddValue.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  label: PropTypes.string,
};

export default DialogAddValue;
