const scopes = {
    EMPLOYEE: [
        'backend/surveys:write',
        'backend/surveys:read',
        'backend/surveyResults:read',
        'backend/projects:read',
        'backend/approvalConsent:read',
        'backend/approvalConsent:write',
        'backend/sections:read',
        'backend/sections:write',
        'backend/solutions:read',
        'backend/solutions:write',
        'backend/questions:read',
        'backend/location:read',
        'draco/users:me',
    ],
    EVALUATOR: [
        'backend/employees:read',
        'backend/employees:write',
        'backend/companies:write',
        'backend/companies:read',
        'backend/surveys:write',
        'backend/surveys:read',
        'backend/projects:read',
        'backend/projects:write',
        'backend/projects:delete',
        'backend/surveyResults:read',
        'backend/surveyResults:write',
        'backend/email:read',
        'backend/email:write',
        'backend/faqs:read',
        'backend/faqs:write',
        'backend/referees:write',
        'backend/referees:read',
        'backend/approvalConsent:read',
        'backend/approvalConsent:write',
        'backend/sections:read',
        'backend/sections:write',
        'backend/solutions:read',
        'backend/solutions:write',
        'backend/questions:read',
        'backend/location:read',
        'draco/users:me',
        'draco/users:read',
    ],
    COMPANY: [
        'backend/users:read',
        'backend/projects:read',
    ],
    ARL: [
        'backend/arl:read',
        'backend/arl:write',
        'backend/arl:delete',
        'backend/projects:read',
    ],
    OPERATOR: [
        'backend/companies:read',
        'backend/companies:write',
        'backend/companies:delete',
        'backend/consent:read',
        'backend/consents:write',
        'backend/consents:delete',
        'backend/employees:read',
        'backend/employees:write',
        'backend/employees:delete',
        'backend/persons:read',
        'backend/persons:write',
        'backend/persons:delete',
        'backend/projects:read',
        'backend/projects:write',
        'backend/projects:delete',
        'backend/questions:read',
        'backend/questions:write',
        'backend/questions:delete',
        'backend/referees:read',
        'backend/referees:write',
        'backend/referees:delete',
        'backend/surveys:read',
        'backend/surveys:write',
        'backend/surveys:delete',
        'backend/users:read',
        'backend/users:write',
        'backend/users:delete',
        'backend/analytics:read',
        'backend/analytics:write',
        'backend/analytics:delete',
        'backend/answer:read',
        'backend/answer:write',
        'backend/answer:delete',
        'backend/approvalConsent:read',
        'backend/approvalConsent:write',
        'backend/approvalConsent:delete',
        'backend/arl:read',
        'backend/arl:write',
        'backend/arl:delete',
        'backend/audit:read',
        'backend/audit:write',
        'backend/audit:delete',
        'backend/conditionals:read',
        'backend/conditionals:write',
        'backend/conditionals:delete',
        'backend/faqs:read',
        'backend/faqs:write',
        'backend/faqs:delete',
        'backend/listMaster:read',
        'backend/listMaster:write',
        'backend/listMaster:delete',
        'backend/detail:read',
        'backend/detail:write',
        'backend/detail:delete',
        'backend/location:read',
        'backend/location:write',
        'backend/location:delete',
        'backend/master:read',
        'backend/master:write',
        'backend/master:delete',
        'backend/profiles:read',
        'backend/profiles:write',
        'backend/profiles:delete',
        'backend/reports:read',
        'backend/reports:write',
        'backend/reports:delete',
        'backend/sections:read',
        'backend/sections:write',
        'backend/sections:delete',
        'backend/email:read',
        'backend/email:write',
        'backend/email:delete',
        'backend/solutions:read',
        'backend/solutions:write',
        'backend/solutions:delete',
        'backend/surveyGroupers:read',
        'backend/surveyGroupers:write',
        'backend/surveyGroupers:delete',
        'backend/surveyResults:read',
        'backend/surveyResults:write',
        'backend/surveyResults:delete',
        'backend/templateEmail:read',
        'backend/templateEmail:write',
        'backend/templateEmail:delete',
    ],
    ADMIN: [
        'backend/companies:read',
        'backend/companies:write',
        'backend/companies:delete',
        'backend/consent:read',
        'backend/consents:write',
        'backend/consents:delete',
        'backend/employees:read',
        'backend/employees:write',
        'backend/employees:delete',
        'backend/persons:read',
        'backend/persons:write',
        'backend/persons:delete',
        'backend/projects:read',
        'backend/projects:write',
        'backend/projects:delete',
        'backend/questions:read',
        'backend/questions:write',
        'backend/questions:delete',
        'backend/referees:read',
        'backend/referees:write',
        'backend/referees:delete',
        'backend/surveys:read',
        'backend/surveys:write',
        'backend/surveys:delete',
        'backend/users:read',
        'backend/users:write',
        'backend/users:delete',
        'backend/analytics:read',
        'backend/analytics:write',
        'backend/analytics:delete',
        'backend/answer:read',
        'backend/answer:write',
        'backend/answer:delete',
        'backend/approvalConsent:read',
        'backend/approvalConsent:write',
        'backend/approvalConsent:delete',
        'backend/arl:read',
        'backend/arl:write',
        'backend/arl:delete',
        'backend/audit:read',
        'backend/audit:write',
        'backend/audit:delete',
        'backend/conditionals:read',
        'backend/conditionals:write',
        'backend/conditionals:delete',
        'backend/faqs:read',
        'backend/faqs:write',
        'backend/faqs:delete',
        'backend/listMaster:read',
        'backend/listMaster:write',
        'backend/listMaster:delete',
        'backend/detail:read',
        'backend/detail:write',
        'backend/detail:delete',
        'backend/location:read',
        'backend/location:write',
        'backend/location:delete',
        'backend/master:read',
        'backend/master:write',
        'backend/master:delete',
        'backend/profiles:read',
        'backend/profiles:write',
        'backend/profiles:delete',
        'backend/reports:read',
        'backend/reports:write',
        'backend/reports:delete',
        'backend/sections:read',
        'backend/sections:write',
        'backend/sections:delete',
        'backend/email:read',
        'backend/email:write',
        'backend/email:delete',
        'backend/solutions:read',
        'backend/solutions:write',
        'backend/solutions:delete',
        'backend/surveyGroupers:read',
        'backend/surveyGroupers:write',
        'backend/surveyGroupers:delete',
        'backend/surveyResults:read',
        'backend/surveyResults:write',
        'backend/surveyResults:delete',
        'backend/templateEmail:read',
        'backend/templateEmail:write',
        'backend/templateEmail:delete',
    ],
};

export default scopes;
