import React from "react";
import PropTypes from "prop-types";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import { blue700 } from "material-ui/styles/colors";
import Spacing from "material-ui/styles/spacing";
import zIndex from "material-ui/styles/zIndex";
import {
  createTheme,
  ThemeProvider as M4ThemeProvider,
} from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors"

const theme = getMuiTheme({
  spacing: Spacing,
  zIndex,
  fontFamily: "Roboto, sans-serif",
  palette: {
    primary1Color: blue700,
    primary2Color: blue700,
    primary3Color: blue700,
    accent1Color: blue700,
    accent2Color: blue700,
    accent3Color: blue700,
  },
});

const m4Theme = createTheme({
  palette: {
    primary: {
      main: blue[700],
    },
    secondary: {
      main: blue[700],
    },
  },
});

const ThemeProvider = ({ children }) => {
  return (
    <M4ThemeProvider theme={m4Theme}>
      <MuiThemeProvider muiTheme={theme}>{children}</MuiThemeProvider>
    </M4ThemeProvider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ThemeProvider;
