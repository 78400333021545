import Immutable from 'immutable';
import _ from 'lodash';
import {STATE_MODAL_MESSAGE} from './actions';

const initialState = Immutable.Map(
    {
        title: "",
        message: "",
        open: false
    }
);

export default function (state = initialState, action) {
    switch (action.type) {
      case STATE_MODAL_MESSAGE:
      var newState = Immutable.Map(
          {
              title: action.title,
              open: action.show,
              message: action.message
          }
      );
      return newState;
      default:
          return state;
    }
  }
