import {
    GET_QUESTIONS_SELECTORS_SECTIONS,
    GET_SECTION_INFO,
    CLEAN_SECTION_INFO,
    GET_ALL_QUESTIONS_SURVEY
} from '../../constants/index';
import { axiosAPI } from '../../middleware/api';

export function FetchSectionParam(id) {

    const request = axiosAPI.get(`/sections/questions/${id}`);

    return {
        type: GET_SECTION_INFO,
        payload: request
    }
}

export function getQuestionSelectorsSection(section) {

    const request = axiosAPI.get(`/sections/${section}/questions/selectors`);

    return {
        type: GET_QUESTIONS_SELECTORS_SECTIONS,
        payload: request
    }

}

export function CleanSectionParam() {

    return {
        type: CLEAN_SECTION_INFO
    }
}

export function getAllQuestionsSurvey(idSurvey) {

    const request = axiosAPI.get(`/sections/questions/all/${idSurvey}`);

    return {
        type: GET_ALL_QUESTIONS_SURVEY,
        payload: request
    }
}
