import Immutable from "immutable";
import _ from "lodash";
import {
    GET_RECORDS,
    ADD_RECORD,
    CLEAN_TABLE,
    REMOVE_RECORD,
    REMOVE_MASSIVE_RECORD,
    CHANGE_FIELD_RECORD,
    LOCKED_RECORD,
    CHANGE_STATUS_RECORD,
    RESET_ORDER_RECORDS,
    SET_ARRAY_FIELDS_FILTER,
    SET_FIELD_SELECTION,
    CLEAN_SELECTED,
    ADD_SELECTED,
    REMOVE_SELECTED,
    ADD_MASSIVE_SELECTED,
    REMOVE_MASSIVE_SELECTED,
    SET_SELECTED,
    SET_SELECTED_WITH_RECORDS,
    SET_STATUS_FILTER,
    SET_IN_SEARCH_RECORDS,
    CHANGE_LIMIT_RECORDS,
    CHANGE_INFO_OF_FIELD_RECORD,
} from "./constants";

const initialState = Immutable.Map({
    skip: 0,
    limit: 25,
    records: Immutable.List(),
    total: 0,
    orderName: "null",
    orderType: 0,
    textFilter: "",
    arrayFields: [],
    selected: [],
    fieldSelection: null,
    status: null,
    inSearch: false,
});

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_RECORDS: {
            const arrayRecord = _.get(
                action,
                "payload.data.data",
                _.get(action, "payload.data", [])
            );
            const listSelected = state.get("selected");
            const array = _.map(arrayRecord, (item) =>
                _.findIndex(listSelected, (record) =>
                    _.isEqual(_.get(item, state.get("fieldSelection")), record)
                ) !== -1
                    ? _.set(item, "selected", true)
                    : item
            );
            const total = _.get(
                action,
                "payload.data.numFound",
                _.get(action, "payload.numFound", _.size(array))
            );
           
            return state.withMutations((state) => {
                state.set("records", array);
                state.set("skip", _.get(action, "meta.skip", 0));
                state.set("total", total);
                state.set("orderName", _.get(action, "meta.orderName", "null"));
                state.set("orderType", _.get(action, "meta.orderType", 0));
                state.set("textFilter", _.get(action, "meta.textFilter", ""));
                state.set("selected", listSelected);
                state.set("inSearch", false);
            });
        }
        case ADD_RECORD: {
            const arrayAddRecord = state.get("records");
            if (
                !_.isUndefined(_.get(action, "meta.record")) &&
                !_.isNull(_.get(action, "meta.record"))
            ) {
                arrayAddRecord.push(_.get(action, "meta.record"));
            }
            return state.set("records", arrayAddRecord);
        }
        case CLEAN_TABLE:
            return state.withMutations((state) => {
                state.set("records", Immutable.List());
                state.set("skip", 0);
                state.set("total", 0);
                state.set("orderName", "null");
                state.set("orderType", 0);
                state.set("textFilter", "");
                state.set("arrayFields", []);
                state.set("selected", []);
                state.set("status", null);
                state.set("inSearch", false);
                state.set("limit", 25);
            });
        case REMOVE_RECORD:
            var listRecordDeleted = state
                .get("records")
                .filter(
                    (item) =>
                        !_.isEqual(
                            _.get(item, _.get(action, "meta.field", "_id")),
                            _.get(action, "meta.id")
                        )
                );
            var listSelectedDeleted = state
                .get("selected")
                .filter((item) => !_.isEqual(item, _.get(action, "meta.id")));
            return state.withMutations((state) => {
                state.set("records", listRecordDeleted);
                state.set("total", state.get("total") - 1);
                state.set("selected", listSelectedDeleted);
            });
        case REMOVE_MASSIVE_RECORD:
            var listRecordMassiveDeleted = state
                .get("records")
                .filter(
                    (item) =>
                        _.findIndex(_.get(action, "meta.arrayId"), (idRecord) =>
                            _.isEqual(
                                _.get(item, state.get("fieldSelection")),
                                idRecord
                            )
                        ) === -1
                );
            var listSelectedMassiveDeleted = state
                .get("selected")
                .filter(
                    (item) =>
                        _.findIndex(_.get(action, "meta.arrayId"), (idRecord) =>
                            _.isEqual(item, idRecord)
                        ) === -1
                );
            return state.withMutations((state) => {
                state.set("records", listRecordMassiveDeleted);
                state.set(
                    "total",
                    state.get("total") - _.size(_.get(action, "meta.arrayId"))
                );
                state.set("selected", listSelectedMassiveDeleted);
            });
        case CHANGE_FIELD_RECORD:
            var listRecordChange = state
                .get("records")
                .map((item) =>
                    _.isEqual(_.get(item, "_id"), _.get(action, "meta.id"))
                        ? changeValueRecord(
                              item,
                              _.get(action, "meta.field"),
                              _.get(action, "meta.value")
                          )
                        : item
                );
            return state.set("records", listRecordChange);
        case LOCKED_RECORD:
            var listRecordLocked = state
                .get("records")
                .map((item) =>
                    _.isEqual(_.get(item, "_id"), _.get(action, "meta.id"))
                        ? _.set(item, "locked", true)
                        : item
                );
            return state.set("records", listRecordLocked);
        case CHANGE_STATUS_RECORD:
            var listRecordStatus = state
                .get("records")
                .map((item) =>
                    _.indexOf(
                        _.get(action, "meta.ids", []),
                        _.get(item, "_id")
                    ) >= 0
                        ? _.set(item, "status", _.get(action, "meta.value", ""))
                        : item
                );
            return state.set("records", listRecordStatus);
        case RESET_ORDER_RECORDS:
            return state.withMutations((state) => {
                state.set("orderName", "null");
                state.set("orderType", 0);
            });
        case SET_ARRAY_FIELDS_FILTER:
            return state.set(
                "arrayFields",
                _.get(action, "meta.arrayFields", [])
            );
        case SET_FIELD_SELECTION:
            return state.set(
                "fieldSelection",
                _.get(action, "meta.fieldSelection", null)
            );
        case CLEAN_SELECTED:
            var arrayRecordCleanSelected = state
                .get("records")
                .map((item) => _.set(item, "selected", false));
            return state.withMutations((state) => {
                state.set("records", arrayRecordCleanSelected);
                state.set("selected", []);
            });
        case ADD_SELECTED:
            var listSelectedAdd = _.concat(
                state.get("selected"),
                _.get(action, "meta.id")
            );
            var listRecordAddSelected = state
                .get("records")
                .map((item) =>
                    _.isEqual(
                        _.get(item, state.get("fieldSelection")),
                        _.get(action, "meta.id")
                    )
                        ? _.set(item, "selected", true)
                        : item
                );
            return state.withMutations((state) => {
                state.set("records", listRecordAddSelected);
                state.set("selected", listSelectedAdd);
            });
        case REMOVE_SELECTED:
            var listSelectedRemove = state
                .get("selected")
                .filter((item) => !_.isEqual(item, _.get(action, "meta.id")));
            var listRecordRemoveSelected = state
                .get("records")
                .map((item) =>
                    _.isEqual(
                        _.get(item, state.get("fieldSelection")),
                        _.get(action, "meta.id")
                    )
                        ? _.set(item, "selected", false)
                        : item
                );
            return state.withMutations((state) => {
                state.set("records", listRecordRemoveSelected);
                state.set("selected", listSelectedRemove);
            });
        case ADD_MASSIVE_SELECTED:
            var listSelectedMassiveAdd = _.concat(
                state.get("selected"),
                _.get(action, "meta.array")
            );
            var listRecordAddMassiveSelected = state
                .get("records")
                .map((item) => _.set(item, "selected", true));
            return state.withMutations((state) => {
                state.set("records", listRecordAddMassiveSelected);
                state.set("selected", _.uniq(listSelectedMassiveAdd));
            });
        case REMOVE_MASSIVE_SELECTED:
            var listSelectedMassiveRemove = _.difference(
                state.get("selected"),
                _.get(action, "meta.array")
            );
            var listRecordRemoveMassiveSelected = state
                .get("records")
                .map((item) => _.set(item, "selected", false));
            return state.withMutations((state) => {
                state.set("records", listRecordRemoveMassiveSelected);
                state.set("selected", _.uniq(listSelectedMassiveRemove));
            });
        case SET_SELECTED:
            return state.set("selected", _.get(action, "meta.selected", []));
        case SET_SELECTED_WITH_RECORDS:
            const listSetSelected = _.get(action, "meta.selected", []);
            const listRecordSetSeleted = state
                .get("records")
                .map((item) =>
                    _.findIndex(listSetSelected, (record) =>
                        _.isEqual(
                            _.get(item, state.get("fieldSelection")),
                            record
                        )
                    ) !== -1
                        ? _.set(item, "selected", true)
                        : item
                );
            return state.withMutations((state) => {
                state.set("records", listRecordSetSeleted);
                state.set("selected", listSetSelected);
            });
        case SET_STATUS_FILTER:
            return state.set("status", _.get(action, "meta.status", null));
        case CHANGE_INFO_OF_FIELD_RECORD:
            var listRecordChangeInfoOfObject = state
                .get("records")
                .map((item) => {
                    if (
                        _.isEqual(
                            _.get(item, "project"),
                            _.get(action, "meta.idProject")
                        ) &&
                        _.isEqual(
                            _.get(item, "employee._id"),
                            _.get(action, "meta.idEmployee")
                        )
                    ) {
                        return _.set(
                            item,
                            "status",
                            _.get(
                                action,
                                "meta.newStatus",
                                _.get(item, "status")
                            )
                        );
                    }
                    return item;
                });
            return state.set("records", listRecordChangeInfoOfObject);
        case SET_IN_SEARCH_RECORDS:
            return state.set("inSearch", true);
        case CHANGE_LIMIT_RECORDS:
            return state.set("limit", _.get(action, "meta.limit", 25));
        default:
            return state;
    }
}

function changeValueRecord(item, arrayFields, arrayValues) {
    let record = item;
    _.map(arrayFields, (field, index) => {
        record = _.set(record, field, arrayValues[index]);
    });
    return record;
}
