import {axiosAPI, axiosApiV2} from "../../middleware/api";

export const GET_PROJECT_INFO = "GET_PROJECT_INFO";
export const GET_PROJECT_ONLY = "GET_PROJECT_ONLY";
export const GET_PROJECT_COLORS = "GET_PROJECT_COLORS";
export const UNMOUNT_PROJECT = "UNMOUNT_PROJECT ";
export const GET_PROJECT_ONLY_LAPSES_TIME_INFO =
    "GET_PROJECT_ONLY_LAPSES_TIME_INFO";
export const GET_PROJECT_ONLY_REFEREES_INFO = "GET_PROJECT_ONLY_REFEREES_INFO";

// Ranking
export const GET_TREE_GROUPERS_SURVEY = "GET_TREE_GROUPERS_SURVEY";
export const SELECT_GROUPER_TREE_SURVEY = "SELECT_GROUPER_TREE_SURVEY";

export function FetchProjectParam(id) {
    const request = axiosAPI.get(`/projects/${id}`);

    return {
        type: GET_PROJECT_INFO,
        payload: request,
    };
}

export async function FetchProjectOnlyParam(id) {
    const request = await axiosAPI.get(`/projects/only/${id}`);

    return {
        type: GET_PROJECT_ONLY,
        payload: request,
    };
}

export function FetchProjectColorsParam(id) {
    const request = axiosAPI.get(`/projects/colors/${id}`);

    return {
        type: GET_PROJECT_COLORS,
        payload: request,
    };
}

export function FetchListRefereesInfoParam(id) {
    const request = axiosAPI.get(`/projects/only/referees/${id}`);

    return {
        type: GET_PROJECT_ONLY_REFEREES_INFO,
        payload: request,
    };
}

export function FetchListLapsesTimeParam(id) {
    const request = axiosAPI.get(`/projects/only/lapsestime/${id}`);

    return {
        type: GET_PROJECT_ONLY_LAPSES_TIME_INFO,
        payload: request,
    };
}

export function unMountProject() {
    return {
        type: UNMOUNT_PROJECT,
    };
}

export function FetchTreeGroupersSurveyParam(id) {
    const request = axiosApiV2.get(`/surveygroupers/tree/groupers/${id}`);

    return {
        type: GET_TREE_GROUPERS_SURVEY,
        payload: request,
    };
}

export function selectGrouperForProject(idGrouper, isSelect) {
    return {
        type: SELECT_GROUPER_TREE_SURVEY,
        meta: {
            idGrouper,
            isSelect,
        },
    };
}
