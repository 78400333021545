import _ from "lodash";
import { axiosAPI, axiosApiV2 } from "../../middleware/api";
import {
  GET_RECORDS,
  ADD_RECORD,
  CLEAN_TABLE,
  REMOVE_RECORD,
  REMOVE_MASSIVE_RECORD,
  CHANGE_FIELD_RECORD,
  LOCKED_RECORD,
  CHANGE_STATUS_RECORD,
  RESET_ORDER_RECORDS,
  SET_ARRAY_FIELDS_FILTER,
  SET_FIELD_SELECTION,
  CLEAN_SELECTED,
  ADD_SELECTED,
  REMOVE_SELECTED,
  ADD_MASSIVE_SELECTED,
  REMOVE_MASSIVE_SELECTED,
  SET_SELECTED,
  SET_SELECTED_WITH_RECORDS,
  SET_STATUS_FILTER,
  SET_IN_SEARCH_RECORDS,
  CHANGE_LIMIT_RECORDS,
  CHANGE_INFO_OF_FIELD_RECORD,
  GET_USER_PROFILES,
} from "./constants";

export function getRecords(
  url,
  version,
  skip,
  limit,
  orderName,
  orderType,
  arrayFields,
  textFilter,
  extraData
) {
  let request = null;
  let params = {
    skip,
    limit,
    order: orderName,
    orderType,
    filter: arrayFields,
    textFilter,
  };

  if (!_.isUndefined(extraData) && !_.isNull(extraData)) {
    params = _.set(params, "extra", extraData);
  }

  if (version === 2) {
    request = axiosApiV2.get(url, { params });
  } else {
    request = axiosAPI.get(url, { params });
  }

  return {
    type: GET_RECORDS,
    payload: request,
    meta: {
      skip,
      orderName,
      orderType,
      textFilter,
    },
  };
}
export function getUserTypes() {
  return dispatch => {
    axiosAPI.get("/profiles/grid").then(response => {
      dispatch({
        type: GET_USER_PROFILES,
        payload: response,
      });
    });
  };
}
  
export function addRecordInList(record) {
  return {
    type: ADD_RECORD,
    meta: {
      record,
    },
  };
}

export function setExtraStatusFilter(status) {
  return {
    type: SET_STATUS_FILTER,
    meta: {
      status,
    },
  };
}

export function cleanTable() {
  return {
    type: CLEAN_TABLE,
  };
}

export function removeRecord(id, field) {
  return {
    type: REMOVE_RECORD,
    meta: {
      id,
      field,
    },
  };
}

export function removeMassiveRecord(arrayId) {
  return {
    type: REMOVE_MASSIVE_RECORD,
    meta: {
      arrayId,
    },
  };
}

export function changeFieldRecord(id, field, value) {
  return {
    type: CHANGE_FIELD_RECORD,
    meta: {
      id,
      field,
      value,
    },
  };
}

export function LockRecord(id) {
  return {
    type: LOCKED_RECORD,
    meta: {
      id,
    },
  };
}

export function changeStatusRecord(ids, value) {
  return {
    type: CHANGE_STATUS_RECORD,
    meta: {
      ids,
      value,
    },
  };
}

export function resetOrderRecord() {
  return {
    type: RESET_ORDER_RECORDS,
  };
}

export function setArrayfieldsFilter(arrayFields) {
  return {
    type: SET_ARRAY_FIELDS_FILTER,
    meta: {
      arrayFields,
    },
  };
}

export function setFieldSelection(fieldSelection) {
  return {
    type: SET_FIELD_SELECTION,
    meta: {
      fieldSelection,
    },
  };
}

export function cleanSelected() {
  return {
    type: CLEAN_SELECTED,
  };
}

export function addSelected(id) {
  return {
    type: ADD_SELECTED,
    meta: {
      id,
    },
  };
}

export function removeSelected(id) {
  return {
    type: REMOVE_SELECTED,
    meta: {
      id,
    },
  };
}

export function addMassiveSelected(array) {
  return {
    type: ADD_MASSIVE_SELECTED,
    meta: {
      array,
    },
  };
}

export function removeMassiveSelected(array) {
  return {
    type: REMOVE_MASSIVE_SELECTED,
    meta: {
      array,
    },
  };
}

export function setSelected(arraySelected) {
  return {
    type: SET_SELECTED,
    meta: {
      selected: arraySelected,
    },
  };
}

export function setInitSelected(arraySelected) {
  return {
    type: SET_SELECTED_WITH_RECORDS,
    meta: {
      selected: arraySelected,
    },
  };
}

export function setInSearchRecords() {
  return {
    type: SET_IN_SEARCH_RECORDS,
  };
}

export function changeLimitRecords(limit) {
  return {
    type: CHANGE_LIMIT_RECORDS,
    meta: {
      limit,
    },
  };
}

export function changeStatusRecordEmployee(idEmployee, idProject, newStatus) {
  return {
    type: CHANGE_INFO_OF_FIELD_RECORD,
    meta: {
      idEmployee,
      idProject,
      newStatus,
    },
  };
}
