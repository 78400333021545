import React from "react";
import { useMachine } from "@xstate/react";
import { useNotifications } from "Notifications";
import { useHistory, useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useAuth } from "../context";
import AuthMachine from "./machine";
import LoginForm from "./signInForm";
import NewPasswordForm from "./change-new-password";
import ForgotPassword from "./sendCodeForm";
import CodeVerificationForm from "./changePasswordForm";
import { loginFulfilled as lf } from "../../containers/login/loginDucks";
import { Hub } from "@aws-amplify/core";

const AuthenticationPage = ({ loginFulfilled }) => {
  const history = useHistory();
  const location = useLocation();
  const { setUser, setPermissions } = useAuth();
  const { sendNotification } = useNotifications();

  React.useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          console.log("signIn", {
            data,
            event,
          });
          break;
        case "signOut":
          console.log("signIn", {
            data,
            event,
          });
          break;
        case "customOAuthState":
          console.log("customOAuthState", {
            data,
            event,
          });
          break;
        default:
          console.log("default", {
            data,
            event,
          });
          break;
      }
    });

    return unsubscribe;
  }, []);

  const [state, send] = useMachine(AuthMachine, {
    actions: {
      onInvalidCredentials: () => {
        sendNotification("Usuario y/o contraseña inválidas");
      },
      onInvalidUser: () => {
        sendNotification("El usuario no existe.");
      },
      onResendComplete: () => {
        sendNotification(
          "Parece que no has iniciado sesión por primera vez,\nhemos enviado nuevamente un mensaje a tu correo con tus credenciales"
        );
      },
      onSendCodeError: (_ctx, event) => {
        const err = event.data;
        if (err.code === "UserNotFoundException") {
          sendNotification("El usuario no existe");
        }
        if (err.code === "CodeMismatchException") {
          sendNotification(
            "Se proporcionó un código de verificación no válido. Vuelva a intentarlo."
          );
        }
        if (err.code === "LimitExceededException") {
          sendNotification(
            "Se superó el límite de intentos. Inténtelo después de un tiempo."
          );
        }
        if (err.code === "InvalidPasswordException") {
          sendNotification(
            "Contraseña invalida, debe tener minúsculas, mayúsculas y números"
          );
        }
      },
      onResetComplete: () => {
        sendNotification(
          "Contraseña reiniciada con exito. \nInicia sesión para continuar."
        );
      },
      onInvalidCode: () => {
        sendNotification("El código que suministraste es inválido");
      },
      onSignIn: (_ctx, event) => {
        const {
          profile: { redirectUri, permissions = [] },
        } = event.data;
        const { from } = location.state || {
          from: { pathname: redirectUri },
        };

        setUser(event.data.user);
        setPermissions(new Set(permissions));
        loginFulfilled(event.data);
        history.replace(_ctx.credentials ? redirectUri : from);
      },
    },
  });

  const { value } = state;

  const handleClickForgot = () => {
    send("RESET_PASSWORD");
  };

  const handleSubmitLogin = ({
    identificationType,
    password,
    username: user,
  }) => {
    const username = `${identificationType}_${user}`;
    send("SUBMIT", {
      data: { password, username },
    });
  };

  const handleSubmitNewPassword = values => {
    send("SUBMIT", {
      newPassword: values.password,
    });
  };

  const handleGoToLogin = () => {
    send("GO_TO_LOGIN");
  };

  const handleSubmitResetPassword = values => {
    send("SUBMIT_RESET_PASSWORD_CODE", {
      data: values,
    });
  };

  const handleSubmitForgotPassword = username => {
    send("SUBMIT_RESET_PASSWORD_USER", {
      data: {
        resetPasswordUsername: username,
      },
    });
  };

  switch (true) {
    case state.matches("authenticationInProgress"):
    case state.matches("loginForm"):
      return (
        <LoginForm
          isSubmitting={value === "authenticationInProgress"}
          onForgotPassword={handleClickForgot}
          onSubmit={handleSubmitLogin}
        />
      );

    case state.matches("changeInitialPassword"):
    case state.matches({
      changeInitialPassword: "submitting",
    }):
    case state.matches({
      changeInitialPassword: "userHasNotSignIn",
    }): {
      // change password required
      return (
        <NewPasswordForm
          isLoading={state.matches({
            changeInitialPassword: "submitting",
          })}
          onSubmit={handleSubmitNewPassword}
        />
      );
    }
    case state.matches("authenticationSucceed"): {
      return null;
    }
    case state.matches({ resetPassword: "userForm" }):
    case state.matches({ resetPassword: "submittingUser" }): {
      return (
        <ForgotPassword
          goToLogin={handleGoToLogin}
          isLoading={state.matches({
            resetPassword: "submittingUser",
          })}
          onSubmit={handleSubmitForgotPassword}
        />
      );
    }
    case state.matches({ resetPassword: "codeForm" }):
    case state.matches({ resetPassword: "submittingCode" }): {
      return (
        <CodeVerificationForm
          onSubmit={handleSubmitResetPassword}
          isLoading={state.matches({
            resetPassword: "submittingCode",
          })}
        />
      );
    }
    default: {
      return null;
    }
  }
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loginFulfilled: lf,
    },
    dispatch
  );
}

export default connect(undefined, mapDispatchToProps)(AuthenticationPage);
