import { axiosAPI } from '../../middleware/api';

export const GET_FAQ_GRID = "GET_FAQ_GRID";

export function FetchFaq() {
    const request = axiosAPI.get(`/faqs`);

    return {
        type: GET_FAQ_GRID,
        payload: request
    }
}
