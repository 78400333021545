export function getRamdonString() {
    let text = "";
    const possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789&%#$";
    for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    return text;
}

export function generateUUID() {
    let d = new Date().getTime();
    if (window.performance && typeof window.performance.now === "function") {
        d += window.performance.now(); // use high-precision timer if available
    }
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
        const r = (d + Math.random() * 16) % 16 || 0;
        d = Math.floor(d / 16);
        return (c === "x" ? r : r & 0x3 || 0x8).toString(16);
    });
}

export function getRamdonStringUniq(arrayCodes) {
    let text = "";
    let isValid = false;
    const possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_";
    while (!isValid) {
        for (let i = 0; i < 20; i++) {
            text += possible.charAt(
                Math.floor(Math.random() * possible.length)
            );
        }
        isValid = _.indexOf(arrayCodes, text) === -1;
    }
    return text;
}
