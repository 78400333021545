const filters = {
    identification:"Identificación",
    firstName:"Nombre",
    lastName:"Apellido",
    email:"Email",
    type:"Tipo de usuario",
    profiles:"Perfil",
}

const tableColumns = [
    {
        Header: filters.identification,
        accessor: 'person.information.identification.number',
    },
    {
        Header: filters.firstName,
        accessor: 'person.information.firstName',
    },
    {
        Header: filters.lastName,
        accessor: 'person.information.lastName',
    },
    {
        Header: filters.email,
        accessor: 'email',
    },
    {
        Header: filters.type,
        accessor: 'type',
    },
    {
        Header: filters.profiles,
        accessor: 'profiles',
    }
]

export {
    tableColumns,
    filters
}
