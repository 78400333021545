import React, {Component} from "react";
import {blue700, blue600} from "material-ui/styles/colors";
import {Row, Col} from "react-flexbox-grid";
import Paper from "material-ui/Paper";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import _ from "lodash";
import {LBLEMPLOYESS} from "../../constants/labels";
import FloatingActionButtonTooltip from "../fields/FloatingActionButtonTooltip/index.tsx";
import {FetchCompanyParam} from "../../actions/info/companies_info_form";
import ButtonBack from "../fields/buttonBack/buttonBack";
import DownloadFile from "../fields/DownloadFile/DownloadFile";
import UploadFile from "../fields/UploadFile/UploadFile";
import AuthComponent from "../AuthComponent";
import SpinnerLoading from "../SpinnerLoading/componentSpinner";
import TablePagination from "../TablePagination/tablePagination";
import TagsEmployee from "../TagsEmployee/TagsEmployee";

const stylePaper = {
    marginTop: 20,
    marginBottom: 20,
};

class EmployeesGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {textValueTag: null, textTag: null};
        this._onChangeTagForTable = this._onChangeTagForTable.bind(this);
        this._onChangeValueTagForTable = this._onChangeValueTagForTable.bind(this);
    }

    _onChangeTagForTable(value) {
        this.setState({textTag: value});
    }

    _onChangeValueTagForTable(value) {
        this.setState({textValueTag: value});
    }

    UNSAFE_componentWillMount() {
        const {params, FetchCompanyParam} = this.props;
        FetchCompanyParam(params._id);
    }

    render() {
        const {params} = this.props;
        const {textTag, textValueTag} = this.state;
        let urlBack = `/admin/companies/info/${_.get(params, "_id")}`;
        if (_.isEqual(_.get(params, "profile"), "ref")) {
            urlBack = `/admin/companies/info/projects/${_.get(params, "_id")}`;
        }

        const onCreate = () => {
            this.props.history.push(`/admin/employees/create/${_.get(
                    params,
                    "profile"
                )}/${this.props.params._id}`
            );
        };

        const body = [
            {
                field: "id",
                title: "Identificación",
                filter: true,
            },
            {
                field: "nameText",
                title: "Nombre",
                filter: true,
            },
            {
                field: "lastNameText",
                title: "Apellido(s)",
                filter: true,
            },
            {
                field: "email",
                title: "Correo electrónico",
            },
            {
                actions: true,
                title: "Operaciones",
            },
        ];
        let extra = {
            company: _.get(params, "_id"),
            profile: _.get(params, "profile"),
        };
        if (!_.isNull(textValueTag) && !_.isNull(textTag)) {
            extra = _.chain(extra)
                .set("textTag", textTag)
                .set("textValueTag", textValueTag)
                .value();
        }
        return (
            <AuthComponent
                component={
                    <Row>
                        <Col xs={12} mdOffset={2} md={8}>
                            <Paper style={stylePaper}>
                                <ButtonBack url={urlBack}/>
                                <Row>
                                    <Col xs>
                                        <div
                                            style={{
                                                width: "100%",
                                                background: blue600,
                                                paddingTop: 5,
                                                paddingBottom: 5,
                                                color: "#FFF",
                                            }}
                                        >
                                            <h1 style={{textAlign: "center", fontWeight: 400}}>
                                                {_.get(this, "props.company.businessName")}
                                            </h1>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs>
                                        <div
                                            style={{
                                                width: "100%",
                                                background: blue700,
                                                padding: "5px 0",
                                                color: "#FFF",
                                            }}
                                        >
                                            <h2 style={{textAlign: "center", fontWeight: 400}}>
                                                {LBLEMPLOYESS.titleGrid}
                                            </h2>
                                        </div>
                                    </Col>
                                </Row>
                                <AuthComponent
                                    component={
                                        <Row style={{paddingTop: 15, paddingBottom: 15}}>
                                            <Col xs style={{textAlign: "center"}}>
                                                <DownloadFile
                                                    storeName="employees"
                                                    id={_.get(this, "props.params._id")}
                                                />
                                            </Col>
                                            <Col xs style={{textAlign: "center"}}>
                                                <UploadFile
                                                    storeName="employees"
                                                    id={_.get(this, "props.params._id")}
                                                />
                                            </Col>
                                        </Row>
                                    }
                                    permission="backend/employees:read"
                                    type="component"
                                />
                                <AuthComponent
                                    component={
                                        <FloatingActionButtonTooltip
                                            textTooltip={LBLEMPLOYESS.tooltips.btnCreate}
                                            component={Link}
                                            onClick={onCreate}
                                        />
                                    }
                                    permission="backend/employees:write"
                                    type="component"
                                />
                                <Row>
                                    <Col xs>
                                        <TagsEmployee
                                            companyId={_.get(params, "_id")}
                                            URL={`/employees/${_.get(params, "_id")}`}
                                            version={1}
                                            extra={{
                                                company: _.get(params, "_id"),
                                                profile: _.get(params, "profile"),
                                            }}
                                            body={body}
                                            onChangeTagForTable={this._onChangeTagForTable}
                                            onChangeValueTagForTable={this._onChangeValueTagForTable}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs>
                                        <TablePagination
                                            name="employees"
                                            URL={`/employees/${_.get(params, "_id")}`}
                                            version={1}
                                            body={body}
                                            selection
                                            fieldSelection="_id"
                                            extra={extra}
                                        />
                                    </Col>
                                </Row>
                            </Paper>
                        </Col>
                        <SpinnerLoading/>
                    </Row>
                }
                permission="backend/employees:read"
                type="url"
            />
        );
    }
}

function mapStateToProps({companies}) {
    return {
        company: companies.company.data
    };
}

export default connect(mapStateToProps, {FetchCompanyParam})(EmployeesGrid);
