import PropTypes from "prop-types";
import React, { Component } from "react";
import { SketchPicker } from "react-color";
import { reduxForm } from "redux-form";
import { Row, Col } from "react-flexbox-grid";
import Paper from "material-ui/Paper";
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from "material-ui/Table";
import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";
import FlatButton from "material-ui/FlatButton";
import _ from "lodash";
import MenuItem from "material-ui/MenuItem";
import { blue700 } from "material-ui/styles/colors";
import { saveConditionsGrouperQuestions } from "../actions/surveys_form";
import { FetchSurveyParam } from "../actions/info/surveys_info_form";
import { LBLSURVEYS } from "Labels";
import { toggleSnackbar } from "../actions/commons";
import DeleteEntityModal from "../components/deleteEntityModal/component";
import PureInput from "../components/PureInput";
import ButtonBack from "../components/fields/buttonBack/buttonBack";
import TreeGroupers from "../components/treeGroupers/treeGroupers";
import SelectFieldFormat from "../components/fields/SelectFieldFormat/SelectFieldFormat";
import PureInputColorPicker from "../components/PureInputColorPicker";
import AuthComponent from "../components/AuthComponent";
import { alphabet } from "../lib/nomenclatures";

const style = {
  margin: 20,
  float: "right",
};
const stylePaper = {
  marginTop: 20,
  marginBottom: 20,
};
const popover = {
  position: "absolute",
  zIndex: 2,
};
const cover = {
  position: "fixed",
  top: 20,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: -2,
};

class GrouperConditionalsNews extends Component {
  constructor(props) {
    super(props);
    this._saveConditionalGrouper = this._saveConditionalGrouper.bind(this);
    this._addConditionalGrouper = this._addConditionalGrouper.bind(this);
    this.renderConditionals = this.renderConditionals.bind(this);
    this.handleChangeComplete = this.handleChangeComplete.bind(this);
    this.state = {
      colorsSelected: "#1976d2",
      displayColorPicker: false,
    };
  }

  handleChangeComplete(color) {
    this.setState({ colorsSelected: _.get(color, "hex") });
  }

  handleClickColorPicker = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleCloseColorPicker = () => {
    this.setState({ displayColorPicker: false });
  };

  UNSAFE_componentWillMount() {
    const { FetchSurveyParam, params } = this.props;
    FetchSurveyParam(params.survey);
  }

  _saveConditionalGrouper() {
    const {
      fields: { _id, conditionals },
      typeEvaluation,
      resetForm,
      saveConditionsGrouperQuestions,
      toggleSnackbar,
      params,
    } = this.props;
    let conditions;
    let validValues = true;
    const validValuesPriorityAndEquivalence = true;
    if (_.isEqual(typeEvaluation, "summatory")) {
      conditions = _.map(conditionals, conditional => {
        if (
          _.isEqual(conditional.reportDescription.value, "") ||
          _.isEqual(conditional.descriptionUser.value, "") ||
          _.isEqual(conditional.priority.value, "") ||
          _.isEqual(conditional.equivalence.value, "") ||
          _.isUndefined(conditional.priority.value) ||
          _.isUndefined(conditional.equivalence.value)
        ) {
          validValues = false;
        }
        return {
          initValue: conditional.initValue.value,
          endValue: conditional.endValue.value,
          reportDescription: conditional.reportDescription.value,
          descriptionUser: conditional.descriptionUser.value,
          priority: conditional.priority.value,
          equivalence: conditional.equivalence.value,
          color:
            _.isUndefined(conditional.color.value) ||
            _.isEqual(conditional.color.value, "")
              ? `#${((Math.random() * 0xffffff) << 0).toString(16)}`
              : conditional.color.value,
        };
      });
    } else {
      conditions = conditionals.map(conditional => {
        if (
          _.isEqual(conditional.reportDescription.value, "") ||
          _.isEqual(conditional.descriptionUser.value, "") ||
          _.isEqual(conditional.priority.value, "") ||
          _.isEqual(conditional.equivalence.value, "") ||
          _.isUndefined(conditional.priority.value) ||
          _.isUndefined(conditional.equivalence.value)
        ) {
          validValues = false;
        }
        return {
          initValue: conditional.initValue.value,
          endValue: conditional.endValue.value,
          reportDescription: conditional.reportDescription.value,
          descriptionUser: conditional.descriptionUser.value,
          category: conditional.category.value,
          priority: conditional.priority.value,
          equivalence: conditional.equivalence.value,
          color:
            _.isUndefined(conditional.color.value) ||
            _.isEqual(conditional.color.value, "")
              ? `#${((Math.random() * 0xffffff) << 0).toString(16)}`
              : conditional.color.value,
        };
      });
    }
    if (validValues) {
      saveConditionsGrouperQuestions(_id.value, conditions)
        .then(
          data => {
            if (_.get(data, "error") || _.get(data, "payload.status") !== 200) {
              toggleSnackbar(true, LBLSURVEYS.metrical.msg.errorSave);
            } else {
              resetForm();
              toggleSnackbar(true, LBLSURVEYS.metrical.msg.successSave);
              this.props.history.push(
                `/admin/surveys/groupers/${_.get(params, "survey")}`
              );
            }
          },
          reason => {
            toggleSnackbar(true, LBLSURVEYS.metrical.msg.errorSave);
          }
        )
        .catch(data => {
          toggleSnackbar(true, LBLSURVEYS.metrical.msg.errorSave);
        });
    } else {
      toggleSnackbar(true, "Hay valores vacíos");
    }
  }

  _addConditionalGrouper(formData) {
    const {
      fields: { conditionals },
      typeEvaluation,
      resetForm,
    } = this.props;
    const validArgument = true;
    if (validArgument) {
      if (_.isEqual(typeEvaluation, "summatory")) {
        conditionals.addField({
          initValue: _.get(formData, "initValue"),
          endValue: _.get(formData, "endValue"),
          reportDescription: _.get(formData, "reportDescription"),
          descriptionUser: _.get(formData, "descriptionUser"),
          priority: _.get(formData, "priority"),
          equivalence: _.get(formData, "equivalence"),
          color: this.state.colorsSelected,
        });
      } else {
        conditionals.addField({
          initValue: _.get(formData, "initValue"),
          endValue: _.get(formData, "endValue"),
          reportDescription: _.get(formData, "reportDescription"),
          descriptionUser: _.get(formData, "descriptionUser"),
          category: _.get(formData, "category"),
          priority: _.get(formData, "priority"),
          equivalence: _.get(formData, "equivalence"),
          color: this.state.colorsSelected,
        });
      }
      resetForm();
    }
  }

  renderConditionals() {
    const {
      fields: { conditionals },
      locked,
      typeEvaluation,
      arrayPriority,
      arrayEquivalence,
    } = this.props;
    const configDeleteModal = {
      display: "table-cell",
    };
    return (
      <Table onRowSelection={this._onRowSelection} selectable={false}>
        <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
          <TableRow>
            <TableHeaderColumn style={{ width: 20 }}>#</TableHeaderColumn>
            <TableHeaderColumn style={{ width: 20 }}>
              {LBLSURVEYS.configConditional.initValue}
            </TableHeaderColumn>
            <TableHeaderColumn style={{ width: 20 }}>
              {LBLSURVEYS.configConditional.endValue}
            </TableHeaderColumn>
            <TableHeaderColumn colSpan={3}>
              {LBLSURVEYS.configConditional.descriptionReport}
            </TableHeaderColumn>
            <TableHeaderColumn colSpan={3}>
              {LBLSURVEYS.configConditional.descriptionUser}
            </TableHeaderColumn>
            {!_.isEqual(typeEvaluation, "summatory") && (
              <TableHeaderColumn>
                {LBLSURVEYS.metrical.grouperQuestions.frecuency}
              </TableHeaderColumn>
            )}
            <TableHeaderColumn>
              {LBLSURVEYS.configConditional.priority}
            </TableHeaderColumn>
            <TableHeaderColumn>
              {LBLSURVEYS.configConditional.equivalence}
            </TableHeaderColumn>
            <TableHeaderColumn />
          </TableRow>
        </TableHeader>
        <TableBody displayRowCheckbox={false}>
          {conditionals.map((conditional, index) => (
            <TableRow key={index}>
              <TableRowColumn style={{ whiteSpace: "normal", width: 20 }}>
                <PureInputColorPicker field={conditional.color} />
              </TableRowColumn>
              <TableRowColumn style={{ whiteSpace: "normal", width: 20 }}>
                {conditional.initValue.value}
              </TableRowColumn>
              <TableRowColumn style={{ whiteSpace: "normal", width: 20 }}>
                {conditional.endValue.value}
              </TableRowColumn>
              <TableRowColumn style={{ whiteSpace: "normal" }} colSpan={3}>
                <PureInput
                  type="text"
                  field={conditional.reportDescription}
                  errorText={
                    conditional.reportDescription.touched &&
                    conditional.reportDescription.error
                      ? conditional.reportDescription.error
                      : ""
                  }
                  multiLine
                  fullWidth
                  rows={1}
                  style={{ fontSize: 14 }}
                />
              </TableRowColumn>
              <TableRowColumn style={{ whiteSpace: "normal" }} colSpan={3}>
                <PureInput
                  type="text"
                  field={conditional.descriptionUser}
                  errorText={
                    conditional.descriptionUser.touched &&
                    conditional.descriptionUser.error
                      ? conditional.descriptionUser.error
                      : ""
                  }
                  multiLine
                  fullWidth
                  rows={1}
                  style={{ fontSize: 14 }}
                />
              </TableRowColumn>
              {!_.isEqual(typeEvaluation, "summatory") && (
                <TableRowColumn style={{ whiteSpace: "normal" }}>
                  {conditional.category.value}
                </TableRowColumn>
              )}
              <TableRowColumn style={{ whiteSpace: "normal" }}>
                <SelectFieldFormat
                  config={{
                    value: conditional.priority.value,
                    style: {
                      width: 60,
                      marginTop: 17,
                      marginBottom: -3,
                    },
                    errorText:
                      conditional.priority.touched && conditional.priority.error
                        ? conditional.priority.error
                        : "",
                    onChange: (e, index, value) =>
                      conditional.priority.onChange(value),
                  }}
                >
                  {_.map(arrayPriority, (prior, index) => (
                    <MenuItem
                      key={`conditional-priority-${index}`}
                      value={prior}
                      primaryText={prior}
                    />
                  ))}
                </SelectFieldFormat>
              </TableRowColumn>
              <TableRowColumn style={{ whiteSpace: "normal" }}>
                <SelectFieldFormat
                  config={{
                    value: conditional.equivalence.value,
                    style: {
                      width: 60,
                      marginTop: 17,
                      marginBottom: -3,
                    },
                    errorText:
                      conditional.equivalence.touched &&
                      conditional.equivalence.error
                        ? conditional.equivalence.error
                        : "",
                    onChange: (e, index, value) =>
                      conditional.equivalence.onChange(value),
                  }}
                >
                  {_.map(arrayEquivalence, (equiv, index) => (
                    <MenuItem
                      key={`conditional-equivalence-${index}`}
                      value={equiv}
                      primaryText={equiv}
                    />
                  ))}
                </SelectFieldFormat>
              </TableRowColumn>
              <TableRowColumn style={{ whiteSpace: "normal" }}>
                <AuthComponent
                  component={
                    <DeleteEntityModal
                      tooltip={LBLSURVEYS.metrical.buttons.delete}
                      title={LBLSURVEYS.metrical.titleDeleteCondition}
                      message={LBLSURVEYS.metrical.msg.deleteCondition}
                      fn={() => conditionals.removeField(index)}
                      args={[conditional]}
                      valid={locked}
                      style={configDeleteModal}
                    />
                  }
                  permission="backend/conditionals:delete"
                  type="component"
                />
              </TableRowColumn>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  render() {
    const {
      fields: {
        initValue,
        endValue,
        reportDescription,
        priority,
        equivalence,
        descriptionUser,
        category,
      },
      typeEvaluation,
      categories,
      surveyName,
      handleSubmit,
      arrayPriority,
      arrayEquivalence,
      locked,
      params,
    } = this.props;
    return (
      <Row>
        <AuthComponent
          component={
            <Col xs={12} mdOffset={1} md={10}>
              <Paper style={stylePaper}>
                <Row>
                  <Col xs={12}>
                    <div
                      style={{
                        width: "100%",
                        background: blue700,
                        paddingTop: 5,
                        paddingBottom: 5,
                        color: "#FFF",
                      }}
                    >
                      <Row>
                        <Col xsOffset={1} xs={10}>
                          <ButtonBack
                            url={`/admin/surveys/groupers/${this.props.params.survey}`}
                          />
                          <div>
                            <h1
                              style={{
                                textAlign: "center",
                                fontWeight: 400,
                              }}
                            >
                              {LBLSURVEYS.metrical.titleConditional}
                            </h1>
                            <h2
                              style={{
                                textAlign: "center",
                                fontWeight: 400,
                              }}
                            >
                              {surveyName}
                            </h2>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xsOffset={1} xs={10}>
                    <TreeGroupers idGrouper={_.get(params, "grouper")} />
                  </Col>
                </Row>
                <Row>
                  <Col xsOffset={1} xs={10}>
                    <form
                      onSubmit={handleSubmit(this._addConditionalGrouper)}
                      style={locked ? { display: "none" } : {}}
                    >
                      <Row>
                        <Col xs={12}>
                          <Row>
                            <Col xs={12} mdOffset={1} md={4}>
                              <Row>
                                <Col xs>
                                  <TextField
                                    hintText=""
                                    floatingLabelText={
                                      LBLSURVEYS.metrical.fields.initValue
                                    }
                                    style={{
                                      width: 95,
                                    }}
                                    type="number"
                                    fullWidth
                                    errorText={
                                      initValue.touched && initValue.error
                                        ? initValue.error
                                        : ""
                                    }
                                    {...initValue}
                                  />
                                </Col>
                                <Col xs>
                                  <TextField
                                    hintText=""
                                    floatingLabelText={
                                      LBLSURVEYS.metrical.fields.endValue
                                    }
                                    style={{
                                      width: 95,
                                    }}
                                    type="number"
                                    fullWidth
                                    errorText={
                                      endValue.touched && endValue.error
                                        ? endValue.error
                                        : ""
                                    }
                                    {...endValue}
                                  />
                                </Col>
                              </Row>
                            </Col>
                            <Col xs={12} mdOffset={1} md={4}>
                              <TextField
                                hintText=""
                                floatingLabelText={
                                  LBLSURVEYS.metrical.fields.reportDescription
                                }
                                type="text"
                                multiLine
                                row={1}
                                errorText={
                                  reportDescription.touched &&
                                  reportDescription.error
                                    ? reportDescription.error
                                    : ""
                                }
                                {...reportDescription}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} mdOffset={1} md={4}>
                              <TextField
                                hintText=""
                                floatingLabelText={
                                  LBLSURVEYS.metrical.fields.descriptionUser
                                }
                                type="text"
                                multiLine
                                row={1}
                                errorText={
                                  descriptionUser.touched &&
                                  descriptionUser.error
                                    ? descriptionUser.error
                                    : ""
                                }
                                {...descriptionUser}
                              />
                            </Col>
                            <Col xs={12} mdOffset={1} md={4}>
                              <SelectFieldFormat
                                config={{
                                  floatingLabelText:
                                    LBLSURVEYS.metrical.grouperQuestions
                                      .frecuency,
                                  value: category.value,
                                  autoWidth: true,
                                  disabled: _.isEqual(
                                    typeEvaluation,
                                    "summatory"
                                  ),
                                  errorText:
                                    category.touched && category.error
                                      ? category.error
                                      : "",
                                  onChange: (e, index, value) =>
                                    category.onChange(value),
                                }}
                              >
                                {categories.map((value, index) => {
                                  return (
                                    <MenuItem
                                      key={index}
                                      value={value}
                                      primaryText={value}
                                    />
                                  );
                                })}
                              </SelectFieldFormat>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={10} xsOffset={1} sm={4} smOffset={1}>
                              <SelectFieldFormat
                                config={{
                                  floatingLabelText: "Priorización",
                                  value: priority.value,
                                  errorText:
                                    priority.touched && priority.error
                                      ? priority.error
                                      : "",
                                  onChange: (e, index, value) =>
                                    priority.onChange(value),
                                }}
                              >
                                {_.map(arrayPriority, (prior, index) => (
                                  <MenuItem
                                    key={`priority-${index}`}
                                    value={prior}
                                    primaryText={prior}
                                  />
                                ))}
                              </SelectFieldFormat>
                            </Col>
                            <Col xs={10} xsOffset={1} sm={4} smOffset={1}>
                              <SelectFieldFormat
                                config={{
                                  floatingLabelText: "Equivalencia",
                                  value: equivalence.value,
                                  errorText:
                                    equivalence.touched && equivalence.error
                                      ? equivalence.error
                                      : "",
                                  onChange: (e, index, value) =>
                                    equivalence.onChange(value),
                                }}
                              >
                                {_.map(arrayEquivalence, (equiv, index) => (
                                  <MenuItem
                                    key={`equivalence-${index}`}
                                    value={equiv}
                                    primaryText={equiv}
                                  />
                                ))}
                              </SelectFieldFormat>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              xs={12}
                              mdOffset={1}
                              md={4}
                              style={{
                                paddingTop: 30,
                              }}
                            >
                              Seleccione el color en la gráfica:
                              <div
                                onClick={this.handleClickColorPicker}
                                style={{
                                  border: 1,
                                  borderStyle: "solid",
                                  borderColor: "#999",
                                  width: 29,
                                  height: 29,
                                  cursor: "pointer",
                                  borderRadius: "100%",
                                  display: "inline-block",
                                  margin: "12px 0 -12px 7px",
                                }}
                              >
                                <div
                                  style={{
                                    width: 21,
                                    height: 21,
                                    borderRadius: "100%",
                                    margin: "4px 0 0px 4px",
                                    background: this.state.colorsSelected,
                                  }}
                                />
                              </div>
                            </Col>
                            {this.state.displayColorPicker ? (
                              <div style={popover}>
                                <div
                                  style={cover}
                                  onClick={this.handleCloseColorPicker}
                                />
                                <SketchPicker
                                  color={this.state.colorsSelected}
                                  onChangeComplete={this.handleChangeComplete}
                                  presetColors={[
                                    "#F44E3B",
                                    "#FE5700",
                                    "#FE9200",
                                    "#FCDC00",
                                    "#DBDF00",
                                    "#A4DD00",
                                    "#68CCCA",
                                    "#73D8FF",
                                    "#AEA1FF",
                                    "#FDA1FF",
                                    "#999999",
                                    "#4D4D4D",
                                  ]}
                                />
                              </div>
                            ) : null}
                          </Row>
                        </Col>
                        <Col xsOffset={2} xs={8}>
                          <div>
                            <AuthComponent
                              component={
                                <FlatButton
                                  label={`${LBLSURVEYS.metrical.buttons.addGrouperConditional}`}
                                  secondary
                                  type="submit"
                                  style={style}
                                />
                              }
                              permission="backend/conditionals:write"
                              type="component"
                            />
                          </div>
                        </Col>
                      </Row>
                    </form>
                    <Row>
                      <Col xs={12}>
                        <Row style={{ marginTop: 50 }}>
                          <Col xs={12}>{this.renderConditionals()}</Col>
                        </Row>
                      </Col>
                    </Row>
                    <AuthComponent
                      component={
                        <RaisedButton
                          label={
                            LBLSURVEYS.metrical.buttons.saveGrouperConditional
                          }
                          onClick={this._saveConditionalGrouper}
                          secondary
                          disabled={locked}
                          style={style}
                        />
                      }
                      permission="backend/conditionals:write"
                      type="component"
                    />
                  </Col>
                </Row>
              </Paper>
            </Col>
          }
          permission="backend/surveyResults:write"
          type="url"
        />
      </Row>
    );
  }
}

const requireFields =
  (...names) =>
  data =>
    names.reduce((errors, index) => {
      if (!data[index]) {
        errors[index] = "Es requerido";
      }
      return errors;
    }, {});

const validateOptions = requireFields(
  "descriptionUser",
  "reportDescription",
  "priority",
  "equivalence"
);

const validate = (values, dispatch) => {
  const errors = {};
  if (_.isEqual(_.get(dispatch, "typeEvaluation"), "frecuency")) {
    let validNum = false;
    if (!values.category) {
      errors.category = LBLSURVEYS.metrical.validations.category;
      validNum = false;
    } else {
      validNum = true;
    }
    if (
      _.isUndefined(values.initValue) ||
      _.isEqual(values.initValue, "") ||
      _.isNull(values.initValue) ||
      _.isNaN(values.initValue) ||
      !_.isNumber(values.initValue)
    ) {
      errors.initValue = LBLSURVEYS.metrical.validations.initValue;
      validNum = false;
    } else {
      validNum = true;
    }
    if (
      _.isUndefined(values.endValue) ||
      _.isEqual(values.endValue, "") ||
      _.isNull(values.endValue) ||
      _.isNaN(values.endValue) ||
      !_.isNumber(values.endValue)
    ) {
      errors.endValue = LBLSURVEYS.metrical.validations.endValue;
      validNum = false;
    } else {
      validNum = true;
    }
    if (validNum) {
      if (_.size(values.conditionals) !== 0) {
        values.conditionals.map(function (condition) {
          if (_.isEqual(condition.category, values.category)) {
            if (!_.lte(parseInt(values.initValue), parseInt(values.endValue))) {
              errors.initValue = LBLSURVEYS.metrical.validations.lessInitValue;
            } else {
              values.conditionals.map(function (condition2) {
                const initValue = parseInt(values.initValue);
                const endValue = parseInt(values.endValue);
                const CondinitValue = parseInt(condition2.initValue);
                const CondendValue = parseInt(condition2.endValue);
                if (
                  !(
                    (_.lt(initValue, CondinitValue) &&
                      _.lt(endValue, CondinitValue)) ||
                    (_.gt(initValue, CondendValue) &&
                      _.gt(endValue, CondendValue))
                  ) &&
                  _.isEqual(condition2.category, values.category)
                ) {
                  errors.initValue =
                    LBLSURVEYS.metrical.validations.invalidInitValueEndValue;
                }
              });
            }
          }
        });
      } else if (
        !_.lte(parseInt(values.initValue), parseInt(values.endValue))
      ) {
        errors.initValue = LBLSURVEYS.metrical.validations.lessInitValue;
      }
    }
  } else {
    let validNum = false;
    if (
      _.isUndefined(values.initValue) ||
      _.isEqual(values.initValue, "") ||
      _.isNull(values.initValue) ||
      _.isNaN(values.initValue) ||
      !_.isNumber(values.initValue)
    ) {
      errors.initValue = LBLSURVEYS.metrical.validations.initValue;
      validNum = false;
    } else {
      validNum = true;
    }
    if (
      _.isUndefined(values.endValue) ||
      _.isEqual(values.endValue, "") ||
      _.isNull(values.endValue) ||
      _.isNaN(values.endValue) ||
      !_.isNumber(values.endValue)
    ) {
      errors.endValue = LBLSURVEYS.metrical.validations.endValue;
      validNum = false;
    } else {
      validNum = true;
    }
    if (validNum) {
      if (_.size(values.conditionals) !== 0) {
        if (!_.lte(parseInt(values.initValue), parseInt(values.endValue))) {
          errors.initValue = LBLSURVEYS.metrical.validations.lessInitValue;
        } else {
          values.conditionals.map(function (condition) {
            const initValue = parseInt(values.initValue);
            const endValue = parseInt(values.endValue);
            const CondinitValue = parseInt(condition.initValue);
            const CondendValue = parseInt(condition.endValue);
            if (
              !(
                (_.lt(initValue, CondinitValue) &&
                  _.lt(endValue, CondinitValue)) ||
                (_.gt(initValue, CondendValue) && _.gt(endValue, CondendValue))
              )
            ) {
              errors.initValue =
                LBLSURVEYS.metrical.validations.invalidInitValueEndValue;
            }
          });
        }
      } else if (
        !_.lte(parseInt(values.initValue), parseInt(values.endValue))
      ) {
        errors.initValue = LBLSURVEYS.metrical.validations.lessInitValue;
      }
    }
  }
  if (!values.reportDescription) {
    errors.reportDescription =
      LBLSURVEYS.metrical.validations.reportDescription;
  }
  if (!values.descriptionUser) {
    errors.descriptionUser = LBLSURVEYS.metrical.validations.descriptionUser;
  }
  if (!values.priority) {
    errors.priority = LBLSURVEYS.metrical.validations.priority;
  }
  if (!values.equivalence) {
    errors.equivalence = LBLSURVEYS.metrical.validations.equivalence;
  }
  errors.conditionals = values.conditionals.map(validateOptions);

  return errors;
};

function mapStateToProps({ groupersQuestion, surveys }, ownProps) {
  const grouper = _.get(groupersQuestion, "grouper.data");
  const categories = _.get(groupersQuestion, "grouper.categories");
  const arrayPriority = [];
  const arrayEquivalence = alphabet;
  for (let i = 1; i <= 100; i++) {
    arrayPriority.push(i);
  }
  return {
    locked: _.get(surveys, "all.data.locked", true),
    typeEvaluation: _.isUndefined(_.get(grouper, "typeEvaluation"))
      ? "summatory"
      : _.get(grouper, "typeEvaluation"),
    name: _.isUndefined(_.get(grouper, "name")) ? "" : _.get(grouper, "name"),
    surveyName: _.get(surveys, "all.data.name"),
    categories: _.isUndefined(categories) ? [] : _.orderBy(categories, "asc"),
    arrayPriority,
    arrayEquivalence,
    initialValues: {
      _id: ownProps.params.grouper,
      conditionals: _.isUndefined(_.get(grouper, "conditions"))
        ? ""
        : _.get(grouper, "conditions"),
    },
  };
}

GrouperConditionalsNews.propTypes = {
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  router: PropTypes.object,
};

export default reduxForm(
  {
    form: "GrouperConditionalsNewsForm",
    fields: [
      "_id",
      "initValue",
      "endValue",
      "reportDescription",
      "descriptionUser",
      "category",
      "priority",
      "equivalence",
      "conditionals[].initValue",
      "conditionals[].endValue",
      "conditionals[].reportDescription",
      "conditionals[].descriptionUser",
      "conditionals[].category",
      "conditionals[].priority",
      "conditionals[].equivalence",
      "conditionals[].color",
    ],
    validate,
  },
  mapStateToProps,
  { saveConditionsGrouperQuestions, toggleSnackbar, FetchSurveyParam }
)(GrouperConditionalsNews);
