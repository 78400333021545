import Immutable from 'immutable';
import _ from 'lodash';
import {SEND_MESSAGE} from '../actions/chat';


const initialState = Immutable.Map({
    messages: Immutable.List()
});

export default (state = initialState, action) => {
    switch (action.type) {
        case SEND_MESSAGE:
            var currentMessages = state.get('messages');
            return state.set('messages', currentMessages.push(action.message));
        default:
            return state
    }
};
