export  const SHOW_EXIT_SURVEY="SHOW_EXIT_SURVEY";


export function showModalExitSurvey(open) {

    return {
        type: SHOW_EXIT_SURVEY,
        open
    }
}
