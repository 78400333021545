import PropTypes from "prop-types";
import React, { Component } from "react";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import { blue700 } from "material-ui/styles/colors";
import IconButton from "material-ui/IconButton";
import RaisedButton from "material-ui/RaisedButton";
import LockIcon from "material-ui/svg-icons/action/lock";
import _ from "lodash";

class ConfirmEntityModal extends Component {
    constructor(props) {
        super(props);
        const { fn, args } = props;
        this.state = {
            open: false,
        };
        this._handleActionOk = this._handleActionOk.bind(this, fn, args);
        this._handleCloseAction = this._handleCloseAction.bind(this);
        this._handleOpenAction = this._handleOpenAction.bind(this);
        this.renderMessage = this.renderMessage.bind(this);
        this.renderTypeButton = this.renderTypeButton.bind(this);
    }

    _handleActionOk(fn, args) {
        this.setState({
            open: false,
        });
        fn.apply(this, args);
    }

    _handleCloseAction() {
        this.setState({
            open: false,
        });
    }

    _handleOpenAction() {
        this.setState({
            open: true,
        });
    }

    renderMessage(textValid, message) {
        if (_.isEqual(textValid, "")) {
            return message;
        } 
            return textValid;
        
    }

    renderTypeButton() {
        const { iconButton, type, tooltip, style } = this.props;

        const styleIcon = _.isUndefined(style) ? {} : style;
        const tooltipStyle = {
            zIndex: 100,
        };

        const typeButton = _.isUndefined(type) ? "icon" : type;
        const button = _.isUndefined(iconButton) ? <LockIcon /> : iconButton;

        if (_.isEqual("icon", typeButton)) {
            return (
                <IconButton
                    tooltip={tooltip}
                    style={styleIcon}
                    tooltipPosition="top-center"
                    children={button}
                    onClick={this._handleOpenAction}
                    tooltipStyles={tooltipStyle}
                />
            );
        } if (_.isEqual("button", typeButton)) {
            return (
                <RaisedButton
                    label={tooltip}
                    secondary
                    onClick={this._handleOpenAction}
                    style={styleIcon}
                />
            );
        }
    }

    render() {
        const { title, message, config, valid } = this.props;
        const textValid = _.isUndefined(valid) ? "" : valid;
        const actions = [
            <FlatButton
                label="Cancelar"
                secondary
                onClick={this._handleCloseAction}
            />,
            <FlatButton
                label="Aceptar"
                primary
                disabled={!_.isEqual(textValid, "")}
                keyboardFocused
                onClick={this._handleActionOk}
            />,
        ];
        return (
            <span>
                {this.renderTypeButton()}
                <Dialog
                    {...config}
                    title={title}
                    actions={actions}
                    titleStyle={{ textAlign: "center", color: blue700 }}
                    contentStyle={{
                        maxWidth: 600,
                        minWidth: 300,
                        textAlign: "justify",
                    }}
                    modal={false}
                    open={this.state.open}
                    onRequestClose={this._handleCloseAction}
                >
                    <h3 style={{ fontWeight: 200, textAlign: "justify" }}>
                        {this.renderMessage(textValid, message)}
                    </h3>
                </Dialog>
            </span>
        );
    }
}

ConfirmEntityModal.propTypes = {
    title: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    config: PropTypes.object,
    fn: PropTypes.func.isRequired,
    args: PropTypes.array.isRequired,
    style: PropTypes.object,
    valid: PropTypes.string,
    iconButton: PropTypes.element,
    type: PropTypes.string,
};

export default ConfirmEntityModal;
