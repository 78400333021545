import PropTypes from "prop-types";
import React, {Component} from "react";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import {blue700} from "material-ui/styles/colors";
import IconButton from "material-ui/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import _ from "lodash";
import RaisedButton from "material-ui/RaisedButton";

class DeleteEntityModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
        this._handleDeleteAction = this._handleDeleteAction.bind(this);
        this._handleCloseAction = this._handleCloseAction.bind(this);
        this._handleOpenAction = this._handleOpenAction.bind(this);
    }

    _handleDeleteAction() {
        this.setState({
            open: false,
        });
        const {fn, args} = this.props;
        fn.apply(this, args);
    }

    _handleCloseAction() {
        this.setState({
            open: false,
        });
    }

    _handleOpenAction() {
        this.setState({
            open: true,
        });
    }

    renderTypeButton(
        typeButton,
        tooltip,
        validAction
    ) {
        // default typeButton is icon
        if (_.isEqual(typeButton, "icon") || _.isUndefined(typeButton)) {
            return (
                <IconButton tooltip={tooltip} disabled={validAction} onClick={this._handleOpenAction}
                            tooltipPosition="top-center">
                    <DeleteIcon/>
                </IconButton>
            );
        }
        if (_.isEqual(typeButton, "button")) {
            return (
                <RaisedButton
                    label={tooltip}
                    secondary
                    style={{margin: 12}}
                    onClick={this._handleOpenAction}
                    disabled={validAction}
                />
            );
        }
    }

    render() {
        const {
            title,
            tooltip,
            message,
            config,
            valid,
            typeButton,
            colorIconButton,
            positionTextIconButton,
        } = this.props;
        const validAction = _.isUndefined(valid) ? false : valid;
        const actions = [
            <FlatButton
                label="Cancelar"
                secondary
                onClick={this._handleCloseAction}
            />,
            <FlatButton
                label="Eliminar"
                primary
                disabled={validAction}
                keyboardFocused
                onClick={this._handleDeleteAction}
            />,
        ];

        return (
            <span>
        {this.renderTypeButton(
            typeButton,
            tooltip,
            validAction,
            colorIconButton,
            positionTextIconButton
        )}
                <Dialog
                    {...config}
                    title={title}
                    actions={actions}
                    titleStyle={{textAlign: "center", color: blue700}}
                    contentStyle={{
                        maxWidth: 600,
                        minWidth: 300,
                        textAlign: "justify",
                    }}
                    modal={false}
                    open={this.state.open}
                    onRequestClose={this._handleCloseAction}
                >
          <h3 style={{fontWeight: 200, textAlign: "justify"}}>{message}</h3>
        </Dialog>
      </span>
        );
    }
}

DeleteEntityModal.propTypes = {
    title: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    config: PropTypes.object,
    fn: PropTypes.func.isRequired,
    args: PropTypes.array.isRequired,
    valid: PropTypes.bool,
    typeButton: PropTypes.string,
    colorIconButton: PropTypes.string,
    positionTextIconButton: PropTypes.string,
};

export default DeleteEntityModal;
