import * as actions from "./constants";
import { axiosApiV2 } from "../../middleware/api";

export function openModalForm(
  id,
  code,
  name,
  title,
  location,
  idParent,
  editMode
) {
  return {
    type: actions.OPEN_MODAL_FORM,
    id,
    title,
    location,
    code,
    name,
    idParent,
    editMode,
  };
}

export function closeModal() {
  return {
    type: actions.CLOSE_MODAL_FORM,
  };
}

export function saveLocation(id, code, name, location, idParent, editMode) {
  switch (location) {
    case "country":
      return {
        type: actions.SAVE_LOCATIONS,
        payload: editMode
          ? axiosApiV2.put(`/location/country`, { id, code, name })
          : axiosApiV2.post(`/location/country`, { id, code, name }),
      };
    case "department":
      return {
        type: actions.SAVE_LOCATIONS,
        payload: editMode
          ? axiosApiV2.put(`/location/department`, {
              id,
              code,
              name,
              country: idParent,
            })
          : axiosApiV2.post(`/location/department`, {
              id,
              code,
              name,
              country: idParent,
            }),
      };
    case "city":
      return {
        type: actions.SAVE_LOCATIONS,
        payload: editMode
          ? axiosApiV2.put(`/location/city`, {
              id,
              code,
              name,
              department: idParent,
            })
          : axiosApiV2.post(`/location/city`, {
              id,
              code,
              name,
              department: idParent,
            }),
      };
  }
}
