import PropTypes from "prop-types";
import React from "react";
import AutoComplete from "material-ui/AutoComplete";
import _ from "lodash";

const mapValues = (dataSource) =>
    _.chain(dataSource)
        .map((t) => (t instanceof Object ? _.get(t, "value", null) : t))
        .filter((t) => !_.isEqual(t, ""))
        .filter((t) => !_.isNull(t))
        .value();

const PureInputAutoComplete = (props) => {
    const { field, dataSource, ...rest } = props;
    const handleUpdateInput = (inputValue) => {
        field.onChange(inputValue);
    };

    return (
        <AutoComplete
            dataSource={mapValues(dataSource)}
            maxSearchResults={4}
            searchText={field.value}
            filter={(searchText, key) =>
                _.deburr(_.toUpper(key)).indexOf(
                    _.deburr(_.toUpper(searchText))
                ) !== -1
            }
            openOnFocus
            onUpdateInput={handleUpdateInput}
            {...rest}
            {...field}
        />
    );
};

PureInputAutoComplete.propTypes = {
    field: PropTypes.shape(Object).isRequired,
    dataSource: PropTypes.arrayOf(Object).isRequired,
};

export default PureInputAutoComplete;
