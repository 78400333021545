import React, {Component} from "react";
import {
    Table,
    TableBody,
    TableHeader,
    TableHeaderColumn,
    TableRow,
    TableRowColumn,
} from "material-ui/Table";
import {Row, Col} from "react-flexbox-grid";
import Paper from "material-ui/Paper";
import RaisedButton from "material-ui/RaisedButton";
import TimelapseIcon from "material-ui/svg-icons/image/timelapse";
import PeopleIcon from "material-ui/svg-icons/social/people";
import PersonPinIcon from "material-ui/svg-icons/maps/person-pin";
import SurveyIcon from "material-ui/svg-icons/action/view-list";
import Divider from "material-ui/Divider";
import {Tab, Tabs} from "material-ui/Tabs";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Link} from "react-router-dom";
import _ from "lodash";
import {FetchProjectParam} from "../../actions/info/projects_info_form";
import {
    LBLCONSENTS,
    LBLSURVEYS,
    LBLPROJECTS,
    LBLEMPLOYESS,
} from "../../constants/labels";
import SendMessageModal from "../ModalSendEmail/modalSendEmail";
import ButtonBack from "../fields/buttonBack/buttonBack";
import SpinnerLoading from "../SpinnerLoading/componentSpinner";
import AuthComponent from "../AuthComponent";
import EmployeesProject from "./employeesProject/employeesProject";
import LapsesTimeInfoProjectComponent from "./projectInfo/lapsesTimeInfoProjectComponent";
import RefereesInfoProjectComponent from "./projectInfo/refereesInfoProjectComponent";
import GeneralInfoProjectComponent from "./projectInfo/generalInfoProjectComponent";

const stylePaper = {
    marginTop: 66,
    marginBottom: 20,
};
const styles = {
    headline: {
        fontSize: 24,
        paddingTop: 16,
        marginBottom: 12,
        fontWeight: 400,
    },
    slide: {
        height: "auto",
        padding: 0,
    },
};

let urlBack;
let urlConfigDay;
let urlSendEmail;
let urlAssocEmployee;
let urlPreviewSurvey;
let urlViewSurvey;

class ProjectsInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            slideIndex: 0,
            isReferee: false,
            tabIndex: 0,
        };

        this.handleChangeTab = this.handleChangeTab.bind(this);
    }

    handleChangeTab(value) {
        this.setState({
            tabIndex: value,
        });
    }

    shouldComponentUpdate(nextProps, nextState) {
        const {projects} = this.props;
        if (_.has(projects, "data.time.dayWeek")) {
            return (
                _.size(_.get(projects, "data.time.dayWeek")) !=
                _.size(_.get(nextProps, "projects.data.time.dayWeek")) ||
                _.size(_.get(projects, "data.employees")) !=
                _.size(_.get(nextProps, "projects.data.employees"))
            );
        }
        return true;
    }

    UNSAFE_componentWillMount() {
        const {FetchProjectParam, profile, params} = this.props;
        if (!_.isEmpty(profile)) {
            this.state = {
                isReferee: _.isEqual(_.get(profile, "role"), "referee"),
            };
        }

        if (_.isEqual(_.get(params, "profile"), "adm")) {
            urlBack = `/admin/companies/info/${params.company}`;
        } else {
            urlBack = `/admin/me/assignprojects`;
        }

        urlConfigDay = `/admin/projects/configDay/${_.get(params, "profile")}/`;
        urlSendEmail = `/admin/projects/email/employees/${_.get(
            params,
            "profile"
        )}/`;
        urlAssocEmployee = `/admin/projects/associate/employees/${_.get(
            params,
            "profile"
        )}/`;
        urlViewSurvey = `/admin/surveys/info/${_.get(params, "profile")}/`;
        urlPreviewSurvey = `/admin/surveys/appl/${_.get(params, "profile")}/`;

        FetchProjectParam(_.get(params, "_id"));
    }

    renderConsents(consents) {
        if (_.size(consents)) {
            return consents.map((consent, idx) => {
                return (
                    <TableRow key={`${consent._id}.${idx}`}>
                        <TableRowColumn style={{whiteSpace: "normal"}}>
                            {consent.value}
                        </TableRowColumn>
                        <TableRowColumn
                            colSpan="4"
                            style={{
                                whiteSpace: "normal",
                                textAlign: "justify",
                            }}
                        >
                            {consent.consent}
                        </TableRowColumn>
                    </TableRow>
                );
            });
        }
        return <TableRow/>;
    }

    render() {
        const {params, projects} = this.props;
        if (_.get(projects, "data")) {
            return (
                <Row>
                    <AuthComponent
                        component={
                            <Col xs={12} mdOffset={2} md={8}>
                                <Paper style={stylePaper}>
                                    <GeneralInfoProjectComponent
                                        projectId={_.get(params, "_id")}
                                        urlBase={`/admin/projects/edit/${_.get(
                                            params,
                                            "profile"
                                        )}/${_.get(params, "_id")}/${_.get(params, "company")}`}
                                        urlBaseColors={`/admin/projects/colors/${_.get(
                                            params,
                                            "profile"
                                        )}/${_.get(params, "_id")}/${_.get(params, "company")}`}
                                        urlBaseRanking={`/admin/projects/ranking/${_.get(
                                            params,
                                            "profile"
                                        )}/${_.get(params, "_id")}/${_.get(
                                            projects,
                                            "data.survey._id"
                                        )}/${_.get(params, "company")}`}
                                        urlBaseFilter={`/admin/projects/filter/init/${_.get(
                                            params,
                                            "profile"
                                        )}/${_.get(params, "_id")}/${_.get(params, "company")}`}
                                    />
                                    <Tabs
                                        onChange={this.handleChangeTab}
                                        value={this.state.tabIndex}
                                    >
                                        <Tab
                                            label={LBLPROJECTS.taplapse}
                                            icon={<TimelapseIcon/>}
                                            value={0}
                                        >
                                            <LapsesTimeInfoProjectComponent
                                                projectId={_.get(params, "_id")}
                                                urlBase={`${urlConfigDay}${_.get(
                                                    params,
                                                    "_id"
                                                )}/${_.get(params, "company")}`}
                                            />
                                        </Tab>
                                        <Tab
                                            label={LBLPROJECTS.tapEvaluators}
                                            icon={<PersonPinIcon/>}
                                            value={1}
                                        >
                                            <RefereesInfoProjectComponent
                                                projectId={_.get(params, "_id")}
                                                urlBase={`/admin/projects/edit_referees/${_.get(
                                                    params,
                                                    "profile"
                                                )}/${_.get(projects, "data._id")}/${_.get(
                                                    params,
                                                    "company"
                                                )}`}
                                            />
                                        </Tab>
                                        <Tab
                                            label={LBLPROJECTS.tapSurveys}
                                            icon={<SurveyIcon/>}
                                            value={2}
                                        >
                                            <div style={styles.slide}>
                                                <Row>
                                                    <Col xsOffset={1} xs={10}>
                                                        <h2
                                                            style={{
                                                                textAlign: "center",
                                                                fontWeight: 400,
                                                                marginTop: 40,
                                                                marginBottom: 0,
                                                            }}
                                                        >
                                                            {_.get(projects, "data.survey.name")}
                                                        </h2>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xsOffset={1} xs={10}>
                                                        <p style={{textAlign: "justify"}}>
                                                            {_.get(projects, "data.survey.description")}
                                                        </p>
                                                        <div style={{float: "right"}}>
                                                            <AuthComponent
                                                                component={
                                                                    <Link
                                                                        to={`${urlViewSurvey}${_.get(
                                                                            projects,
                                                                            "data.survey._id"
                                                                        )}/${_.get(params, "_id")}/${_.get(
                                                                            params,
                                                                            "company"
                                                                        )}`}
                                                                    >
                                                                        <RaisedButton
                                                                            label={LBLSURVEYS.tooltips.btnView}
                                                                            secondary
                                                                            style={{margin: 12}}
                                                                        />
                                                                    </Link>
                                                                }
                                                                permission="backend/surveys:read"
                                                                type="component"
                                                            />
                                                            <AuthComponent
                                                                component={
                                                                    <Link
                                                                        to={`${urlPreviewSurvey}${
                                                                            params.company
                                                                        }/${_.get(
                                                                            projects,
                                                                            "data.survey._id"
                                                                        )}/1/${_.get(params, "_id")}`}
                                                                    >
                                                                        <RaisedButton
                                                                            label={LBLSURVEYS.tooltips.btnPreview}
                                                                            secondary
                                                                            style={{margin: 12}}
                                                                        />
                                                                    </Link>
                                                                }
                                                                permission="backend/surveys:read"
                                                                type="component"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs>
                                                        <Divider/>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xsOffset={1} xs={10}>
                                                        <h2
                                                            style={{
                                                                textAlign: "center",
                                                                fontWeight: 400,
                                                                marginTop: 40,
                                                                marginBottom: 0,
                                                            }}
                                                        >
                                                            {LBLCONSENTS.label}
                                                        </h2>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs>
                                                        <Table selectable={false}>
                                                            <TableHeader
                                                                displaySelectAll={false}
                                                                adjustForCheckbox={false}
                                                            >
                                                                <TableRow>
                                                                    <TableHeaderColumn>
                                                                        {LBLCONSENTS.order}
                                                                    </TableHeaderColumn>
                                                                    <TableHeaderColumn colSpan="4">
                                                                        {LBLCONSENTS.label}
                                                                    </TableHeaderColumn>
                                                                </TableRow>
                                                            </TableHeader>
                                                            <TableBody displayRowCheckbox={false}>
                                                                {this.renderConsents(
                                                                    _.get(projects, "data.consent")
                                                                )}
                                                            </TableBody>
                                                        </Table>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Tab>
                                        <Tab
                                            label={LBLPROJECTS.tapEmployees}
                                            icon={<PeopleIcon/>}
                                            value={3}
                                        >
                                            <div style={styles.slide}>
                                                <Row>
                                                    <Col xs={12}>
                                                        <h2
                                                            style={{
                                                                textAlign: "center",
                                                                fontWeight: 400,
                                                                marginTop: 40,
                                                                marginBottom: 0,
                                                                padding: "5px 20px 20px 20px",
                                                            }}
                                                        >
                                                            {LBLEMPLOYESS.titleAssociated}
                                                        </h2>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xsOffset={1} xs={10}>
                                                        <div
                                                            style={{
                                                                padding: "5px 20px 5px 20px",
                                                                margin: 5,
                                                                float: "right",
                                                            }}
                                                        >
                                                            <AuthComponent
                                                                component={
                                                                    <Link
                                                                        to={`${urlSendEmail}${_.get(
                                                                            params,
                                                                            "_id"
                                                                        )}/${_.get(params, "company")}`}
                                                                    >
                                                                        <RaisedButton
                                                                            label={
                                                                                LBLEMPLOYESS.buttons.masiveOperations
                                                                            }
                                                                            secondary
                                                                            style={{
                                                                                display: "inline-block",
                                                                                margin: 7,
                                                                            }}
                                                                        />
                                                                    </Link>
                                                                }
                                                                permission="backend/email:write"
                                                                type="component"
                                                            />
                                                            <AuthComponent
                                                                component={
                                                                    <Link
                                                                        to={`${urlAssocEmployee}${params._id}/${params.company}`}
                                                                    >
                                                                        <RaisedButton
                                                                            label={LBLEMPLOYESS.tooltips.btnAssociate}
                                                                            secondary
                                                                            style={{
                                                                                display: "inline-block",
                                                                                margin: 7,
                                                                            }}
                                                                        />
                                                                    </Link>
                                                                }
                                                                permission="backend/employees:write"
                                                                type="component"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <EmployeesProject projectId={_.get(params, "_id")}/>
                                            </div>
                                        </Tab>
                                    </Tabs>
                                    <SpinnerLoading/>
                                    <SendMessageModal/>
                                    <ButtonBack url={urlBack}/>
                                </Paper>
                            </Col>
                        }
                        permission="backend/projects:write"
                        type="url"
                    />
                </Row>
            );
        }
        return (
            <div>
                <ButtonBack url={urlBack}/>
            </div>
        );
    }
}

function mapStateToProps({projects, login}) {
    return {
        profile: login.get("profile"),
        businessName: _.get(projects, "all.data.company.businessName", ""),
        projects: _.get(projects, "all"),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            FetchProjectParam,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsInfo);
