import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-flexbox-grid";
import Paper from "material-ui/Paper";
import ButtonBack from "../../fields/buttonBack/buttonBack";
import { LBLQUESTIONS } from "../../../constants/labels";

const stylePaper = {
    marginTop: 20,
    marginBottom: 20,
};

const QuestionsFormContainer = ({ editMode = false, children }) => {
    return (
        <Row>
            <Col xs={12} mdOffset={2} md={8}>
                <Paper className="p-6">
                    <Row>
                        <Col xsOffset={2} xs={8}>
                            <ButtonBack url="/admin/questions/grid" />
                            <span className="text-center text-3xl leading-8 tracking-tight text-gray-900 ml-1 mb-6">
                                {editMode
                                    ? LBLQUESTIONS.titleEdit
                                    : LBLQUESTIONS.titleCreate}
                            </span>

                            {children}
                        </Col>
                    </Row>
                </Paper>
            </Col>
        </Row>
    );
};

QuestionsFormContainer.propTypes = {
    editMode: PropTypes.bool.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

export default QuestionsFormContainer;
