import PropTypes from "prop-types";
import React, { Component } from "react";
import TableCell from "@material-ui/core/TableCell";
import _ from "lodash";
import IconButton from "material-ui/IconButton";
import { connect } from "react-redux";
import SendIcon from "material-ui/svg-icons/content/send";
import AuthComponent from "../../AuthComponent";
import { LBLEMPLOYESS, LBLPROJECTS } from "../../../constants/labels";
import DeleteEntityModal from "../../deleteEntityModal/component";
import { disassociateEmployeeOfProject } from "../../../actions/projects_form";
import { openModalSendEmailId } from "../../ModalSendEmail/action";
import { toggleSnackbar } from "../../../actions/commons";
import { removeRecord } from "../actions";

class EmployeeProjectActionColumn extends Component {
  static propTypes = {
    data: PropTypes.shape(Object).isRequired,
    colSpan: PropTypes.number,
    extra: PropTypes.shape(Object).isRequired,
  };

  static defaultProp = {
    colSpan: 1,
  };

  handleModalSendEmail(data) {
    const { openModalSendEmailId } = this.props;
    openModalSendEmailId([_.get(data, "_id")], "employee");
  }

  _handleDeleteEmployeeAssociated = (data) => {
    const {
      extra,
      disassociateEmployeeOfProject,
      toggleSnackbar,
      removeRecord,
    } = this.props;
    const idEmployeeProject = _.get(data, "_id");
    disassociateEmployeeOfProject(
      { employees: [idEmployeeProject] },
      idEmployeeProject,
      _.get(extra, "project")
    )
      .then(
        (data) => {
          if (_.get(data, "error") || _.get(data, "payload.status") !== 200) {
            toggleSnackbar(
              true,
              LBLPROJECTS.msg.errorProjectDisassaciateEmployee
            );
          } else {
            const employeesAssociated = _.get(
              data,
              "payload.data.data.employees",
              []
            );
            if (_.indexOf(employeesAssociated, idEmployeeProject) !== -1) {
              toggleSnackbar(
                true,
                LBLPROJECTS.msg.successProjectNotDisassaciateEmployee
              );
            } else {
              removeRecord(idEmployeeProject, "_id");
              toggleSnackbar(
                true,
                LBLPROJECTS.msg.successProjectDisassaciateEmployee
              );
            }
          }
        },
        (reason) => {
          toggleSnackbar(
            true,
            LBLPROJECTS.msg.errorProjectDisassaciateEmployee
          );
        }
      )
      .catch((data) => {
        toggleSnackbar(true, LBLPROJECTS.msg.errorProjectDisassaciateEmployee);
      });
  };

  render() {
    const { data, colSpan, extra } = this.props;
    const cs = _.isUndefined(colSpan) ? 1 : colSpan;
    const sizeEmployeesToDelete = 0;
    return (
      <TableCell colSpan={cs}>
        <div style={{ position: "absolute", marginTop: -24, zIndex: 99 }}>
          <AuthComponent
            component={
              <DeleteEntityModal
                tooltip={LBLPROJECTS.tooltips.btnDisassaciate}
                title={LBLPROJECTS.titleDeleteDisassociateEmployee}
                message={LBLPROJECTS.msg.deleteEmployeeAssociated(
                  `${_.get(data, "name")} ${_.get(data, "lastName")}`
                )}
                fn={this._handleDeleteEmployeeAssociated.bind(this, data)}
                args={[_.get(extra, "project"), data]}
                style={{ display: "table-cell" }}
              />
            }
            permission="backend/projects:delete"
            type="component"
          />
          <AuthComponent
            component={
              <IconButton
                tooltip={LBLEMPLOYESS.tooltips.btnSendMail}
                tooltipPosition="top-center"
                children={<SendIcon />}
                onClick={this.handleModalSendEmail.bind(this, data)}
              />
            }
            permission="backend/email:write"
            type="component"
          />
        </div>
      </TableCell>
    );
  }
}

export default connect(null, {
  toggleSnackbar,
  removeRecord,
  disassociateEmployeeOfProject,
  openModalSendEmailId,
})(EmployeeProjectActionColumn);
