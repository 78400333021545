export const metadata = {
    "Name": "Nombre",
    "LastName": "Apellido",
    "IdNumber": "Identificación",
    "IdType": "Tipo de identificación",
    "Email": "Correo electronico",
    "Nit": "Nit",
    "Acronym": "Acrónimo",
    "Country": "País",
    "Province": "Provincia",
    "City": "Ciudad",
    "QuestionAnswer": "Respuesta de pregunta",
    "QuestionMetric": "Métrica de pregunta",
    "GrouperSummation": "Agrupador de sumatoria",
    "GrouperFrequency": "Agrupador de frecuencia",
    "MetadataTag": "Etiquetas de empresa",
    "MetadataCompany": "Información de la empresa",
    "MetadataPerson": "Información de la persona",
    "MetadataSolution": "Información de la solución"
}

export const columnOptionsType = [
    {label: "Respuesta de pregunta", value: "QuestionAnswer"},
    {label: "Métrica de pregunta", value: "QuestionMetric"},
    {label: "Agrupador de sumatoria", value: "GrouperSummation"},
    {label: "Agrupador de frecuencia", value: "GrouperFrequency"},
    {label: "Etiquetas de empresa", value: "MetadataTag"},
    {label: "Información de la empresa", value: "MetadataCompany"},
    {label: "Información de la persona", value: "MetadataPerson"},
    {label: "Información de la solución", value: "MetadataSolution"},
]

const metadataPerson = [
    {label: "Nombre", value: "Name"},
    {label: "Apellido", value: "LastName"},
    {label: "Identificación", value: "IdNumber"},
    {label: "Tipo de identificación", value: "IdType"},
    {label: "Correo electronico", value: "Email"},
]

const metadataSolution = [
    {label: "Identificador de proyecto", value: "projectId"},
    {label: "Identificador de encuesta", value: "surveyId"},
    {label: "Identificador del empleado", value: "employeeId"},
    {label: "Fecha inicio de la encuesta", value: "initDateTime"},
    {label: "Fecha finalización de la encuesta", value: "endDateTime"}
]

const metadataCompany = [
    {label: "Nombre", value: "Name"},
    {label: "Nit", value: "Nit"},
    {label: "Acrónimo", value: "Acronym"},
    {label: "País", value: "Country"},
    {label: "Provincia", value: "Province"},
    {label: "Ciudad", value: "City"}
]

const definedSources = {
    "MetadataCompany": metadataCompany,
    "MetadataPerson": metadataPerson,
    "MetadataSolution": metadataSolution
}

export default definedSources;