import {gql, useMutation, useQuery} from "@apollo/client";
import {mainClient} from "../../../middleware/graphql";

const CREATE_DATASET = gql`
    mutation CreateDataset($request: CreateDatasetInputInput!) {
        createDataset(request: $request) {
            ... on CreateDatasetSuccess{
                item {
                    datasetId
                    details {
                        name
                        description
                    }
                    columns {
                        id
                        name
                        sources
                        type
                    }
                    availableVersions
                    createdAt
                    updatedAt
                }
            }
            ... on CreateDatasetFailure{
                errorCode
                message
                metadata {
                    key
                    value
                }
            }
        }
    }
`

const LIST_DATASETS = gql`
    query ListDataSets($request: ListDatasetsInputInput!) {
        listDatasets(request: $request) {
            ... on ListDatasetsSuccess{
                items {
                    datasetId
                    details {
                        name
                        description
                    }
                    columns {
                        id
                        name
                        sources
                        type
                    }
                    code
                    output
                    availableVersions
                    status
                    triggers {
                        triggerId
                        surveyId
                        projectId
                    }
                    createdAt
                    updatedAt
                }
            }
            ... on ListDatasetsFailure{
                errorCode
                message
                metadata {
                    key
                    value
                }
            }
        }
    }
`

const GET_DATASET = gql`
    query GetDataSet($request: GetDatasetInputInput!) {
        getDataset(request: $request) {
            ... on GetDatasetSuccess {
                item {
                    datasetId
                    details {
                        name
                        description
                    }
                    columns {
                        id
                        name
                        type
                        sources
                    }
                    availableVersions
                    createdAt
                    updatedAt
                    code
                    output
                    status
                    triggers {
                        triggerId
                        surveyId
                        projectId
                    }
                }
            }
            ... on GetDatasetFailure {
                errorCode
                message
                metadata {
                    key
                    value
                }
            }
        }
    }
`

const CHANGE_DATASET_DETAILS = gql`
    mutation ChangeDataSetDetails($request: ChangeDatasetDetailsInputInput!) {
        changeDatasetDetails(request: $request) {
            ... on ChangeDatasetDetailsSuccess{
                item {
                    datasetId
                    details {
                        name
                        description
                    }
                    columns {
                        id
                        name
                        sources
                        type
                    }
                    createdAt
                    updatedAt
                    availableVersions
                    code
                    output
                    status
                    triggers {
                        triggerId
                        surveyId
                        projectId
                    }
                }
            }
            ... on ChangeDatasetDetailsFailure{
                errorCode
                message
                metadata {
                    key
                    value
                }
            }
        }
    }
`

const ADD_DATASET_COLUMN = gql`
    mutation AddDatasetColumn($request: AddDatasetColumnInputInput!){
        addDatasetColumn(request: $request){
            ... on AddDatasetColumnSuccess{
                datasetId
                column {
                    id
                    name
                    sources
                    type
                }
            }
            ... on AddDatasetColumnFailure{
                errorCode
                message
                metadata {
                    key
                    value
                }
            }
        }
    }
`

const PUBLISH_DATASET_VERSION = gql`
    mutation PublishDatasetVersion($request: PublishDatasetVersionInputInput!){
        publishDatasetVersion(request: $request){
            ... on PublishDatasetVersionSuccess{
                datasetVersion {
                    datasetId
                    columns {
                        id
                        name
                        sources
                        type
                    }
                    details {
                        name
                        description
                    }
                    output
                    publishedAt
                    version
                }
            }
            ... on PublishDatasetVersionFailure{
                errorCode
                message
                metadata {
                    key
                    value
                }
            }
        }
    }
`

const REMOVE_DATASET_COLUMN = gql`
    mutation RemoveDatasetColumn($request: RemoveDatasetColumnInputInput!){
        removeDatasetColumn(request: $request){
            ... on RemoveDatasetColumnSuccess{
                columnId
                datasetId
                __typename
            }
            ... on RemoveDatasetColumnFailure{
                errorCode
                message
                metadata {
                    key
                    value
                }
            }
        }
    }
`

const ADD_DATASET_COLUMN_SOURCE = gql`
    mutation AddDatasetColumnSource($request: AddDatasetColumnSourceInputInput!){
        addDatasetColumnSource(request: $request){
            ... on AddDatasetColumnSourceSuccess{
                column {
                    id
                    name
                    sources
                    type
                }
                datasetId
            }
            ... on AddDatasetColumnSourceFailure{
                errorCode
                message
                metadata {
                    key
                    value
                }
            }
        }
    }
`

const REMOVE_DATASET_COLUMN_SOURCE = gql`
    mutation RemoveDatasetColumnSource($request: RemoveDatasetColumnSourceInputInput!){
        removeDatasetColumnSource(request: $request){
            ... on RemoveDatasetColumnSourceSuccess{
                columnId
                datasetId
                source
            }
            ... on RemoveDatasetColumnSourceFailure{
                errorCode
                message
                metadata {
                    key
                    value
                }
            }
        }
    }
`

const ARCHIVE_DATASET = gql`
    mutation ArchiveDataset($request: ArchiveDatasetInputInput!){
        archiveDataset(request: $request){
            ... on ArchiveDatasetSuccess{
                item {
                    availableVersions
                    code
                    columns {
                        id
                        name
                        sources
                        type
                    }
                    createdAt
                    updatedAt
                    datasetId
                    details {
                        name
                        description
                    }
                    output
                    status
                    triggers {
                        triggerId
                        surveyId
                        projectId
                    }
                }
            }
            ... on ArchiveDatasetFailure{
                errorCode
                message
                metadata {
                    key
                    value
                }
            }
        }
    }
`

const ADD_DATASET_TRIGGER = gql`
    mutation AddDatasetTrigger($request: AddDatasetTriggerInputInput!) {
        addDatasetTrigger(request: $request) {
            ... on AddDatasetTriggerSuccess {
                datasetId
                trigger {
                    triggerId
                    surveyId
                    projectId
                }
            }
            ... on AddDatasetTriggerFailure {
                errorCode
                message
                metadata {
                    key
                    value
                }
            }
        }
    }
`

const REMOVE_DATASET_TRIGGER = gql`
    mutation RemoveDatasetTrigger($request: RemoveDatasetTriggerInputInput!){
        removeDatasetTrigger(request: $request){
            ... on RemoveDatasetTriggerSuccess{
                datasetId
                removedId
            }
            ... on RemoveDatasetTriggerFailure{
                errorCode
                message
                metadata {
                    key
                    value
                }
            }
        }
    }
`

const GET_SURVEYS = gql`
    query FindAllLeanSurveys {
        findAllLeanSurveys {
            id
            name
            createdAt
        }
    }
`;

const FIND_PROJECTS_BY_SURVEYS = gql`
    query FindProjectsBySurveys($surveys: [ObjectId!]!) {
        findProjectsBySurvey(surveys: $surveys) {
            companyId
            companyName
            companyTags
            creationDate
            id
            projectName
            surveyId
        }
    }
`

export function useSaveDataset() {
    const [mutate, {data, error, loading}] = useMutation(
        CREATE_DATASET,
        {
            client: mainClient,
            update(cache, {data: result}) {
                const newDatasetResponse = result?.createDataset.item;
                const existingDatasets = cache.readQuery({
                    variables: {request: {"limit": 25, "lastToken": ""}},
                    query: LIST_DATASETS,
                });
                if (existingDatasets && newDatasetResponse) {
                    cache.writeQuery({
                        query: LIST_DATASETS,
                        variables: {request: {"limit": 25, "lastToken": ""}},
                        data: {
                            listDatasets: {
                                __typename: "ListDatasetsSuccess",
                                items: [
                                    ...existingDatasets.listDatasets.items,
                                    newDatasetResponse,
                                ],
                            },
                        }
                    });
                }
            }
        }
    )
    return {mutate, data, error, loading};
}

export function useChangeDetails() {
    const [mutate, {data, error, loading}] = useMutation(
        CHANGE_DATASET_DETAILS,
        {
            client: mainClient,
            update(cache, {data: result}) {
                const changeDatasetResponse = result?.changeDatasetDetails.item;
                let existingDatasets = cache.readQuery({
                    variables: {request: {"limit": 25, "lastToken": ""}},
                    query: LIST_DATASETS,
                });
                const renderedDataset = cache.readQuery({
                    variables: {request: {datasetId: changeDatasetResponse.datasetId}},
                    query: GET_DATASET,
                });

                // The dataset in the initial list is updated []
                if (existingDatasets && changeDatasetResponse) {
                    existingDatasets = existingDatasets.listDatasets.items.map((item) => {
                        if (item.datasetId === changeDatasetResponse.datasetId) {
                            item = {
                                ...item,
                                details: changeDatasetResponse.details
                            }
                        }
                        return item
                    })
                    cache.writeQuery({
                        query: LIST_DATASETS,
                        variables: {request: {"limit": 25, "lastToken": ""}},
                        data: {
                            listDatasets: {
                                __typename: "ListDatasetsSuccess",
                                items: existingDatasets,
                            },
                        }
                    });
                }

                // The rendered dataset is updated in the detail
                if (renderedDataset && changeDatasetResponse) {
                    cache.writeQuery({
                        query: GET_DATASET,
                        variables: {request: {datasetId: changeDatasetResponse.datasetId}},
                        data: {
                            getDataset: {
                                __typename: "GetDatasetSuccess",
                                item: {
                                    ...renderedDataset.getDataset.item,
                                    details: changeDatasetResponse.details
                                },
                            },
                        }
                    })
                }
            }
        }
    )
    return {mutate, data, error, loading};
}

export const useListDatasets = () => {
    const {loading, data, error, fetchMore} = useQuery(LIST_DATASETS, {
        client: mainClient,
        variables: {
            request: {
                "limit": 25, "lastToken": ""
            }
        }
    });

    const loadMore = () => {
        return fetchMore({
            variables: {
                request: {
                    "limit": 25, "lastToken": data.listDatasets.lastToken
                }
            },
            updateQuery: (prev, {fetchMoreResult}) => {
                if (!fetchMoreResult) return prev;
                return {
                    listDatasets: {
                        __typename: "ListDatasetsSuccess",
                        items: [...prev.listDatasets.items, ...fetchMoreResult.listDatasets.items],
                        lastToken: fetchMoreResult.listDatasets.lastToken,
                    }
                }
            }
        });
    }

    return {
        loading,
        data,
        error,
        loadMore
    };
};

export const useGetDataset = ({datasetId}) => {
    const {loading, data, error} = useQuery(GET_DATASET, {
        client: mainClient, variables: {
            request: {
                datasetId
            }
        }
    });

    return {
        loading, data, error,
    };
}

export const useAddDatasetColumn = () => {
    const [mutate, {data, error, loading}] = useMutation(
        ADD_DATASET_COLUMN,
        {
            client: mainClient,
            update(cache, {data: result}) {
                const addDatasetColumnResponse = result?.addDatasetColumn;
                let existingDatasets = cache.readQuery({
                    variables: {request: {"limit": 0, "lastToken": ""}},
                    query: LIST_DATASETS,
                });
                const renderedDataset = cache.readQuery({
                    variables: {request: {datasetId: addDatasetColumnResponse.datasetId}},
                    query: GET_DATASET,
                });

                if (existingDatasets && addDatasetColumnResponse) {
                    existingDatasets = existingDatasets.listDatasets.items.map((item) => {
                        if (item.datasetId === addDatasetColumnResponse.datasetId) {
                            item = {
                                ...item,
                                triggers: [addDatasetColumnResponse.column]
                            }
                        }
                        return item
                    })
                    cache.writeQuery({
                        query: LIST_DATASETS,
                        variables: {request: {"limit": 0, "lastToken": ""}},
                        data: {
                            listDatasets: {
                                __typename: "ListDatasetsSuccess",
                                items: existingDatasets,
                            },
                        }
                    });
                }

                if (renderedDataset && addDatasetColumnResponse) {
                    cache.writeQuery({
                        query: GET_DATASET,
                        variables: {request: {datasetId: addDatasetColumnResponse.datasetId}},
                        data: {
                            getDataset: {
                                __typename: "GetDatasetSuccess",
                                item: {
                                    ...renderedDataset.getDataset.item,
                                    columns: [...renderedDataset.getDataset.item.columns, addDatasetColumnResponse.column]
                                },
                            },
                        }
                    })
                }
            }
        }
    )
    return {mutate, data, error, loading}
}

export const useRemoveDatasetColumn = () => {
    const [mutate, {data, error, loading}] = useMutation(
        REMOVE_DATASET_COLUMN,
        {
            client: mainClient,
            update(cache, {data: result}) {
                const removeDatasetColumnResponse = result.removeDatasetColumn;
                let existingDatasets = cache.readQuery({
                    variables: {request: {"limit": 0, "lastToken": ""}},
                    query: LIST_DATASETS,
                });

                const renderedDataset = cache.readQuery({
                    variables: {request: {datasetId: removeDatasetColumnResponse?.datasetId}},
                    query: GET_DATASET,
                });

                if (existingDatasets && removeDatasetColumnResponse) {
                    existingDatasets = existingDatasets.listDatasets.items.map((item) => {
                        if (item.datasetId === removeDatasetColumnResponse.datasetId) {
                            item = {
                                ...item,
                                columns: item.columns.filter((col) => col.id !== removeDatasetColumnResponse.columnId)
                            }
                        }
                        return item
                    })
                    cache.writeQuery({
                        query: LIST_DATASETS,
                        variables: {request: {"limit": 0, "lastToken": ""}},
                        data: {
                            listDatasets: {
                                __typename: "ListDatasetsSuccess",
                                items: existingDatasets,
                            },
                        }
                    });
                }

                if (renderedDataset && removeDatasetColumnResponse) {
                    cache.writeQuery({
                        query: GET_DATASET,
                        variables: {request: {datasetId: removeDatasetColumnResponse.datasetId}},
                        data: {
                            getDataset: {
                                __typename: "GetDatasetSuccess",
                                item: {
                                    ...renderedDataset.getDataset.item,
                                    columns: renderedDataset.getDataset.item.columns.filter((col) => col.id !== removeDatasetColumnResponse.columnId)
                                },
                            },
                        }
                    })
                }
            }
        }
    )
    return {mutate, data, error, loading}
}

export const usePublishDatasetVersion = () => {
    return useMutation(PUBLISH_DATASET_VERSION, {
        client: mainClient,
    });
}

export const useAddDatasetColumnSource = () => {
    return useMutation(ADD_DATASET_COLUMN_SOURCE, {
        client: mainClient,
    });
}

export const useRemoveDatasetColumnSource = () => {
    return useMutation(REMOVE_DATASET_COLUMN_SOURCE, {
        client: mainClient,
    });
}

export const useArchiveDataset = () => {
    const [mutate, {data, error, loading}] = useMutation(
        ARCHIVE_DATASET,
        {
            client: mainClient,
            update(cache, {data: result}) {
                const archiveDatasetResponse = result?.archiveDataset.item;
                const existingDatasets = cache.readQuery({
                    variables: {request: {"limit": 0, "lastToken": ""}},
                    query: LIST_DATASETS,
                });
                if (existingDatasets && archiveDatasetResponse) {
                    const newArrayDatasets = existingDatasets.listDatasets.items.filter((item) => {
                        return item.datasetId !== archiveDatasetResponse.datasetId
                    })
                    cache.writeQuery({
                        query: LIST_DATASETS,
                        variables: {request: {"limit": 0, "lastToken": ""}},
                        data: {
                            listDatasets: {
                                __typename: "ListDatasetsSuccess",
                                items: newArrayDatasets,
                            },
                        }
                    });
                }
            }
        }
    )
    return {mutate, data, error, loading}
}

export const useAddDatasetTrigger = () => {
    const [mutate, {data, error, loading}] = useMutation(
        ADD_DATASET_TRIGGER,
        {
            client: mainClient,
            update(cache, {data: result}) {
                const addDatasetTriggerResponse = result?.addDatasetTrigger;
                let existingDatasets = cache.readQuery({
                    variables: {request: {"limit": 0, "lastToken": ""}},
                    query: LIST_DATASETS,
                });
                const renderedDataset = cache.readQuery({
                    variables: {request: {datasetId: addDatasetTriggerResponse.datasetId}},
                    query: GET_DATASET,
                });

                if (existingDatasets && addDatasetTriggerResponse) {
                    existingDatasets = existingDatasets.listDatasets.items.map((item) => {
                        if (item.datasetId === addDatasetTriggerResponse.datasetId) {
                            item = {
                                ...item,
                                triggers: [addDatasetTriggerResponse.trigger]
                            }
                        }
                        return item
                    })
                    cache.writeQuery({
                        query: LIST_DATASETS,
                        variables: {request: {"limit": 0, "lastToken": ""}},
                        data: {
                            listDatasets: {
                                __typename: "ListDatasetsSuccess",
                                items: existingDatasets,
                            },
                        }
                    });
                }

                if (renderedDataset && addDatasetTriggerResponse) {
                    cache.writeQuery({
                        query: GET_DATASET,
                        variables: {request: {datasetId: addDatasetTriggerResponse.datasetId}},
                        data: {
                            getDataset: {
                                __typename: "GetDatasetSuccess",
                                item: {
                                    ...renderedDataset.getDataset.item,
                                    triggers: [...renderedDataset.getDataset.item.triggers, addDatasetTriggerResponse.trigger]
                                },
                            },
                        }
                    })
                }
            }
        }
    )
    return {mutate, data, error, loading}
}

export const useRemoveDatasetTrigger = () => {
    const [mutate, {data, error, loading}] = useMutation(
        REMOVE_DATASET_TRIGGER,
        {
            client: mainClient,
            update(cache, {data: result}) {
                const removeDatasetTriggerResponse = result?.removeDatasetTrigger;
                let existingDatasets = cache.readQuery({
                    variables: {request: {"limit": 0, "lastToken": ""}},
                    query: LIST_DATASETS,
                });

                const renderedDataset = cache.readQuery({
                    variables: {request: {datasetId: removeDatasetTriggerResponse?.datasetId}},
                    query: GET_DATASET,
                });

                if (existingDatasets && removeDatasetTriggerResponse) {
                    existingDatasets = existingDatasets.listDatasets.items.map((item) => {
                        if (item.datasetId === removeDatasetTriggerResponse.datasetId) {
                            item = {
                                ...item,
                                triggers: item.triggers.filter((trigger) => trigger.id !== removeDatasetTriggerResponse.removedId)
                            }
                        }
                        return item
                    })
                    cache.writeQuery({
                        query: LIST_DATASETS,
                        variables: {request: {"limit": 0, "lastToken": ""}},
                        data: {
                            listDatasets: {
                                __typename: "ListDatasetsSuccess",
                                items: existingDatasets,
                            },
                        }
                    });
                }

                if (renderedDataset && removeDatasetTriggerResponse) {
                    cache.writeQuery({
                        query: GET_DATASET,
                        variables: {request: {datasetId: removeDatasetTriggerResponse.datasetId}},
                        data: {
                            getDataset: {
                                __typename: "GetDatasetSuccess",
                                item: {
                                    ...renderedDataset.getDataset.item,
                                    triggers: renderedDataset.getDataset.item.triggers.filter((trigger) => trigger.triggerId !== removeDatasetTriggerResponse.removedId)
                                },
                            },
                        }
                    })
                }
            }
        }
    )
    return {mutate, data, error, loading}
}

export const useGetSurveys = () => {
    const {loading, data, error} = useQuery(GET_SURVEYS, {
        client: mainClient
    })

    return {
        loading, data: data?.findAllLeanSurveys, error,
    };
}

export const useFindProjectsBySurveys = ({surveyId}) => {
    const {loading, data, error, refetch} = useQuery(FIND_PROJECTS_BY_SURVEYS, {
        client: mainClient,
        variables: {
            surveys: [surveyId]
        },
    })

    return {
        loading, data: data?.findProjectsBySurvey, error, refetch
    };
}
