import PropTypes from "prop-types";
import React, { Component } from "react";
import { blue600 } from "material-ui/styles/colors";
import { SketchPicker } from "react-color";
import { Row, Col } from "react-flexbox-grid";
import _ from "lodash";

const cover = {
        position: "fixed",
        top: 20,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: -2,
        border: "0px solid #000",
    };
    const coverButton = {
        position: "absolute",
        top: -22,
        left: 29,
        zIndex: 2,
        height: 20,
        padding: "2px 5px",
        borderRadius: "3px 3px 0 0",
        color: "#FFF",
        cursor: "pointer",
        backgroundColor: blue600,
    };
    const styleButtonPicker = {
        border: 1,
        borderStyle: "solid",
        borderColor: "#999",
        width: 29,
        height: 29,
        cursor: "pointer",
        borderRadius: "100%",
        display: "inline-block",
        marginTop: -10,
        marginBottom: 10,
        marginLeft: 0,
        marginRight: 0,
    };

const colorDefault = "#1976d2";

export default class PureInputColorPicker extends Component {
    static propTypes = {
        field: PropTypes.object.isRequired,
        positionFixed: PropTypes.bool,
        onChangeFunc: PropTypes.func,
    };

    state = {
        displayColorPicker: false,
    };

    handleChangeComplete = (color) => {
        const { field, onChangeFunc } = this.props;
        if (
            !_.isUndefined(onChangeFunc) &&
            !_.isNull(onChangeFunc) &&
            typeof onChangeFunc === "function"
        ) {
            onChangeFunc(_.get(color, "hex"));
        } else {
            field.onChange(_.get(color, "hex"));
        }
    };

    handleClickColorPicker = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker });
    };

    handleCloseColorPicker = () => {
        this.setState({ displayColorPicker: false });
    };

    render() {
        const { field, positionFixed, style } = this.props;
        const positionPicker = _.isUndefined(positionFixed)
            ? false
            : positionFixed;

        let popover = {
            position: "absolute",
            zIndex: 12,
            paddingLeft: 30,
        };
        if (positionPicker) {
            popover = {
                position: "fixed",
                bottom: 16,
                zIndex: 12,
                paddingLeft: 30,
            };
        }

        return (
            <Row>
                <Col xs={12} mdOffset={1} md={4} style={{ paddingTop: 30 }}>
                    <div
                        onClick={this.handleClickColorPicker}
                        style={_.assign(styleButtonPicker, style)}
                    >
                        <div
                            style={{
                                width: 21,
                                height: 21,
                                borderRadius: "100%",
                                marginTop: 4,
                                marginLeft: 4,
                                background: _.isUndefined(field.value)
                                    ? colorDefault
                                    : field.value,
                            }}
                        />
                    </div>
                </Col>
                {this.state.displayColorPicker ? (
                    <div style={popover}>
                        <div
                            style={cover}
                            onClick={this.handleCloseColorPicker}
                        />
                        <div
                            style={coverButton}
                            onClick={this.handleCloseColorPicker}
                        >
                            Cerrar
                        </div>
                        <SketchPicker
                            color={
                                _.isUndefined(field.value)
                                    ? colorDefault
                                    : field.value
                            }
                            onChangeComplete={this.handleChangeComplete}
                            presetColors={[
                                "#F44E3B",
                                "#FE5700",
                                "#FE9200",
                                "#FCDC00",
                                "#DBDF00",
                                "#A4DD00",
                                "#68CCCA",
                                "#73D8FF",
                                "#AEA1FF",
                                "#FDA1FF",
                                "#999999",
                                "#4D4D4D",
                            ]}
                        />
                    </div>
                ) : null}
            </Row>
        );
    }
}
