import PropTypes from "prop-types";
import React, { Component } from "react";
import { List, ListItem } from "material-ui/List";
import _ from "lodash";
import { bindActionCreators } from "redux";
import { reduxForm, addArrayValue } from "redux-form";
import { Row, Col } from "react-flexbox-grid";
import { blue700 } from "material-ui/styles/colors";
import Divider from "material-ui/Divider";
import DeleteIcon from "material-ui/svg-icons/action/delete";
import AddIcon from "material-ui/svg-icons/content/add";
import IconButton from "material-ui/IconButton";
import { getRamdonString } from "../../lib/util";
import { getSurveyTreeGroupers } from "../groupersConfiguration/actions";
import { addGrouper, removeGrouper, initialState } from "./actions";
import { LBLSURVEYS } from "../../constants/labels";
import LoadingPage from "../commons/loadingPage";

const innerDivStyle = {
  paddingRight: 35,
};
const innerDivStyle2 = {
  paddingRight: 35,
  paddingTop: 0,
};
const nestedListStyle = {
  paddingLeft: 10,
};

class TreeAllGrouperSurvey extends Component {
  constructor(props) {
    super(props);
    this.renderChildren = this.renderChildren.bind(this);
    this.renderHeadList = this.renderHeadList.bind(this);
    this.renderQuestions = this.renderQuestions.bind(this);
    this._handleAddGrouper = this._handleAddGrouper.bind(this);
    this._getTreeGrouper = this._getTreeGrouper.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { getSurveyTreeGroupers, initialState, idSurvey } = this.props;
    initialState();
    getSurveyTreeGroupers(idSurvey);
  }

  componentWillUnmount() {
    this.props.initialState();
  }

  _handleAddGrouper(id, name, level) {
    const {
      fields: { groupers },
      addGrouper,
    } = this.props;
    let exits = true;
    groupers.map(function (grouper) {
      if (_.isEqual(grouper.id.value, id)) {
        exits = false;
      }
    });
    if (exits) {
      const grouper = {
        name,
        id,
        level,
      };
      addGrouper(grouper);
      groupers.addField(grouper);
    }
  }

  _handleRemoveGrouper(index, idGrouper) {
    const {
      fields: { groupers },
      removeGrouper,
    } = this.props;
    removeGrouper(idGrouper);
    groupers.removeField(index);
  }

  renderOnlyQuestions(questions) {
    return _.map(questions, function (question, index) {
      return (
        <ListItem
          key={getRamdonString()}
          primaryText={question.title}
          innerDivStyle={innerDivStyle}
          nestedListStyle={nestedListStyle}
          primaryTogglesNestedList
          nestedItems={_.map(_.get(question, "items", []), (item) => (
            <ListItem
              key={getRamdonString()}
              primaryText={`${_.get(item, "name")} (${_.get(item, "value")})`}
              disabled
              innerDivStyle={innerDivStyle2}
              nestedListStyle={nestedListStyle}
            />
          ))}
        />
      );
    });
  }

  renderQuestions(children) {
    return (
      <ListItem
        key={getRamdonString()}
        primaryText={_.get(children, "name")}
        secondaryText={`Nilvel ${_.get(children, "level")}`}
        innerDivStyle={innerDivStyle}
        nestedListStyle={nestedListStyle}
        primaryTogglesNestedList
        nestedItems={_.map(_.get(children, "questions", []), (question) => (
          <ListItem
            key={getRamdonString()}
            primaryText={question.title}
            innerDivStyle={innerDivStyle}
            nestedListStyle={nestedListStyle}
            primaryTogglesNestedList
            nestedItems={_.map(_.get(question, "items", []), (item) => (
              <ListItem
                key={getRamdonString()}
                primaryText={`${_.get(item, "name")} (${_.get(item, "value")})`}
                disabled
                innerDivStyle={innerDivStyle2}
                nestedListStyle={nestedListStyle}
              />
            ))}
          />
        ))}
      />
    );
  }

  renderHeadList(children) {
    return (
      <ListItem
        key={getRamdonString()}
        primaryText={children.name}
        secondaryText={`Nilvel ${_.get(children, "level")}`}
        innerDivStyle={innerDivStyle}
        nestedListStyle={nestedListStyle}
        primaryTogglesNestedList
        nestedItems={this.renderChildren(_.get(children, "children", []))}
      />
    );
  }

  renderChildren(childrens) {
    const { renderHeadList, renderQuestions } = this;
    return _.map(childrens, function (children) {
      return _.get(children, "level", 1) !== 1
        ? renderHeadList(children)
        : renderQuestions(children);
    });
  }

  _getTreeGrouper(allGroupersSurvey) {
    const { locked } = this.props;
    if (!_.isUndefined(allGroupersSurvey)) {
      return _.map(allGroupersSurvey, (grouper) => {
        if (
          _.size(_.get(grouper, "children", [])) != 0 ||
          _.size(_.get(grouper, "questions", [])) != 0
        ) {
          return (
            <ListItem
              primaryText={grouper.name}
              initiallyOpen={false}
              number={2}
              key={getRamdonString()}
              leftCheckbox={
                <div
                  style={{
                    padding: "4px 4px 1px 4px",
                    cursor: "pointer",
                    marginTop: -5,
                    borderRadius: "100%",
                    background: blue700,
                    display: locked ? "none" : "block",
                  }}
                  onClick={() => {
                    this._handleAddGrouper(
                      _.get(grouper, "_id"),
                      _.get(grouper, "name"),
                      _.get(grouper, "level")
                    );
                  }}
                >
                  <AddIcon color="#FFF" hoverColor="#000" />
                </div>
              }
              innerDivStyle={innerDivStyle}
              primaryTogglesNestedList
              nestedListStyle={nestedListStyle}
              secondaryTextLines={1}
              disabled
              secondaryText={`Nivel ${_.get(grouper, "level")}`}
              nestedItems={
                _.get(grouper, "level") !== 1
                  ? this.renderChildren(_.get(grouper, "children", []))
                  : this.renderOnlyQuestions(_.get(grouper, "questions", []))
              }
            />
          );
        }
        return null;
      });
    }
    return null;
  }

  render() {
    const {
      groupersTree,
      fields: { groupers },
    } = this.props;
    if (!_.isUndefined(groupersTree)) {
      return (
        <div>
          <Row>
            <Col xs>
              <List Subheader={LBLSURVEYS.metrical.titleListGroupers}>
                {this._getTreeGrouper(groupersTree)}
              </List>
            </Col>
          </Row>
          <Row style={{ marginBottom: 20 }}>
            <Col xs>
              <Divider />
            </Col>
          </Row>
          <Row style={{ marginBottom: 15 }}>
            <Col xs>
              <h2 style={{ fontWeight: 200, textAlign: "center" }}>
                {LBLSURVEYS.metrical.titleConfigGrouperGroups}
              </h2>
            </Col>
          </Row>
          {groupers.map((grouper, index) => (
            <div key={index}>
              <Row>
                <Col xsOffset={2} xs={7}>
                  <h3
                    style={{
                      textAlign: "justify",
                      fontWeight: 200,
                    }}
                  >
                    {grouper.name.value}
                  </h3>
                </Col>
                <Col xs={1}>
                  <div>
                    <IconButton
                      children={<DeleteIcon />}
                      tooltip={LBLSURVEYS.metrical.buttons.delete}
                      tooltipPosition="top-center"
                      onClick={this._handleRemoveGrouper.bind(
                        this,
                        index,
                        grouper.id.value
                      )}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          ))}
        </div>
      );
    }
    return <LoadingPage visible text="Cargando..." />;
  }
}

TreeAllGrouperSurvey.propTypes = {
  idSurvey: PropTypes.string.isRequired,
  locked: PropTypes.bool.isRequired,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getSurveyTreeGroupers,
      addGrouper,
      removeGrouper,
      initialState,
    },
    dispatch
  );
}

function mapStateToProps({ groupersSurvey }, ownProps) {
  return {
    groupersTree: _.get(groupersSurvey, "treeGroupers"),
  };
}

//
export default reduxForm(
  {
    form: "AddGrouperForm",
    fields: ["groupers[].id", "groupers[].name", "groupers[].level"],
  },
  mapStateToProps,
  mapDispatchToProps
)(TreeAllGrouperSurvey);
