import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "material-ui/IconButton";
import RefreshIcon from "material-ui/svg-icons/navigation/refresh";
import LoadingPage from "../commons/loadingPage";
import TableHeadPagination from "./tableHeadPagination";
import TableBodyPagination from "./tableBodyPagination";
import TableHeadColumn from "./tableHeadColumn";
import ActionPagination from "./actionPagination";
import SelectedRecords from "./selectedRecords";
import {
  getRecords,
  cleanTable,
  setArrayfieldsFilter,
  setFieldSelection,
} from "./actions";
import { cleanTagEmployee } from "../TagsEmployee/actions";

class TablePagination extends Component {
  constructor(props) {
    super(props);
    this._toUpdateTable = this._toUpdateTable.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { cleanTagEmployee } = this.props;
    cleanTagEmployee();
    this._toUpdateTable();
  }

  _renderRowHead() {
    const { body, orderName, orderType, version, URL, extra } = this.props;
    return _.map(body, function (field, index) {
      const align = index !== 0 ? "left" : "left";
      return (
        <TableHeadColumn
          align={align}
          URL={URL}
          version={version}
          field={field}
          key={`subHead${index}`}
          extra={extra}
        />
      );
    });
  }

  _toUpdateTable() {
    const {
      body,
      URL,
      version,
      limit,
      cleanTable,
      getRecords,
      extra,
      setArrayfieldsFilter,
      fieldSelection,
      setFieldSelection,
    } = this.props;
    const arrayFields = [];
    _.forEach(body, function (field) {
      if (!_.has(field, "actions") && _.has(field, "filter")) {
        if (_.get(field, "filter", false)) {
          arrayFields.push(_.get(field, "field"));
        }
      }
    });
    if (_.size(arrayFields) === 1) {
      arrayFields.push("default");
    }
    cleanTable();
    setArrayfieldsFilter(arrayFields);
    setFieldSelection(_.isUndefined(fieldSelection) ? null : fieldSelection);
    if (!_.isUndefined(extra) && !_.isNull(extra)) {
      getRecords(URL, version, 0, 25, "null", 0, arrayFields, "", extra);
    } else {
      getRecords(URL, version, 0, 25, "null", 0, arrayFields, "");
    }
  }

  render() {
    const {
      records,
      body,
      name,
      total,
      URL,
      version,
      textFilter,
      extra,
      selection,
      hasFilter,
      hasRefreshNotFound,
    } = this.props;

    const hasFilterTable = _.isUndefined(hasFilter) ? true : hasFilter;
    const hasRefreshNotFoundTable = _.isUndefined(hasRefreshNotFound)
      ? true
      : hasRefreshNotFound;

    if (!_.isUndefined(records.size)) {
      if (records.size === 0) {
        return <LoadingPage visible />;
      }
    } else {
      if (_.size(records) === 0) {
        return (
          <div
            style={{
              marginTop: 20,
              marginBottom: 40,
              textAlign: "center",
            }}
          >
            {hasRefreshNotFoundTable && (
              <IconButton
                tooltip="Recargar"
                style={{ margin: "5px auto" }}
                tooltipPosition="top-center"
                onClick={this._toUpdateTable}
              >
                <RefreshIcon />
              </IconButton>
            )}
            <br />
            <h2
              style={{
                marginTop: 10,
                marginBottom: 40,
                textAlign: "center",
                fontWeight: 200,
              }}
            >
              {_.isEqual(textFilter, "")
                ? (`No se encontro ningún registro`)
                : (`No se encontro ningún registro con el filtro "${textFilter}"`)}
            </h2>
          </div>
        );
      }
      return (
        <div>
          <SelectedRecords name={name} selection={selection} />
          <ActionPagination URL={URL} version={version} extra={extra} />

          <TableHeadPagination
            URL={URL}
            version={version}
            body={body}
            extra={extra}
            selection={selection}
            hasFilter={hasFilterTable}
          />
          <div >
            <Table>
              <TableHead>
                <TableRow>{this._renderRowHead()}</TableRow>
              </TableHead>
              <TableBodyPagination
                name={name}
                body={body}
                extra={extra}
                selection={selection}
              />
            </Table>
          </div>
          <ActionPagination URL={URL} version={version} extra={extra} />
        </div>
      );
    }
  }
}

function mapStateToProps({ tablePagination }, ownProps) {
  return {
    records: tablePagination.get("records"),
    skip: tablePagination.get("skip"),
    limit: tablePagination.get("limit"),
    total: tablePagination.get("total"),
    orderName: tablePagination.get("orderName"),
    orderType: tablePagination.get("orderType"),
    textFilter: tablePagination.get("textFilter"),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getRecords,
      cleanTable,
      setArrayfieldsFilter,
      setFieldSelection,
      cleanTagEmployee,
    },
    dispatch
  );
}

TablePagination.defaultProps = {
  extra: {},
};

TablePagination.propTypes = {
  name: PropTypes.string.isRequired,

  URL: PropTypes.string.isRequired,
  version: PropTypes.number.isRequired,
  body: PropTypes.arrayOf(PropTypes.object).isRequired,
  fieldFilter: PropTypes.string,
  extra: PropTypes.shape({}),
  selection: PropTypes.bool,
  fieldSelection: PropTypes.string,
  hasFilter: PropTypes.bool,
  hasRefreshNotFound: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(TablePagination);
