import { axiosAPI } from '../../middleware/api';

export const POST_CONSENT_INFO = "POST_CONSENT_INFO";
export const GET_CONSENTS = "GET_CONSENTS";

export function FetchConsentParam(id) {

    const request = axiosAPI.get(`/consents/${id}`);

    return {
        type: POST_CONSENT_INFO,
        payload: request
    }
}

export function fetchConsents(ids) {

    const request = axiosAPI.get(`/consents`, {data: { ids }});

    return {
        type: GET_CONSENTS,
        payload: request
    }
}
