import PropTypes from "prop-types";
import React, { Component } from "react";
import { Row, Col } from "react-flexbox-grid";
import _ from "lodash";
import { blue700 } from "material-ui/styles/colors";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, withRouter } from "react-router-dom";
import RaisedButton from "material-ui/RaisedButton";
import moment from "moment";
import Popover from "material-ui/Popover/Popover";
import { Menu, MenuItem } from "material-ui/Menu";
import { spanishLocale } from "../../../constants/index";
import AuthComponent from "../../AuthComponent";
import { LBLPROJECTS } from "../../../constants/labels";
import { FetchProjectParam } from "../../../actions/info/projects_info_form";

class generalInfoProjectComponent extends Component {
  constructor(props) {
    super(props);
    this._toUpdateGeneralInfoProject = this._toUpdateGeneralInfoProject.bind(
      this
    );
    this.handleTouchTap = this.handleTouchTap.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this._goEditColors = this._goEditColors.bind(this);
    this._goEditRanking = this._goEditRanking.bind(this);
    this._goEditFilter = this._goEditFilter.bind(this);
    this.state = {
      open: false,
    };
  }

  _goEditColors() {
    const { urlBaseColors, history } = this.props;
    history.push(urlBaseColors);
  }

  _goEditRanking() {
    const { urlBaseRanking, history } = this.props;
    history.push(urlBaseRanking);
  }

  _goEditFilter() {
    const { urlBaseFilter, history } = this.props;
    history.push(urlBaseFilter);
  }

  UNSAFE_componentWillMount() {
    this._toUpdateGeneralInfoProject();
  }

  _toUpdateGeneralInfoProject() {
    const { projectId, FetchProjectParam } = this.props;
    FetchProjectParam(projectId);
  }

  handleTouchTap(event) {
    event.preventDefault();
    this.setState({
      open: true,
      anchorEl: event.currentTarget,
    });
  }

  handleRequestClose() {
    this.setState({ open: false });
  }

  renderDescription(description) {
    let desp = "";
    if (!_.isUndefined(description) && !_.isEqual(description, "")) {
      desp = _.split(description, /\r\n|\r|\n/g);
    }
    if (desp instanceof Array) {
      return desp.map((value, index) => {
        if (!_.isEqual(value, "")) {
          return (
            <div key={index}>
              {value}
              <br />
              <br />
            </div>
          );
        }
      });
    }
    return desp;
  }

  render() {
    const { projects, businessName, urlBase } = this.props;
    if (!_.isNull(projects)) {
      moment.locale(spanishLocale);
      return (
        <div style={{ height: "auto", padding: 0 }}>
          <Row>
            <Col xs>
              <div
                style={{
                  width: "100%",
                  background: blue700,
                  paddingTop: 5,
                  paddingBottom: 5,
                  marginBottom: 20,
                  color: "#FFF",
                }}
              >
                <h1
                  style={{
                    textAlign: "center",
                    paddingLeft: 10,
                    paddingRight: 10,
                    fontWeight: 400,
                    lineHeight: 1.5,
                  }}
                >
                  {`${businessName}`}
                </h1>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs>
              <h1
                style={{
                  textAlign: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  fontWeight: 400,
                  lineHeight: 1.5,
                }}
              >
                {_.get(projects, "data.name", "")}
              </h1>
            </Col>
          </Row>
          <Row>
            <Col xsOffset={1} xs={10} style={{ textAlign: "justify" }}>
              {this.renderDescription(_.get(projects, "data.description"))}
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col xsOffset={1} xs={4}>
              <strong>Fecha de Inicio: </strong>
              {moment(_.get(projects, "data.time.openDate")).format("LL")}
              <br />
              <strong>Fecha de cierre: </strong>
              {moment(_.get(projects, "data.time.closeDate")).format("LL")}
            </Col>
          </Row>
          <Row>
            <Col xsOffset={1} xs={10} style={{ marginTop: 35 }}>
              {_.map(
                _.orderBy(
                  _.get(projects, "data.colors", []),
                  ["order"],
                  ["asc"]
                ),
                (color, index) => (
                  <div
                    key={`color-default-project-${index}`}
                    style={{
                      background: _.get(color, "color", "#FFF"),
                      width: 20,
                      height: 20,
                      border: "1px solid #CCC",
                      borderRadius: "100%",
                      display: "inline-block",
                      margin: 2,
                    }}
                  />
                )
              )}
            </Col>
          </Row>
          <Row>
            <Col xsOffset={1} xs={10}>
              <AuthComponent
                component={
                  <Link to={urlBase}>
                    <RaisedButton
                      label={LBLPROJECTS.buttons.edit}
                      secondary
                      style={{
                        margin: "40px 12px 12px",
                        float: "right",
                      }}
                    />
                  </Link>
                }
                permission="backend/projects:write"
                type="component"
              />
            </Col>
          </Row>
        </div>
      );
    }
    return <div />;
  }
}

function mapStateToProps({ projects }) {
  return {
    businessName: _.get(projects, "all.data.company.businessName", ""),
    projects: projects.all,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      FetchProjectParam,
    },
    dispatch
  );
}

generalInfoProjectComponent.propTypes = {
  projectId: PropTypes.string.isRequired,
  urlBase: PropTypes.string.isRequired,
  urlBaseColors: PropTypes.string.isRequired,
  urlBaseRanking: PropTypes.string.isRequired,
  urlBaseFilter: PropTypes.string.isRequired,
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(generalInfoProjectComponent));
