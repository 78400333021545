export const GET_RECORDS = "GET_RECORDS";
export const CLEAN_TABLE = "CLEAN_TABLE";
export const REMOVE_RECORD = "REMOVE_RECORD";
export const REMOVE_MASSIVE_RECORD = "REMOVE_MASSIVE_RECORD";
export const CHANGE_FIELD_RECORD = "CHANGE_FIELD_RECORD";
export const LOCKED_RECORD = "LOCKED_RECORD";
export const CHANGE_STATUS_RECORD = "CHANGE_STATUS_RECORD";
export const RESET_ORDER_RECORDS = "RESET_ORDER_RECORDS";
export const SET_ARRAY_FIELDS_FILTER = "SET_ARRAY_FIELDS_FILTER";
export const SET_FIELD_SELECTION = "SET_FIELD_SELECTION";
export const CLEAN_SELECTED = "CLEAN_SELECTED";
export const ADD_SELECTED = "ADD_SELECTED";
export const REMOVE_SELECTED = "REMOVE_SELECTED";
export const ADD_MASSIVE_SELECTED = "ADD_MASSIVE_SELECTED";
export const REMOVE_MASSIVE_SELECTED = "REMOVE_MASSIVE_SELECTED";
export const SET_SELECTED = "SET_SELECTED";
export const SET_SELECTED_WITH_RECORDS = "SET_SELECTED_WITH_RECORDS";
export const SET_STATUS_FILTER = "SET_STATUS_FILTER";
export const SET_IN_SEARCH_RECORDS = "SET_IN_SEARCH_RECORDS";
export const CHANGE_LIMIT_RECORDS = "CHANGE_LIMIT_RECORDS";
export const CHANGE_INFO_OF_FIELD_RECORD = "CHANGE_INFO_OF_FIELD_RECORD";
export const ADD_RECORD = "ADD_RECORD";
export const GET_USER_PROFILES = "GET_USER_PROFILES";
