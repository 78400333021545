import axios from 'axios';
import { GET_SECTIONS_GRID } from '../../constants/index';
import { axiosAPI } from '../../middleware/api';

export function FetchSection() {

    const request = axiosAPI.get(`/sections`);

    return {
        type: GET_SECTIONS_GRID,
        payload: request
    }
}
