import React, {useMemo} from "react";
import Select from "react-select";
import {makeStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        marginLeft: 8,
        marginTop: 12,
        width: "100%",
        textAlign: "initial",
    },
    label: {
        marginBottom: 6,
    },
}));

type ReactSelectProps = {
    label: string
    name: string
    isMulti: boolean
    options: any
    onChange: any
    placeholder: string
    keys: object
};

/**
 * Transformation of values for the adaptation of the Select
 * @author Jhoan Lopez <jhoan.lopez@ias.com.co>
 * @param {*} data
 */
const assingItems = (data = [], keys) => {
    return data.map((option) => ({label: option[keys.label], value: option[keys.value]}));
};

/**
 * Basic component of the react select library
 * @author Jhoan Lopez <jhoan.lopez@ias.com.co>
 * @param name
 * @param isMulti
 * @param options
 * @param onChange
 * @param placeholder
 * @param label
 * @constructor
 */
const BasicReactSelect: React.FC<ReactSelectProps> = ({
                                                          name,
                                                          isMulti,
                                                          options,
                                                          onChange,
                                                          placeholder,
                                                          label,
                                                          keys
                                                      }) => {
    const classes = useStyles();
    const defaultOptions = useMemo(() => assingItems(options, keys), [options]);

    return (
        <div className={classes.root}>
            <Typography variant="caption" className={classes.label}>
                {label}
            </Typography>
            <Select
                isMulti={isMulti}
                label={label}
                name={name}
                options={defaultOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(item) => onChange({target: {name, value: item.value}})}
                placeholder={placeholder}
            />
        </div>
    );
};

export default BasicReactSelect;
