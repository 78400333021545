import _ from 'lodash';
import {TOGGLE_SNACKBAR} from '../actions/commons';

export default (state = {delete: false, company: {}}, action) => {
    switch (action.type) {
        case TOGGLE_SNACKBAR:
            return _.assign({}, state, {
                status: action.status,
                message: action.message
            });
        default:
            return state;
    }
}
