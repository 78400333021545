import Immutable from 'immutable';
import { GET_SURVEY_ADVANCE } from './actions';

const INITIAL_STATE = Immutable.Map({
    advance: null
});

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_SURVEY_ADVANCE:
            return state.set('advance', action.payload.data.data);
        default:
            return state;
    }
};
