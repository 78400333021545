import React, { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import { blue700 } from "material-ui/styles/colors";
import _ from "lodash";
import { LBLPROJECTS } from "Labels";
import { useAuth } from "../../../auth/context";

const customContentStyle = {
  width: "70%",
  maxWidth: "none",
  textAlign: "center",
};

export default function IdleMonitor({ time, isAdmin }) {
  const [state, setState] = useState("Active");
  const [open, setOpen] = useState(false);
  const [remaining, setRemaining] = useState(0);
  const timeValue = _.isUndefined(time) ? 20 : time;
  const { signOut } = useAuth();

  const onIdle = () => {
    setOpen(true);
    const isAdminValue = _.isUndefined(isAdmin) ? false : isAdmin;
    if (!isAdminValue) {
      setOpen(true);
    }
  };

  const onActive = () => {
    setState("Active");
  };

  const handlerCloseSession = () => {
    setOpen(false);
    window.location.replace(`/login`);
    // signOut();
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    timeout: time * 60 * 1000,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <>
      <Dialog
        title="Tiempo inactivo"
        titleStyle={{ textAlign: "center", color: blue700 }}
        actions={[
          <FlatButton
            label="Aceptar"
            secondary
            keyboardFocused
            onClick={handlerCloseSession}
          />,
        ]}
        modal
        contentStyle={customContentStyle}
        open={open}
      >
        <h3 style={{ textAlign: "center", fontWeight: 200 }}>
          {LBLPROJECTS.msg.inactiveMessageIDLE(timeValue)}
        </h3>
      </Dialog>
    </>
  );
}
