import React, { Component } from "react";
import DatePicker from "material-ui/DatePicker";
import moment from "moment";
import areIntlLocalesSupported from "intl-locales-supported";
import _ from "lodash";
import { spanishLocale } from "../../../constants/index";

let DateTimeFormat;
if (window.Intl) {
  if (areIntlLocalesSupported("es")) {
    DateTimeFormat = global.Intl.DateTimeFormat;
  }
} else {
  require.ensure(
    ["intl", `intl/locale-data/jsonp/${spanishLocale}`],
    function (require) {
      require("intl");
      require(`intl/locale-data/jsonp/${spanishLocale}`);
      DateTimeFormat = global.Intl.DateTimeFormat;
    }
  );
}

export default class DatePickerFormat extends Component {
  constructor(props) {
    super(props);
    const minDate = new Date();
    const maxDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 295);
    maxDate.setFullYear(maxDate.getFullYear() + 100);
    this.state = {
      minDate,
      maxDate,
    };
  }

  render(){
    const { datePickerConfig, stylefloatingLabelText } = this.props;
    const config = _.has(datePickerConfig, "floatingLabelText")
      ? _.omit(datePickerConfig, "floatingLabelText")
      : datePickerConfig;
    const floatingLabelText = !_.has(datePickerConfig, "floatingLabelText")
      ? "."
      : _.isUndefined(config.value)
      ? "."
      : _.get(datePickerConfig, "floatingLabelText");
    let styleFloatingText =
      _.isUndefined(stylefloatingLabelText) || _.isNull(stylefloatingLabelText)
        ? { fontWeight: 500 }
        : stylefloatingLabelText;
    styleFloatingText = _.chain(styleFloatingText)
      .set("fontWeight", 500)
      .set(
        "color",
        _.isUndefined(config.value) ||
          _.isEqual(config.value, "") ||
          _.isEqual(floatingLabelText, ".")
          ? "rgba(0, 0, 0, 0)"
          : "rgba(0, 0, 0, 0.498039)"
      )
      .value();
    return (
      <div>
        <h5 style={styleFloatingText}>{floatingLabelText}</h5>
        <DatePicker
          DateTimeFormat={DateTimeFormat}
          locale="es"
          className="datepickerformat"
          autoOk
          minDate={this.state.minDate}
          textFieldStyle={{ zIndex: 0 }}
          formatDate={(date) => {
            return moment(date).format("DD/MM/YYYY");
          }}
          style={{ marginTop: -10, marginBottom: 23, zIndex: 10 }}
          okLabel="ACEPTAR"
          cancelLabel="CANCELAR"
          {...config}
        />
      </div>
    );
  }
}
