import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import {Row, Col} from 'react-flexbox-grid';
import {blue700} from 'material-ui/styles/colors';

class DataCompany extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { result } = this.props;
        if(_.isNull(result)){
            return ( <div /> )
        } 
            const tdStyle = { width: 170 };
            const h4Style = { fontWeight: 400, margin: '2px 0' };
            const h4StyleTitle = { fontWeight: 600, margin: '2px 0' };
            return (
                <div style={{padding: '0 20px'}}>
                    <Row>
                        <Col xs>
                            <h1 style={{fontWeight: 400, marginTop: 30}}>Información de la empresa</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs>
                            <table>
                                <tbody>
                                    <tr>
                                        <td style={tdStyle}><h4 style={h4StyleTitle}>NIT:</h4></td>
                                        <td><h4 style={h4Style}>{_.get(result, 'companyNit')}</h4></td>
                                    </tr>
                                    <tr>
                                        <td style={tdStyle}><h4 style={h4StyleTitle}>Razón social:</h4></td>
                                        <td><h4 style={h4Style}>{_.get(result, 'companyName')}</h4></td>
                                    </tr>
                                    <tr>
                                        <td style={tdStyle}><h4 style={h4StyleTitle}>Correo electrónico:</h4></td>
                                        <td><h4 style={h4Style}>{_.get(result, 'companyEmail')}</h4></td>
                                    </tr>
                                    <tr>
                                        <td style={tdStyle}><h4 style={h4StyleTitle}>Dirección:</h4></td>
                                        <td><h4 style={h4Style}>{_.get(result, 'companyAddress')}</h4></td>
                                    </tr>
                                    <tr>
                                        <td style={tdStyle}><h4 style={h4StyleTitle}>Teléfono:</h4></td>
                                        <td><h4 style={h4Style}>{_.get(result, 'companyPhone')}</h4></td>
                                    </tr>
                                    <tr>
                                        <td style={tdStyle}><h4 style={h4StyleTitle}>Nombre del contacto:</h4></td>
                                        <td><h4 style={h4Style}>{_.get(result, 'companyContactName')}</h4></td>
                                    </tr>
                                    <tr>
                                        <td style={tdStyle}><h4 style={h4StyleTitle}>Teléfono del contacto:</h4></td>
                                        <td><h4 style={h4Style}>{_.get(result, 'companyContactPhone')}</h4></td>
                                    </tr>
                                    <tr>
                                        <td style={tdStyle}><h4 style={h4StyleTitle}>Correo electrónico del contacto:</h4></td>
                                        <td><h4 style={h4Style}>{_.get(result, 'companyContactEmail')}</h4></td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </div>
            );
        
    }
}

export default DataCompany;
