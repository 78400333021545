import _ from "lodash";
import { Observable } from "rxjs";
import { Map } from "immutable";
import { axiosApiV2, axiosAPI } from "../../middleware/api";

export const LOAD_PROJECT_METADATA = "nss/metadataEditor/LOAD_PROJECT_METADATA";
export const UPDATE_METADATA = "nss/metadataEditor/UPDATE_METADATA";
export const CLEAR_METADATA = "nss/metadataEditor/CLEAR_METADATA";

// Reducers
const initialState = Map({
  metadata: {},
  project: {},
});

export default function (state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_PROJECT_METADATA:
      const dataFromServer = action.payload.data;
      return state.withMutations((map) => {
        map.set("metadata", _.get(dataFromServer, "metadata", {}));
        map.set("project", _.omit(dataFromServer, "metadata"));
      });
    default:
      return state;
  }
}

// Action creators
export function loadProjectData(projectId) {
  const request = axiosApiV2.get(`/results/metadata/${projectId}`);
  return {
    type: LOAD_PROJECT_METADATA,
    payload: request,
  };
}

export function updateMetadata(projectId, itemId, subItemIndex, coi) {
  const request = axiosApiV2.post(`/results/metadata/${projectId}`, {
    itemId,
    subItemIndex,
    coi,
  });

  return {
    type: UPDATE_METADATA,
    payload: request,
  };
}
