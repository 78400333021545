import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {removeAliasesEmail} from "Utils";
import {LOCALE_PROFILE} from "../../components/users/localTranslation";

const headCells = [
  {
    id: "nombreAtributo	",
    label: "Nombre de atributo",
  },
  { id: "value", label: "Valor" },
];

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell align="cebter">{headCell.label}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
const useToolbarStyles = makeStyles((theme) => ({
  root: {
    borderBottom: "1px solid #E0E0E0",
    backgroundColor: "#F7F7F7",
    margin: 0,
  },
  title: {
    flex: "1 1 100%",
    fontSize: 25,
    fontWeight: 1,
    textAlign: "left",
    // marginLeft:"10%"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  button: {
    margin: theme.spacing(1),
    border: "1px solid blue600",
  },
}));

const EnhancedTableToolbar = () => {
  const classes = useToolbarStyles();
  const history = useHistory();
  const dataUser = history.location;

  const handleAction = () => {
    history.push({ pathname: `/admin/users/edit/:user`, state: dataUser });
  };
  return (
    <Toolbar className={classes.root}>
      {
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Atributos de usuario
        </Typography>
      }
      {
          <Button
            variant="contained"
            color="primary"
            size="medium"
            disableElevation
            onClick={handleAction}
          >
            Editar
          </Button>
      }
    </Toolbar>
  );
};
const useStyles = makeStyles((theme) => ({
  root: {
    height: "94%",
    margin: "1%",
    borderLeft: "1px solid #D0D0D0",
    backgroundColor: "#F7F7F7",
  },
}));
function TableAtributesUser({data}) {
  const {person, user} = data
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Paper>
        <EnhancedTableToolbar />
        <TableContainer>
          <Table aria-labelledby="tableTitle">
            <EnhancedTableHead />
            <TableBody>
              <TableRow>
                <TableCell align="left">Nombres</TableCell>
                <TableCell align="left">
                  {person.information.firstName}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Apellidos </TableCell>
                <TableCell align="left">
                  {person.information.lastName}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Perfil</TableCell>
                <TableCell align="left">{LOCALE_PROFILE[user.profiles[0]] ? LOCALE_PROFILE[user.profiles[0]] : user.profiles[0]}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Correo</TableCell>
                <TableCell align="left">{removeAliasesEmail(person.information.email)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Identificación</TableCell>
                <TableCell align="left">
                  {person.information.identification.number}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
export default TableAtributesUser;
