import * as React from 'react';
import {
    Formik,
    Form,
} from 'formik';
import {Row, Col} from "react-flexbox-grid";
import {LBLUSERS} from "Labels";
import {Paper, Typography, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {RaisedButton} from "material-ui";
import Checkbox from "material-ui/Checkbox";
import * as Yup from "yup";
import {useNotifications} from "Notifications";
import {useHistory} from "react-router-dom"
import {removeAliasesEmail} from "Utils";
import AdaptiveSelector from "./adaptiveSelector";
import ButtonBack from "../../fields/buttonBack/buttonBack";
import operations from "../../../containers/users/http";
import {LOCALE_USER_TYPE} from '../localTranslation';

const useStyles = makeStyles(theme => ({
    buttonContent: {
        textAlign: "center",
        marginTop: 36,
        marginBottom: 20,
    },
    paper: {
        marginTop: 20,
        marginBottom: 20,
        paddingTop: 20
    },
    contentCheckbox: {
        display: 'flex',
        justifyContent: 'center'
    },
    checkbox: {
        margin: theme.spacing(1),
        maxWidth: 280
    },
    textField: {
        margin: theme.spacing(1),
        width: "50%"
    }
}))

const schema = Yup.object().shape({
    identificationNumber: Yup.number().typeError('La identificación solo debe de contener números')
        .required("Identificación requerida"),
    firstName: Yup.string()
        .required("Nombres requeridos"),
    lastName: Yup.string()
        .required("Apellidos requeridos"),
    emailAddress: Yup.string()
        .required("Email requerido").email('El email debe ser válido').max(255)
});

interface FormValues {
    userId?: string
    userType?: string
    userProfile: string
    identificationType: string
    emailAddress: string
    identificationNumber: string
    firstName: string
    lastName: string,
    sendNotification: boolean,
    username: string
}

/**
 * Component for creating a user
 * @author Jhoan Lopez <jhoan.lopez@ias.com.co>
 * @constructor
 */
const UserCreate: React.FC<{}> = () => {
    const classes = useStyles();
    const {sendNotification} = useNotifications();

    const history = useHistory<{ dataUser: string | undefined }>();
    const editUser: boolean = history.location.pathname.includes('edit');
    const dataUser: any = editUser && history.location.state;
    const dataEdit: any = dataUser.state || '';
    const url: string = '/admin/users/grid';
    const initialValues: FormValues =
        editUser === false ?
            {
                // userId: '',
                userType: 'EXTERNAL',
                userProfile: 'EMPLOYEE',
                identificationType: 'CC',
                identificationNumber: '',
                emailAddress: '',
                firstName: '',
                lastName: '',
                username: '',
                sendNotification: true
            } :
            editUser === true ?
                {
                    userId: dataEdit.user.userId,
                    userType: dataEdit.user.type.kind,
                    userProfile: dataEdit.user.profiles[0],
                    identificationType: dataEdit.person.information.identification.type,
                    identificationNumber: dataEdit.person.information.identification.number,
                    emailAddress: removeAliasesEmail(dataEdit.person.information.email),
                    firstName: dataEdit.person.information.firstName,
                    lastName: dataEdit.person.information.lastName,
                    ...(dataEdit.user.type.kind === 'INTERNAL') && {username: dataEdit.user.type.emailAddress},
                    sendNotification: false
                } : null


    return (
        <Row center="xs">
            <Col xs={12} md={8}>
                <Paper className={classes.paper}>
                    <Row>
                        <Col xsOffset={1} xs={10}>
                            {
                                editUser ? <Typography variant="h5">{`${LBLUSERS.titleEdit}`}</Typography> :
                                    <Typography variant="h5">{`${LBLUSERS.titleCreate}`}</Typography>
                            }
                            <ButtonBack url={url}
                            />
                        </Col>
                    </Row>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={(values, actions) => {
                            if (!editUser) {
                                const createScheme = {
                                    userProfile: values.userProfile,
                                    userType: {
                                        kind: values.userType,
                                        ...(values.userType === 'INTERNAL') && {username: values.emailAddress},
                                    },
                                    personInformation: {
                                        identification: {
                                            type: values.identificationType,
                                            number: values.identificationNumber
                                        },
                                        firstName: values.firstName,
                                        lastName: values.lastName,
                                        email: values.emailAddress
                                    },
                                    sendNotification: values.sendNotification
                                }

                                operations.saveUser({...createScheme, }).then(() => {
                                    sendNotification('Usuario creado correctamente');
                                    history.push(`/admin/users/grid`);
                                }).catch(({response}) => {
                                    if (response.data.title === 'USER_EMAIL_ALREADY_EXISTS') {
                                        sendNotification('El email del usuario ya existe');
                                    }
                                    if (response.data.title === 'USER_ID_ALREADY_EXISTS') {
                                        sendNotification('La identificacion del usuario ya existe');
                                    }
                                })
                                // sendNotification('La identificacion del usuario o el email del usuario ya existe');
                                actions.setSubmitting(false);
                            } else {
                                const updateScheme = {
                                    id: values.userId,
                                    userProfile: values.userProfile,
                                    emailAddress: values.emailAddress,
                                    firstName: values.firstName,
                                    lastName: values.lastName,
                                }
                                operations.updateUser(updateScheme).then(() => {
                                    sendNotification('Usuario modificado correctamente');
                                    history.push(`/admin/users/grid`);
                                }).catch(() => {
                                    sendNotification('"error al editar usuario');
                                })
                                actions.setSubmitting(false);
                            }
                        }}
                        validationSchema={schema}
                    >
                        {({values, handleChange, touched, errors}) => (
                            <Form>
                                <Row>
                                    <Col xs>
                                        <AdaptiveSelector label="Tipo de usuario" name="userType"
                                                          value={
                                                              values.userType ||
                                                              LOCALE_USER_TYPE[initialValues.userType]}

                                                          handleChange={handleChange}
                                                          disabled={editUser}
                                                          data={operations.getUserTypes}/>
                                    </Col>
                                    <Col xs>
                                        <AdaptiveSelector
                                            label="Perfil"
                                            name="userProfile"
                                            value={values.userProfile || initialValues.userProfile}
                                            handleChange={handleChange}
                                            data={operations.getUserProfiles}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs>
                                        <AdaptiveSelector label="Tipo de documento" name="identificationType"
                                                          value={values.identificationType || initialValues.identificationType}
                                                          handleChange={handleChange}
                                                          disabled={editUser}
                                                          data={operations.getDocumentTypes}/>
                                    </Col>
                                    <Col xs>
                                        <TextField
                                            label={LBLUSERS.id}
                                            value={values.identificationNumber || initialValues.identificationNumber}
                                            type="text"
                                            name="identificationNumber"
                                            onChange={handleChange}
                                            disabled={editUser}
                                            className={classes.textField}
                                            error={
                                                touched.identificationNumber &&
                                                Boolean(errors.identificationNumber)
                                            }
                                            helperText={
                                                touched.identificationNumber && errors.identificationNumber
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs>
                                        <TextField
                                            label={LBLUSERS.name}
                                            value={values.firstName || initialValues.firstName}
                                            type="text"
                                            name="firstName"
                                            onChange={handleChange}
                                            className={classes.textField}
                                            error={
                                                touched.firstName &&
                                                Boolean(errors.firstName)
                                            }
                                            helperText={
                                                touched.firstName && errors.firstName
                                            }
                                        />
                                    </Col>
                                    <Col xs>
                                        <TextField
                                            label={LBLUSERS.lastName}
                                            value={values.lastName ||

                                                initialValues.lastName}
                                            type="text"
                                            name="lastName"
                                            onChange={handleChange}
                                            className={classes.textField}
                                            error={
                                                touched.lastName &&
                                                Boolean(errors.lastName)
                                            }
                                            helperText={
                                                touched.lastName && errors.lastName
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs>
                                        <TextField
                                            label={LBLUSERS.email}
                                            value={values.emailAddress || initialValues.emailAddress}
                                            type="text"
                                            name="emailAddress"
                                            onChange={handleChange}
                                            className={classes.textField}
                                            error={
                                                touched.emailAddress &&
                                                Boolean(errors.emailAddress)
                                            }
                                            helperText={
                                                touched.emailAddress && errors.emailAddress
                                            }
                                        />
                                    </Col>
                                    <Col xs />
                                </Row>
                                {!editUser &&
                                    <Row>
                                        <Col xs className={classes.contentCheckbox}>
                                            <Checkbox
                                                label="Enviar notificación de creación"
                                                name="sendNotification"
                                                disabled={values.userType === "INTERNAL"}
                                                onCheck={handleChange}
                                                className={classes.checkbox}
                                                checked={values.sendNotification}
                                            />
                                        </Col>
                                        <Col xs/>
                                    </Row>
                                }
                                <Row center="xs">
                                    <Col xs className={classes.buttonContent}>
                                        <RaisedButton
                                            label={editUser ? "Modificar" : "Guardar"}
                                            secondary
                                            type="submit"
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </Paper>
            </Col>
        </Row>
    );
};

export default UserCreate