import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import promise from "redux-promise";
import { createEpicMiddleware } from "redux-observable";
import rootReducer from "../reducers";
import rootEpic from "./epics";

const epicMiddleware = createEpicMiddleware(rootEpic);

function configureStore() {
  const finalCreateStore = applyMiddleware(
    thunk,
    promise,
    epicMiddleware
  )(createStore);
  return finalCreateStore(rootReducer);
}

export default configureStore;
