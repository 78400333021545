import { axiosApiV2 } from "../../middleware/api";
import { GET_PROFILE } from "../../constants/index";

export function FetchProfileParam(id) {
  const request = axiosApiV2.get(`/profiles/${id}`);

  return {
    type: GET_PROFILE,
    payload: request,
  };
}
