import React, { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";
import Auth from "@aws-amplify/auth";
import axios from "axios";

const AuthContext = createContext({
  user: null,
  permissions: new Set(),
  setUser: () => {},
  signOut: () => {},
  setPermissions: () => {},
});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [permissions, setPermissions] = useState(new Set());

  const logoutEndpointCognito = async () => {
    try {
      const response = await axios.get(`${process.env.CONFIG_GET_LOGOUT}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) { /* empty */ } else { /* empty */ }
    } catch (error) {
      console.error('Request error:', error);
    }
  };

  const handleSignOut = async () => {
    try {
      await Auth.signOut({
        global: true,
      });
      await logoutEndpointCognito()
      setUser(null);
      setPermissions(new Set());
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        permissions,
        setUser,
        setPermissions,
        signOut: handleSignOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export function useAuth() {
  return useContext(AuthContext);
}
