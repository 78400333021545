import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm, addArrayValue } from 'redux-form';
import {RadioButton, RadioButtonGroup} from 'material-ui/RadioButton';
import { Row, Col } from 'react-flexbox-grid';
import IconButton from 'material-ui/IconButton';
import NoCollapseIcon from 'material-ui/svg-icons/navigation/arrow-drop-up';
import CollapseIcon from 'material-ui/svg-icons/navigation/arrow-drop-down';
// import NoCollapseIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-right';
// import CollapseIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-down';
import _ from 'lodash';
import PureInput from '../../components/PureInput';

class QuestionMetricalItemsGroupNews extends Component {

    constructor(props){
        super(props);
        this._changeVisibleItems = this._changeVisibleItems.bind(this);
        this.state = {
            visible: true
        };
    }

    _changeVisibleItems(){
        this.setState({
            visible: !this.state.visible
        });
    }

    render() {
        const {
            fields: {
                _id,
                title,
                type,
                lists,
                typeCalc,
                questions
            }
        } = this.props;
        return (
            <Row>
                <Col xs={10} xsOffset={1} md={10} mdOffset={1}>
                    <Row style={{marginBottom: 10}}>
                        <Col xs={11}>
                            <h3 style={{width: '100%', minWidth: 280, textAlign: 'justify'}}>{title.value}</h3>
                        </Col>
                        <Col xs={1}>
                            <IconButton tooltip={this.state.visible ? "Ocultar" : "Mostrar"}
                                        tooltipPosition="top-center"
                                        onClick={this._changeVisibleItems}
                                        children={this.state.visible
                                        ? <CollapseIcon/>
                                        : <NoCollapseIcon/>}
                            />
                        </Col>
                    </Row>
                    <Row style={{display: this.state.visible ? 'flex' : 'none'}}>
                        <Col xs>
                            <RadioButtonGroup
                                name="type"
                                onChange={(e, value) => typeCalc.onChange(value)}
                                defaultSelected="summatory"
                                valueSelected={typeCalc.value}
                                style={{marginTop: 15}}
                            >
                                <RadioButton
                                    value="summatory"
                                    label="Sumatoria"
                                    style={{marginBottom: 16, display: 'inline-block', width: 130}}
                                />
                                <RadioButton
                                    value="frecuency"
                                    label="Frecuencia"
                                    style={{marginBottom: 16, display: 'inline-block', width: 130}}
                                />
                            </RadioButtonGroup>
                        </Col>
                    </Row>
                    <Row style={{display: this.state.visible ? 'flex' : 'none'}}>
                        <Col xs>
                            <h2 style={{fontWeight: 200, width: '100%', textAlign: 'center', minWidth: 280, margin: '30px 0'}}>Valorar las opciones de las preguntas agrupadas</h2>
                        </Col>
                    </Row>
                        {_.map(questions, (question, index) =>
                            <Row key={index} style={{display: this.state.visible ? 'flex' : 'none'}}>
                                <Col xs>
                                    <h3 style={{fontWeight: 400, width: '100%', minWidth: 280}}>{question.title.value}</h3>
                                </Col>
                                <Col xs>
                                    {_.map(_.get(question, 'lists', []), (list, index) =>
                                        <Row key={index}>
                                            <Col xs style={{paddingTop: 0, paddingBottom: 0, paddingLeft: 10, paddingRight: 10, minWidth: 190}}>
                                                <h3 style={{fontWeight: 200}}>{list.value.value}</h3>
                                            </Col>
                                            <Col xs style={{paddingTop: 0, paddingBottom: 0, paddingLeft: 10, paddingRight: 10, minWidth: 110}}>
                                                <PureInput
                                                    hintText="Valor"
                                                    type={_.isEqual(typeCalc.value, '') ? 'number' :_.isEqual(typeCalc.value, 'summatory') ? "number" : "text"}
                                                    errorText={list.metrical.touched && list.metrical.error ? list.metrical.error : '' }
                                                    field={list.metrical}
                                                    style={{width: "100%", marginTop: -8, marginBottom: 8, marginLeft: 10}}
                                                />
                                            </Col>
                                        </Row>
                                    )}
                                </Col>
                            </Row>
                        )}
                </Col>
            </Row>
        );
    }
}

QuestionMetricalItemsGroupNews.propTypes = {
    fields: PropTypes.object.isRequired
};

export default reduxForm({
    form: 'QuestionMetricalItemsGroupNewsForm',
    fields: [
        '_id',
        'title',
        'type',
        'typeCalc',
        'questions[]._id',
        'questions[].title',
        'questions[].type',
        'questions[].lists[]._id',
        'questions[].lists[].key',
        'questions[].lists[].value',
        'questions[].lists[].name',
        'questions[].lists[].metrical'
    ]
})( QuestionMetricalItemsGroupNews );
