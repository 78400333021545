import PropTypes from "prop-types";
import React, { Component } from "react";
import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from "material-ui/Table";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import IconButton from "material-ui/IconButton";
import RefreshIcon from "material-ui/svg-icons/navigation/refresh";
import { toggleSnackbar } from "../../actions/commons";
import LoadingPage from "../commons/loadingPage";
import TableHeadPagination from "./tableHeadPagination";
import TableBodyPagination from "./tableBodyPagination";
import ActionPagination from "./actionPagination";
import SelectedRecords from "./selectedRecords";
import AuthComponent from "../AuthComponent";
import { getRecords, cleanTable, setArrayfieldsFilter, setFieldSelection } from "./actions";
import { cleanTagEmployee } from "../TagsEmployee/actions";

class GenericTablePagination extends Component {
    constructor(props) {
        super(props);
        this._toUpdateTable = this._toUpdateTable.bind(this);
    }

    UNSAFE_componentWillMount() {
        const { cleanTagEmployee } = this.props;
        cleanTagEmployee();
        this._toUpdateTable();
    }

    _toUpdateTable() {
        const {
            body,
            URL,
            version,
            limit,
            cleanTable,
            getRecords,
            extra,
            setArrayfieldsFilter,
            fieldSelection,
            setFieldSelection,
            coreName,
        } = this.props;
        const arrayFields = [];
        _.map(body, (field) => {
            if (!_.has(field, "actions") && _.has(field, "filter")) {
                if (_.get(field, "filter", false)) {
                    arrayFields.push(_.get(field, "field"));
                }
            }
        });
        if (_.size(arrayFields) === 1) {
            arrayFields.push("default");
        }
        cleanTable(coreName);
        setArrayfieldsFilter(arrayFields, coreName);
        setFieldSelection(_.isUndefined(fieldSelection) ? null : fieldSelection, coreName);
        if (!_.isUndefined(extra) && !_.isNull(extra)) {
            getRecords(URL, version, 0, 25, "null", 0, arrayFields, "", coreName, extra);
        } else {
            getRecords(URL, version, 0, 25, "null", 0, arrayFields, "", coreName);
        }
    }

    render() {
        const {
            records,
            body,
            name,
            total,
            URL,
            version,
            textFilter,
            extra,
            selection,
            coreName,
            refresh,
            refreshName,
            hasFilter,
        } = this.props;

        const refreshView = _.isUndefined(refresh) ? true : refresh;
        const hasFilterTable = _.isUndefined(hasFilter) ? true : hasFilter;
        const refreshNameView = _.isUndefined(refreshName) ? "Limpiar" : refreshName;

        if (!_.isUndefined(records.size)) {
            if (records.size === 0) {
                return <LoadingPage visible />;
            }
        } else {
            if (_.size(records) === 0) {
                return (
                    <div style={{ marginTop: 20, marginBottom: 40, textAlign: "center" }}>
                        {refreshView && (
                            <IconButton
                                tooltip="Recargar"
                                style={{ margin: "5px auto" }}
                                tooltipPosition="top-center"
                                onClick={this._toUpdateTable}
                                children={<RefreshIcon />}
                            />
                        )}
                        <br />
                        <h2 style={{ marginTop: 10, marginBottom: 40, textAlign: "center", fontWeight: 200 }}>
                            {_.isEqual(textFilter, "")
                                ? `No se encontro ningún registro`
                                : `No se encontro ningún registro con el filtro "${textFilter}"`}
                        </h2>
                    </div>
                );
            }
                return (
                    <div>
                        <SelectedRecords name={name} selection={selection} coreName={coreName} />
                        <ActionPagination URL={URL} version={version} extra={extra} coreName={coreName} />
                        <TableHeadPagination
                            URL={URL}
                            version={version}
                            body={body}
                            extra={extra}
                            selection={selection}
                            coreName={coreName}
                            refreshName={refreshNameView}
                            hasFilter={hasFilterTable}
                        />
                        <TableBodyPagination
                            name={name}
                            body={body}
                            extra={extra}
                            selection={selection}
                            coreName={coreName}
                        />
                        <ActionPagination URL={URL} version={version} extra={extra} coreName={coreName} />
                    </div>
                );

        }
    }
}

function mapStateToProps({ genericTablePagination }, ownProps) {
    const store = genericTablePagination.get(_.get(ownProps, "coreName", "default"));
    return {
        records: _.isUndefined(store) ? [] : store.get("records"),
        skip: store.get("skip"),
        limit: store.get("limit"),
        total: store.get("total"),
        orderName: store.get("orderName"),
        orderType: store.get("orderType"),
        textFilter: store.get("textFilter"),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getRecords,
            cleanTable,
            setArrayfieldsFilter,
            setFieldSelection,
            cleanTagEmployee,
        },
        dispatch,
    );
}

GenericTablePagination.propTypes = {
    name: PropTypes.string.isRequired,
    URL: PropTypes.string.isRequired,
    version: PropTypes.number.isRequired,
    body: PropTypes.arrayOf(PropTypes.object).isRequired,
    fieldFilter: PropTypes.string,
    extra: PropTypes.object,
    selection: PropTypes.bool,
    fieldSelection: PropTypes.string,
    coreName: PropTypes.string.isRequired,
    refresh: PropTypes.bool,
    refreshName: PropTypes.string,
    hasFilter: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(GenericTablePagination);
