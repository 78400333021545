import * as Rx from "rxjs";
import { Map } from "immutable";
import { axiosApiV2 } from "../../middleware/api";

// actions
const CLEAN = "nss/reset-survey-application/CLEAN";

export const SUBMIT_RESET_REQUESTED =
  "nss/reset-survey-application/SUBMIT_RESET_REQUESTED";
export const SUBMIT_RESET_IN_PROGRESS =
  "nss/reset-survey-application/SUBMIT_RESET_IN_PROGRESS";
export const SUBMIT_RESET_FULFILLED =
  "nss/reset-survey-application/SUBMIT_RESET_FULFILLED";
export const SUBMIT_RESET_REJECTED =
  "nss/reset-survey-application/SUBMIT_RESET_REJECTED";
export const RELOAD_EMPLOYEE_STATUS =
  "nss/reset-survey-application/RELOAD_EMPLOYEE_STATUS";

const initialState = Map({
  status: CLEAN,
});
export default function (state = initialState, action = {}) {
  switch (action.type) {
    case CLEAN:
      return initialState;
    case SUBMIT_RESET_IN_PROGRESS:
    case SUBMIT_RESET_FULFILLED:
    case SUBMIT_RESET_REJECTED:
      return state.set("status", action.type);
    default:
      return state;
  }
}

// action creators
export const submitRequest = (projectId, employeeId) => {
  return {
    type: SUBMIT_RESET_REQUESTED,
    payload: {
      projectId,
      employeeId,
    },
  };
};

export const cleanStatus = () => {
  return {
    type: CLEAN,
  };
};

// epics
export const requestResetEpic$ = (action$) =>
  action$.ofType(SUBMIT_RESET_REQUESTED).mergeMap((action) => {
    const { projectId, employeeId } = action.payload;
    const promise = axiosApiV2.delete(`/solutions/${projectId}/${employeeId}`);
    const reloadEmployee$ = Rx.Observable.of({ type: RELOAD_EMPLOYEE_STATUS });
    const req$ = Rx.Observable.fromPromise(promise)
      .map((response) => ({
        type: SUBMIT_RESET_FULFILLED,
        payload: { data: response },
      }))
      .concat(reloadEmployee$)
      .concat(Rx.Observable.of(cleanStatus()).delay(3000))
      .catch((error) =>
        Rx.Observable.of({ type: SUBMIT_RESET_REJECTED, error })
      );
    return Rx.Observable.of({ type: SUBMIT_RESET_IN_PROGRESS }).concat(req$);
  });
