import PropTypes from "prop-types";
import React from "react";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";
import {
  blue700,
  orange700,
  green700,
  red700,
} from "material-ui/styles/colors";
import { LBLQUESTIONS } from "../../constants/labels";
import { addSelected, removeSelected } from "./actions";
import ActionsColumn from "./actionsColumn";

const isConfigEmployees = (value) =>
  [
    "configemployeesemail",
    "configemployeesstatus",
    "configemployeesstatuscompany",
  ].includes(value);

const TableBodyPagination = (props) => {
  const renderRowRecords = (record) => {
    const { body, name } = props;

    const styleColFields = {
      whiteSpace: "normal",
      wordWrap: "break-word",
      margin: "30px",  
      maxHeight: "150px",
      maxWidth: "150px",

    };
    const styleColFieldStatus = {
      whiteSpace: "normal",
      wordWrap: "break-word",
      borderLeft: `5px solid ${getColorBorder(record)}`,
 
    };
    const styleColFieldProjectByReferee = {
      whiteSpace: "normal",
      wordWrap: "break-word",
      borderLeft: `5px solid ${getColorBorderByReferee(record)}`,
   
    };

    return _.map(body, (field, index) => {
      const align = index === 0 ? "left" : "left";
      if (_.has(field, "actions")) {
        return (
          <ActionsColumn
            align={align}
            record={record}
            index={index}
            colSpan={_.get(field, "colspan", 1)}
            name={name}
            extra={props.extra}
          />
        );
      }
      if (index === 0 && isConfigEmployees(name)) {
        return (
          <TableCell
            align={align}
            key={`bodyRow${index}`}
            colSpan={_.get(field, "colspan", 1)}
            style={styleColFieldStatus}
          >
            {_.get(record, _.get(field, "field"), "---")}
          </TableCell>
        );
      }
      if (index === 0 && _.isEqual(name, "projectsByReferee")) {
        return (
          <TableCell
            align={align}
            key={`bodyRow${index}`}
            colSpan={_.get(field, "colspan", 1)}
            style={styleColFieldProjectByReferee}
          >
            {_.get(record, _.get(field, "field"), "---")}
          </TableCell>
        );
      }
      if (
        _.isEqual(_.get(field, "field"), "type") &&
        _.isEqual(name, "questions")
      ) {
        return (
          <TableCell
            align={align}
            key={`bodyRow${index}`}
            colSpan={_.get(field, "colspan", 1)}
            style={styleColFields}
          >
            {getTypeQuestion(_.get(record, _.get(field, "field"), "---"))}
          </TableCell>
        );
      }
      if (
        _.isEqual(_.get(field, "field"), "name") &&
        (_.isEqual(name, "countries") ||
          _.isEqual(name, "departments") ||
          _.isEqual(name, "cities"))
      ) {
        return (
          <TableCell
            align={align}
            key={`bodyRow${index}`}
            colSpan={_.get(field, "colspan", 1)}
            style={styleColFields}
          >
            {_.capitalize(_.get(record, _.get(field, "field"), "---"))}
          </TableCell>
        );
      }

      if (
        (_.isEqual(name, "projects") || _.isEqual(name, "projectsByReferee")) &&
        (_.isEqual(_.get(field, "field"), "time.openDate") ||
          _.isEqual(_.get(field, "field"), "time.closeDate"))
      ) {
        return (
          <TableCell
            align={align}
            key={`bodyRow${index}`}
            colSpan={_.get(field, "colspan", 1)}
            style={styleColFields}
          >
            {moment(_.get(record, _.get(field, "field"), "")).format(
              "DD/MM/YYYY"
            )}
          </TableCell>
        );
      }
      if (
        _.isEqual(name, "audit") &&
        _.isEqual(_.get(field, "field"), "date")
      ) {
        return (
          <TableCell
            align={align}
            key={`bodyRow${index}`}
            colSpan={_.get(field, "colspan", 1)}
            style={styleColFields}
          >
            {moment(_.get(record, _.get(field, "field"), "")).format("LLL")}
          </TableCell>
        );
      }
      return (
        <TableCell
          align={align}
          key={`bodyRow${index}`}
          colSpan={_.get(field, "colspan", 1)}
          style={styleColFields}
        >
          {_.get(record, _.get(field, "field"), "---")}
        </TableCell>
      );
    });
  };

  const handleRowSelected = (record, recordSeleted) => {
    const { addSelected, removeSelected, selection, fieldSelection } = props;
    const selectionRow =
      _.isUndefined(selection) ||
      _.isNull(selection) ||
      _.isUndefined(fieldSelection) ||
      _.isNull(fieldSelection)
        ? false
        : selection;
    if (selectionRow) {
      if (recordSeleted) {
        removeSelected(_.get(record, fieldSelection));
      } else {
        addSelected(_.get(record, fieldSelection));
      }
    }
  };

  const renderRecords = () => {
    const { records, skip, selection, fieldSelection } = props;
    return _.map(records, (record, index) => {
      const recordSeleted = _.has(record, "selected")
        ? _.get(record, "selected")
        : false;
      return (
        <TableRow
          key={`body${index}`}
          onClick={() => handleRowSelected(record, recordSeleted)}
          selected={recordSeleted}
        >
          {renderRowRecords(record)}
        </TableRow>
      );
    });
  };

  const { selection = false, fieldSelection } = props;
  const records = renderRecords();
  return <TableBody>{records}</TableBody>;
};

TableBodyPagination.defaultProps = {
  selection: false,
  extra: {},
};

TableBodyPagination.propTypes = {
  body: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  extra: PropTypes.shape({}),
  selection: PropTypes.bool,
};

function mapStateToProps({ tablePagination }) {
  return {
    skip: tablePagination.get("skip"),
    records: tablePagination.get("records"),
    fieldSelection: tablePagination.get("fieldSelection"),
  };
}

function getTypeQuestion(typeQuestion) {
  switch (typeQuestion) {
    case "AnswerOpen":
      return LBLQUESTIONS.arrTypesQuestions[0];
    case "AnswerClose":
      return LBLQUESTIONS.arrTypesQuestions[1];
    case "ListOpen":
      return LBLQUESTIONS.arrTypesQuestions[2];
    case "ListClose":
      return LBLQUESTIONS.arrTypesQuestions[3];
    case "Date":
      return LBLQUESTIONS.arrTypesQuestions[4];
    case "Number":
      return LBLQUESTIONS.arrTypesQuestions[5];
    case "AnswerOpenShort":
      return LBLQUESTIONS.arrTypesQuestions[6];
    case "GroupClose":
      return LBLQUESTIONS.arrTypesQuestions[7];
    case "GroupList":
      return LBLQUESTIONS.arrTypesQuestions[8];
    case "Group":
      return LBLQUESTIONS.arrTypesQuestions[9];
    case "ListSelected":
      return LBLQUESTIONS.arrTypesQuestions[10];
    case "LocationCountry":
      return LBLQUESTIONS.arrTypesQuestions[11];
    case "LocationDepartment":
      return LBLQUESTIONS.arrTypesQuestions[12];
    case "LocationCity":
      return LBLQUESTIONS.arrTypesQuestions[13];
    default:
      return "Vacío";
  }
}

function getColorBorder(record) {
  switch (_.get(record, "status")) {
    case "Pendiente":
      return "#EDE969";
    case "Desertor":
      return "#E34242";
    case "Finalizado":
      return "#58C257";
    case "En proceso":
      return "#EB9850";
    default:
      return "#FFF";
  }
}

function getColorBorderByReferee(record) {
  if (
    !_.isUndefined(_.get(record, "time.openDate")) &&
    !_.isUndefined(_.get(record, "time.closeDate"))
  ) {
    const initDate = moment(_.get(record, "time.openDate")).format(
      "YYYY-MM-DD"
    );
    const finishDate = moment(_.get(record, "time.closeDate")).format(
      "YYYY-MM-DD"
    );
    const currentDate = moment(new Date()).format("YYYY-MM-DD");
    if (moment(currentDate).isBefore(initDate)) {
      return red700;
    }
    if (moment(currentDate).isBefore(finishDate)) {
      return orange700;
    }
    return green700;
  }
  return blue700;
}

export default connect(mapStateToProps, { addSelected, removeSelected })(
  TableBodyPagination
);
