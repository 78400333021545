import Immutable from 'immutable';
import { GET_LIST_SECTIONS, CLEAN_LIST_SECTIONS } from './actions';

const initialState = Immutable.Map({
    values: Immutable.List()
});

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_LIST_SECTIONS:
            return state.set('values', action.payload.data);
        case CLEAN_LIST_SECTIONS:
            return state.set('values', Immutable.List());
        default:
            return state;
    }
};
