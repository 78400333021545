import React, {Component} from 'react';
import CircularProgress from 'material-ui/CircularProgress';

/**
 * This function receives the multiple states a component
 * may have and return different messages for each of them
 * as a mode of usability and user comprehension.
 * @param loadingState
 * @param rejectedState
 * @param fulfilledState
 * @param currentState
 * @param label
 * @param WrappedComponent
 */
export default function (loadingState, rejectedState, fulfilledState, currentState, label, WrappedComponent) {
    switch (currentState) {
    case loadingState:
        return (
            <div>
                <span>Cargando {label}...</span>
                <CircularProgress />
            </div>
        );
    case rejectedState:
        return <span>Ocurrió un error inesperado al cargar los datos de {label}, por favor intentelo de nuevo más tarde.</span>;
    case fulfilledState:
        return WrappedComponent;
    default:
        return <span/>;
    }
}
