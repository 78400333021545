import { axiosAPI } from '../middleware/api';

export const LOAD_DATA_FN_TABLE = 'LOAD_DATA_FN_TABLE';
export const DELETE_USER = 'DELETE_USER';
export const CLEAN_TABLE_USER = 'CLEAN_TABLE_USER';

export function loadData(url, skip, limit, storeName) {

    const request = axiosAPI.get(`/${url}`, {params: { limit, skip }});

    return {
        type: LOAD_DATA_FN_TABLE,
        meta: {
            storeName
        },
        payload: request
    };
}

export function deleteUser(userId) {

    const request = axiosAPI.delete(`/users/${userId}`, {data: { "type": 'logic' }});

    return {
        type: DELETE_USER,
        payload: request,
        meta:{
            userId
        }

    };
}

export function cleanTableUser(storeName) {
    return {
        type: CLEAN_TABLE_USER,
        meta: {
            storeName
        }
    };
}
