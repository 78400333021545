import { axiosApiV2 } from "../../middleware/api";
import {
  GET_DATA_PROJECT_ADVANCE,
  DATA_PROJECT_ADVANCE_IN_SEARCH,
} from "./constants";

export function getAdvanceProject(projectId) {
  const request = axiosApiV2.get(`/projects/employees/advance/${projectId}`);

  return {
    type: GET_DATA_PROJECT_ADVANCE,
    payload: request,
  };
}

export function inSearchProjectGraphic() {
  return {
    type: DATA_PROJECT_ADVANCE_IN_SEARCH,
  };
}
