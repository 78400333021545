import React, { useState } from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import { Row, Col } from "react-flexbox-grid";
import * as Yup from "yup";
import { InputAdornment, TextField, Typography } from "@material-ui/core";
import { RaisedButton } from "material-ui";
import Container from "./container";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import IconButton from "material-ui/IconButton";


const schema = Yup.object().shape({
  password: Yup.string()
    .matches(
      /^(?=.*[A-Z])(?=.*[0-9])(?=.*\W)(?=.{8,})/,
      "La contraseña debe contener mayúsculas, números, carácteres especiales y tener al menos 8 dígitos"
    )
    .required("La contraseña es requerida"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Los valores deben coincidir')
    .required("La confirmación de la contraseña es requerida")
});

const styles = {
  buttonContent: {
    textAlign: "center",
    marginTop: 36,
    marginBottom: 20,
  },
  fieldContent: {
    textAlign: "center",
    width: "100%",
    marginTop: 20,
  },
  textRow: {
    marginTop: 40,
  },
  input: {
    margin: "0 auto",
    textAlign: "left",
    width: 200,
  },
};

/**
 * Form to force user password change when logging in with temporary credentials
 * @param isLoading
 * @param onSubmit
 * @returns {JSX.Element}
 * @constructor
 */
function ChangeNewPassword({ isLoading, onSubmit }) {
  const [showPassword, setShowPassword] = useState({
    newPassword: false,
    confirmPassword: false,
  });

  const handleClickShowPassword = (field) => {
    setShowPassword({ ...showPassword, [field]: !showPassword[field] });
  };

  return (
    <Container>
      <>
        <Row style={styles.textRow}>
          <Col xs style={styles.fieldContent}>
            <Typography color="textSecondary">
              Debes cambiar la contraseña para continuar
            </Typography>
          </Col>
        </Row>
        <Formik
          initialValues={{
            password: "",
            confirmPassword: "",
          }}
          onSubmit={onSubmit}
          validationSchema={schema}
        >
          {(formik) => {
            return (
              <Form>
                <Row>
                  <Col xs style={styles.fieldContent}>
                    <TextField
                      style={styles.input}
                      label="Nueva contraseña"
                      type={showPassword.newPassword ? "text" : "password"}
                      fullWidth
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => handleClickShowPassword('newPassword')}
                            >
                              {showPassword.newPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs style={styles.fieldContent}>
                    <TextField
                      style={styles.input}
                      label="Confirmar contraseña"
                      type={showPassword.confirmPassword ? "text" : "password"}
                      fullWidth
                      name="confirmPassword"
                      value={formik.values.confirmPassword}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.confirmPassword &&
                        Boolean(formik.errors.confirmPassword)
                      }
                      helperText={
                        formik.touched.confirmPassword &&
                        formik.errors.confirmPassword
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => handleClickShowPassword('confirmPassword')}
                            >
                              {showPassword.confirmPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Col>
                </Row>
                <Row center="xs">
                  <Col xs style={styles.buttonContent}>
                    <RaisedButton
                      label="Cambiar"
                      secondary
                      disabled={isLoading}
                      type="submit"
                    />
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </>
    </Container>
  );
}

ChangeNewPassword.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ChangeNewPassword;