import { axiosAPI } from '../../../middleware/api';

export const CHECK_VALUE_FIELD = "CHECK_VALUE_FIELD";
export const CLEAN_STATE_TREE_MAP = "CLEAN_STATE_TREE_MAP";

/**
 *
 * @param fields Object
 * @param serviceUrl String URL
 * @param storeName String in the immutable map to save the values from the request.
 */
export function checkValueField(fields, serviceUrl, storeName) {

    const request = axiosAPI.get(`/${serviceUrl}/id`, { params: fields });

    return {
        type: CHECK_VALUE_FIELD,
        payload: request,
        meta: {
            storeName
        }
    };
}


export function cleanStateTreeMap(storeName) {
    return {
        type: CLEAN_STATE_TREE_MAP,
        storeName
    }
}
