import { axiosApiV2 } from "../../../../../middleware/api";
import { GET_DATA_PROJECT_ARRAY_ADVANCE } from "./constants";

export function getAdvanceArrayProject(arrayProjectsId) {
  const request = axiosApiV2.post(`/arl/companies/projects/status`, {
    arrayProjectsId,
  });

  return {
    payload: request,
    type: GET_DATA_PROJECT_ARRAY_ADVANCE,
  };
}
