import React, { Component } from "react";
import MenuItem from "material-ui/MenuItem";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { getCountries, getDepartments, cleanCities } from "./actions";
import SelectFieldFormat from "../SelectFieldFormat/SelectFieldFormat";

class SelectCountries extends Component {
    UNSAFE_componentWillMount() {
        const { getCountries } = this.props;
        getCountries();
    }

    _onChange = country => {
        const { getDepartments, cleanCities, field2, field3 } = this.props;
        getDepartments(country);
        cleanCities();
        field2.onChange(null);
        field3.onChange(null);
    };

    render() {
        const { config, field, countries } = this.props;
        return (
            <SelectFieldFormat
                config={_.assign(
                    {
                        value: field.value,
                        autoWidth: true,
                        errorText: field.touched && field.error ? field.error : "",
                        onChange: (e, index, value) => {
                            field.onChange(value);
                            this._onChange(value);
                        },
                    },
                    { ...config },
                )}
            >
                {_.map(_.orderBy(countries, ["name"]), item => (
                    <MenuItem key={item._id} value={item._id} primaryText={item.name} />
                ))}
            </SelectFieldFormat>
        );
    }
}

function mapStateToProps({ master }, ownProps) {
    return {
        countries: master.countries,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getCountries,
            getDepartments,
            cleanCities,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectCountries);
