import React, {useState} from 'react'
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select, Slide
} from "@material-ui/core";
import {Form, Formik, useFormikContext, useFormik, FormikProps} from "formik";
import {Col, Row} from "react-flexbox-grid";
import {makeStyles} from "@material-ui/core/styles";
import {TransitionProps} from '@material-ui/core/transitions';
import {useNotifications} from "Notifications";
import {
    useAddDatasetTrigger,
    useFindProjectsBySurveys,
    useGetSurveys
} from "../../../data";
import BasicReactSelect from "../../../../commons/basic-react-select";

const useStyles = makeStyles(theme => ({
    buttonContent: {
        marginTop: 36,
        marginBottom: 20,
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 200
    },
    fieldContent: {
        textAlign: "center",
        marginTop: 20,
        paddingLeft: 0,
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}))

type AddFilterProps = {
    open: boolean
    handleClose: () => void
    dataset: any
}

interface FormValues {
    type: string
    surveyId: string
    projectId: string
}

const initialValues: FormValues = {
    type: 'Survey',
    surveyId: '',
    projectId: '',
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Component to add filters to the dataset
 * @modified Jhoan Lopez <jhoan.lopez@ias.com.co>
 * @param open
 * @param handleClose
 * @constructor
 */
const AddFilter: React.FC<AddFilterProps> = ({open, handleClose, dataset}) => {
    const classes = useStyles()
    const {data: surveys} = useGetSurveys();
    const {mutate: addTrigger, loading} = useAddDatasetTrigger();
    const {sendNotification} = useNotifications();
    const formik: FormikProps<FormValues> = useFormik<FormValues>({
        initialValues,
        onSubmit: values => {
            if (values.type === 'Survey') delete formik.values.projectId
            addTrigger({variables: {request: {...values, datasetId: dataset.datasetId}}})
                .then(() => {
                    handleClose();
                    sendNotification('Filtro agregado correctamente');
                })
                .catch(() => {
                    sendNotification('Ocurrió un error al agregar el filtro');
                })
        },
    });
    const [surveyValue, setSurveyValue] = useState('')
    const [projectValue, setProjectValue] = useState('')
    const [enabled, setEnabled] = useState(false)
    const {data: projects} = useFindProjectsBySurveys({surveyId: formik.values.surveyId});

    React.useEffect(() => {
        //refetch({surveys: ['']})
    }, [formik.values.surveyId]);

    /*const OutsideForm = () => {
        React.useEffect(() => {
            if(formik.values.type === "Project"){
                refetch({surveys: [formik.values.surveyId]})
            }
        }, [formik.values.type]);
        return null;
    };*/

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="add-filter-dialog" fullWidth maxWidth="md"
                TransitionComponent={Transition}>
            <DialogTitle id="form-dialog-title">Agregar filtro</DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={initialValues}
                    onSubmit={formik.submitForm}>
                    {() => {
                        return (
                            <Form>
                                <Row>
                                    <Col xs={12}>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel>Tipo</InputLabel>
                                            <Select
                                                name="type"
                                                value={formik.values.type}
                                                onChange={formik.handleChange}
                                            >
                                                <MenuItem value="Survey">Encuesta</MenuItem>
                                                <MenuItem value="Project">Proyecto</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Col>
                                    <Col xs={12} className={classes.fieldContent}>
                                        <BasicReactSelect name="surveyId" isMulti={false} options={surveys}
                                                          onChange={formik.handleChange}
                                                          placeholder="Seleccionar encuesta"
                                                          keys={{label: "name", value: "id"}}
                                                          label="Encuesta"/>
                                    </Col>
                                    <Col xs={12} className={classes.fieldContent}>
                                        {formik.values.type === "Project" &&
                                            <BasicReactSelect name="projectId" isMulti={false} options={projects}
                                                              onChange={formik.handleChange}
                                                              placeholder="Seleccionar proyecto"
                                                              keys={{label: "companyName", value: "id"}}
                                                              label="Proyecto"/>}
                                    </Col>
                                </Row>
                                <Row center="xs">
                                    <Col xs>
                                        <Button onClick={handleClose} className={classes.buttonContent}>
                                            Cancelar
                                        </Button>
                                    </Col>
                                    <Col xs>
                                        <Button
                                            type="submit"
                                            color="primary"
                                            className={classes.buttonContent}
                                            disabled={loading}
                                        >
                                            Guardar
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        )
                    }}
                </Formik>
            </DialogContent>
        </Dialog>
    )
}

export default AddFilter;