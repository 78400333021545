import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-flexbox-grid';
import { bindActionCreators } from 'redux';
import CircularProgress from 'material-ui/CircularProgress';
import MenuItem from 'material-ui/MenuItem';
import RaisedButton from 'material-ui/RaisedButton';
import _ from 'lodash';
import { getRecords } from '../../GenericTablePagination/actions';
import SelectFieldFormat from '../../fields/SelectFieldFormat/SelectFieldFormat';
import { changeValueDepartments, getValuesCities } from '../actions';

class FilterDepartmentsGeneric extends Component {

    constructor(props){
        super(props);
        this._onChangeDepartments = this._onChangeDepartments.bind(this);
        this._execGetRecords = this._execGetRecords.bind(this);
    }

    _onChangeDepartments(e, index, value){
        const { changeValueDepartments, getValuesCities, extra } = this.props;
        let extraData = extra;
        changeValueDepartments(value);
        if(!_.isEqual(value, 'all')){
            getValuesCities(value);
        }
        extraData = _.set(extraData, 'city', 'all');
        this._execGetRecords(_.set(extraData, 'department', value));
    }

    _execGetRecords(extraData){
        const {
            URL,
            version,
            skip,
            limit,
            orderName,
            orderType,
            textFilter,
            body,
            textValueTag,
            textTag,
            coreName,
            getRecords
        } = this.props;
        const arrayFields = [];
        _.map(body, function(field){
             if(!_.has(field, 'actions') && _.has(field, 'filter')){
                 if(_.get(field, 'filter')){
                     arrayFields.push(_.get(field, 'field'));
                 }
             }
        });
        getRecords(URL, version, skip, limit, orderName, orderType, arrayFields, textFilter, coreName, extraData);
    }

    render() {
        const { department, departments, country } = this.props;
        if(!_.isNull(country) && !_.isUndefined(country) && !_.isEqual(country, 'all')){
            return (
                <SelectFieldFormat
                    config={{
                        floatingLabelText: "Departamento",
                        value: department,
                        autoWidth: true,
                        onChange: this._onChangeDepartments
                    }}
                >
                    <MenuItem key="department0" value="all" primaryText="Todos"/>
                    {
                        _.map(_.orderBy(departments, ['name']), (item, index) => {
                            return (
                                <MenuItem key={`${_.get(item, '_id')}_ne_${index}`} value={_.get(item, '_id')} primaryText={_.capitalize(_.get(item, 'name'))}/>
                            );
                        })
                    }
                </SelectFieldFormat>
            );
        } 
            return ( <div /> );
        
    }
}

function mapStateToProps({ filterCompany, genericTablePagination }, ownProps) {
    const tablePagination = genericTablePagination.get(_.get(ownProps, 'coreName', 'default'));
    return {
        departments: filterCompany.get("departments"),
        department: filterCompany.get("department"),
        country: filterCompany.get("country"),
        skip: tablePagination.get('skip'),
        limit: tablePagination.get('limit'),
        orderName: tablePagination.get('orderName'),
        orderType: tablePagination.get('orderType'),
        textFilter: tablePagination.get('textFilter'),
        status: _.isNull(tablePagination.get('status')) ? 'all' : tablePagination.get('status')
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getValuesCities,
        changeValueDepartments,
        getRecords
    }, dispatch);
}

FilterDepartmentsGeneric.propTypes = {
    URL: PropTypes.string.isRequired,
    version: PropTypes.number.isRequired,
    extra: PropTypes.object.isRequired,
    body: PropTypes.array.isRequired,
    coreName: PropTypes.string.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterDepartmentsGeneric);
