import { Map, List } from "immutable";
import * as Rx from "rxjs";
import get from "lodash/get";
import sortBy from "lodash/sortBy";
import _ from "lodash";
import forEach from "lodash/forEach";
import set from "lodash/set";
import isNil from "lodash/isNil";
import size from "lodash/size";
import { reset } from "redux-form";
import { axiosApiV2, axiosAPI } from "../../middleware/api";

// actions
export const CLEAN_STATE = "nss/excel-generator-survey-results/CLEAN_STATE"; // CLEAN STATUS
export const LOAD_SURVEYS = "nss/excel-generator-survey-results/LOAD_SURVEYS";
export const LOADING_SURVEYS =
  "nss/excel-generator-survey-results/LOADING_SURVEYS";
export const LOAD_SURVEYS_FULFILLED =
  "nss/excel-generator-survey-results/LOAD_SURVEYS_FULFILLED";
export const LOAD_SURVEYS_REJECTED =
  "nss/excel-generator-survey-results/LOAD_SURVEYS_REJECTED";
export const SELECT_SURVEY = "nss/excel-generator-survey-results/SELECT_SURVEY";
export const SELECT_SURVEY_INDEX =
  "nss/excel-generator-survey-results/SELECT_SURVEY_INDEX";

export const LOAD_COMPANIES =
  "nss/excel-generator-survey-results/LOAD_COMPANIES";
export const LOADING_COMPANIES =
  "nss/excel-generator-survey-results/LOADING_COMPANIES";
export const LOAD_COMPANIES_FULFILLED =
  "nss/excel-generator-survey-results/LOAD_COMPANIES_FULFILLED";
export const LOAD_COMPANIES_REJECTED =
  "nss/excel-generator-survey-results/LOAD_COMPANIES_REJECTED";
export const SELECT_COMPANIES =
  "nss/excel-generator-survey-results/SELECT_COMPANIES";
export const TOGGLE_COMPANIES_INDEX =
  "nss/excel-generator-survey-results/TOGGLE_COMPANIES_INDEX";

export const LOAD_PROJECTS = "nss/excel-generator-survey-results/LOAD_PROJECTS";
export const LOADING_PROJECTS =
  "nss/excel-generator-survey-results/LOADING_PROJECTS";
export const LOAD_PROJECTS_FULFILLED =
  "nss/excel-generator-survey-results/LOAD_PROJECTS_FULFILLED";
export const LOAD_PROJECTS_REJECTED =
  "nss/excel-generator-survey-results/LOAD_PROJECTS_REJECTED";
export const SELECT_PROJECTS_INDEX =
  "nss/excel-generator-survey-results/SELECT_PROJECTS_INDEX";

export const SUBMIT_FORM_DETAILS =
  "nss/excel-generator-survey-results/SUBMIT_FORM_DETAILS";
export const SUBMIT_JOB_CLEAN =
  "nss/excel-generator-survey-results/SUBMIT_JOB_CLEAN";
export const SUBMIT_JOB_IN_PROGRESS =
  "nss/excel-generator-survey-results/SUBMIT_JOB_IN_PROGRESS";
export const SUBMIT_JOB_FULFILLED =
  "nss/excel-generator-survey-results/SUBMIT_JOB_FULFILLED";
export const SUBMIT_JOB_REJECTED =
  "nss/excel-generator-survey-results/SUBMIT_JOB_REJECTED";
export const SUBMIT_JOB_REQUESTED =
  "nss/excel-generator-survey-results/SUBMIT_JOB_REQUESTED";

export const RESET_GENERATOR =
  "nss/excel-generator-survey-results/RESET_GENERATOR";
export const RESET_GENERATOR_REQUEST =
  "nss/excel-generator-survey-results/RESET_GENERATOR_REQUEST";

function calculateToggleNextState(currentState = Map(), elementsToToggle = []) {
  return currentState.withMutations((map) => {
    forEach(elementsToToggle, (elementToToggle) => {
      if (map.has(elementToToggle)) {
        const currentValue = map.get(elementToToggle);
        const currentState = get(currentValue, "selected", false);
        const nextValue = set(currentValue, "selected", !currentState);
        map.set(elementToToggle, nextValue);
      }
    });
  });
}

export function getAll(
  allValues = List(),
  currentSelected = Map(),
  state = false
) {
  return allValues
    .map((item, originalIdx) => [item, originalIdx])
    .filterNot((item) => {
      const valueInMap = currentSelected.get(item[0]._id);
      const selected = _.get(valueInMap, "selected", false);
      return selected == state;
    })
    .map((item) => item[1]);
}

export function getItemsId(row = [], list = List()) {
  return _.chain(row)
    .map((index) => list.get(index))
    .filter((item) => !_.isNil(item))
    .map("_id")
    .value();
}

// reducer
const initialState = Map({
  surveys: List(),
  selectedSurvey: -1, // index
  selectedSurveyObj: {}, // index
  companies: List(),
  selectedCompanies: Map(),
  projects: List(),
  selectedProjects: Map(),
  status: CLEAN_STATE,
  err: undefined,
  email: "Sin asignar",
  type: [false, false],
  filename: "Sin asignar",
  jobStatus: SUBMIT_JOB_CLEAN,
  jobDetails: {},
  statusSurvey: CLEAN_STATE,
  statusCompanies: CLEAN_STATE,
  statusProjects: CLEAN_STATE,
});

export default function (state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_SURVEYS_FULFILLED:
      return state.withMutations((map) => {
        map
          .set("surveys", List(sortBy(action.payload.data, "name")))
          .set("statusSurveys", action.type);
      });
    case LOAD_COMPANIES_FULFILLED:
      return state.withMutations((map) => {
        const companiesList = List(sortBy(action.payload.data, "businessName"));
        const companiesListIndex = Map(
          companiesList.map((company) => [
            company._id,
            _.assign({}, company, { selected: false }),
          ])
        );
        map
          .set("statusCompanies", action.type)
          .set("companies", companiesList)
          .set("availableCompanies", List(action.payload.data))
          .set("selectedCompanies", companiesListIndex);
      });
    case LOAD_PROJECTS_FULFILLED:
      const projectsId = List(sortBy(action.payload.data, "name"));
      const projectsListIndex = Map(
        projectsId.map((project) => [
          project._id,
          _.assign({}, project, { selected: false }),
        ])
      );
      return state.withMutations((map) => {
        map
          .set("statusProjects", action.type)
          .set("selectedProjects", projectsListIndex)
          .set("projects", projectsId)
          .set("availableProjects", projectsId);
      });
    case SELECT_SURVEY:
      return state.withMutations((map) => {
        map
          .set("selectedSurvey", action.payload.index)
          .set(
            "selectedSurveyObj",
            state.get("surveys").get(action.payload.index)
          )
          .set("selectedProjectsIndexes", state.get("companies"))
          .set("selectedCompaniesIndexes", state.get("projects"))
          .set("selectedProjects", Map())
          .set("selectedCompanies", List());
      });
    case SELECT_COMPANIES:
      return state.set("selectedCompanies", action.payload);
    case SELECT_PROJECTS_INDEX:
      return state.set(
        "selectedProjects",
        calculateToggleNextState(
          state.get("selectedProjects"),
          action.payload.projects
        )
      );
    case LOAD_SURVEYS_REJECTED:
      return state.set("statusSurvey", action.type);
    case LOAD_COMPANIES_REJECTED:
      return state.set("statusCompanies", action.type);
    case LOAD_PROJECTS_REJECTED:
      return state.withMutations((map) => {
        map.set("statusProject", action.type).set("err", action.payload.err);
      });
    case LOADING_SURVEYS:
      return state.set("statusSurveys", action.type);
    case LOADING_COMPANIES:
      return state.set("statusCompanies", action.type);
    case LOADING_PROJECTS:
      return state.set("statusProjects", action.type);
    case SUBMIT_FORM_DETAILS:
      return state.withMutations((map) => {
        map
          .set("email", action.payload.data.email)
          .set("type", action.payload.data.type)
          .set("filename", action.payload.data.filename);
      });
    case SUBMIT_JOB_CLEAN:
    case SUBMIT_JOB_IN_PROGRESS:
    case SUBMIT_JOB_FULFILLED:
    case SUBMIT_JOB_REJECTED:
    case SUBMIT_JOB_REQUESTED:
      return state.set("jobStatus", action.type);
    case RESET_GENERATOR:
      return initialState;
    default:
      return state;
  }
}

// action creators
export const loadSurveys = () => ({ type: LOAD_SURVEYS });
export const loadCompanies = (survey) => ({
  type: LOAD_COMPANIES,
  payload: { survey },
});
export const loadProjects = (companies, survey) => ({
  type: LOAD_PROJECTS,
  payload: { companies, survey },
});

export const selectSurvey = (index, survey) => ({
  type: SELECT_SURVEY_INDEX,
  payload: { index, survey },
});
export const selectCompanies = (companies) => ({
  type: TOGGLE_COMPANIES_INDEX,
  payload: { companies },
});
export const selectProjects = (projects) => ({
  type: SELECT_PROJECTS_INDEX,
  payload: { projects },
});

export const httpRequestFulfilled = (action) => (data) => ({
  type: action,
  payload: { data },
});
export const httpRequestRejected = (action, err) => ({
  type: action,
  payload: { err },
});
export const httpRequestLoading = (action) => ({ type: action });

export const submitFormDetails = (data) => ({
  type: SUBMIT_FORM_DETAILS,
  payload: { data },
});
export const submitJob = (data) => ({
  type: SUBMIT_JOB_REQUESTED,
  payload: { data },
});

export const resetGenerator = () => ({ type: RESET_GENERATOR_REQUEST });

export const mapJobStatusToLabel = (status) => {
  switch (status) {
    case SUBMIT_JOB_CLEAN:
      return "Confirmar y enviar";
    case SUBMIT_JOB_IN_PROGRESS:
      return "Recolectando información";
    case SUBMIT_JOB_FULFILLED:
      return "Solicitud Enviada con exito";
    case SUBMIT_JOB_REJECTED:
      return "Error al enviar solicitud.";
    case SUBMIT_JOB_REQUESTED:
      return "Recolectando información";
    default:
      return "";
  }
};

// Epics
export const resetGeneratorEpic$ = (action$) =>
  action$.ofType(RESET_GENERATOR_REQUEST).flatMap((action) => {
    return Rx.Observable.of(
      { type: RESET_GENERATOR },
      reset("excel-generator-form-details")
    );
  });

export const selectSurveyEpic = (action$) => {
  return action$
    .ofType(SELECT_SURVEY_INDEX)
    .filter((action) => {
      return (
        get(action, "payload.survey") !== undefined &&
        get(action, "payload.index") !== undefined
      );
    })
    .mergeMap((action) => {
      return Rx.Observable.of(
        {
          type: SELECT_SURVEY,
          payload: action.payload,
        },
        loadCompanies(action.payload.survey._id)
      );
    });
};

export const selectCompaniesEpic = (action$, store) => {
  return action$
    .ofType(TOGGLE_COMPANIES_INDEX)
    .map((action) => {
      const { surveysExcelGenerator } = store.getState();
      const selectedCompanies = surveysExcelGenerator.get("selectedCompanies");
      const { companies } = action.payload;
      return {
        action,
        map: calculateToggleNextState(selectedCompanies, companies),
      };
    })
    .mergeMap((actionMap) => {
      const { surveysExcelGenerator } = store.getState();
      const selectedSurveyObj = surveysExcelGenerator.get("selectedSurveyObj");
      const companiesId = actionMap.map
        .toArray()
        .filter((company) => company.selected)
        .map((company) => company._id);

      return Rx.Observable.of(
        {
          type: SELECT_COMPANIES,
          payload: actionMap.map,
        },
        size(companiesId) > 0
          ? loadProjects(companiesId, selectedSurveyObj._id)
          : null
      ).filter((element) => !isNil(element));
    });
};

export const loadSurveysEpic = (action$) =>
  action$.ofType(LOAD_SURVEYS).mergeMap(() => {
    const httpPromise = axiosAPI.get("/surveys?code=all");
    const promiseObs$ = Rx.Observable.fromPromise(httpPromise)
      .map((response) => get(response, "data.data", []))
      .map(httpRequestFulfilled(LOAD_SURVEYS_FULFILLED))
      .catch((error) =>
        Rx.Observable.of(
          httpRequestRejected(
            LOAD_SURVEYS_REJECTED,
            get(error, "response.data", error)
          )
        )
      );

    return Rx.Observable.of(httpRequestLoading(LOADING_SURVEYS)).concat(
      promiseObs$
    );
  });

export const loadCompaniesEpic = (action$) =>
  action$
    .ofType(LOAD_COMPANIES)
    .debounce(() => Rx.Observable.interval(1000))
    .mergeMap((action) => {
      const httpPromise = axiosApiV2.get(
        `/surveys/${_.get(action, "payload.survey")}/companies`
      );
      const promiseObs$ = Rx.Observable.fromPromise(httpPromise)
        .map((response) => response.data)
        .map(httpRequestFulfilled(LOAD_COMPANIES_FULFILLED))
        .catch((error) =>
          Rx.Observable.of(
            httpRequestRejected(
              LOAD_COMPANIES_REJECTED,
              get(error, "response.data", error)
            )
          )
        );
      return Rx.Observable.of(httpRequestLoading(LOADING_COMPANIES)).concat(
        promiseObs$
      );
    });

export const loadProjectsEpic = (action$) =>
  action$
    .ofType(LOAD_PROJECTS)
    .debounce(() => Rx.Observable.interval(1000))
    .mergeMap((action) => {
      const httpPromise = axiosApiV2.get(
        `/surveys/${_.get(action, "payload.survey")}/projects`,
        {
          params: {
            company: action.payload.companies,
          },
        }
      );
      const promiseObs$ = Rx.Observable.fromPromise(httpPromise)
        .map((response) => response.data)
        .map(httpRequestFulfilled(LOAD_PROJECTS_FULFILLED))
        .catch((error) =>
          Rx.Observable.of(
            httpRequestRejected(
              LOAD_PROJECTS_REJECTED,
              get(error, "response.data", error)
            )
          )
        );
      return Rx.Observable.of(httpRequestLoading(LOADING_PROJECTS)).concat(
        promiseObs$
      );
    });

export const saveJobEpic = (action$) =>
  action$.ofType(SUBMIT_JOB_REQUESTED).mergeMap((action) => {
    const httpPromise = axiosApiV2.post("/surveys/projects/results", {
      data: action.payload.data,
    });
    const promiseObs$ = Rx.Observable.fromPromise(httpPromise)
      .map((response) => response.data)
      .map(httpRequestFulfilled(SUBMIT_JOB_FULFILLED))
      .catch((error) =>
        Rx.Observable.of(
          httpRequestRejected(SUBMIT_JOB_REJECTED, get(error, "response.data"))
        )
      );
    return Rx.Observable.of(httpRequestLoading(SUBMIT_JOB_IN_PROGRESS))
      .concat(promiseObs$)
      .concat(Rx.Observable.of({ type: SUBMIT_JOB_CLEAN }).delay(3000));
  });
