import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Row, Col } from "react-flexbox-grid";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { blue700 } from "material-ui/styles/colors";
import TextField from "material-ui/TextField";
import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";
import RaisedButton from "material-ui/RaisedButton";
import Paper from "material-ui/Paper";
import MenuItem from "material-ui/MenuItem";
import { toggleSnackbar } from "../../actions/commons";
import { getSurveyTreeGroupers } from "../groupersConfiguration/actions";
import { FetchSurveyParam } from "../../actions/info/surveys_info_form";
import { saveGroupGroupers } from "./actions";
import { LBLSURVEYS } from "Labels";
import ButtonBack from "../fields/buttonBack/buttonBack";
import AddGroupersTree from "./TreeAllGrouperSurvey";
import AuthComponent from "../AuthComponent";
import SelectFieldFormat from "../fields/SelectFieldFormat/SelectFieldFormat";

const stylePaper = {
  marginTop: 20,
  marginBottom: 50,
  paddingTop: 0,
  paddingBottom: 40,
};

class TreeGroupersGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      groupersgroup: [],
    };

    this._saveGroupGroupers = this._saveGroupGroupers.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { FetchSurveyParam, params } = this.props;
    FetchSurveyParam(params.survey);
  }

  _saveGroupGroupers(formData) {
    const { groupers, toggleSnackbar, saveGroupGroupers, params } = this.props;
    saveGroupGroupers(formData, groupers, _.get(params, "survey"))
      .then(
        data => {
          if (_.get(data, "error") || _.get(data, "payload.status") !== 200) {
            toggleSnackbar(true, LBLSURVEYS.msg.errorSaveGroupGroup);
          } else {
            this.props.history.push(`/admin/surveys/groupers/${params.survey}`);
            toggleSnackbar(true, LBLSURVEYS.msg.successSaveGroupGroup);
          }
        },
        reason => {
          toggleSnackbar(true, LBLSURVEYS.msg.errorSaveGroupGroup);
        }
      )
      .catch(data => {
        toggleSnackbar(true, LBLSURVEYS.msg.errorSaveGroupGroup);
      });
  }

  render() {
    const {
      fields: { name, visible, priority },
      groupers,
      surveyName,
      locked,
      params,
      arrayPriority,
      handleSubmit,
    } = this.props;
    return (
      <Row>
        <AuthComponent
          component={
            <Col xs={12} mdOffset={2} md={8}>
              <Paper style={stylePaper}>
                <Row>
                  <Col xs={12}>
                    <div
                      style={{
                        width: "100%",
                        background: blue700,
                        paddingTop: 5,
                        paddingBottom: 5,
                        color: "#FFF",
                      }}
                    >
                      <h1 style={{ textAlign: "center", fontWeight: 400 }}>
                        {LBLSURVEYS.metrical.titleConfigGrouperGroups}
                      </h1>
                      <h2 style={{ textAlign: "center", fontWeight: 400 }}>
                        {surveyName}
                      </h2>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} mdOffset={1} md={10}>
                    <AddGroupersTree idSurvey={params.survey} locked={locked} />
                    <form onSubmit={handleSubmit(this._saveGroupGroupers)}>
                      <Row
                        style={{ display: this.state.open ? "none" : "flex" }}
                      >
                        <Col
                          xs={10}
                          xsOffset={1}
                          style={{ marginBottom: 5, marginTop: 20 }}
                        >
                          <TextField
                            hintText={LBLSURVEYS.metrical.fields.name}
                            floatingLabelText={
                              LBLSURVEYS.metrical.fields.titleName
                            }
                            type="text"
                            fullWidth
                            errorText={
                              name.touched && name.error ? name.error : ""
                            }
                            {...name}
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ display: this.state.open ? "none" : "flex" }}
                      >
                        <Col xs={10} xsOffset={1}>
                          <h3 style={{ fontWeight: 200 }}>
                            {LBLSURVEYS.metrical.fields.titleVisible}
                          </h3>
                          <RadioButtonGroup
                            name="type"
                            onChange={(e, value) => visible.onChange(value)}
                            defaultSelected="false"
                            disabled={locked}
                            valueSelected={visible.value}
                            style={{ marginTop: 15 }}
                          >
                            <RadioButton
                              value="false"
                              label={LBLSURVEYS.metrical.buttons.no}
                              style={{
                                marginBottom: 16,
                                display: "inline-block",
                                width: 130,
                              }}
                            />
                            <RadioButton
                              value="true"
                              label={LBLSURVEYS.metrical.buttons.yes}
                              style={{
                                marginBottom: 16,
                                display: "inline-block",
                                width: 130,
                              }}
                            />
                          </RadioButtonGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={10} xsOffset={1}>
                          <h3 style={{ fontWeight: 200 }}>
                            Priorizaciòn del agrupador
                          </h3>
                          <SelectFieldFormat
                            config={{
                              floatingLabelText: "Priorización",
                              value: priority.value,
                              errorText:
                                priority.touched && priority.error
                                  ? priority.error
                                  : "",
                              onChange: (e, index, value) =>
                                priority.onChange(value),
                            }}
                          >
                            {_.map(arrayPriority, (priority, index) => (
                              <MenuItem
                                key={`priority-${index}`}
                                value={priority}
                                primaryText={priority}
                              />
                            ))}
                          </SelectFieldFormat>
                        </Col>
                      </Row>
                      <Row
                        style={{
                          marginTop: 30,
                          display: this.state.open ? "none" : "flex",
                        }}
                      >
                        <Col xs style={{ textAlign: "center" }}>
                          <AuthComponent
                            component={
                              <RaisedButton
                                label={
                                  LBLSURVEYS.metrical.buttons
                                    .saveGrouperQuestion
                                }
                                type="submit"
                                secondary
                                // disabled={_.size(groupers) === 0 || locked}
                                style={{ margin: "0 auto" }}
                              />
                            }
                            permission="backend/surveyGroupers:read"
                            type="component"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs />
                      </Row>
                    </form>
                  </Col>
                </Row>
                <Row>
                  <Col xs>
                    <ButtonBack
                      url={`/admin/surveys/groupers/${this.props.params.survey}`}
                    />
                  </Col>
                </Row>
              </Paper>
            </Col>
          }
          permission="backend/surveyGroupers:write"
          type="url"
        />
      </Row>
    );
  }
}

const validate = values => {
  const errors = {};
  if (!values.name) {
    errors.name = LBLSURVEYS.metrical.msg.nameRequired;
  }
  return errors;
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      toggleSnackbar,
      getSurveyTreeGroupers,
      FetchSurveyParam,
      saveGroupGroupers,
    },
    dispatch
  );
}

function mapStateToProps({ surveys, groupersAggregate }, ownProps) {
  const arrayPriority = [];
  for (let i = 1; i <= 100; i++) {
    arrayPriority.push(i);
  }
  return {
    locked: _.get(surveys, "all.data.locked", true),
    surveyName: _.get(surveys, "all.data.name"),
    groupers: groupersAggregate.get("groupers"),
    arrayPriority,
    initialValues: {
      name: "",
      visible: "false",
      priority: 1,
      typeEvaluation: "summatory",
    },
  };
}

export default reduxForm(
  {
    form: "TreeGroupersGroupForm",
    fields: ["name", "visible", "priority", "typeEvaluation"],
    validate,
  },
  mapStateToProps,
  mapDispatchToProps
)(TreeGroupersGroup);
