import PropTypes from "prop-types";
import React from "react";
import RichTextEditor from "react-rte";
import { red500 } from "material-ui/styles/colors";
import _ from "lodash";

const styleError = {
  position: "relative",
  top: 5,
  fontSize: 12,
  lineHeight: 2,
  color: red500,
  transition: "all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
};

function parseError(errorText) {
  return _.isNil(errorText) ? "" : errorText;
}

const RichText = ({ errorText, field }) => {
  const [value, setValue] = React.useState(()=>{
    return RichTextEditor.createEmptyValue();
  });

  React.useEffect(()=>{
    if(field.initialValue){
      const parseValue = RichTextEditor.createValueFromString(field.initialValue, "html");
      setValue(parseValue);
      }
      return ()=>{
        setValue(RichTextEditor.createEmptyValue());
      }
  },[field.initialValue])

 const errTxt = parseError(errorText);
 const handleChange = (value) => {
   const htmlString = value.toString("html");
   setValue(value);
   field.onChange(htmlString);
 };

  return (
    <div>
      <RichTextEditor value={value} onChange={handleChange} />
      <div style={styleError}>{errTxt}</div>
    </div>
  );
};

RichText.propTypes = {
  field: PropTypes.object.isRequired,
  errorText: PropTypes.string.isRequired,
  valueInitial: PropTypes.string,
};

export default RichText;
