import { axiosAPI } from "Api";
import _ from "lodash";
import { useQuery, useMutation, useQueryCache } from "react-query";
import { isBlank } from "Utils";

export async function checkQuestionId(id) {
    const response = await axiosAPI.get(`/questions/id`, {
        params: { fieldName: id },
    });
    const questionsExists = _.get(response, "data.data", false);
    return questionsExists;
}

export async function getValueSources() {
    const response = await axiosAPI.get("/listmaster/names");
    return _.get(response, "data.data", []);
}

export async function getValuesNameList(key, { listName }) {
    if (isBlank(listName)) {
        return [];
    }
    const response = await axiosAPI.get(`/list/${listName}`);
    return _.get(response, "data.data", []);
}

export function useListNameValues(listName) {
    return useQuery([`list-${listName}`, { listName }], getValuesNameList);
}

function getQuestionProps(props) {
    let objeto = {
        fieldName: _.get(props, "fieldName"),
        type: _.get(props, "type"),
        title: _.get(props, "title"),
        size: _.get(props, "size") ? _.get(props, "size") : "middle",
        itemList: _.get(props, "itemList") ? _.get(props, "itemList") : "none",
        help: _.get(props, "help") ? _.get(props, "help") : "",
        defaultValue: props.defaultValue,
    };
    if (_.get(props, "_id")) {
        objeto = _.set(objeto, "_id", _.get(props, "_id"));
    }
    if (
        (_.get(props, "valueSource") &&
            (_.get(props, "type") === "ListOpen" ||
                _.get(props, "type") === "ListClose")) ||
        _.get(props, "type") === "ListSelected"
    ) {
        objeto = _.set(objeto, "valueSource", {
            list: {
                name: _.get(props, "valueSource.nameList"),
            },
        });
    }
    if (_.get(props, "type") === "ListOpen") {
        objeto = _.chain(objeto)
            .set("quantitySelectable", _.get(props, "quantitySelectable"))
            .set("otherWhich", _.get(props, "otherWhich"))
            .value();
    } else {
        objeto = _.chain(objeto)
            .set("quantitySelectable", 0)
            .set("otherWhich", false)
            .value();
    }
    if (_.get(props, "type") === "Group") {
        objeto = _.set(objeto, "valueSource", {
            group: {
                name: _.get(props, "list"),
            },
        });
    }
    if (_.get(props, "type") === "GroupClose") {
        objeto = _.set(objeto, "valueSource", {
            groupClose: {
                name: _.get(props, "listClose").map((value, index) => {
                    return _.set(value, "id", index + 1);
                }),
            },
        });
    }
    if (_.get(props, "type") === "GroupList") {
        objeto = _.set(objeto, "valueSource", {
            list: {
                name: !_.isUndefined(_.get(props, "valueSource.nameList"))
                    ? _.get(props, "valueSource.nameList")
                    : _.get(props, "valueSource"),
            },
            groupList: {
                name: _.get(props, "listClose").map((value, index) => {
                    return _.set(value, "id", index + 1);
                }),
            },
        });
    }
    return { question: objeto };
}

function submitQuestion({ editMode, ...questionValues }) {
    const questionProps = getQuestionProps(questionValues);
    if (editMode) {
        return axiosAPI.put("/questions", questionProps);
    }
    return axiosAPI.post("/questions", questionProps);
}

export function useQuestionMutation(editMode) {
    const queryCache = useQueryCache();
    return useMutation(submitQuestion, {
        onSuccess: (data, mutationVariables) => {
            if (editMode) {
                queryCache.invalidateQueries(
                    `question-${mutationVariables._id}`
                );
            }
        },
    });
}

async function queryQuestion(query, { id }) {
    const response = await axiosAPI.get(`/questions/${id}`);
    const question = response.data.data;
    const listName = _.get(question, "valueSource.list.name");
    let valueSource = {};
    if (listName) {
        const sourceResponse = await axiosAPI.get(`/listmaster/${listName}`);
        valueSource = _.get(sourceResponse, "data.data.0", {});
    }
    return {
        ...question,
        valueSource,
    };
}

export function useQuestion(id) {
    return useQuery([`question-${id}`, { id }], queryQuestion);
}
