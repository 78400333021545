import { axiosAPI } from '../../middleware/api';

export const GET_REFEREES_GRID = "GET_REFEREES_GRID";

export function FetchRefereesCompany() {

    const request = axiosAPI.get(`/referees`);

    return {
        type: GET_REFEREES_GRID,
        payload: request
    }
}
