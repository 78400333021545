import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn} from 'material-ui/Table';
import _ from 'lodash';
import Checkbox from 'material-ui/Checkbox';
import loadingData from './loadingData';
import {
    selectProjects,
    LOADING_PROJECTS,
    LOAD_PROJECTS_FULFILLED,
    LOAD_PROJECTS_REJECTED,
    getAll,
    getItemsId,
} from './excelGeneratorDucks';

class ProjectsTable extends Component {

    state = {
        selectedAll: false
    };

    renderSurveyRow = (project, index) => {
        const {selectProjects, selectedProjects, projects} = this.props;
        const id = project._id;
        const currentRowSelected = _.get(selectedProjects.get(id), 'selected', false);
        return (
            <TableRow key={id} hovered={false} striped={false} selectable={false}>
                <TableRowColumn style={{width: 15}}>
                    <Checkbox
                        onCheck={() => {
                            this.setState({selectedAll: false});
                            const selectedItems = getItemsId([index], projects);
                            selectProjects(selectedItems);
                        }}
                        checked={currentRowSelected}
                    />
                </TableRowColumn>
                <TableRowColumn style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'justify' }}>{project.name}</TableRowColumn>
            </TableRow>
        );
    };

    selectAll = (e, checked) => {
        const {projects, selectProjects, selectedProjects} = this.props;
        this.setState({selectedAll: checked});
        const row = getAll(projects, selectedProjects, checked);
        const selectedItems = getItemsId(row.toArray(), projects);
        selectProjects(selectedItems);
    };

    render() {
        const {projects, status} = this.props;
        const projectsComponent = (
            <Table >
                <TableHeader enableSelectAll={false} displaySelectAll={false} adjustForCheckbox={false}>
                    <TableRow>
                        <TableHeaderColumn style={{width: 15}}>
                            <Checkbox
                                onCheck={this.selectAll}
                                checked={this.state.selectedAll}
                            />
                        </TableHeaderColumn>
                        <TableHeaderColumn>Nombre</TableHeaderColumn>
                    </TableRow>
                </TableHeader>
                <TableBody deselectOnClickaway={false} displayRowCheckbox={false}>
                    {projects.map(this.renderSurveyRow)}
                </TableBody>
            </Table>
        );
        return loadingData(LOADING_PROJECTS, LOAD_PROJECTS_REJECTED, LOAD_PROJECTS_FULFILLED, status, 'proyectos', projectsComponent);
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({selectProjects}, dispatch);
}

function mapStateToProps({surveysExcelGenerator}) {
    return {
        projects: surveysExcelGenerator.get('projects'),
        selectedProjects: surveysExcelGenerator.get('selectedProjects'),
        status: surveysExcelGenerator.get('statusProjects'),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsTable);
