import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { AuthProvider } from "../auth/context";
import SignInForm from "../auth/components/index";
import ForgotPassword from "../auth/components/forgotPassword";
import PrivateRoute from "./private-route";
import Routes from "./routes";

function AuthRouter() {
  return (
    <AuthProvider>
      <Router>
        <Switch>
          <Redirect exact from="/" to="/login" />
          <Redirect
            exact
            from="/dashboard/referee"
            to="/dashboard/referee/projects"
          />
          <Redirect
            exact
            from="/dashboard/employee"
            to="/dashboard/employee/projects"
          />
          <Redirect
            exact
            from="/dashboard/company"
            to="/dashboard/company/projects"
          />
          <Redirect exact from="/dashboard/arl" to="/dashboard/arl/companies" />

          <Route exact path="/login">
            <SignInForm />
          </Route>
          <Route exact path="/forgot">
            <ForgotPassword />
          </Route>
          <PrivateRoute path="*">
            <Routes />
          </PrivateRoute>
        </Switch>
      </Router>
    </AuthProvider>
  );
}

export default AuthRouter;
