import {IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Typography} from '@material-ui/core';
import * as React from 'react'
import DeleteIcon from '@material-ui/icons/Delete'
import {Col, Row} from "react-flexbox-grid";
import {makeStyles} from "@material-ui/core/styles";
import {useNotifications} from "Notifications";
import {useState} from "react";
import AlertDialog from "../../../../commons/alert-dialog";
import {useRemoveDatasetTrigger} from "../../../data";

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 12
    },
    deleteButton: {
        marginLeft: 12
    }
}));

type SourcesListProps = {
    dataset: any
}

/**
 * Component to render filters
 * @author Jhoan Lopez <jhoan.lopez@ias.com.co>
 * @param trigger
 * @param dataset
 * @param key
 * @constructor
 */
const FiltersList: React.FC<SourcesListProps> = ({dataset}) => {
    const classes = useStyles();
    const [openDelete, setOpenDelete] = React.useState(false);
    const [triggerProcess, setTriggerProcess] = useState(null)
    const {mutate, loading} = useRemoveDatasetTrigger();
    const {sendNotification} = useNotifications();

    const handleClickOpen = (trigger) => {
        setTriggerProcess(trigger)
        setOpenDelete(true);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    const handleRemoveDatasetTrigger = async () => {
        await mutate({
            variables: {request: {datasetId: dataset.datasetId, triggerId: triggerProcess?.triggerId}}
        })
            .then(() => {
                handleCloseDelete()
                sendNotification('Filtro eliminado correctamente');
            })
            .catch(() => {
                sendNotification('Ocurrió un error al eliminar el filtro');
            })
    }

    return (
        <Row className={classes.root}>
            <Col xs>
                <Row center="xs">
                    {dataset?.triggers.length === 0 ?
                        <Typography>No se han agregado filtros</Typography> :
                        <div>
                            <Typography>Filtros</Typography>
                            <List dense>
                                {dataset?.triggers.map((trigger, key) => (
                                    <ListItem key={`${key}-${trigger.triggerId}`}>
                                        <ListItemText
                                            secondary={trigger.triggerId}
                                        />
                                        <ListItemSecondaryAction className={classes.deleteButton}>
                                            <IconButton edge="end" aria-label="delete" onClick={() => handleClickOpen(trigger)}
                                                        disableRipple disableTouchRipple disableFocusRipple
                                                        size="small">
                                                <DeleteIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))
                                }
                            </List>
                        </div>
                    }
                </Row>
            </Col>
            <AlertDialog title="¿Está seguro de eliminar el filtro?" description="" open={openDelete}
                         handleClose={handleCloseDelete} handleAccept={handleRemoveDatasetTrigger}
                         disabled={loading}/>
        </Row>
    )
}

export default FiltersList