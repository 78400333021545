import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Row, Col } from "react-flexbox-grid";
import _ from "lodash";
import RaisedButton from "material-ui/RaisedButton";
import DatePickerFormat from "../../../fields/DatePickerFormat/DatePickerFormat";
import GenericTablePagination from "../../../GenericTablePagination/genericTablePagination";
import {
    getRecords,
    cleanTable,
    setInSearchRecords,
    resetOrderRecord,
} from "../../../GenericTablePagination/actions";

const body = [
    {
        field: "company.businessName",
        title: "Empresa",
        colspan: 2,
        order: false,
    },
    {
        field: "name",
        title: "Proyecto",
        filter: true,
        colspan: 2,
    },
    {
        field: "time.openDate",
        title: "Fecha inicio",
    },
    {
        field: "time.closeDate",
        title: "Fecha final",
    },
];

class ListProjects extends Component {
    constructor(props) {
        super(props);
        this.getProjectsForCompanies = this.getProjectsForCompanies.bind(this);
        this.renderDates = this.renderDates.bind(this);
        this.changeVisibleItems = this.changeVisibleItems.bind(this);
        this.onChangeOpenDate = this.onChangeOpenDate.bind(this);
        this.onChangeCloseDate = this.onChangeCloseDate.bind(this);
        this.cleanDatesFilter = this.cleanDatesFilter.bind(this);
        this.state = {
            visible: true,
            closeDate: null,
            openDate: null,
            order: 1,
            fieldOrder: null,
        };
    }

    onChangeCloseDate(value) {
        this.setState({ closeDate: value });
    }

    onChangeOpenDate(value) {
        this.setState({ openDate: value });
    }

    getProjectsForCompanies() {
        const {
            selected,
            getRecords,
            limit,
            skip,
            total,
            orderName,
            orderType,
            textFilter,
            arrayFields,
            cleanTable,
            setInSearchRecords,
            resetOrderRecord,
        } = this.props;
        let extraData = { arrayCompanies: selected };
        if (!_.isNull(this.state.openDate) && !_.isNull(this.state.closeDate)) {
            extraData = _.set(extraData, "openDate", this.state.openDate);
            extraData = _.set(extraData, "closeDate", this.state.closeDate);
        }
        cleanTable("projectsARL");
        resetOrderRecord("projectsARL");
        setInSearchRecords("projectsARL");
        getRecords(
            "/arl/companies/projects",
            1,
            skip,
            limit,
            orderName,
            orderType,
            arrayFields,
            textFilter,
            "projectsARL",
            extraData
        );
    }

    changeVisibleItems() {
        this.setState({ visible: !this.state.visible });
    }

    cleanDatesFilter() {
        this.setState({
            closeDate: null,
            openDate: null,
        });
    }

    renderDates() {
        return (
            // eslint-disable-next-line
            <div>
                <Row
                    style={{
                        paddingLeft: 30,
                        display: this.state.visible ? "flex" : "none",
                    }}
                >
                    <Col xs>
                        <div
                            style={{
                                display: "inline-block",
                                padding: "0 10px",
                            }}
                        >
                            <DatePickerFormat
                                datePickerConfig={{
                                    floatingLabelText: "Fecha Inicial",
                                    hintText: "Fecha Inicial",
                                    mode: "landscape",
                                    value: this.state.openDate,
                                    onChange: (e, value) =>
                                        this.onChangeOpenDate(value),
                                    style: { marginTop: 24, marginBottom: 23 },
                                }}
                                stylefloatingLabelText={{
                                    marginTop: 33,
                                    marginBottom: -33,
                                }}
                            />
                        </div>
                        <div
                            style={{
                                display: "inline-block",
                                padding: "0 10px",
                            }}
                        >
                            <DatePickerFormat
                                datePickerConfig={{
                                    floatingLabelText: "Fecha Final",
                                    hintText: "Fecha Final",
                                    mode: "landscape",
                                    value: this.state.closeDate,
                                    onChange: (e, value) =>
                                        this.onChangeCloseDate(value),
                                    style: { marginTop: 24, marginBottom: 23 },
                                }}
                                stylefloatingLabelText={{
                                    marginTop: 33,
                                    marginBottom: -33,
                                }}
                            />
                        </div>
                    </Col>
                </Row>
                <Row
                    style={{
                        paddingLeft: 40,
                        display: this.state.visible ? "flex" : "none",
                    }}
                >
                    <Col>
                        <RaisedButton
                            label="Limpiar fechas"
                            secondary
                            disabled={
                                _.isNull(this.state.closeDate) &&
                                _.isNull(this.state.openDate)
                            }
                            style={{
                                margin: "20px auto",
                                display: "inline-block",
                            }}
                            onClick={this.cleanDatesFilter}
                        />
                    </Col>
                    <Col>
                        <RaisedButton
                            label="Aplicar filtro"
                            secondary
                            disabled={
                                _.isNull(this.state.closeDate) ||
                                _.isNull(this.state.openDate)
                            }
                            style={{ margin: "20px", display: "inline-block" }}
                            onClick={this.getProjectsForCompanies}
                        />
                    </Col>
                </Row>
            </div>
        );
    }

    render() {
        const { selected } = this.props;

        let extraData = { arrayCompanies: selected };

        if (!_.isNull(this.state.openDate) && !_.isNull(this.state.closeDate)) {
            extraData = _.set(extraData, "openDate", this.state.openDate);
            extraData = _.set(extraData, "closeDate", this.state.closeDate);
        }
        return (
            <div style={{ width: "100%" }}>
                {this.renderDates()}
                <Row style={{ display: this.state.visible ? "flex" : "none" }}>
                    <Col xs>
                        <GenericTablePagination
                            name="projects"
                            URL="/arl/companies/projects"
                            version={1}
                            body={body}
                            coreName="projectsARL"
                            extra={extraData}
                            selection
                            fieldSelection="_id"
                            refresh
                            refreshName="Actualizar"
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getRecords,
            cleanTable,
            setInSearchRecords,
            resetOrderRecord,
        },
        dispatch
    );
}

ListProjects.propTypes = {
    selected: PropTypes.arrayOf(PropTypes.string).isRequired,
    getRecords: PropTypes.func.isRequired,
    limit: PropTypes.number.isRequired,
    skip: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    orderName: PropTypes.string.isRequired,
    orderType: PropTypes.number.isRequired,
    textFilter: PropTypes.string.isRequired,
    arrayFields: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    cleanTable: PropTypes.func.isRequired,
    setInSearchRecords: PropTypes.func.isRequired,
    resetOrderRecord: PropTypes.func.isRequired,
};

function mapStateToProps({ arlDashboard, genericTablePagination }) {
    const storeCompanies = genericTablePagination.get("companies");
    return {
        arlId: arlDashboard.get("arlId"),
        selected: storeCompanies.get("selected"),
        countSelected: _.size(storeCompanies.get("selected")),
        projects: arlDashboard.get("projects"),
        idProjects: arlDashboard.get("idProjects"),
        limit: storeCompanies.get("limit"),
        skip: storeCompanies.get("skip"),
        total: storeCompanies.get("total"),
        orderName: storeCompanies.get("orderName"),
        orderType: storeCompanies.get("orderType"),
        textFilter: storeCompanies.get("textFilter"),
        arrayFields: storeCompanies.get("arrayFields"),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ListProjects);
