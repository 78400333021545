import PropTypes from "prop-types";
import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Row, Col } from "react-flexbox-grid";
import Paper from "material-ui/Paper";
import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";
import MenuItem from "material-ui/MenuItem";
import _ from "lodash";
import Checkbox from "material-ui/Checkbox";
import CircularProgress from "material-ui/CircularProgress";
import is from "is_js";
import { axiosAPI } from "Api";
import { LBLEVALUATORS } from "Labels";
import { createReferee } from "../actions/referees_form";
import {
  FetchRefereeParam,
  getDocumentsTypes,
  getStudyLevels,
} from "../actions/info/referees_info_form";
import { getCompaniesName } from "../actions/grid/companies_grid_form";
import DatePickerFormat from "../components/fields/DatePickerFormat/DatePickerFormat";
import { toggleSnackbar } from "../actions/commons";
import ButtonBack from "../components/fields/buttonBack/buttonBack";
import SelectFieldFormat from "../components/fields/SelectFieldFormat/SelectFieldFormat";
import AuthComponent from "../components/AuthComponent";

const style = {
  margin: 20,
  float: "right",
};
const stylePaper = {
  marginTop: 20,
  marginBottom: 20,
};

function errorFieldValidation(field) {
  return field.touched && field.error ? field.error : "";
}

class RefereesNews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chekedSendEmail: true,
    };
    this._saveReferees = this._saveReferees.bind(this);
    this._onCheckSendEmail = this._onCheckSendEmail.bind(this);
  }

  _saveReferees(formData) {
    const { createReferee, toggleSnackbar, resetForm, params } = this.props;
    const editMode = _.has(params, "_id");

    createReferee(
      formData,
      editMode,
      editMode ? false : this.state.chekedSendEmail
    )
      .then(
        (data) => {
          const estado = _.get(data, "payload.status");
          const error = _.get(data, "error");
          if (error || estado === 200) {
            resetForm();
            this.props.history.push(`/admin/referees/grid`);
            toggleSnackbar(true, "Evaluador guardado correctamente.");
          } else {
            toggleSnackbar(true, "Hubo un error al guardar");
          }
        },
        (reason) => {
          toggleSnackbar(true, "Hubo un error al guardar");
        }
      )
      .catch((data) => {
        toggleSnackbar(true, "Hubo un error al guardar");
      });
  }

  UNSAFE_componentWillMount() {
    const {
      FetchRefereeParam,
      getCompaniesName,
      getDocumentsTypes,
      getStudyLevels,
    } = this.props;
    getCompaniesName();
    getDocumentsTypes();
    getStudyLevels();
    if (_.has(this, "props.params._id")) {
      FetchRefereeParam(this.props.params._id);
    }
  }

  _onCheckSendEmail(e, cheked) {
    this.state = {
      chekedSendEmail: cheked,
    };
  }

  render() {
    const {
      asyncValidating,
      fields: {
        documentType,
        id,
        name,
        email,
        lastName,
        profession,
        company,
        educationLevel,
        professionalCard,
        healthLicense,
        expeditionDateHealthLicensed,
        workingProcess,
      },
      handleSubmit,
      params,
      studyLevels,
    } = this.props;

    const editMode = _.has(params, "_id");
    return (
      <Row>
        <Col xsOffset={2} xs={8}>
          <Paper style={stylePaper}>
            <Row>
              <Col xsOffset={2} xs={8}>
                <ButtonBack url="/admin/referees/grid" />
                <h1 style={{ textAlign: "center", fontWeight: 400 }}>
                  {editMode
                    ? LBLEVALUATORS.titleEdit
                    : LBLEVALUATORS.titleCreate}{" "}
                </h1>
                <form onSubmit={handleSubmit(this._saveReferees)}>
                  <Row>
                    <Col xs>
                      <SelectFieldFormat
                        config={{
                          floatingLabelText: LBLEVALUATORS.typeId,
                          value: documentType.value,
                          disabled: editMode,
                          errorText:
                            documentType.touched && documentType.error
                              ? documentType.error
                              : "",
                          onChange: (e, index, value) =>
                            documentType.onChange(value),
                        }}
                      >
                        {this.props.documentsTypes.map((item) => (
                          <MenuItem
                            key={item._id}
                            value={item._id}
                            primaryText={item.value}
                          />
                        ))}
                      </SelectFieldFormat>
                    </Col>
                    <Col xs>
                      <TextField
                        hintText=""
                        floatingLabelText={LBLEVALUATORS.id}
                        type="text"
                        disabled={editMode}
                        errorText={id.touched && id.error ? id.error : ""}
                        {...id}
                      />
                      {asyncValidating === "id" && (
                        <CircularProgress
                          size={25}
                          thickness={2}
                          style={{
                            position: "absolute",
                            float: "right",
                            marginTop: 35,
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs>
                      <TextField
                        hintText=""
                        floatingLabelText={LBLEVALUATORS.name}
                        type="text"
                        errorText={name.touched && name.error ? name.error : ""}
                        {...name}
                      />
                    </Col>
                    <Col xs>
                      <TextField
                        hintText=""
                        floatingLabelText={LBLEVALUATORS.lastNames}
                        type="text"
                        errorText={
                          lastName.touched && lastName.error
                            ? lastName.error
                            : ""
                        }
                        {...lastName}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs>
                      <TextField
                        hintText=""
                        floatingLabelText={LBLEVALUATORS.profession}
                        type="text"
                        errorText={
                          profession.touched && profession.error
                            ? profession.error
                            : ""
                        }
                        {...profession}
                      />
                    </Col>
                    <Col xs>
                      <TextField
                        hintText=""
                        floatingLabelText={LBLEVALUATORS.cardProfessional}
                        type="text"
                        errorText={
                          professionalCard.touched && professionalCard.error
                            ? professionalCard.error
                            : ""
                        }
                        {...professionalCard}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs>
                      <TextField
                        hintText=""
                        floatingLabelText={
                          LBLEVALUATORS.licensedOccupationalHealth
                        }
                        type="text"
                        errorText={
                          healthLicense.touched && healthLicense.error
                            ? healthLicense.error
                            : ""
                        }
                        {...healthLicense}
                      />
                    </Col>
                    <Col xs>
                      <DatePickerFormat
                        datePickerConfig={{
                          floatingLabelText: LBLEVALUATORS.licenseIssuanceDate,
                          hintText: LBLEVALUATORS.licenseIssuanceDate,
                          mode: "landscape",
                          errorText: errorFieldValidation(
                            expeditionDateHealthLicensed
                          ),
                          value: expeditionDateHealthLicensed.value,
                          onChange: (e, value) =>
                            expeditionDateHealthLicensed.onChange(value),
                          style: { marginTop: 0, marginBottom: 23 },
                        }}
                        stylefloatingLabelText={{
                          marginTop: 16,
                          marginBottom: -12,
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs>
                      <SelectFieldFormat
                        config={{
                          floatingLabelText: LBLEVALUATORS.levelStudy,
                          value: educationLevel.value,
                          errorText:
                            educationLevel.touched && educationLevel.error
                              ? educationLevel.error
                              : "",
                          onChange: (e, index, value) =>
                            educationLevel.onChange(value),
                        }}
                      >
                        {_.map(studyLevels, (item, index) => (
                          <MenuItem
                            key={index}
                            value={item.value}
                            primaryText={item.value}
                          />
                        ))}
                      </SelectFieldFormat>
                    </Col>
                    <Col xs>
                      <TextField
                        hintText=""
                        floatingLabelText={LBLEVALUATORS.email}
                        type="text"
                        errorText={
                          email.touched && email.error ? email.error : ""
                        }
                        {...email}
                      />

                      {asyncValidating === "email" && (
                        <CircularProgress
                          size={25}
                          thickness={2}
                          style={{
                            position: "absolute",
                            float: "right",
                            marginTop: 35,
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs>
                      <SelectFieldFormat
                        config={{
                          floatingLabelText: LBLEVALUATORS.company,
                          value: company.value,
                          autoWidth: true,
                          fullWidth: true,
                          errorText:
                            company.touched && company.error
                              ? company.error
                              : "",
                          onChange: (e, index, value) =>
                            company.onChange(value),
                        }}
                      >
                        {_.orderBy(this.props.companies, ["businessName"]).map(
                          (item) => (
                            <MenuItem
                              key={item._id}
                              value={item._id}
                              primaryText={item.businessName}
                            />
                          )
                        )}
                      </SelectFieldFormat>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs>
                      <TextField
                        hintText=""
                        floatingLabelText={LBLEVALUATORS.process}
                        type="text"
                        fullWidth
                        errorText={
                          workingProcess.touched && workingProcess.error
                            ? workingProcess.error
                            : ""
                        }
                        {...workingProcess}
                      />
                    </Col>
                  </Row>
                  {!editMode && (
                    <Row>
                      <Col>
                        <Checkbox
                          label="Enviar notificación de creación"
                          defaultChecked
                          style={{ padding: 20 }}
                          onCheck={this._onCheckSendEmail}
                        />
                      </Col>
                    </Row>
                  )}
                  <AuthComponent
                    component={
                      <RaisedButton
                        type="submit"
                        label={
                          editMode
                            ? LBLEVALUATORS.buttons.update
                            : LBLEVALUATORS.buttons.save
                        }
                        secondary
                        style={style}
                      />
                    }
                    permission="backend/referees:write"
                    type="component"
                  />
                </form>
              </Col>
            </Row>
          </Paper>
        </Col>
      </Row>
    );
  }
}

function getStudylevels() {
  return LBLEVALUATORS.arrayLevelsStudys.map((value) => (
    <MenuItem value={value} primaryText={value} />
  ));
}
const validate = (values) => {
  const errors = {};
  if (!values.documentType) {
    errors.documentType = LBLEVALUATORS.validations.typeId;
  }
  if (!values.id) {
    errors.id = LBLEVALUATORS.validations.id;
  }
  // else {
  //    if(!EXPRESSION_IDENTIFICACION.test(values.id)){
  //        errors.id = "Ingrese un número de identificación válido";
  //    }
  // }
  if (!values.name) {
    errors.name = LBLEVALUATORS.validations.name;
  }
  if (!values.company) {
    errors.company = LBLEVALUATORS.validations.company;
  }
  if (!values.lastName) {
    errors.lastName = LBLEVALUATORS.validations.lastName;
  }
  if (!values.educationLevel) {
    errors.educationLevel = LBLEVALUATORS.validations.levelStudy;
  }
  if (!values.profession) {
    errors.profession = LBLEVALUATORS.validations.profession;
  }
  if (!values.professionalCard) {
    errors.professionalCard = LBLEVALUATORS.validations.cardProfessional;
  }
  // else {
  //    if(!EXPRESSION_IDENTIFICACION.test(values.professionalCard)){
  //        errors.professionalCard = LBLEVALUATORS.validations."Ingrese un número de tarjeta profesional válido";
  //    }
  // }
  if (!values.healthLicense) {
    errors.healthLicense = LBLEVALUATORS.validations.licensedOccupationalHealth;
  }
  // else {
  //    if(!EXPRESSION_IDENTIFICACION.test(values.healthLicense)){
  //        errors.healthLicense = "Ingrese un número de licencia salud ocupacional válido";
  //    }
  // }
  if (!values.email) {
    errors.email = LBLEVALUATORS.validations.email;
  } else if (!is.email(values.email)) {
    errors.email = LBLEVALUATORS.validations.emailValid;
  }
  if (!values.workingProcess) {
    errors.workingProcess = LBLEVALUATORS.validations.process;
  }

  if (!values.expeditionDateHealthLicensed) {
    errors.expeditionDateHealthLicensed =
      LBLEVALUATORS.validations.licenseIssuanceDate;
  }
  return errors;
};

const asyncValidate = (values, dispatch) => {
  return new Promise((resolve, reject) => {
    const editMode = values._id ? true : false;

    if (!editMode) {
      if (values.id) {
        axiosAPI
          .get(`/referees/id`, {
            params: {
              id: values.id,
            },
          })
          .then((data) => {
            const resp = _.get(data.data, "data");
            if (!_.isNull(resp)) {
              if (_.isNull(values._id)) {
                reject({ id: LBLEVALUATORS.validations.idExist });
              } else {
                resolve();
              }
            } else {
              resolve();
            }
          })
          .catch((data) => {
            console.error("Error", data);
          });
      }
    } else {
      resolve();
    }
  });
};
const asyncValidateEmail = (values, dispatch) => {
  return new Promise((resolve, reject) => {
    const editMode = values._id ? true : false;

    if (!editMode) {
      if (values.email) {
        axiosAPI
          .get(`/persons/id/email`, {
            params: {
              email: values.email,
            },
          })
          .then((data) => {
            const respuesta = _.get(data.data, "data");
            if (_.isNull(respuesta)) {
              resolve();
            } else {
              reject({ email: LBLEVALUATORS.validations.emailExist });
            }
          })
          .catch((data) => {
            console.error("Error", data);
          });
      }
    } else {
      resolve();
    }
  });
};

function mapStateToProps({ referees, companies, master }, ownProps) {
  if (_.has(ownProps, "params._id") && _.has(referees, "referee.profession")) {
    const data = _.get(referees, "referee");
    return {
      companies: companies.all,
      initialValues: {
        documentType: _.get(data, "person.documentType"),
        id: data ? _.get(data, "person.id") : "",
        _id: _.get(ownProps.params, "_id"),
        name: _.get(data, "person.name"),
        lastName: _.get(data, "person.lastName"),
        profession: _.get(data, "profession"),
        email: data ? _.get(data, "person.email") : "",
        company: _.get(data, "company"),
        educationLevel: _.get(data, "educationLevel"),
        professionalCard: _.get(data, "professionalCard"),
        healthLicense: _.get(data, "healthLicense"),
        expeditionDateHealthLicensed: new Date(
          _.get(data, "expeditionDateHealthLicensed")
        ),
        workingProcess: data ? _.get(data, "workingProcess") : "",
      },
      studyLevels: master.studylevels,
      documentsTypes: master.documentsTypes,
    };
  }
  return {
    companies: companies.all,
    initialValues: {
      documentType: "",
      id: "",
      _id: null,
      name: "",
      lastName: "",
      profession: "",
      educationLevel: "",
      professionalCard: "",
      company: "",
      healthLicense: "",
      expeditionDateHealthLicensed: "",
      workingProcess: "",
    },
    studyLevels: master.studylevels,
    documentsTypes: master.documentsTypes,
  };
}

RefereesNews.propTypes = {
  asyncValidating: PropTypes.string.isRequired,
  router: PropTypes.object,
};

export default reduxForm(
  {
    form: "RefereesNewForm",
    fields: [
      "documentType",
      "id",
      "_id",
      "name",
      "email",
      "lastName",
      "profession",
      "educationLevel",
      "professionalCard",
      "company",
      "healthLicense",
      "expeditionDateHealthLicensed",
      "workingProcess",
    ],
    asyncValidate,
    asyncBlurFields: ["id", "email"],
    validate,
  },
  mapStateToProps,
  {
    createReferee,
    FetchRefereeParam,
    getCompaniesName,
    toggleSnackbar,
    getDocumentsTypes,
    getStudyLevels,
  }
)(RefereesNews);
