import _ from "lodash";
import { POST_COMPANY, DELETE_COMPANY, PUT_COMPANY } from "../constants/index";
import { axiosAPI } from "../middleware/api";

export function createCompany(props) {
  const propertiesToSend = _.omit(props, ["_id"]);
  const request = axiosAPI.request({
    url: `/companies`,
    method: "POST",
    data: {
      company: {...propertiesToSend, type: "NIT"}
    },
  });
  return {
    type: POST_COMPANY,
    payload: request,
  };
}

export function deleteCompany(companyId) {
  const request = axiosAPI.delete(`/companies/${companyId}`, {
    data: { type: "logic" },
  });

  return {
    type: DELETE_COMPANY,
    payload: request,
    meta: {
      companyId,
    },
  };
}

export function updateCompany(props){
  const propertiesToSend = _.omit(props, ["_id"]);
  const request = axiosAPI.request({
    url: `/companies/${props._id}`,
    method: "PUT",
    data: {
      company: {...propertiesToSend, type: "NIT"}
    },
  });
  return {
    type: PUT_COMPANY,
    payload: request,
  };
}
