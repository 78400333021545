import React from "react";
import Auth from "@aws-amplify/auth";
import MenuItem from "material-ui/MenuItem";
import { useNotifications } from "Notifications";
import Form, { PasswordFormValues } from "./form/passwordChangeForm";
import Dialog from "./modal/changePasswordModal";

function useChangeUserPassword() {
  const { sendNotification } = useNotifications();

  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const handleClick = () => setOpen(true);

  const handleSubmit = async (values: PasswordFormValues) => {
    const user = await Auth.currentAuthenticatedUser();
    try {
      await Auth.changePassword(user, values.currentPassword, values.password);

      sendNotification("Contraseña cambiada con exito");
      setOpen(false);
    } catch (e) {
      if (e.code === "NotAuthorizedException") {
        sendNotification("La contraseña actual es incorrecta");
      } else {
        sendNotification(
          `Error al cambiar la contraseña\nPor favor notifique al administrador`
        );
      }
    }
  };

  return {
    MenuItem: () => (
      <MenuItem primaryText="Cambiar contraseña" onClick={handleClick} />
    ),
    Modal: () => (
      <Dialog open={open} onClose={handleClose}>
        <Form onSubmit={handleSubmit} />
      </Dialog>
    ),
  };
}

export default useChangeUserPassword;
