import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Grid } from "react-flexbox-grid";
import Paper from "@material-ui/core/Paper";
import AppBar from "material-ui/AppBar";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    containerRow: {
        marginTop: 80,
    },
    paper: {
        paddingBottom: 25,
        paddingLeft: 0,
    },
});

const AuthContainer = ({ children }) => {
    const classes = useStyles();
    return (
        <Grid fluid>
            <Row className={classes.containerRow}>
                <Col xs={12} sm={6} smOffset={3} md={4} mdOffset={4}>
                    <Paper className={classes.paper}>
                        <Row>
                            <Col xs>
                                <AppBar
                                    title="SURA Encuestas - Ingresar"
                                    showMenuIconButton={false}
                                />
                            </Col>
                        </Row>
                        {children}
                    </Paper>
                </Col>
            </Row>
        </Grid>
    );
};

AuthContainer.propTypes = {
    children: PropTypes.element.isRequired,
};

export default AuthContainer;
