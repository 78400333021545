import PropTypes from "prop-types";
import React, { Component } from "react";
import TableCell from "@material-ui/core/TableCell";
import _ from "lodash";
import { Link } from "react-router-dom";
import IconButton from "material-ui/IconButton";
import { connect } from "react-redux";
import VisibilityIcon from "material-ui/svg-icons/action/visibility";
import AuthComponent from "../../AuthComponent";
import {
  openDialogUserAdvance,
  advanceUserProject,
} from "../../fields/AdvanceUser/actions";
import { LBLEMPLOYESS, LBLPROJECTS } from "../../../constants/labels";

class EmployeeStatusProjectForCompanyActionColumn extends Component {
  constructor(props) {
    super(props);
    this.handleModalAdvanceUser = this.handleModalAdvanceUser.bind(this);
  }

  handleModalAdvanceUser(data) {
    const { extra, openDialogUserAdvance, advanceUserProject } = this.props;
    advanceUserProject(
      _.get(data, "_id"),
      _.get(extra, "project"),
      _.get(data, "employee.person")
    );
    openDialogUserAdvance();
  }

  render() {
    const { data, colSpan, extra } = this.props;
    const cs = _.isUndefined(colSpan) ? 1 : colSpan;
    const sizeEmployeesToDelete = 0;
    return (
      <TableCell colSpan={cs}>
        <div style={{ position: "absolute", marginTop: -24, zIndex: 99 }}>
          {_.isEqual(_.get(data, "status"), "En proceso") && (
            <AuthComponent
              component={
                <IconButton
                  tooltip="Ver estado actual"
                  tooltipPosition="top-center"
                  children={<VisibilityIcon />}
                  onClick={() => this.handleModalAdvanceUser(data)}
                />
              }
              permission="backend/users:read"
              type="component"
            />
          )}
        </div>
      </TableCell>
    );
  }
}

EmployeeStatusProjectForCompanyActionColumn.propTypes = {
  data: PropTypes.object.isRequired,
  colSpan: PropTypes.number,
  extra: PropTypes.object,
};

export default connect(null, { advanceUserProject, openDialogUserAdvance })(
  EmployeeStatusProjectForCompanyActionColumn
);
