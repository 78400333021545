import React from 'react';
import {Col, Row} from 'react-flexbox-grid';
import CreateFormDialog from "../create/createFormDialog";
import {Fab, Grid, List, ListItem, ListItemText, Tooltip, makeStyles} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import {parseSimpleDate} from "Utils";

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: 294
    },
    changeDetailsButton: {
        marginTop: theme.spacing(2.5),
        marginRight: theme.spacing(3.5),
    },
    gridContainer: {
        backgroundColor: "#FFF",
        padding: "2%",
    },
    divider: {
        borderLeft: "1px solid #E0E0E0",
    }
}))

type TabDetailProps = {
    item: any
}

/**
 * Component to see the detail of the dataset
 * @modified Jhoan Lopez <jhoan.lopez@ias.com.co>
 * @param item
 * @constructor
 */
const DetailTab: React.FC<TabDetailProps> = ({item}) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className={classes.root}>
            <Col xs>
                <Row end="xs">
                    <Tooltip title="Editar información">
                        <Fab
                            size="small"
                            color="secondary"
                            onClick={handleClickOpen}
                            className={classes.changeDetailsButton}
                        >
                            <EditIcon/>
                        </Fab>
                    </Tooltip>
                </Row>
            </Col>
            <Col xs>
                <Grid container className={classes.gridContainer}>
                    <Grid xs={6} md={6} item>
                        <div>
                            <List>
                                <ListItem>
                                    <ListItemText primary="Id"/>
                                    <ListItemText secondary={item?.datasetId}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Nombre"/>
                                    <ListItemText secondary={item?.details.name}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Descripción"/>
                                    <ListItemText secondary={item?.details.description}/>
                                </ListItem>
                            </List>
                        </div>
                    </Grid>
                    <Grid xs={6} md={6} item>
                        <div className={classes.divider}>
                            <List>
                                <ListItem>
                                    <ListItemText primary="Fecha de creación"/>
                                    <ListItemText secondary={parseSimpleDate(item?.createdAt)}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Última actualización"/>
                                    <ListItemText secondary={parseSimpleDate(item?.updatedAt)}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Tipo de salida"/>
                                    <ListItemText secondary="##"/>
                                </ListItem>
                            </List>
                        </div>
                    </Grid>
                </Grid>
            </Col>
            <CreateFormDialog open={open} handleClose={handleClose} item={item}/>
        </div>
    );
};

export default DetailTab;
