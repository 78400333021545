import React, { useState, useEffect } from "react";
import { Row, Col } from "react-flexbox-grid";
import PropTypes from "prop-types";
import { LBL_COMPLEMENTS } from "../../../constants/labels";
import ListComplementValue from "../listComplementValue";
import ComplementForm from "../complementForm";
import { useGetValueById, useDeleteValue, useEditValue } from "../data";
import { useHistory } from "react-router-dom";

const styles = {
  colMenu: {
    borderRight: "solid",
    borderRightColor: "#eeeeee",
    borderRightWidth: 2,
  },
  contentTitle: {
    padding: 6,
    backgroundColor: "#E3F2FD",
    margin: 12,
  },
  contentList: {
    padding: 24,
    paddingTop: 0,
  },
  contentTextFieldValues: {
    textAlign: "center",
  },
};

/**
 * Componente para representar la gestión de un valor del complemento
 * @author Jhoan Lopez <jhoanlt19@gmail.com>
 * @param {*} param0
 */
const ComplementValueMain = ({ value }) => {
  const history = useHistory();
  const [complementValue, setComplementValue] = useState({});
  const { loading, error, data } = useGetValueById({ _id: value });
  const [deleteComplementValue, {}] = useDeleteValue({
    _id: complementValue._id,
  });
  const [editValue, {}] = useEditValue({});

  useEffect(() => {
    if (!loading && !error) {
      setComplementValue(data);
    }
  }, [loading, data, error]);

  const handleSaveEditValue = name => {
    editValue({
      variables: { input: { _id: value, name } },
    })
      .then(({ data }) => {
        if (data.editValue) {
        }
      })
      .catch(err => {
        console.log("Error: ", err);
      });
  };

  const handleDeleteValue = _id => {
    deleteComplementValue()
      .then(({ data }) => {
        if (data.deleteComplementValue) {
          history.push("/admin/complements/main");
        }
      })
      .catch(err => {
        console.log("Error: ", err);
      });
  };

  return (
    <Row>
      <Col xs={12} md={12}>
        <ComplementForm
          selected={complementValue}
          handleSave={handleSaveEditValue}
          handleDelete={handleDeleteValue}
          titleDelete={LBL_COMPLEMENTS.titleDeleteValue}
        />
        <div style={styles.contentTitle}>
          <span>{LBL_COMPLEMENTS.titleListValues}</span>
        </div>
        <div style={styles.contentList}>
          <ListComplementValue
            data={[]}
            emptyValues={false}
            loading={loading}
          />
        </div>
      </Col>
    </Row>
  );
};

ComplementValueMain.defaultProps = {
  value: null,
};

ComplementValueMain.propTypes = {
  value: PropTypes.string,
};

export default ComplementValueMain;
