import PropTypes from "prop-types";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { Row, Col } from "react-flexbox-grid";
import { connect } from "react-redux";
import _ from "lodash";
import { Step, Stepper, StepLabel, StepContent } from "material-ui/Stepper";
import RaisedButton from "material-ui/RaisedButton";
import SelectCompanies from "./components/selectCompanies";
import ListProjects from "./components/listProjects";
import StatusEmployees from "./components/statusEmployees";
import SurveysProjects from "./components/surveysProjects";
import { setProjectsResultsAndNameSurvey } from "./actions";
import { cleanSelected } from "../../GenericTablePagination/actions";

class businessInformation extends Component {
  state = {
    finished: false,
    stepIndex: 0,
    numberSteps: 2,
  };

  static propTypes = {
    info: PropTypes.object.isRequired,
  };

  UNSAFE_componentWillMount() {
    const { resetState } = this.props;
    resetState();
  }

  componentDidMount() {
    const { cleanSelected } = this.props;
    cleanSelected("companies");
    cleanSelected("projectsARL");
  }

  handleNext = () => {
    const { stepIndex, numberSteps } = this.state;
    const { countCompaniesSelected, countProjectsSelected } = this.props;
    if (_.isEqual(stepIndex, 0)) {
      if (countCompaniesSelected > 0) {
        this.setState({
          stepIndex: stepIndex + 1,
          finished: stepIndex >= numberSteps,
        });
      }
    } else if (_.isEqual(stepIndex, 1)) {
      if (countProjectsSelected > 0) {
        this.setState({
          stepIndex: stepIndex + 1,
          finished: stepIndex >= numberSteps,
        });
      }
    } else {
      this.setState({
        stepIndex: stepIndex + 1,
        finished: stepIndex >= numberSteps,
      });
    }
  };

  handlePrevious = () => {
    const { stepIndex, numberSteps } = this.state;
    const stepPrev = stepIndex - 1 < 0 ? 0 : stepIndex - 1;
    this.setState({
      stepIndex: stepPrev,
      finished: stepIndex >= numberSteps,
    });
  };

  handleReinit = () => {
    const { resetState, setProjectsResultsAndNameSurvey, cleanSelected } =
      this.props;
    this.setState({ stepIndex: 0 });
    resetState();
    cleanSelected("companies");
    cleanSelected("projectsARL");
    setProjectsResultsAndNameSurvey([], "Prueba");
  };

  renderStepActions = step => {
    const { stepIndex, numberSteps } = this.state;
    const { countCompaniesSelected, countProjectsSelected } = this.props;
    let disabled = false;
    let disabledPreious = false;
    let visibleNext = true;
    if (_.isEqual(stepIndex, 0)) {
      if (_.isEqual(countCompaniesSelected, 0)) {
        disabled = true;
      }
    } else if (_.isEqual(stepIndex, 1)) {
      if (_.isEqual(countProjectsSelected, 0)) {
        disabled = true;
      }
    }
    if (_.isEqual(stepIndex, 0)) {
      disabledPreious = true;
    }
    if (_.isEqual(step, numberSteps)) {
      visibleNext = false;
    }
    return (
      <div style={{ margin: "12px 0" }}>
        {visibleNext && (
          <RaisedButton
            label={stepIndex === numberSteps ? "Finalizar" : "Siguiente"}
            disableTouchRipple
            disableFocusRipple
            disabled={disabled}
            primary
            onClick={this.handleNext}
            style={{ marginRight: 12 }}
          />
        )}
        {!disabledPreious && (
          <RaisedButton
            label="Atrás"
            disableTouchRipple
            disableFocusRipple
            disabled={disabledPreious}
            primary
            onClick={this.handlePrevious}
            style={{ marginRight: 12 }}
          />
        )}
      </div>
    );
  };

  renderCountCompanies = () => {
    const { info } = this.props;
    return (
      <div
        style={{
          width: 200,
          textAlign: "center",
          display: "inline-block",
          height: 100,
        }}
      >
        <h3 style={{ fontWeight: 400 }}>Empresas</h3>
        <h1>{_.get(info, "countArlCompanies", 0)}</h1>
      </div>
    );
  };

  renderCountEmployeesOfCompanies = () => {
    const { info } = this.props;
    return (
      <div
        style={{
          width: 200,
          textAlign: "center",
          display: "inline-block",
          height: 100,
        }}
      >
        <h3 style={{ fontWeight: 400 }}>Colaboradores</h3>
        <h1>{_.get(info, "countArlEmployees", 0)}</h1>
      </div>
    );
  };

  renderResults = () => {
    const { projectsResults, metadata, nameSurvey } = this.props;
    if (_.size(projectsResults) > 0) {
      const filterProject = _.map(projectsResults, project => ({
        item: "projectId",
        operator: "=",
        value: project,
        columnFamily: "metadata",
        condition: "OR",
      }));

      const allowedFilters = [QUESTIONS];
      if (_.size(projectsResults) === 1) {
        allowedFilters.push(METADATA);
      }

      /* return (
        <AuthComponent
          component={
            <SarinCharter
              baseFilter={filterProject}
              title={`${nameSurvey}`}
              records={metadata}
              baseFilterItems={BASE_QUESTIONS}
              allowedFilters={allowedFilters}
              allowSelectType={false}
              projectsId={projectsResults}
            />
          }
          permission="backend/projects:read"
          type="component"
        />
      ); */
    }
    return <div />;
  };

  render() {
    const { stepIndex, numberSteps } = this.state;
    return (
      <div style={{ width: "100%" }}>
        <Row>
          <Col xs>
            <div
              style={{
                margin: "0 auto",
                maxWidth: 420,
                minWidth: 210,
                width: "100%",
                padding: 5,
              }}
            >
              {this.renderCountCompanies()}
              {this.renderCountEmployeesOfCompanies()}
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs>
            <div
              style={{
                width: "100%",
                margin: "0 auto 20px auto",
              }}
            >
              <Stepper activeStep={stepIndex} orientation="vertical">
                <Step>
                  <StepLabel>Seleccionar empresas</StepLabel>
                  <StepContent>
                    <SelectCompanies />
                    {this.renderStepActions(0)}
                  </StepContent>
                </Step>
                <Step>
                  <StepLabel>Seleccionar proyectos</StepLabel>
                  <StepContent>
                    <ListProjects />
                    {this.renderStepActions(1)}
                  </StepContent>
                </Step>
                <Step>
                  <StepLabel>Seleccionar prueba</StepLabel>
                  <StepContent>
                    <StatusEmployees />
                    {this.renderStepActions(2)}
                    <SurveysProjects handleNext={this.handleNext} />
                  </StepContent>
                </Step>
              </Stepper>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs>
            <RaisedButton
              label="Reiniciar pasos"
              disabled={stepIndex === 0}
              disableTouchRipple
              disableFocusRipple
              primary
              onClick={this.handleReinit}
              style={{ float: "right", margin: 20 }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs>{this.renderResults()}</Col>
        </Row>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      resetState: () => {},
      setProjectsResultsAndNameSurvey,
      cleanSelected,
    },
    dispatch
  );
}

function mapStateToProps({
  arlDashboard,
  projectReports,
  genericTablePagination,
}) {
  const storeCompanies = genericTablePagination.get("companies");
  const storeProjects = genericTablePagination.get("projectsARL");
  return {
    metadata: projectReports.get("metadata"),
    arlId: arlDashboard.get("arlId"),
    projectsResults: arlDashboard.get("projectsResults"),
    nameSurvey: arlDashboard.get("nameSurvey"),
    countCompaniesSelected: _.size(storeCompanies.get("selected")),
    countProjectsSelected: _.size(storeProjects.get("selected")),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(businessInformation);
