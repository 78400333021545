import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import { Table, TableBody, TableRow, TableRowColumn, TableHeader, TableHeaderColumn } from 'material-ui/Table';
import {Row, Col} from 'react-flexbox-grid';
import {blue700, grey300} from 'material-ui/styles/colors';

class DataGroupersProcessed extends Component {

    constructor(props) {
        super(props);
        this.renderFrequency = this.renderFrequency.bind(this);
        this.renderSummation = this.renderSummation.bind(this);
    }

    renderFrequency(){
        const { result } = this.props;
        const arrayFreq = _.get(result, 'groupersProcessed.freq', []);
        return _.map(arrayFreq, function(freq, index){
            return (
                <div key={`freqGraphic${index}`}>
                    <h3 style={{fontWeight: 500, marginTop: 40, marginBottom: 0, padding: '0 20px'}}>{_.get(freq, 'name')}</h3>
                    {_.get(freq, 'visible', false)
                    ? <h4 style={{fontWeight: 400, marginTop: 5, padding: '0 20px', color: '#AAA'}}>Esta información es visible para el usuario</h4>
                    : <h4 style={{fontWeight: 400, marginTop: 5, padding: '0 20px', color: '#AAA'}}>Esta información no es visible para el usuario</h4>}
                    <Table selectable={false}>
                        <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
                            <TableRow>
                                <TableHeaderColumn>Categoria</TableHeaderColumn>
                                <TableHeaderColumn>Descripción del usuario</TableHeaderColumn>
                                <TableHeaderColumn>Descripción del reporte</TableHeaderColumn>
                            </TableRow>
                        </TableHeader>
                        <TableBody displayRowCheckbox={false}>
                            {_.map(_.get(freq, 'conditions', []), (condition, idx) => {
                                return (
                                    <TableRow key={`conditionFreqGraphic${idx}`}>
                                        <TableRowColumn style={getStyleColumnFreq(_.get(condition, 'color', '#FFF'))}>
                                            {_.get(condition, 'category')}
                                        </TableRowColumn>
                                        <TableRowColumn style={getStyleColumnFreq()}>
                                            {_.get(condition, 'descriptionUser')}
                                        </TableRowColumn>
                                        <TableRowColumn style={getStyleColumnFreq()}>
                                            {_.get(condition, 'reportDescription')}
                                        </TableRowColumn>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </div>
            );
        });
    }

    renderSummation(){
        const { result } = this.props;
        const arraySum = _.get(result, 'groupersProcessed.sum', []);
        /* <div style={{width: '100%', border: `1px solid ${grey300}`, padding: 0,  margin: '0 auto'}}>
        </div> */
        return _.map(arraySum, function(sum, index){
            return (
                <div key={`sumGraphic${index}`}>
                    <h3 style={{fontWeight: 500, marginTop: 40, marginBottom: 0, padding: '0 20px'}}>{`${_.get(sum, 'name')}`}</h3>
                    {_.get(sum, 'visible', false)
                    ? <h4 style={{fontWeight: 400, marginTop: 5, padding: '0 20px', color: '#AAA'}}>Esta información es visible para el usuario</h4>
                    : <h4 style={{fontWeight: 400, marginTop: 5, padding: '0 20px', color: '#AAA'}}>Esta información no es visible para el usuario</h4>}
                    <Table selectable={false}>
                        <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
                            <TableRow>
                                <TableHeaderColumn>Descripción del usuario</TableHeaderColumn>
                                <TableHeaderColumn>Descripción del reporte</TableHeaderColumn>
                            </TableRow>
                        </TableHeader>
                        <TableBody displayRowCheckbox={false}>
                            {_.map(_.get(sum, 'conditions', []), (condition, idx) => {
                                return (
                                    <TableRow key={`conditionSumGraphic${idx}`}>
                                        <TableRowColumn style={getStyleColumnSum(_.get(condition, 'color', '#FFF'))}>
                                            {_.get(condition, 'descriptionUser')}
                                        </TableRowColumn>
                                        <TableRowColumn style={getStyleColumnSum()}>
                                            {_.get(condition, 'reportDescription')}
                                        </TableRowColumn>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </div>
            );
        });
    }

    render() {
        const { result } = this.props;
        if(_.isNull(result)){
            return ( <div /> )
        } 
            return (
                <div>
                    <Row style={{padding: '0 20px'}}>
                        <Col xs>
                            <h1 style={{fontWeight: 400, textAlign: '0 auto', marginTop: 30}}>Resultados</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs>
                            {this.renderFrequency()}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs>
                            {this.renderSummation()}
                        </Col>
                    </Row>
                </div>
            );
        
    }
}

function getStyleColumnFreq(color){
    const style = { whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'justify' };
    if(!_.isNull(color) && !_.isUndefined(color)){
        return _.set(style, 'borderLeft', `5px solid ${color}`);
    }
    return style;
}

function getStyleColumnSum(color){
    const style = { whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'justify' };
    if(!_.isNull(color) && !_.isUndefined(color)){
        return _.set(style, 'borderLeft', `5px solid ${color}`);
    }
    return style;
}

export default DataGroupersProcessed;
