import { axiosAPI, axiosApiV2 } from "../../../middleware/api";

export const LOGOUT = "LOGOUT";

export function logOut() {
  const { localStorage } = window;
  localStorage.removeItem("authToken");
  axiosAPI.defaults.headers.authorization = undefined;
  axiosApiV2.defaults.headers.authorization = undefined;
  return {
    type: LOGOUT,
  };
}
