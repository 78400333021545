import Immutable from 'immutable';
import * as actions from './constants';

const initialState = Immutable.Map();
export default function (state = initialState, action) {
    switch (action.type) {
    case actions.LOAD_TABLE_DATA:
        var {data} = action.payload;
        var {storeName} = action.meta;
        return state.set(storeName, data);
    default:
        return state;
    }
}
