import {
  POST_SURVEY,
  LOAD_SURVEYS_PROJECT,
  DELETE_SURVEY,
  LOCK_SURVEY,
  CLONE_SURVEY,
  POST_METRICS_SECTION_SURVEY,
  GET_GROUPER_QUESTION_METRICS,
  CLEAN_GROUPER_QUESTION_METRICS,
  POST_CONDITIONS_GROUPER_QUESTIONS,
} from "../constants/index";
import { axiosAPI, axiosApiV2 } from "../middleware/api";

export function createSurvey(props, editMode) {
  let request = null;
  if (editMode) {
    request = axiosAPI.put(`/surveys`, { survey: props });
  } else {
    request = axiosAPI.post(`/surveys`, { survey: props });
  }

  return {
    type: POST_SURVEY,
    payload: request,
  };
}

export function loadSurveysProject() {
  const request = axiosAPI.get(`/surveys/lock`);

  return {
    type: LOAD_SURVEYS_PROJECT,
    payload: request,
  };
}

export function deleteSurvey(surveysId) {
  const request = axiosAPI.delete(`/surveys/${surveysId}`, {
    data: { type: "logic" },
  });

  return {
    type: DELETE_SURVEY,
    payload: request,
    meta: {
      surveysId,
    },
  };
}

export function lockSurvey(surveysId) {
  const request = axiosAPI.put(`/surveys/lock/${surveysId}`);

  return {
    type: LOCK_SURVEY,
    payload: request,
    meta: {
      surveysId,
    },
  };
}

export function cloneSurvey(idSurvey) {
  const request = axiosAPI.post(`/surveys/clone/${idSurvey}`);

  return {
    type: CLONE_SURVEY,
    payload: request,
  };
}

export function saveMetricsSectionSurvey(idSurvey, idSection, questions) {
  const request = axiosAPI.post(`/surveys/section/metrical`, {
    survey: {
      surveyId: idSurvey,
      idSection,
      questions,
    },
  });

  return {
    type: POST_METRICS_SECTION_SURVEY,
    payload: request,
  };
}

export function FetchGroupQuestionMetricParam(id) {
  const request = axiosApiV2.get(`/surveygroupers/grouper/${id}`);

  return {
    type: GET_GROUPER_QUESTION_METRICS,
    payload: request,
  };
}

export function cleanGroupQuestionMetricParam() {
  return {
    type: CLEAN_GROUPER_QUESTION_METRICS,
  };
}

export function saveConditionsGrouperQuestions(id, conditions) {
  const request = axiosApiV2.post(`/surveygroupers/conditions/${id}`, {
    survey: {
      conditions,
    },
  });

  return {
    type: POST_CONDITIONS_GROUPER_QUESTIONS,
    payload: request,
  };
}
