import PropTypes from "prop-types";
import React, { Component } from "react";
import TableCell from "@material-ui/core/TableCell";
import _ from "lodash";
import { Link } from "react-router-dom";
import IconButton from "material-ui/IconButton";
import { connect } from "react-redux";
import EditIcon from "material-ui/svg-icons/editor/mode-edit";
import SettingsIcon from "material-ui/svg-icons/action/settings";
import AuthComponent from "../../AuthComponent";
import { LBLCOMPANIES } from "../../../constants/labels";
import DeleteEntityModal from "../../deleteEntityModal/component";
import { deleteCompany } from "../../../actions/companies_form";
import { toggleSnackbar } from "../../../actions/commons";
import { removeRecord } from "../actions";

class CompanyActionColumn extends Component {
  constructor(props) {
    super(props);
    this.handleTouchDelete = this.handleTouchDelete.bind(this);
  }

  handleTouchDelete(data) {
    const { toggleSnackbar, deleteCompany, removeRecord } = this.props;
    const idCompany = _.get(data, "_id");
    deleteCompany(idCompany)
      .then(
        (data) => {
          if (_.get(data, "error") || _.get(data, "payload.status") !== 200) {
            toggleSnackbar(true, LBLCOMPANIES.msg.errorDelete);
          } else {
            removeRecord(idCompany, "_id");
            toggleSnackbar(true, LBLCOMPANIES.msg.successDelete);
          }
        },
        (reason) => {
          toggleSnackbar(true, LBLCOMPANIES.msg.errorDelete);
        }
      )
      .catch((data) => {
        toggleSnackbar(true, LBLCOMPANIES.msg.errorDelete);
      });
  }

  render() {
    const { data, colSpan } = this.props;
    const cs = _.isUndefined(colSpan) ? 1 : colSpan;
    return (
      <TableCell colSpan={cs}>
        <div style={{ position: "absolute", marginTop: -24, zIndex: 99 }}>
          <AuthComponent
            component={
              <Link to={`/admin/companies/info/${_.get(data, "_id")}`}>
                <IconButton
                  tooltip={LBLCOMPANIES.tooltips.btnView}
                  tooltipPosition="top-center"
                  children={<SettingsIcon />}
                />
              </Link>
            }
            permission="backend/companies:write"
            type="component"
          />
          <AuthComponent
            component={
              <Link to={`/admin/companies/edit/${_.get(data, "_id")}`}>
                <IconButton
                  tooltip={LBLCOMPANIES.tooltips.btnEdit}
                  tooltipPosition="top-center"
                  children={<EditIcon />}
                />
              </Link>
            }
            permission="backend/companies:write"
            type="component"
          />
          <AuthComponent
            component={
              <DeleteEntityModal
                tooltip={LBLCOMPANIES.tooltips.btnDelete}
                title={LBLCOMPANIES.titleDelete}
                message={LBLCOMPANIES.msg.deleteModal(
                  _.get(data, "businessName")
                )}
                fn={this.handleTouchDelete}
                args={[data]}
                style={{ display: "table-cell" }}
              />
            }
            permission="backend/companies:delete"
            type="component"
          />
        </div>
      </TableCell>
    );
  }
}

CompanyActionColumn.propTypes = {
  data: PropTypes.object.isRequired,
  colSpan: PropTypes.number,
};

export default connect(null, { deleteCompany, toggleSnackbar, removeRecord })(
  CompanyActionColumn
);
