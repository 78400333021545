import {
  GET_TEMPLATE,
  POST_TEMPLATE,
  DELETE_TEMPLATE,
  TEMPLATE_GRID,
} from "../constants/index";
import { axiosApiV2 } from "../middleware/api";

export function createTemplate(props, editMode) {
  let request = null;
  if (editMode) {
    request = axiosApiV2.put(`/templateEmail`, { template: props });
  } else {
    request = axiosApiV2.post(`/templateEmail`, { template: props });
  }

  return {
    type: POST_TEMPLATE,
    payload: request,
  };
}

export function getTemplates() {
  const request = axiosApiV2.get(`/templateEmail`);

  return {
    type: TEMPLATE_GRID,
    payload: request,
  };
}

export function getTemplate(id) {
  const request = axiosApiV2.get(`/templateEmail/${id}`);

  return {
    type: GET_TEMPLATE,
    payload: request,
  };
}

export function deleteTemplate(templateId) {
  const request = axiosApiV2.delete(`/templateEmail/${templateId}`, {
    data: { type: "logic" },
  });

  return {
    type: DELETE_TEMPLATE,
    payload: request,
    meta: {
      templateId,
    },
  };
}
