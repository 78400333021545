import Immutable from 'immutable';
import _ from 'lodash';
import {
    GET_DATA_EMPLOYEES_PROJECT_PROCESSED,
    DATA_EMPLOYEES_PROJECT_PROCESSED_IN_SEARCH,
    SEND_DATA_EMPLOYEES_PROJECT_PROCESSED
} from './constants';

const initialState = Immutable.Map({
    processed: null,
    inSearchProcessed: true
});

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_DATA_EMPLOYEES_PROJECT_PROCESSED:
            return state.withMutations(function(state) {
                        state.set("processed", _.get(action, 'payload.data', null));
                        state.set("inSearchProcessed", false);
                    });
        case DATA_EMPLOYEES_PROJECT_PROCESSED_IN_SEARCH:
            return state.set("inSearchProcessed", true);
        case SEND_DATA_EMPLOYEES_PROJECT_PROCESSED:
            return state.set("inSearchProcessed", false);
        default:
            return state;
    }
}
