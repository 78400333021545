import moment from "moment";
import _ from "lodash";

const initialDate = moment().toDate();

/**
 * Function that checks and open and a close date to return if a [x] date pass.
 * @param  {[Date]} openDate       Open date.
 * @param  {[Date]} closeDate      Close Date.
 * @param  {[Date]} dateToValidate Date to perform the validation.
 * @return {Boolean}             validation if the date is inside the interval.
 */
function validaOpenAndCloseDate(openDate, closeDate, dateToValidate) {
  const validateOpenDate = moment(dateToValidate).isSameOrAfter(openDate);
  const validateCloseDate = moment(dateToValidate).isBefore(closeDate);
  return validateOpenDate && validateCloseDate;
}

/**
 * [getLapseOfTheDate validates if the currentDate has a lapse valid, based on
 * lapse openTime and closeTime.]
 * @param  {[Object]} daysCollection                         [Collection of days.]
 * @param  {[Date]} currentDate    =             initialDate [initialDate or date to validate.]
 * @return {[lodash.lazy]}                                   [lazy lodash function to be chained or get the values.]
 */
function getLapseOfTheDate(daysCollection = [], currentDate = initialDate) {
  const currentDateDayOfTheWeek = moment(currentDate).day();
  const filtered = daysCollection.filter((dayObject) =>
    _.isEqual(dayObject.day, currentDateDayOfTheWeek)
  );

  return _.get(filtered, "0.lapses", []).filter((lapse) => {
    const openHour = _.get(lapse, "open.hour");
    const openMinute = _.get(lapse, "open.minute");
    const closeHour = _.get(lapse, "close.hour");
    const closeMinute = _.get(lapse, "close.minute");
    const openDate = moment().hour(openHour).minutes(openMinute);
    const closeDate = moment().hour(closeHour).minutes(closeMinute);
    return validaOpenAndCloseDate(openDate, closeDate, currentDate);
  });
}

export function getLapsesToday(daysCollection = [], currentDate = initialDate) {
  const currentDateDayOfTheWeek = moment(currentDate).day();
  const filtered = daysCollection.filter((dayObject) =>
    _.isEqual(dayObject.day, currentDateDayOfTheWeek)
  );

  return _.get(filtered, "0.lapses", []);
}

/**
 * Validates that the currentDate has and lapse in the lapsesCollection.
 * @param  {[Object]} daysCollection =             []          [Collection of days.]
 * @param  {[Date]} currentDate      =             initialDate [CurrentDate or date to validate.]
 * @return {Boolean}                                         [if the currentDate has a lapse.]
 */
export function validateProjectLapseOfDay(
  daysCollection = [],
  currentDate = initialDate
) {
  const dayLapses = getLapseOfTheDate(daysCollection, currentDate).length;
  return dayLapses > 0;
}

/**
 *
 * @param daysCollection
 * @param currentDate
 * @returns {boolean}
 */
export function validateProjectLapseDayToday(
  daysCollection = [],
  currentDate = initialDate
) {
  const dayLapses = getLapsesToday(daysCollection, currentDate).length;
  return dayLapses > 0;
}

/**
 * Checks if the date passed has any value in lapsesCollection
 * and returns the close date for that lapse.
 * @param  {[Object]} daysCollection =             []          [Collection of days.]
 * @param  {[Date]} currentDate      =             initialDate [Current date or date to validate.]
 * @return {[Date]}                                            [Close date and time.]
 */
export function getTimeLapseTimeLeft(
  daysCollection = [],
  currentDate = initialDate
) {
  const dayLapse = getLapseOfTheDate(daysCollection, currentDate)
    .get("0")
    .value();
  const hour = _.get(dayLapse, "close.hour");
  const minute = _.get(dayLapse, "close.minute");
  return moment().hour(hour).minutes(minute).toDate();
}

/**
 *
 * @param daysCollection
 * @param currentDate
 * @returns {*}
 */
export function getTimeLapseNearest(
  daysCollection = [],
  currentDate = initialDate
) {
  const dayLapsesToday = getLapsesToday(daysCollection, currentDate).value();
  const lapsesFuture = getLapsesFutureToday(dayLapsesToday);
  let lapseNearest;
  if (_.size(lapsesFuture) > 1) {
    // no terminado
    lapseNearest = lapsesFuture[0];
  } else {
    lapseNearest = lapsesFuture[0];
  }
  const hour = _.get(lapseNearest, "open.hour");
  const minute = _.get(lapseNearest, "open.minute");
  return moment().hour(hour).minutes(minute).toDate();
}

export function validateSurveyToday(
  daysCollection = [],
  currentDate = initialDate
) {
  const dayLapsesToday = getLapsesToday(daysCollection, currentDate).value();
  const lapsesFuture = getLapsesFutureToday(dayLapsesToday);
  return _.size(lapsesFuture) > 0;
}

function getLapsesFutureToday(lapsesCollection) {
  if (_.size(lapsesCollection) > 0) {
    return _.filter(lapsesCollection, (lapse) => {
      const hour = _.get(lapse, "open.hour");
      const minute = _.get(lapse, "open.minute");
      const dateClose = moment().hour(hour).minutes(minute).toDate();
      return moment(dateClose).isAfter(moment());
    });
  }
  return [];
}

export function validateProjectToday(time) {
  let validToday = validateDateProject(
    time.openDate,
    time.closeDate,
    new Date()
  );
  if (validToday) {
    const arrayLapsesWeek = time.dayWeek;
    const hasLapsesToday = validateProjectLapseDayToday(
      arrayLapsesWeek,
      Date.now()
    );
    if (hasLapsesToday) {
      const lapseToday = validateProjectLapseOfDay(arrayLapsesWeek, Date.now());
      if (!lapseToday) {
        validToday = false;
      }
    } else {
      validToday = false;
    }
  }
  return validToday;
}

/**
 * Function to validation the open and close date of a project
 * @param  {Date} projectOpenDate     Project Open Date.
 * @param  {Date} projectCloseDate    Project close date.
 * @param  {[type]} currentDate      =             initialDate [description]
 * @return {Boolean}                  Should the project be available.
 */
export function validateDateProject(
  projectOpenDate,
  projectCloseDate,
  currentDate = initialDate
) {
  const projectOpenDateParser = moment(projectOpenDate);
  const projectCloseDateParser = moment(projectCloseDate);
  return validaOpenAndCloseDate(
    projectOpenDateParser,
    projectCloseDateParser,
    currentDate
  );
}
