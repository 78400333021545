import _ from 'lodash';
import { GET_GROUPER_QUESTION_METRICS, CLEAN_GROUPER_QUESTION_METRICS } from '../constants/index';

const INITIAL_STATE = { grouper: null };

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLEAN_GROUPER_QUESTION_METRICS:
            return _.assign({}, state, { grouper: null });
        case GET_GROUPER_QUESTION_METRICS:
            return _.assign({}, state, { grouper: _.get(action, 'payload.data', null) });
        default:
            return state;
    }
}
