import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import _ from "lodash";
import {blue700} from "material-ui/styles/colors";
import FlatButton from "material-ui/FlatButton";
import {useHistory} from "react-router-dom";
import {LBLSURVEYS, idEmployeeTest} from "Labels";
import {Dialog} from "material-ui";
import {declineSurvey} from "../../actions/consents_form";
import {toggleSnackbar} from "../../actions/commons";
import {useAuth} from "../../auth/context";
import LoadingPage from "../commons/loadingPage";

const customContentStyle = {
    maxWidth: 600,
    minWidth: 300,
    textAlign: "justify",
};

let isAdmin = false;

/**
 * @param declineSurvey
 * @param toggleSnackbar
 * @param consentsArray
 * @param idProject
 * @param companyId
 * @param login
 * @param disabled
 * @returns {JSX.Element}
 * @constructor
 */
const ButtonDeclineSurvey = ({declineSurvey, toggleSnackbar, consentsArray, idProject, companyId, login, disabled}) => {
    const [open, setOpen] = useState(false)
    const [userId, setUserId] = useState('')
    const [loadingDecline, setLoadingDecline] = useState(false)
    const history = useHistory();
    const {user} = useAuth();

    const getUserProfile = ({ attributes, challengeParam }) => {
        const { "custom:Role": role, "custom:user_profile": userProfile } = attributes || {};
        const { userAttributes } = challengeParam || {};
        const { "custom:Role": challengeRole, "custom:user_profile": challengeUserProfile } = userAttributes || {};

        return role || userProfile || challengeRole || challengeUserProfile || "EMPLOYEE";
    }

    useEffect(() => {
        const userType = getUserProfile(user);
        isAdmin = !_.isEqual(userType, "EMPLOYEE");

        if (login.get("loggedIn")) {
            const profile = login.get("profile");
            setUserId(_.get(profile, "person._id"))
        } else {
            setUserId(idEmployeeTest)
            console.error("No hay un usuario logeado");
            console.error(
                "Se guardará con el empleado de prueba -> ",
                idEmployeeTest
            );
        }
    }, [login]);

    const handleCloseAction = () => {
        setOpen(false)
    }

    const handleDeclineSurvey = () => {
        setOpen(false)
        setLoadingDecline(true)
        declineSurvey(idProject, consentsArray, userId, companyId)
            .then(
                (data) => {
                    if (_.get(data, "error") || _.get(data, "payload.status") !== 200) {
                        toggleSnackbar(true, "Hubo un error");
                    } else if (isAdmin) {
                        history.push("/admin/me/projects");
                    } else {
                        toggleSnackbar(
                            true,
                            "Encuesta rechazada"
                        );
                        history.push("/dashboard/employee/projects");
                    }
                    setLoadingDecline(false)
                },
                () => {
                    toggleSnackbar(
                        true,
                        "Hubo un error al guardar los consentimientos"
                    );
                }
            )
            .catch(() => {
                setLoadingDecline(false)
                toggleSnackbar(
                    true,
                    "Hubo un error al guardar los consentimientos"
                );
            });
    }

    const handleOpenAction = () => {
        setOpen(true)
    }

    const actions = [
        <FlatButton
            label={LBLSURVEYS.buttons.cancel}
            secondary
            onClick={handleCloseAction}
        />,
        <FlatButton
            label={LBLSURVEYS.buttons.acept}
            primary
            keyboardFocused
            onClick={handleDeclineSurvey}
        />,
    ];

    return (
        <span>
        <FlatButton
            label={LBLSURVEYS.msg.declineSurveyConfirm}
            secondary
            disabled={disabled || loadingDecline}
            onClick={handleOpenAction}
        />
            {loadingDecline ? <LoadingPage visible size={80}/> :
                <Dialog
                    title={LBLSURVEYS.confirm}
                    actions={actions}
                    modal={false}
                    titleStyle={{textAlign: "center", color: blue700}}
                    contentStyle={customContentStyle}
                    autoScrollBodyContent
                    open={open}
                    onRequestClose={handleCloseAction}
                >
                    <h3 style={{fontWeight: 200, textAlign: "justify"}}>
                        {LBLSURVEYS.msg.confirmDeclineSurvey}
                    </h3>
                    <h3 style={{fontWeight: 200, textAlign: "justify"}}>
                        {LBLSURVEYS.msg.rememberDecline}
                    </h3>
                </Dialog>
            }
      </span>
    )
};

ButtonDeclineSurvey.propTypes = {
    idProject: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    consentsArray: PropTypes.array.isRequired,
    companyId: PropTypes.string.isRequired,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            declineSurvey,
            toggleSnackbar,
        },
        dispatch
    );
}

function mapStateToProps({login}) {
    return {
        login,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ButtonDeclineSurvey);
