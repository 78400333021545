import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {RadioButton, RadioButtonGroup} from 'material-ui/RadioButton';
import _ from 'lodash';
import {connect} from 'react-redux';
import Title from './Title';

const styleWidthContent = {
    minWidth: 240
};

class QuestionClose extends Component {

    constructor(props) {
        super(props);
        this._onChange = this._onChange.bind(this);
    }

    _onChange(e, value) {
        this.props._handlerUpdateValue(e, value);
    }

    render() {
        const self = this;
        const {
            question,
            index,
            valueQuestion
        } = this.props;
        return (
            <div style={styleWidthContent}>
                <Title question={_.has(question, 'fieldName') ? question : _.get(question, '_id')} index={index}/>
                <RadioButtonGroup
                    name={question.title}
                    style={{display: 'block'}}
                    onChange={this._onChange}
                    valueSelected={valueQuestion}
                >
                    <RadioButton
                        value="Si"
                        label="Si"
                        style={{
                            marginBottom: 16,
                            display: 'inline-block',
                            width: '50%',
                            float:"left"
                        }}
                    />
                    <RadioButton
                        value="No"
                        label="No"
                        style={{
                            marginBottom: 16,
                            display: 'inline-block',
                            width: '50%'
                        }}
                    />
                </RadioButtonGroup>
            </div>
        );
    }
}

QuestionClose.propTypes = {
    question: PropTypes.object.isRequired
};

function mapStateToProps({ questionsSurvey }, ownerProps) {
    return {
        valueQuestion: _.get(questionsSurvey.get('answered').toObject(), _.get(ownerProps, 'idQuestion'))
    };
}

export default connect(mapStateToProps)(QuestionClose);
