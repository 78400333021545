import {
  OPEN_MODAL_FORM_GROUPER,
  CLOSE_MODAL_FORM_GROUPER,
  SAVE_NAME_GROUPER_GROUPER,
} from "./constants";
import { axiosApiV2 } from "../../middleware/api";

export function openModalForm(id, name, visible, priority) {
  return {
    type: OPEN_MODAL_FORM_GROUPER,
    meta: {
      id,
      name,
      visible,
      priority,
    },
  };
}

export function closeModal() {
  return {
    type: CLOSE_MODAL_FORM_GROUPER,
  };
}

export function saveNameGrouper(id, name, visible, priority) {
  const request = axiosApiV2.put(`/surveygroupers/grouper/${id}`, {
    grouper: {
      name,
      visible,
      priority,
    },
  });

  return {
    type: SAVE_NAME_GROUPER_GROUPER,
    payload: request,
    meta: {
      id,
      name,
      visible,
      priority,
    },
  };
}
