import PropTypes from "prop-types";
import React, { Component } from "react";
import { reduxForm, addArrayValue } from "redux-form";
import { Row, Col } from "react-flexbox-grid";
import Paper from "material-ui/Paper";
import RaisedButton from "material-ui/RaisedButton";
import FlatButton from "material-ui/FlatButton";
import IconButton from "material-ui/IconButton";
import UpIcon from "material-ui/svg-icons/hardware/keyboard-arrow-up";
import DownIcon from "material-ui/svg-icons/hardware/keyboard-arrow-down";
import DeleteIcon from "material-ui/svg-icons/action/delete";
import _ from "lodash";
import { updateColorsProject } from "../actions/projects_form";
import { FetchProjectColorsParam } from "../actions/info/projects_info_form";
import PureInputColorPicker from "../components/PureInputColorPicker";
import { toggleSnackbar } from "../actions/commons";
import ButtonBack from "../components/fields/buttonBack/buttonBack";
import AuthComponent from "../components/AuthComponent";

let urlBack = "";

class ColorsProjectNews extends Component {
  constructor(props) {
    super(props);
    this._saveColorsProject = this._saveColorsProject.bind(this);
    this.state = {
      colorsSelected: "#1976d2",
    };
  }

  UNSAFE_componentWillMount() {
    const { FetchProjectColorsParam, profile, params } = this.props;
    if (!_.isEmpty(profile)) {
      this.state = {
        isReferee: _.isEqual(_.get(profile, "role"), "referee"),
      };
    }
    urlBack = `/admin/projects/info/${_.get(params, "profile")}/${_.get(
      params,
      "project"
    )}/${_.get(params, "company")}`;
    FetchProjectColorsParam(_.get(params, "project"));
  }

  _saveColorsProject(formData) {
    const { updateColorsProject, resetForm, toggleSnackbar, params, history } =
      this.props;
    updateColorsProject(formData, _.get(params, "project"))
      .then(
        data => {
          if (_.get(data, "error") || _.get(data, "payload.status") !== 200) {
            toggleSnackbar(true, "Hubo un error al actualizar los colores");
          } else {
            resetForm();
            toggleSnackbar(
              true,
              "Se guardó correctamente los colores para el proyecto"
            );
            history.push(urlBack);
          }
        },
        reason => {
          toggleSnackbar(true, "Hubo un error al actualizar los colores");
        }
      )
      .catch(data => {
        toggleSnackbar(true, "Hubo un error al actualizar los colores");
      });
  }

  render() {
    const {
      asyncValidating,
      fields: { colors },
      handleSubmit,
      resetForm,
      params,
    } = this.props;

    return (
      <Row>
        <AuthComponent
          component={
            <Col
              xs={10}
              xsOffset={1}
              smOffset={3}
              sm={6}
              mdOffset={3}
              md={6}
              lgOffset={3}
              lg={6}
            >
              <Paper style={{ marginTop: 20, marginBottom: 20 }}>
                <Row>
                  <Col xs>
                    <Row>
                      <Col xs>
                        <h1
                          style={{
                            textAlign: "center",
                            fontWeight: 400,
                            marginTop: 30,
                            lineHeight: 1.3,
                          }}
                        >
                          Definir colores para el proyecto
                        </h1>
                        <ButtonBack url={urlBack} />
                      </Col>
                    </Row>
                    <form onSubmit={handleSubmit(this._saveColorsProject)}>
                      <Row
                        style={{
                          marginTop: 50,
                          padding: 20,
                        }}
                      >
                        <Col xs={10} xsOffset={1}>
                          {!colors.length && (
                            <h3
                              style={{
                                marginTop: 30,
                                textAlign: "center",
                              }}
                            >
                              No hay valores
                            </h3>
                          )}
                          {colors.map((color, index) => (
                            <Row key={index}>
                              <Col xs={2}>
                                <div
                                  style={{
                                    marginTop: -13,
                                    textAlign: "right",
                                  }}
                                >
                                  <PureInputColorPicker field={color.value} />
                                </div>
                              </Col>
                              <Col xs={10}>
                                <div>
                                  <IconButton
                                    children={<UpIcon />}
                                    disabled={index === 0}
                                    tooltip="Subir"
                                    tooltipPosition="top-center"
                                    onClick={() => {
                                      colors.swapFields(index, index - 1);
                                    }}
                                  />
                                  <IconButton
                                    children={<DownIcon />}
                                    disabled={index === colors.length - 1}
                                    tooltip="Bajar"
                                    tooltipPosition="top-center"
                                    onClick={() => {
                                      colors.swapFields(index, index + 1);
                                    }}
                                  />
                                  <IconButton
                                    children={<DeleteIcon />}
                                    tooltip="Eliminar"
                                    tooltipPosition="top-center"
                                    onClick={() => {
                                      colors.removeField(index);
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                          ))}
                        </Col>
                      </Row>
                      <Row>
                        <Col xsOffset={1} xs={10}>
                          <div>
                            <FlatButton
                              label={`Agregar color ${colors.length + 1}`}
                              secondary
                              style={{
                                margin: 20,
                                float: "right",
                              }}
                              onClick={() => {
                                colors.addField({
                                  value: this.state.colorsSelected,
                                });
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                      <AuthComponent
                        component={
                          <RaisedButton
                            type="submit"
                            label="Modificar"
                            secondary
                            style={{
                              margin: 20,
                              float: "right",
                            }}
                          />
                        }
                        permission="backend/answer:write"
                        type="url"
                      />
                    </form>
                  </Col>
                </Row>
              </Paper>
            </Col>
          }
          permission="backend/answer:write"
          type="url"
        />
      </Row>
    );
  }
}

const requireFields =
  (...names) =>
  data =>
    names.reduce((errors, index) => {
      if (!data[index]) {
        errors[index] = "Es requerido";
      }
      return errors;
    }, {});

const validateOptions = requireFields("value");

const validate = values => {
  const errors = {};
  errors.colors = values.colors.map(validateOptions);

  return errors;
};

function mapStateToProps({ projects, login }, ownProps) {
  return {
    profile: login.get("profile"),
    initialValues: {
      colors: _.map(_.get(projects, "projectColors", []), color => ({
        value: color,
      })),
    },
  };
}

ColorsProjectNews.propTypes = {
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  router: PropTypes.object,
};

export default reduxForm(
  {
    form: "ColorsProjectNewsForm",
    fields: ["colors[].value"],
    validate,
  },
  mapStateToProps,
  { toggleSnackbar, FetchProjectColorsParam, updateColorsProject }
)(ColorsProjectNews);
