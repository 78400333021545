import React, {Component} from 'react';
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn,TableFooter} from 'material-ui/Table';





import {Row, Col} from 'react-flexbox-grid';
import Paper from 'material-ui/Paper';

import TextField from 'material-ui/TextField';
import Toggle from 'material-ui/Toggle';
import RaisedButton from 'material-ui/RaisedButton';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FetchEmployeeParam } from '../../actions/info/employees_info_form';

const style = {
        margin: 20,
        float: 'right'
    };
    const stylePaper = {
        marginTop: 20,
        marginBottom: 20
    };
    const styleTable = {
        margin: 'auto',
        width: '90%'
    };
    const styleButton = {
        margin: 12,
    };

const stylePopover = {
    padding: 20,
};

class EmployeesInfo extends Component {
    UNSAFE_componentWillMount(){
        this.props.FetchEmployeeParam(this.props.params._id, this.props.params.id);
    }

    renderEmployee(){
        if(this.props.employees.data){
            return (
                <table style={styleTable}>
                    <tbody>
                        <tr>
                            <td colSpan='3'>
                                <h1 style={{textAlign: 'center', fontWeight: 400}}> IAS </h1>
                            </td>
                        </tr>
                        <tr>
                            <td><strong>NIT</strong></td>
                            <td colSpan='2'>{this.props.params._id}</td>
                        </tr>
                        <tr>
                            <td><strong>Teléfono</strong></td>
                            <td colSpan='2'>{this.props.employees.data.name}</td>
                        </tr>
                        <tr>
                            <td><strong>Correo electrónico</strong></td>
                            <td colSpan='2'>{this.props.employees.data.lasName}</td>
                        </tr>
                        <tr>
                            <td>
                                <RaisedButton label="Actualizar Empleados" secondary style={styleButton} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            );
        }
    }

    render() {
        return (
          <Row>
              <Col xsOffset={2} xs={8}>
                  <Paper style={stylePaper}>
                      <Row>
                          <Col xs>
                              {this.renderEmployee()}
                          </Col>
                      </Row>
                  </Paper>
              </Col>
          </Row>
        );
    }
}

function mapStateToProps(state){
    return  { employees: state.employees.all };
}

export default connect (mapStateToProps, { FetchEmployeeParam })(EmployeesInfo);
