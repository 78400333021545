import Immutable from 'immutable';
import _ from 'lodash';
import { CLEAN_RESULTS_EMPLOYEE_FOR_REFEREE, SEARCH_RESULTS_EMPLOYEE_FOR_REFEREE, LOAD_RESULTS_EMPLOYEE_FOR_REFEREE } from './constants';

const INITIAL_STATE = Immutable.Map({
    result: null,
    inSearch: false
});

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SEARCH_RESULTS_EMPLOYEE_FOR_REFEREE:
            /* const results = _.get(action, 'payload.data', {});
            const noEvalQuestions = _.get(results, 'questions', []);
            const comment = _.get(results, 'comment', '');
            const solutionId = _.get(results, 'solutionId', '');
            const sumQuestions = _
                .chain(results)
                .get('tree', {})
                .value();
            const freqQuestions = _
                .chain(results)
                .get('results')
                .filter(item => {
                    const type = _.get(item, 'type');
                    return _.isEqual(type, 'freq');
                })
                .map(item => {
                    const results = _
                        .chain(item)
                        .get('result')
                        .reduce(
                            (detail, value, key) => {
                                detail.push({key: key, value: value});
                                return detail;
                            },
                            []
                        )
                        .value();
                    return _.set(item, 'result', results);
                })
                .value();
            return state.withMutations(map => {
                map.set("solutionId", solutionId);
                map.set("freq", freqQuestions);
                map.set("sum", sumQuestions);
                map.set("notEval", noEvalQuestions);
                map.set("raw", results);
                map.set("comment", comment);
            }); */
            return state.withMutations(s => {
                s.set("inSearch", false);
                s.set("result", _.get(action, 'payload.data', null));
            });
        case LOAD_RESULTS_EMPLOYEE_FOR_REFEREE:
            return state.set("inSearch", _.get(action, 'meta.inSearch', false));
        case CLEAN_RESULTS_EMPLOYEE_FOR_REFEREE:
            return state.withMutations(s => {
                s.set("result", null);
                s.set("inSearch", false);
            });
        default:
            return state;
    }
};
