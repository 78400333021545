import axios from 'axios';
import _ from 'lodash';
import { DOWNLOAD_FILE } from '../../../constants/index';
import { axiosAPI } from '../../../middleware/api';

export function downloadFile(store, id, email) {

    const url = _.isUndefined(id)
                ? `/${store}/file/${email}`
                : `/${store}/file/${id}/${email}`;

    const request = axiosAPI.get(url, {responseType: 'json'});

    return {
        type: DOWNLOAD_FILE,
        payload: request
    }
}
