import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {reduxForm} from 'redux-form';
import {Row, Col} from 'react-flexbox-grid';
import Paper from 'material-ui/Paper';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import PureInput from '../components/PureInput';
import FlatButton from 'material-ui/FlatButton';
import MenuItem from 'material-ui/MenuItem';
import IconButton from 'material-ui/IconButton';
import UpIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-up';
import DownIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-down';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import {createQuestion} from '../actions/questions_form';
import {loadListMaster} from '../actions/listMaster_form';
import {FetchQuestionParam} from '../actions/info/questions_info_form';
import {bindActionCreators} from 'redux';
import {EXPRESSION_ESPECIALS} from '../constants/regularExpressions';
import {axiosAPI} from '../middleware/api';
import _ from 'lodash';
import {toggleSnackbar} from '../actions/commons';
import FecthListValues from '../components/fields/FetchListValues/FetchListValues';
import {LBLQUESTIONSGROUP, LBLQUESTIONS} from '../constants/labels';
import ButtonBack from '../components/fields/buttonBack/buttonBack';
import SelectFieldFormat from '../components/fields/SelectFieldFormat/SelectFieldFormat';
import AuthComponent from '../components/AuthComponent';

const style = {
        margin: 20,
        float: 'right'
    },
    stylePaper = {
        marginTop: 20,
        marginBottom: 20
    };

class QuestionsGroupNews extends Component {

    constructor(props) {
        super(props);
        this._saveQuestions = this._saveQuestions.bind(this);
        this.getValueListState = this.getValueListState.bind(this);
        this.state = {
            listName: ''
        };
    }

    UNSAFE_componentWillMount() {
        this.props.loadListMaster();
        if (_.has(this, 'props.params._id')) {
            this.props.FetchQuestionParam(this.props.params._id);
        }
    }

    _saveQuestions(formData) {
        const {
            createQuestion,
            toggleSnackbar,
            resetForm,
            params
        } = this.props;
        const editMode = _.has(params, '_id');
        createQuestion(formData, editMode)
            .then((data) => {
                if (_.get(data, 'error') || (_.get(data, 'payload.status') !== 200)) {
                    toggleSnackbar(true, LBLQUESTIONSGROUP.msg.errorSave);
                } else {
                    resetForm();
                    toggleSnackbar(true, LBLQUESTIONSGROUP.msg.successSave);
                    window.location.replace(`/admin/questions/grid`)
                }
            }, (reason) => {
                toggleSnackbar(true, LBLQUESTIONSGROUP.msg.errorSave);
            })
            .catch((data) => {
                toggleSnackbar(true, LBLQUESTIONSGROUP.msg.errorSave);
            });
    }

    renderOptionList(list) {
        if (_.size(list)) {
            if (list instanceof Array) {
                return list.map((option) => {
                    return (
                        <MenuItem key={option._id} value={option} primaryText={option.alias}/>
                    );
                });
            }
        }
    }

    getValueListState(editMode, valueSource) {
        if (editMode && _.isEqual(this.state.listName, "")) {
            this.setState({
                listName: valueSource
            });
        }
    }

    render() {
        const {
            fields: {
                fieldName,
                type,
                title,
                valueSource,
                _id,
                help,
                list,
                listClose,
                itemList
            },
            handleSubmit,
            params
        } = this.props;
        const editMode = _.has(params, '_id');
        if (_.get(this, 'props.listmaster.data')) {
            return (
                <Row>
                    {this.getValueListState(editMode, valueSource.value)}
                    <Col xs={12} mdOffset={2} md={8}>
                        <Paper style={stylePaper}>
                            <div>
                                <Row>
                                    <Col xsOffset={2} xs={8}>
                                        <ButtonBack url={`/admin/questions/grid`}/>
                                        <h1 style={{
                                            textAlign: 'center',
                                            fontWeight: 400
                                        }}>{editMode ? LBLQUESTIONSGROUP.titleEdit : LBLQUESTIONSGROUP.titleCreate}</h1>
                                        <form onSubmit={handleSubmit(this._saveQuestions)}>
                                            <Row>
                                                <Col xs>
                                                    <TextField
                                                        hintText=""
                                                        floatingLabelText={LBLQUESTIONSGROUP.code}
                                                        type="text"
                                                        disabled={editMode}
                                                        errorText={fieldName.touched && fieldName.error ? fieldName.error : '' }
                                                        {...fieldName}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12}>
                                                    <TextField
                                                        hintText=""
                                                        floatingLabelText={LBLQUESTIONS.statement}
                                                        type="text"
                                                        disabled={editMode}
                                                        multiLine={true}
                                                        fullWidth={true}
                                                        rows={1}
                                                        errorText={title.touched && title.error ? title.error : '' }
                                                        {...title}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs>
                                                    <TextField
                                                        hintText=""
                                                        floatingLabelText={LBLQUESTIONSGROUP.helpText}
                                                        type="text"
                                                        multiLine={true}
                                                        fullWidth={true}
                                                        rows={3}
                                                        errorText={help.touched && help.error ? help.error : '' }
                                                        {...help}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs>
                                                    <SelectFieldFormat
                                                        config={{
                                                            floatingLabelText: LBLQUESTIONSGROUP.type,
                                                            value: type.value,
                                                            disabled: editMode,
                                                            errorText: type.touched && type.error ? type.error : '',
                                                            onChange: (e, index, value) => type.onChange(value)
                                                        }}
                                                    >
                                                        <MenuItem value={"GroupList"}
                                                                  primaryText={LBLQUESTIONS.arrTypesQuestions[8]}/>
                                                        <MenuItem value={"Group"}
                                                                  primaryText={LBLQUESTIONS.arrTypesQuestions[9]}/>
                                                    </SelectFieldFormat>
                                                </Col>
                                                <Col xs style={{paddingTop: 5}}>
                                                    <h3 style={{
                                                        fontWeight: 200,
                                                        marginBottom: 30,
                                                        display: (editMode && _.isEqual(type.value, 'GroupList')) ? 'block' : 'none'
                                                    }}>
                                                        Lista de valores correspondiente</h3>
                                                    <FecthListValues
                                                        nameList={this.state.listName}
                                                        styleList={itemList.value}
                                                        visible={(editMode && _.isEqual(type.value, 'GroupList')) ? true : false }
                                                    />
                                                </Col>
                                            </Row>
                                            <Row style={{
                                                marginTop: 30,
                                                marginBottom: 30,
                                                display: _.isEqual(type.value, 'Group') ? 'block' : 'none'
                                            }}>
                                                <Col xs={10} xsOffset={2}>
                                                    {!list.length &&
                                                    <h3 style={{marginTop: 30, textAlign: 'center'}}>No hay
                                                        valores</h3>}
                                                    {list.length > 0 &&
                                                    <h3 style={{marginTop: 30, textAlign: 'center'}}>Lista de valores
                                                        asociados a la pregunta</h3>}
                                                    {list.map((val, index) =>
                                                        <Row key={index}>
                                                            <Col xs>
                                                                <h3 style={{fontWeight: 200}}>{val.alias.value}</h3>
                                                            </Col>
                                                            <Col xs>
                                                                <div style={{display: editMode ? 'none' : 'block'}}>
                                                                    <IconButton
                                                                        children={<UpIcon/>}
                                                                        disabled={index === 0 || editMode}
                                                                        tooltip={LBLQUESTIONSGROUP.tooltips.btnUp}
                                                                        tooltipPosition="top-center"
                                                                        onClick={() => {
                                                                            list.swapFields(index, index - 1);
                                                                        }}
                                                                    />
                                                                    <IconButton
                                                                        children={<DownIcon/>}
                                                                        disabled={index === list.length - 1 || editMode}
                                                                        tooltip={LBLQUESTIONSGROUP.tooltips.btnDown}
                                                                        tooltipPosition="top-center"
                                                                        onClick={() => {
                                                                            list.swapFields(index, index + 1);
                                                                        }}
                                                                    />
                                                                    <IconButton
                                                                        children={<DeleteIcon/>}
                                                                        disabled={editMode}
                                                                        tooltip={LBLQUESTIONSGROUP.tooltips.btnDelete}
                                                                        tooltipPosition="top-center"
                                                                        onClick={() => {
                                                                            list.removeField(index);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    )}
                                                </Col>
                                            </Row>
                                            <Row style={{
                                                marginTop: 30,
                                                marginBottom: 30,
                                                display: _.isEqual(type.value, 'GroupClose') || _.isEqual(type.value, 'GroupList') ? 'block' : 'none'
                                            }}>
                                                <Col xs={10} xsOffset={2}>
                                                    {!listClose.length && <h3 style={{
                                                        marginTop: 30,
                                                        textAlign: 'center'
                                                    }}>{LBLQUESTIONSGROUP.noValues}</h3>}
                                                    {listClose.map((val, index) =>
                                                        <Row key={index}>
                                                            <Col>
                                                                <PureInput
                                                                    floatingLabelText={LBLQUESTIONSGROUP.statement}
                                                                    type="text"
                                                                    disabled={editMode}
                                                                    errorText={val.enunciate.touched && val.enunciate.error ? val.enunciate.error : '' }
                                                                    field={val.enunciate}
                                                                    multiLine={true}
                                                                    rows={1}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <div style={{display: editMode ? 'none' : 'block'}}>
                                                                    <IconButton
                                                                        children={<UpIcon/>}
                                                                        disabled={index === 0 || editMode}
                                                                        tooltip={LBLQUESTIONSGROUP.tooltips.btnUp}
                                                                        tooltipPosition="top-center"
                                                                        onClick={() => {
                                                                            listClose.swapFields(index, index - 1);
                                                                        }}
                                                                    />
                                                                    <IconButton
                                                                        children={<DownIcon/>}
                                                                        disabled={index === listClose.length - 1 || editMode}
                                                                        tooltip={LBLQUESTIONSGROUP.tooltips.btnDown}
                                                                        tooltipPosition="top-center"
                                                                        onClick={() => {
                                                                            listClose.swapFields(index, index + 1);
                                                                        }}
                                                                    />
                                                                    <IconButton
                                                                        children={<DeleteIcon/>}
                                                                        disabled={editMode}
                                                                        tooltip={LBLQUESTIONSGROUP.tooltips.btnDelete}
                                                                        tooltipPosition="top-center"
                                                                        onClick={() => {
                                                                            listClose.removeField(index);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    )}
                                                </Col>
                                            </Row>
                                            <Row
                                                style={{display: _.isEqual(type.value, 'GroupClose') || _.isEqual(type.value, 'GroupList') ? 'block' : 'none'}}>
                                                <Col xs>
                                                </Col>
                                                <Col xs>
                                                    <div>
                                                        <FlatButton
                                                            label={LBLQUESTIONSGROUP.buttons.addStatement}
                                                            secondary={true}
                                                            disabled={editMode}
                                                            style={{
                                                                marginTop: 20,
                                                                marginBottom: 20,
                                                                display: editMode ? 'none' : 'block'
                                                            }}
                                                            onClick={() => {
                                                                listClose.addField();
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs
                                                     style={{display: (_.isEqual(type.value, 'Group') || _.isEqual(type.value, 'GroupList')) && !editMode ? 'block' : 'none'}}>
                                                    <SelectFieldFormat
                                                        config={{
                                                            floatingLabelText: LBLQUESTIONSGROUP.groupList,
                                                            value: valueSource.value,
                                                            disabled: editMode,
                                                            autoWidth: true,
                                                            fullWidth: true,
                                                            errorText: valueSource.touched && valueSource.error ? valueSource.error : '',
                                                            onChange: (e, index, value) => {
                                                                valueSource.onChange(value);
                                                                this.setState({listName: value.nameList});
                                                                if (_.isEqual(type.value, 'Group')) {
                                                                    let exit = true;
                                                                    list.map((val) => {
                                                                        if (_.isEqual(val._id.value, value._id)) {
                                                                            exit = false;
                                                                        }
                                                                    });
                                                                    if (exit) {
                                                                        list.addField({
                                                                            _id: value._id,
                                                                            alias: value.alias,
                                                                            nameList: value.nameList
                                                                        });
                                                                    }
                                                                }
                                                            }
                                                        }}
                                                    >
                                                        {this.renderOptionList(this.props.listmaster.data)}
                                                    </SelectFieldFormat>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs>
                                                    <FecthListValues
                                                        nameList={this.state.listName}
                                                        styleList={itemList.value}
                                                        visible={(_.isEqual(type.value, 'Group') || _.isEqual(type.value, 'GroupList')) && !editMode }
                                                    />
                                                    <div
                                                        style={{display: (type.value === 'Group' || type.value === 'GroupList') && this.state.listName != "" ? 'block' : 'none'}}>
                                                        <SelectFieldFormat
                                                            config={{
                                                                floatingLabelText: LBLQUESTIONS.itemListSelectable,
                                                                value: itemList.value,
                                                                autoWidth: true,
                                                                errorText: itemList.touched && itemList.error ? itemList.error : '',
                                                                onChange: (e, index, value) => itemList.onChange(value)
                                                            }}
                                                        >
                                                            <MenuItem value={'none'}
                                                                      primaryText={LBLQUESTIONS.arrItemList[0]}/>
                                                            <MenuItem value={'numeric'}
                                                                      primaryText={LBLQUESTIONS.arrItemList[1]}/>
                                                            <MenuItem value={'lowerCase'}
                                                                      primaryText={LBLQUESTIONS.arrItemList[2]}/>
                                                            <MenuItem value={'upperCase'}
                                                                      primaryText={LBLQUESTIONS.arrItemList[3]}/>
                                                        </SelectFieldFormat>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <AuthComponent
                                                component={<RaisedButton
                                                    type="submit"
                                                    label={editMode ? LBLQUESTIONSGROUP.buttons.update : LBLQUESTIONSGROUP.buttons.save }
                                                    disabled ={type.value === (null ||""||undefined)}
                                                    secondary={true}
                                                    style={style}
                                                />}
                                                permission="backend/answer:write"
                                                type={"component"}
                                            />
                                        </form>
                                    </Col>
                                </Row>
                            </div>
                        </Paper>
                    </Col>
                </Row>
            );
        } else {
            return (<div></div>);
        }
    }
}

const requireFields = (...names) =>
    data =>
        names.reduce((errors, index) => {
            if (!data[index]) {
                errors[index] = LBLQUESTIONSGROUP.validations.isRequired;
            }
            return errors;
        }, {});

const validateEnunciate = requireFields('enunciate');

const validate = (values) => {
    const errors = {};
    if (!values.fieldName) {
        errors.fieldName = LBLQUESTIONSGROUP.validations.code;
    } else {
        if (EXPRESSION_ESPECIALS.test(values.fieldName)) {
            errors.fieldName = LBLQUESTIONSGROUP.validations.codeValue;
        }
    }
    if (!values.type) {
        errors.type = LBLQUESTIONSGROUP.validations.type;
    } else {
        if (values.type === 'Group') {
            if (!values.valueSource) {
                errors.valueSource = LBLQUESTIONSGROUP.validations.validateListQuestion;
            }
        }
        if (values.type === 'GroupList') {
            if (!values.valueSource) {
                errors.valueSource = LBLQUESTIONSGROUP.validations.validateList;
            }
        }
    }
    if (!values.title) {
        errors.title = LBLQUESTIONSGROUP.validations.statement;
    }
    errors.listClose = values.listClose.map(validateEnunciate);
    return errors;
};

// const asyncValidate = (values) => {
//     return new Promise((resolve, reject) => {
//         axiosAPI.get(`/questions/id`, {
//             params: {fieldName: values.fieldName}
//         })
//             .then((data) => {
//                 let resp = _.get(data.data, 'data');
//                 if (!_.isNull(resp)) {
//                     if (_.isEqual(values._id, '')) {
//                         // resolve();
//                         reject({fieldName: LBLQUESTIONS.validations.codeExist});
//                     } else {
//                         resolve();
//                     }
//                 } else {
//                     resolve();
//                 }
//             })
//             .catch((data) => {
//                 console.log('Error', data);
//             });
//     });
// };

function mapStateToProps({listmaster, questions}, ownProps) {
    if (_.has(ownProps, 'params._id')) {
        let question = questions.all.data;
        let qt;
        if (_.get(question, 'type')) {
            let valueSourceString =
                _.isEqual(_.get(question, 'type'), "Group")
                    ? 'valueSource.group.name' : _.isEqual(_.get(question, 'type'), "GroupList")
                    ? 'valueSource.groupList.name' : 'valueSource.groupClose.name';
            let valueForm = _.isEqual(_.get(question, 'type'), "Group") ? 'list' : 'listClose';
            qt = _.chain(question)
                .set('valueSource', _.isUndefined(_.get(question, 'valueSource.list.name')) ? _.get(question, 'valueSource') : _.get(question, 'valueSource.list.name'))
                .set('_id', _.get(ownProps, 'params._id'))
                .set('itemList', _.has(question, 'itemList') ? _.get(question, 'itemList') : 'none')
                .set(valueForm, _.get(question, valueSourceString))
                .value();
        }
        return {
            listmaster: listmaster.all,
            initialValues: qt
        };
    } else {
        return {
            listmaster: listmaster.all,
            initialValues: {
                valueSource: '',
                help: '',
                fieldName: '',
                type: '',
                _id: '',
                title: '',
                itemList: 'none',
            }
        };
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        createQuestion,
        loadListMaster,
        FetchQuestionParam,
        toggleSnackbar
    }, dispatch);
}

QuestionsGroupNews.propTypes = {
    router: PropTypes.object
};

export default reduxForm({
    form: 'QuestionsNewForm',
    fields: [
        'fieldName',
        'type',
        'title',
        'valueSource',
        'help',
        '_id',
        'itemList',
        'list[]._id',
        'list[].alias',
        'list[].nameList',
        'listClose[].enunciate'
    ],
    // asyncValidate,
    asyncBlurFields: ['fieldName'],
    validate
}, mapStateToProps, mapDispatchToProps)(QuestionsGroupNews);
