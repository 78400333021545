import { axiosAPI } from '../../../middleware/api';

export const GET_SURVEY_ADVANCE = "GET_SURVEY_ADVANCE";

/**
 *
 * @param idSurvey
 * @param idProject
 * @param idEmployee
 */
export function getSurveyAdvance(idProject, idSurveys, idEmployee) {
    const request = axiosAPI.get(`/solutions/${idProject}/${idSurveys}/${idEmployee}`);

    return {
        type: GET_SURVEY_ADVANCE,
        payload: request
    };
}
