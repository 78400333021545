import React from "react";
import {Row, Col} from 'react-flexbox-grid';
import {
    AppBar,
    Tabs,
    Tab,
    Button,
    makeStyles,
    Toolbar,
    Typography,
    Paper
} from '@material-ui/core';
import DetailTab from "./detailTab";
import ColumnsTab from "./columns/columnsTab";
import TabFilter from "./filters/filtersTab";
import ButtonBack from "../../../fields/buttonBack/buttonBack";
import {useArchiveDataset, useGetDataset, usePublishDatasetVersion} from "../../data"
import Select from 'react-select';
import AlertDialog from "../../../commons/alert-dialog";
import {useNotifications} from "Notifications";
import {useHistory} from "react-router-dom"

const useStyles = makeStyles(theme => ({
    postButton: {
        marginLeft: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    select: {
        width: "100px"
    },
    versionsLabel: {
        marginRight: theme.spacing(1)
    }
}))

type DatasetDetailProps = {
    match: any
}

/**
 * Component to show the detail of the dataset
 * @modified Jhoan Lopez <jhoan.lopez@ias.com.co>
 * @param props
 * @constructor
 */
const DatasetDetail:React.FC<DatasetDetailProps> = ({match}) => {
    const classes = useStyles()
    const [value, setValue] = React.useState(0)
    const {data} = useGetDataset({datasetId: match.params.id});
    const [open, setOpen] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [mutate, {loading: loadingPublish}] = usePublishDatasetVersion();
    const {mutate: archive, loading: loadingArchive} = useArchiveDataset();
    const {sendNotification} = useNotifications();
    const history = useHistory();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpenDelete = () => {
        setOpenDelete(true);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const colourOptions = [
        {value: '0', label: '0'},
    ];

    const handlePublish = async () => {
        await mutate({variables: {request: {datasetId: match.params.id}}})
            .then(() => {
                handleClose()
                sendNotification('Dataset publicado correctamente');
            })
            .catch(() => {
                sendNotification('Ocurrió un error al publicar el dataset');
            })
    }

    const handleDelete = async () => {
        await archive({variables: {request: {datasetId: match.params.id}}})
            .then(() => {
                handleCloseDelete()
                sendNotification('Dataset eliminado correctamente');
                history.push({
                    pathname: `/admin/analytics/dataset/main`
                });
            })
            .catch(() => {
                sendNotification('Ocurrió un error al eliminar el dataset');
            })
    }

    return (
        <div>
            <Row>
                <Col xsOffset={1} xs={10}>
                    <ButtonBack url="/admin/analytics/dataset/main"/>
                </Col>
            </Row>

            <Row>
                <Col xsOffset={1} xs={10}>
                    <Paper style={{marginTop: 20, marginBottom: 20}}>
                        <AppBar position="static" color="default">
                            <Toolbar>
                                <Typography variant="h6" className={classes.title}>
                                    Dataset
                                </Typography>
                                <Typography variant="caption" className={classes.versionsLabel}>
                                    Versión
                                </Typography>
                                <Select
                                    className={classes.select}
                                    classNamePrefix="select"
                                    name="color"
                                    defaultValue={colourOptions[0]}
                                    options={colourOptions}
                                />
                                <Button
                                    size="small"
                                    color="secondary"
                                    variant="outlined"
                                    className={classes.postButton}
                                    onClick={handleClickOpen}
                                >
                                    Publicar
                                </Button>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    className={classes.postButton}
                                    onClick={handleClickOpenDelete}
                                >
                                    Eliminar
                                </Button>
                            </Toolbar>
                        </AppBar>
                        <AppBar position="static" color="default">
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                            >
                                <Tab label="Detalle"/>
                                <Tab label="Filtros"/>
                                <Tab label="Columnas"/>

                            </Tabs>
                        </AppBar>
                        {
                            value === 0 ? <DetailTab item={data?.getDataset.item}/> :
                                value === 1 ?
                                    <TabFilter item={data?.getDataset.item}/>
                                    : value === 2 ?
                                        <ColumnsTab item={data?.getDataset.item}/> : null
                        }
                    </Paper>
                </Col>
                <AlertDialog title="¿Está seguro de publicar el dataset?" description="" open={open}
                             handleClose={handleClose} handleAccept={handlePublish} disabled={loadingPublish}/>
                <AlertDialog title="¿Está seguro de eliminar el dataset?" description="" open={openDelete} handleClose={handleCloseDelete}
                             handleAccept={handleDelete} disabled={loadingArchive}/>
            </Row>
        </div>
    );


};
export default DatasetDetail;
