import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {useNotifications} from "Notifications";
import {useHistory} from "react-router-dom"
import Index from "../../commons/alert-dialog";
import operations from "../../../containers/users/http";
import InformativeDialog from "./InformativeDialog";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: "10%",
        }
    }),
);

type UserActionsProps = {
    data: any
}

const UserActions: React.FC<UserActionsProps> = ({data}) => {
    const classes = useStyles();
    const [action] = React.useState('');
    const [openAlertResetDialog, setOpenAlertResetDialog] = React.useState(false)
    const [openAlertDeleteDialog, setOpenAlertDeleteDialog] = React.useState(false)
    const {user: {type}} = data;
    const [openInformativeDialog, setOpenInformativeDialog] = React.useState(false)
    const [temporaryUserPassword, setTemporaryUserPassword] = React.useState(null)
    const {sendNotification} = useNotifications();
    const history = useHistory<{ dataUser: string | undefined }>();

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        switch (event.target.value) {
            case "DELETE":
                setOpenAlertDeleteDialog(true)
                break;
            case "RESET_PASSWORD":
                setOpenAlertResetDialog(true)
                break;
            default:
                break;
        }
    };

    const handleCloseAlertResetDialog = () => {
        setOpenAlertResetDialog(false)
    }

    const handleCloseAlertDeleteDialog = () => {
        setOpenAlertDeleteDialog(false)
    }

    const handleCloseInformativeDialog = () => {
        setOpenInformativeDialog(false)
    }

    const handleAcceptReset = () => {
        setOpenAlertResetDialog(false);
        operations.resetPassword({userId: data.user.userId, sendNotification: true})
            .then((res) => {
                setOpenAlertResetDialog(false);
                sendNotification('Contraseña reiniciada correctamente');
                setTemporaryUserPassword(res)
                setOpenInformativeDialog(true);
            })
            .catch(() => {
                setOpenAlertResetDialog(false);
                sendNotification('Ocurrió un error al reiniciar la contraseña');
            })
    }

    const handleAcceptDelete = () => {
        operations.deleteUser({userId: data.user.userId})
            .then(() => {
                setOpenAlertDeleteDialog(false);
                sendNotification('Usuario eliminado correctamente');
                history.push(`/admin/users/grid`);
            })
            .catch(() => {
                setOpenAlertDeleteDialog(false);
                sendNotification('Ocurrio un error al eliminar');
            })
    }

    return (
        <div>
            <FormControl variant='outlined' className={classes.formControl}>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={action}
                    onChange={handleChange}
                    displayEmpty
                >
                    <MenuItem value="" disabled>Acciones</MenuItem>
                    <MenuItem value="DELETE">Eliminar</MenuItem>
                    {
                        type.kind === 'INTERNAL' ?
                            null
                            : <MenuItem value="RESET_PASSWORD">Reiniciar contraseña</MenuItem>
                    }
                </Select>
            </FormControl>
            <Index title="Reiniciar contraseña" description="¿Está seguro de reiniciar la contraseña?"
                   open={openAlertResetDialog} handleClose={handleCloseAlertResetDialog}
                   handleAccept={handleAcceptReset} disabled={false}/>
            <Index title="Eliminar usuario" description="¿Está seguro que desea eliminar el usuario?"
                   open={openAlertDeleteDialog} handleClose={handleCloseAlertDeleteDialog}
                   handleAccept={handleAcceptDelete} disabled={false}/>
            <InformativeDialog open={openInformativeDialog} title="Contraseña asignada temporalmente"
                               handleClose={handleCloseInformativeDialog} data={temporaryUserPassword}/>
        </div>
    );
}

export default UserActions;
