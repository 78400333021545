export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_NAME_COUNTRY = "GET_NAME_COUNTRY";
export const GET_DEPARTMENTS = "GET_DEPARTMENTS";
export const GET_NAME_DEPARTMENT = "GET_NAME_DEPARTMENT";
export const GET_CITIES = "GET_CITIES";
export const GET_NAME_CITY = "GET_NAME_CITY";
export const ClEAN_CITIES = "ClEAN_CITIES";
export const DELETE_COUNTRY = "DELETE_COUNTRY";
export const DELETE_DEPARTMENT = "DELETE_DEPARTMENT";
export const DELETE_CITY = "DELETE_CITY";
