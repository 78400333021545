import { POST_FAQ, DELETE_FAQ, SHOW_FAQ, SET_IN_SEARCH_FAQS } from '../constants/index';
import { axiosAPI } from '../middleware/api';

export function createFaq(props, editMode) {

    let request = null;
    if(editMode){
        request = axiosAPI.put(`/faqs`, {'faq': props});
    } else {
        request = axiosAPI.post(`/faqs`, {'faq': props});
    }

    return {
        type: POST_FAQ,
        payload: request
    }
}

export function deleteFaq(faqId) {

    const request = axiosAPI.delete(`/faqs/${faqId}`, {data: {"type": 'logic'}});

    return {
        type: DELETE_FAQ,
        payload: request,
        meta:{
            faqId
        }
    }
}

export function showFaq(show) {

    let request = null;
    if(show){
        request = axiosAPI.get(`/faqs/users`);
    }

    return {
        type: SHOW_FAQ,
        payload: request,
        meta:{
            show
        }
    }
}

export function setInSearch() {

    return {
        type: SET_IN_SEARCH_FAQS
    }
}
