import _ from 'lodash';
import { GET_SURVEYS_GRID } from '../actions/grid/surveys_grid_form';
import { GET_SURVEY_SECTIONS_INFO , GET_SURVEY_INFO , GET_SURVEY_SECTION , CLEAR_SURVEY_SECTION } from '../actions/info/surveys_info_form';



import { LOAD_SURVEYS_PROJECT, DELETE_SURVEY, LOCK_SURVEY, CLONE_SURVEY } from '../constants/index';

const INITIAL_STATE = { all: [], surveys: null, survey: null};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_SURVEYS_GRID:
            return  _.assign({}, state, {
                all: action.payload.data
            });
        case GET_SURVEY_SECTIONS_INFO:
            return  _.assign({}, state, {
                all: action.payload.data
            });
        case GET_SURVEY_INFO:
            return  _.assign({}, state, {
                all: action.payload.data
            });
        case GET_SURVEY_SECTION:
            return  _.assign({}, state, {
                survey: action.payload.data
            });
        case LOAD_SURVEYS_PROJECT:
            return _.assign({}, state, {
                all: action.payload.data
            });
        case DELETE_SURVEY:
            var newAll = _.filter(state.all.data, item => {
                return !_.isEqual(item._id, action.meta.surveysId);
            });
            return _.assign({}, state, {
                all: {
                    data:newAll
                }
            });
        case LOCK_SURVEY:
            var newSurveysAll = _.map(state.all.data, item => {
                if(_.isEqual(item._id, action.meta.surveysId)){
                    return _.set(item, "locked", true);
                } 
                    return item;
                
            });
            return _.assign({}, state, {
                all: {
                    data: newSurveysAll
                }
            });
        case CLONE_SURVEY:
            var newStateSurveys = _.get(state, 'all.data', _.get(state, 'all', []));
            newStateSurveys.push(_.get(action, 'payload.data.data'));
            return _.assign({}, state, {
                all: {
                    data: newStateSurveys
                }
            });
        case CLEAR_SURVEY_SECTION:
            return _.assign({}, state, {
                survey: {}
            });
        default:
            return state;
    }
}
