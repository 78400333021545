import React, { Component } from "react";
import { Row, Col } from "react-flexbox-grid";
import Paper from "material-ui/Paper";
import { blue700 } from "material-ui/styles/colors";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { LBLEVALUATORS } from "../../constants/labels";
import FloatingActionButtonTooltip from "../fields/FloatingActionButtonTooltip/index.tsx";
import AuthComponent from "../AuthComponent";
import SendMessageModal from "../ModalSendEmail/modalSendEmail";
import SpinnerLoading from "../SpinnerLoading/componentSpinner";
import TablePagination from "../TablePagination/tablePagination";

const stylePaper = {
  marginTop: 20,
  marginBottom: 20,
};

class RefereesGrid extends Component {
  render() {
    const body = [
      {
        field: "name",
        title: "Nombre",
        filter:true,
      },
      {
        field: "lastName",
        title: "Apellidos",
        filter: true,
      },
      {
        field: "id",
        title: "Identificación",
        filter: true,
      },
      {
        actions: true,
        title: "Operaciones",
      },
    ];
    return (
      <Row>
        <AuthComponent
          component={
            <Col mdOffset={2} md={8} xs={12}>
              <Paper style={stylePaper}>
                <Row>
                  <Col xs>
                    <Row>
                      <Col xs>
                        <div
                          style={{
                            width: "100%",
                            background: blue700,
                            paddingTop: 5,
                            paddingBottom: 5,
                            color: "#FFF",
                          }}
                        >
                          <h1 style={{ textAlign: "center", fontWeight: 400 }}>
                            {LBLEVALUATORS.titleGrid}
                          </h1>
                          <AuthComponent
                            component={
                                <FloatingActionButtonTooltip
                                  textTooltip={LBLEVALUATORS.tooltips.btnCreate}
                                  to="/admin/referees/create"
                                  component={Link}
                                />
                            }
                            permission="backend/referees:write"
                            type="component"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs>
                        <TablePagination
                          name="referees"
                          URL="/referees"
                          version={1}
                          body={body}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <SpinnerLoading />
                  <SendMessageModal />
                </Row>
              </Paper>
            </Col>
          }
          permission="backend/referees:read"
          type="url"
        />
      </Row>
    );
  }
}

function mapStateToProps({ referees }) {
  return {
    referees: referees.all,
  };
}

export default connect(mapStateToProps)(RefereesGrid);
