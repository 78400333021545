import PropTypes from "prop-types";
import React, { Component } from "react";
import TextField from "material-ui/TextField";
import { List, ListItem } from "material-ui/List";
import {
    Popover,
    PopoverAnimationVertical as PopoverAnimationFromTop,
} from "material-ui/Popover";
import IconButton from "material-ui/IconButton";
import SearchIcon from "material-ui/svg-icons/action/search";
import _ from "lodash";
import CircularProgress from "material-ui/CircularProgress";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addValueStore } from "./actions";
import { API_URL } from "../../constants/index";
import { toggleSnackbar } from "../../actions/commons";
import { axiosAPI } from "../../middleware/api";

function promiseConfig(value) {
    return {
        params: {
            search: value,
        },
    };
}

const style = {
    refresh: {
        display: "inline-block",
        position: "relative",
        top: 15,
        top: -45,
        right: -35,
        float: "right",
    },
    list: {
        width: 380,
        top: 50,
    },
};

class AutoComplete extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: "",
            loading: false,
            results: [],
        };

        this._handleOnChange = this._handleOnChange.bind(this);
        this._handleRequestClose = this._handleRequestClose.bind(this);
        this._handleKeyUp = this._handleKeyUp.bind(this);
        this._handleKeyPress = this._handleKeyPress.bind(this);
        this.renderListValues = this.renderListValues.bind(this);
        this._searchReference = this._searchReference.bind(this);
    }

    static propTypes = {
        placeholder: PropTypes.string,
        label: PropTypes.string,
        subHeader: PropTypes.string,
        coreName: PropTypes.string,
        storeName: PropTypes.string,
    };

    render() {
        const { placeholder, label, subHeader, fullWidth } = this.props;
        const { value, open, anchorEl, loading, results } = this.state;
        const widthFull = _.isUndefined(fullWidth) ? false : fullWidth;
        const lbl = _.isUndefined(label) ? "" : label;
        // Subheader={`${subHeader} (${_.size(results)})`}
        const list = (
            <List style={_.get(style, "list", {})}>
                {results.map(this.renderListValues)}
            </List>
        );
        return (
            <div style={{ width: "90%" }}>
                <TextField
                    hintText={placeholder}
                    floatingLabelText={lbl}
                    onChange={this._handleOnChange}
                    fullWidth={widthFull}
                    value={value}
                    onKeyPress={this._handleKeyPress}
                    onKeyUp={this._handleKeyUp}
                />
                {loading ? (
                    <CircularProgress
                        size={30}
                        thickness={3}
                        style={style.refresh}
                    />
                ) : open ? (
                    <span />
                ) : (
                    <IconButton
                        tooltip="Buscar"
                        tooltipPosition="top-center"
                        style={{ display: "none" }}
                        onClick={this._searchReference}
                        children={<SearchIcon />}
                    />
                )}
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onRequestClose={this._handleRequestClose}
                    animation={PopoverAnimationFromTop}
                    style={{ width: 380 }}
                >
                    {list}
                </Popover>
            </div>
        );
    }

    renderListValues(item) {
        const {
            storeName,
            addValueStore,
            arrayValidate,
            toggleSnackbar,
        } = this.props;
        const self = this;

        function handleTapList(e) {
            e.preventDefault();
            if (_.isEqual("questions", storeName)) {
                if (_.isUndefined(arrayValidate)) {
                    addValueStore(item, storeName);
                } else if (
                    arrayValidate instanceof Array &&
                    _.size(arrayValidate) > 0
                ) {
                    let exist = true;
                    arrayValidate.map(function (idQuestion) {
                        if (_.isEqual(idQuestion, _.get(item, "_id"))) {
                            exist = false;
                        }
                    });
                    if (exist) {
                        addValueStore(item, storeName);
                    } else {
                        toggleSnackbar(
                            true,
                            "La pregunta seleccionada ya se encuestra registrada en otra sección"
                        );
                    }
                } else {
                    addValueStore(item, storeName);
                }
            } else {
                addValueStore(item, storeName);
            }
            self.setState({ value: "" });
        }

        return (
            <ListItem
                key={item._id}
                primaryText={
                    item.title
                        ? item.title
                        : item.lastName
                        ? `${item.name} ${item.lastName}`
                        : item.name
                }
                secondaryText={item.fieldName ? item.fieldName : item.id}
                onClick={handleTapList}
            />
        );
    }

    _handleOnChange(event) {
        this.setState({ value: event.target.value });
    }

    _handleRequestClose() {
        this.setState({
            open: false,
            results: [],
        });
    }

    _handleKeyUp(e) {
        const self = this;
        if (e.keyCode === 8) {
            e.preventDefault();
            self.setState({
                open: false,
            });
        }
    }

    _handleKeyPress(e) {
        const self = this;
        if (e.key === "Enter") {
            e.preventDefault();
            self.setState({
                open: true,
                anchorEl: e.currentTarget,
                loading: true,
            });
            axiosAPI
                .get(`/${self.props.coreName}`, promiseConfig(this.state.value))
                .then((response) => {
                    self.setState({
                        loading: false,
                        results: _.get(response, "data.data"),
                    });
                })
                .catch(console.log);
        }
    }

    _searchReference(e) {
        const self = this;
        self.setState({
            open: true,
            anchorEl: e.currentTarget,
            loading: true,
        });
        axiosAPI
            .get(`/${self.props.coreName}`, promiseConfig(this.state.value))
            .then((response) => {
                self.setState({
                    loading: false,
                    results: _.get(response, "data.data"),
                });
            })
            .catch(console.log);
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            addValueStore,
            toggleSnackbar,
        },
        dispatch
    );
}

export default connect(null, mapDispatchToProps)(AutoComplete);
