import _ from "lodash";
import {format, parseISO} from "date-fns";

export function isBlank(value) {
    return (
        (_.isEmpty(value) && !_.isNumber(value)) ||
        _.isNaN(value) ||
        _.isNil(value)
    );
}

export function parseSimpleDate(unsafeDate: string, dateFormat="dd-MM-yyyy hh:mm aaa") {
    try {
        const parsedDate = parseISO(unsafeDate);
        return format(parsedDate, dateFormat);
    } catch (_e) {
        return unsafeDate;
    }
}

export function removeAliasesEmail(email){
    const index1 = email.indexOf('+')
    const index2 = email.indexOf('@')

    if(index1 !== -1){
        const part1 = email.substring(0, index1)
        const part2 = email.substring(index2)
        return part1 + part2
    }
    return email
}