import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

type LoaderProps = {
  isLoading: boolean;
  error: any;
};
const Loader: React.FC<LoaderProps> = ({ isLoading, error, children }) => {
  if (isLoading) {
    return <CircularProgress color="secondary" />;
  }
  if (error) {
    return (
      <h3>
        <pre>
          <code>{JSON.stringify(error, null, 3)}</code>
        </pre>
      </h3>
    );
  }
  return <>{children}</>;
};

export default Loader;
