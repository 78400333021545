import PropTypes from "prop-types";
import React, { Component } from "react";
import TableCell from "@material-ui/core/TableCell";
import _ from "lodash";
import { Link } from "react-router-dom";
import IconButton from "material-ui/IconButton";
import { connect } from "react-redux";
import EditIcon from "material-ui/svg-icons/editor/mode-edit";
import PollIcon from "material-ui/svg-icons/social/poll";
import AccessibilityIcon from "material-ui/svg-icons/action/accessibility";
import ContentPasteIcon from "material-ui/svg-icons/content/content-paste";
import VisibilityIcon from "material-ui/svg-icons/action/visibility";
import SettingsIcon from "material-ui/svg-icons/action/settings";
import AuthComponent from "../../AuthComponent";
import { LBLPROJECTS } from "../../../constants/labels";
import DeleteEntityModal from "../../deleteEntityModal/component";
import { deleteProject } from "../../../actions/projects_form";
import { toggleSnackbar } from "../../../actions/commons";
import { removeRecord } from "../actions";

class ProjectByRefereeActionColumn extends Component {
  constructor(props) {
    super(props);
    this.handleTouchDelete = this.handleTouchDelete.bind(this);
  }

  handleTouchDelete(data) {
    const { toggleSnackbar, deleteProject, removeRecord } = this.props;
    const idProject = _.get(data, "_id");
    deleteProject(idProject)
      .then(
        (data) => {
          if (_.get(data, "error") || _.get(data, "payload.status") !== 200) {
            toggleSnackbar(true, LBLPROJECTS.msg.errorProjectDelete);
          } else {
            removeRecord(idProject, "_id");
            toggleSnackbar(true, LBLPROJECTS.msg.successProjectDelete);
          }
        },
        (reason) => {
          toggleSnackbar(true, LBLPROJECTS.msg.errorProjectDelete);
        }
      )
      .catch((data) => {
        toggleSnackbar(true, LBLPROJECTS.msg.errorProjectDelete);
      });
  }

  render() {
    const { data, colSpan, extra } = this.props;
    const cs = _.isUndefined(colSpan) ? 1 : colSpan;

    const projectId = _.get(data, "_id");
    const companyId = _.get(data, "company._id");

    return (
      <TableCell colSpan={cs}>
        <div style={{ position: "absolute", marginTop: -24, zIndex: 99 }}>
          <AuthComponent
            component={
              <Link
                to={`/admin/projects/advance/ref/${companyId}/${projectId}`}
              >
                <IconButton
                  tooltip="Estado actual"
                  tooltipPosition="top-center"
                >
                  <PollIcon />
                </IconButton>
              </Link>
            }
            permission="backend/projects:read"
            type="component"
          />
          <AuthComponent
            component={
              <Link to={`/admin/projects/info/ref/${projectId}/${companyId}`}>
                <IconButton
                  tooltip={LBLPROJECTS.tooltips.btnView}
                  tooltipPosition="top-center"
                >
                  <SettingsIcon />
                </IconButton>
              </Link>
            }
            permission="backend/projects:write"
            type="component"
          />
          <AuthComponent
            component={
              <Link to={`/admin/companies/info/${companyId}`}>
                <IconButton
                  tooltip="Visualizar empresa"
                  tooltipPosition="top-center"
                  children={<VisibilityIcon />}
                />
              </Link>
            }
            permission="backend/companies:write"
            type="component"
          />
        </div>
      </TableCell>
    );
  }
}

ProjectByRefereeActionColumn.propTypes = {
  data: PropTypes.object.isRequired,
  colSpan: PropTypes.number,
  extra: PropTypes.object,
};

export default connect(null, { deleteProject, toggleSnackbar, removeRecord })(
  ProjectByRefereeActionColumn
);
