import React, { Component } from "react";
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from "material-ui/Table";
import RaisedButton from "material-ui/RaisedButton";
import { Row, Col } from "react-flexbox-grid";
import Paper from "material-ui/Paper";
import SettingsIcon from "material-ui/svg-icons/action/settings";
import EditIcon from "@material-ui/icons/Edit";
import _ from "lodash";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { black, blue700 } from "material-ui/styles/colors";
import Divider from "material-ui/Divider";
import { FetchSurveyParam } from "../../actions/info/surveys_info_form";
import { getSurveyGroupers, deleteSurveyGrouper } from "./actions";
import { LBLGROUPERS } from "../../constants/labels";
import DeleteEntityModal from "../deleteEntityModal/component";
import ButtonBack from "../fields/buttonBack/buttonBack";
import AuthComponent from "../AuthComponent";
import { openModalForm } from "../ModalFormGroupers/actions";
import ModalFormGroupers from "../ModalFormGroupers/ModalFormGroupers";
import { toggleSnackbar } from "../../actions/commons";
import { AuthComponent as AuthComponentV2 } from "../../auth";
import { LinkIconButton, TooltipIconButton } from "../commons/index.tsx";

const stylePaper = {
  marginTop: 20,
  marginBottom: 20,
};

class GroupersGrid extends Component {
  constructor(props) {
    super(props);
    this._handleDeleteGrouper = this._handleDeleteGrouper.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { getSurveyGroupers, FetchSurveyParam, params } = this.props;
    getSurveyGroupers(params.survey);
    FetchSurveyParam(params.survey);
  }

  _handleDeleteGrouper(grouper) {
    const { deleteSurveyGrouper, toggleSnackbar } = this.props;
    const URL = `/admin/surveys/groupers/${this.props.history.location.pathname}`
    deleteSurveyGrouper(_.get(grouper, "_id"))
      .then(
        (data) => {
          if (_.get(data, "error") || _.get(data, "payload.status") !== 200) {
            toggleSnackbar(true, LBLGROUPERS.msg.errorDelete);
          } else {
            toggleSnackbar(true, LBLGROUPERS.msg.successDelete);
            window.location.reload(URL)
          }
        },
        (reason) => {
          toggleSnackbar(true, LBLGROUPERS.msg.errorDelete);
        }
      )
      .catch((data) => {
        toggleSnackbar(true, LBLGROUPERS.msg.errorDelete);
      });
  }

  renderGroupers(groupersSurvey) {
    const { params, locked, openModalForm } = this.props;
    if (_.size(groupersSurvey) > 0) {
      return groupersSurvey.map((grouper) => {
        return (
          <TableRow key={grouper._id}>
            <TableRowColumn colSpan={2} style={{ whiteSpace: "normal" }}>
              {grouper.name}
            </TableRowColumn>
            <TableRowColumn>{grouper.level}</TableRowColumn>
            <TableRowColumn>{grouper.visible ? "Si" : "No"}</TableRowColumn>
            <TableRowColumn colSpan={2}>
              {_.isEqual(grouper.typeEvaluation, "summatory")
                ? "Sumatoria"
                : "Frencuencia"}
            </TableRowColumn>
            <TableRowColumn colSpan={2}>
              <AuthComponentV2 permission="backend/surveyResults:write">
                <LinkIconButton
                  icon={<SettingsIcon />}
                  tooltip={LBLGROUPERS.tooltips.btnConfig}
                  to={`/admin/surveys/groupers/${_.get(
                    params,
                    "survey"
                  )}/conditionals/${_.get(grouper, "_id")}`}
                />
              </AuthComponentV2>
              <AuthComponentV2 permission="backend/surveyGroupers:delete">
                <DeleteEntityModal
                  tooltip={LBLGROUPERS.tooltips.btnDelete}
                  title={LBLGROUPERS.titleDelete}
                  valid={locked}
                  message={LBLGROUPERS.msg.deleteModal(grouper.name)}
                  fn={this._handleDeleteGrouper}
                  args={[grouper]}
                />
              </AuthComponentV2>
              <AuthComponentV2 permission="backend/surveyGroupers:write">
                <TooltipIconButton
                  tooltip={LBLGROUPERS.tooltips.btnEdit}
                  disabled={locked}
                  onClick={() => {
                    if (!locked) {
                      openModalForm(
                        _.get(grouper, "_id"),
                        _.get(grouper, "name"),
                        _.get(grouper, "visible") ? "true" : "false",
                        _.get(grouper, "priority")
                      );
                    }
                  }}
                  icon={<EditIcon  style={!locked ?{color:black}:{color:"#aaaaaa"}}/>}
                />
              </AuthComponentV2>
            </TableRowColumn>
          </TableRow>
        );
      });
    }
  }

  renderActions(locked, idSurvey) {
    if (locked) {
      return null;
    }
    return (
      <div>
        <AuthComponent
          component={
            <Link to={`/admin/surveys/metrical/${idSurvey}`}>
              <RaisedButton
                label={LBLGROUPERS.buttons.valueItems}
                secondary
                style={{ margin: 20 }}
              />
            </Link>
          }
          permission="backend/surveys:write"
          type="component"
        />
        <AuthComponent
          component={
            <Link to={`/admin/surveys/groupers/questions/${idSurvey}`}>
              <RaisedButton
                label={LBLGROUPERS.buttons.grouperQuestions}
                secondary
                style={{ margin: 20 }}
              />
            </Link>
          }
          permission="backend/surveyGroupers:write"
          type="component"
        />
        <AuthComponent
          component={
            <Link to={`/admin/surveys/groupers/create/${idSurvey}`}>
              <RaisedButton
                label={LBLGROUPERS.buttons.groupGroupers}
                secondary
                style={{ margin: 20 }}
              />
            </Link>
          }
          permission="backend/surveyGroupers:write"
          type="component"
        />
      </div>
    );
  }

  render() {
    const { surveyName, params, locked, groupersSurvey } = this.props;
    return (
      <AuthComponent
        component={
          <Row>
            <Col xs={12} mdOffset={2} md={8}>
              <Paper style={stylePaper}>
                <ButtonBack url="/admin/surveys/grid" />
                <Row>
                  <Col xs>
                    <div
                      style={{
                        width: "100%",
                        background: blue700,
                        paddingTop: 5,
                        paddingBottom: 5,
                        color: "#FFF",
                      }}
                    >
                      <h1 style={{ textAlign: "center", fontWeight: 400 }}>
                        {LBLGROUPERS.titleGridConfigGrouper}
                      </h1>
                      <h2 style={{ textAlign: "center", fontWeight: 400 }}>
                        {surveyName}
                      </h2>
                    </div>
                    {this.renderActions(locked, params.survey)}
                    <Divider />
                    <div>
                      <Table selectable={false}>
                        <TableHeader
                          displaySelectAll={false}
                          adjustForCheckbox={false}
                        >
                          <TableRow>
                            <TableHeaderColumn
                              colSpan={2}
                              style={{ whiteSpace: "normal" }}
                            >
                              {LBLGROUPERS.name}
                            </TableHeaderColumn>
                            <TableHeaderColumn>
                              {LBLGROUPERS.level}
                            </TableHeaderColumn>
                            <TableHeaderColumn>
                              {LBLGROUPERS.viewEmployee}
                            </TableHeaderColumn>
                            <TableHeaderColumn colSpan={2}>
                              {LBLGROUPERS.typeOperation}
                            </TableHeaderColumn>
                            <TableHeaderColumn colSpan={2}>
                              {LBLGROUPERS.actions}
                            </TableHeaderColumn>
                          </TableRow>
                        </TableHeader>
                        <TableBody displayRowCheckbox={false}>
                          {this.renderGroupers(groupersSurvey)}
                        </TableBody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </Paper>
            </Col>
            <ModalFormGroupers />
          </Row>
        }
        permission="backend/surveyGroupers:write"
        type="url"
      />
    );
  }
}

function mapStateToProps({ surveys, groupersSurvey }) {
  return {
    locked: _.get(surveys, "all.data.locked", true),
    surveyName: _.get(surveys, "all.data.name"),
    groupersSurvey: _.get(groupersSurvey, "groupers"),
  };
}

export default connect(mapStateToProps, {
  FetchSurveyParam,
  getSurveyGroupers,
  deleteSurveyGrouper,
  openModalForm,
  toggleSnackbar,
})(GroupersGrid);
