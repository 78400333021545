import Immutable from 'immutable';
import {LOAD_SECTIONS} from '../../actions/surveys/sections';

const initialState = Immutable.Map({
    all: Immutable.List()
});

export default (state = initialState, action) => {
    switch (action.type) {
        case LOAD_SECTIONS:
            var { data } = action.payload.data;
            return state.set('all', Immutable.List(data));
        default:
            return state;
    }
};
