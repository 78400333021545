import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import MenuReducer from "./left_navbar"; // Immutable
import aboutReducer from "./reducer_about"; // Immutable
import CompanyReducer from "./reducer_companies";
import EmployeeReducer from "./reducer_employees";
import RefereeReducer from "./reducer_referees";
import ConsentReducer from "./reducer_consents";
import ListMasterReducer from "./reducer_listmaster";
import ListReducer from "./reducer_list";
import SurveyReducer from "./reducer_surveys";
import FaqReducer from "./reducer_faq"; // Immutable
import SectionReducer from "./reducer_sections";
import ProjectReducer from "./reducer_projects";
import DialogReducer from "./reducer_commons";
import StoreAutoCompleteReducer from "../components/functional/reducers"; // Immutable
import QuestionReducer from "./reducer_questions";
import chatReducer from "./reducer_chat"; // Immutable
import fnTableReducer from "./reducer_fnTable"; // Immutable
import AsyncFieldValidatorReducer from "../components/fields/AsyncFieldValidator/reducer"; // Immutable
import ListValuesReducer from "../components/fields/FetchListValues/reducer"; // Immutable
import TreeQuestionsItemsReducer from "../containers/surveys/reducer"; // Immutable
import ListValuesSectionReducer from "../components/fields/TableListValuesSection/reducer"; // Immutable
import ControlsSectionsReducer from "../components/fields/ControlsSection/reducer"; // Immutable
import SurveyAdvanceReducer from "../components/fields/SurveyAdvance/reducer"; // Immutable
import GraphicAdvanceProjectReducer from "../components/DoughnutChart/reducer"; // Immutable
import GraphicAdvanceProjectsReducer from "../components/dashboards/arl/components/doughnutChartStatus/reducer"; // Immutable
import SnackbarReducer from "./commons/SnackbarReducer";
import UserDialogReducer from "./users/userDialogReducer";
import UserDialogAdvanceReducer from "../components/fields/AdvanceUser/reducer";
import SurveySectionReducer from "./surveys/sections"; // Immutable
import TableFxReducer from "../containers/fixedDataTable/reducers"; // Immutable
import ProfileReducer from "../containers/profiles/reducer"; // Immutable
import loginReducer from "../containers/login/loginDucks"; // Immutable
import answerReducer from "../components/survey/question/reducers"; // Immutable
import questionListOpenReducer from "../components/survey/question/ReducerListQuestionOpen"; // Immutable
import questionLocationReducer from "../components/survey/question/location/reducer"; // Immutable
import employeeProjectAssociate from "../components/tableProjectAssociated/reducer";
import employeeDashboardReducer from "../components/dashboards/employee/reducer"; // Immutable
import QuestionsSurveyReducer from "../components/survey/ReducerSurvey"; // Immutable
import refereesDashboardReducer from "../components/dashboards/referee/reducers"; // Immutable
import companiesDashboardReducer from "../components/dashboards/company/reducers"; // Immutable
import arlDashboardReducer from "../components/dashboards/arl/reducer"; // Immutable
import MasterReducer from "./reducer_master";
import EmployeesAssocProjectReducer from "../components/SendEmailMasive/ducks";
import groupersSurveyReducer from "../components/groupersConfiguration/reducer";
import groupersQuestionMetricsReducer from "./reducer_questions_grouper";
import modalExitSurveyReducer from "../components/ModalExitSurvey/reducer";
import resultsSurvey from "../components/surveyResults/reducer"; // Immutable
import resultsSurveyRefereeReducer from "../components/surveyResultsReferee/reducer"; // Immutable
import changePasswordReducer from "../components/ModalChangePassword/reducerChangePassword";
import modalFormLocationReducer from "../components/ModalFormLocation/reducer"; // Immutable
import modalFormGrouperReducer from "../components/ModalFormGroupers/reducer"; // Immutable
import modalSendEmailReducer from "../components/ModalSendEmail/reducer"; // Immutable
import loadingReducer from "../components/SpinnerLoading/reducer"; // Immutable
import GroupersGroupReducer from "../components/GroupersGroup/reducer"; // Immutable
import templateEmailsReducer from "./reducir_template"; // Immutable
import AuditsReducer from "../components/Audit/reducer"; // Immutable
import ProfileFormReducer from "./reducer_profiles"; // Immutable
import SurveyResultsMetadata from "../components/surveyResults/dashboard/reducer";
import ModalMessageResultReducer from "../components/ModalMessageResult/reducer";
import TablePaginationReducer from "../components/TablePagination/reducer"; // Immutable
import GenericTablePaginationReducer from "../components/GenericTablePagination/reducer"; // Immutable
import TagsEmployeeReducer from "../components/TagsEmployee/reducer"; // Immutable
import FilterCompanyReducer from "../components/FilterCompanies/reducer"; // Immutable
import metadataProject from "../components/metadataEditor/MetadataDucks"; // Immutable
import reportEmployeeReducer from "../components/reportEmployee/reportEmployeeDucks";
import employeesProcessedReducer from "../components/employeesProcessed/reducer"; // Immutable
import MedatataEditorReducer from "../components/surveyMetadataEditor/editorDucks";
import surveysExcelGenerator from "../components/SurveyResultsExcelGenerator/excelGeneratorDucks";
import resetEmployee from "../components/resetSurvey/ducks";
import chatEmployee from "../components/chat/employee/ducks";
import chatEvaluator from "../components/chat/evaluator/ducks";

const rootReducer = combineReducers({
  menu: MenuReducer,
  about: aboutReducer,
  companies: CompanyReducer,
  employees: EmployeeReducer,
  referees: RefereeReducer,
  consents: ConsentReducer,
  listmaster: ListMasterReducer,
  list: ListReducer,
  surveys: SurveyReducer,
  faq: FaqReducer,
  sections: SectionReducer,
  projects: ProjectReducer,
  form: formReducer,
  dialog: DialogReducer,
  snackbar: SnackbarReducer,
  autoComplete: StoreAutoCompleteReducer,
  questions: QuestionReducer,
  chat: chatReducer,
  fnTable: fnTableReducer,
  listValues: ListValuesReducer,
  treeQuestionsItems: TreeQuestionsItemsReducer,
  listValuesSection: ListValuesSectionReducer,
  controlsections: ControlsSectionsReducer,
  surveyadvance: SurveyAdvanceReducer,
  graphicAdvance: GraphicAdvanceProjectReducer,
  AsyncFieldValidator: AsyncFieldValidatorReducer,
  userDialog: UserDialogReducer,
  UserDialogAdvance: UserDialogAdvanceReducer,
  surveySections: SurveySectionReducer,
  TableFX: TableFxReducer,
  profiles: ProfileReducer,
  login: loginReducer,
  answers: answerReducer,
  questionListOpen: questionListOpenReducer,
  employeeTableProject: employeeProjectAssociate,
  dashboard: employeeDashboardReducer,
  questionsSurvey: QuestionsSurveyReducer,
  refereesDashboard: refereesDashboardReducer,
  companiesDashboard: companiesDashboardReducer,
  arlDashboard: arlDashboardReducer,
  graphicAdvanceProjects: GraphicAdvanceProjectsReducer,
  employeesProcessed: employeesProcessedReducer,
  employeesAssocProject: EmployeesAssocProjectReducer,
  groupersSurvey: groupersSurveyReducer,
  groupersQuestion: groupersQuestionMetricsReducer,
  modalExitSurvey: modalExitSurveyReducer,
  master: MasterReducer,
  showChangePassword: changePasswordReducer,
  resultsSurvey,
  resultsSurveyReferee: resultsSurveyRefereeReducer,
  spinnerLoading: loadingReducer,
  formLocation: modalFormLocationReducer,
  formGrouper: modalFormGrouperReducer,
  sendEmail: modalSendEmailReducer,
  groupersAggregate: GroupersGroupReducer,
  templateemails: templateEmailsReducer,
  audits: AuditsReducer,
  profileForm: ProfileFormReducer,
  surveyResults: SurveyResultsMetadata,
  modalMessageResult: ModalMessageResultReducer,
  tablePagination: TablePaginationReducer,
  genericTablePagination: GenericTablePaginationReducer,
  tagsEmployee: TagsEmployeeReducer,
  filterCompany: FilterCompanyReducer,
  metadataProject,
  metadataSurvey: MedatataEditorReducer,
  surveysExcelGenerator,
  reportEmployee: reportEmployeeReducer,
  questionLocation: questionLocationReducer,
  resetEmployee,
  chatEmployee,
  chatEvaluator,
});

export default rootReducer;
