export const GET_VALUES_ACTIVITY_ECONOMIC_FILTER_COMPANY = 'GET_VALUES_ACTIVITY_ECONOMIC_FILTER_COMPANY';
export const CHANGE_VALUE_ACTIVITY_ECONOMIC_FILTER_COMPANY = 'CHANGE_VALUE_ACTIVITY_ECONOMIC_FILTER_COMPANY';
export const GET_VALUES_EMPLOYEES_NUMBER_FILTER_COMPANY = 'GET_VALUES_EMPLOYEES_NUMBER_FILTER_COMPANY';
export const CHANGE_VALUE_EMPLOYEES_NUMBER_FILTER_COMPANY = 'CHANGE_VALUE_EMPLOYEES_NUMBER_FILTER_COMPANY';
export const GET_VALUES_COUNTRIES_FILTER_COMPANY = 'GET_VALUES_COUNTRIES_FILTER_COMPANY';
export const CHANGE_VALUE_COUNTRIES_FILTER_COMPANY = 'CHANGE_VALUE_COUNTRIES_FILTER_COMPANY';
export const GET_VALUES_DEPARTMENTS_FILTER_COMPANY = 'GET_VALUES_DEPARTMENTS_FILTER_COMPANY';
export const CHANGE_VALUE_DEPARTMENTS_FILTER_COMPANY = 'CHANGE_VALUE_DEPARTMENTS_FILTER_COMPANY';
export const GET_VALUES_CITIES_FILTER_COMPANY = 'GET_VALUES_CITIES_FILTER_COMPANY';
export const CHANGE_VALUE_CITIES_FILTER_COMPANY = 'CHANGE_VALUE_CITIES_FILTER_COMPANY';
export const CLEAN_VALUES_FILTER_COMPANY = 'CLEAN_VALUES_FILTER_COMPANY';
