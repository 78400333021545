import PropTypes from "prop-types";
import React, {Component} from "react";
import {reduxForm} from "redux-form";
import {bindActionCreators} from "redux";
import {Row, Col} from "react-flexbox-grid";
import Paper from "material-ui/Paper";
import RaisedButton from "material-ui/RaisedButton";
import FlatButton from "material-ui/FlatButton";
import MenuItem from "material-ui/MenuItem";
import TextField from "material-ui/TextField";
import _ from "lodash";
import is from "is_js";
import PureInput from "../components/PureInput";
import {createCompany, updateCompany} from "../actions/companies_form";
import {
    FetchCompanyParam,
    getEconomicActivities,
    getEmployeesNumber,
    getARLs,
} from "../actions/info/companies_info_form";
import {axiosAPI} from "../middleware/api";
import {toggleSnackbar} from "../actions/commons";
import ButtonBack from "../components/fields/buttonBack/buttonBack";
import {LBLCOMPANIES} from "../constants/labels";
import SelectFieldFormat from "../components/fields/SelectFieldFormat/SelectFieldFormat";
import SelectCountries from "../components/fields/Locations/componentCountries";
import SelectDepartments from "../components/fields/Locations/componentDepartments";
import SelectCities from "../components/fields/Locations/componentCities";
import DeleteEntityModal from "../components/deleteEntityModal/component";
import AuthComponent from "../components/AuthComponent";

const style = {
    margin: 20,
    float: "right",
};
const stylePaper = {
    marginTop: 20,
    marginBottom: 20,
};

class CompaniesNews extends Component {
    constructor(props) {
        super(props);
        this._saveCompany = this._saveCompany.bind(this);
        this._updateCompany = this._updateCompany.bind(this)
    }

    UNSAFE_componentWillMount() {
        const {
            FetchCompanyParam,
            getEconomicActivities,
            getEmployeesNumber,
            getARLs,
        } = this.props;

        getEconomicActivities();
        getEmployeesNumber();
        getARLs();

        if (_.has(this, "props.params._id")) {
            FetchCompanyParam(this.props.params._id);
        }
    }

    _saveCompany(formData) {
        const {createCompany, toggleSnackbar, resetForm} = this.props;
        createCompany(formData)
            .then(
                (data) => {
                    if (_.get(data, "error") || _.get(data, "payload.status") !== 200) {
                        toggleSnackbar(true, LBLCOMPANIES.msg.errorSave);
                    } else {
                        resetForm();
                        toggleSnackbar(true, LBLCOMPANIES.msg.successSave);
                        this.props.history.push(`/admin/companies/grid`);
                    }
                },
                (reason) => {
                    toggleSnackbar(true, LBLCOMPANIES.msg.errorSave);
                }
            )
            .catch((data) => {
                toggleSnackbar(true, LBLCOMPANIES.msg.errorSave);
            });
    }

    _updateCompany(formData) {
        const {updateCompany, toggleSnackbar, resetForm} = this.props;
        updateCompany(formData)
            .then(
                (data) => {
                    if (_.get(data, "error") || _.get(data, "payload.status") !== 200) {
                        toggleSnackbar(true, LBLCOMPANIES.msg.errorSave);
                    } else {
                        resetForm();
                        toggleSnackbar(true, LBLCOMPANIES.msg.successSave);
                        this.props.history.push(`/admin/companies/grid`);
                    }
                },
                (reason) => {
                    toggleSnackbar(true, LBLCOMPANIES.msg.errorSave);
                }
            )
            .catch((data) => {
                toggleSnackbar(true, LBLCOMPANIES.msg.errorSave);
            });
    }

    render() {
        const {
            asyncValidating,
            fields: {
                businessName,
                nit,
                acronym,
                address,
                _id,
                phone,
                email,
                nameContact,
                phoneContact,
                emailContact,
                economicActivity,
                numEmployees,
                country,
                department,
                city,
                arl,
                tags,
            },
            initialDepartment,
            initialCity,
            employeesNumber,
            economicActivities,
            arlSeleted,
            handleSubmit,
            params,
        } = this.props;
        const editMode = _.has(params, "_id");
        if (!params._id || (params._id && this.props.company)) {
            return (
                <Row>
                    <AuthComponent
                        component={
                            <Col xs={12} mdOffset={2} md={8}>
                                <Paper style={stylePaper}>
                                    <Row>
                                        <Col xs={10} xsOffset={1}>
                                            <ButtonBack url="/admin/companies/grid"/>
                                            <h1 style={{textAlign: "center", fontWeight: 400}}>
                                                {editMode
                                                    ? LBLCOMPANIES.titleEdit
                                                    : LBLCOMPANIES.titleCreate}
                                            </h1>
                                            <form
                                                onSubmit={handleSubmit(!editMode ? this._saveCompany : this._updateCompany)}>
                                                <Row>
                                                    <Col xs>
                                                        <TextField
                                                            ref="nit"
                                                            hintText="Ej: 123326752"
                                                            floatingLabelText={LBLCOMPANIES.nit}
                                                            type="text"
                                                            {...nit}
                                                            errorText={
                                                                nit.touched && nit.error ? nit.error : ""
                                                            }
                                                            disabled={editMode}
                                                        />
                                                        {asyncValidating === "nit" && (
                                                            <i /* spinning cog */ />
                                                        )}
                                                    </Col>
                                                    <Col xs>
                                                        <TextField
                                                            ref="businessName"
                                                            hintText=""
                                                            floatingLabelText={LBLCOMPANIES.businessName}
                                                            type="text"
                                                            errorText={
                                                                businessName.touched && businessName.error
                                                                    ? businessName.error
                                                                    : ""
                                                            }
                                                            {...businessName}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs>
                                                        <TextField
                                                            ref="abbreviation"
                                                            hintText=""
                                                            floatingLabelText={LBLCOMPANIES.abbreviation}
                                                            errorText={
                                                                acronym.touched && acronym.error
                                                                    ? acronym.error
                                                                    : ""
                                                            }
                                                            type="text"
                                                            {...acronym}
                                                        />
                                                    </Col>
                                                    <Col xs>
                                                        <TextField
                                                            ref="address"
                                                            hintText=""
                                                            floatingLabelText={LBLCOMPANIES.address}
                                                            type="text"
                                                            errorText={
                                                                address.touched && address.error
                                                                    ? address.error
                                                                    : ""
                                                            }
                                                            {...address}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs>
                                                        <TextField
                                                            ref="phone"
                                                            hintText=""
                                                            floatingLabelText={LBLCOMPANIES.phone}
                                                            type="text"
                                                            errorText={
                                                                phone.touched && phone.error ? phone.error : ""
                                                            }
                                                            {...phone}
                                                        />
                                                    </Col>
                                                    <Col xs>
                                                        <TextField
                                                            hintText=""
                                                            ref="email"
                                                            onKeyDown={(e) => {
                                                                if (e.keyCode == 9) {
                                                                    // e.preventDefault();
                                                                }
                                                            }}
                                                            floatingLabelText={LBLCOMPANIES.email}
                                                            type="email"
                                                            errorText={
                                                                email.touched && email.error ? email.error : ""
                                                            }
                                                            {...email}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs>
                                                        <SelectFieldFormat
                                                            config={{
                                                                floatingLabelText: LBLCOMPANIES.numberEmployees,
                                                                ref: "numberEmployees",
                                                                value: numEmployees.value,
                                                                autoWidth: true,
                                                                errorText:
                                                                    numEmployees.touched && numEmployees.error
                                                                        ? numEmployees.error
                                                                        : "",
                                                                onChange: (e, index, value) =>
                                                                    numEmployees.onChange(value),
                                                            }}
                                                        >
                                                            {_.map(employeesNumber, (item) => {
                                                                return (
                                                                    <MenuItem
                                                                        key={item._id}
                                                                        value={item._id}
                                                                        primaryText={item.value}
                                                                    />
                                                                );
                                                            })}
                                                        </SelectFieldFormat>
                                                    </Col>
                                                    <Col xs>
                                                        <SelectFieldFormat
                                                            config={{
                                                                floatingLabelText:
                                                                LBLCOMPANIES.ActivityEconomic,
                                                                value: economicActivity.value,
                                                                autoWidth: true,
                                                                errorText:
                                                                    economicActivity.touched &&
                                                                    economicActivity.error
                                                                        ? economicActivity.error
                                                                        : "",
                                                                onChange: (e, index, value) =>
                                                                    economicActivity.onChange(value),
                                                            }}
                                                        >
                                                            {_.orderBy(economicActivities, ["value"]).map(
                                                                (item) => {
                                                                    return (
                                                                        <MenuItem
                                                                            key={item._id}
                                                                            value={item._id}
                                                                            primaryText={item.value}
                                                                        />
                                                                    );
                                                                }
                                                            )}
                                                        </SelectFieldFormat>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs>
                                                        <SelectFieldFormat
                                                            config={{
                                                                floatingLabelText: LBLCOMPANIES.ARL,
                                                                value: arl.value,
                                                                autoWidth: true,
                                                                errorText:
                                                                    arl.touched && arl.error ? arl.error : "",
                                                                onChange: (e, index, value) =>
                                                                    arl.onChange(value),
                                                            }}
                                                        >
                                                            {_.orderBy(arlSeleted, ["value"]).map((item) => {
                                                                return (
                                                                    <MenuItem
                                                                        key={item._id}
                                                                        value={item._id}
                                                                        primaryText={item.value}
                                                                    />
                                                                );
                                                            })}
                                                        </SelectFieldFormat>
                                                    </Col>
                                                    <Col xs>
                                                        <SelectCountries
                                                            config={{
                                                                floatingLabelText: LBLCOMPANIES.country,
                                                            }}
                                                            field={country}
                                                            field2={department}
                                                            field3={city}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs>
                                                        <SelectDepartments
                                                            config={{
                                                                floatingLabelText: LBLCOMPANIES.department,
                                                            }}
                                                            field={department}
                                                            field2={city}
                                                            initialValue={initialDepartment}
                                                        />
                                                    </Col>
                                                    <Col xs>
                                                        <SelectCities
                                                            config={{floatingLabelText: LBLCOMPANIES.city}}
                                                            field={city}
                                                            initialValue={initialCity}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs>
                                                        <h2
                                                            style={{fontWeight: 200, textAlign: "center"}}
                                                        >
                                                            {LBLCOMPANIES.infoContact}
                                                        </h2>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs>
                                                        <TextField
                                                            hintText=""
                                                            floatingLabelText={LBLCOMPANIES.nameContact}
                                                            type="text"
                                                            errorText={
                                                                nameContact.touched && nameContact.error
                                                                    ? nameContact.error
                                                                    : ""
                                                            }
                                                            {...nameContact}
                                                        />
                                                    </Col>
                                                    <Col xs>
                                                        <TextField
                                                            hintText=""
                                                            floatingLabelText={LBLCOMPANIES.phone}
                                                            type="text"
                                                            errorText={
                                                                phoneContact.touched && phoneContact.error
                                                                    ? phoneContact.error
                                                                    : ""
                                                            }
                                                            {...phoneContact}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs>
                                                        <TextField
                                                            hintText=""
                                                            floatingLabelText={LBLCOMPANIES.email}
                                                            type="email"
                                                            errorText={
                                                                emailContact.touched && emailContact.error
                                                                    ? emailContact.error
                                                                    : ""
                                                            }
                                                            {...emailContact}
                                                        />
                                                    </Col>
                                                    <Col xs/>
                                                </Row>
                                                <Row>
                                                    <Col xs>
                                                        <h2
                                                            style={{
                                                                fontWeight: 200,
                                                                textAlign: "center",
                                                                marginTop: 20,
                                                            }}
                                                        >
                                                            {LBLCOMPANIES.Tags}
                                                        </h2>
                                                    </Col>
                                                </Row>
                                                {tags.map((tag, index) => (
                                                    <Row key={index}>
                                                        <Col xs={8} xsOffset={1}>
                                                            <PureInput
                                                                floatingLabelText={LBLCOMPANIES.tagsCompany}
                                                                type="text"
                                                                errorText={
                                                                    tag.value.touched && tag.value.error
                                                                        ? tag.value.error
                                                                        : ""
                                                                }
                                                                field={tag.value}
                                                                multiLine
                                                                fullWidth
                                                                rows={1}
                                                            />
                                                        </Col>
                                                        <Col xs={2}>
                                                            <DeleteEntityModal
                                                                tooltip={LBLCOMPANIES.tooltips.btnDelete}
                                                                title={LBLCOMPANIES.deleteTags}
                                                                message={LBLCOMPANIES.msg.deleteTags()}
                                                                fn={() => {
                                                                    tags.removeField(index);
                                                                }}
                                                                args={[tags]}
                                                                style={{display: "table-cell"}}
                                                            />
                                                        </Col>
                                                    </Row>
                                                ))}
                                                <Row>
                                                    <Col xs={10} xsOffset={1}>
                                                        <FlatButton
                                                            label={LBLCOMPANIES.buttons.addTags}
                                                            secondary
                                                            onClick={() => tags.addField()}
                                                            style={{marginTop: 25}}
                                                        />
                                                    </Col>
                                                </Row>
                                                <AuthComponent
                                                    component={
                                                        <RaisedButton
                                                            type="submit"
                                                            label={
                                                                editMode
                                                                    ? LBLCOMPANIES.buttons.update
                                                                    : LBLCOMPANIES.buttons.save
                                                            }
                                                            secondary
                                                            style={style}
                                                        />
                                                    }
                                                    permission="backend/companies:write"
                                                    type="component"
                                                />
                                            </form>
                                        </Col>
                                    </Row>
                                </Paper>
                            </Col>
                        }
                        permission="backend/companies:write"
                        type="url"
                    />
                </Row>
            );
        }
    }
}

const requireFields = (...names) => (data) =>
    names.reduce((errors, index) => {
        if (!data[index]) {
            errors[index] = LBLCOMPANIES.validations.isRequired;
        }
        return errors;
    }, {});

const validateTags = requireFields("value");

const validate = (values) => {
    const errors = {};
    if (!values.businessName) {
        errors.businessName = LBLCOMPANIES.validations.businessName;
    }
    if (!values.acronym) {
        errors.acronym = LBLCOMPANIES.validations.abbreviation;
    }
    if (!values.nameContact) {
        errors.nameContact = LBLCOMPANIES.validations.nameContact;
    }
    if (!values.nit) {
        errors.nit = LBLCOMPANIES.validations.nit;
    }
    if (!values.address) {
        errors.address = LBLCOMPANIES.validations.address;
    }
    if (!values.numEmployees) {
        errors.numEmployees = LBLCOMPANIES.validations.numberEmployees;
    }
    if (!values.economicActivity) {
        errors.economicActivity = LBLCOMPANIES.validations.ActivityEconomic;
    }
    if (!values.phone) {
        errors.phone = LBLCOMPANIES.validations.phone;
    }
    if (!values.phoneContact) {
        errors.phoneContact = LBLCOMPANIES.validations.phone;
    }
    if (!values.department) {
        errors.department = LBLCOMPANIES.validations.department;
    }
    if (!values.city) {
        errors.city = LBLCOMPANIES.validations.city;
    }
    if (!values.arl) {
        errors.arl = LBLCOMPANIES.validations.arl;
    }
    if (!values.country) {
        errors.country = LBLCOMPANIES.validations.country;
    }
    if (!values.email) {
        errors.email = LBLCOMPANIES.validations.email;
    } else if (!is.email(values.email)) {
        errors.email = LBLCOMPANIES.validations.validateEmail;
    }
    if (!values.emailContact) {
        errors.emailContact = LBLCOMPANIES.validations.email;
    } else if (!is.email(values.emailContact)) {
        errors.emailContact = LBLCOMPANIES.validations.validateEmail;
    }

    errors.tags = values.tags.map(validateTags);

    return errors;
};

const asyncValidate = (values /* , dispatch */) => {
    return new Promise((resolve, reject) => {
        axiosAPI
            .get(`/companies/id`, {
                params: {nit: values.nit},
            })
            .then((data) => {
                const resp = _.get(data.data, "data");
                if (!_.isNull(resp)) {
                    if (_.isNull(values._id)) {
                        reject({nit: LBLCOMPANIES.msg.errorExistNit});
                    } else {
                        resolve();
                    }
                } else {
                    resolve();
                }
            })
            .catch((data) => {
                console.log("Error", data);
            });
    });
};

function mapStateToProps({companies, master}, ownProps) {
    if (_.has(ownProps, "params._id")) {
        return {
            company: companies.company,
            initialDepartment: _.get(companies.company.data, "country"),
            initialCity: _.get(companies.company.data, "department"),
            initialValues: companies.company.data,
            employeesNumber: master.employeesNumber,
            economicActivities: master.economicActivities,
            arlSeleted: master.arls,
        };
    }
    return {
        company: companies.company,
        employeesNumber: master.employeesNumber,
        economicActivities: master.economicActivities,
        initialDepartment: undefined,
        initialCity: undefined,
        arlSeleted: master.arls,
        initialValues: {
            businessName: "",
            nit: "",
            acronym: "",
            address: "",
            location: "",
            phone: "",
            email: "",
            nameContact: "",
            phoneContact: "",
            emailContact: "",
            economicActivity: "",
            numEmployees: "",
            country: "",
            department: "",
            city: "",
            arl: "",
            tags: [],
            _id: null,
        },
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            createCompany,
            updateCompany,
            FetchCompanyParam,
            toggleSnackbar,
            getEconomicActivities,
            getEmployeesNumber,
            getARLs,
        },
        dispatch
    );
}

CompaniesNews.propTypes = {
    asyncValidating: PropTypes.string.isRequired,
    router: PropTypes.object,
};

export default reduxForm(
    {
        form: "CompaniesNewForm",
        fields: [
            "businessName",
            "nit",
            "acronym",
            "address",
            "phone",
            "email",
            "nameContact",
            "phoneContact",
            "emailContact",
            "economicActivity",
            "numEmployees",
            "country",
            "department",
            "city",
            "arl",
            "tags[].value",
            "_id",
        ],
        asyncValidate,
        asyncBlurFields: ["nit"],
        validate,
    },
    mapStateToProps,
    mapDispatchToProps
)(CompaniesNews);
