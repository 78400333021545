import React, { Component } from "react";
import PropTypes from "prop-types";
import { TableHeaderColumn } from "material-ui/Table";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import UpIcon from "material-ui/svg-icons/navigation/arrow-drop-up";
import DownIcon from "material-ui/svg-icons/navigation/arrow-drop-down";
import SortIcon from "material-ui/svg-icons/av/sort-by-alpha";
import { blue500, grey400 } from "material-ui/styles/colors";
import { getRecords, setInSearchRecords } from "./actions";

class TableHeadColumn extends Component {
  constructor(props) {
    super(props);
    this._handleOrderRecords = this._handleOrderRecords.bind(this);
    this._renderIconOrder = this._renderIconOrder.bind(this);
    this._renderIconColumnSort = this._renderIconColumnSort.bind(this);
    this._renderIconColumnSort2 = this._renderIconColumnSort2.bind(this);
  }

  _handleOrderRecords(field, order) {
    if (order) {
      const {
        URL,
        version,
        limit,
        skip,
        orderName,
        orderType,
        textFilter,
        arrayFields,
        getRecords,
        setInSearchRecords,
        extra,
        coreName,
      } = this.props;
      let ot = 0;
      if (_.isEqual(orderName, field)) {
        ot = orderType * -1;
      } else {
        ot = 1;
      }
      setInSearchRecords(coreName);
      if (!_.isUndefined(extra) && !_.isNull(extra)) {
        getRecords(
          URL,
          version,
          skip,
          limit,
          field,
          ot,
          arrayFields,
          textFilter,
          coreName,
          extra
        );
      } else {
        getRecords(
          URL,
          version,
          skip,
          limit,
          field,
          ot,
          arrayFields,
          textFilter,
          coreName
        );
      }
    }
  }

  _renderTitleOrder(title, field, order) {
    const styleColumnOrder = {
      display: "inline-block",
      cursor: order ? "pointer" : "default",
    };
    return (
      <div
        style={styleColumnOrder}
        onClick={() => this._handleOrderRecords(field, order)}
      >
        {title}
      </div>
    );
  }

  _renderIconOrder(field) {
    const { orderName, orderType } = this.props;
    if (
      _.isEqual(orderName, field) &&
      !_.isNull(field) &&
      !_.isEqual(orderType, 0)
    ) {
      if (_.isEqual(orderType, 1)) {
        return (
          <div style={{ display: "inline-block" }}>
            <DownIcon
              style={{
                fill: blue500,
                marginTop: -5,
                marginBottom: -10,
                width: 30,
                height: 30,
              }}
            />
          </div>
        );
      }
      return (
        <div style={{ display: "inline-block" }}>
          <UpIcon
            style={{
              fill: blue500,
              marginTop: -5,
              marginBottom: -10,
              width: 30,
              height: 30,
            }}
          />
        </div>
      );
    }
    return <div />;
  }

  _renderIconColumnSort(order) {
    const styleIconSort = {
      width: 21,
      height: 21,
      fill: grey400,
      marginLeft: 10,
    };
    return (
      <div style={{ float: "right", width: 21, height: 21 }}>
        {order ? (
          <SortIcon style={styleIconSort} />
        ) : (
          <div style={styleIconSort} />
        )}
      </div>
    );
  }

  _renderIconColumnSort2(order) {
    const styleIconSort = {
      width: 21,
      height: 21,
      fill: grey400,
      marginLeft: -10,
    };
    return (
      <div style={{ float: "left", width: 21, height: 21 }}>
        {order ? (
          <SortIcon style={styleIconSort} />
        ) : (
          <div style={styleIconSort} />
        )}
      </div>
    );
  }

  render() {
    const { name, total, URL, version, field } = this.props;
    if (_.has(field, "actions")) {
      return (
        <TableHeaderColumn colSpan={_.get(field, "colspan", 1)}>
          {_.get(field, "title", "-")}
        </TableHeaderColumn>
      );
    }
    return (
      <TableHeaderColumn colSpan={_.get(field, "colspan", 1)}>
        {this._renderIconColumnSort(_.get(field, "order", true))}
        {this._renderTitleOrder(
          _.get(field, "title", "-"),
          _.get(field, "field", null),
          _.get(field, "order", true)
        )}
        {this._renderIconOrder(_.get(field, "field", null))}
      </TableHeaderColumn>
    );
  }
}

function mapStateToProps({ genericTablePagination }, ownProps) {
  const store = genericTablePagination.get(
    _.get(ownProps, "coreName", "default")
  );
  return {
    skip: store.get("skip"),
    limit: store.get("limit"),
    orderName: store.get("orderName"),
    orderType: store.get("orderType"),
    textFilter: store.get("textFilter"),
    arrayFields: store.get("arrayFields"),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getRecords,
      setInSearchRecords,
    },
    dispatch
  );
}

TableHeadColumn.propTypes = {
  URL: PropTypes.string.isRequired,
  version: PropTypes.number.isRequired,
  field: PropTypes.object.isRequired,
  extra: PropTypes.object,
  coreName: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(TableHeadColumn);
