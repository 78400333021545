import {authClient} from "Api";
import {
    UsersPage,
    GetUsersInput,
    UserInput,
    DeleteUserInput,
    UpdateUserInput,
    ResetPasswordInput,



} from "../../../components/users/types";

const getUserTypes = [
    {value: 'INTERNAL', label: 'AZURE'},
    {value: 'EXTERNAL', label: 'CGR'}
]

const getUserProfiles = [
    {value: 'ADMIN', label: 'Admin'},
    {value: 'OPERATOR', label: 'Operario'},
    {value: 'EVALUATOR', label: 'Evaluador'},
    {value: 'COMPANY', label: 'Empresa'},
    {value: 'EMPLOYEE', label: 'Empleado'},
    {value: 'ARL', label: 'ARL'},
]

const getDocumentTypes = [
    {value: 'CC', label: 'Cédula de ciudadanía'},
    {value: 'NIT', label: 'NIT'},
    {value: 'TI', label: 'Tarjeta de identidad'},
    {value: 'PA', label: 'Pasaporte'},
    {value: 'RC', label: 'Registro civil'},
    {value: 'CE', label: 'Cédula de extranjería'},
]

async function getUsers(key, input: GetUsersInput): Promise<UsersPage> {
    const response = await authClient.get<UsersPage>("", {
        params: input,
    });
    return response.data;
}

async function saveUser(input: UserInput): Promise<{}> {
    return authClient.post<{}>("", input);
}

async function updateUser(input: UpdateUserInput): Promise<{}> {
    return authClient.put<{}>(`/${input.id}`,input);
}

async function deleteUser(input: DeleteUserInput): Promise<{}> {
    return authClient.delete<{}>(`/${input.userId}`);
}

async function resetPassword(input: ResetPasswordInput): Promise<{}> {
    const response = await authClient.patch<{}>(`/${input.userId}:reset`, {sendNotification: true});
    return response.data;
}

const operations = {
    getUsers,
    saveUser,
    deleteUser,
    updateUser,
    resetPassword,
    getUserTypes,
    getUserProfiles,
    getDocumentTypes,

};

export default operations;
