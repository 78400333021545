import React, { createContext, useContext, useState } from 'react';

const ComplementContext = createContext({
  complements: [],
  selectedComplement: {},
});

const ComplementProvider = ({ children }) => {
  const [complements, setComplements] = useState([]);
  const [selectedComplement, setSelectedComplement] = useState({});

  return (
    <ComplementContext.Provider
      value={{
        complements,
        selectedComplement,
        setComplements,
        setSelectedComplement,
      }}
    >
      {children}
    </ComplementContext.Provider>
  );
};

export const useComplementContext = () => {
  return useContext(ComplementContext);
};

export default ComplementProvider;
