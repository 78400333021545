import PropTypes from "prop-types";
import React, { Component } from "react";
import { Row, Col } from "react-flexbox-grid";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import RaisedButton from "material-ui/RaisedButton";
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from "material-ui/Table";
import { FetchListRefereesInfoParam } from "../../../actions/info/projects_info_form";
import { LBLEVALUATORS } from "../../../constants/labels";
import AuthComponent from "../../AuthComponent";
import { toggleSnackbar } from "../../../actions/commons";

class RefereesInfoProjectComponent extends Component {
  /* {
    height: 'auto',
    padding: 0
} */
  constructor(props) {
    super(props);
    this._toUpdateReferees = this._toUpdateReferees.bind(this);
  }

  UNSAFE_componentWillMount() {
    this._toUpdateReferees();
  }

  _toUpdateReferees() {
    const { projectId, FetchListRefereesInfoParam } = this.props;
    FetchListRefereesInfoParam(projectId);
  }

  renderReferees(referees = []) {
    return _.map(referees, (referee) => {
      const refereeName = _.get(referee, "person.name");
      const refereeLastName = _.get(referee, "person.lastName");
      const refereeIdNumber = _.get(referee, "person.id");
    
      return (
        <TableRow key={referee._id}>
          <TableRowColumn style={{ wordWrap: "break-word" }}>
            {refereeIdNumber}
          </TableRowColumn>
          <TableRowColumn colSpan="3">{`${refereeName} ${refereeLastName}`}</TableRowColumn>
        </TableRow>
      );
    });
  }

  renderRefereesBody(referees) {
    if (_.size(referees)) {
      return (
        <TableBody displayRowCheckbox={false}>
          {this.renderReferees(referees)}
        </TableBody>
      );
    }
    return <TableBody />;
  }

  render() {
    const { refereesInfo, urlBase } = this.props;
    if (!_.isNull(refereesInfo)) {
      return (
        <div style={{ height: "auto", padding: 0 }}>
          <Row>
            <Col xs style={{ padding: "0 30px" }}>
              <h2
                style={{
                  textAlign: "center",
                  fontWeight: 400,
                  marginTop: 40,
                  marginBottom: 0,
                }}
              >
                {LBLEVALUATORS.titleTap}
              </h2>
              <AuthComponent
                component={
                  <Link to={urlBase}>
                    <RaisedButton
                      label={LBLEVALUATORS.tooltips.btnAdd}
                      secondary
                      style={{ margin: 5, float: "right" }}
                    />
                  </Link>
                }
                permission="backend/referees:write"
                type="component"
              />
            </Col>
          </Row>
          <Row>
            <Col xs>
              <Table selectable={false}>
                <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
                  <TableRow>
                    <TableHeaderColumn>{LBLEVALUATORS.id}</TableHeaderColumn>
                    <TableHeaderColumn colSpan="3">
                      {LBLEVALUATORS.name}
                    </TableHeaderColumn>
                  </TableRow>
                </TableHeader>
                {this.renderRefereesBody(refereesInfo)}
              </Table>
            </Col>
          </Row>
        </div>
      );
    }
    return <div />;
  }
}

function mapStateToProps({ projects }) {
  return {
    refereesInfo: _.get(projects, "refereesInfo", []),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      FetchListRefereesInfoParam,
    },
    dispatch
  );
}

RefereesInfoProjectComponent.propTypes = {
  projectId: PropTypes.string.isRequired,
  urlBase: PropTypes.string.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RefereesInfoProjectComponent);
