import React from "react";
import PropTypes from "prop-types";
import FaqActionColumn from "./faqActionColumn";
import LisMasterActionColumn from "./lisMasterActionColumn";
import SurveyActionColumn from "./surveyActionColumn";
import QuestionActionColumn from "./questionActionColumn";
import ConsentActionColumn from "./consentActionColumn";
import RefereeActionColumn from "./refereeActionColumn";
import CompanyActionColumn from "./companyActionColumn";
import ProfileActionColumn from "./profileActionColumn";
import TemplateEmailActionColumn from "./templateEmailActionColumn";
import CountryActionColumn from "./countryActionColumn";
import DepartmentActionColumn from "./departmentActionColumn";
import CityActionColumn from "./cityActionColumn";
import EmployeeActionColumn from "./employeeActionColumn";
import ProjectActionColumn from "./projectActionColumn";
import EmployeeProjectActionColumn from "./employeeProjectActionColumn";
import EmployeeStatusProjectActionColumn from "./employeeStatusProjectActionColumn";
import ProjectByRefereeActionColumn from "./ProjectByRefereeActionColumn";
import EmployeeStatusProjectForCompanyActionColumn from "./employeeStatusProjectForCompanyActionColumn";
import UserActionColumn from "./userActionColumn";

const ActionsColumn = (props) => {
    const { name, extra, record, index, colSpan } = props;
    switch (name) {
        case "faqs":
            return (
                <FaqActionColumn
                    key={`body${index}`}
                    colSpan={colSpan}
                    data={record}
                />
            );
        case "listmaster":
            return (
                <LisMasterActionColumn
                    key={`body${index}`}
                    colSpan={colSpan}
                    data={record}
                />
            );
        case "surveys":
            return (
                <SurveyActionColumn
                    key={`body${index}`}
                    colSpan={colSpan}
                    data={record}
                />
            );
        case "questions":
            return (
                <QuestionActionColumn
                    key={`body${index}`}
                    colSpan={colSpan}
                    data={record}
                />
            );
        case "consents":
            return (
                <ConsentActionColumn
                    key={`body${index}`}
                    colSpan={colSpan}
                    data={record}
                />
            );
        case "referees":
            return (
                <RefereeActionColumn
                    key={`body${index}`}
                    colSpan={colSpan}
                    data={record}
                />
            );
        case "companies":
            return (
                <CompanyActionColumn
                    key={`body${index}`}
                    colSpan={colSpan}
                    data={record}
                />
            );
        case "profiles":
            return (
                <ProfileActionColumn
                    key={`body${index}`}
                    colSpan={colSpan}
                    data={record}
                />
            );
        case "templateEmail":
            return (
                <TemplateEmailActionColumn
                    key={`body${index}`}
                    colSpan={colSpan}
                    data={record}
                />
            );
        case "countries":
            return (
                <CountryActionColumn
                    key={`body${index}`}
                    colSpan={colSpan}
                    data={record}
                />
            );
        case "departments":
            return (
                <DepartmentActionColumn
                    key={`body${index}`}
                    colSpan={colSpan}
                    data={record}
                    extra={extra}
                />
            );
        case "cities":
            return (
                <CityActionColumn
                    key={`body${index}`}
                    colSpan={colSpan}
                    data={record}
                    extra={extra}
                />
            );
        case "employees":
            return (
                <EmployeeActionColumn
                    key={`body${index}`}
                    colSpan={colSpan}
                    data={record}
                    extra={extra}
                />
            );
        case "projects":
            return (
                <ProjectActionColumn
                    key={`body${index}`}
                    colSpan={colSpan}
                    data={record}
                    extra={extra}
                />
            );
        case "projectsByReferee":
            return (
                <ProjectByRefereeActionColumn
                    key={`body${index}`}
                    colSpan={colSpan}
                    data={record}
                    extra={extra}
                />
            );
        case "configemployees":
            return (
                <EmployeeProjectActionColumn
                    key={`body${index}`}
                    colSpan={colSpan}
                    data={record}
                    extra={extra}
                />
            );
        case "configemployeesstatus":
            return (
                <EmployeeStatusProjectActionColumn
                    key={`body${index}`}
                    colSpan={colSpan}
                    data={record}
                    extra={extra}
                />
            );
        case "configemployeesstatuscompany":
            return (
                <EmployeeStatusProjectForCompanyActionColumn
                    key={`body${index}`}
                    colSpan={colSpan}
                    data={record}
                    extra={extra}
                />
            );
        case "users":
            return (
                <UserActionColumn
                    key={`body${index}`}
                    colSpan={colSpan}
                    data={record}
                    extra={extra}
                />
            );
        default:
            return null;
    }
};

ActionsColumn.defaultProps = {
    extra: {},
};

ActionsColumn.propTypes = {
    name: PropTypes.string.isRequired,
    extra: PropTypes.shape({}),
    record: PropTypes.shape({}).isRequired,
    index: PropTypes.number.isRequired,
    colSpan: PropTypes.number.isRequired,
};

export default ActionsColumn;
