import PropTypes from "prop-types";
import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Row, Col } from "react-flexbox-grid";
import Paper from "material-ui/Paper";
import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";
import _ from "lodash";
import CircularProgress from "material-ui/CircularProgress";
import { createSurvey } from "../actions/surveys_form";
import { FetchOnlySurveyParam } from "../actions/info/surveys_info_form";
import { toggleSnackbar } from "../actions/commons";
import { LBLSURVEYS } from "Labels";
import ButtonBack from "../components/fields/buttonBack/buttonBack";
import AuthComponent from "../components/AuthComponent";
import { axiosAPI } from "Api";

const style = {
  margin: 20,
  float: "right",
};
const stylePaper = {
  marginTop: 20,
  marginBottom: 20,
};

class SurveysNews extends Component {
  constructor(props) {
    super(props);

    this._saveSurveys = this._saveSurveys.bind(this);
  }

  _saveSurveys(formData) {
    const { createSurvey, resetForm, toggleSnackbar, params } = this.props;
    const editMode = _.has(params, "_id");
    createSurvey(editMode ? formData : _.omit(formData, "_id"), editMode)
      .then(
        data => {
          if (_.get(data, "error") || _.get(data, "payload.status") !== 200) {
            toggleSnackbar(true, LBLSURVEYS.msg.errorSave);
          } else {
            resetForm();
            toggleSnackbar(true, LBLSURVEYS.msg.successSave);
            if (editMode) {
              this.props.history.push(`/admin/surveys/info/${params._id}`);
            } else {
              this.props.history.push(`/admin/surveys/grid`);
            }
          }
        },
        reason => {
          toggleSnackbar(true, LBLSURVEYS.msg.errorSave);
        }
      )
      .catch(data => {
        toggleSnackbar(true, LBLSURVEYS.msg.errorSave);
      });
  }

  UNSAFE_componentWillMount() {
    if (_.has(this, "props.params._id")) {
      this.props.FetchOnlySurveyParam(this.props.params._id);
    }
  }

  render() {
    const {
      asyncValidating,
      fields: { name, description, _id },
      handleSubmit,
      resetForm,
      params,
    } = this.props;
    const editMode = _.has(params, "_id");
    let urlBack = "";
    if (editMode) {
      urlBack = `/admin/surveys/info/${params._id}`;
    } else {
      urlBack = `/admin/surveys/grid`;
    }
    return (
      <Row>
        <Col mdOffset={3} md={6} xs={12}>
          <Paper style={stylePaper}>
            <Row>
              <Col xsOffset={1} xs={10}>
                <ButtonBack url={urlBack} />
                <h1
                  style={{
                    textAlign: "center",
                    fontWeight: 400,
                  }}
                >
                  {editMode ? LBLSURVEYS.titleEdit : LBLSURVEYS.titleCreate}
                </h1>
                <form onSubmit={handleSubmit(this._saveSurveys)}>
                  <Row>
                    <Col xs>
                      <TextField
                        floatingLabelText={LBLSURVEYS.name}
                        type="text"
                        fullWidth
                        errorText={name.touched && name.error ? name.error : ""}
                        {...name}
                      />
                      {asyncValidating === "name" && (
                        <CircularProgress
                          size={0.8}
                          style={{ position: "absolute", float: "right" }}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs>
                      <TextField
                        hintText=""
                        floatingLabelText={LBLSURVEYS.description}
                        multiLine
                        rows={4}
                        fullWidth
                        errorText={
                          description.touched && description.error
                            ? description.error
                            : ""
                        }
                        {...description}
                      />
                    </Col>
                  </Row>
                  <AuthComponent
                    component={
                      <RaisedButton
                        type="submit"
                        label={
                          editMode
                            ? LBLSURVEYS.buttons.update
                            : LBLSURVEYS.buttons.save
                        }
                        secondary
                        style={style}
                      />
                    }
                    permission="backend/surveys:write"
                    type="component"
                  />
                </form>
              </Col>
            </Row>
          </Paper>
        </Col>
      </Row>
    );
  }
}

const validate = values => {
  const errors = {};
  if (!values.name) {
    errors.name = LBLSURVEYS.validations.name;
  }
  if (!values.description) {
    errors.description = LBLSURVEYS.validations.description;
  }
  return errors;
};

const asyncValidate = values => {
  return new Promise((resolve, reject) => {
    axiosAPI
      .get(`/surveys/id`, {
        params: {
          _id: values._id,
          name: values.name,
        },
      })
      .then(data => {
        const resp = _.get(data.data, "data");
        if (!_.isNull(resp)) {
          if (_.isUndefined(values.name) || _.isEqual(values.name, "")) {
            reject({ name: LBLSURVEYS.validations.name });
          } else {
            reject({ name: LBLSURVEYS.validations.existName });
          }
        } else if (_.isUndefined(values.name) || _.isEqual(values.name, "")) {
          reject({ name: LBLSURVEYS.validations.name });
        } else {
          resolve();
        }
      })
      .catch(data => {
        console.log("Error", data);
      });
  });
};

function mapStateToProps(state, ownProps) {
  if (_.has(ownProps, "params._id")) {
    return {
      initialValues: state.surveys.all.data,
      _id: ownProps.params._id,
    };
  }
  return {
    initialValues: {
      name: "",
      description: "",
      _id: "",
    },
  };
}

SurveysNews.propTypes = {
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  router: PropTypes.object,
};

export default reduxForm(
  {
    form: "SurveysNewForm",
    fields: ["name", "description", "_id"],
    asyncValidate,
    asyncBlurFields: ["name"],
    validate,
  },
  mapStateToProps,
  { createSurvey, FetchOnlySurveyParam, toggleSnackbar }
)(SurveysNews);
