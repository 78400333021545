import * as React from 'react'
import {Col} from "react-flexbox-grid";
import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    formControl: {
        marginBottom: theme.spacing(2),
        minWidth: 250
    },
    helperTextColor: {
        color: "red"
    }
}))

type ArrayValues = {
    label: string
    value: string
}

type OptionsSelectorProps = {
    options: Array<ArrayValues>
    name: string
    value: string
    label: string
    handleChange: any
    errors: any
    touched: any
    errorText: string
}

/**
 * Generic component for select field
 * This component works specifically for forms with Formik
 * @author Jhoan Lopez <jhoan.lopez@ias.com.co>
 * @param options
 * @param name
 * @param label
 * @param value
 * @param handleChange
 * @param errors
 * @param touched
 * @param errorText
 * @constructor
 */
const OptionsSelector: React.FC<OptionsSelectorProps> = ({
                                                             options,
                                                             name,
                                                             label,
                                                             value,
                                                             handleChange,
                                                             errors,
                                                             touched,
                                                             errorText
                                                         }) => {
    const classes = useStyles()

    return (
        <Col xs={12}>
            <FormControl className={classes.formControl}>
                <InputLabel>{label}</InputLabel>
                <Select
                    name={name}
                    value={value}
                    onChange={handleChange}
                    error={
                        touched[name] &&
                        Boolean(errors[name])
                    }
                >
                    {options.map((item, key) => (
                        <MenuItem key={`${item.value}-${key}`}
                                  value={item.value}>{item.label}</MenuItem>
                    ))}
                </Select>
                {Boolean(errors[name]) &&
                    <FormHelperText className={classes.helperTextColor}>
                        {errorText}
                    </FormHelperText>
                }
            </FormControl>
        </Col>
    )
}

export default OptionsSelector;