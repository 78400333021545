import { axiosApiV2 } from "../../../../middleware/api";
import {
  SET_INITIAL_LOCATION_QUESTION,
  SAVE_VALUE_DEPARTMENT_QUESTION_LOCATION,
  DELETE_VALUE_DEPARTMENT_QUESTION_LOCATION,
  GET_COUNTRIES_QUESTION_SURVEY,
  GET_DEPARTMENTS_QUESTION_SURVEY,
  GET_CITIES_QUESTION_SURVEY,
  SAVE_VALUE_COUNTRY_QUESTION_LOCATION,
} from "./constants";

export function setInitialLocationQuestionState() {
  return {
    type: SET_INITIAL_LOCATION_QUESTION,
  };
}

export function getCountries(id) {
  const request = axiosApiV2.get(`/location/countries`);

  return {
    type: GET_COUNTRIES_QUESTION_SURVEY,
    payload: request,
    meta: {
      id,
    },
  };
}

export function getDepartments(id, idCountry) {
  const request = axiosApiV2.get(`/location/departments/${idCountry}`);

  return {
    type: GET_DEPARTMENTS_QUESTION_SURVEY,
    payload: request,
    meta: {
      id,
    },
  };
}

export function getCities(id, idDepartment) {
  const request = axiosApiV2.get(`/location/cities/${idDepartment}`);

  return {
    type: GET_CITIES_QUESTION_SURVEY,
    payload: request,
    meta: {
      id,
    },
  };
}

export function saveCountryInQuestionDepartment(id, idCountry) {
  return {
    type: SAVE_VALUE_COUNTRY_QUESTION_LOCATION,
    meta: {
      id,
      idCountry,
    },
  };
}

export function saveDepartmentInQuestionDepartment(id, idDepartment) {
  return {
    type: SAVE_VALUE_DEPARTMENT_QUESTION_LOCATION,
    meta: {
      id,
      idDepartment,
    },
  };
}

export function deleteDepartmentInQuestionDepartment(id) {
  return {
    type: DELETE_VALUE_DEPARTMENT_QUESTION_LOCATION,
    meta: {
      id,
    },
  };
}
