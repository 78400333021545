import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Typography, Tooltip, Fab, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { Col, Row } from "react-flexbox-grid";
import { useNotifications } from "Notifications";
import AddSource from "./addSource";
import { useRemoveDatasetColumn } from "../../../data";
import SourcesList from "./sourcesList";
import AlertDialog from "../../../../commons/alert-dialog";
import definedSources, { metadata } from "./options";

const useStyles = makeStyles(theme => ({
  root: {
    width: "80%",
    padding: 8,
    margin: 4,
  },
  title: {
    fontSize: 14,
  },
  actionButton: {
    marginRight: theme.spacing(1),
  },
}));

type RenderColumnProps = {
  column: any;
  dataset: any;
};

/**
 * Component to render dataset column
 * @author Jhoan Lopez <jhoan.lopez@ias.com.co>
 * @param column
 * @param key
 * @constructor
 */
const RenderColumn: React.FC<RenderColumnProps> = ({ column, dataset }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const { mutate, loading } = useRemoveDatasetColumn();
  const { sendNotification } = useNotifications();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleDeleteColumn = async () => {
    await mutate({
      variables: {
        request: { datasetId: dataset.datasetId, columnId: column.id },
      },
      optimisticResponse: {},
    })
      .then(() => {
        handleCloseDelete();
        sendNotification("Columna eliminada correctamente");
      })
      .catch(() => {
        sendNotification("Ocurrió un error al eliminar la columna");
      });
  };

  return (
    <Card className={classes.root} raised={false}>
      <Row>
        <Col xs>
          <Typography>{column.name}</Typography>
          <Typography variant="caption">{metadata[column.type]}</Typography>
        </Col>
        <Col xs>
          <Row end="xs">
            {definedSources[column.type] === undefined && (
              <Button
                size="small"
                color="secondary"
                className={classes.actionButton}
                startIcon={<AddIcon />}
                onClick={handleClickOpen}
              >
                Fuente
              </Button>
            )}
            <Tooltip title="Eliminar columna">
              <Fab
                size="small"
                color="primary"
                onClick={handleClickOpenDelete}
                className={classes.actionButton}
              >
                <DeleteIcon />
              </Fab>
            </Tooltip>
          </Row>
        </Col>
      </Row>
      {definedSources[column.type] === undefined && (
        <SourcesList column={column} datasetId={dataset.datasetId} />
      )}
      <AddSource
        open={open}
        handleClose={handleClose}
        datasetId={dataset.datasetId}
        column={column}
      />
      <AlertDialog
        title="¿Está seguro de eliminar la columna?"
        description=""
        open={openDelete}
        handleClose={handleCloseDelete}
        handleAccept={handleDeleteColumn}
        disabled={loading}
      />
    </Card>
  );
};

export default RenderColumn;
