import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { preSaveAnswer, updateAnswers } from './actions';

const MixinClassFn = (InnerComponent) => {

    return class extends Component {
        constructor(props) {
            super(props);
            this._handlerUpdateValue = this._handlerUpdateValue.bind(this);
        }

        _handlerUpdateValue(event, value, typeQuestion) {
            const { preSaveAnswer, idQuestion, sectionOrder, idProject, updateAnswers } = this.props;
            if(!_.isUndefined(idProject)){
                preSaveAnswer(idQuestion, sectionOrder, value, idProject);
            }
            /* if(_.isEqual(typeQuestion, "ListOpen") && _.size(value) !== 0){
                _.forEach(value, (item) => {
                    if(_.isEqual(item,"otherWhich##")) {
                        updateAnswers(idQuestion, []);
                    } else {
                        updateAnswers(idQuestion, value);
                    }
                });
            } else {
                updateAnswers(idQuestion, value);
            } */
            updateAnswers(idQuestion, value);
        }

        render() {
            return (
                <InnerComponent
                    _handlerUpdateValue={this._handlerUpdateValue}
                    {...this.state}
                    {...this.props}
                />
            );
        }
    }
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        preSaveAnswer,
        updateAnswers
    }, dispatch);
}

function mapStateToProps({answers}, ownProps) {
    const idQuestion = `${ownProps.idQuestion}:${ownProps.idSection}`;
    return {
        answer: answers.get(idQuestion)
    }
}

export default (InnerComponent) => {
    const MixinComponent = MixinClassFn(InnerComponent);
    return connect(mapStateToProps, mapDispatchToProps)(MixinComponent);
};
