import { assign, createMachine } from "xstate";
import { authClient, axiosAnon } from "Api";
import Auth from "@aws-amplify/auth";

import scopes from "../scopes";

const redirectMap = {
  OPERATOR: "/admin",
  EVALUATOR: "/admin/me/assignprojects",
  COMPANY: "/dashboard/company",
  EMPLOYEE: "/dashboard/employee",
  ADMIN: "/admin",
  ARL: "/dashboard/arl",
};

async function getUserProfile(user) {
  const { data } = await authClient.get("/me");
  const { profiles } = data.user;
  const { information, id: personId } = data.person;
  const permissions = profiles
    .map(profile => scopes[profile])
    .reduce((acc, current) => [...acc, ...current], []);
  const redirectUri = redirectMap[profiles[0]];

  return {
    user,
    profile: {
      permissions,
      redirectUri,
      profile: {
        firstPassword: false,
        person: {
          _id: personId,
          name: information.firstName,
          lastName: information.lastName,
        },
      },
    },
  };
}

function resendUserPassword(user) {
  return axiosAnon.put(`/users/${user}/resend`);
}

const machine = createMachine(
  {
    id: "auth",
    initial: "checkingAuthenticatedUser",
    context: {
      credentials: null,
      authenticatedUser: null,
      loginError: null,
      resetPasswordUsername: null,
      resetPasswordError: null,
      resetPasswordCredentials: null,
    },
    states: {
      checkingAuthenticatedUser: {
        invoke: {
          src: "getAuthenticatedUser",
          onDone: {
            target: "authenticationSucceed",
            actions: ["setAuthenticatedUser", "onSignIn"],
          },
          onError: {
            target: "loginForm",
          },
        },
      },
      loginForm: {
        on: {
          SUBMIT: {
            target: "authenticationInProgress",
            actions: ["setUserCredentials"],
          },
          RESET_PASSWORD: {
            target: "resetPassword",
          },
        },
      },
      authenticationInProgress: {
        invoke: {
          id: "submitAuthentication",
          src: "authenticateUser",
          onError: {
            target: "loginForm",
            actions: ["setAuthenticationError", "onInvalidCredentials"],
          },
          onDone: [
            {
              target: "changeInitialPassword",
              cond: "isNewPasswordRequired",
              actions: ["setPasswordData"],
            },
            {
              target: "getUserProfile",
              actions: ["setAuthenticatedUser"],
            },
          ],
        },
      },
      getUserProfile: {
        invoke: {
          id: "getUserProfileRequest",
          src: "getUserProfile",
          onDone: {
            target: "authenticationSucceed",
            actions: ["onSignIn"],
          },
          onError: {
            target: "getUserProfile",
          },
        },
      },
      changeInitialPassword: {
        initial: "form",
        states: {
          form: {
            on: {
              SUBMIT: {
                target: "submitting",
                actions: ["setNewPassword"],
              },
            },
          },
          submitting: {
            invoke: {
              id: "setNewPasswordOperation",
              src: "completeNewPassword",
              onDone: {
                target: "complete",
                actions: ["setAuthenticatedUser"],
              },
              onError: {
                target: "submitting",
              },
            },
          },
          complete: {
            type: "final",
          },
        },
        onDone: {
          target: "getUserProfile",
        },
      },
      authenticationSucceed: {
        type: "final",
      },
      resetPassword: {
        initial: "userForm",
        states: {
          userForm: {
            on: {
              SUBMIT_RESET_PASSWORD_USER: {
                target: "submittingUser",
                actions: ["setUserReset"],
              },
              GO_TO_LOGIN: {
                target: "successPasswordRest",
              },
            },
          },
          submittingUser: {
            invoke: {
              id: "submitUserReset",
              src: "submitUserReset",
              onDone: {
                target: "codeForm",
              },
              onError: [
                {
                  cond: "userHasNotSignInYet",
                  target: "userHasNotSignIn",
                },
                {
                  target: "userForm",
                  actions: ["setErrorUserReset", "onInvalidUser"],
                },
              ],
            },
          },
          userHasNotSignIn: {
            invoke: {
              id: "resendUserCredentialsOperation",
              src: "resendUserCredentials",
              onDone: {
                target: "successPasswordRest",
                actions: ["onResendComplete", "removeUserReset"],
              },
            },
          },
          codeForm: {
            on: {
              SUBMIT_RESET_PASSWORD_CODE: {
                target: "submittingCode",
                actions: ["setResetPasswordCredentials"],
              },
            },
          },
          submittingCode: {
            invoke: {
              id: "submitUserCodeReset",
              src: "submitUserCodeReset",
              onDone: {
                target: "successPasswordRest",
                actions: ["onResetComplete"],
              },
              onError: {
                target: "codeForm",
                actions: ["setErrorUserCodeReset", "onSendCodeError"],
              },
            },
          },
          successPasswordRest: {
            type: "final",
          },
        },
        onDone: {
          target: "loginForm",
          actions: "removeUserReset",
        },
      },
    },
  },
  {
    actions: {
      setUserCredentials: assign((_ctx, event) => ({
        credentials: event.data,
      })),
      setNewPassword: assign({
        newPassword: (ctx, event) => event.newPassword,
      }),
      setAuthenticationError: assign({
        loginError: (_ctx, event) => event.data.error,
      }),
      setAuthenticatedUser: assign({
        authenticatedUser: (_ctx, event) => event.data,
      }),
      setResetPasswordCredentials: assign({
        resetPasswordCredentials: (_ctx, event) => event.data,
      }),
      removeAuthenticatedUser: assign({
        authenticatedUser: null,
      }),
      setUserReset: assign({
        resetPasswordUsername: (_ctx, event) =>
          event.data.resetPasswordUsername,
      }),
      setErrorUserReset: assign({
        resetPasswordError: (_ctx, event) => event.data.error,
      }),
      setErrorUserCodeReset: assign({
        resetPasswordError: (_ctx, event) => event.data.error,
      }),
      removeUserReset: assign({
        resetPasswordUsername: null,
        resetPasswordCredentials: null,
      }),

      setPasswordData: assign((ctx, event) => {
        return {
          passwordData: event.data,
        };
      }),
      onInvalidCredentials: () => {},
      onSignIn: () => {},
      onInvalidUser: () => {},
      onResetComplete: () => {},
    },
    guards: {
      isNewPasswordRequired: (_ctx, event) => {
        return event.data.challengeName === "NEW_PASSWORD_REQUIRED";
      },
      userHasNotSignInYet: (ctx, event) => {
        // It is possible that the cognito API change the error message.
        // @todo
        return (
          event.data.code === "NotAuthorizedException" &&
          event.data.message ===
            "User password cannot be reset in the current state."
        );
      },
    },
    services: {
      getUserProfile: ctx => {
        return getUserProfile(ctx.authenticatedUser);
      },
      getAuthenticatedUser: () => {
        return Auth.currentAuthenticatedUser()
          .then(getUserProfile)
          .catch(error => {
            return Promise.reject(error);
          });
      },
      authenticateUser: ctx => {
        const { username, password } = ctx.credentials;
        return Auth.signIn(username, password);
      },
      completeNewPassword: ctx => {
        return Auth.completeNewPassword(ctx.passwordData, ctx.newPassword);
      },
      submitUserReset: ctx => {
        return Auth.forgotPassword(ctx.resetPasswordUsername);
      },
      submitUserCodeReset: ctx => {
        const username = ctx.resetPasswordUsername;
        const { code, newPassword } = ctx.resetPasswordCredentials;
        return Auth.forgotPasswordSubmit(username, code, newPassword);
      },
      resendUserCredentials: ctx => {
        return resendUserPassword(ctx.resetPasswordUsername);
      },
    },
  }
);

export default machine;
