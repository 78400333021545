import PropTypes from "prop-types";
import React, { Component } from "react";
import RaisedButton from "material-ui/RaisedButton";
import { reduxForm, addArrayValue } from "redux-form";
import { Row, Col } from "react-flexbox-grid";
import TextField from "material-ui/TextField";
import Divider from "material-ui/Divider";
import FlatButton from "material-ui/FlatButton";

export default function (subItem, formId) {
    class MetadataItem extends Component {
        constructor(props) {
            super(props);
        }

        render() {
            const {
                fields: { item, coi },
                handlePrev,
                handleNext,
                handleSubmit,
            } = this.props;
            const mappedCoi = coi.map(({ label, initialValue, endValue }) => {
                return (
                    <Row>
                        <Col xs>
                            <TextField
                                ref="label"
                                hintText="Ej: Interpretación X"
                                floatingLabelText="Condición de interpretación"
                                type="text"
                                {...label}
                                errorText={
                                    label.touched && label.error
                                        ? label.error
                                        : ""
                                }
                            />
                        </Col>
                        <Col xs>
                            <TextField
                                ref="initialValue"
                                hintText="Ej: 20"
                                floatingLabelText="Valor inicial"
                                type="number"
                                errorText={
                                    initialValue.touched && initialValue.error
                                        ? initialValue.error
                                        : ""
                                }
                                {...initialValue}
                            />
                        </Col>
                        <Col xs>
                            <TextField
                                ref="businessName"
                                hintText="Ej: 70"
                                floatingLabelText="Valor final"
                                type="number"
                                errorText={
                                    endValue.touched && endValue.error
                                        ? endValue.error
                                        : ""
                                }
                                {...endValue}
                            />
                        </Col>
                        <Divider />
                    </Row>
                );
            });
            return (
                <form onSubmit={handleSubmit(handleNext)}>
                    <Row>
                        <Col md={12}>
                            <Row>
                                <Col md={8}>
                                    <h3>Condiciones de interpretación</h3>
                                </Col>
                                <Col md={4}>
                                    <RaisedButton
                                        label="Agregar Condición"
                                        style={{ margin: 12 }}
                                        onClick={() => {
                                            coi.addField();
                                        }}
                                    />
                                </Col>
                                <Divider />
                                <Col md={12}>{mappedCoi}</Col>
                            </Row>
                        </Col>
                        <Col md={12} style={{ margin: "12px 0" }}>
                            <RaisedButton
                                label="Finalizar"
                                disableTouchRipple
                                disableFocusRipple
                                primary
                                type="submit"
                                style={{ marginRight: 12 }}
                            />
                            <FlatButton
                                label="Atrás"
                                disableTouchRipple
                                disableFocusRipple
                                onClick={handlePrev}
                            />
                        </Col>
                    </Row>
                </form>
            );
        }
    }
    return reduxForm({
        fields: [
            "id",
            "color",
            "item",
            "initialValue",
            "endValue",
            "coi[].label",
            "coi[].initialValue",
            "coi[].endValue",
        ],
        form: formId,
        initialValues: subItem,
    })(MetadataItem);
}
