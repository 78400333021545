import React, { useEffect, useState, useRef } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { TextField, FlatButton, RaisedButton } from 'material-ui';
import PropTypes from 'prop-types';
import { LBL_COMPLEMENTS } from '../../constants/labels';
import DeleteEntityModal from '../deleteEntityModal/component';

const styles = {
  button: {
    margin: 15,
  },
  contentButton: {
    justifyContent: 'center',
  },
};

/**
 * Componente de formulario para la edicion de complementos y valores
 * @author Jhoan Lopez <jhoanlt19@gmail.com>
 * @param {*} param0
 */
const ComplementForm = ({
  selected,
  handleSave,
  handleDelete,
  titleDelete,
}) => {
  const [name, setName] = useState('');
  const [edit, setEdit] = useState(false);
  const inputCom = useRef(null);

  useEffect(() => {
    setName(selected.name || '');
  }, [selected]);

  const handleChange = ({ target }) => {
    setName(target.value);
  };

  const handleEdit = () => {
    setEdit(true);
    inputCom.current.state.isFocused = true;
  };

  const handleCancel = () => {
    setName(selected.name || '');
    setEdit(false);
  };

  const handleFocus = event => {
    event.target.select();
  };

  const handleItemSave = () => {
    handleSave(name);
    setEdit(false);
  };

  const handleItemDelete = ({ _id }) => {
    handleDelete(_id);
  };

  return (
    <form onSubmit={() => null}>
      <Row>
        <Col xs={12} md={12}>
          <TextField
            data-testid="text-field"
            ref={inputCom}
            floatingLabelText="Nombre"
            value={name}
            disabled={!edit}
            onFocus={handleFocus}
            onChange={handleChange}
          />
        </Col>
        {!edit ? (
          <Col xs={12}>
            <FlatButton
              data-testid="edit-button"
              label="Editar"
              primary
              style={styles.button}
              disabled={Object.keys(selected).length === 0}
              onClick={handleEdit}
            />
            <DeleteEntityModal
              tooltip={LBL_COMPLEMENTS.tooltips.btnDelete}
              title={titleDelete}
              message={LBL_COMPLEMENTS.msg.deleteModal(selected.name)}
              fn={a => handleItemDelete(a)}
              args={[selected]}
              typeButton="button"
              valid={Object.keys(selected).length === 0}
            />
          </Col>
        ) : (
          <Col xs={12}>
            <FlatButton
              data-testid="cancel-button"
              label="Cancelar"
              primary
              style={styles.button}
              onClick={handleCancel}
            />
            <RaisedButton
              data-testid="save-button"
              label="Guardar"
              primary
              style={styles.button}
              onClick={handleItemSave}
              disabled={name === ''}
            />
          </Col>
        )}
      </Row>
    </form>
  );
};

ComplementForm.defaultProps = {
  selected: {},
  handleSave: () => null,
  handleDelete: () => null,
  titleDelete: 'Eliminar',
};

ComplementForm.propTypes = {
  selected: PropTypes.any,
  handleSave: PropTypes.func,
  handleDelete: PropTypes.func,
  titleDelete: PropTypes.string,
};

export default ComplementForm;
