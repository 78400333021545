import { useQuery, useMutation, gql } from "@apollo/client";
import client from "../../../middleware/graphql";

const checkUserLicenseQuery = gql`
  query($username: String!) {
    status: getQuickSightUserLicenseStatus(username: $username)
  }
`;

const registerUserMutation = gql`
  mutation($input: QuickSightUserInput!) {
    registerQuickSightUser(input: $input) {
      id
    }
  }
`;

const deleteUserMutation = gql`
  mutation($userName: String!) {
    deleteQuicksightUser(userName: $userName) {
      operationId
    }
  }
`;

type Args = {
  username: string;
  email: string;
};
function useQuickSightLicenseStatus({ email, username }: Args) {
  const query = useQuery(checkUserLicenseQuery, {
    variables: {
      username,
    },
    client,
  });
  const [registerUser, mutationStatus] = useMutation(registerUserMutation, {
    variables: {
      input: {
        username,
        email,
      },
    },
    client,
    refetchQueries: [
      {
        query: checkUserLicenseQuery,
        variables: {
          username,
        },
      },
    ],
  });
  const [deleteUserLicense, deleteStatus] = useMutation(deleteUserMutation, {
    variables: {
      userName: username,
    },
    client,
    refetchQueries: [
      {
        query: checkUserLicenseQuery,
        variables: {
          username,
        },
      },
    ],
  });
  const isLoading =
    query.loading || mutationStatus.loading || deleteStatus.loading;
  const hasError = query.error || mutationStatus.error || deleteStatus.error;
  const isReady = !isLoading && !hasError;
  const isActive = isReady ? query.data.status : false;
  const toggleLicense = (status: boolean) => {
    if (status) {
      registerUser();
    } else {
      deleteUserLicense();
    }
  };

  return {
    isReady,
    isActive,
    toggleLicense,
  };
}

export { useQuickSightLicenseStatus };
