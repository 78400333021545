import React from 'react'
import {useAuth} from "../../auth/context";

const withAuthHook = (Component: any) => {
    return (props: any) => {
        const {user} = useAuth();

        return <Component user={user} {...props} />;
    };
};

export default withAuthHook