import _ from 'lodash';
import {
    GET_QUESTIONS_SELECTORS_SECTIONS,
    GET_SECTION_INFO,
    GET_SECTIONS_GRID,
    CLEAN_SECTION_INFO,
    GET_ALL_QUESTIONS_SURVEY
} from '../constants/index';

const INITIAL_STATE = {all: [], sections: null, section: null, questionsId: []};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_SECTIONS_GRID:
            return _.assign({}, state, {
                all: action.payload.data
            });
        case GET_QUESTIONS_SELECTORS_SECTIONS:
            return _.assign({}, state, {
                section: action.payload.data
            });
        case GET_SECTION_INFO:
            return _.assign({}, state, {
                all: action.payload.data
            });
        case CLEAN_SECTION_INFO:
            return _.assign({}, state, {
                all: null
            });
        case GET_ALL_QUESTIONS_SURVEY:
            return _.assign({}, state, {
                questionsId: action.payload.data.data
            });
        default:
            return state;
    }
}
